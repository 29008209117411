import React, { Component } from "react";
import { observer } from "mobx-react";
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import JoditEditor, { Helpers } from "jodit-pro-react";
import * as JoditCore from "jodit-pro/build/jodit";
import { configJodit } from "../../utils/configJodit";
import { Tooltip } from "@mui/material";
import {
  PLACEHOLDER_COLOR,
  PLACEHOLDER_ERROR_COLOR,
  PLACEHOLDER_FORM_ERROR_COLOR
} from "../../utils/PlaceholderUtil";
import { Alert } from "@mui/lab";
import ErrorIcon from "@mui/icons-material/Error";
import JoditSuperClass from "../TextEditor/JoditSuperClass";
import { TOOLBAR_BUTTONS } from "../../utils/Jodit/JoditButtons";
import insertImage from "../../utils/Jodit/insertImage";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import { preventDefaultPaste } from "../../utils/Jodit/preventDefaultPaste";
class FormMentionEditor extends JoditSuperClass {
  joditRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.text ? this.props.text.toString() : "",
      oldContent: this.props.text ? this.props.text.toString() : "",
      placeholder: this.props.placeholder ? this.props.placeholder : "",
      tooltip: "",
      aiAssistant:this.props.aiAssistant?this.props.aiAssistant:false
    };
  }

  componentDidMount() {
    //choose correct data fromat to initialize
    if (this.props.contentBlock) this.initializeContentBlock();
    else this.initializeTextField();

    //initialize tooltip text
    if (!this.props.contentBlock) {
      if(this.props.aiAssistant){
        this.setState({ tooltip: "Type “$” to view and insert placeholders" });

      } else if (this.props.assignTask) {
        this.setState({
          tooltip:
            "Type “!” to insert a form placeholder or “$” for workflow placeholder"
        });
      } else {
        this.setState({ tooltip: "Type “!” to insert a form placeholder" });
      }
    }
    if (this.props.disableTooltip) this.setState({ tooltip: "" });
  }

  //this fuction retrieves saved text from a textfield
  initializeTextField = () => {
    let content = this.props.text;
    if (content) {
      content = content.toString();
      content = content.replace(/(?:\r\n|\r|\n\n)/g, "<br>");
      content = content.replace(/(?:\n)/g, "");
    } else {
      content = "";
    }
    this.setState({
      content
    });
  };

  //this fuction retrieves saved text in html for jodit editor
  initializeContentBlock = () => {
    let content = this.props.text;

    if (content) {
      if (content.includes("table")) {
        content = content
          .replace(/(<table style=")/g, val => {
            return `<table border="1" style="border-collapse:collapse; `;
          })
          .replace(/(border-color:)/g, "border: 1px solid");
      }
      content = content
        .replace(/(?:\r\n|\r|\n)/g, "<br>")
        .replace(/(<td style="width: [0-9.]+%;">)/g, val => {
          let width = val.match(/([0-9.]+)/g)[0];
          return `<td style="width: ${width}%; padding: 4px;">`;
        });
    } else {
      content = "";
    }

    this.setState({
      content
    });
  };

  componentDidUpdate(prevProps) {
    //if this is a jodit text editor
    if (this.props.contentBlock) {
      const { availablePlaceholder } = this.props;
      //re-init placeholders after more available
      if (
        availablePlaceholder &&
        availablePlaceholder.length !== prevProps.availablePlaceholder.length
      ) {
        this.insertPlaceholder(availablePlaceholder[placeholder.length - 1]);
      }
    }
  }

  //handles inserting placeholder chip through autocomplete
  insertPlaceholder = placeholder => {
    let text = "";
    //if this is a form placeholder
    if (placeholder && placeholder.type == "form_placeholder") {
      //follow this convention

      text = "!{" + placeholder.key + "}";
    } else {
      //if this is any other placeholder type
      if (this.props.assignTask) {
        //and workflow placeholders are allowed in this form
        //follow this convention

        text = "${" + placeholder.text + "}";
      }
    }
    //and insert into the editor
    this.jodit.selection.insertHTML(text);
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check usually
    //but in this case default question text just doesn't load properly
    if (nextProps.text !== this.state.content) {
      this.setState({ content: nextProps.text });
    }
  }

  //style inserted placeholders in the editor
  //if your placeholders aren't rendering properly inside the editor
  //problem is here
  getPlaceholderStyle = (value, backgroundColor, borderColor, type, icon) => {
    //create a span to contain the placeholder
    let content = document.createElement("span");
    //create a placeholder
    const button = this.generateButton(
      backgroundColor,
      borderColor,
      "",
      "nowrap",
      icon
    );

    //if this is not a form placehoder
    if (type !== "form_placeholder") {
      //and this form allows workflow placeholders
      if (this.props.assignTask) {
        //set placeholder to workflow style placeholders
        button.setAttribute("data-highlight-schema", "${([^}]+)}");
        button.setAttribute("data-jodit-temp", "true");
        //add value
        button.innerHTML = "${" + value + "}";
      }
    } else {
      //this is a form placeholder
      //therefore set style to form placeholder
      button.setAttribute("data-highlight-schema", "!{([^}]+)}");
      button.setAttribute("data-jodit-temp", "true");
      //set value
      button.innerHTML = "!{" + value + "}";
    }

    //insert into the span
    content.insertAdjacentHTML("afterbegin", button.outerHTML);

    return content;
  };

  //updates content of the text editor
  updateContent = value => {
    //if this is a text editor
    if (this.props.contentBlock) {
      //update contents according to the text editor rules
      value = this.updateTextEditorContent(value);
    } else {
      //this is a textfield value
      //we remove all the trailing whitespaces
      value = value.replaceAll("&nbsp;", " ");
      //then strip out jodit html tags
      value = JoditCore.Jodit.modules.Helpers.stripTags(value);
      //call parent to change value in the state
      this.props.onChange(value);
    }
  };

  //updates value of the editor if this is considered a text editor
  updateTextEditorContent = value => {
    value = value.replace(/(?:\n)/g, "");
    value = value
      .replace(/(<table style=")/g, val => {
        return `<table border="1" style="border-collapse:collapse; `;
      })
      .replace(/(border-color:)/g, "border: 1px solid")
      .replace(/(<td style="width: [0-9.]+%;">)/g, val => {
        let width = val.match(/([0-9.]+)/g)[0];
        return `<td style="width: ${width}%; padding: 4px;">`;
      });

    this.props.onChange(value);
  };


  //CONFIGURATION IF THIS COMPONENT IS A TEXT EDITOR LIKE IN CONTENT BLOCK
  TEXT_EDITOR_CONFIG = {
    readonly: false,
    limitChars: "1073741823", // java character limit
    limitHTML: true,
    spellcheck: true,
    placeholder: this.state ? this.state.placeholder : "",
    disablePlugins: [
      "keyboard",
      "google-search",
      "fullsize",
      "about",
      "classSpan",
      "file",
      "video",
      "image",
      "google-maps",
      "export-docs"
    ],
    // Set for differing screen sizes
    buttonsXS: TOOLBAR_BUTTONS,
    buttonsSM: TOOLBAR_BUTTONS,
    buttonsMD: TOOLBAR_BUTTONS,
    countHTMLChars: true,
    height: "auto",
    uploader: false,
    defaultActionOnPaste: "insert_only_text",
    events: {
      afterInit: (instance) => {
        this.jodit = instance;
        this.joditRef.current = instance;
      },
      beforePaste: (event)=>{preventDefaultPaste(event)},
      afterPaste: insertImage(this.joditRef, ProjectStore.project_id)
    },
    autocomplete: {
      sources: [
        {
          //feed contains a list of placeholders that get's rendered in the autocomplete window
          feed: query =>
            //if the placeholder list is available
            this.props.availablePlaceholders
              ? //filter it by
              this.props.availablePlaceholders.filter(value => {
                let text = "";
                //if this is a form placeholder
                if (value.type === "form_placeholder")
                  //form placeholder pattern
                  text = "!{" + value.key + "}";
                else if (this.props.assignTask)
                  //if this is any other type and placeholders are allowed in this form
                  //workflow placeholder pattern
                  text = "${" + value.key + "}";

                //now filter by input
                if (text === query) {
                  return false;
                } else {
                  return (
                    text.indexOf(query.trim()) === 0 ||
                    text.indexOf(query.trim()) === 1
                  );
                }
              })
              : //if available placeholders do not exist there is nothing to return
              false,
          itemRenderer: item => {
            //this handles how the placeholder is rendered in the autocomplete render
            //determine color
            const placeholderColor = getPlaceholderColors(
              item.type,
              item.length
            );
            //create placeholder
            const button = this.generateButton(
              placeholderColor.hover,
              placeholderColor.default,
              "16px",
              "nowrap",
              placeholderColor.icon
            );
            //if this is a form_placeholder
            if (item.type === "form_placeholder") {
              //set value to the button with !
              button.innerHTML = "!{" + item.key + "}";
            } else if (this.props.assignTask) {
              //if this is a workflow placeholder
              //and workflow placeholders are allowed in this form
              //set button value with $
              button.innerHTML = "${" + item.key + "}";
            }
            return button;
          },
          insertValueRenderer: ({ key, type, length }) => {
            //calls on the insertion and styles the inserted value
            const placeholderColor = getPlaceholderColors(type, length);
            //render placeholder in the textfield
            return this.getPlaceholderStyle(
              key,
              placeholderColor.hover,
              placeholderColor.default,
              type,
              placeholderColor.icon
            );
          }
        }
      ]
    },
    highlightSignature: {
      //this finds placeholders in the textfield that have already been rendered
      //has to handle 2 types workflow and form
      schema: {
        //this is form
        "\\!\\{([^}]+)\\}": (jodit, matched) => {
          let color = "";
          let borderColor = "";
          let icon = "";

          //availdable placeholders aren't empty
          if (this.props.availablePlaceholders.length > 0) {
            //go through each
            this.props.availablePlaceholders.filter(placeholder => {
              //determine color
              const placeholderColor = getPlaceholderColors(
                placeholder.type,
                placeholder.length
              );
              //set color
              color = placeholderColor.hover;
              borderColor = placeholderColor.default;
              icon = placeholderColor.icon;
            });
          }
          //init a button to highlight
          return this.generateButton(color, borderColor, "", "", icon);
        },
        //this is a workflow placeholder
        "\\$\\{([^}]+)\\}": (jodit, matched) => {
          let color = PLACEHOLDER_ERROR_COLOR.BACKGROUND;
          let borderColor = PLACEHOLDER_ERROR_COLOR.BORDER;
          let textColor = PLACEHOLDER_ERROR_COLOR.TEXT;
          let icon = PLACEHOLDER_ERROR_COLOR.ICON;

          //if there are placeholders
          if (this.props.availablePlaceholders.length > 0) {
            //filter
            this.props.availablePlaceholders.filter(placeholder => {
              if (placeholder.key === matched[1]) {
                //create style
                const placeholderColor = getPlaceholderColors(
                  placeholder.type,
                  placeholder.length
                );
                //assign style
                color = placeholderColor.hover;
                borderColor = placeholderColor.default;
                textColor = PLACEHOLDER_COLOR.TEXT;
                icon = placeholderColor.icon;
              }
            });
          }
          //init a button to highlight
          return this.generateButton(
            color,
            borderColor,
            "",
            "",
            icon,
            textColor
          );
        }
      }
    },
    ...configJodit,
    ...this.props.contentStyles
  };

  //CONFIGURATION IF THIS COMPONENT IS A TEXT FIELD LIKE IN QUESTION FIELD
  TEXT_FIELD_CONFIG = {
    readonly: false,
    toolbar: false,
    placeholder: this.state ? this.state.placeholder : "",
    defaultActionOnPaste: "insert_only_text",
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    disablePlugins:
      "add-new-line,about,class-span,backup,button-generator,bold,clean-html,delete,wrap-text-nodes,copy-format,clipboard,paste,paste-storage,color,drag-and-drop,drag-and-drop-element,key-arrow-outside,error-messages,font,format-block,fullsize,hotkeys,iframe,indent,hr,inline-popup,justify,limit,link,mobile,ordered-list,placeholder,redo-undo,search,select,source,stat,sticky,symbols,tooltip,xpath,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table-keyboard-navigation,table,preview,print",
    toolbarAdaptive: false,
    height: "auto",
    useSearch: false,
    spellcheck: false,
    allowResizeY: false,

    events: {
      afterInit: instance => {
        this.jodit = instance;
      }
    },
    autocomplete: {
      sources: [
        {
          feed: query =>
            this.props.availablePlaceholders !== undefined
              ? this.props.availablePlaceholders.filter(value => {
                let text = "";
                if (value.type == "form_placeholder")
                  text = "!{" + value.key + "}";
                else if (this.props.assignTask) text = "${" + value.key + "}";

                if (text === query) {
                  return false;
                } else {
                  if (!text) return;
                  text.indexOf(query.trim()) === 0 ||
                    text.indexOf(query.trim()) === 1;
                }
              })
              : false,
          itemRenderer: item => {
            const placeholderColor = getPlaceholderColors(
              item.type,
              item.length
            );
            const button = this.generateButton(
              placeholderColor.hover,
              placeholderColor.default,
              "16px",
              "nowrap",
              placeholderColor.icon
            );
            if (item.type === "form_placeholder") {
              button.innerHTML = "!{" + item.key + "}";
            } else if (this.props.assignTask) {
              button.innerHTML = "${" + item.key + "}";
            }
            return button;
          },
          insertValueRenderer: ({ key, type, length }) => {
            const placeholderColor = getPlaceholderColors(type, length);

            return this.getPlaceholderStyle(
              key,
              placeholderColor.hover,
              placeholderColor.default,
              type,
              placeholderColor.icon
            );
          }
        },
        {
          feed: query =>
            this.props.availablePlaceholders !== undefined
              ? this.props.availablePlaceholders.filter(value => {
                let text = "";

                if (value.type === "form_placeholder") {
                  text = "!{" + value.key + "}";
                } else if (this.props.assignTask) {
                  text = "${" + value.key + "}";
                }

                if (text === query) {
                  return false;
                } else {
                  return text.indexOf(query.trim()) === 0;
                }
              })
              : false,
          itemRenderer: item => {
            const placeholderColor = getPlaceholderColors(
              item.type,
              item.length
            );

            const button = this.generateButton(
              placeholderColor.hover,
              placeholderColor.default,
              "16px",
              "nowrap",
              placeholderColor.icon
            );
            if (item.type === "form_placeholder") {
              button.innerHTML = "!{" + item.key + "}";
            } else if (this.props.assignTask) {
              button.innerHTML = "${" + item.key + "}";
            }
            return button;
          },
          insertValueRenderer: ({ key, type, length }) => {
            const placeholderColor = getPlaceholderColors(type, length);

            return this.getPlaceholderStyle(
              key,
              placeholderColor.hover,
              placeholderColor.default,
              type,
              placeholderColor.icon
            );
          }
        }
      ]
    },
    highlightSignature: {
      schema: {
        "\\!\\{([^}]+)\\}": (jodit, matched) => {
          let color = "";
          let borderColor = "";
          let icon = "";

          if (this.props.availablePlaceholders.length > 0) {
            this.props.availablePlaceholders.filter(placeholder => {
              if (placeholder.key === matched[1]) {
                const placeholderColor = getPlaceholderColors(
                  placeholder.type,
                  placeholder.length
                );

                color = placeholderColor.hover;
                borderColor = placeholderColor.default;
                icon = placeholderColor.icon;
              }
            });
          }
          return this.generateButton(color, borderColor, "", "", icon);
        },
        "\\$\\{([^}]+)\\}": (jodit, matched) => {
          let color = PLACEHOLDER_ERROR_COLOR.BACKGROUND;
          let borderColor = PLACEHOLDER_ERROR_COLOR.BORDER;
          let textColor = PLACEHOLDER_ERROR_COLOR.TEXT;
          let icon = PLACEHOLDER_ERROR_COLOR.ICON;

          if (this.props.availablePlaceholders.length > 0) {
            this.props.availablePlaceholders.filter(placeholder => {
              if (placeholder.key === matched[1]) {
                const placeholderColor = getPlaceholderColors(
                  placeholder.type,
                  placeholder.length
                );

                color = placeholderColor.hover;
                borderColor = placeholderColor.default;
                textColor = PLACEHOLDER_COLOR.TEXT;
                icon = placeholderColor.icon;
              }
            });
          }
          return this.generateButton(color, borderColor, "", "", icon);
        }
      }
    },
    ...configJodit,
    ...this.props.style
  };

  setRef = jodit => (this.jodit = jodit);

  focus = () => {
    this.props.onFocus();
  };

  render() {
    const { error, helperText } = this.props;
    return (
      <Tooltip placement="bottom" title={this.state.tooltip}>
        <div
          onClick={this.focus}
          style={this.props.style}
          className={this.props.className}
        >
          <span className="jodit-mention">
            <JoditEditor
              ref={this.setRef}
              value={this.state.content}
              config={
                this.props.contentBlock
                  ? this.TEXT_EDITOR_CONFIG
                  : this.TEXT_FIELD_CONFIG
              }
              tabIndex={1}
              onBlur={this.updateContent}
            />
          </span>
          {error &&
            <p style={{ color: "red", margin: "5px 0 0" }}>
              {helperText}
            </p>}
          <Alert
            severity={"error"}
            icon={
              <ErrorIcon
                sx={{
                  color: "#B00020"
                }}
              />
            }
            style={{
              display:
                this.state.content && this.state.content.length > 1073741823
                  ? null
                  : "none",
              backgroundColor: "rgba(176, 0, 32, 0.15)",
              marginTop: "16px",
              width: "fit-content",
              color: "rgba(0, 0, 0, 0.87)",
              fontWeight: "400",
              border: "1px solid rgba(176, 0, 32, 1)"
            }}
          >
            You have reached the character limit for this editor
          </Alert>
        </div>
      </Tooltip>
    );
  }
}

export default observer(FormMentionEditor);
