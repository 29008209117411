import { computed, observable, decorate, action, toJS } from "mobx";

class ParseJSONStore {
  constructor() {
    this.initialiseEmpty();
  }

  setParseJSONTemplate = data => {
    this.parse_json_template_id = data.componentId;
    this.parse_json_name = data.componentData.name;
    this.lastModified = data.componentData.lastModified;
    this.componentData = data.componentData.data;
    this.samplePlaceholders = data.componentData.samplePlaceholders;
  };

  setParseJSONName = ParseJSONName => {
    this.parse_json_name = ParseJSONName;
    this.template_data.name = ParseJSONName;
  };

  storeError = (obj) => {
    this.placeholderError = obj;
  }

  setPlaceholders = (placeholders) => {
    this.componentData.placeholders = placeholders;
  }

  setJSONPlaceholder = (placeholder) => {
    this.componentData.jsonPlaceholder = placeholder;
  }

  setSampleJSON = (sampleJson) => {
    this.componentData.sample_json = sampleJson;
  }
  

  deletePlaceholders = () => {
    this.componentData.placeholders = [];
  }

  initialiseEmpty = () => {
    this.parse_json_template_id = "";
    this.lastModified = "";
    this.componentData = {
      sample_json: "",
      placeholders: [],
      jsonPlaceholder: "",
      samplePlaceholders: []
    }
  };

  clearField = () => {
    this.componentData.samplePlaceholders = [];
  };

  addJsonData = (obj) => {
    this.componentData.samplePlaceholders.push(obj);
  };

  jsonData = (key, value) => {
    return {
      key: key,
      sampleKey: key,
      sampleData: value,
      checked: true
    };
  };

  onChangeField = (value,placeholder,type) => {
    let placeholders = toJS(this.componentData.samplePlaceholders)
    let index = placeholders.findIndex(x => x.key === placeholder.key);
    switch (type) {
      case "content":
        this.componentData.samplePlaceholders[index].sampleKey = value;
        this.componentData.samplePlaceholders[index].sampleData = "";
        return;
      case "json" :
        this.componentData.json = value
        return;
      case "key" :
        this.componentData.samplePlaceholders[index].key = value;
        return;
      case "timeout" :
        this.componentData.timeout = value
    }
  }


  get template() {
    return {
      componentId: this.parse_json_template_id,
      lastModified: this.lastModified,
      componentData: toJS(this.template_data)
    };
  }
}

decorate(ParseJSONStore, {
  parse_json_template_id: observable,
  sample_json: observable,
  client_id: observable,
  lastModified: observable,
  template_data: observable,
  componentData: observable,
  setParseJSONTemplate: action,
  setParseJSONName: action,
  setSampleJSON: action,
  initialiseEmpty: action,
  deletePlaceholder: action,
  jsonData: action,
  addJsonData: action,
  clearField: action,
  template: computed
});

const parseJSONStore = new ParseJSONStore();
export default parseJSONStore;
