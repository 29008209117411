import config from "../config";
import ProjectStore from "../components/ProjectCanvas/ProjectStore";

const NOTE_TYPE = "note";
const LINK_TYPE = "link";

export function isAIEnabled(clientId) {
 return true;
}

/**
 * @param {*} items => the canvas items
 * @param {*} startNoteId => the starting note that the user clicked for doing notes to AI
 * @returns the ai notes sent to the backend to convert to a workflow
 */
export function getAINotesSent(nodes, edges, startNoteId) {
  let visitedNoteIds = new Set();

  if (!nodes || !edges) return visitedNoteIds;

  const items = [...nodes, ...edges];

  const noteItems = items.filter((item) => item.type === NOTE_TYPE || item.type === LINK_TYPE);
  const sourceToTarget = createSourceToTargetMap(noteItems);
  const idToNote = createIdToNote(noteItems);

  let queue = [];

  let source = idToNote[startNoteId];

  queue.push(source.id);
  visitedNoteIds.add(source.id);

  while (queue.length != 0) {
    // Grab the last item
    const first = queue.shift();

    // Grab the next items
    const neighbours = sourceToTarget[first];
    if (!neighbours) continue;

    for (let neighbourId of neighbours) {
      if (!visitedNoteIds.has(neighbourId)) {
        queue.push(neighbourId);
        visitedNoteIds.add(neighbourId);
      }
    }
  }
  
  return visitedNoteIds;
}

/**
 * @param {*} canvas => The given workflow nodes
 * @param {*} notesSent => The ai notes sent
 * @returns an updated canvas array with ai notes sent having sentToAI = true
 */
export function getCanvasWithAINotesBorderSet(nodes, notesSent) {
  let updatedNotesCanvas = [];

  if (!nodes || !notesSent) return updatedNotesCanvas;

  // Go over each nodes item
  for (let idx = 0; idx < nodes.length; idx++) {
    let canvasItem = nodes[idx];
    
    if (canvasItem.type === "note") {
      let copyItem = {
        ...canvasItem
      };

      copyItem["data"].sentToAI = notesSent.has(canvasItem.id);
      updatedNotesCanvas.push(copyItem);
    } else {
      updatedNotesCanvas.push(canvasItem);
    }
  }

  return updatedNotesCanvas;
}

/**
 * Creates a mapping from a given notes id => the note 
 * i.e: {
 *  '1': {...note1},
 *  '2': {...note2},
 *  ...
 * }
 */
const createIdToNote = (items) => {
  let idToNoteMap = {};

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.type !== NOTE_TYPE) continue;
    idToNoteMap[item.id] = item;
  }

  return idToNoteMap;
}

/**
 * Creates a source to target map for the canvas links
 * i.e: a key is a source of a given link and the value is the target
 *      of that respective link
 */
const createSourceToTargetMap = (items) => {
  let linkSourceToTargetMap = {};

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.type !== LINK_TYPE) continue; // If not link, skip

    // If already in map, add to value array
    // ELSE, create new entry
    if (item.source in linkSourceToTargetMap) {
      linkSourceToTargetMap[item.source] = [...linkSourceToTargetMap[item.source], item.target];
    } else {
      linkSourceToTargetMap[item.source] = [item.target];
    }
  }

  return linkSourceToTargetMap;
}