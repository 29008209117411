import { Grid, styled } from "@mui/material";
import ComponentNameFromId from "../ComponentNameFromId";

export const getNameFromKey = (key) => {
    switch (key) {
        case "notUnique":
            return "placeholder overwrites";
        case "broken":
            return "broken placeholders";
        default:
            return "component configuration errors";
    }
};

export const getErrorTextFromKey = (err, key) => {
    if (!err) return;
    switch (key) {
        case "notUnique":
            return (
                <Message>
                    The value for <b>{`$\{${err.error}\}`}</b> is being set by multiple components. Its value will be overwritten as the workflow runs.
                </Message>
            );
        case "broken":
            return (
                <Message>
                    The source for placeholder <b>{`$\{${err.error}\}`}</b> cannot be found.
                </Message>
            );
        default:
            return <Message>{err.error}</Message>;
    }
};

export const isComponentAlreadyOpen = (editComponent, errComponent) => {
    if (!editComponent || !errComponent) return false;
    if (!errComponent.component) return false;
    return editComponent.componentId === errComponent.component.componentId;
}

// Common styled components
const Message = styled("span")({
    fontSize: "14px",
    fontFamily: "Open Sans",
    lineHeight: "1.65em",
});

export const BoldTopText = styled("span")({
    fontSize: "14px",
    fontWeight: "bold",
});

export const ErrorItem = styled(Grid)(({ bgColor }) => ({
    backgroundColor: bgColor,
    borderRadius: "8px",
    padding: "8px",
    color: "#000 !important",
}));
