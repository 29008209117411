import React from "react";

// Mui
import { styled, Grid, Button } from "@mui/material";
import { Autorenew, SkipNext } from "@mui/icons-material";

// Styled components
const ValidationButton = styled(Button)(({ textColor, border, bg, hover }) => ({
    color: textColor ? textColor : "#000",
    border: border,
    background: bg,
    textTransform: "none",
    fontSize: "14px",
    "&:hover": {
        ...hover,
    },
}));

function ValidationActionButtons(props) {
    return (
        <Grid container display="flex" alignItems="center" justifyContent="flex-end" gap="12px">
            <Grid item>
                <ValidationButton
                    size="small"
                    variant={"outlined"}
                    color={"info"}
                    disabled={props.disabled}
                    onClick={() => {
                        props.close();
                    }}
                    border="none"
                    bg="none"
                    hover={{
                        border: "none",
                    }}
                    id="close-fix-errors-btn"
                >
                    Close
                </ValidationButton>
            </Grid>
            <Grid item>
                <ValidationButton
                    size="small"
                    variant={"outlined"}
                    color={"primary"}
                    disabled={props.disabled}
                    onClick={() => {
                        props.handlePublish();
                    }}
                    startIcon={<SkipNext />}
                    border="1px solid #000"
                    bg="#FFF"
                    id="fix-errors-publish-anyway-btn"
                >
                    Publish anyway
                </ValidationButton>
            </Grid>
            <Grid item>
                <ValidationButton
                    size="small"
                    variant={"contained"}
                    onClick={() => {
                        props.fireValidateDialog("publish");
                    }}
                    disabled={props.disabled}
                    startIcon={<Autorenew />}
                    border="1px solid #000"
                    bg="#000"
                    textColor="#FFF"
                    hover={{
                        background: "rgba(0, 0, 0, 0.9)",
                    }}
                    id="fix-errors-check-again-btn"
                >
                    Check again
                </ValidationButton>
            </Grid>
        </Grid>
    );
}

export default ValidationActionButtons;
