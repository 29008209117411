import React, { Component } from "react";
import TextField from "vardogyir-ui/TextField";
import Tooltips from "vardogyir-ui/Tooltips";
import DropZone from "vardogyir-ui/DropZone";
import { MenuItem, Select, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { observer, inject } from "mobx-react";
import { withStyles } from "@mui/styles";
import Dropzone from "react-dropzone";
import { autorun, toJS, reaction, trace } from "mobx";
import Chip from "@mui/material/Chip";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import projectStore from "../ProjectCanvas/ProjectStore";
import { send_request, send_external_request, send_component_save_request } from "../../utils/Request";
import DocumentStore from "./DocumentStore";
import styles from "./DocumentStyle";
import { getCurrentTime } from "../../utils/getCurrentTime";
import Icon from "@mui/material/Icon";
import checkKey from "../../utils/CheckKey";
import DocumentEditor from "../TextEditor/DocumentEditor";
import CircularProgress from "@mui/material/CircularProgress";
import CONFIG from "../../config";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import DocumentFilePlaceholderUpload from "../TextEditor/DocumentFilePlaceholderUpload";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";
import DocumentPreview from "./DocumentPreview";
import documentStore from "./DocumentStore";
import './DocumentEditor.css'; // Add this import at the top of the file

const useStyles = () => ({
  root: {
    "& .jodit-container": {
      borderColor: "#ffffff !important"
    }
  }
});

const fileService = CONFIG.FILE_SERVICE;

class DocumentTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: null,
      placeholders: [],
      loading: false,
      checkKey: false,
      errorMessage: "",
      fileUrl:[],
      description:null,
      status:null,
      fileId:"",
      uploadLoading: false,
      previewLoading: false
    };

    const url = DocumentStore;

  }

  async componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });
      const url = `project-service/project/component/${this.props.component_id}`;
      const json = await send_request(url, "", {}).catch((err) => {
        throw Error(err.message);
      });
      if (json && json.data) {
        const components = json.data;
        if (components) {
          DocumentStore.setData(components);
          this.props.onComponentNameChanged(DocumentStore.name);
          this.props.onComponentDescriptionChanged(components.description);
          this.props.onLastModifiedChanged(DocumentStore.lastModified);
        }
      }

      // Check if aiComponentBuilder is given and if so, set the draft data
      if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
        this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
        this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
        DocumentStore.setTemplateData(this.props.aiComponentBuilderData.data);
        this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
        this.setState({ status: "AI_BUILD_GENERATED" });
      }

      this.setState({ 
        loading: false, 
        fileId: (DocumentStore.files && DocumentStore.files.length > 0) ? 
                  DocumentStore.files[0].id : 
                  this.state.fileId 
      });
      this.props.setChildTemplateLoaded(true);
      
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveDocumentEditorTemplate();
    this.reactToPlaceHolder();
  }

  saveDocumentEditorTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    async () => {
      if (this.state.checkKey) {
        this.props.showError();
        return;
      }

      let lastModified = getCurrentTime();
      let files = DocumentStore.template.files;
      let fileUrl = DocumentStore.template.fileUrl;
      if (DocumentStore.template.data.type !== "DOCX")
      {
        files = [];
      }
      if (DocumentStore.template.data.type !== "URL")
      {
        fileUrl = [];
      }
      let data = {
        componentData: {
          data: DocumentStore.template.data,
          name: toJS(this.props.ComponentName),
          files: files,
          fileUrl: fileUrl,
          lastModified: lastModified
        },
        componentId: this.props.component_id,
        type: DocumentStore.template.type,
        status: this.state.status,
        description: this.props.ComponentDescription.value
      };
      const json = await send_component_save_request(
        "component-service/document-editor/data",
        data,
        "",
        "POST",
        this.props.SaveTrigger
      ).catch((err) => {
        throw err;
      });

      if (json && json.status == 200) {
        this.props.onLastModifiedChanged(lastModified);
        this.props.showSuccess(data, this.props.SaveTrigger);
        projectStore.savedComponent = true;
      }
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key } = placeholder["selected"];
      this.insertPlaceholder(style.default, key, style, this.state.focus);
    }
  );

  insertPlaceholder = (label, text, style, focus) => {
    if (focus === "editor" || focus==="placeholder") {
      this.setState((prevState) => ({
        placeholders: [...prevState.placeholders, { label, text, style, focus }]
      }));
    } else {
      this.toInput(label, text, style, focus);
    }
  };

  onFilesDrop = async (files) => {
    if (this.props.project_id || this.props.projectId) {
      this.setState({
        uploadLoading: true
      });

      let formData = new FormData();
      formData.append("file", files[0]);
      if(this.props.project_id)
      {
          formData.append("projectId", this.props.project_id);
      }
      else {
          formData.append("projectId", this.props.projectId);
      }

      const json = await send_request(
        "component-service/document-editor/upload",
        formData,
        "",
        "POST",
        {
          "content-type": "multipart/form-data"
        }
      ).catch((err) => {
        throw err;
      });

      if (json && json.data) {
        let { fileName, id } = json.data;
        DocumentStore.setFiles([{ fileName, id }]);
        this.setState({
          fileId: id
        })

        this.setState({
          uploadLoading: false
        });
      }
    }
  };

  handleKeyChanges = (event) => {
    const { data } = toJS(DocumentStore);
    const value = checkKey(event, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
  };

  handleKeyChange = (e) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    DocumentStore.setContent(placeholderValue, "key");

    const { data } = toJS(DocumentStore);
    const value = checkKey(placeholderValue, data);

    this.setState({
      checkKey: value[0],
      errorMessage: value[1]
    });
  };
  handleChangeUrl = (chip) => {
    const { data } = toJS(DocumentStore);

    let key = chip.key;

    if (chip.isNotPlaceholder !== true) {
      key = "${" + key + "}";
    }
    let updateField;
   /* if(data.fileUrl!==undefined) //remove this to allow adding multiple placheolders
    {   updateField = [...data.fileUrl, key];}
    else*/
    {     updateField = [key];}

    this.setState({fileUrl:updateField})
    DocumentStore.setFileUrl(updateField);
  };

  render() {
    const { classes } = this.props;
    const { data, files,fileUrl } = toJS(DocumentStore);
    
    if (this.state && !this.state.loading) {
      return (
        <div className="editor-container">
          <div className={`${classes.wrap} document-wrapper-container`} name="documentWrapperContainer">
            <div className={classes.verticalContainer}>
              <div className="section">
                <div className="label">Placeholder</div>
                <div className="placeholder-input">
                  <W86PlaceholderTextField
                    value={data.key}
                    error={this.state.checkKey}
                    helperText={this.state.checkKey ? this.state.errorMessage : null}
                    placeholderText="Enter placeholder"
                    handleChange={this.handleKeyChange}
                  />
                  <Tooltips
                    Title="Output will be file URL of the generated document"
                    Children={<HelpOutlineOutlinedIcon className={classes.customIcon} />}
                  />
                </div>
              </div>

              <div className="section">
                <div className="label">Create a document by</div>
                <RadioGroup
                  className="radio-group"
                  name="selectType"
                  value={data.type}
                  onChange={(event) => {
                    this.setState({ loading: true });
                    DocumentStore.setContent(event.target.value, "type");
                    this.setState({ loading: false });
                  }}
                  row // This makes the radio buttons horizontal
                >
                  <FormControlLabel value="EDITOR" control={<Radio />} label="built-in editor" />
                  <FormControlLabel value="DOCX" control={<Radio />} label="uploading .docx template" />
                  <FormControlLabel value="URL" control={<Radio />} label="via placeholder" />
                </RadioGroup>
              </div>

              <div className="section">
                <div className="label">Output format</div>
                <RadioGroup
                  className="radio-group"
                  name="selectType"
                  value={data.export}
                  onChange={(event) => {
                    this.setState({ loading: true });
                    DocumentStore.setContent(event.target.value, "export");
                    this.setState({ loading: false });
                  }}
                  row // This makes the radio buttons horizontal
                >
                  <FormControlLabel value="DOCX" control={<Radio />} label=".docx" />
                  <FormControlLabel value="PDF" control={<Radio />} label=".pdf" />
                </RadioGroup>
              </div>
            </div>
          </div>
          {data.type == "EDITOR" ? (
            <div className="text-editor">
              <DocumentEditor
                availablePlaceholders={this.props.availablePlaceholders}
                html={DocumentStore.data.content}
                onChange={(html) => {
                  DocumentStore.setContent(html, "content");
                }}
                placeholder={this.state.placeholders}
                onFocus={() => {
                  this.setState({ focus: "editor" });
                }}
                isExpanded ={this.props.isExpanded}
                documentEditor
              />
            </div>
          ) : ( data.type=="DOCX"?
            <div className={classes.wrap}>
              <h4 className="upload-title">
                Upload a .docx template
              </h4>
              <div className="upload-info">
                <span>
                  Generate documents by merging placeholders into an uploaded
                  .docx template.{" "}
                  <a
                    href="https://docs.workflow86.com/docs/components/doc_editor#generate-documents-using-a-docx-template"
                    target="_blank"
                    className="a-href"
                  >
                    Learn more here
                  </a>
                </span>
              </div>
              <div className={classes.dropzone}>
                {this.state.uploadLoading ? (
                  <div className="upload-loading">
                    <CircularProgress size={24} className="upload-loading-spinner" />
                    <span>Uploading file...</span>
                  </div>
                ) : (
                  <div>
                    <Dropzone
                      style={{ width: "100%" }}
                      multiple={false}
                      onDrop={(acceptedFiles) =>
                        this.onFilesDrop(acceptedFiles)
                      }
                      accept={".docx,.doc"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()} style={{}}>
                            <DropZone />
                            <input type="file" {...getInputProps()} />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <div className="file-upload-container">
                      {files && files.length > 0 && (
                        <Chip
                          className={classes.chipStyle}
                          label={
                            <span className="chip-container">
                              {decodeURIComponent(files[0].fileName)}
                            </span>
                          }
                          onDelete={() => {
                            DocumentStore.setFiles([]);
                            this.setState({
                              uploadLoading: false,
                              files: "",
                              fileId: ""
                            });
                          }}
                          onClick={() => {
                            const url =
                              `${fileService.QUERY_URL}` +
                              "/" +
                              DocumentStore.files[0].id;
                            window.location.href = url;
                          }}
                          color="primary"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
                  :
              <div className="file-placeholder-upload">
                <DocumentFilePlaceholderUpload
                    availablePlaceholders={this.props.availablePlaceholders}
                    onPlaceholderSelected={this.handleChangeUrl}
                    placeholder={this.state.placeholders}
                    onFocus={() => {
                      this.setState({ focus: "placeholder" });
                    }}
                    defaultValues={(toJS(fileUrl))}
                />
              </div>
            )}
            {data.type === "DOCX" && files[0] && !this.state.loading && this.state.fileId && !this.state.uploadLoading &&(
            <div>
                              
                <DocumentPreview
                    filePath={this.state.fileId} 
                />
            </div>
            )}
            
        </div>
      );
    } else return <IndividualComponentLoader />;
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(withStyles(useStyles)(DocumentTemplate)))
);