import React from "react";

// MUI
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { getColourFromString } from "../../../utils/ColourNameGenerator";

const MyChip = styled(Chip)(({ colour }) => ({
    border: `1px solid ${colour.border}`,
    backgroundColor: colour.bg,
    boxSizing: "border-box",
    borderRadius: "4px",
    marginRight: "6px",
}));

const DBManagementRenderChips = ({ textAreaProps }) => {
    return (
        <>
            {textAreaProps.value.map((val) => {
                return <MyChip label={val} colour={getColourFromString(val)} />;
            })}
        </>
    );
};

export default DBManagementRenderChips;
