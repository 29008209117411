import React, { Component } from "react";
import "./MarketplaceTemplate.css";
import CanvasPreview from "../ProjectCanvas/Canvas/CanvasPreview";
import { observer, inject } from "mobx-react";
import MarketplaceTemplateNavBar from "./MarketplaceTemplateNavBar";

class MarketplaceTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      download: false,
      reactFlowInstance: null,
    };
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <CanvasPreview className={"embed-tag"} />
      </div>
    );
  }
}
export default inject("PreviewStore")(observer(MarketplaceTemplate));
