import React from 'react'
import { styled } from '@mui/system'
import { Alert } from '@mui/material'
import { Error, Verified } from '@mui/icons-material'

const StyledAlert = styled(Alert)(({ borderColor, backgroundColor }) => ({
    border: `solid 1px ${borderColor}`,
    backgroundColor,
    color: "black"
}))

const renderStyle = (alertType) => {
  switch(alertType) {
    case "alert":
      return {
        icon: <Error sx={{ color: "rgba(255, 183, 77, 1)" }}/>,
        borderColor: "rgba(255, 183, 77, 1)",
        backgroundColor: "rgba(255, 234, 203, 1)"
      }
    case "success":
      return {
        icon: <Verified sx={{ color: "rgba(85, 167, 122, 1)" }}/>,
        borderColor: "rgba(85, 167, 122, 1)",
        backgroundColor: "rgba(85, 167, 122, 0.2)"
      }
  }
}

const AlertMessage = ({
    message,
    alertType = "alert",
    style,
  }) => {
  
  return (
    <StyledAlert
      {...renderStyle(alertType)}
      style={{...style}}
    >
      {message}
    </StyledAlert>
  )
}

export default AlertMessage