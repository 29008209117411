import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export function format(date, zone, format) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativeTime);

  date = String(date);

  if (date.includes("[UTC]")) date = date.substring(0, date.indexOf("[UTC]"));

  let utcCustomDate = new Date(date);

  if (date && date.includes && !date.includes("T")) {
    utcCustomDate = new Date(
      Date.UTC(
        utcCustomDate.getFullYear(),
        utcCustomDate.getMonth(),
        utcCustomDate.getDate(),
        utcCustomDate.getHours(),
        utcCustomDate.getMinutes(),
        utcCustomDate.getSeconds()
      )
    );
  }

  if (zone==null)
    zone = "Australia/Hobart"; //this is to catch errors with timezone for google accounts
  
    let timezonedDate = dayjs(new Date(Date.parse(utcCustomDate))).tz(zone);

  if (format === "relative") return toRelative(timezonedDate);
  if (format === "timeline") return print_timeline(timezonedDate)
  else return print_pretty(timezonedDate);
}

function print_pretty(date) {
  return dayjs(date).format("DD MMMM YYYY HH:mm");
}

function print_timeline(date) {
  return dayjs(date).format("DD MMMM YYYY") + "\n" + dayjs(date).format("HH:mm")
}

export function print_timeline_date(date) {
  return dayjs(date).format("DD MMMM YYYY");
}

function toRelative(date) {
  return dayjs(date).fromNow();
}

export function print_date_only(date) {
  return dayjs(date).format("YYYY-DD-MM");
}

export function print_time_only(date) {
  return dayjs(date).format("HH:mm:ss");
}

// Converts a utc datetime iso string
// To a date in format YYYY-MM-DD
export function getFormattedDateFromUTC(givenDate, timezone) {
  try {
    let formatted = new Date(format(givenDate, timezone));
    
    let month = formatted.getMonth() + 1;
    if (month < 10) month = "0" + month;

    let day = formatted.getDate();
    if (day < 10) day = "0" + day;

    let date = `${formatted.getFullYear()}-${month}-${day}`;
    return date;
  } catch (e) {
    return givenDate.split("T")[0];
  }
}
