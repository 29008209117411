import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { TextField } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

// Grids
export const Container = styled(Grid)({
  width: "100%",
  padding: "24px",
  backgroundColor: "white",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px"
});

export const PlaceholderContainer = styled(Grid)({
  maxWidth: "1000px"
});

export const NameContainer = styled(Grid)({
  marginBottom: "16px"
});

export const InsideGrid = styled(Grid)({
  maxWidth: "75px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const PlaceholderRow = styled(Grid)({
  marginBottom: "8px"
});

export const Header = styled("h1")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#000"
});

// Divs //
export const InputLabel = styled("div")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#000",
  margin: "18px 0px 8px 0px"
});

export const PlaceholderTextField = styled(TextField)(() => ({
  fontSize: "14px",
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#FAFAFA",
  }
}));

// Spans //
export const BlueText = styled("span")({
  fontSize: "14px",
  color: "#2196f3",
  margin: "0px 3px",
  "&:hover": {
    color: "rgba(33, 150, 243, 0.75)"
  },
});

export const BlueTextCenter = styled("span")({
  fontSize: "14px",
  color: "#2196f3",
  margin: "0px 3px",
  "&:hover": {
    color: "rgba(33, 150, 243, 0.75)"
  },
  display: "flex",
  alignItems: "center"
});

export const InfoLabel = styled("span")({
  margin: "8px",
  fontSize: "12px"
});

export const BoldText = styled("span")({
  fontWeight: "bold"
});

export const PlaceholderHeader = styled("span")({
  fontSize: "14px",
  lineHeight: "16px"
});

export const ConnectionText = styled("span")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#343434",
});

export const OpenWorkflowText = styled("span")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#343434",
  display: "flex",
  alignItems: "center",
  marginTop: "16px",
});

export const ErrorText = styled("div")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#b00020",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "24px"
});

export const StyledErrorIcon = styled(ErrorIcon)({
  color: "#b00020",
  marginRight: "8px"
});

// Icons //
export const StyledArrow = styled(ArrowRightAltIcon)({
  color: "#757575"
});

export const StyledOpenInNewIcon = styled(OpenInNewIcon)({
  width: "15px",
  height: "15px",
  marginRight: "4px"
});

// Buttons //
export const StyledButton = styled(Button)({
  backgroundColor: "#2196F3",
  color: "rgba(255, 255, 255, 0.87)",
  fontSize: "14px",
  lineHeight: "18px",
  padding: "8px 20px",
  marginTop: "24px",
  "&:hover": {
    backgroundColor: "rgba(33, 150, 243, 0.9)",
    color: "rgba(255, 255, 255, 0.75)"
  },
  "&:disabled": {
    color: "rgba(0, 0, 0, 0.26)",
    backgroundColor: "rgba(0, 0, 0, 0.05);"
  }
});

// Autocompletes //
export const Dropdown = styled(Autocomplete)({
  maxWidth: "650px",
  backgroundColor: "#fff !important"
});
