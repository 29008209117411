import React, { Component } from "react";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import { observer, inject } from "mobx-react";
import { decorate, observable, action, reaction, toJS } from "mobx";
import { Snackbar } from "@mui/material";
import { send_request } from "../../utils/Request";
import _ from "lodash";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextEditor from "../TextEditor/TextEditor";
import PageEditor from "../TextEditor/PageEditor";
import styles from "./style";
import Switch from "@mui/material/Switch";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Alert from "@mui/material/Alert";
import DocumentEditor from "../TextEditor/DocumentEditor";
import { withParams } from "../../Routes";
class Readme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      edit: false,
      readme: null,
      saved: false,
      focus: null,
      showReadmeDialog: false,
    };
  }

  async componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { id } = this.props.params;
    if (id) {
      const json = await send_request(
        `project-service/project/${id}`,
        "",
        {}
      ).catch((err) => {
        throw Error(err.message);
      });
      if (json && json.data) {
        const { name, extras } = json.data;
        let showReadmeDialog = false;
        if (extras) {
          if (extras.showReadmeDialog !== undefined) {
            showReadmeDialog = extras.showReadmeDialog;
          }
        }
        this.setState({
          name: name ? name : "Untitled",
          readme: extras
            ? extras.readme
            : "<p>👋 Welcome to the Read Me page for this workflow. </p><p></p><p>A Read Me is where you can communicate important information about your workflow. This can include what the workflow does, how users can get started and instructions on using the workflow or setting it up. You can edit the Read Me by clicking Edit in the top right. </p>",
          showReadmeDialog: showReadmeDialog,
        });
      }
    }
  };

  goBack = () => {
    this.props.navigate(`/project/canvas/${this.props.params.id}`);
  };

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  focus = () => {
    this.editor.focus();
  };

  show = () => {
    this.setState({ edit: true });
  };

  exitEdit = () => {
    this.setState({ edit: false });
  };

  save = async (showReadmeDialog, showSavedDialog) => {
    const { id } = this.props.params;
    const data = {
      readme: this.state.readme,
      showReadmeDialog: showReadmeDialog,
    };
    const json = await send_request(
      `project-service/project/${id}/readme`,
      data,
      "",
      "POST"
    ).catch((err) => {
      throw err;
    });
    if (json && json.status == 200) {
      if (showSavedDialog) {
        this.setState({ saved: true });
      }
    }
  };

  handleChange = () => {
    let showReadmeDialog = !this.state.showReadmeDialog;
    this.setState({
      showReadmeDialog,
    });
    this.save(showReadmeDialog, false);
  };

  render() {
    const { classes } = this.props;
    let { name, readme, edit, showReadmeDialog } = this.state;
    const { id } = this.props.params;
    return (
      <div>
        <Snackbar
          open={this.state.saved}
          onClose={() => {
            this.setState({ saved: false });
          }}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            icon={<></>}
            onClose={() => {
              this.setState({ saved: false });
            }}
            variant={"filled"}
            className={"saveSuccess"}
            severity="success"
          >
            👍 Your changes were saved
          </Alert>
        </Snackbar>

        <Grid className="backButtonAndHeader">
          <Grid container direction="row" alignItems="center" xs={12}>
            <ArrowBackIcon onClick={this.goBack} className="arrow_back" />
            <h3 className="top_page_title">{name} Read Me</h3>
          </Grid>
        </Grid>
        <div className="readme-main" style={{ padding: "10px 30px" }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ paddingBottom: "50px" }}
          >
            <Grid item>
              {/* <h3 style={{ margin: 0 }}>{name} Read Me</h3> */}
            </Grid>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Switch
                checked={showReadmeDialog}
                onChange={this.handleChange}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <Grid item className={classes.readMeText}>
                Auto-Open Read Me
              </Grid>

              {edit ? (
                <>
                  <Button
                    onClick={this.exitEdit}
                    startIcon={<KeyboardBackspaceIcon />}
                    size={"large"}
                    variant={"outlined"}
                    color={"info"}
                    style={{ marginRight: 16 }}
                  >
                    EXIT EDIT
                  </Button>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"large"}
                    onClick={() => this.save(showReadmeDialog, true)}
                  >
                    SAVE
                  </Button>
                </>
              ) : (
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size="large"
                  onClick={this.show}
                  startIcon={<EditIcon />}
                >
                  EDIT
                </Button>
              )}
            </Grid>
          </Grid>
          {edit ? (
            <div className="textEditor">
              <DocumentEditor
                contentStyles={{ minHeight: "297mm" }}
                html={readme}
                onChange={(html) => {
                  this.setState({ readme: html });
                }}
                availablePlaceholders={[]}
                placeholder={[]}
                onFocus={() => {}}
                readMe
                disableTooltip
              />
            </div>
          ) : (
            <PageEditor html={readme} />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withParams(Readme));
