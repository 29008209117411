import React from 'react';
import { SvgIcon } from '@mui/material';

function TextIcon(props) {
  return (
    <SvgIcon viewBox="0 -5 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.875 0V2.25H4.625V11.25H6.875V2.25H10.625V0H0.875ZM15.125 3.75H8.375V6H10.625V11.25H12.875V6H15.125V3.75Z" fill="#868686"/>
      </svg>
    </SvgIcon>
  );
}

export default TextIcon;