import React from "react";

// MUI
import { ArrowBack } from "@mui/icons-material";
import { Grid, Skeleton, styled, TextField } from "@mui/material";
import { StyledSkeleton } from "../../../VersionHistory/VersionHistoryStyledComponents";

const UneditableText = styled("span")({
    color: "#212121",
    fontSize: "16px",
    fontWeight: 400,
});

const BackGrid = styled(Grid)({
    paddingRight: "8px",
    width: "fit-content",
    minWidth: "20px",
});

function BackAndWorkflowName({ showUneditableWorkflowName, isCanvasLocked, workflowName, setWorkflowName, handleSaveProjectName, handleBack, showSkeleton }) {
    return (
        <Grid container display="flex" alignItems="center" justifyContent="center">
            <BackGrid item>
                <ArrowBack
                    className={`arrow_back back ${isCanvasLocked && "canvas-locked-back"}`}
                    onClick={() => {
                        if (isCanvasLocked) return;
                        handleBack();
                    }}
                />
            </BackGrid>
            {showSkeleton ? (
                <StyledSkeleton height={30} width={400} variant={"rectangular"} />
            ) : (
                <Grid item>
                    {!showUneditableWorkflowName ? (
                        <TextField
                            variant={"standard"}
                            fullWidth={true}
                            InputProps={{ disableUnderline: true, classes: { root: "textinput" } }}
                            sx={{ width: "130px", marginRight: "8px" }}
                            placeholder={"Untitled"}
                            value={workflowName}
                            onChange={(event) => {
                                setWorkflowName(event.target.value);
                            }}
                            onBlur={(event) => {
                                handleSaveProjectName(event.target.value);
                            }}
                            disabled={isCanvasLocked}
                        />
                    ) : (
                        <UneditableText>{workflowName}</UneditableText>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export default BackAndWorkflowName;
