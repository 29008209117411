export const NoteRegistry = [
  {
    name: "sticky_note",
    displayName: "Sticky note",
    description: "Sticky note for text and comments",
    logo: "/svgs/sticky_note.svg"
  },
  {
    name: "small_note",
    displayName: "Small note",
    description: "Small note for short titles and headers",
    logo: "/svgs/small_note.svg"
  },
  {
    name: "large_note",
    displayName: "Large note",
    description: "Larger sticky note for longer text and content",
    logo: "/svgs/large_note.svg"
  },
  {
    name: "full_page",
    displayName: "Full page",
    description: "A full size page for notes and documentation",
    logo: "/svgs/full_page.svg"
  },
  {
    name: "swimlane",
    displayName: "Swimlane",
    description: "Create a swimlanes to identify specific roles or teams",
    logo: "/svgs/swimlane.svg"
  },
  {
    name: "map_a_zap",
    displayName: "Map a Zap",
    description: "Map all steps in a Zap from Zapier as notes",
    logo: "/svgs/map_a_zap.svg"
  }
];

export default NoteRegistry;

function createFactory(NoteRegistry) {
  const note_factory = {};
  NoteRegistry.forEach(note => {
    note_factory[note.name] = {
      logo: note.logo,
      description: note.description,
      displayName: note.displayName
    };
  });
  return note_factory;
}

export const NoteFactory = createFactory(NoteRegistry);
