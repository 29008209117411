import React, { useEffect, useState } from "react";
import "./ForceStart.css";

// MUI
import {Grid, styled, TextField, Autocomplete, Chip} from "@mui/material";

// Util
import ExistingSessionInfo from "./ExistingSessionInfo";
import { send_request } from "../../../../utils/Request";

const StyledAutocomplete = styled(Autocomplete)({
    backgroundColor: "#FFF !important",
    minWidth: "50%",
});

const StyledTextField = styled(TextField)({
    backgroundColor: "#FFF !important",
    width: "100%%",
});

const Text = styled("span")(({ textColor }) => ({
    fontSize: "14px",
    color: textColor,
}));

function SelectSessionDropdown({ projectId,
                                   StyledSkeleton, setValues, placeholders, setIsLoading, setLoadingInputPHs,
                                   isTest, version, selected, setSelected }) {
    const [sessionIds, setSessionIds] = useState(null);
    //Added this for now. Selected wants to take in Array of strings (sessionIds) but the AutoComplete wants to take in array of objects
    //Made a second hook for now until figure out a better solution
    const [selectedSessionObject, setSelectedSessionObject] = useState([]);

    useEffect(async () => {
        setSelected([]);
        setValues(null);

        // Fetch all the existing session ids linked to the given project id
        let url = `project-service/project-session/get_recent_existing_session_ids/${projectId}`;
        if (isTest) url += "?includeTestSessions=true";

        let json = await send_request(url, null, null, "GET");
        let dropdownTitleArray = [];
        const responseProjectSessions = json?.data
        if (!json || !responseProjectSessions) {
            setSessionIds(dropdownTitleArray);
            return;
        } else {
            dropdownTitleArray = responseProjectSessions;
        }
        setSessionIds(dropdownTitleArray);

    }, []);


    // Function for fetching existing placeholder data values and setting
    // It into the state
    const handleFetchExisting = async (selectedId) => {
        if (!selectedId) return;

        // Set loading to true
        setIsLoading(true);
        setLoadingInputPHs(true);

        const url = `project-service/project-session/get_existing_session_placeholder_data/${selectedId}`;
        let json = await send_request(url, placeholders, "", "POST");

        if (!json || !json.data) {
            setIsLoading(false);
            setLoadingInputPHs(false);
            return;
        }

        // Transform the response data to maintain the correct structure
        const transformedValues = {};
        for (const key in json.data) {
            const placeholder = placeholders.find(p => p.key === key);
            if (placeholder) {
                const placeholderType = placeholder.type;
                const placeholderData = json.data[key];
                transformedValues[key] = {
                    type: placeholderType,
                    value: placeholderType === 'datetime'
                        ? {
                            rawValue: placeholderData?.rawValue || null,
                            format: "yyyy-MM-DD hh:MM:ssZ",
                            value: placeholderData?.value || ""
                        }
                        : placeholderData
                };
            }
        }

        // Set the transformed values
        setValues(transformedValues);
        setIsLoading(false);
        setLoadingInputPHs(false);

        return transformedValues;
    };

    const handleSelectionChange = (event, newValue) => {
        const newSessionIdList = newValue.map(session => session?.sessionId);

        setSelectedSessionObject(newValue);
        setSelected(newSessionIdList); // Pass the entire session objects instead of just IDs

        //Update the values when there is only one selected
        if (newValue.length === 1) {
            handleFetchExisting(newValue[0].sessionId);
        } else {
            setValues(null);
        }
    };

    return sessionIds ? (
        <Grid container rowGap={2} marginBottom={1}>
            <Grid item xs={12}>
                <Grid container rowGap={2}>
                    <Grid item xs={12}>
                        <b>Select session</b>
                    </Grid>
                    <Grid item xs={12}>
                        <Text>Use the placeholder values from an existing session to run this workflow</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledAutocomplete
                            className="force-start-autocomplete"
                            multiple
                            freeSolo
                            disableCloseOnSelect
                            variant="standard"
                            options={sessionIds}
                            getOptionLabel={(option) => {
                                if (!option) return "";
                                return `${option?.sessionId} (${option?.timeAgo})`
                            }}
                            onChange={handleSelectionChange}
                            value={selectedSessionObject}
                            renderInput={(params) => <StyledTextField {...params} size="small" placeholder="Type in or search for session IDs" />}
                            renderTags={(value, getTagProps) =>
                                value.map((selectedOption, index) => {
                                    return (
                                        <Chip
                                            label={selectedOption.sessionId}
                                            {...getTagProps({ index })}
                                        />
                                    );
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            {selected?.length > 0 && (
                <Grid item xs={12}>
                    {selected.map((sessionId) => (
                        <ExistingSessionInfo 
                            key={sessionId}
                            selected={sessionId} 
                            projectId={projectId} 
                            version={version} 
                        />
                    ))}
                </Grid>
            )}
        </Grid>
    ) : (
        <StyledSkeleton variant="rectangular" height="35px" />
    );
}

export default SelectSessionDropdown;
