import React, { useState } from "react";

// mui
import { Button, Popover, styled, Tooltip, Typography } from "@mui/material";

// icons
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";

const DiscardButton = styled(Button)({
    textTransform: "unset !important",
    color: "black",
    border: "1px solid transparent",
    "&:hover": {
        color: "rgba(225, 0, 0, 1)",
        border: "1px solid rgba(225, 0, 0, 1)",
        background: "#FFF",
    },
});

const ConfirmText = styled(Typography)({
    fontSize: "12px",
    padding: "6px 8px",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(0, 0, 0, 0.85)",
    },
});

function DiscardChangesButton({ disabled }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleDiscardChanges = () => {
        window.location.reload();
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title="Discard all unsaved changes and re-load this component" placement="bottom">
                <DiscardButton size={"small"} startIcon={<DeleteSweepOutlinedIcon />} disabled={disabled} onClick={(e) => setAnchorEl(e.currentTarget)}>
                    Discard changes
                </DiscardButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <ConfirmText onClick={handleDiscardChanges}>Confirm discard changes</ConfirmText>
            </Popover>
        </>
    );
}

export default DiscardChangesButton;
