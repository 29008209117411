import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import ReactJson from "react-json-view";
import { send_request } from "../../../../../utils/Request";
import Schema from "../../../../WorkflowSchema/Schema";
import {Error} from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function AITranslationComponent() {
    const [lookup, setLookup] = useState("");
    const [data, setData] = useState(null);
    const [userMarkedSkip,setUserMarkedSkip] = useState(false);

    const [loading, setLoading] = useState(false);

    const sendRequest = async () => {
        setLoading(true);

        let requestBody = {
            "isUpdate":true,
            "userMarkedSkip":userMarkedSkip
        }

        const json = await send_request(
            `project-service/project-ai/assistant/getExistingComponentData/${lookup}`,
            requestBody,
            "",
            "POST"
        ).catch((err) => {
            throw Error(err.message);
        });

        console.log(json);
        if (json && json.data && json.data !== data) setData(json.data);
        setLoading(false);
    };
    const handleSkipChange = (event) => {
        setUserMarkedSkip(event.target.checked);
    };

    return (
        <Grid container padding="32px" spacing={3}>
            <Grid item xs={12}>
                <h3>Component to AI Translation</h3>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    placeholder="Enter component id"
                    variant="filled"
                    disabled={loading}
                    value={lookup}
                    style={{ width: "400px", marginRight: "16px" }}
                    onChange={(e) => setLookup(e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={userMarkedSkip}
                            onChange={handleSkipChange}
                            color="primary"
                        />
                    }
                    label="Skip Update"
                />

                <Button variant="contained" onClick={sendRequest} disabled={loading}>
                    Lookup
                </Button>
            </Grid>
            {data && (
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} spacing={1}>
                            Component for AI Translation :
                        </Grid>
                        <Grid item xs={12}>
                            <ReactJson
                                src={data}
                                name="Data"
                                iconStyle="square"
                                displayDataTypes={false}
                                collapsed={true}
                                theme={{
                                    base00: "rgba(1, 1, 1, 0)",
                                    base01: "rgba(1, 1, 1, 0.1)",
                                    base02: "rgba(0, 0, 0, 0.2)",
                                    base03: "rgba(1, 1, 1, 0.3)",
                                    base04: "rgba(0, 0, 0, 0.4)",
                                    base05: "rgba(1, 1, 1, 0.5)",
                                    base06: "rgba(1, 1, 1, 0.6)",
                                    base07: "rgba(1, 1, 1, 0.7)",
                                    base08: "rgba(0, 0, 0, 1)",
                                    base09: "rgba(0, 0, 0, 1)",
                                    base0A: "rgba(0, 0, 0, 1)",
                                    base0B: "rgba(0, 0, 0, 1)",
                                    base0C: "rgba(0, 0, 0, 1)",
                                    base0D: "rgba(0, 0, 0, 1)",
                                    base0E: "rgba(1, 1, 1, 0.7)",
                                    base0F: "rgba(1, 1, 1, 0.7)",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default AITranslationComponent;
