import { computed, observable, decorate, action, toJS } from "mobx";

class TriggerAnotherWorkflowStore {
  constructor() {
    this.initialiseEmpty();
  }

  setTriggerAnotherWorkflowTemplate = data => {
    this.trigger_another_workflow_template_id = data.componentId;
    this.trigger_another_workflow_name = data.componentData.name;
    this.trigger_another_workflow_targetProjectId =
      data.componentData.targetProjectId;
    this.trigger_another_workflow_targetComponentId =
      data.componentData.targetComponentId;
    this.trigger_another_workflow_savedPlaceholders =
      data.componentData.savedPlaceholders;
    this.template_data = data.componentData.data;
    this.lastModified = data.componentData.lastModified;
  };

  setTriggerAnotherWorkflowName = triggerAnotherWorkflowName => {
    this.trigger_another_workflow_name = triggerAnotherWorkflowName;
    this.template_data.name = triggerAnotherWorkflowName;
  };

  initialiseEmpty = () => {
    this.trigger_another_workflow_template_id = "";
    this.client_id = "000001";
    this.trigger_another_workflow_name = "";
    this.lastModified = "";
    this.template_data = {
      targetProjectId: "",
      targetComponentId: "",
      savedPlaceholders: []
    };
  };

  get template() {
    return {
      componentId: this.trigger_another_workflow_template_id,
      lastModified: this.lastModified,
      componentData: toJS(this.template_data)
    };
  }
}

decorate(TriggerAnotherWorkflowStore, {
  trigger_another_workflow_template_id: observable,
  client_id: observable,
  lastModified: observable,
  trigger_another_workflow_name: observable,
  template_data: observable,
  setTriggerAnotherWorkflowTemplate: action,
  setTriggerAnotherWorkflowName: action,
  initialiseEmpty: action,
  template: computed
});

const triggerAnotherWorkflowStore = new TriggerAnotherWorkflowStore();
export default triggerAnotherWorkflowStore;
