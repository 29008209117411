import React from "react";
import { Fade } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const ProcessDiscoveryMessage = ({ classes }) => {
  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <p>
          Welcome, to a new process discovery session. Here are some of the ways
          I can help.
        </p>
        <b> 📝 Document or map an existing process</b>
        <p>
          Describe a business process or workflow and I can map and document it
          on the canvas using notes.
        </p>
        <b>↔️ Expand a starting point into a workflow</b>
        <p>
          Describe a starting point for a workflow or an existing step in a
          workflow, and I can expand it out with additional steps and actions
          e.g. “customer onboarding”
        </p>
        <b> 🔍 Discover and extract processes </b>
        <p>
          Provide me with any information that contains processes such as a
          transcript, email chain or the the contents of a document and I can
          extract any processes from that and map them on the canvas.
        </p>
      </div>
    </Fade>
  );
};

ProcessDiscoveryMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(AIBuilderStyles())(ProcessDiscoveryMessage);
