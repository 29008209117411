import React, { useState, useEffect } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Popover, styled } from "@mui/material";
import { Close, Link, LinkOff, Refresh } from "@mui/icons-material";
import Draggable from "react-draggable";
import ConnectedDBs from "./ConnectedDBs";
import CreatedButNotConnectedDBs from "./CreatedButNotConnectedDBs";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import W86FloatingPane from "../../../W86Components/W86FloatingPane";
import { send_request } from "../../../../utils/Request";

const TextButton = styled(Button)({
    textTransform: "unset !important",
    color: "black",
});

function ViewDatabasesModal({ openViewDatabasesDialog, closeDialog, projectId, draftVersion }) {
    const [refresh, setRefresh] = useState(0);
    const [connectedDBs, setConnectedDBs] = useState([]);
    const [loading, setLoading] = useState(false);

    const [createdButNotConnectedDBs, setCreatedButNotConnectedDBs] = useState([]);

    useEffect(async () => {
        setLoading(true);

        const res = await Promise.all([fetchDBs(), fetchCreatedButNotConnectedDBs()]);

        const connectedJson = res[0];
        const notConnectedJson = res[1];

        let connected = [];
        let connectedDBIdSet = new Set();

        if (connectedJson && connectedJson.data) {
            connected = connectedJson.data;

            for (let db of connected) {
                connectedDBIdSet.add(db.databaseId);
            }

            setConnectedDBs(connectedJson.data);
        }

        let createdNotConnected = [];
        if (notConnectedJson && notConnectedJson.data) createdNotConnected = notConnectedJson.data;

        let finalCreatedButNotConnected = [];
        let dbsToRemove = [];

        // Go over each from the request and if it appears in the connected list, remove it
        for (let db of createdNotConnected) {
            if (connectedDBIdSet.has(db.databaseId)) {
                // Need to remove
                dbsToRemove.push(db.databaseId);
            } else {
                // Just add to list
                finalCreatedButNotConnected.push(db);
            }
        }

        await removeDBLinks(dbsToRemove); // Remove dbs

        setCreatedButNotConnectedDBs(finalCreatedButNotConnected);
        setLoading(false);
    }, [refresh]);

    const removeDBLinks = async (dbsToRemove) => {
        if (!dbsToRemove || dbsToRemove.length <= 0) return;

        let removeURL = `project-service/project/delete_database_links/${projectId}/${draftVersion}`;

        return await send_request(removeURL, dbsToRemove, null, "DELETE");
    };

    const fetchDBs = async () => {
        // Make request
        let url = `project-service/project/get_connected_databases/${projectId}`;
        return await send_request(url, null, null, "GET");
    };

    const fetchCreatedButNotConnectedDBs = async () => {
        // Make request
        let url = `project-service/project/get_created_but_not_connected_databases/${projectId}`;
        return await send_request(url, null, null, "GET");
    };

    return (
        <W86FloatingPane
            defaultPosition={{ x: 10, y: 100 }}
            topButtons={
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container display="flex" alignItems="flex-end" justifyContent="flex-end">
                            <Grid item>
                                <TextButton size={"small"} startIcon={<Refresh />} onClick={() => setRefresh(refresh + 1)}>
                                    Refresh
                                </TextButton>
                            </Grid>
                            <Grid item>
                                <TextButton size={"small"} startIcon={<Close />} onClick={closeDialog}>
                                    Close
                                </TextButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            content={
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ConnectedDBs loading={loading} setLoading={setLoading} connectedDBs={connectedDBs} setConnectedDBs={setConnectedDBs} />
                    </Grid>
                    <Grid item xs={12}>
                        <CreatedButNotConnectedDBs
                            projectId={projectId}
                            draftVersion={draftVersion}
                            loading={loading}
                            setLoading={setLoading}
                            connectedDBs={connectedDBs}
                            createdButNotConnectedDBs={createdButNotConnectedDBs}
                            setCreatedButNotConnectedDBs={setCreatedButNotConnectedDBs}
                            removeDBLinks={removeDBLinks}
                        />
                    </Grid>
                </Grid>
            }
        />
    );
}

export default ViewDatabasesModal;
