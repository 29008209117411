import React, { useState, useEffect } from "react";
import { CustomDialog } from "../../../Component/Dialog";
import PropTypes from "prop-types";
import { Button, Box, CircularProgress } from "@mui/material";
import CustomAutocomplete from "../../../ProjectCanvas/WorkflowBuilderComponents/CustomAutocomplete";
import ComponentRegistry from "../../../ProjectCanvas/ComponentRegistry";
import { send_request } from "../../../../utils/Request";
const FORMS = ["sequential_form", "form_section", "form"];
import { withStyles } from "@mui/styles";

const styles = () => ({
  error: {
    background: "rgba(176, 0, 32, 0.1)",
    border: " 1px solid #B00020",
    borderRadius: "4px",
    padding: "0px 8px 0px 8px",
    margin: "16px 0px 0px 0px"
  },
  box: {
    backgroundColor: "#F8F8F8",
    paddingLeft: "16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  image: { height: "20px", width: "20px", marginRight: "10px" }
});
const ConvertFormDialog = ({
  component,
  open,
  classes,
  handleClose,
  projectId,
  componentId,
  handleComponentUpdated
}) => {
  const [changeTo, setChangeTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleCancel = () => {
    handleClose();
    setChangeTo(null);
  };

  const handleConvert = async () => {
    setLoading(true);
    let body = {
      projectId: projectId,
      type: changeTo.name,
      componentId: componentId
    };

    await send_request(`component-service/convert/${component.type}/into/${body.type}`, body, "", "POST")
      .then((res) => {
        //returns new type of the component in question
        handleComponentUpdated(res.data, changeTo);
        setLoading(false);
        setChangeTo(null);
      })
      .catch((e) => {
        console.log(e);
      });
    handleClose();
  };

  const handleChange = (value) => {
    setChangeTo(value);
    if (value != null && component.type == "sequential_form") {
      setError(true);
    } else setError(false);
  };
  return (
    <CustomDialog
      isOpen={open}
      title={"Convert component"}
      contents={
        <>
          <p className={"s-text"}>Convert this component from</p>
          <Box
            className={classes.box}
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          >
            <img src={component.logo} className={classes.image} />
            <p>{component.displayName}</p>
          </Box>
          <p className={"s-text"}>To</p>
          <CustomAutocomplete
            convertForm={true}
            inputValue={changeTo}
            components={ComponentRegistry.filter(
              (comp) =>
                FORMS.indexOf(comp.name) !== -1 && comp.name != component.type
            )}
            handleChange={handleChange}
          />
          {error && (
            <div className={classes.error}>
              <p className={"error-text"}>
                Converting an Assign Task to a Form or Form Section will discard
                all task settings (assigned users, task subject, task
                description, reminder email etc)
              </p>
            </div>
          )}
        </>
      }
      buttons={
        <>
          <Button
            variant={"outlined"}
            onClick={() => handleCancel()}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || !changeTo}
            variant={"contained"}
            color={"primary"}
            onClick={() => handleConvert()}
          >
            {loading && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
            Convert
          </Button>
        </>
      }
    />
  );
};

ConvertFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  components: PropTypes.arrayOf(PropTypes.object.isRequired),
  handleClose: PropTypes.func,
  handleComponentUpdated: PropTypes.func,
  convertTo: PropTypes.object,
  componentId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired
};

export default withStyles(styles)(ConvertFormDialog);
