// ** Helper functions for loop selection algorithm  ** //

/**
 * This function creates a mapping from parentId to an array of { threadId, status }
 * i.e: {
 *          parentThread1: [{ threadId: A, status: "SUCCESS_END" }, ...],
 *          parentThread2: [{ threadId: B, status: "SUCCESS"}, ...],
 *          ...
 *      }
 */
export const collectThreadMappingsWithStatus = (sessionCompData, allComponents) => {
    let mapping = {};

    // Go over all components
    for (let currComp of allComponents) {
        if (currComp.type !== "loop_through_list") continue; // Skip if not loop

        let relevantSessionComps = sessionCompData.filter((sC) => sC.componentId === currComp.componentId); // Grab only by component id

        // Need to go over component sessions
        for (let i = 0; i < relevantSessionComps.length; i++) {
            let currLTL = relevantSessionComps[i];

            let pT = currLTL.thread.parentThreadId ? currLTL.thread.parentThreadId : "null";
            let t = currLTL.thread.threadId;

            // If parent thread in mapping, add to array
            if (pT in mapping) {
                mapping[pT].push({
                    threadId: t,
                    status: currLTL.status,
                });
            } else {
                // ELSE, create new entry with array
                mapping[pT] = [
                    {
                        threadId: t,
                        status: currLTL.status,
                    },
                ];
            }
        }
    }
    return mapping;
};

/**
 * Takes the thread mapping created and a given parent thread ID
 * From this parent threadID, it utilises recursion to draw all loop paths
 * 
 * @param {Object} threadMapping ==> Map from parentThreadID : [{threadId, status}, ...]
 * @param {String} parent ==> parent thread ID
 * @returns newly drawn paths
 */
export const drawPaths = (threadMapping, parent) => {
    let newPath = {};
    let itemArr = threadMapping[parent];
    newPath = createRandomPath(parent, itemArr, newPath, threadMapping);
    return newPath;
};

/**
 * Main recursive function behind drawing the loop paths
 * It follows the following logic:
 * - If we hit a SUCCESS_END, we draw a NEW path down from there
 * - ELSE, we add it to the current loop path
 * 
 * @param {String} currParent ==> current parent thread ID
 * @param {Array} itemArr ==> array of thread IDs
 * @param {Object} path ==> current drawn path
 * @param {Object} threadMapping ==> thread mapping of parent thread IDs to array of {threadId, success}
 * @returns 
 */
export const createRandomPath = (currParent, itemArr, path, threadMapping) => {
    if (!currParent || !itemArr) return path;

    let currT = null;

    for (let i = 0; i < itemArr.length; i++) {

        // Grab the status and theradID
        let status = itemArr[i].status;
        let t = itemArr[i].threadId;

        // Add to path
        if (currParent in path) path[currParent].push(t);
        else path[currParent] = [t];

        // If it's a success end, we create a new path from that point
        if (status === "SUCCESS_END") createRandomPath(t, threadMapping[t], path, threadMapping);
        else {
            // ELSE, we continue moving through the list 
            currT = t;
            break;
        }
    }
    return createRandomPath(currT, threadMapping[currT], path, threadMapping);
};

/**
 * This function searches for all paths starting with oldThread
 * And removes them from the path object
 * This is done through a BFS
 * 

    FOR EXAMPLE:

        {
            null: [A, D],
            A: [B]
            B: [G, M],
            G: [H],
            M: [N],
            D: [E]
        }

    If oldThread = B (I.E: We want to remove all oldThreadPaths of B)

    The above would become:
        {
            null: [A, D],
            A: [B]
            D: [E]
        }
 * 
 * 
 * 
 * @param {String} oldThread ==> The old thread path to remove
 * @param {Object} currPath ==> The current paths shown
 * @returns the same paths but with the oldThread paths removed
 */
export const removeOldThreadPaths = (oldThread, currPath) => {
    let queue = [oldThread];
    let visited = [oldThread];

    while (queue.length > 0) {
        let v = queue.pop();
        let items = currPath[v];
        if (items) {
            for (let item of items) {
                if (!visited.includes(item)) {
                    queue.push(item);
                    visited.push(item);
                }
            }
        }
    }

    // Delete all items
    let editedPath = { ...currPath };
    for (let idToRemove of visited) {
        delete editedPath[idToRemove];
    }

    return editedPath;
};

/**
 * This function replaces any oldThreadIDs with newThreadIDs
 * 
 * Say we have path:
 * {
 *      Parent-A: [Child-A, Child-B, ...],
 *      ...
 * }
 * 
 * If we have:
 *      - oldThread = Child-B
 *      - newThread = Child-E
 * 
 * Our new path would be:
 * {
 *      Parent-A: [Child-A, Child-E, ...],
 *      ...
 * }
 * 
 * @param {String} oldThread 
 * @param {String} newThread 
 * @param {Object} newPath 
 * @param {String} parent 
 * @returns 
 */
export const replaceOldThreadWithNew = (oldThread, newThread, newPath, parent) => {
    if (!parent || !newPath[parent]) return;

    let items = newPath[parent];
    for (let i = 0; i < items.length; i++) {
        if (items[i] === oldThread) {
            newPath[parent][i] = newThread;
            break;
        } else {
            replaceOldThreadWithNew(oldThread, newThread, newPath, items[i]);
        }
    }

    for (let item of items) {
        if (item === oldThread) replaceOldThreadWithNew(oldThread, newThread, newPath, item);
    }
    return newPath;
};

/**
 * This function inverts a given paths object
 * It essentially swaps the values and keys around
 * 
 * i.e: { A: [B, C], D: [E], F: [G, H, I]}
 * ===> { B: A, C: A, E: D, G: F, H: F, I: F }
 * 
 * @param {*} givenPath 
 * @returns 
 */
export const invertThreadPath = (givenPath) => {
    let inv = {};

    for (let [key, value] of Object.entries(givenPath)) {
        // Loop over all thread
        for (let thread of value) {
            inv[thread] = key;
        }
    }
    return inv;
};
