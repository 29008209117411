import React from "react";

// MUI
import { Grid, styled, Tooltip } from "@mui/material";

// Util
import { Link } from "react-router-dom";
import { format } from "../../../../utils/Date";
import ProjectStore from "../../ProjectStore";
import DraftProductionSwitch from "./DraftProductionSwitch";

const VersionText = styled("span")({
    fontSize: "12px",
    fontWeight: 400,
    color: "#757575",
});

function DraftProductionContent(props) {
    const { changes, production, isCanvasLocked, draftVersion, lastModified, publishedVersion, projectId } = props;

    const getVersionBehindText = (draftVersion, version, projectId) => {
        if (!draftVersion || !version) return;
        if (version === "None") return;
        let versionDiff = parseInt(draftVersion - version);

        switch (versionDiff) {
            case 0:
                return (
                    <>
                        Same version as{" "}
                        <Link to={`/project/production/${projectId}`} style={{ textDecoration: "none", color: "rgba(33, 150, 243, 1)" }}>
                            Production
                        </Link>
                    </>
                );
            case 1:
                return (
                    <>
                        1 version ahead of{" "}
                        <Link to={`/project/production/${projectId}`} style={{ textDecoration: "none", color: "rgba(33, 150, 243, 1)" }}>
                            Production
                        </Link>{" "}
                    </>
                );
            default:
                return (
                    <>
                        {versionDiff} versions ahead of{" "}
                        <Link to={`/project/production/${projectId}`} style={{ textDecoration: "none", color: "rgba(33, 150, 243, 1)" }}>
                            Production
                        </Link>
                    </>
                );
        }
    };

    return (
        <Grid container display="flex" alignItems="flex-start" justifyContent="center" spacing={1}>
            <Grid item>
                <DraftProductionSwitch isDraft hasPublishedVersion={publishedVersion !== "None"} projectId={projectId} />
            </Grid>
            <Grid item>
                <Tooltip title={lastModified ? format(lastModified, ProjectStore.state.timezone) : ""}>
                    <VersionText>
                        Version {draftVersion} {lastModified && ` saved ${format(lastModified, ProjectStore.state.timezone, "relative")}`}
                    </VersionText>
                </Tooltip>
            </Grid>
            <Grid item>
                {changes && (
                    <div className={"unpublishedBanner"}>
                        <i className={"material-icons"} style={{ color: "#E10050", marginRight: "4px" }}>
                            error
                        </i>
                        <p className={"m-0"}>You have unpublished changes</p>
                    </div>
                )}
            </Grid>
        </Grid>
    );
}

export default DraftProductionContent;
