import { decorate, observable, action } from "mobx";
import { extractNodes, extractEdges } from "../../../utils/CanvasUtil";

class CanvasStore {
    nodes = []; // Global nodes
    edges = []; // Global edges

    /**
     * Nodes to update when dragging
     * We only update this during drag onNodesChange which won't re-render
     * the workflow canvas
     * On drag stop, we sync the global nodes with this
     */
    draggingNodes = [];


    // ====== ACTIONS ====== //

    /** Add actions */
    addNode(node) {
        if (!node) return;
        this.nodes.push(node);
        this.draggingNodes.push(node);
    }

    addDraggingNode(draggingNode) {
        this.draggingNodes.push(draggingNode);
    }

    addEdge(edge) {
        this.edges.push(edge);
    }

    addNodes(nodes) {
        if (!nodes) return;
        const updatedNodesList = [...this.nodes, ...nodes];
        this.nodes = updatedNodesList;
        this.draggingNodes = updatedNodesList;
    }

    addEdges(edges) {
        if (!edges) return;
        this.edges = [...this.edges, ...edges];
    }


    /** Set actions */
    setNodes(nodes) {
        this.nodes = nodes;
        this.draggingNodes = nodes;
    }

    setNodesFn(updateFn) {
        const updatedNodes = updateFn(this.nodes);
        this.nodes = updatedNodes;
        this.draggingNodes = updatedNodes;
    }

    setNodesFromDraggingNodesFn(updateFn) {
        const updatedNodes = updateFn(this.draggingNodes);
        this.nodes = updatedNodes;
        this.draggingNodes = updatedNodes;
    }

    setDraggingNodes(draggingNodes) {
        this.draggingNodes = draggingNodes;
    }

    setDraggingNodesFn(updateFn) {
        this.draggingNodes = updateFn(this.draggingNodes);
    }

    setEdges(edges) {
        this.edges = edges;
    }

    setEdgesFn(updateFn) {
        const res = updateFn(this.edges);
        this.edges = res;
    }

    setNodesAndEdges(elements) {
        const extractedNodes = extractNodes(elements);
        const extractedEdges = extractEdges(elements);

        this.nodes = extractedNodes;
        this.draggingNodes = extractedNodes;
        this.edges = extractedEdges;
    }

    /** MISC */
    updateNode(updatedNode) {
        const nodeIndex = this.nodes.findIndex((node) => node.id === updatedNode.id);
        if (nodeIndex >= 0) {
            this.nodes[nodeIndex] = updatedNode;
            this.draggingNodes[nodeIndex] = updatedNode
        }
    }

    // This synchronises the nodes and dragging nodes array
    // It is called on drag stop within Canvas.js
    syncNodes() {
        this.nodes = this.draggingNodes;
    }
}

decorate(CanvasStore, {
    // What we can watch
    nodes: observable,
    draggingNodes: observable,
    edges: observable,

    // Adding a single node/edge
    addNode: action,
    addDraggingNode: action,
    addEdge: action,

    // Adding a list of nodes/edges
    addNodes: action,
    addEdges: action,

    // Setting the entire nodes/edges
    setNodes: action,
    setNodesFn: action,
    setDraggingNodes: action,
    setNodesFromDraggingNodesFn: action,
    setDraggingNodesFn: action,
    setEdges: action,
    setEdgesFn: action,

    setNodesAndEdges: action,

    // Misc
    updateNode: action,
    syncNodes: action,
});

const canvasStore = new CanvasStore();
export default canvasStore;
