import React, { createRef, Component } from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";

import PropTypes from "prop-types";

const styles = theme => ({
  Button: {
    color: "#2196F3",
    backgroundColor: "#444444",
    "&:hover": {
      backgroundColor: "#2f2f2f"
    }
  }
});
class CustomMap extends React.Component {
  constructor() {
    super();
  }
  // mapRef = createRef(<Map>)

  render() {
    const { classes } = this.props;
    return (
      <div>
        <img
          style={{
            width: "100%",
            border: "#868686",
            borderRadius: "8px",
            objectFit: "cover"
          }}
          src={"/images/map.jpg"}
        />
        <br />
      </div>
    );
  }
}

CustomMap.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomMap);
