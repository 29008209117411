import React, { Component } from "react";
import { observer } from "mobx-react";

import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import JoditEditor, { Helpers } from "jodit-pro-react";
import * as JoditCore from "jodit-pro/build/jodit";
import { configJodit } from "../../../../../utils/configJodit";
import { Tooltip } from "@mui/material";
import { PLACEHOLDER_COLOR, PLACEHOLDER_ERROR_COLOR } from "../../../../../utils/PlaceholderUtil";
import JoditSuperClass from "../../../../TextEditor/JoditSuperClass";

class Autocomplete  extends JoditSuperClass {
  constructor(props) {
    super(props);
    this.state = {
      content: this.props.text ? this.props.text.toString() : "",
      oldContent: this.props.text ? this.props.text.toString() : ""
    };
  }

  focus = () => {
    this.props.onFocus();
  };

  componentDidMount() {
    let content = this.props.text;
    if (content) {
      content = content.toString();
      content = content.replace(/(?:\r\n|\r|\n\n)/g, "<br>");
      content = content.replace(/(?:\n)/g, "");
    } else {
      content = "";
    }
    this.setState({
      content
    });
  }


  updateContent = value => {
    value = value.replaceAll("&nbsp;", " ");
    value = JoditCore.Jodit.modules.Helpers.stripTags(value);

    this.props.onChange(value);
  };

  config = {
    readonly: false,
    toolbar: false,
    defaultActionOnPaste: "insert_only_text",
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    disablePlugins:
      "add-new-line,about,class-span,backup,button-generator,bold,clean-html,delete,wrap-text-nodes,copy-format,clipboard,paste,paste-storage,color,drag-and-drop,drag-and-drop-element,key-arrow-outside,error-messages,font,format-block,fullsize,hotkeys,iframe,indent,hr,inline-popup,justify,limit,link,mobile,ordered-list,placeholder,redo-undo,search,select,source,stat,sticky,symbols,tooltip,xpath,image-properties,image-processor,image,media,video,file,resize-cells,select-cells,table-keyboard-navigation,table,preview,print",
    toolbarAdaptive: false,
    height: "auto",
    useSearch: false,
    spellcheck: false,
    allowResizeY: false,

    events: {
      afterInit: instance => {
        this.jodit = instance;
      }
    },
    autocomplete: {
      sources: [
        {
          feed: query =>
              this.props.functions !== undefined
                  ? this.props.functions[0].placeholders.filter(value => {
                    let text = "={" + value.key + "}";
                    if (text === query) {
                      return false;
                    } else {
                      return text.indexOf(query.trim()) === 0;
                    }
                  })
                  : false,
          itemRenderer: item => {
            return this.itemRenderer(item,'=');

          },
          insertValueRenderer: ({ key, type, length }) => {
            return this.insertValueRenderer(type, length, key,'=');

          }
        },
        {
          feed: (query) =>{
            return this.sourceFeed(query,'$');
          },

          itemRenderer: (item) => {
            return this.itemRenderer(item,'$');
          },
          insertValueRenderer: ({ key, type, length }) => {
            return this.insertValueRenderer(type, length, key,'$');
          },
        }
      ]
    },
    highlightSignature: {
      schema: {
        "\\=\\{([^}]+)\\}": (jodit, matched) => {
          let color = "";
          let borderColor = "";
          let icon="";
          if (this.props.functions.length > 0) {
            this.props.functions[0].placeholders.filter(func => {
              const funcColor = getPlaceholderColors(func.type, func.length);

              color = funcColor.hover;
              borderColor = funcColor.default;
              icon=funcColor.icon;
            });
          }
          return this.generateButton(color,borderColor,"","",icon)
        },
        "\\$\\{([^}]+)\\}": (jodit, matched) => {
          return this.getPlaceholderStyleForExistingPlaceholders(matched, jodit);
        }
      }
    },
    ...configJodit,
    ...this.props.style
  };

  setRef = jodit => (this.jodit = jodit);

  render() {
    const { error, helperText } = this.props;
    return (
      <Tooltip
        placement="bottom"
        title={
          this.props.availablePlaceholders == null
            ? "Type “={” to insert a function"
            : "Type “${” or “={” for commands"
        }
      >
        <div
          onClick={this.focus}
          style={this.props.style}
          className={this.props.className}
        >
          <span className="jodit-mention">
            <JoditEditor
              ref={this.setRef}
              value={this.state.content}
              config={this.config}
              tabIndex={1}
              onBlur={this.updateContent}
            />
          </span>
          {error && (
            <p style={{ color: "red", margin: "5px 0 0" }}>{helperText}</p>
          )}
        </div>
      </Tooltip>
    );
  }
}

export default observer(Autocomplete);
