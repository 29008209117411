/**
 * Function to prevent pasting of image files from the clipboard.
 * @param {Event} event - The paste event object.
 */
export function preventDefaultPaste(event) {
  if (!event || !event.clipboardData) return;
  // Retrieve the items from the clipboard. Some browsers use 'clipboardData', 
  // while others may use 'originalEvent.clipboardData'.
  const items = (event.clipboardData || event.originalEvent.clipboardData).items;

  // Iterate over each item in the clipboard.
  for (let index in items) {
    const item = items[index];

    // If the item is a file and its type starts with "image/",
    // then it's an image file.
    if (item.kind === "file" && item.type.indexOf("image/") !== -1) {
      // Prevent the default paste behavior to stop the image from being pasted.
      event.preventDefault();

      // Return false to stop any further processing.
      return false;
    }
  }
}
