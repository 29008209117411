import React from "react";

import { toJS } from "mobx";
import "../css/draggable.css";
import Access from "../Access";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import ScienceIcon from "@mui/icons-material/Science";
import ShareIcon from "@mui/icons-material/Share";
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import { CustomDialog } from "../../Component/Dialog";
import { send_request } from "../../../utils/Request";
import projectStore from "../ProjectStore";
import AccessControl from "./ButtonComponents/AccessControl";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { format } from "../../../utils/Date";
import ProjectStore from "../ProjectStore";
import config from "../../../config";
import MultipleStartTriggersDialog from "./MultipleStartTriggersDialog";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ViewDatabasesModal from "./CanvasDatabaseDialog/ViewDatabasesModal";
import BackAndWorkflowName from "./CanvasButtonsUtil/BackAndWorkflowName";
import MainCanvasButtons from "./CanvasButtonsUtil/MainCanvasButtons";
import DraftProductionContent from "./CanvasButtonsUtil/DraftProductionContent";

export default class DraftCanvasHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      open: false,
      error: false,
      rerenderBadge: [],
      anchorEl: null,
      versionAnchor: null,
      openViewDatabasesDialog: false
    };
  }

  handleDisabledStartWorkflow = (status, disableWF, publishedVersion) => {
    if (disableWF && publishedVersion != "None") {
      this.setState({
        open: true,
        errors: ["Insert a trigger component onto the canvas"],
      });
    } else if (!disableWF && publishedVersion == "None") {
      this.setState({
        open: true,
        errors: ["Workflow must be published before it can be started and run"],
      });
    } else {
      this.setState({
        open: true,
        errors: [
          "Insert a trigger component onto the canvas",
          "Workflow must be published before it can be started and run",
        ],
      });
    }
  };

  handleAccess = () => {
    this.props.setOpenAccess(true);
  };

  handleRerenderBadge = () => {
    this.setState({ rerenderBadge: [...this.state.rerenderBadge, 1] });
  };

  handleTest = (open) => {
    this.props.fireTestDialog(open);
  };

  handleShare = (open) => {
    this.props.fireShareDialog(open);
  };

  handleRenamePlaceholders = (open) => {
    this.props.fireRenamePlaceholdersDialog(open);
  };

  render() {
    let disableWF = true;
    let startWorkflowStatus = "";
    const { publishedVersion, draftVersion, lastModified } = this.props;
    const { aggregate } = this.props.ProjectStore;
    const componentDatas = toJS(this.props.ProjectStore.components);

    if (componentDatas) {
      for (let i = 0; i < componentDatas.length; i++) {
        let t = componentDatas[i].type;
        if (t === "form" || t === "bulk_assessment" || t === "webhook" || t === "schedule_start" || 
            t === "trigger_from_a_workflow" || t === "trigger_from_zapier") {
              disableWF = false;
        }
      }
    }

    const projectId = toJS(this.props.ProjectStore).project_id;
    const { production, openStartDialog, closeStartDialog, isCanvasLocked } = this.props;

    return (
      <>
        <Grid container spacing={1}>
          <Grid item display="flex" alignItems="center" justifyContent="flex-start">
            <BackAndWorkflowName
              isCanvasLocked={isCanvasLocked}
              workflowName={this.props.workflow.name}
              setWorkflowName={this.props.setWorkflowName}
              handleSaveProjectName={this.props.handleSaveProjectName}
              handleBack={this.props.handleBack}
            />
          </Grid>

          <Grid item display="flex" alignItems="center" justifyContent="flex-start">
            <MainCanvasButtons 
              production={production}
              pauseSave={this.props.pauseSave}
              saving={this.props.saving}
              isCanvasLocked={isCanvasLocked}
              firePublish={this.props.firePublish}

              openViewDatabasesDialog={this.state.openViewDatabasesDialog}
              updateState={(newState) => this.setState(newState)}

              anchorEl={this.state.anchorEl}
              versionAnchor={this.state.versionAnchor}
              saveDraft={this.props.saveDraft}
              saveDraftVersion={this.props.saveDraftVersion}
              handleTest={this.handleTest}
              publishedVersion={publishedVersion}

              handleRenamePlaceholders={this.handleRenamePlaceholders}
              handleShare={this.handleShare}
              aggregate={aggregate}
              error={this.props.ProjectStore.error}
              handleAccess={this.handleAccess}
              rerenderBadge={this.state.rerenderBadge}
              setLoadingDialog={this.props.setLoadingDialog}
              checkUnpublishedChanges={this.props.checkUnpublishedChanges}
            />
          </Grid>

          <Grid item display="flex" alignItems="center" justifyContent="flex-start">
            <DraftProductionContent 
              changes={this.props.changes}
              production={production}
              isCanvasLocked={isCanvasLocked}
              draftVersion={draftVersion}
              lastModified={lastModified}
              publishedVersion={publishedVersion}
              projectId={projectId}
            />
          </Grid>

        </Grid>
        <>
          {projectId && this.props.openAccess && (
            <Access
              open={this.props.openAccess}
              history={this.props.history}
              published={this.props.ProjectStore.aggregate.publishedVersion}
              projectId={projectId}
              handleRerenderBadge={this.handleRerenderBadge}
              close={() => {
                this.props.setOpenAccess(false);
              }}
            />
          )}

          {this.state.openViewDatabasesDialog && 
            <ViewDatabasesModal 
              openViewDatabasesDialog={this.state.openViewDatabasesDialog}
              closeDialog={() => this.setState({ openViewDatabasesDialog: false })}
              projectId={projectId}
              draftVersion={draftVersion}
            />
          }

          <CustomDialog
            isOpen={this.state.open}
            zIndex={true}
            title={"🚩 Fix these issues before you can start the workflow"}
            contents={
              <>
                <p className={"s-text"}>
                  Before you can start and run this workflow, you need to fix the
                  following issues:
                </p>
                {this.state.errors.map((error, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      xs={12}
                      direction={"row"}
                      alignItems={"center"}
                    >
                      <Grid item xs={1}>
                        <Error className={"error-text"} />
                      </Grid>
                      <Grid item xs={11}>
                        <p className={"error-text"}>{error}</p>
                      </Grid>
                    </Grid>
                  );
                })}
                <p className={"s-text"}>
                  The Start Workflow button will automatically turn green once
                  these issues have been fixed.{" "}
                </p>
              </>
            }
            buttons={
              <>
                <Button
                  onClick={() => {
                    this.setState({ open: false });
                    this.props.firePublish();
                  }}
                  color={"error"}
                  variant={"contained"}
                  disabled={isCanvasLocked}
                >
                  Publish
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => {
                    this.setState({
                      open: false,
                    });
                  }}
                  disabled={isCanvasLocked}
                >
                  Done
                </Button>
              </>
            }
          />
        </>
      </>
    );
  }
}
