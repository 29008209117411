import React, { Fragment } from "react";

import { Grid, TextField } from "@mui/material";
import TransformStore from "../../TransformStore";
import ChipInput from "../../../ChipInput/ChipInput";

function CountASpecificValue({ onPlaceholderSelected, availablePlaceholders, present, index }) {
    return (
        <Fragment>
            <Grid item xs={12} className="item-label">
                Count this value
            </Grid>
            <Grid item xs={12} className="input-list-transform">
                <ChipInput
                    onPlaceholderSelected={onPlaceholderSelected}
                    placeholders={availablePlaceholders}
                    fullWidth
                    onBeforeAdd={(chip) => {
                        TransformStore.setComponent(chip, index, "present");
                        return true;
                    }}
                    placeholder="Type something or insert a placeholder"
                    inputValue={present}
                    onDelete={(indexChip) => TransformStore.deleteChip(indexChip, index, "present")}
                    onFocus={() => TransformStore.setFocus(index, "present")}
                />
            </Grid>
        </Fragment>
    );
}

export default CountASpecificValue;
