import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import SignatureCanvas from 'react-signature-canvas';

const styles = theme => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  textroot: {
    width: "100%",
    margin: "auto"
  }
});

class ESignature extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    try {
      return (
        <div className={classes.root}>              
          <SignatureCanvas penColor='green'
            canvasProps={{width: 500, height: 120, 
              style: {borderColor:"#868686",borderStyle:"solid",borderWidth:"thin",width:"fit-content",borderRadius:"8px"}} } />
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

ESignature.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ESignature);
