import React from "react";

// MUI
import { Grid, styled } from "@mui/material";

// RF
import { Position, getBezierPath, EdgeLabelRenderer } from "reactflow";

// Util
import { toJS } from "mobx";
import { EdgeBox, EdgeLabelDiv, LabelText } from "./EdgeUtils/EdgeStyledComponents";

const OuterLabelDiv = styled("div")({
    width: "fit-content !important",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

export default ({ fromNode, fromX, fromY, toX, toY }) => {
    const edgePath = getBezierPath({
        sourceX: fromX,
        sourceY: fromY,
        sourcePosition: Position.Right,
        targetX: toX,
        targetY: toY,
        targetPosition: Position.Left,
    });

    const fromComponentType = fromNode && fromNode.data ? fromNode.data.type : "";

    // Handle connections from different components
    switch (fromComponentType) {
        case "branch_paths":
            return (
                <>
                    <path className="react-flow__edge-path" d={edgePath[0]} />
                    <EdgeLabelRenderer>
                        <EdgeLabelDiv className="nodrag nopan" labelX={edgePath[1]} labelY={edgePath[2]}>
                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                <EdgeBox item display="flex" alignItems="center" justifyContent="center" background="rgba(35, 35, 35, 1)" borderRadius="4px">
                                    <OuterLabelDiv>
                                        <LabelText color="rgba(255, 255, 255, 1)">Must connect to a Conditional Path</LabelText>
                                    </OuterLabelDiv>
                                </EdgeBox>
                            </Grid>
                        </EdgeLabelDiv>
                    </EdgeLabelRenderer>
                    ;
                </>
            );

        default:
            return (
                <>
                    <path className="react-flow__edge-path" d={edgePath[0]} />;
                </>
            );
    }
};
