import React, { useEffect, useState } from "react";

// MUI
import { Grid, Skeleton } from "@mui/material";
import { styled } from "@mui/system";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { getIcon } from "../ChunkRenderer";


// Styled components
const ComponentBlock = styled(Grid)({
    padding: "16px",
    backgroundColor: "#FFF",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "6px",
});
const Open = styled(ExpandMore)({});
const Close = styled(ExpandLess)({});

const ClickableGridItem = styled(Grid)({
    cursor: 'pointer',
});

const WordBreakDiv = styled('div')({
    wordBreak: 'break-word',
});

function HandleExpandedState({ proposal }) {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            {proposal && (
                <Grid container display="flex" alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <b>Plan</b>
                    </Grid>
                    <ClickableGridItem
                        item
                        xs={1}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}>
                        {expanded ? <Open /> : <Close />}
                    </ClickableGridItem>
                    {proposal && expanded && (
                        <Grid item xs={12}>
                            <Grid container rowGap="16px">
                                {getProposalStructure(proposal)}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );

}
const getProposalStructure = (proposal) => {
    // Try new structure
    try {
        return proposal.map((item) => {
            return getProposalItem(item);
        });
    } catch (e) { }

    // Must be loading old history
    try {
        return (
            <Grid container rowSpacing={1}>
                {proposal.map((p) => {
                    return (
                        <Grid item xs={12}>
                            <span>{p}</span>
                        </Grid>
                    );
                })}
            </Grid>
        );
    } catch (e) {
        return <span>{proposal}</span>;
    }
};

const getProposalItem = (proposalItem) => {
    try {
        return (
            <Grid item xs={12}>
                <ComponentBlock container gap="16px">
                    <Grid item display="flex" alignItems="center" justifyContent="center">
                        {getIcon(proposalItem.component, "28px")}
                    </Grid>
                    <Grid item xs>
                        <WordBreakDiv>
                            {proposalItem.content}
                        </WordBreakDiv>
                    </Grid>
                </ComponentBlock>
            </Grid>
        );
    } catch (e) {
        return <span>{proposalItem}</span>;
    }
};

export default HandleExpandedState;
