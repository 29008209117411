import { Grid } from "@mui/material";

export const REFUSAL_ERROR_MESSAGE = (username) => {
    return {
        role: "error",
        content: (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <b>🔴 I'm sorry, I'm afraid I can't do that.</b>
                </Grid>
                <Grid item xs={12}>
                    This is because you have asked me to do something that is prohibited or outside of my job scope or what I can do. Please try another request or contact support if you continue to have issues.
                </Grid>
            </Grid>
        )
    };
};
