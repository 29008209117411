import React from "react"; 
import { MenuItem, Select } from "@mui/material";

function SelectFunction(props) {
  return (
    <Select
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className={`dropdown-mat selectStandard ${props.className}`}
      displayEmpty
      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={null} disabled> select option </MenuItem>
      <MenuItem value={"count_a_specific_value"}> Count a specific value </MenuItem>
      <MenuItem value={"count_if_conditional_is_met"}>  Count if conditional is met</MenuItem>
      <MenuItem value={"count_length"}> Count length </MenuItem>
      <MenuItem value={"filter_based_on_a_conditional"}> Filter based on a conditional</MenuItem>
      <MenuItem value={"find_and_replace"}> Find and replace </MenuItem>
      <MenuItem value={"split_list_into_placeholders"}> Split list into placeholders </MenuItem>
      <MenuItem value={"join"}>  Join</MenuItem>
      <MenuItem value={"remove_empty_values"}>  Remove empty values</MenuItem>
      <MenuItem value={"remove_duplicate_values"}>  Remove duplicate values</MenuItem>
      <MenuItem value={"extract_by_order"}>  Extract by order</MenuItem>
      <MenuItem value={"rearrange"}>  Rearrange</MenuItem>
      <MenuItem value={"convert_list_to_text"}>  Convert list to text</MenuItem>

    </Select>
  );
}

function SelectConditional(props) {
  return (
    <React.Fragment>
      <Select
        // native
        style={{
          width: "100%",
          backgroundColor: "#F8F8F8",
          borderRadius: "4px",
          height: "36px",
          color: "#868686"
        }}
        name="selectFunc"
        displayEmpty
        value={props.value}
        onChange={event => {
          props.onChange(event.target.value);
        }}
        disableUnderline
        labelId="demo-simple-select-outlined-label"
        className="dropdown-mat selectStandard"
        variant="standard"
      >
        <MenuItem value="" disabled> Function </MenuItem>
        <MenuItem value={"CONTAIN"} > contains </MenuItem>
        <MenuItem value={"NOTCONTAIN"} > does not contain </MenuItem>
        <MenuItem value={"MATCHES"} > matches </MenuItem>
        <MenuItem value={"NOTMATCH"} > does not match </MenuItem>
        <MenuItem value={"EQUAL"} > equals </MenuItem>
        <MenuItem value={"NOTEQUAL"} > does not equal </MenuItem>
        <MenuItem value={"GREATERTHAN"} > greater than </MenuItem>
        <MenuItem value={"GREATERTHANOREQUAL"} > greater than or equal to </MenuItem>
        <MenuItem value={"LESSTHAN"} > less than </MenuItem>
        <MenuItem value={"LESSTHANOREQUAL"} > less than or equal to </MenuItem>
        <MenuItem value={"NOTEMPTY"} > is not empty </MenuItem>
        <MenuItem value={"EMPTY"} > is empty </MenuItem>
      </Select>
    </React.Fragment>
  )
}

function SelectLogic(props) {
  return (
    <Select
      style={{
        width: "100%",
        backgroundColor: "#F8F8F8",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
        textAlign: "center"
      }}
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectStandard"

      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={"OR"} > OR </MenuItem>
      <MenuItem value={"AND"} > AND </MenuItem>
    </Select>
  )
}

function SelectExtract(props) {
  return (
    <Select
      style={{
        width: "100%",
        backgroundColor: "#F8F8F8",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
        textAlign: "left"
      }}
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectStandard"

      value={props.value}
      defaultValue={"the_first"}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={"the_first"} > the first </MenuItem>
      <MenuItem value={"the_last"} > the last </MenuItem>
    </Select>
  )
}

function SelectArrange(props) {
  return (
    <Select
      style={{
        width: "100%",
        backgroundColor: "#F8F8F8",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
        textAlign: "left"
      }}
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectStandard"

      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={"ascending_order"} > ascending order </MenuItem>
      <MenuItem value={"descending_order"} > descending order </MenuItem>
    </Select>
  )
}

export { SelectFunction, SelectConditional, SelectLogic, SelectExtract, SelectArrange };