import { Grid } from "@mui/material";
import Validation from "./Validation";
import { observer } from "mobx-react";
import { DropTarget } from "react-dnd";
import React, { Component } from "react";
import TaskInfo from "./TaskInfo";
import { send_request } from "../../utils/Request";
import SequentialFormContainer from "./SequentialFormContainer";
import "./Component.css";

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem(),
  };
}

const itemSource = {
  drop(props, monitor, component) {
    const hoverIndex = SequentialFormContainer.position;
    if (hoverIndex >= 0) {
      SequentialFormContainer.setComponentAndPosition(
        monitor.getItem(),
        hoverIndex
      );
    } else {
      SequentialFormContainer.addComponent(monitor.getItem());
    }
    props.setInnerPlaceholders();
  },
};
class Target extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (
      SequentialFormContainer.email &&
      Array.isArray(SequentialFormContainer.email) &&
      SequentialFormContainer.email.length > 0
    ) {
      //email has been initalised
      //do nothing
      //_callback();
      return Promise.resolve();
    } else {
      //fallback
      return send_request(
        "/form_builder/form",
        "",
        { form_id: this.props.formId },
        "get"
      )
        .then((res) => {
          SequentialFormContainer.credentialId = res.data.data.credentialId;
        })
        .catch((err) => {
          this.setState({ loading: false });
          SequentialFormContainer.disableButtonsWhileLoading = false;
        });
    }
  }

  render() {
    const { classes, connectDropTarget, coverImage, coverImageCrop, linkedComponent, launchSettingDialog ,uploadIcon,formTitle} =
      this.props;

    return connectDropTarget(
      <div>
        <Grid container item xs={12} direction={"column"}>
          {!this.props.isFormSection && <TaskInfo SelectedPlaceholder={this.props.SelectedPlaceholder} />}
          <Validation coverImage={coverImage} coverImageCrop={coverImageCrop} 
            linkedComponent={linkedComponent} 
            launchSettingDialog={launchSettingDialog}
            uploadIcon={uploadIcon}
            formTitle={formTitle}/>
        </Grid>
      </div>
    );
  }
}

export default DropTarget("item", itemSource, collect)(observer(Target));
