import React from "react";

import { Grid } from "@mui/material";
import {
  BoldText,
  InputLabel,
  Row,
  RowItem,
  StyledCheckBox,
  PlaceholderBox,
  Text
} from "./StyledComponents";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";

function PlaceholderSelection(props) {
  const checkIfSelected = pH => {
    const isSelected = props.savedPlaceholders.some(sPH => sPH.key === pH.key);
    return isSelected;
  };

  const checkIfAllSelected = () => {
    return (
      props.availablePlaceholders[0].placeholders.length ===
      props.savedPlaceholders.length
    );
  };

  const handleSelectAll = e => {
    if (e.target.checked) {
      // Need to select all
      const newSavedPHs = props.availablePlaceholders[0].placeholders.map(
        pH => {
          return {
            key: pH.key,
            value: "${" + pH.key + "}",
            type: pH.type,
            length: pH.length
          };
        }
      );

      props.updateState({ savedPlaceholders: newSavedPHs });
    } else {
      // Need to remove all
      props.updateState({ savedPlaceholders: [] });
    }
  };

  const handleSelectPlaceholder = (e, pH) => {
    if (e.target.checked) {
      const newPlaceholders = [
        ...props.savedPlaceholders,
        {
          key: pH.key,
          value: "${" + pH.key + "}",
          type: pH.type,
          length: pH.length
        }
      ];
      props.updateState({ savedPlaceholders: newPlaceholders });
    } else {
      const updateSavedPlaceholders = props.savedPlaceholders.filter(
        sPH => sPH.key != pH.key
      );
      props.updateState({ savedPlaceholders: updateSavedPlaceholders });
    }
  };

  const renderPlaceholders = () => {
    return (
      <Grid container>
        <Row container item xs={12} marginBottom={"16px !important"}>
          <RowItem item>
            <StyledCheckBox
              size="medium"
              checked={checkIfAllSelected()}
              onChange={e => handleSelectAll(e)}
            />
          </RowItem>
          <RowItem item>
            <Text>Placeholders</Text>
          </RowItem>
        </Row>

        {props.availablePlaceholders[0].placeholders.map((pH, idx) => {
          return (
            <Row container item xs={12} key={pH.key}>
              <RowItem item>
                <StyledCheckBox
                  size="medium"
                  checked={checkIfSelected(pH)}
                  onChange={e => handleSelectPlaceholder(e, pH)}
                />
              </RowItem>
              <RowItem item>
                <W86PlaceholderTextField
                  disabled={true}
                  value={pH.key}
                  customStyleProps={{ width: "425px" }}
                />
              </RowItem>
            </Row>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Grid item xs={12} marginBottom={"20px"}>
        <InputLabel>
          Select placeholders from this workflow to export to the{" "}
          <BoldText>Target Workflow</BoldText>
        </InputLabel>
      </Grid>
      {renderPlaceholders()}
    </>
  );
}

export default PlaceholderSelection;
