import React from "react";

import { Button, styled } from "@mui/material";

import { useNavigate } from "react-router";

const DraftProdSwitchButton = styled(Button)(({ isActive, textColor, bgColor, borderRadius }) => ({
    color: isActive ? textColor : "rgba(117, 117, 117, 1)",
    backgroundColor: isActive ? bgColor : "#f1f1f1",
    borderRadius: borderRadius,

    "&:hover": {
        color: !isActive && "#2196F3",
        boxShadow: !isActive && "2px 2px 0px 0px rgba(0, 0, 0, 0.25)",
    },

    fontSize: "11px",
    lineHeight: "12px",
    textTransform: "none",
    padding: "4px 8px",
    fontWeight: 400,
}));

function DraftProductionSwitch({ isDraft, hasPublishedVersion, projectId }) {
    const navigate = useNavigate();

    let styles = {};

    if (isDraft) {
        // Set draft styling
        styles = {
            textColor: "#FFF !important",
            bgColor: "rgba(255, 88, 0, 1)",
        };
    } else {
        // Set production active styling
        styles = {
            textColor: "#FFF !important",
            bgColor: "rgba(85, 167, 122, 1)",
        };
    }

    const handleProductionClick = () => {
        navigate(`/project/production/${projectId}`);
    };

    const handleDraftClick = () => {
        navigate(`/project/canvas/${projectId}`);
    };

    return (
        <>
            <DraftProdSwitchButton isActive={isDraft} onClick={handleDraftClick} disabled={isDraft} {...styles} borderRadius="6px 0px 0px 6px">
                Draft
            </DraftProdSwitchButton>
            <DraftProdSwitchButton isActive={!isDraft} onClick={handleProductionClick} disabled={!isDraft} {...styles} borderRadius="0px 6px 6px 0px">
                Production
            </DraftProdSwitchButton>
        </>
    );
}

export default DraftProductionSwitch;
