import React, { Component } from "react";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { toJS, reaction } from "mobx";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import ConditionalLogicStore from "./ConditionalLogicStore";
import AddIcon from "@mui/icons-material/Add";
import ItemLogic from "./ConditionalLogicComponents/ItemLogic";
import "./ConditionalLogicComponents/style.css";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { Grid } from "@mui/material";
import Pagination from "@mui/lab/Pagination";
import projectStore from "../ProjectCanvas/ProjectStore";
import { value } from "lodash/seq";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";

class ConditionalLogicTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeaderFields: false,
      focus: null,
      placeholders: [],
      extraHeaderFields: [],
      requestClick: false,
      dataClick: false,
      finishedLoading: false,
      error: false,
      loading: false,
      totalPage: 1,
      currentPage: 1,
      currentIndex: 0,
      itemsInPage: 0,
      itemList: [],
      description:null,
      status:null
    };
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
    ConditionalLogicStore.initialiseEmpty();
  }

  handleLogOut = () => {
    this.props.onBackProjectCanvas();
  };

  componentDidMount() {
    const currentIndex = this.state.currentIndex;
    this.getConditionalLogicData(currentIndex);
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveConditionalLogicTemplate();
    this.reactToPlaceHolder();
  }

  getConditionalLogicData = (currentIndex) => {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });

      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}/${currentIndex}`,
        "",
        {}
      )
        .then((response) => {
          if (response && response.data) {
            if (response.data.componentData) {

              if (response.data.componentData.data) {
                let { componentData } = response.data;
                let checkKeyRandom = false;
                response.data.itemList.map((item) => {
                  if (!item.keyRandom) {
                    checkKeyRandom = true;
                  }
                });

                if (checkKeyRandom) {
                  this.updateKeyRandom(
                    componentData.name,
                    response.data.description,
                    componentData.lastModified
                  );
                } else {
                  this.setState({
                    totalPage: response.data.totalPage,
                    itemsInPage: response.data.componentData.data.length,
                    currentPage: response.data.currentPage,
                    currentIndex: response.data.currentIndex,
                    itemList: response.data.itemList,
                  });

                  ConditionalLogicStore.setConditionalLogicTemplate(
                    componentData
                  );

                  this.props.onComponentNameChanged(ConditionalLogicStore.name);
                  this.props.onComponentDescriptionChanged(response.data.description);
                  this.props.onLastModifiedChanged(ConditionalLogicStore.lastModified);
                }

                this.setState({ loading: false });
              }
            } else {
              this.setState({ itemsInPage: 1 });
            }
          }

          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
            this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            ConditionalLogicStore.setTemplateData(this.props.aiComponentBuilderData.data);
            this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
            this.setState({ status: "AI_BUILD_GENERATED" });
            this.setState({ itemsInPage: this.props.aiComponentBuilderData.data.length })
          }

          this.props.setChildTemplateLoaded(true);
          this.setState({ loading: false });
        })
        .catch((err) => {
          throw Error(err.message);
        });
    }
  };

  updateKeyRandom = (componentName, componentDescription, lastModified) => {
    const currentIndex = this.state.currentIndex;
    const data = {
      type: "conditional_logic",
      componentData: {
        name: componentName,
        data: [],
        lastModified: lastModified,
        itemDelete: null,
      },
      componentId: this.props.component_id,
      description: componentDescription,
    };
    send_request(
      `component-service/conditional-logic/update-key-random`,
      data,
      "",
      "POST"
    ).then((response) => {
      if (response) {
        this.getConditionalLogicData(currentIndex);
      }
    });
  };

  saveConditionalLogicTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      const { placeholdersError } = toJS(ConditionalLogicStore);

      if (placeholdersError.length > 0) {
        this.props.showError();
        return;
      }

      const { currentIndex } = this.state;

      this.saveComponent(currentIndex, null);
    }
  );

  saveComponent = (currentIndex, itemDelete) => {
    const templateName = toJS(this.props.ComponentName);
    const lastModified = getCurrentTime();
    ConditionalLogicStore.setLastModified(lastModified);
    ConditionalLogicStore.setConditionalLogicName(templateName);

    const template = toJS(ConditionalLogicStore.template);

    delete template.placeholders;
    delete template.positions;

    const data = {
      type: "conditional_logic",
      componentData: {
        name: template.name,
        data: template.data,
        lastModified: lastModified,
        itemDelete: itemDelete ? itemDelete : null,
      },
      componentId: this.props.component_id,
      description: this.props.ComponentDescription.value,
      status: this.state.status,
    };

    send_component_save_request(`component-service/conditional-logic/data`, data, "", "POST", this.props.SaveTrigger)
      .then((response) => {
        if ((response.status = 200)) {
          this.props.showSuccess(data, this.props.SaveTrigger);
          this.props.onLastModifiedChanged(lastModified);
          projectStore.savedComponent = true;
          // ConditionalLogicStore.clearData();
          // this.getConditionalLogicData(currentIndex);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (!placeholder["selected"]) return;
      const { style, key } = placeholder["selected"];

      this.insertPlaceholder(style.default, key, style);
    }
  );

  insertPlaceholder = (label, text, style) => {
    let { placeholders } = ConditionalLogicStore;
    placeholders.push({ label, text, style });

    ConditionalLogicStore.setPlaceholders(placeholders);
  };

  handleKeyChanges = (error) => {
    this.setState({ error });
  };

  handleChange = (event, value) => {
    const { placeholdersError } = toJS(ConditionalLogicStore);

    if (placeholdersError.length > 0) {
      this.props.showError();
      return;
    }

    this.saveComponent((value - 1) * 5, null);
  };

  handleAddItem = () => {
    const { currentIndex, itemsInPage } = this.state;

    if (itemsInPage === 5) {
      const { placeholdersError } = toJS(ConditionalLogicStore);

      if (placeholdersError.length > 0) {
        this.props.showError();
        return;
      }

      ConditionalLogicStore.addLogic();
      this.saveComponent(currentIndex + 5, null);
    } else {
      ConditionalLogicStore.addLogic();
    }

    this.setState({
      itemsInPage: itemsInPage + 1,
    });
  };

  copyThisLogic = (data) => {
    const { currentIndex, itemsInPage } = this.state;

    if (itemsInPage === 5) {
      const { placeholdersError } = toJS(ConditionalLogicStore);

      if (placeholdersError.length > 0) {
        this.props.showError();
        return;
      }

      ConditionalLogicStore.copyThisLogic(toJS(data));
      this.saveComponent(currentIndex + 5, null);
    } else {
      ConditionalLogicStore.copyThisLogic(toJS(data));
    }

    this.setState({
      itemsInPage: itemsInPage + 1,
    });
  };

  render() {
    const { data, newData } = toJS(ConditionalLogicStore);
    const { totalPage, currentPage } = this.state;
    if (this.state && !this.state.loading) {
      return (
        <Grid
          container
          item
          xs={12}
          className="conditional-logic"
          direction={"column"}
        >
            {data.map((d, index) => (
              <ItemLogic
                isExpanded={this.props.isExpanded}
                key={d.keyRandom}
                data={d}
                indexLogic={index}
                availablePlaceholders={this.props.availablePlaceholders}
                handleKeyChanges={this.handleKeyChanges}
                keyRandom={d.keyRandom}
                conditionalWorkflowPath={false}
                itemList={this.state.itemList}
                copyThisLogic={() => {
                  this.copyThisLogic(d);
                }}
                updateItemsInPage={() => {
                  const { itemsInPage } = this.state;
                  this.setState({
                    itemsInPage: itemsInPage - 1,
                  });
                }}
                removeItem={() => {
                  let { itemsInPage, currentIndex } = this.state;
                  itemsInPage -= 1;

                  if (itemsInPage === 0) {
                    currentIndex =
                      currentIndex - 5 < 0 ? currentIndex : currentIndex - 5;
                    this.saveComponent(currentIndex, d.keyRandom);
                  } else {
                    this.saveComponent(currentIndex, d.keyRandom);
                  }
                  this.setState({ itemsInPage });
                }}
              />
            ))}
          <Grid
            container
            item
            direction={"row"}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            {currentPage === totalPage ? (
              <div
                className="hover-add-button"
                onClick={() => this.handleAddItem()}
              >
                <AddIcon /> Add Logic
              </div>
            ) : (
              ""
            )}
          </Grid>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Pagination
              count={totalPage}
              page={currentPage}
              color="primary"
              onChange={this.handleChange}
            />
          </div>
        </Grid>
      );
    } else return <IndividualComponentLoader />;
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(ConditionalLogicTemplate));
