import React from "react";

import { styled } from "@mui/system";
import { Alert, Grid } from "@mui/material";
import { ErrorRounded } from "@mui/icons-material";

const StyledAlert = styled(Alert)({
    border: "1px solid rgba(255, 183, 77, 1)",
    background: "rgba(255, 234, 203, 1)",
    color: "black",
    fontSize: "14px",
});

function WarningBox({ text }) {
    return (
        <StyledAlert severity="warning" icon={<ErrorRounded />}>
            <Grid container>
                <Grid item>{text}</Grid>
            </Grid>
        </StyledAlert>
    );
}

export default WarningBox;
