import React, { useEffect, useState } from "react";

// mui
import { Grid, Skeleton } from "@mui/material";
import { styled } from "@mui/system";

// util
import TopBar from "./TopBar";
import Schema from "./Schema";
import { useNavigate } from "react-router";
import { send_request } from "../../utils/Request";

const StyledSkeleton = styled(Skeleton)(({ width, height, margin, radius }) => ({
    borderRadius: radius ? radius : "4px",
    background: "#E8E8E8",
    width: width,
    height: height,
    margin: margin,
}));

const Container = styled(Grid)({
    height: "100vh",
});

const TopBarGrid = styled(Grid)({
    paddingBottom: "16px",
});

const SchemaGrid = styled(Grid)({
    height: "calc(100vh - 64px)", // Modify 100% to 100vh
    overflowY: "auto", // Add overflowY property as 'auto' or 'scroll'
    width: "100%", // Ensure it takes the full width
    paddingBottom: "32px",
});

function WorkflowSchema() {
    const projectId = window.location.pathname.split("/").pop();

    // navigation + query params
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [compareLoad, setCompareLoad] = useState(false);

    const [allProjects, setAllProjects] = useState(null);
    const [workflowName, setWorkflowName] = useState(null);

    // view
    const [view, setView] = useState("split_view");

    // Dropdown selections
    const [selectionOne, setSelectionOne] = useState(null);
    const [selectionTwo, setSelectionTwo] = useState(null);

    // JSONs that are compared
    const [jsonOne, setJsonOne] = useState(null);
    const [jsonTwo, setJsonTwo] = useState(null);

    useEffect(async () => {
        setLoading(true);

        const URL = `project-service/project/all_project_versions/${projectId}`;

        // Get list of project versions and their statuses
        let json = await send_request(URL, null, null, "GET");
        if (!json || json.status !== 200) return;

        let projects = json.data;

        console.log(projects);

        setAllProjects(projects); // Set the projects

        // Check if there is a query param set
        const params = new URLSearchParams(location.search);
        const selected = params.get("selected");
        const compareWith = params.get("compareWith");

        if (selected && compareWith) {
            // Both selected and compare with are set
            const v1 = projects.find((item) => String(item.version) === String(selected));
            const v2 = projects.find((item) => String(item.version) === String(compareWith));

            if (!v1 || !v2) {
                await handleDefaultCase(projects);
                return;
            }

            setSelectionOne(`${v1.projectStatus}_version_${v1.version}`);
            setSelectionTwo(`${v2.projectStatus}_version_${v2.version}`);
            setLoading(false);

            await handleCompareJson(selected, compareWith);
            return;
        } else if (selected) {
            // Only the selected version has been set
            const v1 = projects.find((item) => String(item.version) === String(selected));

            if (!v1) {
                await handleDefaultCase(projects);
                return;
            }

            setSelectionOne(`${v1.projectStatus}_version_${v1.version}`);
            setSelectionTwo(`${v1.projectStatus}_version_${v1.version}`);
            setLoading(false);

            await handleCompareJson(selected, selected);

            return;
        }

        // Default to latest workflow
        await handleDefaultCase(projects);
    }, []);

    const handleDefaultCase = async (projects) => {
        let latestProject = projects[projects.length - 1];
        let data = await getTranslatedWorkflow(projectId, latestProject.version);

        if (!data) return;

        setWorkflowName(data.name);

        setSelectionOne(`${latestProject.projectStatus}_version_${latestProject.version}`);
        setSelectionTwo(`${latestProject.projectStatus}_version_${latestProject.version}`);

        setJsonOne(data);
        setJsonTwo(data);

        // Set the selected and compareWith for the default case
        const params = new URLSearchParams(window.location.search);
        params.set("selected", latestProject.version);
        params.set("compareWith", latestProject.version);
        navigate({ search: params.toString() });

        setLoading(false);
    };

    const goBack = () => {
        navigate(`/project/canvas/${projectId}`);
    };

    const getTranslatedWorkflow = async (projectId, version) => {
        const url = `project-service/project-ai/assistant/retrieve_workflow_translation/${projectId}?version=${version}`;
        const json = await send_request(url, null, null, "GET");
        return json.data;
    };

    const handleCompareJson = async (selectedV1, selectedV2) => {
        try {
            setCompareLoad(true);

            let versionOne = selectedV1 ? selectedV1 : selectionOne.split("_")[2];
            let versionTwo = selectedV2 ? selectedV2 : selectionTwo.split("_")[2];

            const res = await Promise.all([getTranslatedWorkflow(projectId, versionOne), getTranslatedWorkflow(projectId, versionTwo)]); // Execute logic
            setJsonOne(res[0]);
            setJsonTwo(res[1]);

            // Set into the query params if not already selected
            if (!selectedV1 && !selectedV2) {
                const params = new URLSearchParams(window.location.search);
                params.set("selected", versionOne);
                params.set("compareWith", versionTwo);
                navigate({ search: params.toString() });
            }

            setCompareLoad(false);
        } catch (e) {}
    };

    const getSkeletonLoaders = () => {
        return (
            <Grid container padding="24px" rowSpacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="40px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="175px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="200px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="40px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="200px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="125px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="150px" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <StyledSkeleton variant="rectangular" height="1000px" width="100%" />
                </Grid>
            </Grid>
        );
    };

    return !loading ? (
        <Container container padding="24px" rowSpacing={2}>
            <TopBarGrid item xs={12}>
                <TopBar
                    goBack={goBack}
                    workflowName={workflowName}
                    allProjects={allProjects}
                    handleCompareJson={handleCompareJson}
                    selectionOne={selectionOne}
                    selectionTwo={selectionTwo}
                    setSelectionOne={setSelectionOne}
                    setSelectionTwo={setSelectionTwo}
                    compareLoad={compareLoad}
                    view={view}
                    setView={setView}
                />
            </TopBarGrid>
            <SchemaGrid item xs={12}>
                <Schema jsonOne={jsonOne} jsonTwo={jsonTwo} view={view} compareLoad={compareLoad} StyledSkeleton={StyledSkeleton} />
            </SchemaGrid>
        </Container>
    ) : (
        getSkeletonLoaders()
    );
}

export default WorkflowSchema;
