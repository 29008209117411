import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { observer } from "mobx-react";

import DragComponent from "./DragComponent";

const styles = (theme) => ({
  drawer: {},
  drawerPaper: {},
  toolbar: theme.mixins.toolbar,
});

class ComponentsSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [
        {
          type: 7,
          text: "Calendar",
        },
        {
          type: 5,
          text: "Checkbox",
        },
        {
          type: 11,
          text: "Content Block",
        },
        {
          type: 10,
          text: "Dropdown",
        },
        {
          type: 18,
          text: "Get Database Cell",
        },
        {
          type: 16,
          text: "Embed video",
        },
        {
          type: 13,
          text: "E-Signature",
        },
        {
          type: 14,
          text: "File preview",
        },
        {
          type: 12,
          text: "List",
        },
        {
          type: 2,
          text: "Long Text",
        },
        {
          type: 3,
          text: "Map",
        },
        {
          type: 1,
          text: "Number Input",
        },
        {
          type: 8,
          text: "Page Break",
        },
        {
          type: 4,
          text: "Radio Button",
        },
        {
          type: 19,
          text: "Repeatable Section",
        },
        {
          type: 17,
          text: "Rich Text Editor",
        },
        {
          type: 9,
          text: "Short Text",
        },
        {
          type: 15,
          text: "Table",
        },
        {
          type: 6,
          text: "Upload File",
        },
      ],
    };
  }

  render() {
    const { classes } = this.props;
    try {
      return (
        <div
          style={{
            position: "sticky",
            top: "16px",
            font: "inherit",
            width: "min-content",
            boxShadow: "rgb(0 0 0 / 25%) 0px 4px 8px",
            backgroundColor: "rgb(255, 255, 255)",
            padding: 8,
            borderRadius: 6,
            maxWidth: 280,
          }}
        >
          <div
            style={{
              maxWidth: 280,
              overflowY: "scroll",
              height: "max-content",
              borderRadius: "8px",
              maxHeight: "90vh",
              padding: 8,
            }}
          >
            {this.state.components.map((n) => {
              return <DragComponent item={n} />;
            })}
          </div>
        </div>
      );
    } catch (e) {
      return <div></div>;
    }
  }
}

ComponentsSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(observer(ComponentsSidebar));
