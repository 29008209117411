import React, { useState, useEffect, Fragment } from "react";

// MUI
import { Autocomplete, Chip, Grid, InputAdornment, MenuItem, Select, styled, TextField } from "@mui/material";
import FormMentionEditor from "../../../Component/FormMentionEditor";
import { send_request } from "../../../../utils/Request";
import TextIcon from "../../../Component/Icons/TextIcon";
import NumberIcon from "../../../Component/Icons/NumberIcon";
import BooleanIcon from "../../../Component/Icons/BooleanIcon";
import DatetimeIcon from "../../../Component/Icons/DatetimeIcon";
import ListIcon from "../../../Component/Icons/ListIcon";
import { StyledSkeleton } from "../../../pages/Canvas/RenamePlaceholderLogic/RenameStyledComponents";
import { getColourFromString } from "../../../../utils/ColourNameGenerator";
import ListChip from "../../ConfigComponents/ListChip";

export const getColumnIcon = (type) => {
    if (!type) return;

    switch (type) {
        case "VARCHAR2":
            return <TextIcon style={{ height: "16px" }} />;
        case "DECIMAL":
            return <NumberIcon style={{ height: "16px" }} />;
        case "BOOLEAN":
            return <BooleanIcon style={{ height: "16px" }} />;
        case "DATETIME":
            return <DatetimeIcon style={{ height: "16px" }} />;
        case "LIST":
            return <ListIcon style={{ height: "16px" }} />;
        default:
            return <></>;
    }
};

function ColumnAndRecordSelection({
    selectedDatabase,
    displayValue,
    setDisplayValue,
    SectionTitle,
    isAssignTask,
    setColumnID,
    columnID,
    setRecordID,
    recordID,
    dropdown,
    setDropdown,
    availablePlaceholders,
}) {
    const [isFetchingColumns, setIsFetchingColumns] = useState(false);

    const [columns, setColumns] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState(null);

    useEffect(() => {
        if (!selectedDatabase) return;
        getColumns(selectedDatabase.databaseId);
    }, [selectedDatabase]);

    const getColumns = async (databaseId) => {
        setIsFetchingColumns(true);

        let url = `database/get_columns/${databaseId}`;
        const json = await send_request(url, null, null, "GET");

        if (json && json.data) {
            // Check if columnID is passed
            if (columnID) {
                let found = false;
                for (let column of json.data) {
                    if (column.columnId !== columnID) continue;
                    setSelectedColumn(column);
                    found = true;
                    break;
                }
                if (!found) setSelectedColumn(null); // If not found, set to null
            } else {
                setSelectedColumn(null);
            }
            setColumns(json.data);
        }

        setIsFetchingColumns(false);
    };

    const getColumnSelectionAutocomplete = () => {
        return (
            <Autocomplete
                size="small"
                disableClearable
                options={columns}
                renderInput={(params) => <TextField size="small" {...params} placeholder="Select a column" />}
                getOptionLabel={(option) => (option.name ? option.name : option.columnId)}
                renderOption={(props, option) => (
                    <li {...props} key={option.columnId}>
                        {getColumnIcon(option.type)} {option.name ? option.name : option.columnId}
                    </li>
                )}
                onChange={(e, value) => {
                    if (!value) return;
                    setSelectedColumn(value);
                    setColumnID(value.columnId);
                }}
                value={selectedColumn}
            />
        );
    };

    const getRecordIDSelection = () => {
        let ansList = [];

        if (isAssignTask) ansList = [[recordID]];
        else if (!recordID) ansList = [];
        else ansList = [[recordID]];

        return (
            <ListChip
                allowOne
                availablePlaceholders={isAssignTask ? availablePlaceholders : []}
                answer_list={ansList}
                handleReplaceAnswerList={(value) => {
                    try {
                        if (value && value.length === 1) {
                            setRecordID(value[0][0]);
                            return;
                        }

                        setRecordID("");
                    } catch (e) {
                        setRecordID("");
                    }
                }}
                placeholderText="Type in the record ID"
            />
        );
    };

    return (
        <>
            <Grid item xs={12}>
                <Select
                    disableUnderline
                    variant="filled"
                    value={displayValue}
                    style={{
                        width: "fit-content",
                        backgroundColor: "#F8F8F8",
                        borderRadius: "4px",
                        color: "#868686",
                        minWidth: "250px",
                    }}
                >
                    <MenuItem value="display_cell">Display a specific cell</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SectionTitle isBold>Column</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                        {!isFetchingColumns ? getColumnSelectionAutocomplete() : <StyledSkeleton variant="rectangular" height="35px" />}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {selectedColumn && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SectionTitle isBold>Record ID</SectionTitle>
                        </Grid>
                        <Grid item xs={12}>
                            {!isFetchingColumns ? getRecordIDSelection() : <StyledSkeleton variant="rectangular" height="35px" />}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default ColumnAndRecordSelection;
