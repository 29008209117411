// Required imports
import { send_request } from "../Request";
import config from "../../config";

/**
 * This function is meant to be added to Jodit for inserting images.
 *
 * @param {Object} joditRef - Reference to the Jodit instance.
 * @param {string} projectId - The project ID.
 * @return {function} - Returns a function to handle the insert image event.
 */

export function insertImage(joditRef, projectId) {
  return async function(event) {
    if (event.type !== "paste") return;

    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;

    for (let item of items) {
      if (item.kind !== "file") continue;

      const blob = item.getAsFile();
      const uniquePlaceholderId = `loading-img-${Date.now()}`;

      const jodit = joditRef && joditRef.current ? joditRef.current : joditRef;
      if (jodit) {
        jodit.value += `<div id="${uniquePlaceholderId}" class="skeleton"></div>`;
      }

      const formData = new FormData();
      formData.append("file", blob);
      formData.append("configId", "fileupload");
      formData.append("type", "upload");
      formData.append("projectId", projectId);
      formData.append("projectSessionId", "");
      formData.append("formSessionId", "");

      try {
        const json = await send_request(
          "project-service/project/file-library/create",
          formData,
          "",
          "POST",
          { "content-type": "multipart/form-data" }
        );

        if (json && json.data) {
          const url = `${config.FILE_SERVICE.QUERY_URL}/${json.data.id}`;
          const imgTag = `<img src="${url}" />`;

          if (jodit) {
            // sometimes the div gets a <br> tag which was causing the replacement to fail,
            // so I have added a wild card to handle cases where <br> is present or not
            jodit.value = jodit.value.replace(
              new RegExp(
                `<div id="${uniquePlaceholderId}" class="skeleton">(.*?)</div>`
              ),
              imgTag
            );
          }
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        if (jodit) {
          jodit.value = jodit.value.replace(
            new RegExp(
              `<div id="${uniquePlaceholderId}" class="skeleton"></div>`
            ),
            ""
          );
        }
      }
    }
  };
}

export default insertImage;
