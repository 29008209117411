import React, {Component} from 'react';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {toJS} from "mobx";
import ReactJson from "react-json-view";
import PlaceholderJson from "../../Component/PlaceholderJson";
import {Grid} from "@mui/material";

class SetUpPlaceholders extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checkKey: false,
      error:false
    };
  }


  handleReactJson = () => {
    const { data } = toJS(this.props.componentStore);
    if (data.json != ""){
      let jsonData = JSON.parse(data.json)

      return (
        <ReactJson
          src={
            jsonData
              ? jsonData
              : {}
          }
          collapsed="true"
          name="Data"
          displayDataTypes={false}
          style={{ fontFamily: "Open Sans !important" }}
          theme={{
            base00: "rgba(1, 1, 1, 0)",
            base01: "rgba(1, 1, 1, 0.1)",
            base02: "rgba(0, 0, 0, 0.2)",
            base03: "rgba(1, 1, 1, 0.3)",
            base04: "rgba(0, 0, 0, 0.4)",
            base05: "rgba(1, 1, 1, 0.5)",
            base06: "rgba(1, 1, 1, 0.6)",
            base07: "rgba(1, 1, 1, 0.7)",
            base08: "rgba(1, 1, 1, 0.8)",
            base09: "rgba(1, 1, 1, 0.8)",
            base0A: "rgba(1, 1, 1, 0.8)",
            base0B: "rgba(1, 1, 1, 0.8)",
            base0C: "rgba(1, 1, 1, 0.8)",
            base0D: "rgba(1, 1, 1, 0.8)",
            base0E: "rgba(1, 1, 1, 0.8)",
            base0F: "rgba(1, 1, 1, 0.8)",
          }}
        />
      )
    }
  }

  render() {
    const { data, placeholderError } = toJS(this.props.componentStore);
    const {method} = this.props

    return (
      <Grid item xs={12} className="textAlignLeftWebhook">
        <span>We received the following sample data. Click next if this looks correct otherwise go back and try again by uploading another sample. </span><br/>
        <Grid
          item xs={12}
          className="json-webhook"
        >
          {
            this.handleReactJson()
          }
        </Grid>
        <Grid
          item xs={12}
          style={{margin:"24px 0"}}
        >
          The following placeholders were mapped based on the sample data.
        </Grid>
        <PlaceholderJson
          componentStore={this.props.componentStore}
          stepStatus={"setPlaceholders"}
          handleKeyChanges={(error) => {
          this.setState({error})
          this.props.handleKeyChanges(error)
          }}
          data={toJS(this.props.componentStore).data}
          placeholderJson={data.samplePlaceholders}
        />
        <Grid item xs={12} className="dialogContentRight" >
          <Grid item xs={12}>
            <Button
              onClick={() => {this.props.onChangeState(3,3) }}
              className="btnSpace"
              variant={"outlined"}  
              color={"info"}

              disabled={placeholderError.length > 0}
            >
              {" "}
              Back
            </Button>
            <Button
              onClick={() => this.props.onChangeState(5,5)}
              disabled={this.state.error || placeholderError.length > 0}
              variant={"contained"} color={"primary"} 
            >
              {" "}
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SetUpPlaceholders;
