import React, { useState, useEffect } from "react";
import { Close, Link, LinkOff, OpenInNew, Refresh, Storage, StorageOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, styled, Skeleton } from "@mui/material";
import AddDatabaseUI from "./AddDatabaseUI";

const DescriptionText = styled("span")({
    fontSize: "13px",
    color: "rgba(117, 117, 117, 1)",
    lineHeight: "18px",
});

const DatabaseItem = styled(Grid)({
    padding: "6px",
    background: "rgba(245, 245, 245, 1)",
    borderRadius: "4px",
    fontSize: "14px",
});

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "6px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

const AddDBText = styled("span")(({ color, hoverColor }) => ({
    fontSize: "14px",
    color: color ? color : "rgba(33, 150, 243, 1)",
    "&:hover": {
        cursor: "pointer",
        color: hoverColor ? hoverColor : "rgba(33, 150, 243, 0.85)",
    },
}));

const OpenIcon = styled(OpenInNew)({
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33, 150, 243, 1)",
    },
});

const CloseStyled = styled(Close)({
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33, 150, 243, 1)",
    },
});

const BlueText = styled("span")({
    fontSize: "14px",
    color: "rgba(33, 150, 243, 1)",
});

function CreatedButNotConnectedDBs({ projectId, draftVersion, loading, connectedDBs, createdButNotConnectedDBs, setCreatedButNotConnectedDBs, removeDBLinks }) {
    const [showAddDatabaseUI, setShowAddDatabaseUI] = useState(false);

    const handleRemoveDatabase = async (dB) => {
        if (!dB) return;

        // Make request to remove database from list
        await removeDBLinks([dB.databaseId]);

        let newDBs = createdButNotConnectedDBs.filter((notConnectedDB) => notConnectedDB.databaseId !== dB.databaseId);
        setCreatedButNotConnectedDBs(newDBs);
    };

    const getCreatedButNotConnectedDBs = () => {
        if (loading) {
            return [0, 1, 2].map(() => {
                return <StyledSkeleton variant="rectangular" height="28px" />;
            });
        }

        return createdButNotConnectedDBs.map((dB) => {
            return (
                <DatabaseItem item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                    <Grid item display="flex" alignItems="center" justifyContent="center">
                        <StorageOutlined fontSize="14px" style={{ marginRight: "16px" }} />
                        <BlueText>{dB.name}</BlueText>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item>
                                <OpenIcon fontSize="14px" onClick={() => window.open(`/databases/database_management/${dB.databaseId}`, "_blank")} />
                            </Grid>
                            <Grid item>
                                <CloseStyled fontSize="16px" onClick={() => handleRemoveDatabase(dB)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DatabaseItem>
            );
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
                <Grid container spacing={1}>
                    <Grid item>
                        <LinkOff style={{ width: "18px " }} />
                    </Grid>
                    <Grid item>
                        <b>Connected but inactive databases</b>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DescriptionText>These databases have been linked to this workflow but are not actively used by any components.</DescriptionText>
            </Grid>
            <Grid item xs={12}>
                <DescriptionText>
                    This list is used by the AI when building workflows and components. You can remove databases no longer relevant or add in databases that you want the AI to consider when building workflows and components.
                </DescriptionText>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap="8px">
                    {getCreatedButNotConnectedDBs()}
                </Grid>
                <Grid container marginTop={1}>
                    {!showAddDatabaseUI ? (
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                            <AddDBText onClick={() => setShowAddDatabaseUI(true)}>+ Add database</AddDBText>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <AddDatabaseUI
                                AddDBText={AddDBText}
                                StyledSkeleton={StyledSkeleton}
                                setShowAddDatabaseUI={setShowAddDatabaseUI}
                                createdButNotConnectedDBs={createdButNotConnectedDBs}
                                setCreatedButNotConnectedDBs={setCreatedButNotConnectedDBs}
                                connectedDBs={connectedDBs}
                                projectId={projectId}
                                version={draftVersion}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreatedButNotConnectedDBs;
