const styles = theme => ({
    wrapFlexCenter: {
        margin: "24px 22px 24px 0"
    },
    textContentCenter: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        "&:hover": {
            color: "#2196F3"
        }
    },
    deleteButtonStyle: {
        marginTop: 7,
        color: "#868686",
        cursor: "pointer",
        '&:hover': {
            color: "#2196f3",
            cursor: "pointer",
        }
    },
    gridMargin: {
        marginTop: 10,
        marginBottom: 10
    },
    textNote : {
        color: "#868686"
    },
    dialogContent: {
        padding: 24
    },
    dialogText: {
        margin: "0 0 8px",
        fontSize: "14px",
        color: "#868686",
        lineHeight: "28px"
    },
    dialogImage: {
        width: "50%"
    },
    imageCenter: {
        display: "flex",
        justifyContent: "center"
    },
    dropzone: {
        width: "100%",
        margin: "15px 0",
        backgroundColor: theme.palette.background.paper,
    }
});
export default styles;
