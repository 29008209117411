import React, { useState, useEffect } from "react";

import { CardTitle, DashboardCard, StyledSkeleton, CenterGrid, StyledButton } from "./CommonComponents";
import { send_request } from "../../../utils/Request";

// MUI
import { CircularProgress, Grid, styled } from "@mui/material";
import ProjectCard from "../ProjectCard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import { getCurrentTime } from "../../../utils/getCurrentTime";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import "./RecentlyUpdatedWorkflowsCard.css";

const NoWorkflowsCard = styled(Grid)({
    border: "1px dashed rgba(134, 134, 134, 1)",
    borderRadius: "10px",
    background: "#FFF",
    minHeight: "235px",
});

function RecentlyUpdatedWorkflowsCard() {
    const [recentWorkflows, setRecentWorkflows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [creatingWF, setCreatingWF] = useState(false);

    useEffect(async () => {
        setLoading(true);

        let url = "project-service/project/getRecentlyUpdatedProjects/3";
        const json = await send_request(url, null, null, "GET");

        if (json && json.data) setRecentWorkflows(processWorkflowCards(json.data));

        setLoading(false);
    }, []);

    const processWorkflowCards = (workflows) => {
        const cards = workflows.map((card) => {
            return {
                description: card.description ? card.description : "",
                last_modified: card.lastModified,
                project_name: card.name || "Untitled",
                project_id: card.projectId,
                parent_folder_id: card.parentFolderId,
                components: card.components,
                imagePath: card.imagePath,
                isActive: card.isActive || false,
                status: card.status,
                hasPublicVersion: card.hasPublicVersion,
                version: card.version,
                categories: card.categories,
            };
        });

        return cards;
    };

    const createWorkflow = async () => {
        setCreatingWF(true);

        let newWF = {
            status: "DRAFT",
            lastModified: getCurrentTime(),
        };

        let url = "project-service/project/create";
        const json = await send_request(url, newWF, "", "post");

        if (json && json.data) {
            window.open(`/project/canvas/${json.data.projectId}`, "_blank");
        }

        setCreatingWF(false);
    };

    const getCards = () => {
        if (!recentWorkflows || recentWorkflows.length <= 0) {
            return (
                <Grid item xs={4}>
                    <NoWorkflowsCard container display="flex" alignItems="center" justifyContent="center">
                        <CenterGrid item xs={12}>
                            <CenterGrid container rowSpacing={1}>
                                <CenterGrid item xs={12}>
                                    <StyledButton variant="contained" startIcon={<AddCircleOutlineIcon />} disabled={creatingWF} onClick={createWorkflow}>
                                        NEW WORKFLOW
                                        {creatingWF && <CircularProgress size={24} className="buttonProgress" />}
                                    </StyledButton>
                                </CenterGrid>
                                <CenterGrid item xs={12}>
                                    <StyledButton
                                        variant="contained"
                                        startIcon={<DownloadIcon />}
                                        bgColor="rgba(85, 167, 122, 1)"
                                        hoverBgColor="rgba(85, 167, 122, 0.9)"
                                        onClick={() => {
                                            window.open("/marketplace", "_blank");
                                        }}
                                    >
                                        IMPORT TEMPLATE
                                    </StyledButton>
                                </CenterGrid>
                            </CenterGrid>
                        </CenterGrid>
                    </NoWorkflowsCard>
                </Grid>
            );
        } else {
            return recentWorkflows.map((wF, index) => {
                return (
                    <Grid item xs={4} key={index}>
                        <div className="recentUpdateCardFrame">
                            <ProjectCard
                                card={wF}
                                index={index}
                                disableEditing
                                openInNewTab
                            />
                        </div>
                    </Grid>
                );
            });
        }
    };

    return (
        <DashboardCard container rowGap={"12px"}>
            <Grid item xs={12}>
                <CardTitle>Recently updated workflows</CardTitle>
            </Grid>
            <Grid item xs={12}>
                <Grid container columnSpacing={2}>
                    {!loading
                        ? getCards()
                        : [0, 1, 2].map(() => {
                              return (
                                  <Grid item xs={4}>
                                      <StyledSkeleton variant="rectangular" height="230px" width="100%" />
                                  </Grid>
                              );
                          })}
                </Grid>
            </Grid>
            <CenterGrid item xs={12} marginTop={1}>
                <StyledButton
                    variant="outlined"
                    startIcon={<ArrowRightAltIcon />}
                    textColor="rgba(33, 150, 243, 1)"
                    hoverTextColor="rgba(33, 150, 243, 0.8)"
                    border="none"
                    onClick={() => window.open("/project", "_target")}
                >
                    Go to Workflow Library
                </StyledButton>
            </CenterGrid>
        </DashboardCard>
    );
}

export default RecentlyUpdatedWorkflowsCard;