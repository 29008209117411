import { Grid, styled } from "@mui/material";

export const EdgeLabelDiv = styled("div")(({ labelX, labelY }) => ({
    position: "absolute",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    pointerEvents: "all",
    zIndex: "99999999999 !important",
}));

export const LabelText = styled("span")(({ color }) => ({
    fontSize: "12px",
    width: "100%",
    textAlign: "center",
    color: color,
}));

export const EdgeBox = styled(Grid)(({ background, borderRadius }) => ({
    background: background ? `${background} !important` : "#FFF !important",
    borderRadius: borderRadius ? borderRadius : "6px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));