import React, {Component} from "react";
import {Grid, MenuItem, Paper, Select, TextField} from "@mui/material";
import checkKey from "../../utils/CheckKey";
import DeleteIcon from "@mui/icons-material/Delete";
import {withStyles} from "@mui/styles";
import ChipInput from "../ChipInput/ChipInput";
import AddIcon from "@mui/icons-material/Add";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import styles from "../WorkflowData/DataBankStyle";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import {FileCopyOutlined} from "@mui/icons-material";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import {
  isPlaceholder,
} from "../../utils/PlaceholderUtil";
class RenderQueryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkKey: false,
      errorMessage: "",
        showWarning:false
    };

    //Adding type to all condition objects
    let queryDataLocal = props.queryData;
    let allColumns = props.availableCols;
    //Loop through every single data component in query data
    for (let queryIndex in queryDataLocal){
      //Loop through all the conditions in the data component
      for (let index in queryDataLocal[queryIndex].condition){
        let condition = queryDataLocal[queryIndex].condition[index];
        let column = allColumns.find((col) => col.name == condition.columnName);
        let type = "VARCHAR2"
        
        if (column){
          //Session id and database id dont have a type so will automatically give it a type
          type = column.type ? allColumns.find((col) => col.name == condition.columnName).type : "VARCHAR2";
        }
        queryDataLocal[queryIndex].condition[index].type = type ;

        //Adding Input type in queryData e.g. Whether a date time field should show up or a placeholder input box should show up
        if (condition.value && condition.type === "DATETIME" && isPlaceholder(condition.value)){
          queryDataLocal[queryIndex].condition[index].datetimeValue = "placeholder";
        } else {
          queryDataLocal[queryIndex].condition[index].datetimeValue = "datetime";
        }

        if (column === undefined){
          condition.columnName = null;
        }
      }
      props.setQueryData(queryDataLocal);
    }

  }

  renderQueryItemDetails = (outputFunc, aData, index) => {
    const {
      classes,
      availablePlaceholders,
      queryData,
      availableCols,
      component,
      validateKey,
    } = this.props;
    if (outputFunc === "SELECTED_COLUMN") {
      return (
        <Grid  style={{ marginTop: "8px" }} container>
          <Grid
            style={{
              minWidth: "max-content",
              maxWidth: "max-content",
              flexBasis: "auto",
              marginRight: "24px",
              paddingTop: "8px",
            }}
            item
            xs={2}
          >
            <p style={{ fontWeight: "600 !important" }}>
              {component === "query_database_record"
                ? "Return values from"
                : "Output data from"}
            </p>
          </Grid>
          <Grid style={{ marginTop: "16px" }} item error>
            <FormControl error={aData.value == null ? true : false}>
              <Select
                style={{
                  width: "max-content",
                  minWidth: "280px",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "4px",
                  color: "#868686",
                  marginRight: "24px",
                  verticalAlign: "top",
                  visibility: "",
                }}
                name="selectType"
                disableUnderline
                variant="standard"
                labelId="demo-simple-select-outlined-label"
                className="selectStandard"
                displayEmpty
                value={aData.value}
                onChange={(value) =>
                  this.setOutputValue(index, value, queryData)
                }
              >
                <MenuItem value={null} disabled>
                  {" "}
                  select column{" "}
                </MenuItem>
                {availableCols.map((aCol, aColIndex) => (
                  <MenuItem
                    value={
                      aCol.placeholderKey
                        ? aCol.placeholderKey
                        : aCol.realValue.toUpperCase()
                    }
                  >
                    {" "}
                    {aCol.name}{" "}
                  </MenuItem>
                ))}
              </Select>
              {aData.value == null && validateKey ? (
                <FormHelperText>This field cannot be empty</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>

          </Grid>
            {component === "query_database_record" ? this.getReturnTypeSelect(aData, index, queryData) : ""}

        </Grid>

      );
    } else if (outputFunc === "FILTERED_COLUMN") {
      return (
        <>
          <Grid style={{ marginTop: "8px" }} container>
            <Grid
              style={{
                minWidth: "max-content",
                maxWidth: "max-content",
                flexBasis: "auto",
                marginRight: "24px",
                paddingTop: "8px",
              }}
              item
              xs={2}
            >
              <p style={{ fontWeight: "600 !important" }}>
                {component === "query_database_record"
                  ? "Return values from"
                  : "Output data from"}
              </p>
            </Grid>
            <Grid
              style={{ marginTop: "16px" }}
              className={classes.selectError}
              item
            >
              <FormControl error={aData.value == null ? true : false}>
                <Select
                  style={{
                    width: "max-content",
                    minWidth: "280px",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "4px",
                    color: "#868686",
                    marginRight: "24px",
                    verticalAlign: "top",
                    visibility: "",
                  }}
                  name="selectType"
                  disableUnderline
                  variant="standard"
                  labelId="demo-simple-select-outlined-label"
                  className="selectStandard"
                  displayEmpty
                  value={aData.value}
                  onChange={(value) => {
                      this.setOutputValue(index, value, queryData);
                    }
                  }
                >
                  <MenuItem value={null} disabled>
                    select column
                  </MenuItem>
                  {availableCols.map((aCol, aColIndex) => (
                    <MenuItem
                      value={
                        aCol.placeholderKey
                          ? aCol.placeholderKey
                          : aCol.realValue.toUpperCase()
                      }
                    >
                      {" "}
                      {aCol.name}{" "}
                    </MenuItem>
                  ))}
                </Select>
                {aData.value == null && validateKey ? (
                  <FormHelperText>This field cannot be empty</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>

              <span
                style={{
                  fontWeight: "600 !important",
                  marginTop: 8,
                  display: "inline-flex",
                }}
              >
              </span>
            </Grid>

          </Grid>
            {component === "query_database_record" ? this.getReturnTypeSelect(aData, index, queryData) : ""}

            <Grid style={{ marginTop: "8px" }} container direction="row" xs={12}>
            <Grid
              style={{
                marginRight: "16px",
                maxWidth: "max-content",
                width: "max-content",
                paddingTop: "8px",
                flexBasis: "auto",
              }}
              item
              xs={2}
            >
              <p style={{ fontWeight: "600 !important", marginTop: 16 }}>
                Where the values in
              </p>
            </Grid>
            <Grid item xs={10} direction="column">
              {aData.condition &&
                aData.condition.map((aDataCondition, aDataConditionIndex) => (
                  <Grid item xs={12}>
                    {aDataConditionIndex > 0 && (
                      <Select
                        style={{
                          width:
                            aDataConditionIndex == 0 ? "0px" : "max-content",
                          minWidth: aDataConditionIndex == 0 ? "0px" : "80px",
                          backgroundColor: "#F8F8F8",
                          borderRadius: "4px",
                          marginTop: 16,
                          color: "#868686",
                          marginRight:
                            aDataConditionIndex == 0 ? "0px" : "16px",
                          verticalAlign: "top",
                          visibility: aDataConditionIndex == 0 ? "hidden" : "",
                        }}
                        name="selectType"
                        disableUnderline
                        variant="standard"
                        labelId="demo-simple-select-outlined-label"
                        className="selectStandard"
                        value={aDataCondition.logic}
                        onChange={(event) => {
                          let logic = event.target.value;

                          this.updateCondition(
                            index,
                            aDataConditionIndex,
                            logic,
                            aDataCondition.key,
                            aDataCondition.function,
                            aDataCondition.value,
                            aDataCondition.datetimeValue,
                            queryData
                          );
                        }}
                      >
                        <MenuItem value="AND">AND</MenuItem>
                        <MenuItem value="OR">OR</MenuItem>
                      </Select>
                    )}
                    <div style={{ width: "100%" }} />
                    <FormControl
                      error={aDataCondition.key == null ? true : false}
                    >
                      <Select
                        style={{
                          width: "max-content",
                          backgroundColor: "#F8F8F8",
                          borderRadius: "4px",
                          marginTop: 16,
                          color: "#868686",
                          minWidth: "280px",
                          marginRight: "24px",
                          verticalAlign: "top",
                        }}
                        name="selectType"
                        disableUnderline
                        variant="standard"
                        labelId="demo-simple-select-outlined-label"
                        className="selectStandard"
                        value={aDataCondition.key}
                        displayEmpty
                        onChange={(event) => {
                          let key = event.target.value;
                          const { availableCols } = this.props;
                          const column = availableCols.find((col) => col.placeholderKey === key);
                          if ( (aDataCondition.function === "MATCHES" || aDataCondition.function === "NOTMATCH") && column.type === "DATETIME"){
                            this.updateCondition(
                              index,
                              aDataConditionIndex,
                              aDataCondition.logic,
                              key,
                              "GREATER_THAN",
                              aDataCondition.value,
                              aDataCondition.datetimeValue,
                              queryData
                            );
    
                          } else {
                            this.updateCondition(
                              index,
                              aDataConditionIndex,
                              aDataCondition.logic,
                              key,
                              aDataCondition.function,
                              aDataCondition.value,
                              aDataCondition.datetimeValue,
                              queryData
                            );
                          }

                        }}
                      >
                        <MenuItem value={null} disabled>
                          select column
                        </MenuItem>
                        {availableCols.map((aCol, aColIndex) => (
                          <MenuItem
                            value={
                              aCol.placeholderKey
                                ? aCol.placeholderKey
                                : aCol.realValue.toUpperCase()
                            }
                          >
                            {" "}
                            {aCol.name}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                      {aDataCondition.key == null && validateKey ? (
                        <FormHelperText>
                          This field cannot be empty
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    <FormControl
                      error={aDataCondition.function == null ? true : false}
                    >
                      <Select
                        style={{
                          width: "max-content",
                          backgroundColor: "#F8F8F8",
                          borderRadius: "4px",
                          marginTop: 16,
                          color: "#868686",
                          minWidth: "160px",
                          marginRight: "24px",
                          verticalAlign: "top",
                        }}
                        name="selectType"
                        disableUnderline
                        variant="standard"
                        labelId="demo-simple-select-outlined-label"
                        className="selectStandard"
                        displayEmpty
                        value={aDataCondition.function}
                        onChange={(event) => {
                          let func = event.target.value;
                          let value = aDataCondition.value;
                          if (func === "EMPTY" || func === "NOTEMPTY") {
                            value = "";
                          }
                          this.updateCondition(
                            index,
                            aDataConditionIndex,
                            aDataCondition.logic,
                            aDataCondition.key,
                            func,
                            value,
                            aDataCondition.datetimeValue,
                            queryData
                          );
                        }}
                      >
                        <MenuItem value={null} disabled>
                          {" "}
                          Function{" "}
                        </MenuItem>
                        <MenuItem value="EQUAL">equals</MenuItem>
                        <MenuItem value="NOT_EQUAL">does not equal</MenuItem>
                        {aDataCondition.type !== "DATETIME" && (
                        <MenuItem value="MATCHES"> contains </MenuItem>
                        )}
                        {aDataCondition.type !== "DATETIME" && (
                        <MenuItem value="NOTMATCH"> does not contain </MenuItem>
                        )}
                        <MenuItem value="GREATER_THAN">greater than</MenuItem>
                        <MenuItem value="GREATERTHANOREQUAL">
                          greater than or equal to
                        </MenuItem>
                        <MenuItem value="LESS_THAN">less than</MenuItem>
                        <MenuItem value="LESSTHANOREQUAL">
                          {" "}
                          less than or equal to{" "}
                        </MenuItem>
                        <MenuItem value="NOTEMPTY"> is not empty </MenuItem>
                        <MenuItem value="EMPTY"> is empty </MenuItem>
                      </Select>
                      {aDataCondition.function == null && validateKey ? (
                        <FormHelperText>
                          This field cannot be empty
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    { aDataCondition.type === "DATETIME" &&
                      <FormControl
                        error={aDataCondition.function == null ? true : false}
                      >
                        <Select
                          style={{
                            width: "max-content",
                            backgroundColor: "#F8F8F8",
                            borderRadius: "4px",
                            marginTop: 16,
                            color: "#868686",
                            minWidth: "160px",
                            marginRight: "24px",
                            verticalAlign: "top",
                          }}
                          name="selectType"
                          disableUnderline
                          variant="standard"
                          labelId="demo-simple-select-outlined-label"
                          className="selectStandard"
                          displayEmpty
                          value={aDataCondition.datetimeValue ? aDataCondition.datetimeValue : null}
                          onChange={(event) => {
                            let func = aDataCondition.function;
                            let value = aDataCondition.value;
                            let datetimeValue = event.target.value
                            this.updateCondition(
                              index,
                              aDataConditionIndex,
                              aDataCondition.logic,
                              aDataCondition.key,
                              func,
                              value,
                              datetimeValue,
                              queryData
                            );
                          }}
                        >
                          <MenuItem value={null} disabled>
                          {" "}
                          Datetime input method{" "}
                        </MenuItem>
                          <MenuItem value="datetime">A specific datetime value</MenuItem>
                          <MenuItem value="placeholder">A datetime placeholder</MenuItem>
                        </Select>
                      </FormControl>
                    }
                    {aDataCondition.function === "EMPTY" ||
                    aDataCondition.function === "NOTEMPTY" ? (
                      ""
                    ) : (
                        <>
                          
                          {/*If the data type is DateTime use the DateTimePicker */}
                          { aDataCondition.type === "DATETIME" && aDataCondition.datetimeValue === "datetime" ? (

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                  sx={{
                                    width: "330px",
                                    marginTop: 2,
                                    marginRight: 24,
                                    verticalAlign: "top"
                                  }}
                                  viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock,
                                  }}
   
                                  format="YYYY/MM/DD hh:mm a"
                                  label = "Pick a datetime value"
                                  timezone={'UTC'}
                                  value = {aDataCondition.value ? dayjs(aDataCondition.value) : dayjs('')}
                                  onChange = {(newValue) => {this.updateCondition(
                                                index,
                                                aDataConditionIndex,
                                                aDataCondition.logic,
                                                aDataCondition.key,
                                                aDataCondition.function,
                                                (() => { try { return newValue.toISOString(); } catch (error) { return newValue ? newValue.toString() : ""; } })(),
                                                aDataCondition.datetimeValue,
                                                queryData
                                              );
                                              }

                                            }
                              />
                            </LocalizationProvider>
                          /* Else use the normal String input */
                          ) : (
                            <ChipInput
                              onPlaceholderSelected={
                                this.props.onPlaceholderSelected
                              }
                              style={{
                                width: "330px",
                                marginTop: 16,
                                marginRight: 24,
                                verticalAlign: "top",
                              }}
                              placeholders={availablePlaceholders}
                              onBeforeAdd={(chip) => {
                                let val = chip;

                                if (val && val.trim().length > 0) {
                                  this.updateCondition(
                                    index,
                                    aDataConditionIndex,
                                    aDataCondition.logic,
                                    aDataCondition.key,
                                    aDataCondition.function,
                                    val,
                                    aDataCondition.datetimeValue,
                                    queryData
                                  );
                                  return true;
                                } else {
                                  return false;
                                }
                              }}
                              // If it is a datetime query, it will only allow datetime placeholders and no input
                              placeholder={aDataCondition.type === "DATETIME" ? "Insert a datetime placeholder" : "Type something or insert a placeholder"}
                              inputValue={
                                aDataCondition.value ? [aDataCondition.value] : []
                              }

                              inputType = {aDataCondition.type}
                              onDelete={(chip, indexChip) => {
                                let val = "";
                                this.updateCondition(
                                  index,
                                  aDataConditionIndex,
                                  aDataCondition.logic,
                                  aDataCondition.key,
                                  aDataCondition.function,
                                  val,
                                  aDataCondition.datetimeValue,
                                  queryData
                                );
                              }}
                              onFocus={() => {
                                this.props.setThisFocus(index, aDataConditionIndex);
                              }}
                            />
                          )
                          } 
                          &nbsp;
                        </> 
                                       
                    )}
                    {aDataConditionIndex > 0 && (
                      <DeleteIcon
                        style={{
                          visibility: aDataConditionIndex == 0 ? "hidden" : "",
                          marginTop: 24,
                        }}
                        className={classes.deleteIcon}
                        onClick={(value) =>
                          this.deleteQueryItemCondition(
                            index,
                            aDataConditionIndex,
                            queryData
                          )
                        }
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
              <span
                style={{ marginTop: 16 }}
                className={classes.textContentCenter}
                onClick={() => {
                  this.addCondition(index, queryData);
                }}
              >
                <AddIcon /> Add condition
              </span>
              </Grid>
          </Grid>
        </>
      );
    } else if (outputFunc === "COUNT_COLUMN") {
      return (
        <>
          <Grid style={{ marginTop: "8px" }} container>
            <Grid
              style={{
                minWidth: "max-content",
                maxWidth: "max-content",
                flexBasis: "auto",
                marginRight: "24px",
                paddingTop: "8px",
                marginBottom: 8,
              }}
              item
              xs={2}
            >
              <p style={{ fontWeight: "600 !important" }}>
                Return count of values from
              </p>
            </Grid>

            <Grid style={{ marginTop: "16px" }} item>
              <Select
                style={{
                  width: "max-content",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "4px",
                  color: "#868686",
                  marginRight: "24px",
                  minWidth: "280px",
                  verticalAlign: "top",
                }}
                name="selectType"
                disableUnderline
                variant="standard"
                labelId="demo-simple-select-outlined-label"
                className="selectStandard"
                displayEmpty
                value={aData.value}
                onChange={(value) => { 
                    this.setOutputValue(index, value, queryData)
                  }
                }
              >
                <MenuItem value={null} disabled>
                  {" "}
                  select column{" "}
                </MenuItem>
                {availableCols.map((aCol, aColIndex) => (
                  <MenuItem
                    value={
                      aCol.placeholderKey
                        ? aCol.placeholderKey
                        : aCol.realValue.toUpperCase()
                    }
                  >
                    {" "}
                    {aCol.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "8px" }} container direction="row" xs={12}>
            <Grid
              style={{
                marginRight: "16px",
                maxWidth: "max-content",
                width: "max-content",
                paddingTop: "8px",
                flexBasis: "auto",
              }}
              item
              xs={2}
            >
              <p style={{ fontWeight: "600 !important", marginTop: 16 }}>
                Where the data in
              </p>
            </Grid>
            <Grid item xs={10} direction="column">
              {aData.condition &&
                aData.condition.map((aDataCondition, aDataConditionIndex) => (
                  <>
                    <Grid item xs>
                      {aDataConditionIndex > 0 && (
                        <Select
                          style={{
                            width:
                              aDataConditionIndex == 0 ? "0px" : "max-content",
                            minWidth: aDataConditionIndex == 0 ? "0px" : "80px",
                            backgroundColor: "#F8F8F8",
                            borderRadius: "4px",
                            marginTop: 16,
                            color: "#868686",
                            marginRight:
                              aDataConditionIndex == 0 ? "0px" : "16px",
                            verticalAlign: "top",
                            visibility:
                              aDataConditionIndex == 0 ? "hidden" : "",
                          }}
                          name="selectType"
                          disableUnderline
                          variant="standard"
                          labelId="demo-simple-select-outlined-label"
                          className="selectStandard"
                          value={aDataCondition.logic}
                          onChange={(event) => {
                            let logic = event.target.value;

                            this.updateCondition(
                              index,
                              aDataConditionIndex,
                              logic,
                              aDataCondition.key,
                              aDataCondition.function,
                              aDataCondition.value,
                              aDataCondition.datetimeValue,
                              queryData
                            );
                          }}
                        >
                          <MenuItem value="AND">AND</MenuItem>
                          <MenuItem value="OR">OR</MenuItem>
                        </Select>
                      )}
                      <FormControl
                        error={aDataCondition.key == null ? true : false}
                      >
                        <Select
                          style={{
                            width: "max-content",
                            backgroundColor: "#F8F8F8",
                            borderRadius: "4px",
                            color: "#868686",
                            marginRight: "24px",
                            minWidth: "280px",
                            marginTop: 16,
                            verticalAlign: "top",
                          }}
                          name="selectType"
                          disableUnderline
                          variant="standard"
                          labelId="demo-simple-select-outlined-label"
                          className="selectStandard"
                          value={aDataCondition.key}
                          displayEmpty
                          onChange={(event) => {
                            let key = event.target.value;
                            this.updateCondition(
                              index,
                              aDataConditionIndex,
                              aDataCondition.logic,
                              key,
                              aDataCondition.function,
                              aDataCondition.value,
                              aDataCondition.datetimeValue,
                              queryData
                            );
                          }}
                        >
                          <MenuItem value={null} disabled>
                            {" "}
                            select column{" "}
                          </MenuItem>
                          {availableCols.map((aCol, aColIndex) => (
                            <MenuItem
                              value={
                                aCol.placeholderKey
                                  ? aCol.placeholderKey
                                  : aCol.realValue.toUpperCase()
                              }
                            >
                              {" "}
                              {aCol.name}{" "}
                            </MenuItem>
                          ))}
                        </Select>
                        {aDataCondition.key == null && validateKey ? (
                          <FormHelperText>
                            This field cannot be empty
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                      &nbsp;
                      <FormControl
                        error={aDataCondition.function == null ? true : false}
                      >
                        <Select
                          style={{
                            width: "max-content",
                            backgroundColor: "#F8F8F8",
                            borderRadius: "4px",
                            color: "#868686",
                            minWidth: "160px",
                            marginRight: "24px",
                            marginTop: 16,
                            verticalAlign: "top",
                          }}
                          name="selectType"
                          disableUnderline
                          variant="standard"
                          labelId="demo-simple-select-outlined-label"
                          className="selectStandard"
                          displayEmpty
                          value={aDataCondition.function}
                          onChange={(event) => {
                            let func = event.target.value;
                            let value = aDataCondition.value;
                            if (func === "EMPTY" || func === "NOTEMPTY") {
                              value = "";
                            }
                            this.updateCondition(
                              index,
                              aDataConditionIndex,
                              aDataCondition.logic,
                              aDataCondition.key,
                              func,
                              value,
                              aDataCondition.datetimeValue,
                              queryData
                            );
                          }}
                        >
                          <MenuItem value={null} disabled>
                            {" "}
                            Function{" "}
                          </MenuItem>

                          <MenuItem value="EQUAL">Equals</MenuItem>
                          <MenuItem value="NOT_EQUAL">does not equal</MenuItem>
                          <MenuItem value="MATCHES" > contains </MenuItem>
                          <MenuItem value="NOTMATCH">
                            {" "}
                            does not contain{" "}
                          </MenuItem>
                          <MenuItem value="GREATER_THAN">greater than</MenuItem>
                          <MenuItem value="GREATERTHANOREQUAL">
                            greater than or equal to
                          </MenuItem>
                          <MenuItem value="LESS_THAN">less than</MenuItem>
                          <MenuItem value="LESSTHANOREQUAL">
                            {" "}
                            less than or equal to{" "}
                          </MenuItem>
                          <MenuItem value="NOTEMPTY"> is not empty </MenuItem>
                          <MenuItem value="EMPTY"> is empty </MenuItem>
                        </Select>
                        {aDataCondition.key == null && validateKey ? (
                          <FormHelperText>
                            This field cannot be empty
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                      &nbsp;
                      {aDataCondition.function === "EMPTY" ||
                      aDataCondition.function === "NOTEMPTY" ? (
                        ""
                      ) : (
                        <>
                          <ChipInput
                            style={{
                              width: "360px",
                              marginTop: 16,
                              marginRight: 24,
                              verticalAlign: "top",
                            }}
                            onPlaceholderSelected={
                              this.props.onPlaceholderSelected
                            }
                            placeholders={availablePlaceholders}
                            onBeforeAdd={(chip) => {
                              let val = chip;

                              if (val && val.trim().length > 0) {
                                this.updateCondition(
                                  index,
                                  aDataConditionIndex,
                                  aDataCondition.logic,
                                  aDataCondition.key,
                                  aDataCondition.function,
                                  val,
                                  aDataCondition.datetimeValue,
                                  queryData
                                );
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            placeholder="Type something or insert a placeholder"
                            inputValue={
                              aDataCondition.value ? [aDataCondition.value] : []
                            }
                            onDelete={(chip, indexChip) => {
                              let val = "";
                              this.updateCondition(
                                index,
                                aDataConditionIndex,
                                aDataCondition.logic,
                                aDataCondition.key,
                                aDataCondition.function,
                                val,
                                aDataCondition.datetimeValue,
                                queryData
                              );
                            }}
                            onFocus={() => {
                              this.props.setThisFocus(
                                index,
                                aDataConditionIndex
                              );
                            }}
                          />
                          &nbsp;
                        </>
                      )}
                      {aDataConditionIndex > 0 && (
                        <DeleteIcon
                          style={{
                            visibility:
                              aDataConditionIndex == 0 ? "hidden" : "",
                            marginTop: 24,
                          }}
                          className={classes.deleteIcon}
                          onClick={(value) =>
                            this.deleteQueryItemCondition(
                              index,
                              aDataConditionIndex,
                              queryData
                            )
                          }
                        />
                      )}
                    </Grid>
                  </>
                ))}
              <Grid item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    marginTop: "16px",
                  }}
                >
                  <div
                    style={{ marginTop: 0 }}
                    className={classes.textContentCenter}
                    onClick={() => {
                      this.addCondition(index, queryData);
                    }}
                  >
                    <AddIcon /> Add AND/OR condition
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
  };

    getReturnTypeSelect(aData, index, queryData) {
        return <Grid style={{marginTop: "8px"}} container>
            <Grid
                style={{
                    minWidth: "max-content",
                    maxWidth: "max-content",
                    flexBasis: "auto",
                    marginRight: "24px",
                    paddingTop: "8px",
                    marginTop: "8px",

                }}
                item
                xs={2}

            >
                <p style={{fontWeight: "600 !important"}}>
                    as a
                </p>
            </Grid>
            <Grid style={{paddingTop: "8px", marginTop: "8px"}} item>
                <Select
                    style={{
                        width: "max-content",
                        minWidth: "280px",
                        backgroundColor: "#F8F8F8",
                        borderRadius: "4px",
                        color: "#868686",
                        marginRight: "24px",
                        verticalAlign: "top",
                        visibility: "",
                    }}
                    xs={12}
                    name="selectType"
                    disableUnderline
                    variant="standard"
                    labelId="demo-simple-select-outlined-label"
                    className="selectStandard"
                    value={aData.returnType}
                    defaultValue={"list"}
                    onChange={(value) =>
                        this.setOutputReturnType(index, value, queryData)
                    }

                >
                    <MenuItem value={"list"}>
                        {this.state.showWarning ? "List of a list [[A,B,C],[D,E,F]]" : "List [A,B,C]"}
                    </MenuItem>
                    <MenuItem value={"string"}>
                        {this.state.showWarning ? "String “A,B,C,D,E,F”" : "String “A,B,C”"}
                    </MenuItem>
                    {this.state.showWarning ?
                        <MenuItem value={"nestedList"}>
                            List [A,B,C,D,E,F]
                        </MenuItem> : ""
                    }
                </Select>
            </Grid>
        </Grid>;
    }

    handleKey = (e, queryData, index) => {
    let keyValue = replacePlaceholderValue(e.target.value);

    const ckValue = checkKey(keyValue, index, queryData);
    this.setState({
      checkKey: ckValue[0],
      errorMessage: ckValue[1],
    });

    let aData = queryData[index];
    aData.key = keyValue;

    this.props.setQueryData(queryData);
  };

  setOutputFunc = (index, event, queryData) => {
    this.props.setValidateKey(false);
    let aData = queryData[index];
    aData.outputFunc = event.target.value;

    let outputType = "list";

    if (aData.outputFunc === "COUNT_COLUMN") {
      //count so we set the type to number
      outputType = "number";
    }

    if (
      aData.outputFunc === "FILTERED_COLUMN" ||
      aData.outputFunc === "COUNT_COLUMN"
    ) {
      //add the 1st condition
      aData.condition = [];
      let condition = {
        key: null,
        function: null,
        value: null,
      };
      aData.condition.push(condition);
    }

    if (aData.outputFunc === "SELECTED_COLUMN") {
      delete aData.condition;
    }

    aData.outputType = outputType;

    this.props.setQueryData(queryData);
  };

  deleteQueryItem = (index, queryData) => {
    queryData.splice(index, 1);
    this.props.setQueryData(queryData);
  };

  setOutputValue = (index, event, queryData) => {
    const { availableCols } = this.props;
    const column = availableCols.find(
      (col) => col.placeholderKey === event.target.value
    );
    let aData = queryData[index];
    aData.value = event.target.value;
    aData.columnName = column ? column.name : null;
    this.state.showWarning = column ? column.type === "LIST":false;
    this.props.setQueryData(queryData);
  };
    setOutputReturnType = (index, event, queryData) => {
        let aData = queryData[index];
        aData.returnType = event.target.value;
        this.props.setQueryData(queryData);
    }

  updateCondition = (
    index,
    conditionIndex,
    logic,
    key,
    func,
    value,
    datetimeValue,
    queryData
  ) => {
    const { availableCols } = this.props;
    const column = availableCols.find((col) => col.placeholderKey === key);
    let aData = queryData[index];
    if (aData.condition && aData.condition[conditionIndex]) {
      let conditionData = aData.condition[conditionIndex];
      conditionData.logic = logic;
      conditionData.key = key;
      conditionData.function = func;
      conditionData.value = value;
      conditionData.columnName = column ? column.name : null;
      // Session Id and Database Id don't have type equipped in their structure
      conditionData.type = column.type ? column.type : "VARCHAR2";
      conditionData.datetimeValue = datetimeValue
      

      this.props.setQueryData(queryData);
    }
  };

  deleteQueryItemCondition = (index, conditionIndex, queryData) => {
    let aData = queryData[index];

    if (aData && aData.condition) {
      aData.condition.splice(conditionIndex, 1);
    }

    this.props.setQueryData(queryData);
  };

  addCondition = (index, queryData) => {
    let aData = queryData[index];
    if (!aData.condition) {
      aData.condition = [];
    }
    let condition = {
      key: null,
      function: null,
      value: null,
      logic: "AND",
    };
    aData.condition.push(condition);
    this.props.setValidateKey(false);
    this.props.setQueryData(queryData);
  };

  render() {
    const {
      classes,
      aData,
      index,
      queryData,
      component,
      outputSelect,
      validateKey,
    } = this.props;

    const column = this.props.availableCols.find(
        (col) => col.placeholderKey === aData.value
    );
    if(column && column.type==="LIST" && !this.state.showWarning)
        this.state.showWarning =true
    return (
      <Grid item xs={12}>
        <Paper className="query-item-paper defaultText">
          <Grid container xs={12}>
            <Grid item direction="column" xs={11}>
              <Grid container>
                <Grid
                  style={{
                    maxWidth: "max-content",
                    flexBasis: "max-content",
                    marginRight: "24px",
                  }}
                  item
                  xs={1}
                >
                  <p style={{ marginTop: "8px", fontWeight: "600 !important" }}>
                    Placeholder
                  </p>
                </Grid>
                <Grid item>
                  <W86PlaceholderTextField
                    value={aData.key}
                    error={this.state.checkKey ? true : false}
                    helperText={this.state.checkKey ? this.state.errorMessage : null}
                    placeholderText="Enter placeholder for query item"
                    handleChange={(event) => {
                      this.handleKey(event, queryData, index);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid style={{ marginTop: "16px" }} container>
                <Grid
                  style={{
                    flexBasis: "auto",
                    marginRight: "24px",
                    paddingTop: "8px",
                  }}
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                >
                  <p style={{ fontWeight: "600 !important" }}>
                    {component === "query_database_record"
                      ? "Output"
                      : "Perform the following data query"}
                  </p>
                </Grid>
                <Grid style={{ marginTop: "16px" }} item>
                  <Select
                    style={{
                      width: "max-content",
                      minWidth: "280px",
                      backgroundColor: "#F8F8F8",
                      borderRadius: "4px",
                      color: "#868686",
                      marginRight: "24px",
                      verticalAlign: "top",
                      visibility: "",
                    }}
                    name="selectType"
                    disableUnderline
                    variant="standard"
                    labelId="demo-simple-select-outlined-label"
                    className="selectStandard"
                    displayEmpty
                    value={aData.outputFunc}
                    onChange={(value) =>
                      this.setOutputFunc(index, value, queryData)
                    }
                  >
                    {/*<MenuItem value="SELECTED_COLUMN" disabled>return data in a column</MenuItem>*/}
                    {outputSelect.map((item) => (
                      <MenuItem value={item.key}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              {this.renderQueryItemDetails(aData.outputFunc, aData, index)}
            </Grid>
            <Grid
              style={{ justifyContent: "flex-end", display: "inherit" }}
              item
              xs={1}
            >
              <FileCopyOutlined
                sx={{
                  "&:hover": {
                    color: "#2196F3",
                  },
                  cursor: "pointer"
                }}
                className="conditional-logic-del"
                onClick={() => {
                  this.props.copyThisItem();
                }}
              />
              <DeleteIcon
                className="conditional-logic-del"
                sx={{
                  cursor: "pointer"
                }}
                onClick={(value) => this.deleteQueryItem(index, queryData)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(RenderQueryItem);
