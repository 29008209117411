import React, { useEffect, useState } from "react";

// Util
import { EdgeLabelRenderer } from "reactflow";
import { EdgeBox, EdgeLabelDiv, LabelText } from "./EdgeStyledComponents";

// MUI
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { Grid, styled, TextField, Tooltip } from "@mui/material";

const LabelTextField = styled(TextField)({
    padding: "0px !important",
    margin: "0px !important",
});

const RemoveIconSpan = styled("span")({
    marginRight: "8px",
});

const OuterLabelDiv = styled("div")({
    minWidth: "100px",
    maxWidth: "175px",
    width: "fit-content !important",
    padding: "2px 6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowWrap: "break-word",
});

function LinkLabel({ classes, label, handleDelete, edgePath, isComponentLink, isSelected, saving, handleSaveLinkLabel, markedAsRemove }) {
    // state
    const [edgeLabel, setEdgeLabel] = useState(label);
    const [isEditing, setIsEditing] = useState(false);

    // For saving label on click out
    useEffect(async () => {
        // If it's selected or we're not editing, don't save
        if (isSelected || !isEditing) return;

        // If we get to here, we know it has been de-selected
        // so perform a save
        setIsEditing(false);
        await handleSaveEdit();
    }, [isSelected]);

    const handleEditingChange = () => {
        if (saving) return;
        setIsEditing(!isEditing);
    };

    const handleSaveEdit = async () => {
        if (saving) return;
        await handleSaveLinkLabel(edgeLabel);
        setIsEditing(false);
    };

    const buttons = [
        {
            show: !isComponentLink, // Only display for note links
            content: !isEditing ? <EditIcon className={classes.editIcon} onClick={handleEditingChange} /> : <DoneIcon className={classes.editIcon} onClick={handleSaveEdit} />,
            tooltip: !isEditing ? "Edit edge label" : "",
        },
        {
            show: true,
            content: <CloseIcon className={classes.icon} onClick={handleDelete} />,
            tooltip: "Delete edge",
        },
    ];

    const labelX = edgePath[1];
    const labelY = edgePath[2];

    const linkSelectedContent = (
        <EdgeLabelRenderer>
            <EdgeLabelDiv className="nodrag nopan" labelX={labelX} labelY={labelY}>
                <Grid container display="flex" alignItems="center" justifyContent="center" rowGap="8px">
                    {!isComponentLink && (edgeLabel || isEditing) && (
                        <Grid item>
                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                <EdgeBox item display="flex" alignItems="center" justifyContent="center">
                                    <OuterLabelDiv>
                                        {isEditing ? (
                                            <LabelTextField
                                                fullWidth
                                                multiline
                                                maxRows={5}
                                                placeholder="Set a label"
                                                value={edgeLabel}
                                                variant="standard"
                                                size="small"
                                                onChange={(e) => {
                                                    setEdgeLabel(e.target.value);
                                                }}
                                                InputProps={{ disableUnderline: true }}
                                                disabled={saving}
                                            />
                                        ) : (
                                            <LabelText>{edgeLabel}</LabelText>
                                        )}
                                    </OuterLabelDiv>
                                </EdgeBox>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container display="flex" alignItems="center" justifyContent="center">
                            <Grid item>
                                <EdgeBox container columnGap={"6px"}>
                                    {buttons.map((btn) => {
                                        return btn.show ? <Tooltip title={btn.tooltip}>{btn.content}</Tooltip> : <></>;
                                    })}
                                </EdgeBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </EdgeLabelDiv>
        </EdgeLabelRenderer>
    );

    const notSelectedContent = () => {
        // If it's a component link and has been marked as remove:
        if (isComponentLink && markedAsRemove) {
            return (
                <EdgeLabelRenderer>
                    <EdgeLabelDiv className="nodrag nopan" labelX={labelX} labelY={labelY}>
                        <Grid container display="flex" alignItems="center" justifyContent="center">
                            <EdgeBox item display="flex" alignItems="center" justifyContent="center">
                                <OuterLabelDiv>
                                    <LabelText>
                                        <RemoveIconSpan>❌</RemoveIconSpan>To be deleted
                                    </LabelText>
                                </OuterLabelDiv>
                            </EdgeBox>
                        </Grid>
                    </EdgeLabelDiv>
                </EdgeLabelRenderer>
            );
        }

        if (isComponentLink || !edgeLabel) return <></>;

        return (
            <EdgeLabelRenderer>
                <EdgeLabelDiv className="nodrag nopan" labelX={labelX} labelY={labelY}>
                    <Grid container display="flex" alignItems="center" justifyContent="center">
                        <EdgeBox item display="flex" alignItems="center" justifyContent="center">
                            <OuterLabelDiv>
                                <LabelText>{edgeLabel}</LabelText>
                            </OuterLabelDiv>
                        </EdgeBox>
                    </Grid>
                </EdgeLabelDiv>
            </EdgeLabelRenderer>
        );
    };

    return isSelected ? linkSelectedContent : notSelectedContent();
}

export default LinkLabel;
