import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { Grid, MenuItem, Select } from "@mui/material";

import dayjs from "dayjs";

const handleStripDate = (date) => {
    if (!date || !date.includes("[")) return date;
    let split = date.split("[");
    return split[0];
};

function DatetimeInput({ pH, values, setValues }) {
    const [value, setValue] = useState(values[pH.key].value.rawValue ? dayjs(handleStripDate(values[pH.key].value.rawValue)) : null);

    return (
        <Grid container rowGap={1}>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DateTimePicker
                        fullWidth
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                            let val = dayjs(newValue);

                            // If not valid
                            if (!val.isValid()) return;

                            let newVal = { ...values };
                            let type = newVal[pH.key].type;

                            newVal[pH.key] = {
                                type: type,
                                value: {
                                    rawValue: val.toISOString(),
                                    format: newVal[pH.key].value.format,
                                    value: newVal[pH.key].value.value,
                                },
                            };

                            setValues(newVal);
                        }}
                        renderInput={(props) => <TextField {...props} size="small" placeholder="Enter a datetime" variant="outlined" />}
                        ampm={false}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <Select
                    onChange={(e) => {
                        let newVal = { ...values };
                        let type = newVal[pH.key].type;

                        newVal[pH.key] = {
                            type: type,
                            value: {
                                rawValue: newVal[pH.key].value.rawValue,
                                format: e.target.value,
                                value: newVal[pH.key].value.value,
                            },
                        };

                        setValues(newVal);
                    }}
                    className={"date-format-select"}
                    variant={"outlined"}
                    value={values[pH.key].value.format}
                    defaultValue={"yyyy-MM-DD hh:MM:ssZ"}
                >
                    <MenuItem value={"yyyy-MM-DD hh:MM:ssZ"}>yyyy-MM-DD hh:MM:ssZ</MenuItem>
                    <MenuItem value={"dd LLLL YYYY"}>dd Month yyyy [01 February 2021]</MenuItem>
                    <MenuItem value={"dd LLLL YYYY kk:mm"}>dd Month yyyy hh:mm [01 February 2021 14:01]</MenuItem>
                    <MenuItem value={"dd/MM/YY"}>dd/mm/yy [01/02/21]</MenuItem>
                    <MenuItem value={"dd/MM/YY kk:mm"}>dd/mm/yy hh:mm [01/02/21 14:01]</MenuItem>
                    <MenuItem value={"dd/MM/YYYY"}>dd/mm/yyyy [01/02/2021]</MenuItem>
                    <MenuItem value={"dd/MM/YYYY kk:mm"}>dd/mm/yyyy hh:mm [01/02/2021 14:01]</MenuItem>
                    <MenuItem value={"dd-MM-YYYY"}>dd-mm-yyyy [01-02-2021]</MenuItem>
                    <MenuItem value={"dd-MM-YYYY kk:mm"}>dd-mm-yyyy hh:mm [01-02-2021 14:01]</MenuItem>
                    <MenuItem value={"MM/dd/YYYY"}>mm/dd/yyyy [02/01/2021]</MenuItem>
                    <MenuItem value={"MM/dd/YYYY kk:mm"}>mm/dd/yyyy hh:mm [02/01/2021 14:01]</MenuItem>
                    <MenuItem value={"YYYY-MM-dd"}>yyyy-mm-dd [2021-02-01]</MenuItem>
                    <MenuItem value={"YYYY-MM-dd kk:mm"}>yyyy-mm-dd hh:mm [2021-02-01 14:01]</MenuItem>
                    <MenuItem value={"Unix"}>Unix [1612148400]</MenuItem>
                </Select>
            </Grid>
        </Grid>
    );
}

export default DatetimeInput;
