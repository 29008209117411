import { computed, observable, decorate, action, toJS } from "mobx";

class LoopThroughList {
  constructor() {
    this.initialiseEmpty();
  }
  setComponentData = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.componentData();
      this.data = [obj];
      this.lastModified = "";
      this.name = "";
    }
  };

  setTemplateData = data => {
    this.data = data;
  }

  setComponentName = name => {
    this.name = name;
  };
  setLastModified = lastModified => {
    this.lastModified = lastModified;
  };
  setFocus = (index, field) => {
    this.position = index;
    this.field = field;
    this.posiCondition = null;
    this.posiConditionIsMet = null;
  };

  setFocusCondition = (index, posi, type) => {
    this.position = index;
    if (type == "conditions") this.posiCondition = posi;
    if (type == "conditionIsMet") this.posiConditionIsMet = posi;
  };

  keyAuto = () => {
    return (
      "looplist" + (Math.floor(Math.random() * 90000000) + 10000).toString()
    );
  };

  componentData = () => {
    return {
      key: this.keyAuto(),
      list: []
    };
  };
  setComponent = (value, index, type) => {
    switch (type) {
      case "key":
        this.data[index].key = value;
        return;
      case "list":
        if (value) this.data[index].list = [value];
        return;
    }
  };
  setCondition = (value, index, position, field, type) => {
    if (type == "conditions") {
      if (value) this.data[index].conditions[position].value = [value];
    }
    if (type == "conditionIsMet") {
      if (value) this.data[index].conditionIsMet[position].value = [value];
      return;
    }
  };

  addComponent = () => {
    const obj = this.componentData();
    this.data.push(obj);
  };

  delComponent = index => {
    this.data.splice(index, 1);
  };

  deleteCondition = (index, position, type) => {
    if (type == "conditions") this.data[index].conditions.splice(position, 1);
    if (type == "conditionIsMet")
      this.data[index].conditionIsMet.splice(position, 1);
  };

  deleteChipCondition = (indexChip, index, p, type) => {
    if (type == "conditions")
      this.data[index].conditions[p].value.splice(indexChip, 1);
    if (type == "conditionIsMet")
      this.data[index].conditionIsMet[p].value.splice(indexChip, 1);
  };

  deleteChip = (indexChip, index, field) => {
    this.data[index].list.splice(indexChip, 1);
    return;
  };

  initialiseEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.position = null;
    this.componentId = "";
    this.type = "loop_through_list";
    this.lastModified = "";
    this.data = [obj];
    this.field = null;
    this.posiCondition = null;
    this.posiConditionIsMet = null;
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      field: this.field,
      lastModified: this.lastModified,
      posiCondition: this.posiCondition,
      posiConditionIsMet: this.posiConditionIsMet
    };
  }
}

decorate(LoopThroughList, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable,
  type: observable,
  field: observable,
  posiCondition: observable,
  posiConditionIsMet: observable,
  lastModified: observable,
  addComponent: action,
  setComponent: action,
  setComponentData: action,
  setLastModified: action,
  setCondition: action,
  setFocus: action,
  delComponent: action,
  deleteChip: action,
  deleteCondition: action,
  deleteChipCondition: action,
  initialiseEmpty: action,
  template: computed
});

const loopThroughList = new LoopThroughList();
export default loopThroughList;
