import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx"; 
import FormVibilityStore from "./FormConditionStores/FormVisibilityStore"; 
import ItemStatementCondition from "./FormConditionComponents/ItemStatementVisibility";

class FormConditional extends React.Component {
  componentDidMount() {
    const { visibility } = this.props;
    if (visibility && visibility.Statement) {
      FormVibilityStore.setStatement(visibility)
      FormVibilityStore.setFormConditional(visibility);
    } else {
      FormVibilityStore.initialiseEmpty();
    }
  }
  render() {
    const { Condition } = toJS(FormVibilityStore.Statement.Statement.content);
    const { AnswerList } = toJS(FormVibilityStore);
    return (
      <div
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          padding: "15px 15px 30px",
          margin: "15px 0"
        }}
      >
        {Condition &&
          Condition.map((condition, index) => (
            <ItemStatementCondition
              condition={condition}
              indexStatement={index}
              key={index}
              AnswerList={AnswerList[index]}
              availablePlaceholders={this.props.availablePlaceholders}

            />
          ))}
      </div>
    );
  }
}
export default observer(FormConditional);
