import React, {Component} from "react";
import Grid from "@mui/material/Grid";
import {IconButton, MenuItem, Select, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {observer} from "mobx-react";
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import DataArrayIcon from '@mui/icons-material/DataArray';
import HtmlIcon from '@mui/icons-material/Html';
import PinIcon from '@mui/icons-material/Pin';

class PlaceholderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSaveButton: false,
      error: false,
      helperMessage: null,
    };
  }

  focus = () => {
    this.props.onFocus();
  };

  componentDidMount() {
    const placeholderItem = this.props.placeholder;
    if (placeholderItem) {
      this.setState({placeholder: placeholderItem});
      this.setState({index: this.props.index});
    }
  }

  validPlaceholderInput(value) {
    return /^[a-zA-Z]?$|^[a-zA-Z]([a-zA-Z0-9_]+)?$/.test(value) !== false;
  }

  render() {
    const {index, total, placeholder} = this.props;
    if (!placeholder) {
      return;
    }

    return (
      <Grid container xs={12} rowSpacing={2} spacing={2} alignItems={"center"}>
        <Grid item xs={3}>
          <Select
            fullWidth={true}
            variant="standard"
            value={placeholder.type ? placeholder.type : ""}
            defaultValue={"text"}
            onChange={(event) => {
              this.setState({enableSaveButton: true});
              const placeholderType = {
                attribute: "type",
                index: index,
                value: event.target.value,
              }
              this.props.onChange(placeholderType);
            }}
          >
            <MenuItem value={"text"}><FormatSizeIcon size="small"/><a style={{fontSize: 17, paddingLeft: "8px"}}>Text</a></MenuItem>
            <MenuItem value={"number"}><PinIcon size="small"/><a style={{fontSize: 17, paddingLeft: "8px"}}>Number</a></MenuItem>
            <MenuItem value={"list"}><DataArrayIcon size="small"/><a style={{fontSize: 17, paddingLeft: "8px"}}>List</a></MenuItem>
            <MenuItem value={"html"}><HtmlIcon size="small"/><a style={{fontSize: 17, paddingLeft: "8px"}}>HTML</a></MenuItem>
          </Select>
        </Grid>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            placeholder="Type in a key from the return statement"
            fullWidth
            value={placeholder.key ? placeholder.key : ""}
            size="small"
            error={this.state.error}
            helperText={this.state.helperMessage}
            onChange={(event) => {
              const valueValidStatus = this.validPlaceholderInput(event.target.value);
              if(!valueValidStatus){
                this.setState({error: true});
                this.setState({helperMessage: "Accepts alphabets, numbers and underscores but must start with an alphabet"})
                return;
              }
              this.setState({error: false})
              this.setState({helperMessage: null});
              this.setState({enableSaveButton: true});
              const placeholderKey = {
                attribute: "key",
                index: index,
                value: event.target.value || "",
              }
              this.props.onChange(placeholderKey);
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <div>
            <IconButton aria-label="delete">
              <CloseIcon
                fontSize="inherit"
                onClick={() => {
                  this.props.onDelete(index);
                }}
              />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default observer(PlaceholderItem);
