import React from "react";
import { Grid } from "@mui/material"
export function showLoadingScreen() {
    return (
        <Grid
            container item direction={"row"} alignItems={"center"} justifyContent={"center"}
            style={{
                textAlign: "center",
                height: "20vw",
                verticalAlign: "middle",
            }}
        >
            <img
                style={{ position: "relative", margin: "auto", top: "50%" }}
                src="/images/loading_200.gif"
            />
        </Grid>
    );
}
export function runLoading(){
    return (
        <Grid
            container item direction={"row"} alignItems={"center"} justifyContent={"center"}
            style={{
                textAlign: "center",
                verticalAlign: "middle",
            }}
        >
            <img
                src="/images/loading_200.gif"
            />
        </Grid>
    );
}