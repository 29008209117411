import React, { useContext, useRef, useCallback} from "react";

// Canvas
import { CanvasContext } from "../../../WorkflowCanvas";
import ComponentNodeView from './ComponentNodeUtil/ComponentNodeView';
// MUI
import { withStyles } from "@mui/styles";
// Icons
import Error from "@mui/icons-material/Error";
// MobX
import { inject, observer } from "mobx-react";
import { styles } from './ComponentNodeUtil/CustomComponentNodeUtil';
import BaseComponent from "./BaseComponent";

const ProductionCanvasComponent = inject("CanvasStore")(
  observer(({ id, data, classes, selected, CanvasStore }) => {

    const { modeInfo } = useContext(CanvasContext);
    if (modeInfo === undefined) {
      return <BaseComponent classes={classes} data={data} isConnectable={isConnectable} />;
    }

    const parentRef = useRef(null);
    const { nodes } = CanvasStore;
    const [mode, setMode] = modeInfo;

    useCallback((newInstruction) => {
      const updatedCanvas = nodes.map((component) =>
          component.id === id
              ? { ...component, data: { ...component.data, instruction: newInstruction } }
              : component
      );
      CanvasStore.setNodes(updatedCanvas);
    }, [nodes, id, CanvasStore]);

    return (
        <div
            draggable={false}
            ref={parentRef}
            onDragOver={(event) => {
              event.preventDefault();
            }}
        >
          <div className={classes.nodeWrapper}>
            {data.error && (
                <Error className={`error-text ${classes.downsize}`}></Error>
            )}
            <ComponentNodeView
              classes={classes}
              data={data}
              selected={selected}
              mode={mode}
            />

          </div>
        </div>
    );
  })
);

export default withStyles(styles)(ProductionCanvasComponent);
