import React, { useEffect, useState } from "react";

import { LinearProgress, styled } from "@mui/material";
import { send_request } from "../../../utils/Request";
import { useRef } from "react";

const PollingBar = styled(LinearProgress)(({ isSplitView }) => ({
    height: "5px",
    position: "relative",
    borderTopLeftRadius: isSplitView ? "8px" : "0px",
    borderTopRightRadius: isSplitView ? "8px" : "0px"
}));

const FETCHING_TIME = 30;
const POLLING_INTERVAL = 1; // In seconds

function SessionPolling({ projectId, sessionId, isPolling, setIsPolling, sessionData, drawPathAndHandleLogic, workflow, setModalData, modalData, setSessionData, isSplitView }) {
    const [isFetching, setIsFetching] = useState(false);

    // Need to set a ref to the session data so we catch all updates
    const sessionDataRef = useRef(sessionData);
    sessionDataRef.current = sessionData;

    // Attach a ref to the modalData
    const modalDataRef = useRef(modalData);
    modalDataRef.current = modalData;

    useEffect(() => {
        let isMounted = true;

        // Create interval to run every 3 seconds
        const interval = setInterval(() => {
            if(!isMounted) return; // If not mounted, just return
            pollSessionData(isMounted);
        }, POLLING_INTERVAL * 1000);

        // After 15 seconds, stop trying to poll
        setTimeout(() => {
            if(!isMounted) return; // If not mounted, just return
            setIsPolling(false);
        }, FETCHING_TIME * 1000);

        return () => {
            isMounted = false; // set flag to false when unmounted
            // Clean up, clear the interval
            clearInterval(interval);
        };
    }, [sessionId]); // We want to re-poll when a different session is selected

    // Function for polling the session data
    const pollSessionData = async (isMounted) => {
        // If not polling or we are still fetching, skip
        if (!isPolling || isFetching) return;
        try {
            setIsFetching(true);
            
            let url = `project-service/project/get_log/${projectId}/${sessionId}`;
            const json = await send_request(url, "", {}, "");

            // If not data or the polling component has been unmounted
            if (!json || !json.data || !isMounted) {
                setIsFetching(false);
                return;
            }

            // Check if the session data has updated/changed
            // If not, return, else update the data
            let hasChanged = JSON.stringify(sessionDataRef.current) != JSON.stringify(json.data);
            if (!hasChanged) {
                setIsFetching(false);
                return;
            }

            let newSessionData = json.data;

            console.log('Is mounted: ' + isMounted)
            if (isMounted) { // Check if it's still mounted
                setSessionData(newSessionData);
                drawPathAndHandleLogic(newSessionData, workflow.components, modalDataRef.current);
            }

            setIsFetching(false);
        } catch (e) {
            console.log(e);
        }
    };

    return isPolling && <PollingBar isSplitView={isSplitView} />;
}

export default SessionPolling;
