import { Grid, Skeleton, styled } from "@mui/material";

const DropdownSkeleton = styled(Skeleton)({
  marginTop: '8px',
});

const CheckBoxTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const CurlTemplateSkeleton = () => {
  const skeletonData = [
    { textWidth: "100px", height: 40 },
    { textWidth: "120px", height: 40 },
    { textWidth: "110px", height: 40 },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CheckBoxTitleContainer>
          <Skeleton variant="rectangular" width={20} height={20} />
          <Skeleton variant="text" width="70%" height={24} />
        </CheckBoxTitleContainer>
      </Grid>

      {skeletonData.map((skeleton, index) => (
        <Grid item xs={12} key={index}>
          <Skeleton variant="text" width={skeleton.textWidth} height={20} />
          <DropdownSkeleton variant="rectangular" width="100%" height={skeleton.height} />
        </Grid>
      ))}
    </Grid>
  );
}

export default CurlTemplateSkeleton;