/* Added this CanvasConstants file because it causes circular dependencies for CustomComponentNodes when placed in CanvasUtil.jsx*/
export const PRODUCTION_CANVAS_MODE = "PRODUCTION";
export const PROGRESS_CANVAS_MODE = "PROGRESSVIEW";
export const TEMPLATE_CANVAS_MODE = "TEMPLATE";
export const DEFAULT_CANVAS_MODE = "DEFAULT";
export const VERSION_CANVAS_VIEW_MODE = "CANVAS_VIEW";
export const PRODUCTION_CANVAS_VIEW_MODE = "PRODUCTION_CANVAS_VIEW";
export const SAVE_PAUSE_MODE = "SAVE_PAUSED";
export const NOTE_TYPE = "note";
export const LINK_TYPE = "link";
export const COMPONENT_ICON_ALT = "canvas-svg";
export const COMPONENT_NODE_ID = "component-node";
