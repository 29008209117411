import { useEffect, useState } from 'react';
import { toJS } from 'mobx';

const FROM_DB = "from_a_database";
const FROM_LIST = "from_a_list";
export const useQuestionData = (conditionKey, dataContainer) => {
  const [isFromDBOrList, setIsFromDBOrList] = useState(false);

  useEffect(() => {
    const questionData = toJS(dataContainer.dataQuestion).filter(ele => ele.config.key == conditionKey)[0];

    if (questionData) {
      const {config} = questionData;
      const choiceAnswerOption = config.choiceAnswerOption;
      setIsFromDBOrList(choiceAnswerOption === FROM_DB || choiceAnswerOption === FROM_LIST);
    }
  }, [conditionKey, dataContainer]);

  return isFromDBOrList;
};

export default useQuestionData;
export {FROM_DB, FROM_LIST};