import { insertImagePopup } from "../JoditPopup/InsertImagePopup";
import { send_request } from "../Request";
import config from "../../config";
import projectStore from "../../components/ProjectCanvas/ProjectStore";

const customImage = {
  tooltip: "Insert image",
  icon: "image",
  popup: (editor, current, self, close) => {
    const form = editor.create.fromHTML(insertImagePopup);

    const upload = form.querySelectorAll("button")[0];
    const uploadForm = form.querySelectorAll(".jodit-tab")[0];
    const url = form.querySelectorAll("button")[1];
    const urlForm = form.querySelectorAll(".jodit-tab")[1];
    const file = form.querySelector("input[type=file]");

    editor.e.on(file, "change", async e => {
      // Create a unique placeholder for loading
      const uniquePlaceholderId = `loading-img-${Date.now()}`;
      const loadingIndicator = editor.create.fromHTML(`<div id="${uniquePlaceholderId}" class="skeleton"></div>`);


      editor.s.insertNode(loadingIndicator);

      // Close the popup right after the loading indicator is added
      close();

      let formData = new FormData();
      formData.append("file", file.files[0]);
      formData.append("configId", "fileupload");
      formData.append("type", "upload");
      formData.append("projectId", projectStore.project_id);
      formData.append("projectSessionId", "");
      formData.append("formSessionId", "");

      try {
        const json = await send_request(
          "project-service/project/file-library/create",
          formData,
          "",
          "POST",
          { "content-type": "multipart/form-data" }
        );

        if (json && json.data) {
          const url = `${config.FILE_SERVICE.QUERY_URL}/${json.data.id}`;
          const imgTag = editor.create.fromHTML(`<img src="${url}" alt="" width="300px">`);

          // Replace the loading indicator with the uploaded image
          const placeholderNode = editor.s.doc.getElementById(uniquePlaceholderId);
          if (placeholderNode) {
            placeholderNode.replaceWith(imgTag);
          } else {
            editor.s.insertNode(imgTag);
          }
        }
      } catch (err) {
        console.error("Error uploading image:", err);
        const placeholderNode = editor.s.doc.getElementById(uniquePlaceholderId);
        if (placeholderNode) {
          placeholderNode.remove();
        }
      }
    });

    // Change the tab
    editor.e.on(upload, "click", e => {
      upload.setAttribute("aria-pressed", "true"); // active button
      uploadForm.setAttribute("class", "jodit-tab jodit-tab_active"); // active upload tab form
      url.setAttribute("aria-pressed", "false");
      urlForm.setAttribute("class", "jodit-tab");
    });

    // Change the tab
    editor.e.on(url, "click", e => {
      upload.setAttribute("aria-pressed", "false");
      uploadForm.setAttribute("class", "jodit-tab");
      url.setAttribute("aria-pressed", "true"); // active button
      urlForm.setAttribute("class", "jodit-tab jodit-tab_active"); // active url tab form
    });

    // Event submit by url
    editor.e.on(form, "submit", e => {
      e.preventDefault();
      console.log(form.querySelector("input").value);
      const src = form.querySelectorAll("input")[1].value || "";
      const alt = form.querySelectorAll("input")[2].value || "";
      const image = editor.create.fromHTML(
        `<img src="${src}" alt="${alt}" width="300px">`
      );
      editor.s.insertHTML(image);
      close();
    });

    return form;
  }
};

export const TOOLBAR_BUTTONS = [
  "source",
  "|",
  "bold",
  "italic",
  "|",
  "ul",
  "ol",
  "eraser",
  "|",
  "font",
  "fontsize",
  "brush",
  "paragraph",
  "|",
  customImage,
  "table",
  "link",
  "|",
  "align",
  "\n",
  "undo",
  "redo",
  "|",
  "hr",
  "copyformat",
  "fullsize",
  "|",
  "dots"
];
