import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import { Autocomplete, Chip, Grid, TextField } from '@mui/material'
import DateAdapter from "@mui/lab/AdapterDayjs";
import { styled } from '@mui/styles'
import React from 'react'
import { getColourFromString } from '../../../utils/ColourNameGenerator';

const PlaceholderOutputTableContainer = styled("div")({
    width: "100%"
})

const OutputHeader = styled("p")({
    fontWeight: "bold",
    fontSize: "1em"
})

const GridItem = styled(Grid)({
    minHeight: "50px",
    display: "flex",
    alignItems: "center"
})

const TestValueField = styled(TextField)({
    width: "90%"
})

const PlaceholderContainer = styled(Grid)({
    margin: "1rem 0"
})

const HeaderItem = styled(Grid)({
    color: "#7E7E7E"
})

const StyledAutocomplete = styled(Autocomplete)({

})

const MyChip = styled(Chip)(({ colour }) => ({
    border: `1px solid ${colour.border}`,
    backgroundColor: colour.bg,
    boxSizing: "border-box",
    borderRadius: "4px",
    marginRight: "6px",
}));

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const renderValueField = (key, type, handlePlaceholderValueChange, placeholderValue = null) => {
    switch(type) {
        case "datetime":
            return (
                <LocalizationProvider dateAdapter={DateAdapter} >
                    <DateTimePicker
                        renderInput={(params) => (
                            <TestValueField
                              className={"btnSpace"}
                              size={"small"}
                              {...params}
                            />
                          )}
                          onChange={(input) => handlePlaceholderValueChange(key, input)}
                          format="dd/MM/yyyy, hh:mm a"
                          value={placeholderValue}
                    />
                </LocalizationProvider>
                
            )
        case "list":
            return (
                <StyledAutocomplete
                    multiple
                    options={[]}
                    freeSolo
                    fullWidth
                    size="small"
                    variant="standard"
                    renderTags={(tagValue, getTagProps) => {
                        if (tagValue === null) {
                            return;
                        }
                        return tagValue.map((option, index) => <MyChip {...getTagProps({ index })} label={option} colour={getColourFromString(option)} />);
                    }}
                    renderInput={(params) => <TestValueField {...params} size={"small"} autoFocus={true}/>}
                    onChange={(e, value, reason) => {
                        handlePlaceholderValueChange(key, value);
                    }}
                    defaultValue={placeholderValue !== null || placeholderValue !== undefined ? placeholderValue : []}
                />
            )
        case "number":
            return (
                <TestValueField
                    onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    onBlur={(e) => { 
                        handlePlaceholderValueChange(key, parseInt(e.target.value));
                    }}
                    defaultValue={placeholderValue}
                />
            )
        default:
            return (
                <TestValueField
                    onBlur={(e) => handlePlaceholderValueChange(key, e.target.value)}
                    defaultValue={placeholderValue}
                />
            )
    }
}

const PlaceholderOutputTable = ({ placeholders = [], handlePlaceholderValueChange, placeholderValues = {} }) => {
  return (
    <PlaceholderOutputTableContainer>
        <OutputHeader>Output</OutputHeader>
        <PlaceholderContainer item container xs={12}>
            <HeaderItem xs={3}>Placeholder</HeaderItem>
            <HeaderItem item xs={2}>Data Type</HeaderItem>
            <HeaderItem item xs={7}>Test value</HeaderItem>
        </PlaceholderContainer>
        {
          placeholders.map(({ key, type }, idx) => (
            <PlaceholderContainer key={key + idx} item container>
              <GridItem
               container
               item
               xs={3}
              >
                <span
                  style={{
                    wordWrap :"break-word",
                    display: "block",
                    width: "90%"
                  }}
                >
                   {key}
                </span>
              </GridItem>
              <GridItem container item xs={2}>{capitalizeFirstLetter(type)}</GridItem>
              <GridItem container item xs={7}>
                  {renderValueField(key, type, handlePlaceholderValueChange, placeholderValues[key])}
              </GridItem>
          </PlaceholderContainer>
          ))
        }
    </PlaceholderOutputTableContainer>
  )
}

export default PlaceholderOutputTable