export const companySizes = [
  {
    "name": "Less than 5"
  },
  {
    "name": "5-10"
  },
  {
    "name": "11-25"
  },
  {
    "name": "26-50"
  },
  {
    "name": "51-100"
  },
  {
    "name": "101-250"
  },
  {
    "name": "251-500"
  },
  {
    "name": "501-1,000"
  },
  {
    "name": "1,001-2,500"
  },
  {
    "name": "2,501-5,000"
  },
  {
    "name": "5,001-10,000"
  },
  {
    "name": "Greater than 10,000"
  }
]
