import React, { createContext, useContext, useState } from "react";

const AIOnboardingContext = createContext();
export const useAIOnboardingContext = () => useContext(AIOnboardingContext);

export function OnboardingContextProvider({ children }) {
    // state
    const [step, setStep] = useState(0);
    const [messages, setMessages] = useState([]);
    const [loadingBubble, setLoadingBubble] = useState(true);
    const [loading, setLoading] = useState(false);

    // ======= Step 1 ======= //

    // textfield inputs
    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [accountName, setAccountName] = useState("");
    const [role, setRole] = useState("");

    // errors from textfield inputs
    const [firstNameError, setFirstNameError] = useState(null);
    const [accountNameError, setAccountNameError] = useState(null);

    // dropdowns
    const [timezone, setTimezone] = useState(null);
    const [companySize, setCompanySize] = useState(null);
    const [team, setTeam] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [findOut, setFindOut] = useState(null);
    const [findOutDetails, setFindOutDetails] = useState("");

    // ======= Step 2 ======= //
    const [workflowDescription, setWorkflowDescription] = useState("");

    return (
        <AIOnboardingContext.Provider
            value={{
                messages,
                setMessages,
                loading,
                setLoading,
                loadingBubble,
                setLoadingBubble,
                step,
                setStep,
                firstName,
                setFirstName,
                surname,
                setSurname,
                accountName,
                setAccountName,
                timezone,
                setTimezone,
                companySize,
                setCompanySize,
                findOut,
                setFindOut,
                findOutDetails,
                setFindOutDetails,
                workflowDescription,
                setWorkflowDescription,
                firstNameError,
                setFirstNameError,
                accountNameError,
                setAccountNameError,
                role,
                setRole,
                team,
                setTeam,
                industry,
                setIndustry
            }}
        >
            {children}
        </AIOnboardingContext.Provider>
    );
}
