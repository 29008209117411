import React, { Component } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {Grid, TextField, Tooltip} from "@mui/material";
import ItemStatement from "./ItemStatement";
import { OutputType } from "./components";
import ConditionalWorkflowStore from "../ConditionalWorkflowStore";
import { toJS } from "mobx";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Paper } from "@mui/material";
import Icon from "@mui/material/Icon";
import checkKey from "../../../utils/CheckKey";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";
import { withStyles } from "@mui/styles";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";
import ConditionalWorkflowPathStore from "../../ConditionalWorkflowPath/ConditionalWorkflowPathStore";
import conditionalWorkflowPathStore from "../../ConditionalWorkflowPath/ConditionalWorkflowPathStore";
import {getColourFromString} from "../../../utils/ColourNameGenerator";

const styles = (theme) => ({
  paperItem: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: "24px 16px",
    marginBottom: "16px",
  },
  conditionFrame: {
    borderStyle: "solid",
    borderColor: "#86868650",
    borderRadius: "6px",
    borderWidth: "1.5px",
    padding: "16px",
  },
  spacing: {
    marginBottom: "16px",
  },
  conditionSpacing: {
    marginBottom: "8px",
  },
  conditionPartFrame: {
    borderStyle: "solid",
    borderColor: "#86868650",
    borderRadius: "6px",
    borderWidth: "1.5px",
    margin: "4px",
    padding: "0px 8px 8px 4px",
  },
  disabledClass:({
    pointerEvents: 'none',
    opacity: 0.4,
  }),
  deleteIcon: {
    cursor: "pointer",
    color: "#868686",
    "&:hover": {
      color: "#2196F3"
    }
  },

});
class ItemLogic extends Component {
  state = {
    checkKey: false,
    errorMessage: "",
    updateState: false,
  };

  handleInputKey = (event, indexLogic,conditionalWorkflowPath) => {
    const { data } = conditionalWorkflowPath?toJS(ConditionalWorkflowPathStore):toJS(ConditionalWorkflowStore);
    const placeholderValue = replacePlaceholderValue(event.target.value);

    const value = checkKey(placeholderValue, indexLogic, data);

    conditionalWorkflowPath?ConditionalWorkflowPathStore.setLogic(placeholderValue, indexLogic, "key"):ConditionalWorkflowStore.setLogic(placeholderValue, indexLogic, "key");

    this.setState({ checkKey: value[0], errorMessage: value[1] });
    this.props.handleKeyChanges(value[0]);
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;

    this.setState({ updateState: !currentState });
  };

  render() {
    const {
      indexLogic,
      data,
      availablePlaceholders,
      placeholders,
      conditionalWorkflowPath,
        classes,
      isFromConditionalPath,

    } = this.props;
    const { key, outputType, statement,pathName ,color} = data;
    const { condition } = statement.content;
    return (
      <Paper className={this.props.classes.paperItem} >
        <Grid container direction={"row"}  justifyContent={"end"} spacing={1} paddingBottom={"8px"} >
            {conditionalWorkflowPath && <Grid item  >
              <Tooltip title="Copy">

                <FileCopyOutlinedIcon onClick={() => conditionalWorkflowPathStore.copyThisLogic(indexLogic)}
                                      className={classes.deleteIcon}/>
              </Tooltip>

            </Grid>

            }
            {conditionalWorkflowPath && <Grid item  >
              <Tooltip title="Delete">

                <DeleteIcon onClick={() => conditionalWorkflowPathStore.removeLogic(indexLogic)}
                            className={classes.deleteIcon}/>
              </Tooltip>

            </Grid>

            }



        </Grid>
        <Grid container xs={12} direction={"row"} alignItems={"center"} marginBottom={1} >
          <Grid item xs={2}>
            Placeholder
          </Grid>
          <Grid item xs={true} marginLeft={2} >
            <W86PlaceholderTextField
              value={key}
              fullWidth={true}
              error={this.state.checkKey ? true : false}
              helperText={this.state.checkKey ? this.state.errorMessage : null}
              placeholderText="Enter placeholder for conditional path"
              handleChange={(event) => { this.handleInputKey(event, indexLogic,conditionalWorkflowPath); }}
            />

          </Grid>
        </Grid>
        {conditionalWorkflowPath && <Grid container direction={"column"} marginBottom={"16px"}>
          <Grid item marginBottom={"8px"}>
            <span>Path Name</span>
          </Grid>
          <Grid item>

            <TextField
                fullWidth={true}
                value={pathName}
                style={{height:"30px"}}
                size="small"
                style={{backgroundColor:color.bg,
                  border:`1px solid ${color.border}`, width:"100%", borderRadius:"4px"}}
                onChange={(event)=> {
                  ConditionalWorkflowPathStore.setLogic(
                      event.target.value,indexLogic,
                      "pathName"
                  )
                }}

            />


          </Grid>
        </Grid>}

        {condition &&
          condition.map((con, index) => (
            <ItemStatement
              classes={this.props.classes}
              isExpanded={this.props.isExpanded}
              condition={con}
              indexLogic={indexLogic}
              indexStatement={index}
              key={index}
              outputType={outputType}
              availablePlaceholders={availablePlaceholders}
              placeholders={placeholders}
              forceUpdateState={this.forceUpdateState}
              conditionalWorkflowPath={conditionalWorkflowPath}
              pathName={pathName}

            />
          ))}
      </Paper>
    );
  }
}

export default withStyles(styles)(ItemLogic);
