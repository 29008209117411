import projectStore from "../components/ProjectCanvas/ProjectStore"

export const getAutoCompleteOptions = (options) => {

    if (options === undefined || options === null) {
        return;
    }

    const categories = projectStore.state.workflowCategories.filter(c => options.includes(c.categoryId));

    return categories;

}

export const getMarketplaceDialogOptions = (options) => {

    return options.map(({ categoryId, displayName }) => ({
        displayName,
        categoryId
    }))

}