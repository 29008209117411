import React from 'react'; 
import {  MenuItem, Button, Select } from "@mui/material";

function Logic(props) {
  return (
    <Select
      style={{
        width: "100%",
        backgroundColor: "#F8F8F8",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
        textAlign:"center"
      }}
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectStandard"
      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={"OR"} > OR </MenuItem>
      <MenuItem value={"AND"} > AND </MenuItem>
    </Select>
  )
}

function OutputType(props) {
  return (
    <Select
      style={{
        width: "100%",
        backgroundColor: "#F8F8F8",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
      }}
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectStandard"
      displayEmpty
      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value="" disabled> Select type </MenuItem>
      <MenuItem value={"TEXT"} > Text </MenuItem>
      <MenuItem value={"NUMBER"} > Number </MenuItem>
    </Select>
  )
}


function SelectFuc(props) {
  return (
    <React.Fragment>
      <Select
        // native
        style={{
          width: "100%",
          backgroundColor: "#F8F8F8",
          borderRadius: "4px",
          height: "36px",
          color: "#868686"
        }}
        name="selectFunc"
        displayEmpty
        value={props.value}
        onChange={event => {
          props.onChange(event.target.value);
        }}
        disableUnderline
        labelId="demo-simple-select-outlined-label"
        className="dropdown-mat selectStandard"
        variant="standard"
      >
        <MenuItem value="" disabled> Function </MenuItem>
        <MenuItem value={"CONTAIN"} > contains </MenuItem>
        <MenuItem value={"NOTCONTAIN"} > does not contain </MenuItem>
        <MenuItem value={"MATCHES"} > matches </MenuItem>
        <MenuItem value={"NOTMATCH"} > does not match </MenuItem>
        <MenuItem value={"EQUAL"} > equals </MenuItem>
        <MenuItem value={"NOTEQUAL"} > does not equal </MenuItem>
        <MenuItem value={"GREATERTHAN"} > greater than </MenuItem>
        <MenuItem value={"GREATERTHANOREQUAL"} > greater than or equal to </MenuItem>
        <MenuItem value={"LESSTHAN"} > less than </MenuItem>
        <MenuItem value={"LESSTHANOREQUAL"} > less than or equal to </MenuItem>
        <MenuItem value={"NOTEMPTY"} > is not empty </MenuItem>
        <MenuItem value={"EMPTY"} > is empty </MenuItem>
      </Select>
    </React.Fragment>
  )
}



export {
  Logic, SelectFuc, OutputType
}