import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Grid, styled } from "@mui/material";
import { send_request } from "../../utils/Request";
import ErrorExtendingFreeTrialDialog from "../pages/DashboardComponents/ErrorExtendingFreeTrialDialog";

const TopBar = styled(Grid)(({ isClosing }) => ({
    position: "fixed",
    zIndex: "999 !important",
    height: "36px",
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    animation: `${isClosing ? 'slideUp' : 'slideDown'} 0.3s ease-out`,
    '@keyframes slideDown': {
        from: { transform: 'translateY(-100%)' },
        to: { transform: 'translateY(0)' }
    },
    '@keyframes slideUp': {
        from: { transform: 'translateY(0)' },
        to: { transform: 'translateY(-100%)' }
    }
}));

const BannerContent = styled(Link)(({ color }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: color,
    borderRadius: '0 0 12px 12px',
    padding: '6px 24px',
    margin: '0px',
    color: '#FFF',
    whiteSpace: 'nowrap',
    gap: '4px',
    textDecoration: 'none',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
        boxShadow: '0 6px 20px rgba(0,0,0,0.3)',
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '-100%',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
        transition: 'all 650ms',
    },
    '&:hover:before': {
        left: '100%',
    }
}));

const Close = styled('span')({
    cursor: "pointer",
    marginLeft: '12px',
    fontSize: '20px',
    fontWeight: 'bold',
    opacity: 0.8,
    padding: '4px 8px',
    position: 'relative',
    zIndex: 2,
    '&:hover': {
        opacity: 1,
    }
});

const ContactUsText = styled("span")({
    fontWeight: "bold",
    "&:hover": {
        cursor: "pointer"
    }
})

const StyledLink = styled(Link)(({ textcolor }) => ({
    color: textcolor,
    textDecoration: 'none',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '0',
        height: '2px',
        bottom: '-2px',
        left: '0',
        backgroundColor: 'white',
        transition: 'width 0.3s ease'
    },
    '&:hover': {
        transform: 'translateY(-4px)',
        textShadow: '0 0 8px rgba(33,150,243,0.9)',
        '&:after': {
            width: '100%'
        }
    }
}));

function FreeTrialBanner({ classes, closeBanner }) {

    const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);
    const [errorExtendingTrial, setErrorExtendingTrial] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        // Make API call to get count
        send_request("authz-service/get_free_trial_remaining", "", "", "get")
            .then((res) => {
                setTrialDaysRemaining(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleReactivateTrial = async () => {
        let url = `authz-service/reactivateFreeTrial`;

        const json = await send_request(url, null, null, "POST");

        if (!json || !json.data) {
            // This means they were not eligible
            // Show error dialog
            setErrorExtendingTrial(true);
            return;
        }

        // Else, reload the page
        window.location.reload(false);
    };

    const getBannerColor = (remaining) => {
        if (remaining > 7) return "#000"; // Black
        else if (remaining > 0) return "#FF5800"; // Orange
        else return "rgba(176, 0, 32, 1)"; // Red
    };

    const getCorrectText = (remaining) => {
        if (remaining > 7) {
            return "You are currently on a free trial - click to see available pricing and plans";
        } else if (remaining > 0) {
            return "Your free trial is about to end - click to upgrade now";
        } else {
            return "Your 14-day trial has ended - click to upgrade now to keep using Workflow86";
        }
    };

    const handleClose = (e) => {
        e.preventDefault();
        setIsClosing(true);
        setTimeout(() => {
            closeBanner();
        }, 300); // Match animation duration
    };

    return (
        trialDaysRemaining !== null && (
            <TopBar container isClosing={isClosing}>
                <BannerContent 
                    color={getBannerColor(trialDaysRemaining)}
                    to="/set_plan" 
                    target="_blank"
                >
                    {getCorrectText(trialDaysRemaining)}
                    <Close onClick={handleClose}>×</Close>
                </BannerContent>
                {errorExtendingTrial && <ErrorExtendingFreeTrialDialog 
                    errorExtendingTrial={errorExtendingTrial} 
                    setErrorExtendingTrial={setErrorExtendingTrial} 
                />}
            </TopBar>
        )
    );
}

export default FreeTrialBanner;
