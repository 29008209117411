import React, { Component } from "react";
import { Grid, Paper, TextField } from "@mui/material";
import SelectProject from "../Component/SelectProject";
import ItemDatabase from "./ItemDatabase";
import { withStyles } from "@mui/styles";
import styles from "../WorkflowData/DataBankStyle";
import { inject, observer } from "mobx-react";
import { reaction, toJS } from "mobx";
import { send_component_save_request, send_request } from "../../utils/Request";
import { getCurrentTime } from "../../utils/getCurrentTime";
import CreateDatabaseRecordStore from "./CreateDatabaseRecordStore";
import "./CreateDatabaseRecordStyle.css";
import checkKey from "../../utils/CheckKey";
import Icon from "@mui/material/Icon";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import projectStore from "../ProjectCanvas/ProjectStore";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import "../DataBank/DataBankStyle.css"
class CreateDatabaseRecordTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availablePlaceholders: [],
      availableCols: [],
      columns: [],
      databaseList: [],
      targetDatabaseId: null,
      checkKey: false,
      errorMessage: "",
      loadingScreen: false,
      isLoading: false,
      description:null,
      status:null
    };
  }

  saveButton = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.checkKey) {
        this.props.showError();
        return;
      }

      let cData = {};
      //cData.name = toJS(this.props.ComponentName);
      let lastModified = getCurrentTime();
      let data = {
        componentData: {
          data: toJS(CreateDatabaseRecordStore).data,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified,
          targetDatabaseId: CreateDatabaseRecordStore.targetDatabaseId,
        },
        componentId: this.props.component_id,
        type: "create_database_record",
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };
      send_component_save_request(
        "component-service/create_database_record/data",
        data,
        "",
        "POST",
        this.props.SaveTrigger
      )
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loadingScreen: true });

      send_request(`database/get_databases`, "", {}, "GET").then((response) => {
        if (response.data) {
          this.setState({ databaseList: response.data });
          send_request(
            `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
            "",
            {}
          ).then((response) => {
            if (
              response.data &&
              response.data.components &&
              response.data.components[0].componentData
            ) {
              this.props.onComponentNameChanged(
                response.data.components[0].componentData.name
              );
              this.props.onComponentDescriptionChanged(response.data.components[0].description);
              this.props.onLastModifiedChanged(
                response.data.components[0].componentData.lastModified
              );

              let queryData = response.data.components[0].componentData.data;
              if (!Array.isArray(queryData)) {
                //somehow it is not an array.
                queryData = [];
              }
              CreateDatabaseRecordStore.setCreateDatabaseRecordTemplate(
                response.data.components[0]
              );
              this.setState({
                loadingScreen: false,
              });
              this.props.setChildTemplateLoaded(true);
            } else {
              CreateDatabaseRecordStore.setCreateDatabaseRecordTemplate(
                response.data.components[0]
              );
              this.setState({ loadingScreen: false });
              this.props.setChildTemplateLoaded(true);
            }

            if(this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["data"]!==undefined && this.props.aiComponentBuilderData["data"]!==''  )
            {
              this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
              this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
           //   console.log(this.props.aiComponentBuilderData)
              CreateDatabaseRecordStore.setTargetDatabaseId(this.props.aiComponentBuilderData.targetDatabaseId)
              ProjectStore.targetDataBaseId.set(this.props.aiComponentBuilderData.targetDatabaseId);
            //  this.props.targetDatabaseId=this.props.aiComponentBuilderData.componentData.targetDataBaseId;
              CreateDatabaseRecordStore.setData(this.props.aiComponentBuilderData.data.columns);
              CreateDatabaseRecordStore.setKey(this.props.aiComponentBuilderData.data.key);
              this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
              this.setState({ status: "AI_BUILD_GENERATED" });

            }
          });
        }
      });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveButton();
    this.reactToPlaceHolder();
  }

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key } = placeholder["selected"];

      this.insertPlaceholder(style.default, key, style);
    }
  );

  insertPlaceholder = (label, text, style) => {
    let { placeholders } = CreateDatabaseRecordStore;
    placeholders.push({ label, text, style });
    CreateDatabaseRecordStore.setPlaceholders(placeholders);
  };

  updateTargetProject = (targetDatabaseId) => {
    send_request(
      `database/get_columns/${targetDatabaseId}`,
      "",
      {},
      "GET"
    ).then((response) => {
      if (response.data) {
        let columns = [];
        let { data } = toJS(CreateDatabaseRecordStore);

        response.data.forEach((column) => {
          const col = data.columns.filter((c) => c.columnName === column.name);
          const value = col.length > 0 ? col[0].columnValue : "";
          columns.push({
            columnId: column.columnId,
            columnName: column.name,
            columnType: column.type,
            columnValue: value,
            orderNumber: column.orderNumber,
          });
        });

        CreateDatabaseRecordStore.clearData();

        columns.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));

        CreateDatabaseRecordStore.setData(columns);
      }
    });
    this.setState({
      isLoading: false,
    });
  };

  handleKeyChange = (e) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    CreateDatabaseRecordStore.setKey(placeholderValue);

    const { data } = toJS(CreateDatabaseRecordStore);
    const value = checkKey(placeholderValue, data);

    this.setState({
      checkKey: value[0],
      errorMessage: value[1],
    });
  };

  render() {
    const { component, availablePlaceholders } = this.props;
    let { databaseList, targetDatabaseId, data } = CreateDatabaseRecordStore;
    let { key } = data;
    if (!this.state.loadingScreen) {
      return (
        <Grid container xs={12}>
          <Grid item xs={12} >
            <Paper className="query-item-paper defaultText">
              <Grid container spacing={2} style={{ marginBottom: 24 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={2}
                  xl={2}
                  className="item-label-create-record text-normal"
                  style={{ flexBasis: "unset" }}
                >
                  Placeholder{" "}
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
                  <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    <W86PlaceholderTextField
                      value={key}
                      error={this.state.checkKey ? true : false}
                      helperText={this.state.checkKey ? this.state.errorMessage : null}
                      placeholderText="Enter placeholder for create database"
                      handleChange={this.handleKeyChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <SelectProject
                databaseList={this.state.databaseList}
                updateTargetProject={this.updateTargetProject}
                targetDatabaseId={targetDatabaseId}
                setTargetDatabaseId={(targetDatabaseId) => {
                  CreateDatabaseRecordStore.setTargetDatabaseId(
                    targetDatabaseId
                  );
                  ProjectStore.targetDataBaseId.set(targetDatabaseId);

                }}
                component={component}
                isLoading={this.state.isLoading}
                setIsLoading={(isLoading) => {
                  this.setState({
                    isLoading,
                  });
                }}
              />
              <p className="query-item-content">
                Configure the values for each column in the new record
              </p>
              {data.columns.map((column, index) => (
                <ItemDatabase
                  availablePlaceholders={availablePlaceholders}
                  column={column}
                  index={index}
                  columns={data.columns}
                />
              ))}
            </Paper>
          </Grid>
        </Grid>
      );
    } else {
      return <IndividualComponentLoader />;
    }
  }
}
export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(CreateDatabaseRecordTemplate))
);
