import { send_request } from "../Request";

export const getProjectInfoWithComponentId = (componentId) => {
    return send_request(
        `project-service/project/get_project_info_with_componentid/` + componentId,
        {},
        null,
        "GET"
    )
}

export const getProjectSessionWithSessionId = (sessionId) => {
    return send_request(
        `project-service/project-session/${sessionId}`,
        {},
        null,
        "GET"
    )
}

export const getComponentSessionWithSessionIdAndComponentId = (sessionId, componentId) => {
    return send_request(
        `project-service/project-session/component_session/${sessionId}/${componentId}`,
        {},
        null,
        "GET"
    )
}