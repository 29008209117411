import React, { Fragment } from "react";

import { Grid } from "@mui/material";
import TransformStore from "../../TransformStore";
import ChipInput from "../../../ChipInput/ChipInput";

function CountLength({ onPlaceholderSelected, availablePlaceholders, followList, index }) {
    return (
        <Fragment>
            <Grid item xs={12} className="item-label">
                Count the length of the following list
            </Grid>
            <Grid item xs={12} className="input-list-transform">
                <ChipInput
                    onPlaceholderSelected={onPlaceholderSelected}
                    placeholders={availablePlaceholders}
                    fullWidth
                    onBeforeAdd={(chip) => {
                        TransformStore.setComponent(chip, index, "followList");
                        return true;
                    }}
                    placeholder="Insert a list"
                    inputValue={followList}
                    onDelete={(indexChip) => TransformStore.deleteChip(indexChip, index, "followList")}
                    onFocus={() => TransformStore.setFocus(index, "followList")}
                />
            </Grid>
        </Fragment>
    );
}

export default CountLength;
