import React, { useState, useEffect } from "react";
import { Modal, Box, AppBar, Toolbar, IconButton, Button, TextField, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from '@mui/material/styles';
import WorkflowProgressView from "../../WorkflowProgressView/WorkflowProgressView";

const ModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90%',
    backgroundColor: '#fff',
    boxShadow: 24,
    borderRadius: '10px',
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
});

const StyledAppBar = styled(AppBar)({
    maxHeight: '56px',
    backgroundColor: '#f1f3f4',
    color: '#000',
});

const StyledToolbar = styled(Toolbar)({
    minHeight: '56px',
    height: '56px',
    padding: '0 16px',
});

const FlexGrow = styled(Box)({
    flexGrow: 1,
});

const ContentWrapper = styled(Box)({
    flexGrow: 1,
    overflow: 'auto',
});

const StyledButton = styled(Button)({
    color: 'inherit',
    marginRight: '8px',
});

const StyledTextField = styled(TextField)({
    flexGrow: 1,
    marginRight: '8px',
    '& .MuiInputBase-root': {
        height: '36px',
        backgroundColor: '#fff',
        borderRadius: '18px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    '& .MuiInputBase-input': {
        fontSize: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
});

const ActionButton = styled(IconButton)({
    width: '36px',
    height: '36px',
    marginLeft: '8px',
});

function WorkflowProgressModal({ open, onClose, projectId, sessionId, isTest }) {
    const [url, setUrl] = useState('');

    useEffect(() => {
        const fullUrl = `${window.location.origin}/project/logs/progress_view/${projectId}/${sessionId}?test=${isTest}`;
        setUrl(fullUrl);
    }, [projectId, sessionId, isTest]);

    const openFullView = () => {
        window.open(url, '_blank');
    };

    const copyUrl = () => {
        navigator.clipboard.writeText(url);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="workflow-progress-modal"
            aria-describedby="workflow-progress-view"
        >
            <ModalBox>
                <StyledAppBar position="static" elevation={0}>
                    <StyledToolbar>
                        <StyledTextField
                            value={url}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <ActionButton
                            color="inherit"
                            onClick={copyUrl}
                            aria-label="copy url"
                        >
                            <ContentCopyIcon />
                        </ActionButton>
                        <ActionButton
                            color="inherit"
                            onClick={openFullView}
                            aria-label="open in new tab"
                        >
                            <OpenInNewIcon />
                        </ActionButton>
                        <ActionButton
                            edge="end"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </ActionButton>
                    </StyledToolbar>
                </StyledAppBar>
                <ContentWrapper>
                    <WorkflowProgressView
                        id={projectId}
                        project_session_id={sessionId}
                        isTest={isTest}
                        isSplitView={true}
                    />
                </ContentWrapper>
            </ModalBox>
        </Modal>
    );
}

export default WorkflowProgressModal;
