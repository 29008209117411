import React from "react";
import { send_request, send_request_prebuilt_graphql } from "../../../../../../utils/Request";
import { RETRIEVE_SESSION } from "../../AIBuilderQueries";
import { getProposalMessage, INITIAL_COMPONENT_MESSAGE } from "../../components/Chat/StaticMessages/Assistant";
import { ERROR_MESSAGE } from "../../components/Chat/StaticMessages/Error";

import { INSERT_COMPONENT_LOADING_MESSAGE, INSERT_LOADING_MESSAGE } from "../../components/Chat/StaticMessages/Loading";
import { SUCCESS_MESSAGE, SUCCESS_MESSAGE_COMPONENT } from "../../components/Chat/StaticMessages/Success";

import { REFUSAL_ERROR_MESSAGE } from "../../components/Chat/StaticMessages/REFUSAL_ERROR_MESSAGE";

export const convertEventsAndSyncState = async (fns) => {
    const { events, setMessages,setSidebarState,setMessageSent } = fns;
    console.log(events)


    if (!events) return [];
    let messages =[];
    if(events[0].aiEventSource==="COMPONENT_EDITOR")
    {
        setSidebarState("EDIT");
        messages.push({
            role: "editComponentMessage",
            showTimer: false,
            content: ""
        })
    }
    else{
        setSidebarState("BUILD");
         messages.push({
            role: "buildComponentMessage",
            showTimer: false,
            content: ""
        });
    }

    setMessageSent(true)

    for (let i = 0; i < events.length; i++) {
        let event = events[i];
        if (!event) continue;

        let nextEvent = events[i + 1]; // Grab next event

        try {
            let translatedEvent = await handleEventMappingToFunction(event, nextEvent, fns);
            if (!translatedEvent) continue;
            messages.push(translatedEvent);
        } catch (e) {
            console.log(e);
        }
    }

    setMessages(messages); // Set messages

    // Sync the state from the messages
    await syncChatState(fns,messages);
};

// Handles mapping the event type to the given functionality
const handleEventMappingToFunction = async (event, nextEvent, fns) => {
    switch (event.aiEventType) {
        // user
        case "USER_SENT_INITIAL_COMPONENT_BUILDER_MESSAGE":
            return getUserSentInitialComponentBuilderMessage(event);
        case "USER_SENT_ADDITIONAL_MESSAGE_TO_COMPONENT_BUILDER":
            return getUserSentAdditionalMessage(event);
        case "USER_CLICKED_CONFIGURE_AND_SAVE_COMPONENT":
            return getUserClickedConfigureAndSaveComponent(event);

        // ai
        case "AI_SENT_COMPONENT_BUILD_PROPOSAL_TO_USER":
            return getExplanationMessage(event);
        
        // w86
        case "W86_ERROR_MESSAGE_SENT":
            return getW86ErrorMessageSent(event);
        case "W86_COMPONENT_BUILD_STARTED":
            return getW86ComponentBuildStarted(event);
        case "W86_COMPONENT_BUILD_COMPLETED":
            return getW86ComponentBuildCompleted(event);
        case "W86_COMP_BUILDER_PROPOSAL_MESSAGE_SENT":
            return getW86CompBuilderProposalMessageSent(event);
        case "W86_COMP_BUILDER_CONFIGURE_AND_SAVE_MESSAGE_SENT":
            return getW86CompbuilderConfigureAndSaveMessageSent(event);
        case "W86_CONFIGURING_COMPONENT_MESSAGE_SENT":
            return getW86ConfiguringComponentMessageSent(event);
        case "W86_COMPONENT_HAS_BEEN_CONFIGURED_MESSAGE_SENT":
            return getW86ComponentHasBeenConfiguredMessageSent(event);
        case "W86_COMPONENT_EXPLANATION_SENT":
            return getExplanationMessage(event);
        default:
            break;
    }
};

// ============ USER ============ //
const getUserSentInitialComponentBuilderMessage = (event) => {
    return { role: "user", content: event.content }
}
const getExplanationMessage = (event) => {
    console.log(event)
    return { role: "success", content: event.content }
}

const getUserSentAdditionalMessage = (event) => {
    return { role: "user", content: event.content }
}

const getUserClickedConfigureAndSaveComponent = (event) => {
    return { role: "user", content: event.content }
}

// ============ AI ============ //
const getAiSentComponentBuildProposalToUser = (event) => {
    return;
}


// ============ W86 ============ //
const getW86ErrorMessageSent = (event) => {
    if (!event) return;

    let type = "GENERIC_ERROR";
    if (event.content && event.content.type) type = event.content.type;

    let errorContent = "An error has occurred.";

    switch (type) {
        case "GPT_REFUSE_EXCEPTION":
            if (event.content && event.content.errorInfo) {
                errorContent = REFUSAL_ERROR_MESSAGE(event.content.errorInfo).content
            }
            return { role: "error", content: errorContent };
            
        default:
            if (event.content && event.content.errorInfo) {
                errorContent = event.content.errorInfo;
            }
            return { role: "error", content: errorContent };
    }
}

const getW86ComponentBuildStarted = (event) => {
    return;
}

const getW86ComponentBuildCompleted = (event) => {
    return;
}

const getW86CompBuilderProposalMessageSent = (event) => {
    return {
        content: event.content,
        role: "assistant",
        showTimer: false,
        showProposalText: true
    };
}

const getW86CompbuilderConfigureAndSaveMessageSent = (event) => {
    return { role: "component_instruction", content: "" };
}

const getW86ConfiguringComponentMessageSent = (event) => {
    return INSERT_COMPONENT_LOADING_MESSAGE;
}

const getW86ComponentHasBeenConfiguredMessageSent = (event) => {
    return SUCCESS_MESSAGE_COMPONENT;   
}

// ============= SYCHRONISING STATE =============== //
export const syncChatState = async (fns,messages) => {
    const {
        events,
        setPaneSkeleton,
        setUserPrompt,
        setNewComponentData,
        setAiComponentBuilderData,
        setCancelled,
        setReset,
        setShowInstruction,
        setShowComponentSidebarButtons,
        setHideButtons,
        setShowUserPrompt,
        setAdditionalMessagePrompt,
        setSessionTraceId,
        setIsInAIDraft,
        setShowAnimationAsFalse,
        setMessages,
        setBuildInserted
    } = fns; // De-structure required functions and info

    const lastEvent = events[events.length - 1];
    // setPaneSkeleton(true);

    let url = `project-service/project-ai/assistant/retrieve_session_state/${lastEvent.sessionId}/${lastEvent.threadId}`;
    const json = await send_request(url, null, { aiEventSource: events[0].aiEventSource==="COMPONENT_EDITOR"?"COMPONENT_EDITOR":"COMPONENT_BUILDER" }, "GET");

    // console.log(json)
    let currentState = "GENERIC_ERROR";
    let info = {};

    if (json && json.data) {
        let res = json.data;

        if (res.chatSessionState) currentState = res.chatSessionState; // set state if given
        if (res.info) info = res.info;
    }

    setSessionTraceId(info.sessionTraceId); // Set trace id
    console.log(currentState);

    switch (currentState) {
        case "COMPONENT_BUILDER_GENERATING_PROPOSAL":
        case "COMPONENT_BUILDER_PROPOSAL_RETURNED":
            setTimeout(() => {
                setPaneSkeleton(true);
            }, 0);
        
            setUserPrompt(info.userPrompt);
            setCancelled(false);
            setReset(false);
            setShowInstruction(true);
            setShowComponentSidebarButtons(true);
            setHideButtons(false);
            setShowUserPrompt(true);
            setAdditionalMessagePrompt(info.additionalUserPrompt);
            setIsInAIDraft(true)

            // We need to set the draft component here
            setAiComponentBuilderData(info.draftComponent);
            setNewComponentData(info.draftComponent);

            setTimeout(() => {
                setPaneSkeleton(false);
            }, 0);

            break;

        case "COMPONENT_BUILD_CANCELLED":
        case "COMPONENT_BUILDER_PROPOSAL_ACCEPTED":
            setUserPrompt(info.userPrompt);
            setCancelled(false);
            setReset(false);
            setShowInstruction(true);
            setShowComponentSidebarButtons(true);
            setHideButtons(false);
            setShowUserPrompt(true);
            setAdditionalMessagePrompt(info.additionalUserPrompt);
            setIsInAIDraft(false);
            setBuildInserted(true);
            // disabling loading after component is inserted
           setMessages(setShowAnimationAsFalse(messages));



            // Set draft component for ai comp builder data
            // But set new comp data to empty string
            setAiComponentBuilderData(info.draftComponent);
            setNewComponentData("");

            break;

        default:
            setUserPrompt("");
            setNewComponentData("");
            setCancelled(false);
            setReset(false);
            setShowInstruction(false);
            setShowComponentSidebarButtons(false);
            setHideButtons(false);
            setShowUserPrompt(false);
            setAdditionalMessagePrompt("");
            setIsInAIDraft(false);
            
            setAiComponentBuilderData({});
            setNewComponentData("");
            break;
    }
};