import { styled } from "@mui/system";

export const StyledTray = styled("div")(({ hasBanner }) => ({
    background: "#FFF",
    // overflowY: "scroll",
    overflowX: "hidden !important",
    margin: "0px",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    right: 0,
    width: "20vw",
    ["@media (max-width:1800px)"]: {
        width: "23vw"
    },
    ["@media (max-width:1600px)"]: {
        width: "26vw"
    },
    ["@media (max-width:1450px)"]: {
        width: "28vw"
    },
    ["@media (max-width:1350px)"]: {
        width: "30vw"
    },
    ["@media (max-width:1250px)"]: {
        width: "33vw"
    },
    ["@media (max-width:1100px)"]: {
        width: "36vw"
    },
    ["@media (max-width:1000px)"]: {
        width: "40vw"
    },
    top: hasBanner ? "36px" : "0px",
    height: hasBanner ? "calc(100% - 36px)" : "100%",
    padding: "16px !important",
    marginLeft: "16px !important",
    borderRadius: "6px !important",
}));
