import React, { Component } from "react";
import { DropTarget } from "react-dnd";
import { observer } from "mobx-react";
import FormBuilderContainer from "./FormBuilderContainer";
import Validation from "./Validation";
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    item: monitor.getItem()
  };
}
const itemSource = {
  drop(props, monitor, component) {
    const hoverIndex = FormBuilderContainer.position;
    if (hoverIndex >= 0) {
      FormBuilderContainer.setComponentAndPosition(
        monitor.getItem(),
        hoverIndex
      );
    } else {
      FormBuilderContainer.addComponent(monitor.getItem());
    }
    props.setInnerPlaceholders();
  }
};
class Target extends Component {
  render() {
    const { connectDropTarget, coverImage , coverImageCrop, linkedComponent, launchSettingDialog,uploadIcon,formTitle} = this.props;
    return connectDropTarget(
      <div>
        <Validation coverImage={coverImage} coverImageCrop={coverImageCrop} 
          linkedComponent={linkedComponent} 
          launchSettingDialog={launchSettingDialog}
        uploadIcon={uploadIcon}
                    formTitle={formTitle}/>

      </div>
    );
  }
}

export default DropTarget("item", itemSource, collect)(observer(Target));
