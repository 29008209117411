import React, { useState, useEffect, Component } from "react";

import { send_request } from "../../../../utils/Request";

import { Security } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ComponentFactory } from "../../../ProjectCanvas/ComponentRegistry";
import { CanvasHeaderButton } from "../CanvasButtonsUtil/CanvasStyledComponents";

function AccessControl(props) {
  const {
    handleAccess,
    projectId,
    components,
    rerenderBadge,
    tooltips,
  } = props;

  const [accessStatus, setAccessStatus] = useState(null);

  useEffect(async () => {
    let componentsWithAccess = [];

    // Get component ids and isAlwaysPublic from components with access;
    for (let c of components) {
      if (
        ComponentFactory[c.type] &&
        ComponentFactory[c.type].hasAccessControl
      ) {
        let newC = {};
        newC["component_id"] = c["componentId"];
        newC["isAlwaysPublic"] = ComponentFactory[c.type].isAlwaysPublic;
        componentsWithAccess.push(newC);
      }
    }

    let projectComponentData = {
      projectId: String(projectId),
      components: componentsWithAccess
    };

    const request = await send_request(
      "authz-service/get-project-access-status",
      projectComponentData,
      "",
      "post"
    )
      .then((value) => {
        if (value.data != "") {
          setAccessStatus(value.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ rerenderBadge]);

  const StyledBadge = styled(Badge)({
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "black",
      marginRight: "32px !important",
      fontSize: "7px"
    }
  });

  let accessControl = (
    <Tooltip title={tooltips.access}>
      <StyledBadge
        badgeContent={accessStatus}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <CanvasHeaderButton
          data-tut="reactour__canvas__project__access"
          onClick={handleAccess}
          variant={"outlined"}
          startIcon={<Security />}
          disabled={props.isCanvasLocked}
        >
          Access
        </CanvasHeaderButton>
      </StyledBadge>
    </Tooltip>
  );

  return accessControl;
}

export default AccessControl;
