import React, { useState, useEffect } from "react";

import { Skeleton, styled } from "@mui/material";
import { send_request } from "../../utils/Request";

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "10px",
    background: "#E8E8E8",
    width: width,
    height: height,
    margin: margin,
    display: "inline-block",
}));

function ComponentNameFromId({ componentId }) {
    const [loading, setLoading] = useState(true);
    const [componentName, setComponentName] = useState(null);

    useEffect(async () => {
        setLoading(true);

        let name = `Untitled (${componentId})`;

        let url = `project-service/project/component/${componentId}`;
        const json = await send_request(url, null, null, "GET");
        if (json && json.data) {
            if (json.data.componentData && json.data.componentData.name) {
                name = json.data.componentData.name;
            }
            setComponentName(name);
            setLoading(false);
        } else {
            setComponentName(name);
            setLoading(false);
        }
    }, []);

    return !loading ? componentName : <StyledSkeleton variant="text" width="125px" margin="0px 4px" />;
}

export default ComponentNameFromId;
