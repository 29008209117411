import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { send_request } from '../../utils/Request'
import { showLoadingScreen } from '../../utils/showLoadingScreen'
import TestResults from '../WorkflowTest/TestResults'

const MainContainer = styled(Grid)({
    padding: "1rem"

})

const WorkflowTestResultPage = () => {

  const [testResult, setTestResult] = useState(null);
  const [refreshing, setRefreshing] = useState(true);
  const { session_id } = useParams();

  //Adding comment to trigger a deploy

  const handleRefresh = async () => {
    setRefreshing(true);
    await send_request(
      `project-service/test/get_project_test_by_sessionid/` + session_id,
      "",
      "",
      "GET" 
    ).then(res => {
      setTestResult(res.data);
    }).finally(() => {
      setRefreshing(false);
    })
    
  }

  useEffect(async () => {
    await send_request(
      `project-service/test/get_project_test_by_sessionid/` + session_id,
      "",
      "",
      "GET" 
    ).then(res => {
      setTestResult(res.data);
    })
    .finally(() => {
      setRefreshing(false);
    });

  }, []);

  return (
    <MainContainer container xs={12} direction="column">
      {
        refreshing ?
        showLoadingScreen()
        :
        <TestResults
          testResult={testResult}
          displayOnly
          handleRefresh={handleRefresh}
        />
      }
    </MainContainer>
  )
}

export default WorkflowTestResultPage