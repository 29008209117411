import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Select,
  MenuItem, InputAdornment, TextField as TextFieldUI,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {withStyles} from "@mui/styles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/material';
import { Link } from "react-router-dom";

const NewTabIcon = styled(OpenInNewIcon)({
  height: "14px",
  width: "auto",
  marginRight: 4
});

const StyledSpan = styled("span")({
  display: "flex",
  alignItems: "center",
  fontSize: "0.9em",
  marginTop: 16,
  color: "#2196F3",
  "&:hover": {
    cursor: "pointer",
    filter: "brightness(0.9)"
  }
});

const styles = (theme) => ({
  autocomplete: {
    "&> div > div > input" : {
      height: 6
    }
  }
})

class SelectProject extends Component {
  constructor(props) {
    super(props);
    /*this.state = {
      method: this.props.componentStore.data.type
    };*/
    this.state = {
      isLoading:false,
      refreshLoading: false,
      selectOpen: false,
      databaseName: "",
    }

    
  }

  handleGetData = (targetDatabaseId) => {
    this.props.setIsLoading(true)
    this.props.updateTargetProject(targetDatabaseId);

    let currDatabase = this.props.databaseList.find((dB) => dB.databaseId === targetDatabaseId);
    if (!currDatabase) return;
    this.setState({ databaseName : currDatabase.name })
  }

  componentDidMount() {
    let targetId = this.props.targetDatabaseId;

    let currDatabase = this.props.databaseList.find((dB) => dB.databaseId === targetId);
    if (!currDatabase) this.setState({targetDatabaseId: targetId});
    else this.setState({ targetDatabaseId: targetId, databaseName: currDatabase.name });
  }

  setDatabaseId = (value) => {
    this.props.setTargetDatabaseId(value)
  }


  render() {
    const { component,isLoading, classes } = this.props
    const db = this.props.databaseList.filter(db => db.databaseId === this.props.targetDatabaseId);

    return (
        <Grid container>
          <Grid item xs={12}>
            {component === "workflow_data" ? "Import data from Workflow" : "Select database"}
          </Grid>
          <Grid item xs={12}>
            <Grid container display={"flex"} alignItems={"center"} marginTop={1}>
              <Grid item xs={5} marginRight={2}>
                <Autocomplete
                  freeSolo
                  options={this.props.databaseList}
                  getOptionLabel={(option) => option.name ? option.name : option.projectId}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select database" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.projectId}>
                      {option.name ? option.name : option.projectId}
                    </li>
                  )}
                  onChange={(event, newValue) => {
                    if (!newValue) return;
                    this.setDatabaseId(newValue.databaseId)
                  }}
                  value={(db && db[0]) ? db[0] : null}
                  className={classes.autocomplete}
                />
              </Grid>
              <Grid item>
                <Button
                    variant={"contained"} color={"primary"} 
                    onClick={() => {
                      this.handleGetData(this.props.targetDatabaseId)
                    }}
                    style={{marginRight: 16}}
                    disabled={isLoading}
                  >
                    { component === "create_database_record" ? "Get Columns" : "Get Data" }
                    {isLoading && (
                      <CircularProgress size={24} className="buttonProgress" />
                    )}
                  </Button>
              </Grid>
            </Grid>
          </Grid>
          {this.state.databaseName && 
            <Grid item xs={12}>
              <Link
                to={`/databases/database_management/${this.props.targetDatabaseId}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <StyledSpan>
                  <NewTabIcon />
                  Open {this.state.databaseName} in a new tab
                </StyledSpan>
              </Link>
            </Grid>
          }
        </Grid>
    );
  }

}

export default withStyles(styles)(SelectProject);
