import React, {Component} from "react";
import {observer} from "mobx-react";
import {toJS} from "mobx";
import Cards from "./Cards";
import "./Cards.css";
import FormBuilderContainer from "./FormBuilderContainer";
import { createTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import DropDown from "@mui/material/Menu";
import RibbonItem from "vardogyir-ui/RibbonItem";
import TextField from "vardogyir-ui/TextField";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CoverImage from "./CoverImage";
import {
  PlaylistAddCheckRounded,
  DirectionsRounded
} from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";
import {Grid} from "@mui/material";

const update = require("immutability-helper");

const styles = (theme) => ({
  textroot: {
    width: "100%",
    height: "100px",
    margin: "auto"
  },
  pagetitle: {
    fontWeight: 300,
    fontSize: "3.3rem"
  },
  pageDescription: {
    fontSize: "1rem"
  },
  textField: {
    width: 600,
    margin: "auto",
    position: "relative"
  }
});
class Validation extends Component {
  state = {
    pageTitle: "",
    pageDescription: ""
  };
  handleInput = (type, event) => {
    FormBuilderContainer.pageTitles[0][type] = event.target.value;
  };

  deleteItem = (id) => {
    FormBuilderContainer.pages = FormBuilderContainer.pages.filter(item => item.id !== id);
  };

  moveCard = (dragIndex, hoverIndex) => {
    let newcards = FormBuilderContainer.pages;

    // dragCard is card we are dragging
    let dragCard = newcards[dragIndex];

    // removing this dragCard from array
    newcards.splice(dragIndex, 1);

    // insert dragCard at hover position
    newcards.splice(hoverIndex, 0, dragCard);

    FormBuilderContainer.pages = newcards;
  };

  moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  upItem = (id) => {
    const cards = toJS(FormBuilderContainer.pages);
    let index = cards.findIndex((card) => card.id == id);
    let result = this.moveArrayItemToNewIndex(cards, index, index - 1);
    FormBuilderContainer.pages = result;
  };

  downItem = (id) => {
    const cards = toJS(FormBuilderContainer.pages);
    let index = cards.findIndex((card) => card.id == id);
    let result = this.moveArrayItemToNewIndex(cards, index, index + 1);
    FormBuilderContainer.pages = result;
  };

  editItem = (id) => {
    FormBuilderContainer.editComponent(id);
    FormBuilderContainer.onEdit = true;
  };
  copyItem = (id) => {
    FormBuilderContainer.copyItem(id);
  };

  render() {
    const theme = createTheme({
      palette: {
        primary: { 500: "#2196F3" }
      },
      typography: { useNextVariants: true }
    });
    const data = toJS(FormBuilderContainer.pageTitles);
    const {
      classes,
      coverImage,
      coverImageCrop,
      linkedComponent,
      launchSettingDialog,
      uploadIcon,formTitle
    } = this.props;
    return (
      <>
        <div className="form-component-validation">
          <Toolbar variant="dense" style={{ minHeight: "45px !important", borderBottom: "1px solid #C4C4C4"} } xs={12}>
            <Grid
                container
                direction="row"
                alignItems="center"
            >
              <Grid item >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="flex-start"
                >
                  <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid style={{ width: "auto", height: "32px" }}>
                      {
                      uploadIcon == "" ? (
                          <img
                              alt="logo"
                              style={{
                                width: "auto",
                                height: "100%",
                                borderRadius: 8,
                              }}
                              src={"/images/default-image-form.png"}
                          />
                      ) : (
                          <img
                              alt="logo"
                              style={{
                                width: "auto",
                                height: "100%",
                                borderRadius: 8,
                              }}
                              src={uploadIcon}
                          />
                      )}
                    </Grid>

                  </Grid>
                </Grid>

              </Grid>
              <Grid
                  item
                  style={{ display: "flex", alignItems: "center" ,marginLeft:"8px"}}
              >
                {formTitle}
              </Grid>
            </Grid>
          </Toolbar>

          {coverImage !== undefined && coverImage !== "" ? (
            <div className="form-cover-image">
              <CoverImage
                coverImage={coverImage}
                coverImageCrop={coverImageCrop}
              />
            </div>
          ) : (
            ""
          )}

          <div className="appIntro">
            <div style={{ textAlign: "center" }} className="cardContainer">
              {FormBuilderContainer.pages.length == 0 && (
                <p style={{ marginTop: "30vh" }}>Drop Area</p>
              )}
              {FormBuilderContainer.pages.map((card, i) => {
                if (i == 0) {
                  return (
                    <div>
                      <div className="cardPageTitle">
                        <TextField
                          fontSize={24}
                          Theme={"blue"}
                          value={data[0].page_title}
                          className={classes.textroot}
                          InputProps={{
                            className: classes.pagetitle
                          }}
                          Label={"Page Title"}
                          onChange={this.handleInput.bind(this, "page_title")}
                        />
                      </div>
                      <Cards
                        key={card.id}
                        index={i}
                        id={card.id}
                        type={card.type}
                        text={card.text}
                        editItem={this.editItem}
                        copyItem={this.copyItem}
                        deleteItem={this.deleteItem}
                        moveCard={this.moveCard}
                        upItem={this.upItem}
                        downItem={this.downItem}
                      />
                    </div>
                  );
                } else {
                  return (
                    <Cards
                      key={card.id}
                      index={i}
                      id={card.id}
                      type={card.type}
                      text={card.text}
                      editItem={this.editItem}
                      deleteItem={this.deleteItem}
                      copyItem={this.copyItem}
                      moveCard={this.moveCard}
                      upItem={this.upItem}
                      downItem={this.downItem}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>

      <div 
        style={{width: "100%",
                borderRadius: "8px",
                wordWrap: "break-word",
                overflow: "hidden",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgb(0 0 0 / 25%)",
                display:"flex",
                justifyContent:"center",
                padding:"8px",
                cursor:"pointer"}}
        onClick={() => {launchSettingDialog();} }
      >
        
        {(!linkedComponent) ? (<><PlaylistAddCheckRounded/> &nbsp; Display submission page when submitted</>): 
        (<> <DirectionsRounded/> &nbsp; Redirect to next page when submitted  </>) }
        
      </div>
      </>
    );
  }
}

export default withStyles(styles)(observer(Validation));
