// Part of the sign up flow
//
// This component conditionally displays various messages during the sign-up process 
// based on the user's sign-up status. It handles three main states:
// - Private Beta: Shows a message for users when sign-ups are disabled and provides 
//   an option to request access.
// - Sign-Up Success: Displays a success message with the user's email when sign-up is 
//   completed successfully.
// - Referral: Shows a referral code message when applicable.
// - Default: Shows a prompt to sign up for an account.
//
// Props:
// - allowNewSignups (boolean): Determines if new sign-ups are allowed.
// - formState (string): Indicates the current state of the sign-up form (e.g., 
//   "signUpSuccess", "referral").
// - email (string): The user's email, displayed in the success message.
//
// Usage:
// <SignUpStatusMessage allowNewSignups={allowNewSignups} formState={formState} email={email} />
//

import React from 'react';
import { Button, styled } from '@mui/material';

const ButtonContainer = styled('div')({
  display: 'flex',
  gap: '16px',
  marginTop: '24px',
});

const BetaAccessMessage = () => (
  <div>
    <h4>Workflow86 AI is in private beta</h4>
    <p>
      We are currently in private beta for our AI release and have temporarily
      disabled new sign-ups. Please click the button below to request access.
    </p>
    <ButtonContainer>
      <Button
        variant="outlined"
        color="info"
        onClick={() => (window.location.href = "/")}
      >
        Back
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          window.open(
            "https://form.workflow86.com/form/57c15fd2-87b4-45cc-8700-7a7da8607f09",
            "_blank"
          )
        }
      >
        Request Access
      </Button>
    </ButtonContainer>
  </div>
);

const SignUpSuccessMessage = ({ email }) => (
  <h4>Instructions sent to {email}</h4>
);

const ReferralMessage = () => (
  <h4>💌 Invite code</h4>
);

const SignUpHeader = () => (
  <h4>Sign up for an account</h4>
);

const ConditionalSignupMessage = ({ allowNewSignups, formState, email }) => (
  <>
    {!allowNewSignups ? (
      <BetaAccessMessage />
    ) : formState === "signUpSuccess" ? (
      <SignUpSuccessMessage email={email} />
    ) : formState === "referral" ? (
      <ReferralMessage />
    ) : (
      <SignUpHeader />
    )}
  </>
);

export default ConditionalSignupMessage;
