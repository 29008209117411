import React, { useState } from "react";

//componetns
import { Grid, Checkbox } from "@mui/material";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";
import { withStyles } from "@mui/styles";
import { ArrowForward } from "@mui/icons-material";
//styling
import styles from "../EmailTriggerStyles";

//check key util
import checkKey from "../../../utils/CheckKey";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";
const Placeholder = (props) => {
  const [check, setCheck] = useState(false);
  const [checkError, setCheckError] = useState("");
  const [placeholderKey, setPlaceholderKey] = useState(props.placeholderKey);
  return (
    <Grid
      container
      item
      xs={12}
      direction={"row"}
      alignItems={"center"}
      className={props.classes.indent}
    >
      <Grid xs={1} container item>
        <Checkbox
          checked={props.selected}
          onChange={(event) => {
            props.handleSelected(event, props.selected, props.id);
          }}
        ></Checkbox>
      </Grid>
      <Grid xs={4}>
        <p>{props.name}</p>
      </Grid>
      <Grid xs={1}>
        <ArrowForward fontSize={"small"}></ArrowForward>
      </Grid>
      <Grid xs={6}>
        <W86PlaceholderTextField
          value={placeholderKey}
          error={check ? true : false}
          helperText={check ? checkError : null}
          placeholderText="Enter placeholder for update database"
          onBlur={(event)=>{
            props.handleKeyChange(event, placeholderKey, props.id);
          }}

          handleChange={(event) => {
            let checkResult = checkKey(replacePlaceholderValue(event.target.value));

            setCheck(checkResult[0]);
            setCheckError(checkResult[1]);
            
            setPlaceholderKey(replacePlaceholderValue(event.target.value))  
          }}
        />
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(Placeholder);
