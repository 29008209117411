export const compareJsonStyle = {
    variables: {
        light: {
            diffViewerBackground: "#fff",
            diffViewerColor: "#212529",
            addedBackground: "#e6ffed",
            addedColor: "#24292e",
            removedBackground: "#ffeef0",
            removedColor: "#24292e",
            wordAddedBackground: "#acf2bd",
            wordRemovedBackground: "#fdb8c0",
            addedGutterBackground: "#cdffd8",
            removedGutterBackground: "#ffdce0",
            gutterBackground: "#fff",
            gutterBackgroundDark: "#f3f1f1",
            highlightBackground: "#fffbdd",
            highlightGutterBackground: "#fff5b1",
            codeFoldGutterBackground: "#dbedff",
            codeFoldBackground: "#fff",
            emptyLineBackground: "#fff",
            gutterColor: "#212529",
            addedGutterColor: "#212529",
            removedGutterColor: "#212529",
            codeFoldContentColor: "#212529",
            diffViewerTitleBackground: "#fafbfc",
            diffViewerTitleColor: "#212529",
            diffViewerTitleBorderColor: "#eee",
        },
    },
    codeFoldGutter: {
        background: "#FFF",
    },
    diffContainer: {
        border: "1px solid rgba(218, 218, 218, 1)",
        borderRadius: "10px",
        display: "block",
        padding: "24px 8px",
        height: '100%',
        overflow: 'auto',
        backgroundColor: '#fff',
        maxWidth: '100%',
        boxSizing: 'border-box',
    },
};