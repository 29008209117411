import React from "react";
import PropTypes from "prop-types";
import DropZone from "vardogyir-ui/DropZone";
import { withStyles } from "@mui/styles";
import Dropzone from "react-dropzone";

const styles = theme => ({
  checkbox: {
    color: "#afafaf",
    "&$checked": {
      color: "#2196F3"
    }
  },
  listtext: {},
  checked: {},
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  }
});

class UploadMedia extends React.Component {
  constructor(props) {
    super(props);

    this.state = { upload_media: "" };
  }

  render() {
    const { classes } = this.props;

    let accept_list = "";
    // this.props.data.style.split(',').map(n=>{
    //     accept_list = accept_list+ `.${n},`
    // })
    try {
      return (
        <div className={classes.root}>
          <Dropzone
            style={{ width: "100%" }}
            accept={accept_list}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} style={{ width: "100%" }}>
                  <DropZone />

                  <input type="file" {...getInputProps()} />
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

UploadMedia.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UploadMedia);
