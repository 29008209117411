import React, { Component } from "react";
import { Grid, TextField, Switch } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CustomizeResponse from "./CustomizeResponse";
import WebhookImportStore from "../WebhookImportStore";
class SetEndpointUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prodCustomResponse:
        WebhookImportStore.data.prod && WebhookImportStore.data.prod.response
          ? WebhookImportStore.data.prod.response
          : false,
      prodCustomStatus:
        WebhookImportStore.data.prod && WebhookImportStore.data.prod.status
          ? WebhookImportStore.data.prod.status
          : 200,
      prodCustomDataType:
        WebhookImportStore.data.prod && WebhookImportStore.data.prod.dataType
          ? WebhookImportStore.data.prod.dataType
          : "JSON",
      prodCustomHeaders:
        WebhookImportStore.data.prod && WebhookImportStore.data.prod.headers
          ? WebhookImportStore.data.prod.headers
          : [{ key: "", value: "" }],
      prodCustomBody:
        WebhookImportStore.data.prod && WebhookImportStore.data.prod.body
          ? WebhookImportStore.data.prod.body
          : '{"key": "value"}',

    };
  }

  handleCustomResponseSwitch = () => {
    WebhookImportStore.data.prod = {
      response : !this.state.prodCustomResponse
    }
    this.setState({
      prodCustomResponse: !this.state.prodCustomResponse,
    });
  };

  render() {
    return (
      <Grid item xs={12} className="textAlignLeft">
        <Grid container display={"flex"} alignItems={"center"}>
          <Grid item xs={12}>
            <span>
              Return to the source of your webhook and switch the Sample
              Endpoint URL with the Production Endpoint URL below.
            </span>
          </Grid>
          <Grid item xs={12} marginTop={this.props.isExp ? 0 : "24px"}>
            <TextField
              value={
                "https://webhook.workflow86.com/webhook/" +
                this.props.component_id
              }
              className={`key_padding ${this.props.isExp &&
                "textFieldWebhook"}`}
              variant="outlined"
              disabled={true}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={
                        "https://webhook.workflow86.com/webhook/" +
                        this.props.component_id
                      }
                    >
                      <FileCopyIcon className="custom-icon-btn" />
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid container item direction={"row"} alignItems={"center"}>
            <Switch
              checked={this.state.prodCustomResponse}
              onChange={() => {this.handleCustomResponseSwitch()}}
            ></Switch>
            <p className={"bold"}>Customize response (optional)</p>
          </Grid>
          {this.state.prodCustomResponse && (
            <CustomizeResponse
              editStatus={(val) => {
                this.setState({ prodCustomStatus: val });
              }}
              editBody={(val) => {
                this.setState({ prodCustomBody: val });
              }}
              editDataType={(val) => {
                this.setState({ prodCustomDataType: val });
              }}
              status={this.state.prodCustomStatus}
              dataType={this.state.prodCustomDataType}
              headers={this.state.prodCustomHeaders}
              body={this.state.prodCustomBody}
              addHeader={() => {
                this.state.prodCustomHeaders.push({
                  key: "key",
                  value: "value",
                });
                this.setState({
                  prodCustomHeaders: this.state.prodCustomHeaders,
                });
              }}
              editHeader={(value, index, type) => {
                this.state.prodCustomHeaders[index][type] = value;
                this.setState({
                  prodCustomHeaders: this.state.prodCustomHeaders,
                });
              }}
              deleteHeader={(index) => {
                this.state.prodCustomHeaders.splice(index, 1);
                this.setState({
                  prodCustomHeaders: this.state.prodCustomHeaders,
                });
              }}
            />
          )}

          <Grid item xs={12} className="dialogContentLeft">
            <Grid item xs={12}>
              <Button
                onClick={() => this.props.onChangeState(3, 3)}
                className="btnSpace"
                variant={"outlined"}
                color={"info"}
              >
                Back
              </Button>
              <Button
                onClick={() => this.props.onChangeState(5, 5)}
                // disabled={slackStore.data.endpoint_url ? false : true}
                variant={"contained"}
                color={"primary"}
              >
                {" "}
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SetEndpointUrl;
