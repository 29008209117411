import { PlayArrow, SaveAs } from '@mui/icons-material'
import { Button, CircularProgress, Paper, Skeleton } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { print_date_only, print_time_only } from '../../../utils/Date'
import { send_request } from '../../../utils/Request'
import PlaceholderOutputTable from './PlaceholderOutputTable'

const PaperContainer = styled(Paper)({
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
})

const ButtonGroup = styled("div")({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
})

const ComponentInfoGroup = styled("div")({
    display: "flex",
    flexDirection: "row"
})

const ContentGroup = styled("div")({
    width: "100%",
    display: "flex"
})

const Icon = styled("img")({
    width: "40px",
    height: "40px",
    marginRight: "1rem"
})

const ComponentName = styled("p")({
    fontWeight: "bold",
    margin: "0px"
})

const ComponentType = styled("p")({
  margin: "0px",
  color: "#7E7E7E"
})

const RunButtonGroup = styled("div")({
  display: "flex",
  width: "100%",
  marginTop: "1rem",
  justifyContent: "flex-start",
  flexDirection: "column"
})

const RunButton = styled(Button)({
  backgroundColor: "#43A047",
  color: "white",
  border: "none",
  width: "90px"
})

const RunningGroup = styled("div")({
  display: "flex",
  alignItems: "center"
})

const RunningText = styled("p")({
  color: "#5F5F5F",
  marginLeft: "1rem"
})

const parseComponentTypeToFormat = (componentType) => {
  const replacedUnderscore = componentType.replace(/_/g, ' ');
  return replacedUnderscore.charAt(0).toUpperCase() + replacedUnderscore.slice(1);
}
/**
 * @returns Skeleton used for non form triggers which have dateTime, Text, Number and List
 * fields.
 */
const NonFormTriggerSkeleton = ({
  componentType = "form",
  componentName = "Untitled",
  iconName = null,
  componentId = null,
  projectId = null,
  projectVersion = null,
  testId = null,
  testValues = []
}) => {

  const [placeholders, setPlaceholders] = useState([]);
  const [placeholderValues, setPlaceholderValues] = useState({});
  const [placeholderValuesLoaded, setPlaceholderValuesLoaded] = useState(false);
  const [runTest, setRunTest] = useState(false);
  const [savingTestValues, setSavingTestValues] = useState(false);

  useEffect(async() => {
    try {
      
      /**
       * We change the component type for components because their component types
       * do not much the names of their controllers.
       */
      if (componentType === "trigger_from_a_workflow") {
        componentType = "triggerFromAWorkflow";
      }

      if (componentType === "schedule_start") {
        componentType = "schedule-start"
      }

      const res = await send_request(
      `component-service/${componentType}/placeholder/${componentId}`,
      "",
      null,
      );

      const { placeholders } = res.data;
      setPlaceholders(placeholders);

      const newPlaceholderValues = {};
      placeholders.forEach(({ key, type }) => {
        if (type === "list") {
          newPlaceholderValues[key] = [];
        } else {
          newPlaceholderValues[key] = null;
        }
        
      })

      testValues.forEach(({ value, key }) => {
        if ((value !== undefined || value !== null) && newPlaceholderValues[key] !==undefined ) {
          newPlaceholderValues[key] = value;
        }
      })

      setPlaceholderValues(newPlaceholderValues);
    } catch (error) {
      setPlaceholders([]);
    } finally {
      setPlaceholderValuesLoaded(true);
    }

  }, []);

  const handlePlaceholderValueChange = (key, value) => {
    const newPlaceholderValues = {...placeholderValues };
    newPlaceholderValues[key] = value;
    setPlaceholderValues(newPlaceholderValues);
  }

  const handleRunButtonClick = async () => {
    setRunTest(true);
    let data = placeholderValues;
    let status = null;
    let startAt = null;
    let controllerType = componentType;

    if (componentType === "trigger_from_a_workflow") {
      data = {};
      controllerType = "triggerFromAWorkflow"
      const url = `project-service/project/component/${componentId}`;
      try {
        /**
         * We retrieve the black component's information to retrieve the white
         * component's information. We can then get the placeholders needed
         * and substitue the values we want.
         */
        const res = await send_request(
          url,
          null,
          null,
          "GET"
        );
        
        const { sourceComponentId } = res.data.componentData.data;
        const sourceUrl = `project-service/project/component/${sourceComponentId}`;

        try {
          const sourceRes = await send_request(
            sourceUrl,
            null,
            null,
            "GET"
          );

          let { savedPlaceholders } = sourceRes.data.componentData.data;

          const newSavedPlaceholders = savedPlaceholders.map((placeholder) => {

            let value = placeholderValues[placeholder.key];
            if (placeholder.type === "list") {
              value = `[${value.toString()}]`;
            }

            return {
              ...placeholder,
              value
            }
          })

          data = {
            targetComponentId: componentId,
            savedPlaceholders: newSavedPlaceholders
          }
          startAt = new Date();
          status = "INPROGRESS";
        } catch (error) {
          console.log(error);
        }

      } catch(error) {
        console.log(error);
      }
    }
    
    if (componentType === "schedule_start") {   
      /**
       * We have to generate schedule start data the same way a project session is run. 
       * Keep in mind we are not running it, just generating data.
       */
      const dtoUrl = `project-service/test/generate_component_to_schedule_dto/${projectId}/${projectVersion}/${componentId}`;
      try {
        const dtoRes = await send_request(
          dtoUrl,
          null,
          null,
          "GET"
        )

        const dto = dtoRes.data;

        const placeholderValue = placeholderValues[placeholders[0].key];
        const d = new Date(placeholderValue);
        const date = print_date_only(d);
        const time = print_time_only(d);
       
        dto.data.date = date;
        dto.data.time = time;

        dto.projectTestId = testId;

        await send_request(
          `component-service/schedule-start/execute_test`,
          {
            ...dto,
            projectSessionMode: "TEST"
          },
          null,
          "POST"
        );

      } catch(error) {
        console.log(error);
      } finally {
        setRunTest(false);
        return;
      }
    }

    
    // Data needed to execute the project session
    const projectSessionExecute = {
      componentId,
      componentType,
      projectId,
      projectVersion,
      data,
      projectSessionMode: "TEST",
      startAt,
      status,
      projectTestId: testId
    }

    try {
      await send_request(
        `component-service/${controllerType}/execute_test`,
        projectSessionExecute,
        null,
        "POST"
      );
    } catch (error) {
      alert(error);
    } finally {
      setRunTest(false);
    }
  }

  const handleSaveTestValueClick = async () => {
    let saveTestValueDtos = [];
    setSavingTestValues(true);
    placeholders.forEach(({ key, type }) => {
      const saveTestValueDto = { key, type };
      const placeholderValue = placeholderValues[key];

      if (placeholderValue !== undefined) {
        saveTestValueDto.value = placeholderValues[key];
      }
      saveTestValueDtos.push(saveTestValueDto);
    })

    try {
      await send_request(`project-service/test/save_test_values/${testId}/${componentId}`, saveTestValueDtos, null, "PUT");
    } catch (error) {
      alert(error);
    } finally {
      setSavingTestValues(false);
    }

  }

  return (
    !placeholderValuesLoaded ?
    <PaperContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Skeleton
          variant="rectangular"
          width={150}
          height={35}
          style={{
            marginBottom: "1rem"
          }}
        />
      </div>
      <Skeleton
        variant="rectangular"
        width={150}
        height={35}
        style={{
          marginBottom: "1rem"
        }}
      />
      <Skeleton
        variant="rectangular"
        height={35}
        style={{
          marginBottom: "1rem"
        }}
      />
      <Skeleton
        variant="rectangular"
        height={35}
        style={{
          marginBottom: "1rem"
        }}
      />
      <Skeleton
        variant="rectangular"
        height={35}
        width={150}
        style={{
          marginBottom: "1rem"
        }}
      />
    </PaperContainer>
    :
    <PaperContainer>
        <ButtonGroup>
          {
            savingTestValues 
            ?
            <CircularProgress size={32} />
            :
            <Button variant="outlined" onClick={handleSaveTestValueClick} startIcon={<SaveAs />}>
              Save Test Values
            </Button>

          }
        </ButtonGroup>
        <ComponentInfoGroup>
          <Icon src={`/svgs/${iconName ? iconName : componentType}.svg`} />
          <div>
            <ComponentName>{componentName}</ComponentName>
            <ComponentType>{parseComponentTypeToFormat(componentType)}</ComponentType>
          </div>
        </ComponentInfoGroup>
        <ContentGroup>
          {
            placeholderValuesLoaded &&
            <PlaceholderOutputTable 
              placeholders={placeholders}
              placeholderValues={placeholderValues}
              handlePlaceholderValueChange={handlePlaceholderValueChange}
            />
          }
        </ContentGroup>
        <RunButtonGroup>
          <RunButton variant="outlined" disabled={runTest} startIcon={<PlayArrow/>} onClick={handleRunButtonClick}>
            Run
          </RunButton>
          {
            runTest &&
            <RunningGroup>
              <CircularProgress size={32} />
              <RunningText>Running...</RunningText>
            </RunningGroup>
          }
        </RunButtonGroup>
    </PaperContainer>
  )
}


export default NonFormTriggerSkeleton