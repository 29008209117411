import React from "react";

// MUI
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

// Icons
import TextIcon from "../../../../Component/Icons/TextIcon";
import NumberIcon from "../../../../Component/Icons/NumberIcon";
import DatetimeIcon from "../../../../Component/Icons/DatetimeIcon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

// Custom Components
import DropdownOption from "../TableInput/DropdownOption";
import ColumnNameInput from "../TableInput/ColumnNameInput";
import DateTimeRegion from "../TableInput/DateTimeRegion";
import FormMentionEditor from "../../../../Component/FormMentionEditor";
import QuestionInput from "./QuestionInput";
import DescriptionInput from "./DescriptionInput";

function RenderColumnRS(props) {
    const { classes, availablePlaceholders } = props;

    const renderDropdownOptions = (col) => {
        return col.options.map((opt) => {
            return (
                <DropdownOption
                    key={opt.id}
                    id={opt.id}
                    classes={classes}
                    col={col}
                    opt={opt}
                    handleChangeOptionValue={props.handleChangeOptionValue}
                    handleAddOrRemoveOption={props.handleAddOrRemoveOption}
                />
            );
        });
    };

    return (
        <Grid item xs={12} key={props.col.id} id={props.col.id}>
            <Grid item xs={12} style={{ maxWidth: "200px" }}>
                <Select
                    onChange={(e) => props.handleChangeColumnValue(props.col.id, e.target.value, "type")}
                    className={classes.selectList}
                    variant={"filled"}
                    disableUnderline
                    value={props.col.type}
                    size="small"
                >
                    <MenuItem value={"datetime"}>
                        <div className={classes.inputWithIcon}>
                            <DatetimeIcon className={classes.textIcon} />
                            Datetime
                        </div>
                    </MenuItem>
                    <MenuItem value={"dropdown"}>
                        <div className={classes.inputWithIcon}>
                            <img src="/form_icons/10.svg" className={classes.dropdownIcon} />
                            Dropdown
                        </div>
                    </MenuItem>
                    <MenuItem value={"number"}>
                        <div className={classes.inputWithIcon}>
                            <NumberIcon className={classes.textIcon} />
                            Number
                        </div>
                    </MenuItem>
                    <MenuItem value={"text"}>
                        <div className={classes.inputWithIcon}>
                            <TextIcon className={classes.textIcon} />
                            Text
                        </div>
                    </MenuItem>
                </Select>
            </Grid>

            <Grid item xs={12}>
                {availablePlaceholders ? (
                    <div className="mention-editor-container">
                        <span className="mention-editor-label">Key</span>
                        <FormMentionEditor
                            style={{
                                width: "100%",
                                marginBottom: 8,
                                verticalAlign: "top",
                                minHeight: "16px",
                            }}
                            aiAssistant={true}
                            assignTask={true}
                            contentBlock={false}
                            hideScrollBar={true}
                            text={props && props.col ? props.col.columnName : ""}
                            onFocus={(e) => {}}
                            placeholder={"Enter field name"}
                            onChange={(textValue) => {
                                props.handleChangeColumnValue(props.col.id, textValue, "columnName");
                            }}
                            {...(availablePlaceholders != null && {
                                availablePlaceholders: availablePlaceholders,
                            })}
                        />
                    </div>
                ) : (
                    <ColumnNameInput {...props} isRS />
                )}
            </Grid>

            <Grid item xs={12}>
                <QuestionInput {...props} />
            </Grid>

            <Grid item xs={12}>
                <DescriptionInput {...props} />
            </Grid>

            {props.col.type === "dropdown" && (
                <>
                    {renderDropdownOptions(props.col)}
                    <Grid item xs={12}>
                        <span className={classes.addOptionText} onClick={() => props.handleAddOrRemoveOption(props.col.id, null, true)}>
                            + add option
                        </span>
                    </Grid>
                </>
            )}

            {props.col.type === "datetime" && <DateTimeRegion col={props.col} classes={classes} handleChangeDtFormat={props.handleChangeDtFormat} />}

            <Grid item xs={12} className={classes.requiredGridItem}>
                <Checkbox className={classes.requiredCheckbox} checked={props.col.required} onClick={() => props.handleChangeRequired(props.col.id)} />
                <span className={classes.requiredText}>Required</span>
            </Grid>
            <Grid item xs={12}>
                <AddCircleOutlineIcon className={classes.addIcon} onClick={() => props.handleAddOrRemoveColumn(props.col.id, true)} />
                <CancelOutlinedIcon className={classes.removeIcon} onClick={() => props.handleAddOrRemoveColumn(props.col.id, false)} />
            </Grid>
        </Grid>
    );
}

export default RenderColumnRS;
