import { DragSource, DropTarget } from "react-dnd";
import "./Cards.css";
import React from "react";
import flow from "lodash/flow";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Map from "./FormComponents/Map";
import Rawtext from "./FormComponents/Rawtext";
import Freetext from "./FormComponents/Freetext";
import Divider from "@mui/material/Divider";
import { createTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import Calendar from "./FormComponents/Calendar";
import ListInput from "./FormComponents/ListInput";
import SingleLine from "./FormComponents/SingleLine";
import NumberInput from "./FormComponents/NumberInput";
import VideoEmbed from "./FormComponents/VideoEmbed";
import UploadMedia from "./FormComponents/UploadMedia";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SingleSelect from "./FormComponents/SingleSelect";
import Singlechoice from "./FormComponents/Singlechoice";
import Multiplechoice from "./FormComponents/Multiplechoice";
import SequentialFormContainer from "./SequentialFormContainer";
import { createDragPreview } from "react-dnd-text-dragpreview";
import { Menu, IconButton, Tooltip } from "@mui/material";
import RibbonItem from "vardogyir-ui/RibbonItem";
import TextField from "vardogyir-ui/TextField";
import { TextField as MaterialUITextfield } from "@mui/material";
import Tooltips from "vardogyir-ui/Tooltips";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SendIcon from "@mui/icons-material/Send";
import ESignature from "./FormComponents/ESignature";
import Table from "../FormBuilder/FormComponents/Table/Table";
import FilePreview from "../FormBuilder/FormComponents/FilePreview";
import FormBuilderContainer from "../FormBuilder/FormBuilderContainer";
import RichTextEditor from "../FormBuilder/FormComponents/RichTextEditor";
import DisplayDatabaseContent from "../FormBuilder/FormComponents/DatabaseContent/DisplayDatabaseContent";
import RepeatableSection from "../FormBuilder/FormComponents/Table/RepeatableSection";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {styled} from "@mui/system";
import Grid from "@mui/material/Grid";
import StyledWarningGrid from "../../utils/StyledWarningGrid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


var dragPreviewStyle = {
  backgroundColor: "rgb(68, 67, 67)",
  borderColor: "#F96816",
  color: "white",
  fontSize: 15,
  paddingTop: 4,
  paddingRight: 7,
  paddingBottom: 6,
  paddingLeft: 7,
};
const style = {
  margin: "10px",
  borderRadius: "5px",
  padding: "16px",
  textAlign: "center",
  cursor: "move",
  display: "block",
};
const styles = (theme) => ({
  textroot: {
    width: "100%",
    height: "100px",
    margin: "auto",
  },
  pagetitle: {
    fontWeight: 300,
    fontSize: "40px",
  },
  pageDescription: {
    fontSize: "16px",
  },
  textField: { width: 600, margin: "auto", position: "relative" },
});
const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};
var moved = SequentialFormContainer.getMovedQuestions();
const cardTarget = {
  hover(props, monitor, component) {
    if (monitor.getItem().index) {
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = component.node.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    } else if (monitor.getItem().index == undefined) {
      if (monitor.isOver() && monitor.canDrop() && component.node) {
        if (component.props.index == SequentialFormContainer.pages.length - 1)
          component.node.style.paddingBottom = "100px";
        else component.node.style.paddingTop = "100px";
        if (!moved.includes(component)) {
          moved.push(component);
        }
      }
    }

    if (moved.length >= 2) {
      for (var i = 0; i < moved.length - 1; i++) {
        if (moved[i].node) {
          moved[i].node.style.paddingTop = "0px";
          moved[i].node.style.paddingBottom = "0px";
        }
      }
      moved.length = 0;
      SequentialFormContainer.emptyMovedQuestopns();
    }
  },
  drop(props, monitor, component) {
    if (
      props.index >= 0 &&
      !monitor.getItem().index &&
      monitor.getItem().index !== 0 &&
      component.decoratedRef.current.node
    ) {
      SequentialFormContainer.setPosition(props.index);
      component.decoratedRef.current.node.style.paddingTop = "0px";
      component.decoratedRef.current.node.style.paddingBottom = "0px";
      moved.length = 0;
      SequentialFormContainer.emptyMovedQuestopns();
    }
  },
};

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      anchorEl: null,
    };
  }
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    text: PropTypes.string.isRequired,
    moveCard: PropTypes.func.isRequired,
  };
  formatDragMessage = (text) => {
    return `Moving ${text} Component`;
  };
  componentDidMount() {
    // handles first time dragPreview setup
    this.dragPreview = createDragPreview(
      this.formatDragMessage(this.props.text),
      dragPreviewStyle
    );
    this.props.connectDragPreview(this.dragPreview);
  }
  renderView = (type) => {
    let notDbAnsw = true;

    const data = SequentialFormContainer.getData(this.props.id).config;
    if (
      data.databaseId != null &&
      data.columnId != null &&
      data.databaseId != "" &&
      (data.columnId != "" || data.labelColumnId != "")
    )
      notDbAnsw = false;
    let answer_list = [["", "Get answers from database"]];
    let object = {};
    object.answer_list = answer_list;
    const generatePreviewComponent = () => {
      switch (type) {
        case 1:
          return <NumberInput />;
        case 2:
          return <Freetext />;
        case 3:
          return <Map />;
        case 4:
          return (
            <Singlechoice
              data={
                notDbAnsw
                  ? SequentialFormContainer.getData(this.props.id).config
                  : object
              }
            />
          );
        case 5:
          return (
            <Multiplechoice
              data={
                notDbAnsw
                  ? SequentialFormContainer.getData(this.props.id).config
                  : object
              }
            />
          );
        case 6:
          return <UploadMedia />;
        case 7:
          return <Calendar preview={true} />;
        case 8:
          return <Divider />;
        case 9:
          return <SingleLine />;
        case 10:
          return (
            <SingleSelect
              data={
                notDbAnsw
                  ? SequentialFormContainer.getData(this.props.id).config
                  : object
              }
            />
          );
        case 11:
          return (
            <Rawtext
              data={SequentialFormContainer.getData(this.props.id).config}
            />
          );
        case 12:
          return (
            <ListInput
              data={SequentialFormContainer.getData(this.props.id).config}
            />
          );
        case 13:
          return <ESignature />;
        case 14:
          return (
            <FilePreview
              data={SequentialFormContainer.getData(this.props.id).config}
            />
          );
        case 15:
          return (
            <Table
              preview={true}
              data={SequentialFormContainer.getData(this.props.id).config}
            />
          );
        case 16:
          return (
            <VideoEmbed
              data={SequentialFormContainer.getData(this.props.id).config}
            />
          );
        case 17:
          return <RichTextEditor
              data={SequentialFormContainer.getData(this.props.id).config}/>;
        case 18:
          return (
            <DisplayDatabaseContent isPreview data={notDbAnsw ? SequentialFormContainer.getData(this.props.id).config : object} />
          );
        case 19:
          return <RepeatableSection preview={true} data={SequentialFormContainer.getData(this.props.id).config} />;
        default:
          return <div>Error</div>;
      }
    };
    return (
      <>
        {generatePreviewComponent()}
        {
            data.required && (
                <StyledWarningGrid >
                  <img src={"/svgs/asteriskIcon.svg"}/>
                  <span >Required</span>
                </StyledWarningGrid>
            )
        }
        {
          <StyledWarningGrid >
            {data.visibility? <VisibilityOutlined />:<VisibilityOffOutlined/>}
            <span>Visibility { data.visibility ? typeof data.visibility === 'object' ? "CONDITIONAL" : "ON" : "OFF" }</span>
          </StyledWarningGrid>
        }
        {
            data.required && !data.visibility && (
                <StyledWarningGrid color="#E10000" >
                  <ErrorOutlineIcon />
                  <span>The settings for this question will prevent the form from being submitted: change visibility to ON or turn OFF question required</span>
                </StyledWarningGrid>
            )
        }
        {data.comment && (
          <>
            <p>Comments</p>
            <MaterialUITextfield
              multiline
              variant="outlined"
              style={{ width: "100%" }}
              placeholder="Type a comment"
              InputProps={{
                endAdornment: <SendIcon />,
              }}
            />
          </>
        )}
      </>
    );
  };

  render() {
    const theme = createTheme({
      palette: {
        primary: { 500: "#2196F3" },
      },
      typography: { useNextVariants: true },
    });
    const { classes } = this.props;
    const {
      text,
      isDragging,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
    } = this.props;
    const opacity = isDragging ? 0 : 1;
    let data = SequentialFormContainer.getData(this.props.id);
    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div ref={(node) => (this.node = node)}>
            <div>
              {this.props.type == 8 ? (
                <div style={{ cursor: "move", position: "relative" }}>
                  <div style={{ height: "20px" }}></div>
                  <div
                    style={{
                      height: "24px",
                      background: "rgba(122, 121, 121, 0.05)",
                      left: "-500px",
                      position: "absolute",
                      width: "calc(100% + 1000px)",
                      borderTop: "2px dashed #9E9E9E",
                      borderBottom: "2px dashed #9E9E9E",
                    }}
                  ></div>
                  <div style={{ height: "20px" }}></div>
                  <div
                    style={{ width: "100%", margin: "auto", textAlign: "left" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ flexGrow: 1 }} />
                      <IconButton
                        onClick={(event) =>
                          this.setState({
                            menu: true,
                            anchorEl: event.currentTarget,
                          })
                        }
                        className={"icon-btn"}
                        size="large"
                      >
                        <MoreHorizIcon className={"material-icons more-vert"} />
                      </IconButton>
                      {this.state.menu && (
                        <Menu
                          onClose={(event) => {
                            {
                              this.setState({
                                menu: false,
                              });
                            }
                          }}
                          open={this.state.menu}
                          anchorEl={this.state.anchorEl}
                        >
                          <RibbonItem
                            onClick={() => {
                              SequentialFormContainer.deletePageKeepQuestions(
                                this.props.id
                              );
                            }}
                          >
                            Delete page and keep the questions
                          </RibbonItem>
                          <RibbonItem
                            onClick={() => {
                              SequentialFormContainer.clearPage(this.props.id);
                            }}
                          >
                            Delete page and questions
                          </RibbonItem>
                        </Menu>
                      )}
                    </div>
                    <div className="cardPageTitle">
                      <TextField
                        fontSize={18}
                        Theme={"blue"}
                        value={data.config.page_title}
                        label={"Page Title"}
                        onChange={(event) => {
                          SequentialFormContainer.editPagedata(
                            this.props.id,
                            event.target.value,
                            "page_title"
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={"drag-item"} style={{ position: "relative" }}>
                  <div
                    className={"form-component-wrapper cardStyleForm"}
                    style={{ opacity }}
                  >
                    <div className="toolbar-header-buttons">
                      <Tooltip title="Edit" arrow placement="top">
                        <IconButton
                          onClick={() => this.props.editItem(this.props.id)}
                          size="small"
                          className="edit-button"
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Duplicate" arrow placement="top">
                        <IconButton
                          onClick={() => this.props.copyItem(this.props.id)}
                          size="small"
                          className="duplicate-button"
                        >
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton
                          onClick={() => this.props.deleteItem(this.props.id)}
                          size="small"
                          className="delete-button"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="form-component-view formConponentViewForm">
                      <p className="question-text">
                        {data.config.question_text_default}
                      </p>
                      <p className="question-description">
                        {data.config.question_description_default}
                      </p>
                      {this.renderView(this.props.type)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      )
    );
  }
}

export default flow(
  DragSource("card", cardSource, (connect, monitor) => ({
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget(["card", "item"], cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(withStyles(styles)(observer(Card)));
