import React from "react";
import { styled } from "@mui/system";
import { Tooltip, Grid } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard/src";

const Bubble = styled("div")(({ isSelected }) => ({
    minHeight: "50px",
    padding: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "background-color 0.2s ease",
    backgroundColor: isSelected ? "#E9F5FE" : "transparent",
    borderRadius: isSelected ? "12px 12px 12px 0px" : 0,
    "&:hover": {
        backgroundColor: !isSelected && "#F5F5F5",
        borderRadius: !isSelected && "12px 12px 12px 0px",
        cursor: !isSelected && "pointer",
    },
}));

const ChatTitle = styled("span")({
    color: "#868686",
    fontSize: "14px",
    lineHeight: "24px",
});

const IconContainer = styled("div")({
    display: "flex",
    alignItems: "center",
});

const ChatInfoContainer = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
});

function ChatHistoryBubble({ event, isSelected }) {
    const grabLastUserMessage = (event) => event.chatTitle || event.sessionId;

    return (
        <CopyToClipboard text={event.sessionId}>
            <Tooltip
                title={!isSelected ? grabLastUserMessage(event) : null}
                placement="right-end"
            >
                <Bubble isSelected={isSelected}>
                    <ChatInfoContainer>
                        <ChatTitle>{event.sessionId}</ChatTitle>
                        <ChatTitle>{grabLastUserMessage(event)}</ChatTitle>
                    </ChatInfoContainer>
                    <IconContainer>
                        <ContentCopy />
                    </IconContainer>
                </Bubble>
            </Tooltip>
        </CopyToClipboard>
    );
}

export default ChatHistoryBubble;
