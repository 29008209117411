import React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

// Updated function to generate light pastel colors
const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 80%)`;  // Increased lightness for pastel effect
};

function JsonChips({ data }) {
    const renderKeyValuePairs = (data, prefix = []) => {
        return Object.entries(data).flatMap(([key, value]) => {
            const fullKey = [...prefix, key];
            if (typeof value === 'object' && value !== null) {
                return renderKeyValuePairs(value, fullKey);
            }
            return (
                <Grid item xs={12} key={fullKey.join('.')} className="json-chip-container">
                    {fullKey.map((keyPart, index) => (
                        <Chip
                            key={index}
                            label={keyPart}
                            className="json-chip"
                            style={{ backgroundColor: stringToColor(keyPart) }}
                        />
                    ))}
                    {': '}
                    {JSON.stringify(value)}
                </Grid>
            );
        });
    };

    return (
        <Grid container>
            {renderKeyValuePairs(data)}
        </Grid>
    );
}

export default JsonChips;
