import React, { useState, useContext, useEffect } from "react";

// MUI
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { inject, observer } from "mobx-react";

// Util
import ProjectStore from "../../ProjectCanvas/ProjectStore";
import { useLocation } from "react-router-dom";
import { CanvasContext } from "./WorkflowCanvas";
import ComponentTray from "./SidebarItems/ComponentTray";

//css
import "./Canvas.css";
// import { ReactComponent as AIStars } from "/svgs/ai-stars.svg"
import NotesTray from "./SidebarItems/NotesTray";
import AIDisplayHelper from "./AIDisplayHelper";
import { isAIEnabled } from "../../../utils/AIUtil";
import {
  AICanvasButton,
  ComponentMenuCanvasButton,
  NoteMenuCanvasButton,
} from "../../ProjectCanvas/Canvas/CanvasButtonsUtil/CanvasStyledComponents";

const styles = {
  buttons: {
    position: "absolute",
    float: "right",
    top: "40vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    right: "16px",
  },
  trayButton: {
    position: "absolute",
    float: "right",
    top: "50vh",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    right: "20vw",
    ["@media (max-width:1800px)"]: {
      right: "24vw",
    },
    ["@media (max-width:1600px)"]: {
      right: "27vw",
    },
    ["@media (max-width:1450px)"]: {
      right: "30vw",
    },
    ["@media (max-width:1350px)"]: {
      right: "31vw",
    },
    ["@media (max-width:1250px)"]: {
      right: "33vw",
    },
    ["@media (max-width:1100px)"]: {
      right: "35vw",
    },
    ["@media (max-width:1000px)"]: {
      right: "39vw",
    },
  },
  container: {
    borderRadius: "8px !important",
  },
  title: {
    fontFamily: "Open Sans",
    lineHeight: 1,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    margin: "auto",
  },
  description: {
    paddingTop: 4,
    fontFamily: "Open Sans",
    lineHeight: 1,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    margin: "auto",
    color: "#868686",
  },
  search: {
    background: "#FFF",
  },
  dropdown: {
    background: "rgba(238, 238, 238, 1)",
    maxWidth: "100% !important",
    borderRadius: "8px",
  },
  dropdownGrid: {
    maxWidth: "100% !important",
  },
  left: {
    alignSelf: "flex-start!important",
    marginBottom: "8px !important",
  },
  logo: {
    height: "41px",
  },
  canvasItem: {
    boxShadow: "unset!important",
    border: "1px solid #dadada !important",
    borderRadius: "8px !important",
    background: "#FFF",
    padding: "8px",
    marginBottom: "8px",
    "&:hover": {
      background: "#F8F8F8",
      cursor: "pointer",
    },
  },
};

const Sidebar = inject("CanvasStore")(
  observer((props) => {
    const [showDisplayHelper, setShowDisplayHelper] = useState(false);
    const [showTray, setShowTray] = useState(false);
    const [showNotesTray, setShowNotesTray] = useState(false);

    //use react context to set canvas
    const { isCanvasLoaded } = useContext(CanvasContext);

    const { CanvasStore } = props; // extract canvas store
    const { nodes, edges } = CanvasStore;

    const [canvasLoaded, setCanvasLoaded] = isCanvasLoaded;

    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);

    useEffect(() => {
      if (canvasLoaded) {
        let chatSessionId = queryParams.get("chatSession");
        let chatThreadId = queryParams.get("threadId");
        let startBuild = queryParams.get("startBuild");

        if (queryParams.get("openBuilder")) {
          props.setShowAIBuilder(true);
          props.setAiBuilderOpen(true);
          return;
        }

        // open ai workflow builder if given session id and thread id
        if (chatSessionId && chatThreadId) {
          props.setShowAIBuilder(true);
          props.setAiBuilderOpen(true);

          if (startBuild) {
            props.setSelectedHistorySession({
              sessionId: chatSessionId,
              threadId: chatThreadId,
            });
          }
          return;
        } else {
          const hasNoComponents = nodes.length === 0 && edges.length === 0;
          setShowDisplayHelper(
            hasNoComponents && isAIEnabled(ProjectStore.state.clientId)
          ); // Show if nothing on canvas
          props.setDisplayHelperOpen(
            hasNoComponents && isAIEnabled(ProjectStore.state.clientId)
          );
        }
      }
    }, [canvasLoaded]);

    useEffect(() => {
      if (!canvasLoaded) return;

      // Handle session id changes in the query params
      let chatSessionId = queryParams.get("chatSession");
      let chatThreadId = queryParams.get("threadId");
      let startBuild = queryParams.get("startBuild");

      if (chatSessionId && chatThreadId) {
        props.setShowAIBuilder(true);
        props.setAiBuilderOpen(true);
        if (startBuild) {
          props.setSelectedHistorySession({
            sessionId: chatSessionId,
            threadId: chatThreadId,
          });
        }

        return;
      }
    }, [queryParams.get("chatSession")]);

    const showTourTray = () => {
      //this presses next on react tour
      //the least  convoluted way to do it
      if (document.querySelector(".bOQlEF") != null)
        document.querySelector(".bOQlEF").click();
    };

    // Function for closing the initial ai display helper
    // That displays for a new workflow (no components on the canvas)
    const handleCloseAIDisplayHelper = () => {
      setShowDisplayHelper(false);
      props.setDisplayHelperOpen(false);
    };

    const closeAIMenu = () => {
      props.setShowAIBuilder(true);
      props.setAiBuilderOpen(true);
      setShowTray(false);
    };

    const openComponentMenu = () => {
      setShowTray(true);
      props.setShowAIBuilder(false);
      props.setAiBuilderOpen(false);
      setShowNotesTray(false);
      showTourTray();
    };

    const openNoteMenu = () => {
      setShowNotesTray(true);
      setShowTray(false);
      props.setShowAIBuilder(false);
      props.setAiBuilderOpen(false);
    };

    return (
      <>
        {showDisplayHelper && (
          <AIDisplayHelper
            isOpen={showDisplayHelper}
            handleCloseDisplayHelper={handleCloseAIDisplayHelper}
            handleBuildMyOwn={() => {
              handleCloseAIDisplayHelper();
              setShowTray(true);
            }}
            handleHelpMeBuild={() => {
              handleCloseAIDisplayHelper();
              props.setShowAIBuilder(true);
              props.setAiBuilderOpen(true);
            }}
          />
        )}

        {!showTray &&
          !showNotesTray &&
          !props.showAIBuilder &&
          !showDisplayHelper && (
            <div className={props.classes.buttons}>
              <AICanvasButton
                onClick={closeAIMenu}
                isInAIDraft={props.isInAIDraft}
              />
              <ComponentMenuCanvasButton
                setTour
                onClick={() => {
                  setShowTray(true);
                  showTourTray();
                }}
              />
              <NoteMenuCanvasButton
                setTour
                onClick={() => setShowNotesTray(true)}
              />
            </div>
          )}

        {showNotesTray && !props.showAIBuilder && (
          <Grid container item direction={"row"} alignItems={"flex-end"}>
            <div className={props.classes.trayButton}>
              <AICanvasButton
                onClick={closeAIMenu}
                isInAIDraft={props.isInAIDraft}
              />
              <ComponentMenuCanvasButton onClick={openComponentMenu} />
              <NoteMenuCanvasButton
                showClose
                onClick={() => setShowNotesTray(false)}
              />
            </div>
            <NotesTray classes={props.classes} />
          </Grid>
        )}

        {showTray && !props.showAIBuilder && (
          <Grid container item direction={"row"} alignItems={"flex-end"}>
            <div className={props.classes.trayButton}>
              <AICanvasButton
                onClick={closeAIMenu}
                isInAIDraft={props.isInAIDraft}
              />
              <ComponentMenuCanvasButton
                showClose
                onClick={() => setShowTray(false)}
              />
              <NoteMenuCanvasButton onClick={openNoteMenu} />
            </div>
            <ComponentTray classes={props.classes} />
          </Grid>
        )}
      </>
    );
  })
);

export default withStyles(styles)(Sidebar);
