import React, { useEffect } from "react";

// Util
import AIBuilderStyles from "../../pages/Canvas/AIBuilder/AIBuilderStyles";
import { useAIOnboardingContext } from "./OnboardingContextProvider";

// MUI
import { makeStyles, withStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { send_request } from "../../../utils/Request";
import MessageRendering from "./Steps/MessageRendering";
import ChatActions from "./Steps/ChatActions";
import { useNavigate } from "react-router";
import { updateNextStepInDB } from "./Util/Util";

const styles = AIBuilderStyles;

const useStyles = makeStyles((theme) => ({
    box: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "33%",
        height: "75%",
        [theme.breakpoints.down("xl")]: {
            width: "44%",
            height: "75%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "60%",
            height: "80%",
        },
        [theme.breakpoints.down("md")]: {
            width: "75%",
            height: "85%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "80%",
            height: "90%",
        },
        overflow: "auto",
        padding: "16px",
        background: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    chatHeader: {
        marginTop: "8px",
        marginBottom: "16px !important",
        borderBottom: "1px solid #D9D9D9",
        paddingBottom: "8px",
        textAlign: "center",
    },
    userInfoBox: {
        background: "#FFF",
        border: "1px solid #D9D9D9",
        borderRadius: "16px 16px",
        width: "100%",
        padding: "16px",
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
    },
    inputField: {},
    select: {
        borderRadius: "5px",
        border: "none",
        backgroundColor: "#f8f8f8!important",
        minWidth: "250px",
        width: "250px !important",
        color: "rgba(134, 134, 134, 1)",
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    sendButton: {
        marginLeft: "8px",
        background: "#944BA8",
        "&:active": {
            background: "#7B1FA2",
        },
        "&:hover": { background: "#7B1FA2" },
    },
    styledSkeleton: {
        borderRadius: "8px",
        background: "#E8E8E8",
        width: "100%",
    },
    logo: {
        height: "30px",
        width: "auto"
    }
}));

function OnboardingChat(props) {
    const navigate = useNavigate();

    const {
        loadingBubble,
        setLoadingBubble,
        setLoading,
        setStep,
        setFirstName,
        setSurname,
        setTimezone,
        setAccountName,
        setFindOut,
        setFindOutDetails,
        setCompanySize,
        setWorkflowDescription,
        setRole,
        setTeam,
        setIndustry
    } = useAIOnboardingContext();

    useEffect(async () => {
        setLoading(true);
        setLoadingBubble(true);
        await getUserInfo();

        if (props.onboardingStatus === "DESCRIBE_WORKFLOW" || props.onboardingStatus === "CHOICE_MADE_LOADING") setStep(2);
        else setStep(1); // Default to 1

        if (props.onboardingStatus === "CHOICE_MADE_LOADING") {
            setLoading(true);
            setLoadingBubble(true);

            // We need to return the choice made loading state
            const url = `project-service/project-ai/assistant/retrieve_onboarding_event`;
            const json = await send_request(url, null, null, "GET");

            if (json && json.data) {
                setWorkflowDescription(json.data.content);
                await wait(3000); // Wait 3 seconds and then re-direct
                handleRedirect(json.data.projectId, json.data.sessionId, json.data.threadId); // Handle redirect
            }
        } else setLoading(false);
    }, []);

    const handleRedirect = (projectId, sessionId, threadId) => {
        updateNextStepInDB("REDIRECTED_AND_COMPLETED");
        props.handleStart();
        navigate(`/project/canvas/${projectId}?chatSession=${sessionId}&threadId=${threadId}&startBuild=true`);
    };

    const wait = (ms) =>
        new Promise((res) => {
            let timeout = setTimeout(res, ms);
            return timeout;
        });

    const getUserInfo = async () => {
        const json = await Promise.all([fetchAccountInfo(), fetchExtraQuestions(), fetchExtraUserInfo()]); // Execute logic

        // Extract request responses
        const res1 = json[0];
        const res2 = json[1];
        const res3 = json[2];

        // Set information if returned
        if (res1 && res1.data) {
            // Set information if already exists
            setAccountName(res1.data.client ? res1.data.client.clientName : "");
            setTimezone(res1.data.client ? res1.data.client.timezone : null);
            setFirstName(res1.data.firstName ? res1.data.firstName : "");
            setSurname(res1.data.lastName);
        }

        if (res2 && res2.data) {
            let findOutDetails = "";

            if (res2.data.referral_source) {
                switch (res2.data.referral_source) {
                    case "REFERRED_BY_A_WORKFLOW86_USER":
                        findOutDetails = res2.data.referrer_username;
                        break;
                    case "OTHER":
                        findOutDetails = res2.data.other_detail;
                        break;
                    case "ANOTHER_WEBSITE":
                        findOutDetails = res2.data.other_website;
                        break;
                }

                // Set info
                setFindOut(res2.data.referral_source);
                setFindOutDetails(findOutDetails);
            }

            setCompanySize(res2.data.companySize);
            setIndustry(res2.data.industryList);
        }

        if (res3 && res3.data) {
            setRole(res3.data.role);
            setTeam(res3.data.team);
        }
    };

    const fetchAccountInfo = async () => {
        return await send_request(`authz-service/user-info`, null, null, "GET");
    };

    const fetchExtraQuestions = async () => {
        return await send_request(`authz-service/extra_questions/get_extra_questions`, null, null, "GET");
    };

    const fetchExtraUserInfo = async () => {
        return await send_request(`authz-service/get_user_extra_info`, null, null, "GET");
    }

    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <Grid container rowGap={1} direction="column" style={{ height: "100%" }}>
                <Grid item xs>
                    <Grid container className={props.classes.fullChat} direction="column">
                        <Grid item>
                            <div className={classes.chatHeader}><img src="/svgs/logo.svg" className={classes.logo} alt="logo" /></div>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="column" height={"100%"} style={{ fontSize: "14px" }}>
                                <Grid item xs className={props.classes.chatDiv}>
                                    <MessageRendering classes={classes} aiClasses={props.classes} onboardingStatus={props.onboardingStatus} />
                                </Grid>
                                {!loadingBubble && (
                                    <Grid item style={{ minHeight: "50px", display: "flex", alignItems: "flex-end" }}>
                                        <ChatActions classes={classes} aiClasses={props.classes} handleStart={props.handleStart} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default withStyles(styles)(OnboardingChat);
