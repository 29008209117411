import { Button, Grid } from '@mui/material'
import "../../../../ProjectCanvas/css/share.css"
import LinkIcon from "@mui/icons-material/Link";
import {
  Twitter,
  LinkedIn,
  AlternateEmail,
} from "@mui/icons-material";
import React from 'react'
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  margin: "0px !important",
  width: "22%",
  marginRight: "1rem !important"
})

const ShareButtons = ({
  url
}) => {

  const encoded = encodeURIComponent(url);
  const emailBody =
      "I just built this workflow on Workflow86. You can preview it here:" +
      " " +
      url;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <StyledButton
        className="resp-sharing-button resp-sharing-button--email resp-sharing-button--large"
        onClick={() => {
          navigator.clipboard.writeText(url);
        }}
      >
        <div
          aria-hidden="true"
          className="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <LinkIcon className="share-icon-white" />
          Link
        </div>
      </StyledButton>
      <StyledButton
        style={{ backgroundColor: "#E6E5E3", color: "black" }}
        className="resp-sharing-button resp-sharing-button--large"
        href={`mailto:?subject=${encodeURIComponent(
          "🚩 Check out this automated workflow "
        )}&body=${emailBody}`}
      >
        <div
          aria-hidden="true"
          className="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <AlternateEmail />
          Email
        </div>
      </StyledButton>
      <StyledButton
        target="_blank"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encoded}`}
        className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--large"
      >
        <div
          aria-hidden="true"
          className="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <LinkedIn className="share-icon-white" />
        </div>
        LinkedIn
      </StyledButton>
      <StyledButton
        target="_blank"
        href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(
          "🚩 Check out this automated workflow I built on Workflow86: "
        )}&url=${url}`}
        className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--large"
      >
        <div
          aria-hidden="true"
          className="resp-sharing-button__icon resp-sharing-button__icon--solid"
        >
          <Twitter className="share-icon-white" />
        </div>
        Twitter
      </StyledButton>
    </div>
  )
}

export default ShareButtons