import { action, computed, decorate, observable, toJS } from "mobx";

class EmailTriggerStore {
  constructor() {
    this.initialiseEmpty();
  }

  setEmailTrigger = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.triggerViaEmailPlaceholders();
      this.data.incomingMessagePlaceholders = obj;
      this.data.receiver = "none";
      this.data.receivingEmails = true;
      this.lastModified = "";
      this.name = "";
    }
  };

  initialiseEmpty = () => {
    this.name = "";
    this.componentId = "";
    this.type = "trigger_via_email";
    this.lastModified = "";
    this.placeholders = [];
    this.data = this.initData();
    this.position = null;
    this.focus = null;
  };

  initData = () => {
    return {
      incomingMessagePlaceholders: [],
      receiver: "none",
    };
  };

  setIncomingMessagePlaceholders = (incomingMessagePlaceholders) => {
    this.data.incomingMessagePlaceholders = incomingMessagePlaceholders;
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: this.placeholders,
    };
  }

  triggerViaEmailPlaceholders() {
    return {
      content: [
        {
          key: this.keyAuto("subject"),
          name: "Email subject line",
          id: "subject",
          selected: true,
          type: "text",
          value: "",
        },
        {
          key: this.keyAuto("body"),
          name: "Email body as plain text",
          id: "body",
          selected: true,
          type: "text",
          value: "",
        },
        {
          key: this.keyAuto("body_HTML"),
          name: "Email body as HTML",
          id: "html_body",
          selected: true,
          type: "html",
          value: "",
        },
      ],
      addresses: [
        {
          key: this.keyAuto("sender"),
          name: "Sender email addresses (from)",
          id: "from",
          selected: true,
          type: "text",
          value: "",
        },
        {
          key: this.keyAuto("recipient"),
          name: "Recipient email addresses (To)",
          id: "to",
          selected: true,
          type: "text",
          value: "",
        },
        {
          key: this.keyAuto("Cc"),
          name: "Recipient email addresses (Cc)",
          id: "cc",
          selected: true,
          type: "list",
          value: "",
        },
      ],
      metadata: [
        {
          key: this.keyAuto("headers"),
          name: "Headers",
          id: "headers",
          selected: true,
          type: "text",
          value: "",
        },
      ],
    };
  }

  keyAuto = (seed) => {
    return (
      seed +
      (Math.floor(Math.random() * 90000000) + 10000).toString()
    );
  };
}

decorate(EmailTriggerStore, {
  componentId: observable,
  name: observable,
  data: observable,
  type: observable,
  lastModified: observable,
  setLastModified: action,
  initialiseEmpty: action,
  position: observable,
  setSessionVariableData: action,
  template: computed,
  placeholders: observable,
});
const emailTriggerStore = new EmailTriggerStore();
export default emailTriggerStore;
