import React, { Fragment } from "react";

import { Grid, TextField } from "@mui/material";
import TransformStore from "../../TransformStore";
import ChipInput from "../../../ChipInput/ChipInput";
import { SelectConditional, SelectLogic } from "../Component";

// Icons
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

function FilterBasedOnAConditional({ conditions, availablePlaceholders, onPlaceholderSelected, index }) {
    return (
        <Fragment>
            <Grid item xs={12} className="item-label">
                Return values that
            </Grid>
            <Grid item xs={12}>
                {conditions &&
                    conditions.map((c, p) => {
                        return (
                            <Grid container spacing={2}>
                                {p > 0 && (
                                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                                        <SelectLogic value={c.logic} onChange={(value) => TransformStore.setCondition(value, index, p, "logic", "conditions")} />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <SelectConditional value={c.func} onChange={(value) => TransformStore.setCondition(value, index, p, "func", "conditions")} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="input-list-transform">
                                    <ChipInput
                                        placeholders={availablePlaceholders}
                                        onPlaceholderSelected={onPlaceholderSelected}
                                        fullWidth
                                        onBeforeAdd={(chip) => {
                                            TransformStore.setCondition(chip, index, p, "value", "conditions");
                                            return true;
                                        }}
                                        placeholder="Insert placeholder, text or integer. "
                                        inputValue={c.value}
                                        onDelete={(indexChip) => TransformStore.deleteChipCondition(indexChip, index, p, "conditions")}
                                        onFocus={() => TransformStore.setFocusCondition(index, p, "conditions")}
                                    />
                                </Grid>
                                {p > 0 && (
                                    <Grid item xs={1} className="item-icon-delete">
                                        <DeleteIcon onClick={() => TransformStore.deleteCondition(index, p, "conditions")} />
                                    </Grid>
                                )}
                            </Grid>
                        );
                    })}
            </Grid>
            <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                <div className="item-text-add" onClick={() => TransformStore.addCondition(index, "conditions")}>
                    <AddIcon /> Add AND/OR condition
                </div>
            </Grid>
        </Fragment>
    );
}

export default FilterBasedOnAConditional;
