import { TextField } from "@mui/material";
import { styled, alpha } from "@mui/system";

export const StyledTextField = styled(TextField)(({ styles, multiline }) => ({
  "& .MuiFilledInput-root": {
    ...styles,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    overflow: "hidden",
    borderRadius: 4,
    paddingTop: "16px !important",
    paddingLeft: "5px !important",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid black"
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      boxShadow: `${alpha("#90caf9", 0.25)} 0 0 0 1px`,
      borderColor: "#90caf9",
      borderRadius: 4
    }
  },
  "& .MuiFilledInput-input": {
    paddingBottom: multiline && "0px !important"
  },
}));
