import '@fontsource/open-sans';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#7AC0F8',
      main: '#000000',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#ED6696',
      main: '#E10050',
      dark: '#A0003A',
      contrastText: '#FFFFFF',
    },
    info: {
      light: '#808080',
      main: '#000000',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    error: {
      light: '#E57373',
      main: '#F44336',
      dark: '#D32F2F',
      contrastText: '#FFFFFF',
    },
    warning: {
      light: '#FFB74D',
      main: '#FF9800',
      dark: '#F57C00',
      contrastText: '#000000',
    },
    success: {
      light: '#81C784',
      main: '#4CAF50',
      dark: '#388E3C',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#868686',
      disabled: '#BDBDBD',
    },
    background: {
      default: '#FFFFFF',
      boxShadow: 'none'
    },
    divider: '#E0E0E0',
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '2em',
      color: '#868686',
      lineHeight: '60px',
    },
    h2: {
      fontSize: '1.5em',
      color: '#868686',
      lineHeight: '44px',
    },
    h3: {
      fontSize: '1.2em',
      fontWeight: 300,
      color: '#868686',
      lineHeight: '33px',
    },
    h4: {
      fontSize: '1.125em',
      color: '#000000',
      lineHeight: '16px',
    },
    body1: {
      fontSize: 14,
      lineHeight: '19px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '19px',
      fontWeight: 300,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', 
          boxShadow: 'none',  
        },    
        contained: {
          color: '#FFFFFF',
          backgroundColor: '#000000',
          '&:hover': {
            backgroundColor: '#000000',
          },
          '&:active': {
            boxShadow: 'none',
          },
        },
        outlined: {
          color: '#000000',
          borderColor: '#000000',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#F5F5F5',
            borderColor: '#000000',
            color: '#000000',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#E0E0E0',
            borderColor: '#000000',
            color: '#000000',
          },
        },
      },
    },
  },
});

export default theme;
