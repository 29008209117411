import PropTypes from "prop-types";

const MessageTypePropTypes = {
  message: PropTypes.shape({
    role: PropTypes.string,
    content: PropTypes.string.isRequired,
    showAnimation: PropTypes.bool,
    title: PropTypes.string,
    showTimer: PropTypes.bool,
    isJson: PropTypes.bool,
    loadingHistory: PropTypes.bool,
    pollNo: PropTypes.number,
    historyAnswers: PropTypes.array,
  }).isRequired,
  syncCompleted: PropTypes.bool,
  startTime: PropTypes.number,
  completedTime: PropTypes.number,
  dynamicMessageFunctions: PropTypes.func,
  buildComponentInfo: PropTypes.object,
  sessionId: PropTypes.string,
  streamingStatus: PropTypes.string,
  setViewportOnGenerate: PropTypes.func,
  viewportOnGenerate: PropTypes.bool,
  setNewDiagram: PropTypes.func,
  setStreamingStatus: PropTypes.func,
  setError: PropTypes.func,
  setPrebuildQuestionAnswers: PropTypes.func,
  prebuildQuestionAnswers: PropTypes.array,
  messages: PropTypes.array,
  restartComponentBuild: PropTypes.func,
  setWorkflowName: PropTypes.func,
};

export default MessageTypePropTypes;
