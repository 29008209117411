import React from "react";

// MUI
import { Button, CircularProgress, Grid, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";

// Util
import { useNavigate } from "react-router";
import OptionButtons from "../Util/OptionButtons";
import { send_request } from "../../../../utils/Request";
import { useAIOnboardingContext } from "../OnboardingContextProvider";
import User from "../../../pages/Canvas/AIBuilder/components/Chat/Messages/User";
import GPTResponse from "../../../pages/Canvas/AIBuilder/components/Chat/Messages/GPTResponse";
import { updateNextStepInDB } from "../Util/Util";

const messageBubbleStyle = {
    animation: 'bubbleAppear 0.3s ease-out',
    transformOrigin: 'bottom left',
};

function ChatActions(props) {
    const navigate = useNavigate();

    const { messages, setMessages, loadingBubble, setLoadingBubble, loading, setLoading, step, workflowDescription, setWorkflowDescription } = useAIOnboardingContext();

    const step2Actions = () => {
        if (loading) return <></>;

        return (
            <Grid container sx={messageBubbleStyle}>
                <Grid item xs={12}>
                    <OptionButtons classes={props.classes} aiClasses={props.aiClasses} loading={loading} loadingBubble={loadingBubble} handleRequest={handleRequest} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container item xs={12} direction={"row"} alignItems={"flex-start"} wrap={"nowrap"} justifyContent="center" paddingTop="16px">
                        <TextField
                            multiline={true}
                            value={workflowDescription}
                            maxRows={2}
                            variant={"outlined"}
                            placeholder={"Type in your request here"}
                            size={"small"}
                            onChange={(e) => {
                                setWorkflowDescription(e.target.value);
                            }}
                            fullWidth={true}
                            inputProps={{ maxLength: 2000 }}
                            disabled={loading || loadingBubble}
                        />
                        <Button className={props.classes.sendButton} variant={"contained"} onClick={() => handleRequest()} disabled={loading || loadingBubble || workflowDescription.length <= 0}>
                            <Send />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const handleRequest = async (opt) => {
        const option = {
            displayValue: opt && opt.displayValue ? opt.displayValue : workflowDescription,
            rawValue: opt && opt.rawValue ? opt.rawValue : workflowDescription,
        };

        if (!option || !option.displayValue || !option.rawValue) return;

        // Attach user message and ai message
        let arr = messages;
        arr.push({
            position: "flex-end",
            content: <User content={option.displayValue} />,
        });
        arr.push({
            position: "flex-start",
            content: <GPTResponse content={<Grid container>Got it. Just a moment while I work my magic.</Grid>} />,
        });

        setMessages((prevMessages) => [...arr]);

        setWorkflowDescription(option);
        setLoading(true);
        setLoadingBubble(true);

        // Make request to redirect logic
        await handleCreateAndRedirectLogic(option.rawValue);
    };

    const handleCreateAndRedirectLogic = async (option) => {
        // Handle making request to backend here
        const onboardingDTO = {
            workflowDescription: option,
        };

        const url = `project-service/project/create_ai_onboarding_workflow`;
        const json = await send_request(url, onboardingDTO, null, "POST");

        if (json && json.data) handleRedirect(json.data.projectId, json.data.chatSessionId, json.data.threadId); // Handle redirect
    };

    const handleRedirect = async (projectId, sessionId, threadId) => {
        await updateNextStepInDB("REDIRECTED_AND_COMPLETED");
        props.handleStart();
        navigate(`/project/canvas/${projectId}?chatSession=${sessionId}&threadId=${threadId}&startBuild=true`);
    };

    const handleStep = () => {
        switch (step) {
            case 2:
                return (
                    <div style={messageBubbleStyle}>
                        {step2Actions()}
                    </div>
                );
            default:
                return <></>;
        }
    };

    return (
        <React.Fragment>
            <style>
                {`
                    @keyframes bubbleAppear {
                        0% {
                            transform: scale(0);
                            opacity: 0;
                        }
                        100% {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                `}
            </style>
            {handleStep()}
        </React.Fragment>
    );
}

export default ChatActions;
