import React from "react";
import { withStyles } from "@mui/styles";

const styles = theme => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  textroot: {
    width: "100%",
    margin: "auto"
  },
  filePreviewURL: {
    height: 309,
    border: "1px solid #C4C4C4",
    boxSizing: "border-box",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14
  }
});


class FilePreview extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    try {
      return (
        <div className={classes.root}>
          <div className={classes.filePreviewURL}>
            {this.props.data.answer_data_default}
          </div>
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

export default withStyles(styles)(FilePreview);
