import React, { Component } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { toJS } from "mobx";
import WebhookImportStore from "../WebhookImport/WebhookImportStore";
import { send_request } from "../../utils/Request";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import ErrorIcon from "@mui/icons-material/Error";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton, styled } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CSVLink } from "react-csv";
import Dialog from "@mui/material/Dialog";
import config from "../../config";
import KeyIcon from '@mui/icons-material/Key';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DownloadIcon from '@mui/icons-material/Download';

const HeaderText = styled("p")({
  color: "#868686", 
  margin: 0, 
  padding: 0, 
  fontSize: "12px" 
})

const TokenButtonsContainer = styled(Grid)({
  display: 'flex',
  gap: '12px',
});

const StyledButton = styled(Button)({
  minWidth: '120px',
  height: '36px',
  borderRadius: '4px',
  fontWeight: 500,
  letterSpacing: '0.5px',
  '&:hover': {
    opacity: 0.9
  },
  '&.Mui-disabled': {
    backgroundColor: '#ccc',
    color: '#666'
  }
});

const GenerateButton = styled(StyledButton)({
  backgroundColor: '#000000',
  color: 'white',
  '&:hover': {
    backgroundColor: '#333333'
  }
});

const DeleteButton = styled(StyledButton)({
  backgroundColor: '#d32f2f',
  color: 'white',
});

const DownloadButton = styled(StyledButton)({
  backgroundColor: '#000000',
  color: 'white',
  '&:hover': {
    backgroundColor: '#333333'
  }
});

class GenerateToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: this.props.tokenStatus,
      loadingCircle: false,
      generateOpen: false,
      regenerateOpen: false,
      showPassword: false,
      showURL: false,
      webhookTokenURL: "",
    };
  }

  generateToken = async () => {
    this.setState({
      loadingCircle: true
    });
    let lastModified = getCurrentTime();
    let dataSave = {};
    if (!this.props.data) {
      dataSave = toJS(WebhookImportStore).data;
    } else {
      dataSave = this.props.data;
    }

    let data = {
      componentData: {
        data: dataSave,
        name: this.props.name,
        lastModified: lastModified
      },
      componentId: this.props.component_id,
      type: WebhookImportStore.template.type
    };

    let generateTokenUrl = "component-service/webhook/generate-token";

    if (this.props.generateTokenUrl) {
      generateTokenUrl = this.props.generateTokenUrl;
    }

    const json = await send_request(generateTokenUrl, data, "", "POST");

    if (json.data && json.status == "200") {
      setTimeout(() => {
        this.setState({
          generateOpen: true,
          loadingCircle: false,
          token: json.data,
          hasToken: true
        });
        this.props.hasToken(true);
      }, 2000);
    } else {
      this.setState({ loadingCircle: false });
    }
  };

  deleteToken = async () => {
    this.setState({
      deleteLoadingCircle: true
    });
    let lastModified = getCurrentTime();

    let dataSave = {};
    if (!this.props.data) {
      dataSave = toJS(WebhookImportStore).data;
    } else {
      dataSave = this.props.data;
    }

    let data = {
      componentData: {
        data: dataSave,
        name: this.props.name,
        lastModified: lastModified
      },
      componentId: this.props.component_id,
      type: WebhookImportStore.template.type
    };

    let deleteTokenUrl = "component-service/webhook/delete-token";

    if (this.props.deleteTokenUrl) {
      deleteTokenUrl = this.props.deleteTokenUrl;
    }

    const json = await send_request(deleteTokenUrl, data, "", "POST");
    if (json && json.status == "200") {
      setTimeout(() => {
        this.setState({ deleteLoadingCircle: false, hasToken: false });
        this.props.hasToken(false);
      }, 2000);
    }
  };

  handleGenerateToken = () => {
    this.setState({
      generateOpen: false
    });
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  handleMouseDownPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  getEndpointTokenURL = () => {
    if (!this.state.token) return <></>; // If no token, don't show anything
    const url = `${config.WEBHOOK_URL}/${this.props.component_id}?token=${this.state.token}`;

    return (
      <Grid item xs={12}>
        <Grid container spacing={"4px"}>
            <Grid item xs={12}>
              <HeaderText>
                Endpoint URL + token
              </HeaderText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled
                value={url}
                variant="outlined"
                className="gene-textField"
                type={this.state.showURL ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => { this.setState({ showURL: !this.state.showURL })}}
                        size="large"
                      >
                        {this.state.showURL ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={this.state.generateOpen}
          handleClose={() => {
            this.setState({ generateOpen: false });
          }}
        >
          <DialogTitle>
            <h5>Generate token</h5>
          </DialogTitle>
          <div className="dialogPaper">
            <DialogContent
              className="dialogContent"
              style={{ marginTop: "0px" }}
            >
              <p className="dialogText" style={{ color: "#868686" }}>
                The token below is required for any incoming data be to accepted
                by this component.
              </p>

              {this.props.componentType === "trigger_a_zap" && (
                <p className="dialogText" style={{ color: "#868686" }}>
                  Copy and paste this token into Zapier when setting up your
                  zap.
                </p>
              )}

              <Grid
                xs={12}
                container
                className="generate-err-text"
                item
                direction="row"
                style={{ fontSize: "12px" }}
              >
                <Grid item xs={1}>
                  <ErrorIcon className="iconDiscard" />
                </Grid>
                <Grid item xs={11}>
                  This is the <b>only</b> time that this token can be viewed or
                  downloaded. When this window is closed, you will not be able
                  to view or download this token ever again. However, you can
                  generate a new token at any time.
                </Grid>
              </Grid>

              <Grid container marginTop={2} rowGap={3}>

                <Grid item xs={12}>
                  <Grid container spacing={"4px"}>
                    <Grid item xs={12}>
                      <HeaderText>
                        Token
                      </HeaderText>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        className="gene-textField"
                        disabled={true}
                        type={this.state.showPassword ? "text" : "password"} // <-- This is where the magic happens
                        value={this.state.token}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                size="large"
                              >
                                {this.state.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    
                    {!this.props.componentType && (
                      <Grid item xs={12}>
                        <HeaderText>
                          Include this in the incoming request as a header with the name “token” i.e. -H “token: token value here” or use the endpoint URL below which contains the token
                        </HeaderText>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {this.getEndpointTokenURL()}

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item>
                      <CSVLink
                        data={this.state.token}
                        filename={this.props.component_id + ".csv"}
                        style={{ textDecoration: 'none' }}
                      >
                        <DownloadButton
                          variant="outlined"
                          startIcon={<DownloadIcon />}
                        >
                          Download
                        </DownloadButton>
                      </CSVLink>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

              <div
                style={{
                  display: "flex",
                  paddingTop: 24,
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  style={{ marginLeft: 16 }}
                  onClick={this.handleGenerateToken}
                  variant={"contained"}
                  color={"primary"}
                >
                  Done
                </Button>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {!this.state.hasToken ? (
          <TokenButtonsContainer
            item
            container
            sx={{ justifyContent: this.props.isAlignCenter ? "center" : "flex-start" }}
          >
            <GenerateButton
              variant="contained"
              onClick={this.generateToken}
              disabled={this.state.loadingCircle}
              startIcon={<KeyIcon />}
            >
              GENERATE TOKEN
              {this.state.loadingCircle && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </GenerateButton>
          </TokenButtonsContainer>
        ) : (
          <TokenButtonsContainer
            item
            container
            sx={{ justifyContent: this.props.isAlignCenter ? "center" : "flex-start" }}
          >
            <GenerateButton
              variant="contained"
              onClick={this.generateToken}
              disabled={this.state.loadingCircle}
              startIcon={<AutorenewIcon />}
            >
              Regenerate Token
              {this.state.loadingCircle && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </GenerateButton>
            <DeleteButton
              variant="contained"
              onClick={this.deleteToken}
              disabled={this.state.deleteLoadingCircle}
              startIcon={<DeleteOutlineIcon />}
            >
              Delete Token
              {this.state.deleteLoadingCircle && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </DeleteButton>
          </TokenButtonsContainer>
        )}
      </>
    );
  }
}

export default GenerateToken;
