import { computed, observable, decorate, action, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";

class CalcultorStore {
  constructor() {
    this.initialiseEmpty();
  }
  setCalculatorData = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.calculationData();
      this.data = [obj];
      this.lastModified = "";
      this.name = "";
    }
  };

  setTemplateData = data => {
    this.data = data;
  }
  
  setCalculatorName = name => {
    this.name = name;
  };
  setLastModified = lastModified => {
    this.lastModified = lastModified;
  };
  setPlaceholders = placeholders => {
    this.placeholders = placeholders;
  }
  setFocus = (indexCalculation,fieldFocus) => {
    this.position = indexCalculation;
    this.fieldFocus = fieldFocus;
    this.placeholders = [];
  };

  keyAuto = () => {
    return "calc" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }

  calculationData = () => {
    return {
      key: this.keyAuto(),
      perform: "BASIC",
      operator: "",
      round: 2,
      datetimefuncUnit:"MINUTES",
      datetimefuncOperator:"ADD",
      datetimeFormat: "dd MMMM yyyy HH:mm",
      keyRandom: Math.random()
    };
  };
  setCalculation = (value, indexCalculation, type) => {
    try {
      switch (type) {
        case "key":
          this.data[indexCalculation].key = value;
          return;
        case "perform":
          this.data[indexCalculation].round = 2;
          if (value == "FUNCTION") {
            const data = {
              ...this.data[indexCalculation],
              perform: value,
              func: "",
              operator: []
            };
            this.data[indexCalculation] = data;
          } else {
            this.data[indexCalculation].perform = value;
            this.data[indexCalculation].operator = "";
            delete this.data[indexCalculation].func;
          }
          return;
        case "func":
          this.data[indexCalculation].func = value;
          return;
        case "operator":
          if (this.data[indexCalculation].perform == "FUNCTION") {
            value = [...this.data[indexCalculation].operator, value];
          }
          this.data[indexCalculation].operator = value;
          return;
        case "round":
          this.data[indexCalculation].round = parseInt(value);
          return;
        case "datetimefunc":
          this.data[indexCalculation].datetimefunc = value;
          return;
        case "datetimefuncUnit":
          this.data[indexCalculation].datetimefuncUnit = value;
          return;
        case "datetimevalue1":
          //value = [value];
          this.data[indexCalculation].datetimevalue1 = value;
          return;
        case "datetimevalue2":
          //value = [value];
          this.data[indexCalculation].datetimevalue2 = value;
          return;
        case "datetimefuncOperator":
          //value = [value];
          this.data[indexCalculation].datetimefuncOperator = value;
          return;
        case "datetimeFormat":
          //value = [value];
          this.data[indexCalculation].datetimeFormat = value;
          return;
      }
    } catch (error){
      console.error(error);
    }
  };

  addCalculation = () => {
    const obj = this.calculationData();
    this.data.push(obj);
  };

  deleteCalculation = index => {
    this.data.splice(index, 1);
  };
  deleteChip = (indexChip, indexCalculation) => {
    const newChips = [...this.data[indexCalculation].operator];
    newChips.splice(indexChip, 1);
    this.data[indexCalculation].operator = newChips;
  };

  deleteChipByType = (indexChip, indexCalculation, type) => {

    if(this.data[indexCalculation][type] && Array.isArray(this.data[indexCalculation][type]) ) {
      //array
      this.data[indexCalculation][type].splice(indexChip, 1);
    } else {
      //not array
      this.data[indexCalculation][type] = undefined;
    }
  };

  initialiseEmpty = () => {
    const obj = this.calculationData();
    this.name = "";
    this.position = null;
    this.fieldFocus = null;
    this.placeholders = [];
    this.componentId = "";
    this.type = "calculator";
    this.lastModified = "";
    this.data = [obj];
    this.error = false;
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: this.placeholders
    };
  }
}

decorate(CalcultorStore, {
  componentId: observable,
  placeholders: observable,
  position: observable,
  name: observable,
  data: observable.shallow,
  type: observable,
  lastModified: observable,
  error: observable,
  addCalculation: action,
  setCalculation: action,
  setCalculatorData: action,
  setLastModified: action,
  setPlaceholders: action,
  setFocus: action,
  deleteCalculation: action,
  deleteChip: action,
  initialiseEmpty: action,
  template: computed
});

const calcultorStore = new CalcultorStore();
export default calcultorStore;
