import React, { Fragment } from "react";

import { Grid, TextField } from "@mui/material";
import ChipInput from "../../../ChipInput/ChipInput";
import TransformStore from "../../TransformStore";

function FindAndReplace({ replace, onPlaceholderSelected, availablePlaceholders, withThis, index }) {
    return (
        <Fragment>
            <Grid item xs={12} className="item-label">
                Replace this
            </Grid>
            <Grid item xs={12} className="input-list-transform">
                <ChipInput
                    inputValue={replace}
                    onPlaceholderSelected={onPlaceholderSelected}
                    placeholders={availablePlaceholders}
                    placeholder="Type something or insert a placeholder"
                    fullWidth
                    onBeforeAdd={(chip) => {
                        TransformStore.setComponent(chip, index, "replace");
                        return true;
                    }}
                    onDelete={(indexChip) => TransformStore.deleteChip(indexChip, index, "replace")}
                    onFocus={() => TransformStore.setFocus(index, "replace")}
                />
            </Grid>
            <Grid item xs={12} className="item-label">
                With this
            </Grid>
            <Grid item xs={12} className="input-list-transform">
                <ChipInput
                    placeholders={availablePlaceholders}
                    onPlaceholderSelected={onPlaceholderSelected}
                    fullWidth
                    inputValue={withThis}
                    onBeforeAdd={(chip) => {
                        TransformStore.setComponent(chip, index, "withThis");
                        return true;
                    }}
                    placeholder="Type something or insert a placeholder"
                    onDelete={(indexChip) => TransformStore.deleteChip(indexChip, index, "withThis")}
                    onFocus={() => TransformStore.setFocus(index, "withThis")}
                />
            </Grid>
        </Fragment>
    );
}

export default FindAndReplace;
