import React from "react";

// mui
import { Grid, TextField, styled } from "@mui/material";

// icons
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

// util
import { toJS } from "mobx";
import { observer } from "mobx-react";
import MentionEditor from "../TextEditor/MentionEditor";

// styled components
const DataItem = styled(Grid)({
    border: "1px solid #DADADA",
    borderRadius: "6px",
    padding: "16px",
});

const AddKeyValuePairText = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    color: "#000",
    "&:hover": {
        cursor: "pointer",
        color: "#2196F3",
    },
});

const InfoBox = styled(Grid)({
    border: "1px solid #000",
    background: "#F3F3F3",
    borderRadius: "4px",
    padding: "8px",
});

const Error = styled(ErrorIcon)({
    color: "#000",
    marginRight: "12px",
    width: "16px",
    height: "auto",
    paddingTop: "2px",
});

const Close = styled(CloseIcon)({
    cursor: "pointer",
    color: "#000",
    "&:hover": {
        color: "#2196F3",
    },
});

function DataPayloadToZap(props) {
    let plArray = toJS(props.placeholders);

    const addNewData = () => {
        props.TriggerAZapStore.addNewData();
    };

    const getKey = (aZData, index) => {
        return (
            <Grid container rowGap="6px">
                <Grid item xs={12}>
                    <span>Key</span>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={aZData.key}
                        placeholder={"Insert key value here"}
                        variant="outlined"
                        onChange={(event) => {
                            props.TriggerAZapStore.setDataValue(index, "key", event.target.value);
                        }}
                        size="small"
                    />
                </Grid>
            </Grid>
        );
    };

    const getValue = (aZData, index) => {
        return (
            <Grid container rowGap="6px">
                <Grid item xs={12}>
                    <span>Value</span>
                </Grid>
                <Grid item xs={12}>
                    <MentionEditor
                        availablePlaceholders={props.availablePlaceholders}
                        placeholders={plArray[index] ? plArray[index] : []}
                        text={aZData.value}
                        placeholder="Insert value here"
                        onChange={(value) => {
                            props.TriggerAZapStore.setDataValue(index, "value", value);
                        }}
                        onFocus={(e) => {
                            props.TriggerAZapStore.setFocus(index);
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const getTestValue = (aZData, index) => {
        return (
            <Grid container rowGap="6px">
                <Grid item xs={12}>
                    <span>Test value</span>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={aZData.sampleValue}
                        placeholder={"Insert sample value here"}
                        variant="outlined"
                        onChange={(event) => {
                            props.TriggerAZapStore.setDataValue(index, "sampleValue", event.target.value);
                        }}
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <b>Data payload to Zapier</b>
            </Grid>
            <Grid item xs={12}>
                <InfoBox container>
                    <Grid item display="flex" alignItems="center" justifyContent="center">
                        <Error />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center" justifyContent="flex-start">
                        <span>To send a test record to Zapier, perform a test run from the previous component(s) that trigger this component</span>
                    </Grid>
                </InfoBox>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap="24px">
                    {props.TriggerAZapStore.data &&
                        props.TriggerAZapStore.data.zapierData &&
                        props.TriggerAZapStore.data.zapierData.map((aZData, index) => {
                            return (
                                <DataItem item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                                            <Close
                                                onClick={() => {
                                                    props.TriggerAZapStore.deleteData(index);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {getKey(aZData, index)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {getValue(aZData, index)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {getTestValue(aZData, index)}
                                        </Grid>
                                    </Grid>
                                </DataItem>
                            );
                        })}
                </Grid>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
                <AddKeyValuePairText
                    onClick={() => {
                        addNewData();
                    }}
                >
                    <AddIcon /> Add Key Value pair
                </AddKeyValuePairText>
            </Grid>
        </Grid>
    );
}

export default observer(DataPayloadToZap);
