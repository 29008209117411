import React, { useState, useContext } from "react";

import { Grid } from "@mui/material";
import { CanvasContext } from "../WorkflowCanvas";
import AIBuilderSidebar from "../AIBuilder/AIBuilderSidebar";
import PropTypes from "prop-types";

function WorkflowBuilderSidebarWithButtons(props) {
  const [resetHistory, setResetHistory] = useState(null);
  const [openSessionHistory, setOpenSessionHistory] = useState(false);
  const [callDiscard, setCallDiscard] = useState(false);

  const { canvasInstance, savingInfo } = useContext(CanvasContext);

  const [saving, setSaving] = savingInfo ? savingInfo : useState({});
  const [reactFlowInstance, setReactFlowInstance] = canvasInstance
    ? canvasInstance
    : "";

  // Position from the right of the page
  const { isCanvasLocked } = useContext(CanvasContext);
  const [canvasLocked, setCanvasLocked] = isCanvasLocked
    ? isCanvasLocked
    : useState(false);

  const closeAIBuilderSidebar = () => {
    props.setShowAIBuilder(false);
    props.setAiBuilderOpen(false);
    setOpenSessionHistory(false);
    setResetHistory(null);
  };

  const setNodes = (updatedNodes) => props.CanvasStore.setNodes(updatedNodes);
  const setEdges = (updatedEdges) => props.CanvasStore.setEdges(updatedEdges);

  const handleSaving = async () => {
    setSaving(true);
    setTimeout(() => {
      setSaving(false), 2000;
    });
  };

  return (
    <Grid style={{ height: "100%" }}>
      <AIBuilderSidebar
        open={props.showAIBuilder}
        setWorkflowName={props.setWorkflowName}
        handleSaveProjectName={props.handleSaveProjectName}
        close={closeAIBuilderSidebar}
        projectId={props.projectId}
        nodes={props.nodes}
        setNodes={setNodes}
        edges={props.edges}
        setEdges={setEdges}
        reactFlowInstance={reactFlowInstance}
        setSaving={handleSaving}
        draftVersion={props.draftVersion}
        openSessionHistory={openSessionHistory}
        setOpenSessionHistory={setOpenSessionHistory}
        selectedHistorySession={props.selectedHistorySession}
        setSelectedHistorySession={props.setSelectedHistorySession}
        setResetHistory={setResetHistory}
        getLatestVersion={props.getLatestVersion}
        refetchComponents={props.refetchComponents}
        sidebarState={props.sidebarState}
        setSidebarState={props.setSidebarState}
        callDiscard={callDiscard}
        setCallDiscard={setCallDiscard}
        triggerCloseInChild={props.triggerCloseInChild}
        setTriggerCloseInChild={props.setTriggerCloseInChild}
        editComponent={props.editComponent}
        setPaneSkeleton={props.setPaneSkeleton}
        setState={props.setState}

      />
    </Grid>
  );
}

WorkflowBuilderSidebarWithButtons.propTypes = {
  setShowAIBuilder: PropTypes.func.isRequired,
  setAiBuilderOpen: PropTypes.func.isRequired,
  CanvasStore: PropTypes.shape({
    setNodes: PropTypes.func.isRequired,
    setEdges: PropTypes.func.isRequired,
  }).isRequired,
  setWorkflowName: PropTypes.func.isRequired,
  handleSaveProjectName: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  nodes: PropTypes.array.isRequired,
  edges: PropTypes.array.isRequired,
  draftVersion: PropTypes.string.isRequired,
  selectedHistorySession: PropTypes.object.isRequired,
  setSelectedHistorySession: PropTypes.func.isRequired,
  getLatestVersion: PropTypes.func.isRequired,
  refetchComponents: PropTypes.func.isRequired,
  sidebarState: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  showAIBuilder: PropTypes.bool.isRequired,
  triggerCloseInChild:PropTypes.bool,
  setTriggerCloseInChild:PropTypes.func,
  editComponent: PropTypes.object,
  setPaneSkeleton: PropTypes.func,


};

export default WorkflowBuilderSidebarWithButtons;
