import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import { assumptionsChunk, getIcon } from "../ChunkRenderer";
import HandleExpandedState from "./HandleExpandedState";

export const INITIAL_COMPONENT_MESSAGE = {
    role: "assistant",
    showTimer: false,
    content: (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                Hi 👋, I’m The Component Builder, a system AI Agent designed to help you configure and set up components.
            </Grid>
            <Grid item xs={12}>
                I have pre-loaded the component description into the message below as a starting point for this build. I can build this workflow using that description, or you can edit and input your
                own instructions.
            </Grid>
            <Grid item xs={12}>
                💡 <b>Tips</b>
            </Grid>
            <Grid item xs={12}>
                I will automatically have access to this information when building:
            </Grid>
            <Grid item xs={12}>
                <ul>
                    <li>
                        all <b>input placeholders</b> from the rest of the workflow that can be used in this component
                    </li>
                </ul>
            </Grid>
            <Grid item xs={12}>
                For the best results, include any other additional information I need to know to configure this component:
            </Grid>
            <Grid item xs={12}>
                <ul>
                    <li>
                        an <b>explanation or description</b> of what you want the component to do
                    </li>
                    <li>
                        the <b>outputs</b> you want from this component. You can also specify the placeholder names you want e.g. “Output placeholders: output_placeholder1, output_placeholder2”
                    </li>
                    <li>
                        any <b>other component specific information</b> such as database IDs, component IDs, APIs, email addresses or other information that I need to configure this component
                    </li>
                </ul>
            </Grid>
        </Grid>
    ),
};

export const FINISH_SETTING_UP_MESSAGE = {
    role: "assistant",
    showTimer: false,
    content: (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <b>Finish setting up all components</b>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        The changes have been applied to the workflow. However, to get this workflow ready to use, we need to finish setting up any new components that have been added in and
                        implementing the changes for the components that are being updated.
                    </Grid>
                    <Grid item xs={12}>
                        I can perform this final setting up for you across all components. This should only take me about 60 seconds.
                    </Grid>
                    <Grid item xs={12}>
                        You can also end this chat and perform this final set up yourself, but I highly recommend letting me complete and implement for you.
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    ),
};

const ProposalBlock = styled(Grid)({
    background: "#FFF",
    border: "1px solid #ECECEC",
    borderRadius: "6px",
    padding: "16px",
});



const ProposalBox = styled(Grid)({});



export const getProposalMessage = (data, setLoading, setOpenPrebuildPreview, setMermaidJSContent, diagramDTO, historyMermaidJS, messages, setMessages) => {
    const { proposal, comment, assume } = data;

    return {
        role: "assistant",
        content: (
            <ProposalBox container rowGap="16px">
                <Grid item xs={12}>
                    <b>Proposal</b>
                </Grid>
                {comment && (
                    <ProposalBlock item xs={12}>
                        <span>{comment}</span>
                    </ProposalBlock>
                )}
                {assume && assume.length > 0 && (
                    <Grid item xs={12}>
                        {assumptionsChunk({assumptions: assume})}
                    </Grid>
                )}
                <ProposalBlock item xs={12}>
                    <Grid container rowGap="16px">
                        <Grid item xs>
                            <Grid container rowGap="16px">
                                <Grid item xs={12}>
                                    <HandleExpandedState proposal={proposal}/>


                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ProposalBlock>
            </ProposalBox>
        ),
    };
};



