import React, {Component} from "react";
import {observer} from "mobx-react";
import {withStyles} from "@mui/styles";
import ChipInput from "../ChipInput/ChipInput";
import Paper from "@mui/material/Paper";
import {toJS} from "mobx";
import DocumentStore from "../DocumentEditor/DocumentStore";
import CONFIG from "../../config";
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';



const useStyles = () => ({});

class DocumentPreview extends Component {
    constructor(props) {
        const fileService = CONFIG.FILE_SERVICE;
        super(props);
        this.state = {
            loading: false,
            showFrame: false,
            previewLoading: true,
            fileFullPath: `${fileService.QUERY_URL}` + "/" + this.props.filePath
        };
        
    }

    async componentDidMount() {
     
        //Refresh preview every 3.5 seconds
        this.intervalId = setInterval(this.refreshPreview, 3500);
    }

    refreshPreview = () => {
        try {
            //Check if preview has been loaded with data
            if (!document.getElementById("docx-preview")) {
                //Reload Previewer
                this.setState({ showFrame: false }, () => {
                    this.setState({ showFrame: true });
                });
            } else if (!document.getElementById("docx-preview").contentWindow.global){
                //Reload Previewer
                this.setState({ showFrame: false }, () => {
                    this.setState({ showFrame: true });
                });
            } else {
                //Completed Load
                //Break the loop
                clearInterval(this.intervalId);
            }
        } catch {
            //Completed Load
            this.setState({previewLoading: false});
            //Break the loop
            clearInterval(this.intervalId);
        }

       
        
    };
  



    render() {
        return (
          <div>
            <Paper sx={{ width: '100%', padding: '16px' }}>
              <h4>Preview</h4>
              <div style={{ fontSize: 14, marginBottom: 24 }}></div>
              <div style={{ borderRadius: 10, height: '100vh', overflow: 'hidden', position: 'relative' }}>

                {this.state.showFrame && (
                    <div style={{ width: '100%', height: '100%', zIndex: 2, position: 'relative'}}>
                        <iframe
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            id="docx-preview"
                            src={'https://docs.google.com/gview?url=' + this.state.fileFullPath + '&embedded=true'}
                        />
                    </div>
                )}
                {this.state.previewLoading && (
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' ,height: '100vh', zIndex: 1}}>
                    <Skeleton variant="rounded" width={1000} height={1000} sx={{zIndex: 1}}/>
                  </div>
                )}
              </div>
            </Paper>
          </div>
        );
      }
}

export default observer(withStyles(useStyles)(DocumentPreview));
