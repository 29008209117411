import React, { useState, useEffect } from "react";

// MUI
import { Drawer, Grid, styled } from "@mui/material";
import Button from "@mui/material/Button";

// Util
import ErrorContainer from "./ErrorContainer";
import { BoldTopText, getNameFromKey } from "./ValidationUtil";
import WarningContainer from "./WarningContainer";
import { runLoading } from "../../../utils/showLoadingScreen";
import ValidationActionButtons from "./ValidationActionButtons";

const StyledDrawer = styled(Drawer)(() => ({
    "& .MuiDrawer-paper": {
        width: `500px !important`,
        height: "100%",
        maxHeight: "100%",
        zIndex: 100,
        backgroundColor: "#FFFFFF",
        border: "0px 0px 0px 1px solid #D9D9D9",
        left: "48px",
        padding: "32px 24px 0px 24px",
    },
}));

export const CustomGrid = styled(Grid)(({ margin, horizontal, vertical }) => ({
    margin: margin,
    ...(horizontal && vertical && { display: "flex", alignItems: vertical, justifyContent: horizontal }),
}));

const IssuesDetectedText = styled("span")({
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
});

function FixErrorsDialog({
    errorsArray,
    errorsNumber,
    project_id,
    close,
    openInPane,
    validatingType,
    open,
    handlePublish,
    setOpenBrokenPHRename,
    setRenameGivenPH,
    setOpenTestDialog,
    validationLoading,
    fireValidateDialog,
    disabled,
    setNavigationWarning,
    editComponent
}) {
    const [groupedErrors, setGroupedErrors] = useState(null);

    useEffect(() => {
        // Group by error type
        let groupedErrors = getGroupedErrors(errorsArray);
        setGroupedErrors(groupedErrors);
    }, [errorsArray]);

    const getGroupedErrors = (errs) => {
        let groupedErrs = {
            errors: {
                notUnique: [],
                componentError: [],
                broken: [],
            },
            warnings: {
                notUnique: [],
                componentError: [],
                broken: [],
            },
        };

        for (let i = 0; i < errs.length; i++) {
            let currErr = errs[i];
            let type = currErr.type;

            let key = "errors";
            if (currErr.isWarning) key = "warnings";

            groupedErrs[key][type].push(currErr);
        }

        return groupedErrs;
    };

    const handleFix = (error, key) => {
        let component_id = error.component.componentId;

        if (key === "broken") setOpenBrokenPHRename(true);
        else {

            // Check if component is already open and
            if (editComponent) {
                setNavigationWarning(true);
                return;
            }

            // Open in pane
            openInPane(component_id);
        }
    };

    const getTestValidationButtons = () => {
        return (
            <>
                <Button
                    variant={"outlined"}
                    color={"info"}
                    className={"btnSpace"}
                    onClick={() => {
                        close();
                    }}
                >
                    Back to canvas
                </Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        setOpenTestDialog();
                    }}
                >
                    Continue to test
                </Button>
            </>
        );
    };

    const getErrorItems = () => {
        const errorsMap = groupedErrors["errors"];

        if (!errorsMap || !errorsMap.componentError || !errorsMap.broken) return <></>;
        if (errorsMap.componentError.length <= 0 && errorsMap.broken.length <= 0) return <></>;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <BoldTopText>Errors</BoldTopText>
                </Grid>
                <Grid item xs={12}>
                    <p className={"s-text"}>
                        We have detected{" "}
                        <b>
                            {errorsNumber} {`issue${errorsNumber > 1 ? "s" : ""}`}
                        </b>{" "}
                        in this workflow which we recommend you fix before using this workflow.
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowGap={1}>
                        {Object.keys(errorsMap).map((key, gIdx) => {
                            let errs = errorsMap[key];
                            if (!errs || errs.length <= 0) return <></>;
                            return (
                                <Grid item xs={12}>
                                    <ErrorContainer errs={errs} key={key} errKey={key} idx={gIdx} handleFix={handleFix} disabled={disabled} editComponent={editComponent} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const getWarningItems = () => {
        const warningsMap = groupedErrors["warnings"];

        if (!warningsMap || !warningsMap.notUnique) return <></>;
        if (warningsMap.notUnique.length <= 0) return <></>;

        // Group the warnings by the placeholder
        const warningsGroupedByPH = {};
        for (let i = 0; i < warningsMap.notUnique.length; i++) {
            const item = warningsMap.notUnique[i];
            if (item.error in warningsGroupedByPH) {
                warningsGroupedByPH[item.error].push(item);
            } else {
                warningsGroupedByPH[item.error] = [item];
            }
        }

        // Set name with count
        const nameWithCount = `${warningsMap.notUnique.length} ${getNameFromKey("notUnique")}`;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <BoldTopText>Warnings</BoldTopText>
                </Grid>
                <Grid item xs={12}>
                    <p className={"s-text"}>Warnings do not prevent the workflow from working properly but should be noted</p>
                </Grid>
                <Grid item xs={12} marginBottom="8px">
                    <BoldTopText>{nameWithCount}</BoldTopText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowGap={1}>
                        {Object.keys(warningsGroupedByPH).map((placeholderKey, idx) => {
                            const warningItems = warningsGroupedByPH[placeholderKey];
                            if (!warningItems || warningItems.length <= 0) return <></>;

                            return (
                                <Grid item xs={12}>
                                    <WarningContainer placeholderKey={placeholderKey} idx={idx} warningItems={warningItems} handleFix={handleFix} disabled={disabled} editComponent={editComponent} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const getCanvasErrorButtons = () => {
        return (
            <Button variant={"contained"} color={"primary"} onClick={() => close()}>
                done
            </Button>
        );
    };

    const getValidatingButtons = () => {
        switch (validatingType) {
            case "canvasErrorButton":
                return getCanvasErrorButtons();
            case "publish":
                return <ValidationActionButtons disabled={disabled} close={close} handlePublish={handlePublish} fireValidateDialog={fireValidateDialog} />;
            default:
                return getTestValidationButtons();
        }
    };

    return (
        <StyledDrawer open={open} anchor={"left"} hideBackdrop={true} onClose={() => {}} variant="permanent">
            {validationLoading ? (
                <Grid container display="flex" alignItems="center" justifyContent="center" style={{ height: "100%" }}>
                    <Grid item xs={12}>
                        {runLoading()}
                        <p style={{ textAlign: "center" }}>Checking workflow for issues...</p>
                    </Grid>
                </Grid>
            ) : (
                <Grid container display="flex" alignContent="space-between" style={{ height: "100%" }}>
                    <Grid item xs={12}>
                        <Grid container gap="24px">
                            <Grid item xs={12}>
                                <IssuesDetectedText>{`${errorsNumber} issue${errorsNumber > 1 ? "s" : ""} detected`}</IssuesDetectedText>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container gap="16px">
                                    <Grid item xs={12}>
                                        {groupedErrors && groupedErrors["errors"] && getErrorItems()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {groupedErrors && groupedErrors["warnings"] && getWarningItems()}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: "32px" }}>
                        {getValidatingButtons()}
                    </Grid>
                </Grid>
            )}
        </StyledDrawer>
    );
}

export default FixErrorsDialog;
