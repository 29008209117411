import { computed, observable, decorate, action } from "mobx";

class BranchPaths {
    
    constructor() {
        this.initialiseEmpty();
    }

    initialiseEmpty = () => {
        this.name = "";
        this.position = null;
        this.componentId = "";
        this.type = "branch_paths";
        this.lastModified = "";
    };

    setComponentData = (component) => {
        this.componentId = component.componentId;
        this.type = component.type;

        if (component.componentData) {
            this.lastModified = component.componentData.lastModified;
            this.name = component.componentData.name;
        } else {
            this.lastModified = "";
            this.name = "";
        }
    };

    setComponentName = (name) => {
        this.name = name;
    };

    setLastModified = (lastModified) => {
        this.lastModified = lastModified;
    };

    get template() {
        return {
            componentId: this.componentId,
            name: this.name,
            position: this.position,
            type: this.type,
            lastModified: this.lastModified,
        };
    }
}

decorate(BranchPaths, {
    componentId: observable,
    position: observable,
    name: observable,
    type: observable,
    lastModified: observable,
    setLastModified: action,
    initialiseEmpty: action,
    template: computed,
});

const branchPaths = new BranchPaths();
export default branchPaths;
