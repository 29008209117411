import { DesktopWindows, ErrorRounded, OpenInNew, Refresh } from '@mui/icons-material'
import { TimelineOppositeContent } from '@mui/lab'
import { Alert, Button, Paper, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router'
import WorkflowTestLog from "../pages/ProjectLog/WorkflowTestLog"

const PaperContainer = styled(Paper)({
    width: "100%",
    padding: "16px"
})

const TestResultContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "16px",
})

const StyledAlert = styled(Alert)({
    color: "black",
    backgroundColor: "#FFEACB",
    border: "1px solid #FFB74D",
    marginBottom: "1rem",
    padding: "4px 4px"
})

const StyledErrorIcon = styled(ErrorRounded)({
    color: "#FFB74D"
})

const StyledParagraph = styled("p")({
  marginTop: "0",
  marginBottom: "1rem"
})

const TestResults = ({
    testResult,
    displayOnly = false,
    handleRefresh
}) => {

  const navigate = useNavigate();

  return (
    <PaperContainer>
        { (testResult !== null && testResult !== undefined)  &&
            <TestResultContainer>
                <StyledAlert icon={<StyledErrorIcon/>}>
                  This session was generated by a test run
                </StyledAlert>
                <StyledParagraph>Session ID: {testResult.projectSessionId}</StyledParagraph>
                <StyledParagraph>Workflow Version: {testResult.projectVersion}</StyledParagraph>
                <WorkflowTestLog
                  id={testResult.projectId}
                  project_session_id={testResult.projectSessionId}
                  handleRefresh={handleRefresh}
                />
            </TestResultContainer>
        }
    </PaperContainer>
  )
}

export default TestResults