import PropTypes from 'prop-types';

const ChatPropTypes = {  chatRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
  startTime: PropTypes.string,
  completedTime: PropTypes.string,
  regenerate: PropTypes.func,
  insert: PropTypes.func,
  loading: PropTypes.bool,
  restart: PropTypes.func,
  buildAllComponents: PropTypes.func,
  buildComponentsIndividually: PropTypes.func,
  conclude: PropTypes.func,
  rebuild: PropTypes.func,
  cancel: PropTypes.func,
  userPrompt: PropTypes.string,
  generate: PropTypes.func,
  editAndRegenerate: PropTypes.func,
  updateUserPrompt: PropTypes.func,
  canInsert: PropTypes.bool,
  showInstruction: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  hideButtons: PropTypes.bool,
  showComponentSidebarButtons: PropTypes.bool,
  buttonText: PropTypes.string,
  charLimit: PropTypes.number,
  dynamicMessageFunctions: PropTypes.func,
  showBuildButtons: PropTypes.bool,
  showConclude: PropTypes.bool,
  showUserPrompt: PropTypes.bool,
  generateWithAdditional: PropTypes.func,
  additionalMessagePrompt: PropTypes.string,
  setAdditionalMessagePrompt: PropTypes.func,
  showAdditionalPrompt: PropTypes.bool,
  prebuildProposal: PropTypes.func,
  handleBuildWithProposal: PropTypes.func,
  isPrebuild: PropTypes.bool,
  regeneratePrebuild: PropTypes.func,
  restartComponentBuild: PropTypes.func,
  chatHistoryLoading: PropTypes.bool,
  isLoadedFromHistory: PropTypes.bool,
  setWorkflowName: PropTypes.func,
  isInit: PropTypes.bool,
  isCompBuilder: PropTypes.bool,
  paneSkeleton: PropTypes.bool,
  startCompTestRun: PropTypes.func,
  prebuildQuestionAnswers: PropTypes.array,
  buildComponentInfo: PropTypes.object, 
  sessionId: PropTypes.string,
  streamingStatus: PropTypes.string,
  setViewportOnGenerate: PropTypes.func,
  viewportOnGenerate: PropTypes.bool,
  setNewDiagram: PropTypes.func,
  setStreamingStatus: PropTypes.func,
  setError: PropTypes.func,
  setPrebuildQuestionAnswers: PropTypes.func,
};

export default ChatPropTypes;