import React, { Component } from "react";
import "./TimeDelay.css";
import {
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  ButtonGroup,
  OutlinedInput,
} from "@mui/material";
import { LocalizationProvider, TimePicker, DatePicker } from "@mui/lab";
import Button from "@mui/material/Button";
import DateAdapter from "@mui/lab/AdapterDayjs";
import TimeDelayStore from "./TimeDelayStore";
import { observer, inject } from "mobx-react";
import { autorun, reaction, toJS } from "mobx";
import { timezones } from "../../utils/timezones";
import { send_component_save_request, send_request } from "../../utils/Request";
import Autocomplete from "@mui/material/Autocomplete";
import { getCurrentTime } from "../../utils/getCurrentTime";
import projectStore from "../ProjectCanvas/ProjectStore";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import checkKey from "../../utils/CheckKey";
import ChipInput from "../ChipInput/ChipInput";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";
import { getTime, setMonth, convertTimezoneOffset } from "../../utils/TimeUtil";

class TimeDelayTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentType: 0,
      period: 0,
      timezone: "Australia/Hobart",
      day: "",
      loading: false,
      date: new Date(),
      errorMessage: "",
      focus: "",
      delayBy: [],
      loading: true,
      atDatetimePlaceholder: [],
      description:null,
      status:null
    };
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    if (this.props.component_id !== undefined) {
      const url = `project-service/project/component/${this.props.component_id}`;
      const json = await send_request(url, "", {}).catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

      if (json && json.data) {
        let components = json.data;

        if (components) {
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
            //console.log(this.props.aiComponentBuilderData)
            this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            components.componentData=this.props.aiComponentBuilderData
            TimeDelayStore.setComponentData(components);
            this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
            this.setState({ status: "AI_BUILD_GENERATED" });

          }
          else{
            TimeDelayStore.setComponentData(components);

          }
          this.props.onComponentNameChanged(TimeDelayStore.name);
          this.props.onComponentDescriptionChanged(components.description);
          this.props.onLastModifiedChanged(TimeDelayStore.lastModified);
          if (TimeDelayStore.data) {
            this.setState({
              componentType: TimeDelayStore.configComponentTypeValue(
                TimeDelayStore.data.componentType
              ),
              delayBy: TimeDelayStore.data.delayBy
                ? //we add to string here because in the past the field was forcing things
                  //into an int but chip input handles everything as text so having an int here crashes the field
                  [TimeDelayStore.data.delayBy.toString()]
                : [],
              atDatetimePlaceholder: TimeDelayStore.data.atDatetimePlaceholder ? [TimeDelayStore.data.atDatetimePlaceholder.toString()] : [],   
              period: TimeDelayStore.configIntervalValue(
                TimeDelayStore.data.delayInterval
              ),
              timezone: TimeDelayStore.data.timezone,
              date: this.getDatetime(TimeDelayStore.data.date),
              day: TimeDelayStore.data.day,
            });
          }
        }
        this.setState({ loading: false });
      }
      this.props.setChildTemplateLoaded(true);
    }
  }

  /**
   * We use this function to attach the users timezone to the date time stored in the database
   * i.e: "12th April 12pm" and we're in Sydney timezone (+10:00) so we add +10
   * This will then get converted to the correct date time
   */
  getDatetime = (tdStoreDate) => {
    try {
      // Need to invert the timezone offset
      if (tdStoreDate.slice(-1) === "Z") tdStoreDate = tdStoreDate.split("Z")[0];
      let offset = new Date().getTimezoneOffset();
      let dt = new Date(tdStoreDate + convertTimezoneOffset(offset, true, true));
      if (!dt || dt.toString() === "Invalid Date") return new Date();
      return dt;
    } catch (e) {
      console.log(e);
      return tdStoreDate;
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveTimeDelayTemplate();
    this.reactToPlaceHolder();
  }

  saveTimeDelayTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.checkKey) {
        this.props.showError();
        return;
      }

      let dataSave = toJS(TimeDelayStore).data;
      
      let lastModified = getCurrentTime();
      let data = {
        componentData: {
          data: dataSave,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified,
        },
        componentId: this.props.component_id,
        type: TimeDelayStore.template.type,
        description: this.props.ComponentDescription.value,
        status: this.state.status,
      };
      send_component_save_request("component-service/time-delay/data", data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          projectStore.savedComponent = true;
          this.props.setChildTemplateLoaded(true);
        
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  handleEditPlaceholder = (event) => {
    const placeholderValue = replacePlaceholderValue(event.target.value);
    TimeDelayStore.setPlaceholder(placeholderValue);
    const value = checkKey(placeholderValue, null, []);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
  };

  handleChangeComponentType = (event) => {
    this.setState({ componentType: event.target.value });
    TimeDelayStore.setData(event.target.value, "componentType");
  };

  handleChangePeriod = (event) => {
    this.setState({ period: event.target.value });
    TimeDelayStore.setData(event.target.value, "delayInterval");
  };

  setDateTime = (value) => {
    const DateTime = new Date(value);
    const month = setMonth(DateTime.getMonth() + 1);
    const year = DateTime.getFullYear();
    const time = getTime(value);

    let date = DateTime.getDate();
    if (date < 10) date = "0" + date;

    const dateStr = `${year}-${month}-${date}T${time}`;
    
    this.setState({ date: dateStr });
    TimeDelayStore.setData(dateStr, "date");
  };

  handleDelayBy = (event) => {
    this.setState({ delayBy: event.target.value });
    TimeDelayStore.setData(event.target.value, "delayBy");
  };

  handleTimezone = (event, inputValue) => {
    this.setState({ timezone: inputValue.name });
    TimeDelayStore.setData(inputValue.name, "timezone");
  };

  handleWeekDay = (day) => {
    this.setState({ day: day });
    TimeDelayStore.setData(day, "day");
  };

  insertPlaceholder = (label, text, style, focus, isNotPlaceholder) => {
    let newDelayByValue = this.state.delayBy;
    //adding placeholders to delay by field
    if (focus === "delayBy") {
      //to an empty array just push the value
      newDelayByValue = [text];
      //set the state
      this.setState({ delayBy: newDelayByValue });
      //add to the store
      TimeDelayStore.setData(newDelayByValue[0], "delayBy");
    } else if (focus === "atDatetimePlaceholder") {
        //to an empty array just push the value
        newDelayByValue = [text];
        //set the state
        this.setState({ atDatetimePlaceholder: newDelayByValue });
        //add to the store
        TimeDelayStore.setData(newDelayByValue[0], "atDatetimePlaceholder");
    }
  };

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key, isNotPlaceholder } = placeholder["selected"];

      this.insertPlaceholder(
        style.default,
        isNotPlaceholder ? key : "${" + key + "}",
        style,
        this.state.focus,
        isNotPlaceholder
      );
    }
  );

  render() {
    const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const type = this.state.componentType;
    if (!this.state.loading)
      return (
        <Grid container item xs={12} direction={"column"}>
          <Paper className={"paper"}>
            <Grid
              container
              className={"space"}
              marginTop={2}
            >
              <Grid item display="flex" alignItems="center" justifyContent="center">
                <span style={{ fontSize: "14px", width: "fit-content" }}>Placeholder</span>
              </Grid>
              <Grid item xs>
                <Grid container marginLeft={2}>
                  <W86PlaceholderTextField
                    value={TimeDelayStore.data.key}
                    error={this.state.errorMessage.length > 0}
                    helperText={this.state.errorMessage}
                    placeholderText="Enter placeholder for time delay"
                    handleChange={this.handleEditPlaceholder}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              item
              direction={"column"}
              className={"space"}
              alignItems={"baseline"}
            >
              <p>Delay execution of further connections until</p>
              <Select
                className={"key_padding select "}
                onChange={this.handleChangeComponentType}
                value={this.state.componentType}
              >
                <MenuItem value={0}>
                  a certain amount of time has elapsed
                </MenuItem>
                <MenuItem value={1}>a specific date and time</MenuItem>
                <MenuItem value={2}>
                  a certain day and time of the week
                </MenuItem>
                <MenuItem value={3}>
                  a datetime placeholder value
                </MenuItem>
              </Select>
            </Grid>
            {type == 0 && (
              <Grid
                xs={12}
                container
                item
                direction={"row"}
                style={{ padding: "16px" }}
                alignItems={"center"}
                spacing={2}
              >
                <Grid item xs={6}>
                  <ChipInput
                    placeholders={this.props.availablePlaceholders}
                    placeholder={"Insert number or placeholder"}
                    inputValue={this.state.delayBy}
                    size={"small"}
                    fullWidth={true}
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    onDelete={(index) => {
                      this.state.delayBy.splice(index, 1);
                      TimeDelayStore.setData(this.state.delayBy, "delayBy");
                    }}
                    onFocus={(event) => {
                      this.setState({ focus: "delayBy" });
                    }}
                  />
                </Grid>
                <Grid item>
                  <Select
                    placeholder={"select period"}
                    onChange={this.handleChangePeriod}
                    value={this.state.period}
                    style={{ height: 34 }}
                  >
                    <MenuItem value={0}>Select period</MenuItem>
                    <MenuItem value={1}>minutes</MenuItem>
                    <MenuItem value={2}>hours</MenuItem>
                    <MenuItem value={3}>days</MenuItem>
                    <MenuItem value={6}>business days</MenuItem>
                    <MenuItem value={4}>weeks</MenuItem>
                    <MenuItem value={5}>months</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}
            {type == 1 && (
              <Grid container item xs={12} direction={"column"}>
                <Grid
                  container
                  item
                  xs={12}
                  direction={"row"}
                  alignItems={"center"}
                  className={"lift"}
                  style={{ padding: "16px 16px 0px 16px" }}
                >
                  <p className={"spaceItems"}>At this date </p>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                      disableToolbar
                      value={this.state.date}
                      format="dd.MM.yyyy"
                      onChange={this.setDateTime}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}
            {type == 2 && (
              <Grid
                container
                item
                xs={12}
                direction={"column"}
                className={"lift"}
                style={{ padding: "16px" }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  direction={"row"}
                  alignItems={"baseline"}
                >
                  <p className={"spaceItems"}>Select day</p>
                  <ButtonGroup data={weekDays}>
                    {weekDays.map((day, index) => {
                      return (
                        <Button
                          key={index}
                          className={"d-button"}
                          variant={
                            this.state.day == day ? "contained" : "outlined"
                          }
                          color={this.state.day == day ? "primary" : "info"}
                          onClick={() => {
                            this.handleWeekDay(day);
                          }}
                        >
                          {day}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </Grid>
              </Grid>
            )}
            {(type == 1 || type == 2) && (
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                alignItems={"center"}
                className={"space"}
              >
                <p className={"spaceItems"}>At this time</p>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <TimePicker
                    renderInput={(params) => <TextField {...params} />}
                    onChange={this.setDateTime}
                    value={this.state.date}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {(type == 1 || type == 2) && (
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                alignItems={"center"}
                className={"space lift"}
              >
                <p className={"spaceItems"}>Timezone</p>
                <Autocomplete
                  className={"autocomplete"}
                  options={timezones}
                  autoComplete={true}
                  value={this.state.timezone}
                  size={"small"}
                  onChange={this.handleTimezone}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="outlined"
                        value={this.state.timezone}
                      />
                    );
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option == "object")
                      return option.name + " " + option.offset;
                    return option;
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (option != null) {
                      if (option.name == value) return true;
                      else return false;
                    }
                  }}
                  id={"autocomplete"}
                  variant={"outlined"}
                />
              </Grid>
            )}
            {type === 3 &&
              <Grid
                xs={12}
                container
                item
                direction={"row"}
                style={{ padding: "16px" }}
                alignItems={"center"}
                spacing={2}
              >
                <Grid item xs={7}>
                  <ChipInput
                    placeholders={this.props.availablePlaceholders}
                    placeholder={"Insert datetime placeholder"}
                    inputValue={this.state.atDatetimePlaceholder}
                    size={"small"}
                    fullWidth={true}
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    onDelete={(index) => {
                      this.state.atDatetimePlaceholder.splice(index, 1);
                      TimeDelayStore.setData(this.state.atDatetimePlaceholder, "atDatetimePlaceholder");
                    }}
                    onFocus={(event) => {
                      this.setState({ focus: "atDatetimePlaceholder" });
                    }}
                  />
                </Grid>
              </Grid>
            }
          </Paper>
        </Grid>
      );
    else return <IndividualComponentLoader />;
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(TimeDelayTemplate));
