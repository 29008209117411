import { Skeleton } from "@mui/material";
import React from "react";
import User from "../../../pages/Canvas/AIBuilder/components/Chat/Messages/User";
import { useAIOnboardingContext } from "../OnboardingContextProvider";

function ChoiceMadeLoadingMessage(props) {
    const { workflowDescription } = useAIOnboardingContext();
    return <User content={workflowDescription ? workflowDescription : <Skeleton className={props.classes.styledSkeleton} variant="rectangular" width="350px" height="25px" />} />;
}

export default ChoiceMadeLoadingMessage;
