import React from "react";
import { Chip } from "@mui/material";
import { PropTypes } from "mobx-react";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CancelOutlined,
  ErrorOutline,
  MailOutline,
  PersonOutline,
  OutlinedFlag
} from "@mui/icons-material";
import { hexToPastel } from "../../utils/HexToPastelUtil";

const ERROR_COLOUR = "#E10000";
const TODO_COLOUR = "#FFA500";
const SUCCESS_COLOUR = "#4CAF50";
const TERMINATED_COLOUR = "#9E9E9E";
const DEFAULT_EMAIL_STATUS_COLOUR = "#2196F3";
const DEFAULT_USERTASK_COLOUR = "#9C27B0";

const TaskChip = (props) => {
  const isEmail = (value) => {
    var reg = /\S+@\S+\.\S+/;
    return reg.test(value);
  };

  const getChipStyle = (color, fontWeight = 'normal') => ({
    backgroundColor: hexToPastel(color),
    color: 'rgb(0, 0, 0)', // Change text color to black for better contrast with pastel background
    fontWeight: fontWeight,
    border: `1px solid ${color}`, // Add this line to create a border with the original color
  });

  const getChipSx = (color) => ({
    fontSize: '13px',
    '& .MuiChip-label': {
      fontSize: '13px',
    },
  });

  const getIconStyle = (color) => ({ color: color });

  const getChipIcon = (status, color) => {
    switch (status) {
      case "TODO": return <CheckBoxOutlineBlank style={getIconStyle(color)} />;
      case "DONE": return <CheckBoxOutlined style={getIconStyle(color)} />;
      case "OVERDUE": return <OutlinedFlag style={getIconStyle(color)} />;
      case "TERMINATED": return <CancelOutlined style={getIconStyle(color)} />;
      case "ERROR": return <ErrorOutline style={getIconStyle(color)} />;
      default: return isEmail(status) ? <MailOutline style={getIconStyle(color)} /> : <PersonOutline style={getIconStyle(color)} />;
    }
  };

  const overDue = props.overDue;
  switch (props.value) {
    case "TODO":
      if (props.extras.toDoColor != null) {
        const color = overDue ? ERROR_COLOUR : props.extras.toDoColor;
        return (
          <Chip
            className={"taskChip warningTask"}
            style={getChipStyle(color)}
            sx={getChipSx(color)}
            label={
              props.extras.toDoLabel != null
                ?
                overDue ? "(Overdue) "+ props.extras.toDoLabel : props.extras.toDoLabel
                :
                overDue ? "(Overdue) "+ "To do" : "To do"
            }
            icon={getChipIcon(overDue ? "OVERDUE" : "TODO", color)}
          />
        );
      } else return <Chip className={"taskChip warningTask"} style={getChipStyle(TODO_COLOUR)} sx={getChipSx(TODO_COLOUR)} label="To do" icon={getChipIcon("TODO", TODO_COLOUR)} />;
    case "DONE":
      if (props.extras.completedColor) {
        return (
          <Chip
            className={"taskChip warningTask"}
            style={getChipStyle(props.extras.completedColor)}
            sx={getChipSx(props.extras.completedColor)}
            label={props.extras.completedLabel != null ? props.extras.completedLabel : "Completed" }
            icon={getChipIcon("DONE", props.extras.completedColor)}
          />
        );
      } else return <Chip className={"taskChip successTask"} style={getChipStyle(SUCCESS_COLOUR)} sx={getChipSx(SUCCESS_COLOUR)} label="Done" icon={getChipIcon("DONE", SUCCESS_COLOUR)} />;
    case "OVERDUE":
      return <Chip className={"taskChip errorTask"} style={getChipStyle(ERROR_COLOUR)} sx={getChipSx(ERROR_COLOUR)} label="Overdue" icon={getChipIcon("OVERDUE", ERROR_COLOUR)} />;
    case "TERMINATED":
      return (
        <Chip
          className={"taskChip defaultTask"}
          style={getChipStyle(TERMINATED_COLOUR, 'normal')}
          sx={getChipSx(TERMINATED_COLOUR)}
          label="Terminated"
          icon={getChipIcon("TERMINATED", TERMINATED_COLOUR)}
        />
      );
    case "ERROR":
      return <Chip className={"taskChip errorTask"} style={getChipStyle(ERROR_COLOUR)} sx={getChipSx(ERROR_COLOUR)} label="Error" icon={getChipIcon("ERROR", ERROR_COLOUR)} />;
    default:
      if (isEmail(props.value)) {
        return <Chip className={"taskChip"} style={getChipStyle(DEFAULT_EMAIL_STATUS_COLOUR)} sx={getChipSx(DEFAULT_EMAIL_STATUS_COLOUR)} label={props.value} icon={getChipIcon(props.value, DEFAULT_EMAIL_STATUS_COLOUR)} />;
      } else {
        return <Chip className={"taskChip userTask"} style={getChipStyle(DEFAULT_USERTASK_COLOUR)} sx={getChipSx(DEFAULT_USERTASK_COLOUR)} label={props.value} icon={getChipIcon(props.value, DEFAULT_USERTASK_COLOUR)} />;
      }
  }
};

TaskChip.propTypes = {
  value: PropTypes.string,
};

export default TaskChip;