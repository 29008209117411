import React from "react";

// MUI
import { Grid } from "@mui/material";
import { Arrow, CustomGrid, Error, ReplacedItem } from "./RenameStyledComponents";

function ShowPHReplace({ renamedValue, selectedPH, hasPrefix, isSuccess }) {
    const processNewKey = () => {
        let oldKey = selectedPH.key;
        let newKey = [];

        let split = oldKey.split(".");
        if (split.length <= 1) return renamedValue;

        for (let i = 0; i < split.length; i++) {
            if (!hasPrefix && i === 0) newKey.push(renamedValue);
            else if (hasPrefix && i === 1) newKey.push(renamedValue);
            else newKey.push(split[i]);
        }

        return newKey.join(".");
    };

    return (
        selectedPH && (
            <Grid container margin="8px 0px 14px 0px" rowSpacing={2}>
                <CustomGrid item xs={12}>
                    <Grid container>
                        <ReplacedItem item xs={12} showLine={isSuccess}>
                            {`\$\{${selectedPH.key}\}`}
                        </ReplacedItem>
                    </Grid>
                </CustomGrid>
                <CustomGrid item xs={12} horizontal="center" vertical="center">
                    {isSuccess ? <Arrow /> : <Error />}
                </CustomGrid>
                <CustomGrid item xs={12}>
                    <Grid container>
                        <ReplacedItem item xs={12}>
                            {`\$\{${processNewKey(renamedValue)}\}`}
                        </ReplacedItem>
                    </Grid>
                </CustomGrid>
            </Grid>
        )
    );
}

export default ShowPHReplace;
