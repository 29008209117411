import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Button from "@mui/material/Button";
export default class SavedWithErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBack = () => {
    if (this.props.onBack) this.props.closeAndExit();
    else this.props.close();
  };
  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>
          <h5>
            {" "}
            <CheckCircleIcon className="check-icon" />
            Your changes have been saved
          </h5>
        </DialogTitle>
        <DialogContent>
          <p>
            Your changes have been saved, but we found some errors in the
            component:
          </p>
          {this.props.errors.map((error, index) => {
            return (
              <p key={index} className={"error-text"}>
                {error}
              </p>
            );
          })}
          <p>
            You can still save and exit this component, but it will be marked as
            having an error. This means the workflow may not function properly.{" "}
          </p>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} color={"primary"}  onClick={this.handleBack}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
