import React, { useState, useEffect } from "react";

import { TextField } from "@mui/material";

function DefaultInput({ pH, values, setValues }) {
    const [val, setVal] = useState(values[pH.key].value);

    useEffect(() => {
        setVal(values[pH.key].value);
    }, [values[pH.key].value]);

    return (
        <TextField
            value={val}
            fullWidth
            placeholder="Enter value"
            variant="outlined"
            size="small"
            multiline
            maxRows={10} // Maximum number of rows before scrolling
            onChange={(e) => {
                if (pH.type === "number" && e.target.value !== "" && isNaN(e.target.value)) return;
                setVal(e.target.value);
            }}
            onBlur={() => {
                let newVal = { ...values };
                let type = newVal[pH.key].type;

                newVal[pH.key] = {
                    type: type,
                    value: val,
                };

                setValues(newVal);
            }}
        />
    );
}

export default DefaultInput;
