import React from "react";
import TextField from "@mui/material/TextField";
import Error from "@mui/icons-material/Error";
import { makeStyles } from "@mui/styles";

const StandardTextField = (props) => {
  const useStyles = makeStyles({
    textroot: {
      width: "100%",
      margin: "auto"
    },
    input: {
      fontWeight: "normal",
      fontFamily: "Open Sans",
      fontSize: props.fontSize || 14,
      color: props.Error ? "#B00020" : ""
    },
    root: {
      "& label.Mui-focused": {},
      "& .MuiInput-underline:after": {
        borderBottomColor: props.Theme == "red" ? "#E10050" : "#2196F3"
      },
      "& .MuiOutlinedInput-root": {
        "& input": {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 24
        },
        "& fieldset": {
          borderColor: props.Error ? "#B00020" : ""
        },
        "&:hover fieldset": {
          borderColor: props.Error
            ? "#B00020"
            : props.Theme == "red"
            ? "#E10050"
            : "#2196F3"
        },
        "&.Mui-focused fieldset": {
          borderColor: props.Error
            ? "#B00020"
            : props.Theme == "red"
            ? "#E10050"
            : "#2196F3"
        }
      }
    }
  });
  const tsProps = (() => {
    let tsVariant;
    switch (props.variant) {
      case "outlined": {
        tsVariant = { variant: props.variant };
        break;
      }
      case "filled": {
        tsVariant = { variant: "filled" };
        break;
      }
      case "standard":
      default: {
        tsVariant = { variant: "standard" };
        break;
      }
    }
    const p = props;
    // delete p['variant'];
    return { ...p, ...tsVariant };
  })();
  const classes = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <p style={{ fontSize: 13, margin: 0 }}>{props.Label}</p>
      <TextField
        {...tsProps}
        InputProps={{
          endAdornment: props.Error ? <Error /> : props.Button,
          classes: { root: classes.input }
        }}
        classes={{ root: classes.root }}
        className={classes.textroot}
      />
    </div>
  );
};

export default StandardTextField;
