import React from "react";
import Grid from "@mui/material/Grid";
import AIBuilderStyles from "../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import AIPanelInitialMessage from "./Chat/Messages/AIPanelInitialMessage";
import PropTypes from "prop-types";

const styles = AIBuilderStyles;

// Default chat state for the AI panel
const InitialChatState = ({
  username,
  classes,
  setState,
  setSidebarState,
  editComponent,
  isEmpty,
  setShowBuilderWarning
}) => {
  return (
    <>
      <Grid
        container
        direction={"column"}
        alignItems={"center"}
        className={classes.chatHeader}
      >
        <span>
          <b>Start new session</b>
        </span>
      </Grid>

      <Grid item padding="16px">
        <AIPanelInitialMessage
          username={username}
          setState={setState}
          setSidebarState={setSidebarState}
          editComponent={editComponent}
          isEmpty={isEmpty}
          setShowBuilderWarning={setShowBuilderWarning}
        />
      </Grid>
    </>
  );
};

InitialChatState.propTypes = {
  username: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  editComponent: PropTypes.object,
};

export default withStyles(styles)(InitialChatState);
