import React, { useState } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";

// MUI
import { withStyles } from "@mui/styles";
import TableStyles from "./TableStyles";

// Custom
import TableInputs from "./TableInput/TableInputs";
import TablePreview from "./TablePreview";
import TableInputPreview from "./TableInputPreview/TableInputPreview";
import { generateRowLabels, replaceArrItem } from "./TableHelpers";

const styles = TableStyles;

function Table(props) {
  const {
    classes,
    setNumRows,
    columns,
    setColumns,
    data,
    setRowLabels
  } = props;

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = errorMsg => {
    setError(true);
    setErrorMessage(errorMsg);
  };

  const closeError = () => {
    setError(false);
    setErrorMessage("");
  };

  // Handles changing a columns type
  const handleChangeColumnValue = (id, value, keyChanged) => {
    if (!keyChanged) return;
    
    var index = columns.findIndex(c => c.id === id);
    if (index === -1) return;

    let updatedParams = {
      ...columns[index]
    };

    updatedParams[keyChanged] = value;

    setColumns(replaceArrItem(columns, index, updatedParams));
  };

  // Handles adding or removing a column
  const handleAddOrRemoveColumn = (id, isAdding) => {
    if (isAdding) {
      if (columns.length > 26) return; // Don't allow more than 26 columns
      var index = columns.findIndex(c => c.id === id);
      if (index === -1) return;

      let colId = uuid();

      let newCol = {
        id: colId,
        type: "text",
        columnName: `Column ${columns.length + 1}`,
        options: [
          {
            id: uuid(),
            value: "",
            label: ""
          }
        ],
        dtFormat: {
          outputFormat: "dd/MM/YYYY kk:mm",
          timezone: "GMT+10"
        },
        required: false
      };

      // Change columns
      setColumns([
        ...columns.slice(0, index + 1),
        newCol,
        ...columns.slice(index + 1)
      ]);

      if (props.tableCells != null) {
        let newCells = { ...props.tableCells };

        let resArr = [];

        for (let i = 0; i < parseInt(props.numRows); i++) {
          resArr.push({
            id: `row${i + 1}`,
            isRequired: false,
            isDisabled: false,
            isReadOnly: false,
            defaultValue: "",
            rawValue: ""
          });
        }

        newCells[colId] = resArr;
        props.setTableCells(newCells);
        closeError();
      }
    } else {
      if (columns.length < 2) {
        handleError("You must have at least 1 column");
        return;
      }
      // Is removing a column
      let result = columns;
      result = result.filter(c => c.id != id);
      setColumns(result);

      if (props.tableCells != null) {
        // Remove cells
        let newCells = { ...props.tableCells };
        delete newCells[id];
        props.setTableCells(newCells);

        closeError();
      }
    }
  };

  // Handles changing an options value/label
  const handleChangeOptionValue = (columnId, optionId, value) => {
    var index = columns.findIndex(c => c.id === columnId);
    if (index === -1) return;

    let currColumn = columns[index];

    let optionIndex = currColumn.options.findIndex(opt => opt.id === optionId);
    if (optionIndex === -1) return;

    let updatedOptionValue = {
      ...currColumn.options[optionIndex],
      value: value,
      label: value // Set the label to the value for now
    };

    let newOptions = [
      ...currColumn.options.slice(0, optionIndex),
      updatedOptionValue,
      ...currColumn.options.slice(optionIndex + 1)
    ];

    let newCol = {
      ...currColumn,
      options: newOptions
    };

    setColumns(replaceArrItem(columns, index, newCol));
    closeError();
  };

  // Handles adding or removing an option for a dropdown
  const handleAddOrRemoveOption = (columnId, optionId, isAdding) => {
    var index = columns.findIndex(c => c.id === columnId);

    if (index === -1) return;

    let currColumn = columns[index];

    if (isAdding) {
      let newOption = {
        id: uuid(),
        value: ""
      };

      let newOptions = {
        ...currColumn,
        options: [...currColumn.options, newOption]
      };

      setColumns(replaceArrItem(columns, index, newOptions));
      closeError();
    } else {
      let updatedOptions = currColumn.options.filter(opt => opt.id != optionId);

      if (updatedOptions.length === 0) {
        handleError("You must have at least 1 option");
        return;
      }

      let newOptions = {
        ...currColumn,
        options: updatedOptions
      };

      setColumns(replaceArrItem(columns, index, newOptions));
    }
  };

  // Handles changing the column required
  const handleChangeRequired = id => {
    var index = columns.findIndex(c => c.id === id);
    if (index === -1) return;

    let updatedParams = {
      ...columns[index],
      required: !columns[index].required
    };

    setColumns(replaceArrItem(columns, index, updatedParams));
  };

  // Handle changing the number of rows
  const handleChangeNumRows = value => {
    if (props.tableCells != null) {
      // If adding, appending all new tableCells
      if (parseInt(value) > parseInt(props.numRows)) {
        let newTableCells = { ...props.tableCells };

        let arrSize = parseInt(value) - parseInt(props.numRows);

        for (let i = 0; i < Object.keys(props.tableCells).length; i++) {
          const currKey = Object.keys(props.tableCells)[i];

          let newVal = [...newTableCells[currKey]]; // Spread array

          for (let j = 0; j < arrSize; j++) {
            newVal.push({
              id: `row${j + 1 + props.numRows}`,
              isRequired: false,
              isDisabled: false,
              isReadOnly: false,
              defaultValue: "",
              rawValue: ""
            });
          }

          newTableCells[currKey] = newVal;
        }

        props.setTableCells(newTableCells);
      } else {
        // Handle deleting tablecells
        let amountToRemove = parseInt(props.numRows) - parseInt(value);
        let newTableCells = { ...props.tableCells };

        for (let key of Object.keys(props.tableCells)) {
          newTableCells[key] = newTableCells[key].slice(
            0,
            newTableCells[key].length - amountToRemove
          );
        }
        props.setTableCells(newTableCells);
      }
    }

    props.setNumRows(parseInt(value)); // Set new number of rows
    setRowLabels(generateRowLabels(value));
  };

  // Handle changing the date time format for a column
  const handleChangeDtFormat = (id, val, isChangingFormat) => {
    var index = columns.findIndex(c => c.id === id);
    if (index === -1) return;

    let currCol = columns[index];

    let newDtFormat = {
      ...currCol.dtFormat
    };

    if (isChangingFormat) newDtFormat.outputFormat = val;
    else newDtFormat.timezone = val;

    let newCol = {
      ...currCol,
      dtFormat: newDtFormat
    };

    setColumns(replaceArrItem(columns, index, newCol));
    closeError();
  };

  // Input functions grouped into an object
  const inputFunctions = {
    handleAddOrRemoveColumn: handleAddOrRemoveColumn,
    handleAddOrRemoveOption: handleAddOrRemoveOption,
    handleChangeColumnValue: handleChangeColumnValue,
    handleChangeNumRows: handleChangeNumRows,
    handleChangeRequired: handleChangeRequired,
    handleChangeOptionValue: handleChangeOptionValue,
    handleChangeDtFormat: handleChangeDtFormat
  };

  return props.preview ? (
    <TablePreview data={data} />
  ) : (
    <div className={classes.root}>
      <TableInputs {...props} {...inputFunctions} />
      {props.tableCells != null && (
        <TableInputPreview
          {...props}
          availablePlaceholders={props.availablePlaceholders}
          tableCells={props.tableCells}
        />
      )}
    </div>
  );
}

Table.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Table);
