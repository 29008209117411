import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { Grid, Skeleton } from "@mui/material";
import { send_request } from "../../utils/Request";
import { format } from "../../utils/Date";

import { styled } from "@mui/system";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import projectStore from "../ProjectCanvas/ProjectStore";

const Container = styled("div")({
    marginBottom: "16px",
    fontSize: "14px",
});

const TextContainer = styled(Grid)({
    margin: "12px 0px",
});

const TimesOpenedContainer = styled(Grid)({});

const CountText = styled("span")({
    fontWeight: "bold",
});

const NotOpenedIcon = styled(VisibilityOffIcon)({
    height: "20px",
    width: "auto",
    marginRight: "8px",
});

const UpgradeIcon = styled(ErrorIcon)({
    height: "20px",
    width: "auto",
    marginRight: "16px",
    color: "#FFB74D",
});

const DoneIcon = styled(CheckIcon)({
    height: "20px",
    width: "auto",
    marginRight: "8px",
    color: "#55A77A",
});

const UpgradeContainer = styled(Grid)({
    background: "#FFEACB",
    border: "1px solid #FFB74D",
    padding: "8px 14px",
    borderRadius: "4px",
    margin: "8px 0px",
});

const ContentContainer = styled(Grid)({
    gap: "4px",
    marginTop: "12px",
});

const StyledLink = styled(Link)({
    marginLeft: "4px",
    color: "#2196f3",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33,150,243, 0.88)",
    },
});

const SeeMoreText = styled("span")({
    color: "#2196f3",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33,150,243, 0.88)",
    },
    textDecoration: "underline",
});

const SeeMoreContainer = styled(Grid)({
    gap: "4px",
    marginTop: "8px",
});

const LoadItem = styled(Grid)({
    fontStyle: "italic",
    marginBottom: "4px",
});

function TaskOpens({ taskId }) {
    const [loads, setLoads] = useState(null);
    const [clientTier, setClientTier] = useState(null);
    const [openSeeMore, setOpenSeeMore] = useState(false);

    useEffect(() => {
        // Get client tier
        send_request(`authz-service/user-info`, "", {})
            .then((res) => {
                setClientTier(res.data.client.tier);
                // Make request to get opens
                let url = `activity/get_task_loads/${taskId}`;

                send_request(url, "", null, "get")
                    .then((res) => {
                        setLoads(res.data);
                    })
                    .catch((e) => {
                        console.log(e);
                        setLoads([]);
                    });
            })
            .catch((e) => console.log(e));
    }, [taskId]);

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const checkIfFreeTier = (tier) => {
        if (tier === "FREE" || tier === "STARTER") return true;
        return false;
    };

    const getOpensDialog = () => {
        // If the clients tier is free, return upgrade block
        if (checkIfFreeTier(clientTier)) return getUpgradeContent();

        // Else, get other content
        if (loads.length === 0) return getNoLoadsContent();
        else return getManyLoadedContent();
    };

    const getUpgradeContent = () => {
        return (
            <ContentContainer container>
                <Grid item xs={12} display={"flex"} alignItems={"center"} marginBottom={"12px"}>
                    <DoneIcon />
                    First opened ... days ago on ... by ...
                </Grid>
                <TimesOpenedContainer item xs={12}>
                    This task has been opened ... times
                </TimesOpenedContainer>
                <Grid item xs={12} marginTop={"8px"}>
                    <SeeMoreText>See more stats</SeeMoreText>
                </Grid>
                <UpgradeContainer item xs={12} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <UpgradeIcon /> Track when this task is opened by <StyledLink to="/set_plan">upgrading to a Professional Plan or higher</StyledLink>
                </UpgradeContainer>
            </ContentContainer>
        );
    };

    const getNoLoadsContent = () => {
        return (
            <Grid container>
                <TextContainer item xs={12} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <NotOpenedIcon /> Task has not been opened yet
                </TextContainer>
                <TimesOpenedContainer item xs={12}>
                    This task has been opened <CountText>{loads.length}</CountText> times
                </TimesOpenedContainer>
            </Grid>
        );
    };

    const getManyLoadedContent = () => {
        return (
            <ContentContainer container>
                <Grid item xs={12} display={"flex"} alignItems={"center"} marginBottom={"12px"}>
                    <DoneIcon />
                    First {getItemText(loads[0])}
                </Grid>
                <TimesOpenedContainer item xs={12}>
                    This task has been opened <CountText>{loads.length}</CountText> times
                </TimesOpenedContainer>
                {loads.length > 1 && (
                    <>
                        <Grid item xs={12} marginTop={"8px"}>
                            <SeeMoreText onClick={() => setOpenSeeMore(!openSeeMore)}>{!openSeeMore ? "See more stats" : "Hide stats"}</SeeMoreText>
                        </Grid>
                        {openSeeMore && (
                            <SeeMoreContainer container>
                                {loads
                                    .slice(1)
                                    .reverse()
                                    .map((item, idx) => {
                                        return (
                                            <LoadItem item xs={12} id={idx}>
                                                {capitalize(getItemText(item))}
                                            </LoadItem>
                                        );
                                    })}
                            </SeeMoreContainer>
                        )}
                    </>
                )}
            </ContentContainer>
        );
    };

    const getItemText = (item) => {
        let timestamp = `${item.dateTime}Z[UTC]`;

        let user = item.username;
        let timeAgo = format(timestamp, projectStore.state.timezone, "relative"); // 3 days ago
        let formattedDate = format(timestamp, projectStore.state.timezone); // 12 March 2022, 12:00PM
        return `opened ${timeAgo} on ${formattedDate} by ${user}`;
    };

    const showLoader = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Skeleton width={"50%"} height={40} />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton width={"50%"} height={25} />
                </Grid>
            </Grid>
        );
    };

    return (
        <Container>
            <p className={"s-text m-0"}>Opens</p>
            {loads ? getOpensDialog() : showLoader()}
        </Container>
    );
}

export default TaskOpens;
