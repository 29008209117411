import React, { useState, useEffect } from "react";

// mui
import { styled } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

// util
import { send_request } from "../../../../../utils/Request";
import { StyledSkeleton } from "../../RenamePlaceholderLogic/RenameStyledComponents";

// endpoint url(s)
const AI_QUOTA_API_ENDPOINT = "authz-service/get_remaining_ai_quota";

// styled components
const ResponseText = styled("div")(({ isLow }) => ({
    width: "fit-content",
    color: isLow ? "rgba(255, 0, 0, 0.73)" : "rgba(148, 75, 168, 0.73)",
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: 400,
    marginTop: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
}));

const StarsIcon = () => (
    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.0909 5.81818L12.1818 3.81818L10.1818 2.90909L12.1818 2L13.0909 0L14 2L16 2.90909L14 3.81818L13.0909 5.81818ZM13.0909 16L12.1818 14L10.1818 13.0909L12.1818 12.1818L13.0909 10.1818L14 12.1818L16 13.0909L14 14L13.0909 16ZM5.81818 13.8182L4 9.81818L0 8L4 6.18182L5.81818 2.18182L7.63636 6.18182L11.6364 8L7.63636 9.81818L5.81818 13.8182Z"
            fill="rgba(148, 75, 168, 1)"
        />
    </svg>
);

function AIQuotaRemaining() {
    // state
    const [loading, setLoading] = useState(false);
    const [quota, setQuota] = useState(null);

    // variables
    const showSkeleton = loading || !quota;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await send_request(AI_QUOTA_API_ENDPOINT, "", null, "GET");
                setQuota(res.data);
            } catch (error) {
                console.error("Failed to fetch quota:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const remainingRequests = (!showSkeleton && quota && quota.requests > 0) ? quota.requests : 0;
    const isLow = !showSkeleton && remainingRequests <= 10;

    return (
        <ResponseText isLow={isLow}>
            {showSkeleton ? (
                <StarsIcon />
            ) : (
                isLow ? <WarningIcon fontSize="small" /> : <StarsIcon />
            )}
            {showSkeleton ? (
                <StyledSkeleton variant="rectangular" height="14px" width="20px" />
            ) : (
                `${remainingRequests} AI responses remaining`
            )}
        </ResponseText>
    );
}

export default AIQuotaRemaining;