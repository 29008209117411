import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {MenuItem, Paper, Select} from "@mui/material";
import {reaction, toJS} from "mobx";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import {IndividualComponentLoader} from "../../utils/ComponentLoadingSkeleton";
import Switch from "@mui/material/Switch";
import AIPlaceholderItem from "./AIPlaceholderItem";
import aiAssistantTemplateStore from "./AIAssistantTemplateStore";
import {getCurrentTime} from "../../utils/getCurrentTime";
import {send_component_save_request, send_request} from "../../utils/Request";
import projectStore from "../ProjectCanvas/ProjectStore";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormMentionEditor from "../Component/FormMentionEditor";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {withStyles} from "@mui/styles";
import styles from "./AIAssistantStyles";
import TextFieldMUI from "@mui/material/TextField";
import {Image, InsertPhotoOutlined} from "@mui/icons-material";
import Email from "../Component/Email";
import EmailStore from "../Email/EmailStore";
import ChipInput from "../ChipInput/ChipInput";
import CodeStore from "../Code/CodeStore";
import AIAssistantTemplateStore from "./AIAssistantTemplateStore";
import {validURL} from "../../utils/CanvasUtil";
import ImageInputComponent from "./ImageInputComponent";

const openAIModels = ["gpt-3.5-turbo", "gpt-4-turbo", "gpt-4-turbo-vision", "gpt-4o", "gpt-4o-mini"];

const defaultModel = "gpt-4o-mini";

class AIAssistantTemplate extends Component {

    state = {
        error: false, loading: false, availablePlaceholders: [], description: null, status: null, showCollapse: true
    }
    saveSessionTemplate = reaction(() => this.props.SaveTrigger.triggerValue, () => {

        if (this.state.error) {
            this.props.showError();
            return;
        }

        let lastModified = getCurrentTime();
        let data = {
            componentData: {
                data: aiAssistantTemplateStore.template.data,
                name: toJS(this.props.ComponentName),
                lastModified: lastModified,
            },
            componentId: this.props.component_id,
            type: aiAssistantTemplateStore.template.type,
            description: this.props.ComponentDescription.value,
            status: this.state.status
        };
        send_component_save_request("component-service/ai-assistant/data", data, "", "POST", this.props.SaveTrigger)
            .then((response) => {
                this.props.onLastModifiedChanged(lastModified);
                this.props.showSuccess(data, this.props.SaveTrigger);
                projectStore.savedComponent = true;
            })
            .catch((err) => {
                throw err;
            });
    });

    componentDidMount() {
        if (this.props.component_id !== undefined) {
            this.setState({loading: true})
            send_request(`project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`, "", {})
                .then((response) => {
                    if (response && response.data) {
                        const {components} = response.data;
                        if (components) {
                            aiAssistantTemplateStore.setAiAssistantVariables(components[0]);
                            this.props.onComponentDescriptionChanged(components[0].description);
                            this.props.onComponentNameChanged(aiAssistantTemplateStore.name);
                            this.props.onLastModifiedChanged(aiAssistantTemplateStore.lastModified);
                        }
                    }

                    // Check if aiComponentBuilder is given and if so, set the draft data
                    if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
                        this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
                        this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
                        aiAssistantTemplateStore.setTemplateData(this.props.aiComponentBuilderData.data);
                        this.setState({description: this.props.aiComponentBuilderData["explanation"]});
                        this.setState({status: "AI_BUILD_GENERATED"});
                    }

                    this.setState({loading: false})
                    this.props.setChildTemplateLoaded(true);
                })
                .catch((err) => {
                    throw Error(err.message);
                });
        }

    }

    addEmptyPlaceholderValue = (isExplanation) => {

        let {outputPlaceholders} = toJS(aiAssistantTemplateStore.data.aiAssistantData);
        const newPlaceholder = {
            desc: isExplanation ? "explanation of how the task was completed" : "",
            key: this.keyAuto(isExplanation),
            type: "text",
            isExplanation: isExplanation
        }
        if (!isExplanation) {
            outputPlaceholders.push(newPlaceholder);
        } else {
            outputPlaceholders.unshift(newPlaceholder);
        }
        aiAssistantTemplateStore.setAIOutputPlaceholders(outputPlaceholders);

    };

    keyAuto = (isExplanation) => {
        let startString = isExplanation ? "ai_explanation" : "ai_assistant";
        return (startString + (Math.floor(Math.random() * 90000000) + 10000).toString());
    };

    deletePlaceholders = (index) => {
        if (index !== -1) {
            let {outputPlaceholders} = toJS(aiAssistantTemplateStore.data.aiAssistantData);
            outputPlaceholders.splice(index, 1);
            aiAssistantTemplateStore.setAIOutputPlaceholders(outputPlaceholders);
        }
    };

    componentWillUnmount() {
        // Clear the mobX reactions
        this.saveSessionTemplate();
    }

    addChip = (text,isNotPlaceholder) => {
        if (text && text.trim() !== "") {
            if (isNotPlaceholder !== true) {
                text = "${" + text + "}";
            } else if (!validURL(text))
                return;
            AIAssistantTemplateStore.setImageContext(text);
        }
    };
    reactToPlaceHolder = reaction(
        () => toJS(this.props.SelectedPlaceholder),
        (placeholder, reaction) => {
            if (placeholder["selected"] === undefined) {
                return;
            }
            const { key, isNotPlaceholder } = placeholder["selected"];
            this.addChip(
                key,
                isNotPlaceholder
            );


        }
    )


    render() {
        const {availablePlaceholders, classes} = this.props;

        let {data} = aiAssistantTemplateStore;
        let aiAssistantData = toJS(data.aiAssistantData);

        const placeholderTypeMap = ["Text", "Number", "List", "DateTime", "HTML"]
        if (this.state && !this.state.loading && aiAssistantData) {

            return (

                <Grid style={styles.grid}>

                    <Paper>
                        <Accordion style={styles.accordion} defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={styles.accordionSummary}>
                                <span className={classes.b}>

                                AI Settings

                                </span>

                            </AccordionSummary>
                            <AccordionDetails>

                                <Grid container className={classes.gridContainerPaddings}>

                                    <Grid item xs={12} paddingBottom={"16px"}>
                                <span>
                                    LLM Model
                                </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            className="dropdown-mat selectStandard item-select-bulk find-out-by-select"
                                            style={{background: "white", color: "black", width: "50%"}}
                                            variant="outlined"
                                            defaultValue={defaultModel}
                                            value={openAIModels.includes(aiAssistantData.gptModel) ? aiAssistantData.gptModel : "gpt-4o-mini"}
                                            onChange={(event) => {
                                                aiAssistantTemplateStore.setGptModel(event.target.value);
                                                aiAssistantTemplateStore.clearImageContext();
                                            }}
                                        >
                                            <MenuItem value={"gpt-4o-mini"}>GPT-4o-mini (Default)</MenuItem>
                                            <MenuItem value={"gpt-3.5-turbo"}>GPT-3.5 Turbo</MenuItem>
                                            <MenuItem value={"gpt-4-turbo"}>GPT-4 Turbo</MenuItem>
                                            <MenuItem value={"gpt-4-turbo-vision"}>GPT-4 Vision</MenuItem>
                                            <MenuItem value={"gpt-4o"}>GPT-4o</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item className={classes.gridItemPadding}>
                                        <Grid item xs={12} paddingBottom={"16px"}>


                                    <span>
                                        <b>Job Description</b>
                                    </span>

                                        </Grid>
                                        <span>
                                    Describe the job or task the AI is to perform
                                </span>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <FormMentionEditor
                                            availablePlaceholders={availablePlaceholders}
                                            text={aiAssistantData.task}
                                            onChange={(html) => aiAssistantTemplateStore.setTask(html)}
                                            placeholder={""}
                                            style={{
                                                width: "100%",
                                                marginBottom: 16,
                                                verticalAlign: "top",
                                                minHeight: "16px",
                                                fontSize: "14px"
                                            }}
                                            onFocus={(e) => {
                                            }}
                                            aiAssistant={true}

                                            contentBlock={false}
                                            hideScrollBar={true}
                                            assignTask={true}
                                            {...(availablePlaceholders != null && {
                                                availablePlaceholders: availablePlaceholders[0].placeholders,
                                            })}
                                        />
                                    </Grid>
                                    <Grid item paddingBottom={"16px"} paddingTop={"16px"}>
                                        <Grid item xs={12} paddingBottom={"16px"}>

                                    <span>
                                        <b>Input/context</b>
                                    </span>

                                        </Grid>
                                        <Grid container alignItems={"center"} >
                                            <TextFieldsIcon/>
                                            <span>
                                                <b> Text input</b>
                                            </span>
                                        </Grid>
                                        <Grid className={classes.gridItemPadding}>
                                            <span>
                                                (Optional) Add any input for the AI to use when performing its job or task
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <FormMentionEditor
                                            availablePlaceholders={availablePlaceholders}
                                            text={aiAssistantData.context}
                                            onChange={(html) => aiAssistantTemplateStore.setContext(html)}
                                            placeholder={""}
                                            style={{
                                                width: "100%",
                                                marginBottom: 16,
                                                verticalAlign: "top",
                                                minHeight: "16px",
                                                fontSize: "14px"

                                            }}
                                            onFocus={(e) => {
                                            }}
                                            aiAssistant={true}

                                            contentBlock={false}
                                            hideScrollBar={true}
                                            assignTask={true}
                                            {...(availablePlaceholders != null && {
                                                availablePlaceholders: availablePlaceholders[0].placeholders,
                                            })}
                                        />

                                    </Grid>
                                    {(aiAssistantData.gptModel==="gpt-4-turbo-vision" || aiAssistantData.gptModel === "gpt-4o" || aiAssistantData.gptModel === "gpt-4o-mini") &&
                                        <ImageInputComponent
                                        aiAssistantData={aiAssistantData}
                                        onPlaceholderSelected={this.props.onPlaceholderSelected}
                                        availablePlaceholders={this.props.availablePlaceholders}
                                        classes={classes}
                                        />}
                                    <Grid item className={classes.gridItemPadding}>
                                        <Grid item xs={12} paddingBottom={"16px"}>

                                <span>
                                    <b>Output</b>
                                </span>

                                        </Grid>
                                        <span>
                                    Specify what outputs you want from the AI
                                </span>
                                    </Grid>

                                    <Grid item xs={12} style={{paddingBottom: "10px"}}>
                                        <Grid container direction={"row"} wrap={"nowrap"} alignItems={"center"}>

                                            <Switch checked={aiAssistantData.explanation} onChange={(e) => {
                                                aiAssistantTemplateStore.setExplanation(e.target.checked);
                                            }}></Switch>
                                            <span>
                                    Include explanation
                                </span>

                                        </Grid>
                                    </Grid>


                                    {(aiAssistantData.explanation ? (aiAssistantData.outputPlaceholders.find((data) => (data.isExplanation === true)) === undefined) && this.addEmptyPlaceholderValue(true) : this.deletePlaceholders(aiAssistantData.outputPlaceholders.findIndex((data) => (data.isExplanation === true))))}
                                    <Grid container item direction={"column"}>
                                        {aiAssistantData.outputPlaceholders && aiAssistantData.outputPlaceholders.map((aData, index) => (
                                            <AIPlaceholderItem placeholderTypeMap={placeholderTypeMap}
                                                               index={index}
                                                               classes={classes}
                                                               component={"ai_assistant_record"}
                                                               aData={aData}
                                                               aiAssistantData={aiAssistantData}
                                                               availablePlaceholders={availablePlaceholders}
                                                               setAIPlaceholder={aiAssistantTemplateStore.setAIOutputPlaceholders}
                                            />))}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div

                                        >
                                            <div
                                                className="query-database-add-data"

                                                onClick={() => {
                                                    this.addEmptyPlaceholderValue(false);
                                                }}
                                            >
                                                <AddIcon/> Add another output
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                </Grid>


            )
        } else return <IndividualComponentLoader/>;


    }


}


export default withStyles(styles)(inject("SelectedPlaceholder", "ComponentName", "ComponentDescription", "SaveTrigger")(observer(AIAssistantTemplate)));
