import React from "react";
import TextField from "@mui/material/TextField";

const PlaceholderTextField = ({ value, placeholder, disabled, error, onChange, onBlur,rows }) => {
  return (
    <TextField
      value={value}
      placeholder={placeholder}
      variant="outlined"
      disabled={disabled}
      error={error}
      InputProps={{
        style: { color: "black", backgroundColor: "#EEEEEE" },
      }}
      className="textfieldPaddingJson"
      multiline
      maxRows={rows}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default PlaceholderTextField; 