import React from "react";
import PropTypes from "prop-types";

// Material UI
import { styled } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

// Styled components
const Box = styled("div")(({ width, vertical, horizontal }) => ({
    background: "#FFEACB",
    border: "1px solid #FFB74D",
    borderRadius: "4px",
    display: "flex",
    alignItems: vertical ? vertical : "center",
    justifyContent: horizontal ? horizontal : "center",
    width: width ? width : "fit-content",
    padding: "8px 12px",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
}));

const Error = styled(ErrorIcon)({
    color: "#FFB74D",
    marginRight: "12px",
    width: "16px",
    height: "auto",
    paddingTop: "2px"
});

function WarningBox({ text, hideIcon, width, vertical, horizontal }) {
    return (
        <Box width={width} vertical={vertical} horizontal={horizontal}>
            {!hideIcon && <Error />}
            {text}
        </Box>
    );
}

WarningBox.propTypes = {
    text: PropTypes.string.isRequired,
};

export default WarningBox;
