import React, { Component } from 'react';
import { Chip, CircularProgress } from "@mui/material";
import { Person, Email } from "@mui/icons-material";
import { send_request } from "../../../utils/Request";


class UserDisplayName extends Component {
  constructor() {
    super();

    this.state={
      userDisplayName: null,
      loading: true,
      userType: "USER"
    }
  }

  retrieveUserInfo = () => {
    let thiz = this;
    
    if(!thiz.props.userType || thiz.props.userType != "EMAIL") {
      this.setState({ loading: true });

      let url = "authz-service/display_name/"+thiz.props.username;

      if (this.props.isTaskExternal) url = `task/task/get_task_assigned_display_name/${this.props.taskId}/${thiz.props.username}`;

      send_request(url, "", "", "GET")
      .then((response) => {
  
        let userDisplayNameValue = '';
        if(response) {
          
          if(response.data !== '') {
            thiz.setState({
              loading:false,
              userDisplayName: response.data
            });
            userDisplayNameValue = response.data;
          } else {
            //no display name
            thiz.setState({
              loading:false,
              userDisplayName: thiz.props.username,
              userType:"EMAIL"
            });
            userDisplayNameValue = thiz.props.username;
          }
        }

        if(thiz.props.callback) {
          thiz.props.callback(userDisplayNameValue, thiz.props.callbackData);
        }
  
      })
      .catch((error) => {
        //some error. we fallback
        thiz.setState({
          loading:false,
          userDisplayName: thiz.props.username,
          userType:"EMAIL"
        });

        if(thiz.props.callback) {
          thiz.props.callback(thiz.props.username, thiz.props.callbackData);
        }
      });
    } else if(thiz.props.userType == "EMAIL") {
      thiz.setState({
        loading:false,
        userDisplayName: thiz.props.username,
        userType:"EMAIL"
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.username === null || this.props.username === null) {
      return;
    }
    if (prevProps.username !== this.props.username) {
      this.retrieveUserInfo();
    }
  }

  componentDidMount() {
    this.retrieveUserInfo();
  }

  render() {    
    if(this.state.loading) {
      return (
        <span><CircularProgress size={10}/></span>
      );
    } else {
      if(this.state.userType == "USER") {
        return(
          <Chip
            icon={<Person fontSize="small" />}
            className={"taskChip userTask"}
            label={this.state.userDisplayName}
          />
        );
      } else if(this.state.userType == "EMAIL") {
        return(
          <Chip
            icon={<Email fontSize="small" />}
            className={"taskChip"}
            label={this.state.userDisplayName}
          />
        );
      }
    }
    
  }
}

export default UserDisplayName;