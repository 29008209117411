import React, { useState, useEffect, memo } from "react";
import { toJS } from "mobx";

// MUI
import { styled } from "@mui/system";
import { Grid } from "@mui/material";
import TableCell from "./TableCell";
import { alphabet } from "../TableHelpers";

const PreviewContainer = styled(Grid)({
  marginTop: 16
});

const TopTextContainer = styled(Grid)({
  marginBottom: 24
});

const DescriptionTextContainer = styled(Grid)({
  marginBottom: 16
});

const TableContainer = styled(Grid)({
  marginBottom: 16,
  paddingBottom: 32,
  overflow: "auto"
});

const ColumnName = styled("span")({
  fontWeight: "bold",
  fontSize: "14px"
});

const Column = styled(Grid)({
  minWidth: "200px"
});

const Row = styled(Grid)({
  marginBottom: "3px"
});

const RowItem = styled(Grid)({
  minWidth: "200px",
  marginRight: ""
});

function TableInputPreview(props) {
  const { classes, tableCells } = props;

  // Render table cells
  const renderTableCells = () => {
    let order = props.columns.map(col => col.id);

    return [...Array(props.numRows)].map((e, rowIndex) => {
      return (
        <Row item container xs={12} key={rowIndex} wrap={"nowrap"} gap={2}>
          {order.map((id, columnIndex) => {
            return (
              <RowItem item key={rowIndex.toString() + columnIndex.toString()}>
                <TableCell
                  key={tableCells[id][rowIndex].id}
                  label={`${alphabet[columnIndex]}${rowIndex + 1}`}
                  cell={tableCells[id][rowIndex]}
                  rowIdx={rowIndex}
                  colIdx={columnIndex}
                  colId={id}
                  setTableCells={props.setTableCells}
                  tableCells={tableCells}
                  availablePlaceholders={props.availablePlaceholders}
                  columns={props.columns}
                />
              </RowItem>
            );
          })}
        </Row>
      );
    });
  };

  // Render the column names
  const renderColumnNames = () => {
    return props.columns.map(col => {
      return (
        <Column key={col.id} item>
          <Grid item xs={12}>
            <ColumnName>
              {col.columnName ? col.columnName : "Column name"}
            </ColumnName>
          </Grid>
        </Column>
      );
    });
  };

  return (
    <PreviewContainer container>
      <TopTextContainer item xs={12}>
        <span className={classes.tableSettingsTitle}>Table Preview</span>
      </TopTextContainer>
      <DescriptionTextContainer item xs={12}>
        <span className={classes.topText}>
          Prefill values in this table by manually typing in a value or
          inserting a placeholder
        </span>
      </DescriptionTextContainer>

      <Grid container sx={{ overflow: "auto", paddingBottom: "16px" }}>
        <Grid container gap={2} wrap="nowrap" sx={{ marginBottom: "4px" }}>
          {renderColumnNames()}
        </Grid>
        {renderTableCells()}
      </Grid>
    </PreviewContainer>
  );
}

export default TableInputPreview;
