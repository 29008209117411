import DraftCanvasComponent from "./ComponentNodes/DraftCanvasComponent";
import Note from "./Note";
import DBNode from "./DBNode";
import SessionNode from "./ComponentNodes/ProgressCanvasComponent";
import ProgressCanvasComponent from "./ComponentNodes/ProgressCanvasComponent";
import ProdCanvasComponent from "./ComponentNodes/ProductionCanvasComponent";
import VersionCanvasComponent from "./ComponentNodes/VersionCanvasComponent";
import TemplateCanvasComponent from "./ComponentNodes/TemplateCanvasComponent";

export const prodNodeTypes = {
  component: ProdCanvasComponent,
  note: Note,
  dbNode: DBNode,
  sessionComponent: ProgressCanvasComponent,
};
export const versionNodeTypes = {
  component: VersionCanvasComponent,
  note: Note,
  dbNode: DBNode,
  sessionComponent: ProgressCanvasComponent,
};
export const progressNodeTypes = {
  component: ProgressCanvasComponent,
  note: Note,
  dbNode: DBNode,
  sessionComponent: ProgressCanvasComponent,
};
export const draftNodeTypes = {
  component: DraftCanvasComponent,
  note: Note,
  dbNode: DBNode,
  sessionComponent: SessionNode,
};

export const templateNodeTypes = {
  component: TemplateCanvasComponent,
  note: Note,
  dbNode: DBNode,
  sessionComponent: SessionNode,
};