import React, { useState, useEffect, useRef } from "react";

// MUI
import { styled } from "@mui/system";
import { Grid, CircularProgress, Button } from "@mui/material";

// Icons
import ReplayIcon from "@mui/icons-material/Replay";
import { CheckCircle, Error } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Util
import { EDIT_STAGES_ENUM, StageNameMapping, checkIfApplyingEditCompleted, getEditsBeingPerformed } from "../../../util/Edit/EditUtil";

const LoadingContainer = styled(Grid)(({ theme }) => ({
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    padding: "16px",
    fontSize: "14px",
    // minWidth: "400px",
}));

const BlueText = styled("span")({
    fontSize: "12px",
    fontWeight: "400",
    color: "#2196F3",
    display: "flex",
    alignItems: "center",
    "&:hover": {
        cursor: "pointer",
    },
});

export const StyledOpenInNewIcon = styled(OpenInNewIcon)({
    width: "13px",
    height: "13px",
    marginLeft: "4px"
  });

const ContentContainer = styled(Grid)(({ isClickable, fullWidth }) => ({
    background: "#FFF",
    boxShadow: "0px 4px 4px 0px #00000040",
    padding: "10px",
    marginBottom: "8px",
    borderRadius: "6px",
    width: fullWidth ? "95%" : "fit-content",
    "&:hover": {
        cursor: isClickable && "pointer",
        color: isClickable && "rgba(0, 0, 0, 0.6)",
    },
}));

const Text = styled("span")({
    fontSize: "12px",
    lineHeight: "24px",
    fontStyle: "normal",
});

const RetryButton = styled(Button)({
    textTransform: "none",
    fontSize: "14px",
    "&:hover": {
        cursor: "pointer",
        color: "#2196F3",
    },
});

const CenterGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

function EditChangesProgressMessage({ loadingHistory, applyingEditStages, setApplyingEditStages, undoEditChanges, retryApplyingEdit, isLatestMessage, editChangesDTO, projectId, draftVersion }) {
    const freeze = useRef(false); // For freezing the state when completed/error

    // this edit changes is differnt from editChanges in AIBuilderSidebar
    const [editChanges, setEditChanges] = useState({});
    const [changesDTO, setChangesDTO] = useState({});
    const [changes, setChanges] = useState({});
    const [version, setVersion] = useState(0)

    // For loading the workflow editor history
    useEffect(() => {
        if (!loadingHistory || Object.keys(loadingHistory).length <= 0) return;

        freeze.current = true

        setChanges(loadingHistory.editChangesForHistory);
        setVersion(loadingHistory.backupVersion ? loadingHistory.backupVersion : draftVersion);
        setEditChanges(loadingHistory);
        setChangesDTO(loadingHistory.editChangesForHistory);

    }, [loadingHistory])

    useEffect(() => {
        if (freeze.current) return; // If message frozen, don't allow the local state to update

        setEditChanges(applyingEditStages);
        setChangesDTO(editChangesDTO);

        // If it contains an error, we want to set the existing applying edits to empty
        // And freeze the existing message
        if (applyingEditStages.hasError) {
            freeze.current = true
            setApplyingEditStages([]);
        }

        // We also want to freeze if completed
        if (checkIfApplyingEditCompleted(applyingEditStages) || applyingEditStages.isCompleted) {
            freeze.current = true
            setApplyingEditStages([]);
        }
    }, [applyingEditStages]);

    useEffect(() => {
        if (freeze.current || !editChangesDTO) return;
        setChanges(editChangesDTO);
        setVersion(draftVersion);
    }, [editChangesDTO]);

    const fetchStatus = (stage) => {
        if (!editChanges) return <CircularProgress size={14} thickness="4.4" />;

        // Apply any success
        if (editChanges.editStages && editChanges.editStages.includes(stage)) {
            return <CheckCircle style={{ height: "18px", width: "auto", color: "#55A77A" }} />;
        }

        if (editChanges.hasError) {
            // Handle error
            return <Error style={{ height: "18px", width: "auto", color: "#E10000" }} />;
        }

        return <CircularProgress size={14} thickness="4.4" />;
    };

    const fetchLinks = (currStage, changes) => {
        if (!changes || !editChanges || !editChanges.editStages || !editChanges.editStages.includes(currStage)) return;

        if (Object.keys(editChanges).length <= 0) return;

        let items = [];

        switch (currStage) {
            case "SAVED_BACKUP":
                if (!version) break;
                items.push({
                    text: `Version ${version}`,
                    URL: `/project/view_canvas/${projectId}&version=${version}`
                });
                break;

            case "ADDED_NEW_DATABASES":
                for (let i = 0; i < changes.databasesToAdd.length; i++) {
                    const dbToAdd = changes.databasesToAdd[i];
                    items.push({
                        text: dbToAdd.name ? dbToAdd.name : "Untitled",
                        URL: `/databases/database_management/${dbToAdd.databaseId}`
                    });
                }
                break;

            case "UPDATED_DATABASES":
                for (let i = 0; i < changes.databasesToUpdate.length; i++) {
                    const dbToUpdate = changes.databasesToUpdate[i];
                    items.push({
                        text: dbToUpdate.name ? dbToUpdate.name : `${dbToUpdate.databaseId}`,
                        URL: `/databases/database_management/${dbToUpdate.databaseId}`
                    });
                }
                break;

            default:
                return;
        }

        if (!items || items.length <= 0) return null;

        return (
            <Grid item xs={12}>
                <Grid container rowGap={1}>
                    {items.map((item) => {
                        return (
                            <Grid item xs={12}>
                                <BlueText onClick={() => window.open(item.URL, "_blank")}>
                                    {item.text}
                                    <StyledOpenInNewIcon />
                                </BlueText>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    };

    return (
        <LoadingContainer container rowGap={2}>
            <Grid item xs={12} columnGap={2}>
                <Grid container rowGap={1}>
                    {EDIT_STAGES_ENUM.map((stage, idx) => {
                        // Displaying 1 by 1 + handling edge cases
                        if (editChanges) {
                            if (idx > editChanges.length) return <></>;
                            else if (editChanges.editStages && idx > editChanges.editStages.length) return <></>;
                        }

                        // Check if this stage is actually being handled
                        const editsBeingPerformed = getEditsBeingPerformed(changesDTO);
                        if (!editsBeingPerformed.has(stage)) return <></>;

                        const { icon, name } = StageNameMapping[stage]; // Grab icon and name

                        return (
                            <Grid item key={idx} xs={12}>
                                <ContentContainer container rowGap={"8px"} fullWidth>
                                    <Grid item xs={12}>
                                        <Grid container columnGap={1}>
                                            <CenterGrid item>{fetchStatus(stage)}</CenterGrid>
                                            <CenterGrid item>{icon}</CenterGrid>
                                            <CenterGrid item>
                                                <span>{name}</span>
                                            </CenterGrid>
                                        </Grid>
                                    </Grid>
                                    {fetchLinks(stage, changes)}
                                </ContentContainer>
                            </Grid>
                        );
                    })}
                    {/* {editChanges && editChanges.isCompleted && (
                        <Grid item xs={12}>
                            <RetryButton size="small" variant="outlined" startIcon={<ReplayIcon fontSize={"small"} />} onClick={undoEditChanges} disabled={!isLatestMessage}>
                                Undo
                            </RetryButton>
                        </Grid>
                    )} */}
                    {editChanges && editChanges.hasError && (
                        <Grid item xs={12}>
                            <ContentContainer container gap="16px" isClickable={false} fullWidth>
                                <Grid item xs={12}>
                                    <Text>
                                        <b>Error encountered: build stopped and backup restored</b>
                                    </Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Text>
                                        An error was encountered which prevented the build from completing successfully. The workflow has been restored back to the backup version saved before the
                                        build was started. Click Retry build to retry the build again from this backup again.
                                    </Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <RetryButton size="small" variant="outlined" startIcon={<ReplayIcon fontSize={"small"} />} onClick={retryApplyingEdit} disabled={!isLatestMessage}>
                                        Retry build
                                    </RetryButton>
                                </Grid>
                            </ContentContainer>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </LoadingContainer>
    );
}

export default EditChangesProgressMessage;
