import React, { Component } from "react";
import { Error } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { Grid, Snackbar } from "@mui/material";
import { toJS, reaction } from "mobx";
import Target from "./DropTarget";
import { inject, observer } from "mobx-react";
import ComponentConfig from "./ComponentConfig";
import { timezones } from "../../utils/timezones";
import { send_request } from "../../utils/Request";
import { CustomDialog } from "../Component/Dialog";
import ComponentsSidebar from "./ComponentsSidebar";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import { getCurrentTime } from "../../utils/getCurrentTime";
import SequentialFormContainer from "./SequentialFormContainer";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import SavedWithErrors from "../pages/ComponentPageDialogs/SavedWithErrors";
import ValidationErrors from "../pages/ComponentPageDialogs/ValidationErrors";
import { displayRawValue, displayRawValues } from "../../utils/PlaceholderUtil";
import CircularProgress from "@mui/material/CircularProgress";
import "react-image-crop/dist/ReactCrop.css";
import { Alert } from "@mui/lab";
import { withParams } from "../../Routes";
import config from "../../config";
import { searchForInnerFormPlaceholders } from "../../utils/InnerFormPlaceholders";
import { createSaveEvent } from "../../utils/createSaveEvent";
import { send_request_graphql_mutation } from "../../utils/Request";
import { SAVE_EVENT } from "../pages/Canvas/CanvasQueries";
import { ComponentFactory } from "../ProjectCanvas/ComponentRegistry";
import ComponentAIDraftDiscardDialog from "../pages/Canvas/CanvasUtil/ComponentAIDraftDiscardDialog";
import { FormContext } from "../pages/ComponentPane/ComponentPane";
import { FormComponentSettings } from "../FormBuilder/FormDialogs/FormComponentSettings";
import { FormLinkDialog } from "../FormBuilder/FormDialogs/FormLinkDialog";
import { FormStylingDialog } from "../FormBuilder/FormDialogs/FormStylingDialog";
import { FormSettingsDialog } from "../FormBuilder/FormDialogs/FormSettingsDialog";
import { FormExportDialog } from "../FormBuilder/FormDialogs/FormExportDialog";
import DiscardChangesButton from "../pages/ComponentPane/DiscardChangesButton";

const fileService = config.FILE_SERVICE;

class SequentialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      errors: [],
      form_id: "",
      submMsg: "",
      error: false,
      saveD: false,
      onBack: false,
      exportType: 0,
      component: {},
      saving: false,
      saveStatus: "",
      form_title: "",
      lastUpdate: "",
      loading: false,
      uploadIcon: "",
      uploadD: false,
      settingD: false,
      uploadedLogo: "",
      exportMenu: false,
      canReadOnly: false,
      canSaveSubmission: true,
      canAnnotateContent: false,
      canSubmitSubmission: true,
      isSingleUse: false,
      errorDialog: false,
      savedWithErrors: false,
      validationError: false,
      canEditSubmission: true,
      autoSave: false,
      isDynamicForm: false,
      current_status: "published",
      openComponentSettings: false,
      uploadedCoverImage: "",
      coverImageSrc: "",
      coverImageCrop: {
        unit: "%",
        width: 30,
        aspect: 7 / 1,
      },
      logoSrc: "",
      logoCrop: {
        unit: "%",
        width: 30,
        aspect: 1,
      },
      croppedLogoUrl: "",
      loadingUpload: false,
      errorCoverImageMessage: "",
      errorLogoMessage: "",
      exposeMeta: false,
      linkedComponent: false,
      linkedComponents: [],
      relatedSequentialForm: [],
      openDynamicPlaceholder: false,
      action: "",
      innerPlaceholders: [],
      instruction: "",
      aiComponentBuilderOpen: false,
      aiComponentBuilderData: {},
      aiDataUpdate: false,
      status: null,
      openTestPanel: false,
      executeTest: false,
      compData: {},
      openSessionHistory: false,
      selectedHistorySession: false,
      resetHistory: false,
      isInAIDraft: false,
      instructions: {},
      showBuildPrompt: false,
      sidebarState: "INITIAL_COMP_BUILD",
      isLoadedFromPane: false,
      isFullScreen: window.location.href.includes("fullScreen=true"),
      compSessionId: null,
    };

    this.Config = React.createRef();
  }

  setAiComponentBuilderData = (aiComponentBuilderData) => {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    this.setState({
      aiComponentBuilderData: aiComponentBuilderData,
      aiDataUpdate: true,
    });

    if (
      aiComponentBuilderData &&
      Object.keys(aiComponentBuilderData).length > 0
    ) {
      this.setState({
        form_title: aiComponentBuilderData.name,
        loading: false,
        aiDataUpdate: false,
        instruction: aiComponentBuilderData["explanation"],
        status: "AI_BUILD_GENERATED",
        form_id: formId,
        canAddSubmission: aiComponentBuilderData.data.canAddSubmission,
        canAnnotateContent:aiComponentBuilderData.data.canAnnotateContent,
        canEditSubmission:aiComponentBuilderData.data.canEditSubmission,
        canReadOnly:aiComponentBuilderData.data.canReadOnly,
        canSaveSubmission:aiComponentBuilderData.data.canSaveSubmission,
        canSubmitSubmission:aiComponentBuilderData.data.canSubmitSubmission,
      });

      this.props.onComponentDescriptionChanged(aiComponentBuilderData["explanation"]);

      this.updateComponentDataFromAI(aiComponentBuilderData.data);

      if (aiComponentBuilderData["isDynamicForm"]) {
        this.setState({ isDynamicForm: true });
      }
    }
  };

  setCompData = async () => {
    try {
      const formId = this.props.params.form_id
        ? this.props.params.form_id
        : this.props.component_id;

      if (!ProjectStore.components) return;
      let comp = ProjectStore.components.find(
        (comp) => comp.componentId === formId
      );

      // Set type
      let type = "";
      if (comp) type = comp.type;
      else type = this.props.isFormSection ? "form_section" : "sequential_form";

      let compNode = ComponentFactory[type];
      if (!compNode) return;

      let res = {
        id: formId,
        type: "component",
        data: {
          ...compNode,
          type: type,
        },
      };

      this.setState({ compData: res });
    } catch (e) {
      console.log(e);
    }
  };

  handleChange = (event) => {
    this.setState({ [event.target.value]: !this.state[event.target.value] });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    let storeCleared = false;
    if (this.state.form_id != formId) {
      if (formId) {
        this.updateForm();
      } else {
        SequentialFormContainer.clearStorage();
        storeCleared = true;
      }
    }

  }

  onBreadcrumb = (path, isDynamicForm) => {
    this.onSave(isDynamicForm);

    if (this.state.isLoadedFromPane) {
      this.props.handleClose(true);
    } else {
      if (ProjectStore.state.production && !ProjectStore.state.canvasView) {
        this.props.navigate(`/project/production/${this.props.params.id}`);
      } else {
        this.props.navigate(`/project/canvas/${this.props.params.id}`);
      }
    }
  };

  onCancel = () => {
    this.setState({
      onBack: false,
    });
  };

  onSave = async (isDynamicForm) => {
    if (!ProjectStore.state.production) {
      const save = await this.handleSubmit("published", false, isDynamicForm);
    }
  };

  handleCancelSettings = (setOpenSettings) => {
    if (this.baseState) {
      this.setState({
        state: JSON.parse(this.baseState),
      });
      setOpenSettings(false);
    } else {
      setOpenSettings(false);
    }

    // If there isn't a link component selected, set back to default
    if (this.state.linkedComponent && this.state.linkedComponents.length === 0)
      this.setState({ linkedComponent: false });
  };

  /**
   * This function get the Form data from the Project.
   * This is the latest way of getting the Form data as the result of merging Form and Project
   * However, the previous way is still kept for backward compatibility
   */
  updateFormFromProject = () => {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    if (formId) {
      let version = null;
      if (ProjectStore.state.production)
        version = ProjectStore.publishedVersion;
      else version = ProjectStore.draftVersion;
      return send_request(
        `project-service/project/component/query/${formId}/at-version/${version}`,
        "",
        {}
      )
        .then(async (response) => {
          if (
            response.data &&
            response.data.components &&
            response.data.components[0].componentData
          ) {
            let cData = response.data.components[0].componentData;
            let rData = cData.data;

            this.setState({ component: response.data.components[0] });
            this.validate();
            if (rData) {
              SequentialFormContainer.reloadTemplate(
                rData.pages,
                rData.form_length
              );

              const coverImageCrop = {
                unit: "%",
                width: 30,
                aspect: 7 / 1,
              };

              //setup the email data
              if (rData.email && Array.isArray(rData.email)) {
                SequentialFormContainer.email = rData.email;
              } else {
                SequentialFormContainer.email = [];
              }
              SequentialFormContainer.message = rData.emailContent;
              SequentialFormContainer.emailSubject = rData.emailSubject;
              SequentialFormContainer.ccEmails = rData.ccEmails;
              SequentialFormContainer.reminderCcEmails = rData.reminderCcEmails;
              SequentialFormContainer.reminderReplyToEmails = rData.reminderReplyToEmails
                ? rData.reminderReplyToEmails
                : [];
              SequentialFormContainer.replyToEmails = rData.replyToEmails
                ? rData.replyToEmails
                : [];
              SequentialFormContainer.reminderEmail = rData.reminderEmail;
              SequentialFormContainer.reminderEmailMessage =
                rData.reminderEmailMessage;
              SequentialFormContainer.reminderEmailSubject =
                rData.reminderEmailSubject;

              if (rData.users) {
                SequentialFormContainer.users = rData.users;
              } else {
                SequentialFormContainer.users = [];
              }
              SequentialFormContainer.reminder = rData.reminder;

              if (rData.reminderUsers) {
                SequentialFormContainer.reminderUsers = rData.reminderUsers;
              } else {
                SequentialFormContainer.reminderUsers = [];
              }

              SequentialFormContainer.reminderInterval = rData.reminderInterval;
              SequentialFormContainer.reminderType = rData.reminderType;
              SequentialFormContainer.reminderFrequency =
                rData.reminderFrequency;
              SequentialFormContainer.reminderLimit = rData.reminderLimit;

              SequentialFormContainer.setCredentialId(rData.credentialId);

              SequentialFormContainer.toDoColor = rData.toDoColor;
              SequentialFormContainer.toDoLabel = rData.toDoLabel;
              SequentialFormContainer.completedColor = rData.completedColor;
              SequentialFormContainer.completedLabel = rData.completedLabel;

              if (rData.reminderOption) {
                SequentialFormContainer.setReminderOption(rData.reminderOption);
              }

              if (
                rData.reminderOnPlaceholderValue &&
                Array.isArray(rData.reminderOnPlaceholderValue)
              ) {
                SequentialFormContainer.reminderOnPlaceholderValue = displayRawValues(
                  rData.reminderOnPlaceholderValue
                );
              }

              if (
                rData.notificationMethod &&
                rData.notificationMethod.method === "email"
              ) {
                SequentialFormContainer.notificationMethod =
                  rData.notificationMethod.method;
                SequentialFormContainer.setCredentialId(
                  rData.notificationMethod.credentialId
                );
              } else {
                SequentialFormContainer.notificationMethod =
                  rData.notificationMethod.method;
              }

              if (
                rData.taskDueDate &&
                rData.taskDueDate.status === "no_due_date"
              ) {
                SequentialFormContainer.dueDateStatus =
                  rData.taskDueDate.status;
              } else if (
                rData.taskDueDate &&
                rData.taskDueDate.status === "placeholder"
              ) {
                SequentialFormContainer.dueDateStatus =
                  rData.taskDueDate.status;
                SequentialFormContainer.dueDateOn = rData.taskDueDate.dueDateOn
                  ? [displayRawValue(rData.taskDueDate.dueDateOn)]
                  : [];
              } else if (
                rData.taskDueDate &&
                rData.taskDueDate.status === "amount_of_time"
              ) {
                SequentialFormContainer.dueDateStatus =
                  rData.taskDueDate.status;
                SequentialFormContainer.dueDateAfter = rData.taskDueDate
                  .dueDateAfter
                  ? [rData.taskDueDate.dueDateAfter]
                  : [];
                SequentialFormContainer.dueDatePeriod =
                  rData.taskDueDate.dueDatePeriod;
              }

              this.setState({
                form_id: formId,
                lastUpdate: cData.lastModified,
                uploadIcon: rData.form_logo,
                form_title: rData.form_title,
                submMsg: rData.submMsg ? rData.submMsg : "",
                canReadOnly: rData.canReadOnly ? rData.canReadOnly : false,
                canSaveSubmission:
                  rData.canSaveSubmission !== undefined
                    ? rData.canSaveSubmission
                    : true,
                canAnnotateContent:
                  rData.canAnnotateContent !== undefined
                    ? rData.canAnnotateContent
                    : false,
                canSubmitSubmission:
                  rData.canSubmitSubmission !== undefined
                    ? rData.canSubmitSubmission
                    : true,
                canEditSubmission:
                  rData.canEditSubmission != null
                    ? rData.canEditSubmission
                    : true,
                autoSave: rData.autoSave != null ? rData.autoSave : true,
                isSingleUse:
                  rData.isSingleUse != null ? rData.isSingleUse : false,
                prefixLabel: response.data.components[0].prefixLabel,
                exposeMeta:
                  response.data.components[0].prefixLabel &&
                  response.data.components[0].prefixLabel != ""
                    ? true
                    : false,
                isDynamicForm: cData.isDynamicForm ? true : false,
                formSubmittedDateFormat: rData.formSubmittedDateFormat,
                uploadedCoverImage:
                  rData.coverImage !== undefined
                    ? rData.coverImage.uploaded
                    : "",
                coverImageCrop:
                  rData.coverImage !== undefined
                    ? rData.coverImage.crop
                    : coverImageCrop,
                coverImageSrc:
                  rData.coverImage !== undefined ? rData.coverImage.src : "",
                uploadedLogo:
                  rData.logo !== undefined ? rData.logo.uploaded : "",
                linkedComponent: rData.linkedComponent ? true : false,
                linkedComponents: rData.linkedComponents,
                instruction: response.data.components[0].description
                  ? response.data.components[0].description
                  : this.state.description,
                instructions: response.data.components[0].instructions
                  ? response.data.components[0].instructions
                  : this.state.instructions,
              });
              this.props.onLastModifiedChanged(cData.lastModified);
              this.props.onComponentDescriptionChanged(response.data.components[0]?.description || "");
              document.title = `${
                rData.form_title ? rData.form_title : "Untitled"
              } | Workflow86`;

              this.baseState = JSON.stringify(this.state); //enable Cancel button for the FormSettings dialog
              this.setState({ loading: false });
            }
          } else {
            //no data
            //reset the email part which lead to fallback
            SequentialFormContainer.ccEmails = [];
            SequentialFormContainer.replyToEmails = [];
            SequentialFormContainer.email = [];
            (SequentialFormContainer.message =
              "<p>Hello 👋</p> <p>You have been assigned a task to complete. To open and view this task, click the button at the end of this email.</p> <p>Link to task: !{taskURL}</p>"),
              (SequentialFormContainer.emailSubject =
                "You have been assigned a task to complete"),
              (SequentialFormContainer.reminderEmail = []);
            SequentialFormContainer.reminderEmailMessage =
              "<p>Hello 👋</p><p>This is just a reminder that you have been assigned a task to complete. To complete this task, click the button at the end of this email.</p> <p>Link to task: !{taskURL}</p> ";
            SequentialFormContainer.reminderEmailSubject =
              "Reminder to complete your task";
            SequentialFormContainer.reminderInterval = 1;
            SequentialFormContainer.reminderCcEmails = [];
            SequentialFormContainer.reminderReplyToEmails = [];
            SequentialFormContainer.notificationMethod = "email";
            (SequentialFormContainer.reminderOption = "time"),
              (SequentialFormContainer.reminderOnPlaceholderValue = []);
            SequentialFormContainer.reminderType = "days";
            SequentialFormContainer.reminderFrequency = "ONCE";
            SequentialFormContainer.reminder = false;
            SequentialFormContainer.reminderLimit = 1;
            SequentialFormContainer.users = [];
            SequentialFormContainer.reminderUsers = [];
            SequentialFormContainer.toDoColor = "#F59300";
            SequentialFormContainer.toDoLabel = "To do";
            SequentialFormContainer.completedColor = "#55A77A";
            SequentialFormContainer.completedLabel = "Completed";

            ProjectStore.components.map((c) => {
              if (c.componentId === formId) {
                this.setState({
                  instruction: c.description
                    ? c.description
                    : this.state.description,
                  instructions: c.instructions
                    ? c.instructions
                    : this.state.instructions,
                });
              }
            });
          }

          if (!this.state.form_title) {
            this.setState({ form_title: ProjectStore.ComponentName.value });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          // throw Error(err.message);
          console.log(err);
        });
    }
  };

  updateComponentDataFromAI = (aiData) => {
    SequentialFormContainer.reloadTemplate(aiData.pages, aiData.form_length);

    //setup the email data
    if (aiData.email && Array.isArray(aiData.email)) {
      SequentialFormContainer.email = aiData.email;
    } else {
      SequentialFormContainer.email = [];
    }
    SequentialFormContainer.message = aiData.emailContent;
    SequentialFormContainer.emailSubject = aiData.emailSubject;

    // reminder
    SequentialFormContainer.reminder = aiData.reminder;
    SequentialFormContainer.reminderEmail = aiData.reminderEmail;
    SequentialFormContainer.reminderEmailMessage = aiData.reminderEmailMessage;
    SequentialFormContainer.reminderEmailSubject = aiData.reminderEmailSubject;
    SequentialFormContainer.reminderCcEmails = aiData.reminderCcEmails;
    SequentialFormContainer.reminderInterval = aiData.reminderInterval;
    SequentialFormContainer.reminderType = aiData.reminderType;
    SequentialFormContainer.reminderLimit = aiData.reminderLimit;
    SequentialFormContainer.reminderFrequency = aiData.reminderFrequency;
    if (aiData.taskDueDate && aiData.taskDueDate.status === "placeholder") {
      SequentialFormContainer.dueDateStatus = "placeholder";
      SequentialFormContainer.dueDateOn = aiData.taskDueDate.dueDateOn
        ? [aiData.taskDueDate.dueDateOn]
        : [];
      SequentialFormContainer.setReminderOption("time");
      SequentialFormContainer.notificationMethod = "email";
    } else {
      SequentialFormContainer.dueDateStatus = "amount_of_time";
      SequentialFormContainer.dueDateAfter =
        aiData.taskDueDate && aiData.taskDueDate.dueDateAfter
          ? [aiData.taskDueDate.dueDateAfter]
          : [];
      SequentialFormContainer.dueDatePeriod =
        aiData.taskDueDate && aiData.taskDueDate.dueDateAfter
          ? aiData.taskDueDate.dueDatePeriod
          : "null";
    }

    SequentialFormContainer.toDoLabel = aiData.toDoLabel;
    SequentialFormContainer.completedLabel = aiData.completedLabel;

    this.setState({
      form_id: aiData.form_id,
      lastUpdate: aiData.lastModified, // add current time
      form_title: aiData.form_title,
      submMsg: aiData.submMsg ? aiData.submMsg : "",
    });
    this.props.onLastModifiedChanged(aiData.lastModified);
  };

  updateForm = async (isInnerSave) => {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    const projectId = this.props.params.id;
    if (ProjectStore.draftVersion == undefined) {
      return this.props.navigate(`/project/canvas/${projectId}`);
    } else {
      if (!isInnerSave) SequentialFormContainer.clearStorage();
      if (formId) {
        await this.setCompData();
        this.updateFormFromProject()
          .then(() => {
            //get linked components
            send_request(
              "project-service/project/find_related_sequential_forms/" + formId,
              "",
              {}
            ).then((resp) => {
              if (resp && resp.data) {
                let k = Object.keys(resp.data);
                let SFormsList = [];
                for (let a = 0; a < k.length; a++) {
                  SFormsList.push({
                    label:
                      resp.data[k[a]].componentName &&
                      resp.data[k[a]].componentName != ""
                        ? resp.data[k[a]].componentName
                        : k[a],
                    componentId: k[a],
                  });
                }

                let lc = [];

                if (this.state.linkedComponents) {
                  for (let a = 0; a < this.state.linkedComponents.length; a++) {
                    if (resp.data[this.state.linkedComponents[a]]) {
                      lc.push({
                        label:
                          resp.data[this.state.linkedComponents[a]]
                            .componentName &&
                          resp.data[this.state.linkedComponents[a]]
                            .componentName != ""
                            ? resp.data[this.state.linkedComponents[a]]
                                .componentName
                            : this.state.linkedComponents[a],
                        componentId: this.state.linkedComponents[a],
                      });
                    }
                  }
                }

                this.setState({
                  relatedSequentialForm: SFormsList,
                  linkedComponents: lc,
                });
              }
            });

            if (
              !SequentialFormContainer.pages ||
              SequentialFormContainer.pages.length == 0
            ) {
              send_request("form-builder/form", "", { form_id: formId }, "get")
                .then((res) => {
                  const result = res.data.data;
                  SequentialFormContainer.reloadTemplate(
                    result.pages,
                    result.form_length
                  );
                  this.setState({
                    form_id: formId,
                    lastUpdate: result.created_at,
                  });
                  this.props.onLastModifiedChanged(result.created_at);
                  this.baseState = JSON.stringify(this.state);
                  this.setState({ loading: false });
                })

                .catch((e) => {
                  this.setState({ loading: false });
                  return;
                });
              send_request(
                "form-builder/form_meta",
                "",
                { form_id: formId },
                "get"
              )
                .then((res) => {
                  const coverImageCrop = {
                    unit: "%",
                    width: 30,
                    aspect: 7 / 1,
                  };

                  this.setState({
                    uploadIcon: res.data.data.form_logo,
                    form_title: res.data.data.form_title,
                    submMsg: res.data.data.submMsg ? res.data.data.submMsg : "",
                    canReadOnly: res.data.data.canReadOnly
                      ? res.data.data.canReadOnly
                      : false,
                    canSaveSubmission:
                      res.data.data.canSaveSubmission !== undefined
                        ? res.data.data.canSaveSubmission
                        : true,
                    canAnnotateContent:
                      res.data.data.canAnnotateContent !== undefined
                        ? res.data.data.canAnnotateContent
                        : false,
                    canSubmitSubmission:
                      res.data.data.canSubmitSubmission !== undefined
                        ? res.data.data.canSubmitSubmission
                        : true,
                    canEditSubmission:
                      res.data.data.canEditSubmission != null
                        ? res.data.data.canEditSubmission
                        : true,
                    autoSave:
                      res.data.data.autoSave != null
                        ? res.data.data.autoSave
                        : false,
                    isSingleUse:
                      res.data.data.isSingleUse != null
                        ? res.data.data.isSingleUse
                        : false,
                    uploadedCoverImage:
                      res.data.data.coverImage !== undefined
                        ? res.data.data.coverImage.uploaded
                        : "",
                    coverImageCrop:
                      res.data.data.coverImage !== undefined
                        ? res.data.data.coverImage.crop
                        : coverImageCrop,
                    coverImageSrc:
                      res.data.data.coverImage !== undefined
                        ? res.data.data.coverImage.src
                        : "",
                    uploadedLogo:
                      res.data.data.logo !== undefined
                        ? res.data.data.logo.uploaded
                        : "",
                  });
                })
                .catch((e) => {
                  return;
                });
            }
          })
          .then(() => {
            // For handling legacy (before the ability to resubmit assign tasks)
            if (
              this.state.isSingleUse &&
              (this.state.canEditSubmission == null ||
                this.state.canEditSubmission)
            ) {
              this.setState({ canEditSubmission: false });
            }
          })
          .then(() => {
            if (
              SequentialFormContainer &&
              SequentialFormContainer.placeholders &&
              SequentialFormContainer.placeholders[0]
            ) {
              this.setState({
                innerPlaceholders: SequentialFormContainer.placeholders[0].placeholders.concat(
                  searchForInnerFormPlaceholders(SequentialFormContainer.pages)
                ),
              });
            }

            // If force start in URL
            // Open test panel
            if (
              this.props.queryParams &&
              this.props.queryParams.get("force_start")
            ) {
              this.setState({ openTestPanel: true });
            }
            this.props.setChildTemplateLoaded(true);
          });
      }
    }
  };

  componentDidMount() {
    this.disposer = reaction(
      () => SequentialFormContainer.editTarget,
      (editTarget) => {
        const isOpen = editTarget !== null;
        this.setState({ isComponentConfigDialogOpen: isOpen });
      }
    );
    // Determine the id based on whether form_id is present
    const id = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;
    const isLoadedFromPane = this.props.params.form_id ? false : true;

    // Find the component in ProjectStore using the determined id
    const component = ProjectStore.components.find((c) => c.componentId === id);
    // Set state based on the found component, with fallbacks for instruction and instructions
    this.setState({
      instruction: component?.description || "",
      instructions: component?.instructions || "",
      form_id: id,
      loading: true,
      isLoadedFromPane: isLoadedFromPane,
    });
    SequentialFormContainer.placeholders = this.props.availablePlaceholders;
    let aiComponentBuilderData = this.props.aiComponentBuilderData;

    if (
      aiComponentBuilderData !== undefined &&
      aiComponentBuilderData &&
      aiComponentBuilderData["data"] &&
      aiComponentBuilderData["data"] !== ""
    ) {
      this.setAiComponentBuilderData(aiComponentBuilderData);
      this.setState({
        isInAIDraft: this.props.isInAIDraft
      })
    } else {
      // Continue with form update and validation processes
      this.updateForm();
    }
  }

  validate = async () => {
    this.setState({ validationError: false });
  };

  handleSaveProgress = () => {
    if (!this.state.form_id) {
      return;
    }
    try {
      let data = toJS(SequentialFormContainer.saveTemplate());
      this.setState((state) => {
        return { saving: true };
      });
      let querypara = {
        current_status: "draft",
        form_id: this.state.form_id,
        submMsg: this.state.submMsg,
      };
      let meta = new FormData();
      send_request("form", data, querypara, "post", {
        Accept: "application/json",
        "content-type": "application/json",
      })
        .then((res) => {
          this.setState((state) => {
            return {
              form_id: res.data.data.form_id,
              lastUpdate: res.data.data.created_at,
            };
          });
          this.props.onLastModifiedChanged(res.data.data.created_at);
          meta.append(
            "data",
            JSON.stringify({
              form_id: res.data.data.form_id,
              form_title: this.state.form_title,
              submMsg: this.state.submMsg,
            })
          );
          send_request("form_meta", meta, {}, "post", {
            Accept: "application/json",
            "content-type": "multipart/form-data",
          })
            .then((res) => {
              setTimeout(() => {
                this.setState((state) => {
                  return { saving: false };
                });
              }, 2000);
            })
            .catch((e) => {
              console.log(e.response);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  click = (e) => {
    if(this.state.isInAIDraft || this.state.loading){

      SequentialFormContainer.onEdit = false;
      SequentialFormContainer.editTarget = "";
      SequentialFormContainer.onSave = false;
    
    } else if (!ProjectStore.state.production) {
      
      SequentialFormContainer.onSave = true;
      this.Config.current.handleSave();
      this.handleSubmit("published", true);

    }
    this.setInnerPlaceholders();
  };

  handleSubmit = (status, showDialog, isDynamicForm, sessionId) => {
    function dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    }

    function isDataURL(s) {
      return !!s.match(isDataURL.regex);
    }

    isDataURL.regex = /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    try {
      let data = toJS(SequentialFormContainer.saveTemplate());
      //const pages = Object.values(data["pages"]).map((a) => JSON.stringify(a));
      const pages = data["pages"];

      const logoUploaded = {
        name: this.state.uploadedLogo.name,
      };

      let src = "";
      if (this.state.uploadedCoverImage.id != undefined) {
        src = `${fileService.QUERY_URL}/${this.state.uploadedCoverImage.id}`;
      }

      const coverImage = {
        uploaded: this.state.uploadedCoverImage,
        crop: this.state.coverImageCrop,
        src: src,
      };

      const logo = {
        uploaded: logoUploaded,
      };

      let querypara = {
        current_status: status,
        form_id: this.state.form_id,
      };

      let form_template = {};
      form_template["form_length"] = data["form_length"];
      //pages.forEach((a) => form_template.append("pages", a));

      form_template["pages"] = pages;
      form_template["status"] = status;
      form_template["form_id"] = this.state.form_id;

      // If it is not a form section, send email/schedule related data
      if (!this.props.isFormSection) {
        form_template["email"] = SequentialFormContainer.email;
        form_template["ccEmails"] = SequentialFormContainer.ccEmails;
        form_template["replyToEmails"] = SequentialFormContainer.replyToEmails;
        form_template["emailContent"] = SequentialFormContainer.message;
        form_template["emailSubject"] = SequentialFormContainer.emailSubject;
        form_template["reminderEmail"] = SequentialFormContainer.reminderEmail;
        form_template["users"] = SequentialFormContainer.users;
        form_template["notificationMethod"] =
          SequentialFormContainer.notificationMethod;
        form_template["reminderUsers"] = SequentialFormContainer.reminderUsers;
        form_template["toDoLabel"] = SequentialFormContainer.toDoLabel;
        form_template["completedLabel"] =
          SequentialFormContainer.completedLabel;
        form_template["toDoColor"] = SequentialFormContainer.toDoColor;
        form_template["completedColor"] =
          SequentialFormContainer.completedColor;
        form_template["reminderEmailMessage"] =
          SequentialFormContainer.reminderEmailMessage;
        form_template["reminderEmailSubject"] =
          SequentialFormContainer.reminderEmailSubject;
        form_template["reminderCcEmails"] =
          SequentialFormContainer.reminderCcEmails;
        form_template["replyToEmails"] = SequentialFormContainer.replyToEmails;
        form_template["reminderReplyToEmails"] =
          SequentialFormContainer.reminderReplyToEmails;
        form_template["reminderInterval"] =
          SequentialFormContainer.reminderInterval != 0
            ? SequentialFormContainer.reminderInterval
            : 1;

        form_template["reminderOption"] =
          SequentialFormContainer.reminderOption &&
          SequentialFormContainer.reminderOption != ""
            ? SequentialFormContainer.reminderOption
            : "time";

        form_template["reminderOnPlaceholderValue"] =
          SequentialFormContainer.reminderOnPlaceholderValue;

        form_template["reminderType"] =
          SequentialFormContainer.reminderType != ""
            ? SequentialFormContainer.reminderType
            : "days";

        form_template["reminderFrequency"] =
          SequentialFormContainer.reminderFrequency != ""
            ? SequentialFormContainer.reminderFrequency
            : "ONCE";

        form_template["reminderLimit"] =
          SequentialFormContainer.reminderLimit > 20 ||
          SequentialFormContainer.reminderLimit < 1
            ? 1
            : SequentialFormContainer.reminderLimit;
        form_template["reminder"] =
          SequentialFormContainer.reminder != null
            ? SequentialFormContainer.reminder
            : SequentialFormContainer.reminderEmail.length > 0;
        form_template["reminderTimezone"] = timezones.find(
          (timezone) => timezone.name === ProjectStore.state.timezone
        );

        if (SequentialFormContainer.dueDateStatus === "no_due_date") {
          form_template["taskDueDate"] = {
            status: SequentialFormContainer.dueDateStatus,
          };
        } else if (SequentialFormContainer.dueDateStatus === "placeholder") {
          form_template["taskDueDate"] = {
            status: SequentialFormContainer.dueDateStatus,
            dueDateOn: SequentialFormContainer.dueDateOn[0],
          };
        } else if (SequentialFormContainer.dueDateStatus === "amount_of_time") {
          form_template["taskDueDate"] = {
            status: SequentialFormContainer.dueDateStatus,
            dueDateAfter: SequentialFormContainer.dueDateAfter[0],
            dueDatePeriod: SequentialFormContainer.dueDatePeriod,
          };
        }
      }

      form_template["form_title"] = ProjectStore.ComponentName.value;
      document.title = `${
        this.state.form_title ? this.state.form_title : "Untitled"
      } | Workflow86`;

      form_template["form_logo"] = this.state.uploadIcon;

      if (this.state.uploadedLogo && this.state.uploadedLogo.name) {
        //there is new logo uploaded
        form_template["logo_url"] = this.state.uploadIcon;
      }
      form_template["submMsg"] = this.state.submMsg;
      form_template["canReadOnly"] = this.state.canReadOnly;
      form_template["canSaveSubmission"] = this.state.canSaveSubmission;
      form_template["canAnnotateContent"] = this.state.canAnnotateContent;
      form_template["canSubmitSubmission"] = this.state.canSubmitSubmission;
      form_template["canEditSubmission"] = this.state.canEditSubmission;
      form_template["autoSave"] = this.state.autoSave;
      form_template["isSingleUse"] = this.state.isSingleUse;

      form_template[
        "formSubmittedDateFormat"
      ] = this.state.formSubmittedDateFormat;
      form_template["coverImage"] = coverImage;
      form_template["logo"] = logo;

      form_template["credentialId"] = SequentialFormContainer.getCredentialId();

      form_template["linkedComponent"] = this.state.linkedComponent;

      if (this.state.linkedComponents) {
        let lc = [];
        for (let a = 0; a < this.state.linkedComponents.length; a++) {
          lc.push(this.state.linkedComponents[a].componentId);
        }
        form_template["linkedComponents"] = lc;
      }

      if (SequentialFormContainer.notificationMethod === "email") {
        form_template["notificationMethod"] = {
          method: SequentialFormContainer.notificationMethod,
          credentialId: SequentialFormContainer.credentialId,
        };
      } else {
        form_template["notificationMethod"] = {
          method: SequentialFormContainer.notificationMethod,
        };
      }

      let lastModified = getCurrentTime();
      let submitData = {
        componentData: {
          data: form_template,
          name: this.props.ComponentName.value,
          lastModified: lastModified,
          isDynamicForm: isDynamicForm
            ? isDynamicForm
            : this.state.isDynamicForm,
        },
        componentId: this.state.form_id,
        prefixLabel: this.state.prefixLabel,
        type: !this.props.isFormSection ? "sequential_form" : "form_section",
        description: this.props.ComponentDescription.value,
        instruction: this.state.instruction, // required to show instruction on first Save
        // instructions:this.state.instructions, //skipping instructions as it is failing form save
        status: this.state.status,
      };

      if (sessionId) submitData["sessionId"] = sessionId; // Set session id if given
      if (this.state.isInAIDraft) {
        // setting session id to ensure that proposal reloads the translation
        submitData["sessionId"] = this.state.compSessionId;
      }
      this.setState({ component: submitData });

      let dataUrl = !this.props.isFormSection
        ? "component-service/sequential_form/data"
        : "component-service/form_section/data";

      return send_request(dataUrl, submitData, "", "POST")
        .then(async (response) => {
          ProjectStore.savedComponent = true;

          SequentialFormContainer.onEdit = false;
          SequentialFormContainer.editTarget = "";
          SequentialFormContainer.onSave = false;

          const validate = await this.validate().then(() => {
            if (this.state.validationError) {
              if (!showDialog) {
                this.setState({ savedWithErrors: false, saveStatus: 200 });
              } else {
                this.setState({ savedWithErrors: true, saveStatus: 200 });
              }
            } else {
              if (!showDialog) {
                this.setState({
                  onBack: false,
                  saveD: false,
                  saveStatus: 200,
                  lastUpdate: lastModified,
                  savedWithErrors: false,
                });
              } else {
                this.setState({
                  onBack: false,
                  saveD: true,
                  saveStatus: 200,
                  lastUpdate: lastModified,
                  savedWithErrors: false,
                });
              }
              this.props.onLastModifiedChanged(lastModified);
            }
          });
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          // Only re-fetch form if related to AI (making consistent with trigger-form)
          if (sessionId) {
            setTimeout(() => {
              this.updateForm(true);
            }, 1000);
          }
        });
    } catch (e) {
      this.setState({ saveD: true, saveStatus: 500 });
    }
  };
  goBackToCanvas = () => {
    window.location.href = `/project/canvas/${this.props.params.id}`;
  };

  deleteItem = (id) => {
    let cards = toJS(SequentialFormContainer.pages);
    let result = cards.filter((item) => item.id !== id);
    SequentialFormContainer.pages = result;
  };

  onImageDrop = async (files, type) => {
    this.setState({
      loadingUpload: true,
    });
    let project_id = this.props.params.id;
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;
    if (project_id) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("formId", formId);
      formData.append("projectId", project_id);

      const json = await send_request(
        "component-service/form/upload-cover-image",
        formData,
        "",
        "POST",
        {
          "content-type": "multipart/form-data",
        }
      ).catch((err) => {
        this.setState({
          loadingUpload: false,
        });
        throw err;
      });

      if (json && json.data) {
        if (files && files.length > 0) {
          const reader = new FileReader();
          if (type === "coverImage") {
            const coverImageUploaded = {
              name: json.data.fileName,
              id: json.data.id,
            };
            reader.addEventListener("load", () =>
              this.setState({
                coverImageSrc: reader.result,
                uploadedCoverImage: coverImageUploaded,
                loadingUpload: false,
                errorCoverImageMessage: "",
              })
            );
          } else if (type === "logo") {
            reader.addEventListener("load", () =>
              this.setState({
                uploadedLogo: files[0],
                loadingUpload: false,
                errorCoverImageMessage: "",
              })
            );
          }

          reader.readAsDataURL(files[0]);
        }
      }
    }
  };

  onImageLoaded = (image, type) => {
    if (type === "coverImage") {
      this.coverImageRef = image;
    } else if (type === "logo") {
      this.logoRef = image;
    }
  };

  onCropComplete = (crop, type) => {
    this.makeClientCrop(crop, type);
  };

  onCropChange = (crop, type) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    if (type === "coverImage") {
      crop.aspect = 7 / 1;
      this.setState({ coverImageCrop: crop });
    } else if (type === "logo") {
      crop.aspect = 1;
      this.setState({ logoCrop: crop });
    }
  };

  async makeClientCrop(crop, type) {
    if (type === "coverImage") {
      if (this.coverImageRef && crop.width && crop.height) {
        await this.getCroppedImg(this.coverImageRef, crop);
      }
    }
  }

  getCroppedImg(image, crop) {
    image.crossOrigin = "anonymous";
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    this.setState({
      coverImageCrop: {
        aspect: 7 / 1,
        scaleX: scaleX,
        scaleY: scaleY,
        width: crop.width,
        height: crop.height,
        x: crop.x,
        y: crop.y,
        naturalWidth: crop.width * scaleX,
        naturalHeight: crop.height * scaleY,
        naturalX: crop.x * scaleX,
        naturalY: crop.y * scaleY,
      },
    });
  }

  formPreview = () => {
    const { form_id, saveD } = this.state;
    this.handleSubmit("published", true).then(() => {
      let saveStatus = this.state.saveStatus;

      if (saveStatus && saveStatus == 200) {
        setTimeout(() => {
          let project_id = this.props.params.id;

          send_request(
            `project-service/project/last-version/${project_id}`,
            "",
            "",
            "get"
          ).then((res) => {
            if (res && res.data) {
              let { draftVersion } = res.data;
              const url = `${config.FORM.FORM_FRONT_END}/form/form_preview/${form_id}/${draftVersion}`;
              window.open(url, "_blank");
            }
          });
        }, 1000);
      }
    });
  };

  previewIcon = (uploadedLogo) => {
    const reader = (file) => {
      if (Object.keys(file).length > 0) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.readAsDataURL(file);
        });
      }
    };
    if (uploadedLogo !== "") {
      if (Object.keys(uploadedLogo).length > 0) {
        reader(uploadedLogo).then((result) => {
          this.setState({ uploadIcon: result });
        });
      }
    }
  };

  exportFormDataAsCsv = () => {
    var project_id = this.props.params.id;
    var form_id = this.state.form_id;
    send_request(
      "project-service/project-session/download_form_submission_csv/" +
        project_id +
        "/" +
        form_id,
      {},
      "",
      "GET",
      {},
      "arraybuffer"
    )
      .then((response) => {
        const filename = "submissionData.csv";
        var blob = new Blob([response.data]);
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((err) => console.log(err));
  };

  exportFormDataAsXLSX = () => {
    var project_id = this.props.params.id;
    var form_id = this.state.form_id;
    send_request(
      "project-service/project-session/download_form_submission_xlsx/" +
        project_id +
        "/" +
        form_id,
      {},
      "",
      "GET",
      {},
      "arraybuffer"
    )
      .then((response) => {
        const filename = "submissionData.xlsx";
        var blob = new Blob([response.data]);
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((err) => console.log(err));
  };

  onDropRejected = (type) => {
    if (type === "coverImage") {
      this.setState({
        errorCoverImageMessage: "Maximum file upload size is 2MB",
      });
    } else if (type === "logo") {
      this.setState({
        errorLogoMessage: "Maximum file upload size is 2MB",
      });
    }
  };

  onLogoDrop(files) {
    this.previewIcon(files[0]);
    this.setState({
      uploadedLogo: files[0],
      errorLogoMessage: "",
    });
  }

  deleteCoverImage = async () => {
    const { uploadedCoverImage } = this.state;
    const data = [];
    data.push(uploadedCoverImage.id);
    let requestBody = {
      deleteList: data,
      project_id: this.props.params.id,
    };
    const json = await send_request(
      `project-service/project/file-library/deletes`,
      requestBody,
      "",
      "POST"
    ).catch((err) => {
      throw Error(err.message);
    });

    if (json && json.status == "200") {
      this.setState({
        uploadedCoverImage: "",
        coverImageSrc: "",
        coverImageCrop: {
          unit: "%",
          width: 30,
          aspect: 7 / 1,
        },
      });

      this.handleSubmit("published", false);
    }
  };

  handleDynamicPlaceholder = (action) => {
    let open = false;
    let data = toJS(SequentialFormContainer.saveTemplate());

    let { isDynamicForm } = this.state;

    if (!isDynamicForm) {
      const placeholderRegex = new RegExp("\\$\\{(\\w.*?)\\}", "g");
      const questionContent = JSON.stringify(data);
      const found = questionContent.match(placeholderRegex);

      if (found && found.length > 0) {
        open = true;
      }
    }

    this.setState({
      openDynamicPlaceholder: open,
      action,
    });
    return open;
  };

  setInnerPlaceholders = () => {
    //Search for inner form placeholders
    this.setState({
      innerPlaceholders: SequentialFormContainer.placeholders[0].placeholders.concat(
        searchForInnerFormPlaceholders(SequentialFormContainer.pages)
      ),
    });
  };

  updateComponent = async (
    discardBuildInstruction = false,
    discardEditInstructions = false
  ) => {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    let eventData = {
      componentId: formId,
      instruction: this.state.instruction,
      discardBuildInstructions: discardBuildInstruction,
      discardEditInstructions: discardEditInstructions,
    };

    let saveEvent = createSaveEvent(
      "UPDATE_COMPONENT",
      ProjectStore.project_id,
      { x: null, y: 0.0, zoom: 0.0 },
      [],
      eventData
    );

    //send event
    await send_request_graphql_mutation(
      `project-service/graphql/project/save/${ProjectStore.project_id}`,
      SAVE_EVENT(saveEvent),
      "",
      "POST"
    )
      .then((response) => {
        if (response.data.saveEvent && response.data.saveEvent.updated) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setComponentInstruction = async (instruction) => {
    this.setState({ instruction: instruction });
  };

  removeForceStartTestParams = async () => {
    if (this.props.queryParams) {
      this.props.queryParams.delete("force_start");
      this.props.queryParams.delete("selectedSession");
      this.props.navigate({ search: this.props.queryParams.toString() });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.delete("force_start");
      params.delete("selectedSession");
      this.props.navigate({ search: params.toString() });
    }
  };

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  handleExpand = () => {
    this.setState({ isFullScreen: true });
    this.props.handleExpand();
  };

  handleCollapse = () => {
    this.setState({ isFullScreen: false });
    this.props.handleCollapse();
  };

  saveSequentialForm = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      this.handleSubmit("published").then(() => {
        if (this.state.saveStatus == 200) {
          this.props.showSuccess({}, this.props.SaveTrigger);
          this.props.onLastModifiedChanged(this.state.lastModified);
        }
        ProjectStore.savedComponent = true;
      });
    }
  );

  render() {
    let data = toJS(SequentialFormContainer.saveTemplate());
    const { item } = this.props;
    const routes = [
      {
        path: `/project/canvas/${this.props.params.id}`,
        breadcrumbName: "Workflow Canvas",
      },
      {
        breadcrumbName: !this.props.isFormSection
          ? "Assign Task"
          : "Form Section",
      },
    ];
    const { form_id, coverImageSrc, coverImageCrop } = this.state;
    return (
      <FormContext.Consumer>
        {({
          settings,
          formLink,
          formExport,
          formEmbed,
          componentSettings,
          styling,
          placeholders,
        }) => {
          const [openSettings, setOpenSettings] = settings;
          const [openFormLink, setOpenFormLink] = formLink;
          const [openExport, setOpenExport] = formExport;
          const [openEmbed, setOpenEmbed] = formEmbed;
          const [
            openComponentSettings,
            setOpenComponentSettings,
          ] = componentSettings;
          const [openUploadDialog, setOpenUploadDialog] = styling;
          const [isDynamicForm, setIsDynamicForm] = placeholders;

          setIsDynamicForm(this.state.isDynamicForm);

          return (
            <Grid container>
              {this.state && !this.state.loading ? (
                <Grid
                  item
                  xs
                  style={{
                    marginRight: this.state.aiComponentBuilderOpen
                      ? config.AI_COMPONENT_BUILDER_PANE_WIDTH
                      : this.state.openTestPanel
                      ? config.TEST_PANE_WIDTH
                      : "0px",
                  }}
                >
                  <Grid container direction="column" xs={12} spacing={1}>
                    <ValidationErrors
                      open={this.state.errorDialog}
                      close={() => {
                        this.setState({ errorDialog: false, onBack: false });
                      }}
                      errors={this.state.errors}
                    />
                    <SavedWithErrors
                      errors={this.state.errors}
                      open={this.state.savedWithErrors}
                      close={() => {
                        this.setState({ savedWithErrors: false });
                      }}
                      onBack={this.state.onBack}
                      closeAndExit={() => {
                        this.setState({ savedWithErrors: false });
                        let path = `${config.APP_URL}/project/canvas/${this.props.params.id}`;
                        window.open(path, "_self");
                      }}
                    />

                    <CustomDialog
                      isOpen={this.state.openDynamicPlaceholder}
                      title={<>You are using placeholders in this form</>}
                      contents={
                        <div style={{ color: "#868686", fontSize: 14 }}>
                          We have detected that you are using placeholders in
                          the form. In order for placeholder values to be
                          substituted in this task, you will need to turn on the
                          “Allow placeholders in form” setting.
                        </div>
                      }
                      buttons={
                        <>
                          <Button
                            onClick={() => {
                              this.setState({
                                openDynamicPlaceholder: false,
                              });

                              if (this.state.action === "onSave") {
                                if (!ProjectStore.state.production) {
                                  this.handleSubmit("published", true);
                                  this.validate();
                                }
                              } else if (this.state.action === "onBack") {
                                this.onBreadcrumb(this.state.path);
                              }
                            }}
                            variant={"outlined"}
                            color={"info"}
                          >
                            DISREGARD
                          </Button>

                          <Button
                            onClick={() => {
                              this.setState({
                                openDynamicPlaceholder: false,
                                isDynamicForm: true,
                              });
                              if (this.state.action === "onSave") {
                                if (!ProjectStore.state.production) {
                                  this.handleSubmit("published", true, true);
                                  this.validate();
                                }
                              } else if (this.state.action === "onBack") {
                                this.onBreadcrumb(this.state.path, true);
                              }
                            }}
                            variant={"contained"}
                            color={"primary"}
                          >
                            TURN ON NOW
                          </Button>
                        </>
                      }
                    />

                    <FormComponentSettings
                      openComponentSettings={openComponentSettings}
                      setOpenComponentSettings={setOpenComponentSettings}
                      exposeMeta={this.state.exposeMeta}
                      prefixLabel={this.state.prefixLabel}
                      formSubmittedDateFormat={
                        this.state.formSubmittedDateFormat
                      }
                      prefixLabelErrorMsg={this.state.prefixLabelErrorMsg}
                      formId={this.state.form_id}
                      setExposeMeta={(val) => {
                        this.setState({ exposeMeta: val });
                      }}
                      setPrefixLabel={(val) => {
                        this.setState({ prefixLabel: val });
                      }}
                      setPrefixLabelErrorMsg={(val) => {
                        this.setState({ prefixLabelErrorMsg: val });
                      }}
                      handleSubmit={this.handleSubmit}
                      setFormSubmittedDateFormat={(val) => {
                        this.setState({ formSubmittedDateFormat: val });
                      }}
                      isDynamicForm={this.state.isDynamicForm}
                      setIsDynamicForm={(val) => {
                        this.setState({ isDynamicForm: val });
                        setIsDynamicForm(val);
                      }}
                    />

                    <FormLinkDialog
                      openFormLink={openFormLink}
                      setOpenFormLink={setOpenFormLink}
                      formId={this.state.form_id}
                    />
                    <FormSettingsDialog
                      openSettings={openSettings}
                      setOpenSettings={(val) => setOpenSettings(val)}
                      linkedComponent={this.state.linkedComponent}
                      setLinkedComponents={(val) =>
                        this.setState({ linkedComponents: val })
                      }
                      setLinkedComponent={(val) =>
                        this.setState({ linkedComponent: val })
                      }
                      relatedSequentialForm={this.state.relatedSequentialForm}
                      linkedComponents={this.state.linkedComponents}
                      submMsg={this.state.submMsg}
                      setSubmMsg={(val) => this.setState({ submMsg: val })}
                      canEditSubmission={this.state.canEditSubmission}
                      setCanEditSubmission={(val) =>
                        this.setState({ canEditSubmission: val })
                      }
                      canReadOnly={this.state.canReadOnly}
                      setCanReadOnly={(val) =>
                        this.setState({ canReadOnly: val })
                      }
                      canAddSubmission={this.state.canAddSubmission}
                      setCanAddSubmission={(val) =>
                        this.setState({ canAddSubmission: val })
                      }
                      canSaveSubmission={this.state.canSaveSubmission}
                      setCanSaveSubmission={(val) =>
                        this.setState({ canSaveSubmission: val })
                      }
                      autoSave={this.state.autoSave}
                      setAutoSave={(val) => this.setState({ autoSave: val })}
                      canAnnotateContent={this.state.canAnnotateContent}
                      setCanAnnotateContent={(val) =>
                        this.setState({ canAnnotateContent: val })
                      }
                      canSubmitSubmission={this.state.canSubmitSubmission}
                      setCanSubmitSubmission={(val) =>
                        this.setState({ canSubmitSubmission: val })
                      }
                      handleCancelSettings={() =>
                        this.handleCancelSettings(setOpenSettings)
                      }
                    />
                    <FormStylingDialog
                      coverImageCrop={this.state.coverImageCrop}
                      coverImageSrc={this.state.coverImageSrc}
                      openUploadDialog={openUploadDialog}
                      setOpenUploadDialog={setOpenUploadDialog}
                      onImageLoaded={this.onImageLoaded}
                      onCropChange={this.onCropChange}
                      onCropComplete={this.onCropComplete}
                      onImageDrop={this.onImageDrop}
                      onDropRejected={this.onDropRejected}
                      uploadedCoverImage={this.state.uploadedCoverImage}
                      errorCoverImageMessage={this.state.errorCoverImageMessage}
                      uploadedLogo={this.state.uploadedLogo}
                      errorLogoMessage={this.state.errorLogoMessage}
                      loadingUpload={this.state.loadingUpload}
                      deleteCoverImage={this.deleteCoverImage}
                      onLogoDrop={(files) => this.onLogoDrop(files)}
                      setUploadedLogo={(value) =>
                        this.setState({ uploadedLogo: value })
                      }
                      setUploadIcon={(value) =>
                        this.setState({ uploadIcon: value })
                      }
                    />
                    <FormExportDialog
                      openExport={openExport}
                      setOpenExport={setOpenExport}
                      exportType={this.state.exportType}
                      setExportType={(val) => {
                        this.setState({ exportType: val.exportType });
                      }}
                      exportFormDataAsCsv={this.exportFormDataAsCsv}
                      exportFormDataAsXLSX={this.exportFormDataAsXLSX}
                    />

                    <CustomDialog
                      size={
                        SequentialFormContainer.editTarget.type === 15
                          ? "xl"
                          : "md"
                      }
                      isOpen={SequentialFormContainer.onEdit}
                      key={SequentialFormContainer.editTarget.id}
                      onClose={() => {
                        SequentialFormContainer.onEdit = false;
                        SequentialFormContainer.reloadEditComponent();
                      }}
                      title={SequentialFormContainer.editTarget.text}
                      contents={
                        <ComponentConfig
                          ref={this.Config}
                          key={SequentialFormContainer.editTarget.id}
                          handleKeyChanges={(error) => {
                            this.setState({ error });
                          }}
                          checkPlaceholderForm={(action) =>
                            this.handleDynamicPlaceholder(action)
                          }
                          availablePlaceholders={this.state.innerPlaceholders}
                          isDynamicForm={this.state.isDynamicForm}
                          setInnerPlaceholders={this.setInnerPlaceholders}
                        />
                      }
                      buttons={
                        <>
                          <DiscardChangesButton disabled={this.state.error || SequentialFormContainer.onSave} />
                          <Button
                            disabled={
                              this.state.error || SequentialFormContainer.onSave
                            }
                            onClick={this.click}
                            variant={"contained"}
                            color={"primary"}
                          >
                            Save
                            {SequentialFormContainer.onSave && (
                              <CircularProgress
                                size={24}
                                className="buttonProgress"
                              />
                            )}
                          </Button>
                        </>
                      }
                    />

                    <Grid
                      container
                      xs={12}
                      direction={"row"}
                      spacing={2}
                      style={{
                        padding: "0px 16px 16px 16px",
                        position: "relative",
                      }}
                    >
                      <Grid item xs={9}>
                        <Target
                          SelectedPlaceholder={
                            SequentialFormContainer.SelectedPlaceholder
                          }
                          formId={this.state.form_id}
                          availablePlaceholders={
                            SequentialFormContainer.placeholders
                          }
                          coverImage={coverImageSrc}
                          coverImageCrop={coverImageCrop}
                          linkedComponent={this.state.linkedComponent}
                          launchSettingDialog={() => {
                            setOpenSettings(true);
                          }}
                          isFormSection={this.props.isFormSection}
                          setInnerPlaceholders={() =>
                            this.setInnerPlaceholders()
                          }
                          uploadIcon={this.state.uploadIcon}
                          formTitle={ProjectStore.ComponentName.value}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ComponentsSidebar
                          componentId={this.state.form_id}
                          projectId={this.props.params.id}
                          nodeId={SequentialFormContainer.node.id}
                          isFormSection={this.props.isFormSection}
                        />
                      </Grid>
                      <Snackbar
                        open={
                          ProjectStore.state.production &&
                          !ProjectStore.state.canvasView
                        }
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          icon={<></>}
                          variant={"filled"}
                          style={{ backgroundColor: "#303030" }}
                        >
                          🔒 This is a view of the production workflow. It
                          cannot be edited and changes will not be saved.
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={ProjectStore.state.canvasView}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          icon={<></>}
                          variant={"filled"}
                          style={{ backgroundColor: "#303030" }}
                        >
                          🔒 You are viewing version{" "}
                          {ProjectStore.publishedVersion}. This workflow has
                          been published and cannot be edited.
                        </Alert>
                      </Snackbar>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <IndividualComponentLoader />
              )}
            </Grid>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default withParams(inject("ProjectStore")(observer(SequentialForm)));
