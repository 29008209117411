import "./index.css";

import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import Theme from "./Theme.js";

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={Theme}>
      <div>
        <App />
      </div>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
