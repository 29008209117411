import React from "react";
import { Fade, Grid, Typography } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Settings, Output, Storage, VpnKey } from "@mui/icons-material";
import "./initialMessages.css"; // Import the CSS file
import ContextChips from "./ContextChips"; // Import the ContextChips component

const CONTEXT_CHIPS = [
  { 
    label: "All settings in this component",
    tooltip: "All current settings and content in the current component.",
    icon: <Settings fontSize="small" />,
    applicableTypes: ['all']
  },
  { 
    label: "Input placeholders",
    tooltip: "All placeholders from upstream components connected into this component.",
    icon: <Output fontSize="small" />,
    applicableTypes: ['all']
  },
  { 
    label: "Active and connected databases",
    tooltip: "Any databases actively being used by a component in this workflow, or connected but not actively used. I can choose to use these databases if appropriate.",
    icon: <Storage fontSize="small" />,
    applicableTypes: ['query_database_record', 'update_database_record', 'create_database_record']
  },
  { 
    label: "Secrets and credentials",
    tooltip: "Any secret and credential placeholders available to this workflow or component but I will not know their values. I can choose to use these secrets and credentials if appropriate.",
    icon: <VpnKey fontSize="small" />,
    applicableTypes: ['code', 'api_request_and_response']
  }
];

const EditComponentMessage = ({
  classes,
  sidebarState,
  setSidebarState,
  messageSent,
  componentType,
}) => {
  const getContextChips = () => {
    const type = (componentType || '').toLowerCase() || 'all';
    return CONTEXT_CHIPS.filter(chip => 
      (chip.applicableTypes.includes('all') || chip.applicableTypes.includes(type)) &&
      (chip.label !== "Secrets and credentials" || ['code', 'api_request_and_response'].includes(type)) &&
      (chip.label !== "Connected and used databases" || 
        ['query_database_record', 'update_database_record', 'create_database_record'].includes(type))
    );
  };

  const contextChips = getContextChips();

  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <Grid container spacing={3}>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <span className={"bold"}>
                <img
                  src="/svgs/edit_square.svg"
                  className="autoAwesomeIcon"
                />
                Edit this Component
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12} className="messagePadding">
            I am currently operating in edit mode. This means I will only make
            specific changes according to your instructions or requirements, and
            leave everything else in the component unchanged.
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <b>💡 Tips</b>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            Be specific about what you want to change and what you do not want
            me to change. By default, I will only apply changes to what you have
            instructed and leave everything else the same.
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <b>ℹ️ Context</b>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
               I will have access to the following context when editing this component:
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <ContextChips chips={contextChips} />
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

EditComponentMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.string.isRequired,
  messageSent: PropTypes.bool.isRequired,
  componentType: PropTypes.string.isRequired,
};

export default withStyles(AIBuilderStyles())(EditComponentMessage);
