import React, { useState, useEffect } from "react";

// MUI
import { styled } from "@mui/system";
import { Grid, TextField } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

// Custom components
import { parsingTablePreviewInput } from "../TableHelpers";
import Autocomplete from "./Autocomplete";
import { tableFunctions } from "./TableFunctions";

const StyledTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    color: "rgba(134, 134, 134, 1)",
    fontSize: "10px",
    lineHeight: "10px",
    fontSize: "13px"
  },
  marginTop: "8px"
});

const StyledLabel = styled("span")({
  position: "absolute",
  color: "rgba(134, 134, 134, 1)",
  top: 3,
  left: 3,
  fontSize: "7px",
  lineHeight: "7px",
  marginTop: "8px"
});

const TableCellItem = styled(Grid)({
  position: "relative",
  maxWidth: "200px !important",
  overflow: "hidden",
  ".jodit-wysiwyg": {
    fontSize: "13px",
    marginTop: "3px !important",
    overflowWrap: "anywhere !important"
  }
});

function TableCell(props) {
  const [loading, setLoading] = useState(true);
  const [placeholders, setPlaceholders ] = useState([]);
  // For loading each cell
  // Need to set a timeout to speed up the initial on load
  // Of the table
  useEffect(() => {
    //we do this to match the initial data structure of the placeholder array
    //it had to change to accommodate inner form placeholders in the rest of the form
    //so now we have to wrap it like this so Autocomplete component doesn't crash
    let array = [{ placeholders: props.availablePlaceholders }];
    setPlaceholders(array);

    setTimeout(() => {
      setLoading(false);
    }, 50);
  }, []);

  const updateTableCells = (givenVal) => {
    let newTableCells = { ...props.tableCells };

    let content = {
      newTableCells: newTableCells,
      colId: props.colId,
      colIdx: props.colIdx,
      rowIdx: props.rowIdx,
      value: givenVal.toString(),
      columns: props.columns
    };

    // Parse the input value & update table cells
    parsingTablePreviewInput(content);
    props.setTableCells(newTableCells);
  };

  return !loading ? (
    <TableCellItem item xs={12}>
      <StyledLabel>{props.label}</StyledLabel>
      <Autocomplete
        style={{
          width: "100%",
          marginTop: "8px",
          minHeight: "40px"
        }}
        onChange={(value) => {
          if (value != null) updateTableCells(value);
        }}
        onFocus={(e) => {}}
        hideScrollBar={true}
        functions={tableFunctions}
        text={props.cell.rawValue}
        {...(props.availablePlaceholders != null && {
          availablePlaceholders: placeholders
        })}
      />
    </TableCellItem>
  ) : (
    <Grid item xs={12}>
      <Skeleton variant="text" height={50} animation="wave" />
    </Grid>
  );
}

export default TableCell;
