import React from "react";
import { CustomDialog } from "../../Component/Dialog";
import {
  Select,
  MenuItem,
  ButtonGroup,
  TextField as MuiTextfield,
  Chip as MuiChip,
  Checkbox,
} from "@mui/material";
import TextField from "vardogyir-ui/TextField";
import { Autocomplete } from "@mui/lab";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

export const FormSettingsDialog = (props) => {
  const { isForm } = props;

  return (
    <CustomDialog
      isOpen={props.openSettings}
      onClose={() => {
        props.setOpenSettings(false);
      }}
      title={"Form settings"}
      contents={
        <>
          <p className={"stand-alone-text"}>After this form is submitted</p>
          <p className={"s-text"}>
            Select what happens when this form is submitted{" "}
          </p>
          <Select
            onChange={(e) => {
              props.setLinkedComponent(e.target.value);
            }}
            value={props.linkedComponent}
          >
            <MenuItem value={false}>display submission page</MenuItem>
            <MenuItem value={true}>redirect to another page</MenuItem>
          </Select>

          {props.linkedComponent && (
            <>
              <Autocomplete
                style={{ marginTop: "8px" }}
                options={props.relatedSequentialForm}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <MuiChip
                      key={index}
                      color="default"
                      label={option.label}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size={"small"}
                multiple
                freeSolo={false}
                openOnFocus={true}
                getOptionLabel={(option) => {
                  return option.label;
                }}
                variant={"outlined"}
                value={props.linkedComponents}
                blurOnSelect={false}
                className={"autocomplete"}
                onChange={(event, inputValue, reason) => {
                  console.log("======= " + inputValue);
                  if (reason == "clear") {
                    props.setLinkedComponents([]);
                  } else {
                    props.setLinkedComponents(inputValue);
                  }
                }}
                renderInput={(params) => {
                  return (
                    <MuiTextfield
                      {...params}
                      placeholder={"Select next page"}
                      size={"small"}
                      variant="outlined"
                      error={props.linkedComponents.length === 0}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: 0,
                        },
                      }}
                      helperText={
                        props.linkedComponents.length === 0 &&
                        "Select a form to redirect to when this form is submitted"
                      }
                    />
                  );
                }}
              />
            </>
          )}

          <p className={"primary-text"}>Submission Message</p>
          <p className={"s-text"}>
            Specify the message displayed after a submission has been made.{" "}
          </p>
          <TextField
            variant="outlined"
            multiline
            rows={3}
            onChange={(event) => {
              props.setSubmMsg(event.target.value);
            }}
            value={props.submMsg}
            defaultValue={
              "Thank you for your submission. Your response has been recorded."
            }
            placeholder="Type in a message to be displayed when the user submits the form"
          />
          <p className={"stand-alone-text"}>
            Allow the user to view, edit and resubmit their response
          </p>
          <ButtonGroup>
            <Button
              id={"ON"}
              value={true}
              className="d-button"
              color={props.canEditSubmission ? "primary" : "info"}
              variant={props.canEditSubmission ? "contained" : "outlined"}
              onClick={() => {
                props.setCanEditSubmission(true);
                props.setCanReadOnly(false);
              }}
            >
              ON
            </Button>
            <Button
              id={"READ"}
              value={true}
              className="d-btn-read-only"
              color={props.canReadOnly ? "primary" : "info"}
              variant={props.canReadOnly ? "contained" : "outlined"}
              onClick={() => {
                props.setCanEditSubmission(null);
                props.setCanReadOnly(true);
              }}
            >
              Read only
            </Button>
            <Button
              id={"OFF"}
              value={false}
              className="d-button"
              color={
                props.canEditSubmission == true ||
                props.canEditSubmission == null
                  ? "info"
                  : "primary"
              }
              variant={
                props.canEditSubmission == true ||
                props.canEditSubmission == null
                  ? "outlined"
                  : "contained"
              }
              onClick={() => {
                props.setCanEditSubmission(false);
                props.setCanReadOnly(false);
              }}
            >
              OFF
            </Button>
          </ButtonGroup>
          {isForm ? (
            <>
              <p className={"stand-alone-text"}>
                Allow user to start a new submission after finishing the form
              </p>
              <ButtonGroup>
                <Button
                  className="d-button"
                  color={props.canAddSubmission ? "primary" : "info"}
                  variant={props.canAddSubmission ? "contained" : "outlined"}
                  onClick={() => props.setCanAddSubmission(true)}
                >
                  ON
                </Button>
                <Button
                  className="d-button"
                  color={props.canAddSubmission ? "info" : "primary"}
                  variant={props.canAddSubmission ? "outlined" : "contained"}
                  onClick={() => props.setCanAddSubmission(false)}
                >
                  OFF
                </Button>
              </ButtonGroup>
              <p className={"stand-alone-text"}>
                Allow user to save their response
              </p>
              <ButtonGroup>
                <Button
                  className="d-button"
                  color={props.canSaveSubmission ? "primary" : "info"}
                  variant={props.canSaveSubmission ? "contained" : "outlined"}
                  onClick={() => props.setCanSaveSubmission(true)}
                >
                  ON
                </Button>
                <Button
                  className="d-button"
                  color={props.canSaveSubmission ? "info" : "primary"}
                  variant={props.canSaveSubmission ? "outlined" : "contained"}
                  onClick={() => {
                    props.setCanSaveSubmission(false);
                    props.setAutoSave(false);
                  }}
                >
                  OFF
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <>
              <p className={"stand-alone-text"}>
                Allow user to save their response
              </p>
              <ButtonGroup>
                <Button
                  className="d-button"
                  color={props.canSaveSubmission ? "primary" : "info"}
                  variant={props.canSaveSubmission ? "contained" : "outlined"}
                  onClick={() => props.setCanSaveSubmission(true)}
                >
                  ON
                </Button>
                <Button
                  className="d-button"
                  color={props.canSaveSubmission ? "info" : "primary"}
                  variant={props.canSaveSubmission ? "outlined" : "contained"}
                  onClick={() => {
                    props.setCanSaveSubmission(false);
                    props.setAutoSave(false);
                  }}
                >
                  OFF
                </Button>
              </ButtonGroup>
            </>
          )}
          {props.canSaveSubmission && (
            <div className="autosaveGrid">
              <Checkbox
                checked={props.autoSave}
                onClick={() => props.setAutoSave(!props.autoSave)}
                className="notify-email-checkbox"
                size="medium"
              />
              <span className="comment-email-text">
                Auto-save form every 30 seconds
              </span>
            </div>
          )}
          <p className={"stand-alone-text"}>
            Allow user to annotate the content
          </p>
          <ButtonGroup>
            <Button
              className="d-button"
              color={props.canAnnotateContent ? "primary" : "info"}
              variant={props.canAnnotateContent ? "contained" : "outlined"}
              onClick={() => props.setCanAnnotateContent(true)}
            >
              ON
            </Button>
            <Button
              className="d-button"
              color={props.canAnnotateContent ? "info" : "primary"}
              variant={props.canAnnotateContent ? "outlined" : "contained"}
              onClick={() => props.setCanAnnotateContent(false)}
            >
              OFF
            </Button>
          </ButtonGroup>
          <p className={"stand-alone-text"}>
            Allow user to submit their response
          </p>
          <ButtonGroup>
            <Button
              className="d-button"
              color={props.canSubmitSubmission ? "primary" : "info"}
              variant={props.canSubmitSubmission ? "contained" : "outlined"}
              onClick={() => props.setCanSubmitSubmission(true)}
            >
              ON
            </Button>
            <Button
              className="d-button"
              color={props.canSubmitSubmission ? "info" : "primary"}
              variant={props.canSubmitSubmission ? "outlined" : "contained"}
              onClick={() => props.setCanSubmitSubmission(false)}
            >
              OFF
            </Button>
          </ButtonGroup>
        </>
      }
      buttons={
        <>
          <Button
            onClick={props.handleCancelSettings}
            variant={"outlined"}
            color={"info"}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (props.linkedComponent && props.linkedComponents.length === 0)
                return;
              props.setOpenSettings(false);
            }}
            variant={"contained"}
            color={"primary"}
          >
            Done
          </Button>
        </>
      }
    />
  );
};

FormSettingsDialog.propTypes = {
  openSettings: PropTypes.bool.isRequired,
  linkedComponent: PropTypes.bool.isRequired,
  setLinkedComponent: PropTypes.func.isRequired,
  setOpenSettings: PropTypes.func.isRequired,
  relatedSequentialForm: PropTypes.array.isRequired,
  linkedComponents: PropTypes.array.isRequired,
  setLinkedComponents: PropTypes.func.isRequired,
  submMsg: PropTypes.string.isRequired,
  setSubmMsg: PropTypes.func.isRequired,
  canEditSubmission: PropTypes.bool,
  setCanEditSubmission: PropTypes.func.isRequired,
  canReadOnly: PropTypes.bool,
  setCanReadOnly: PropTypes.func.isRequired,
  canAddSubmission: PropTypes.bool.isRequired,
  setCanAddSubmission: PropTypes.func.isRequired,
  canSaveSubmission: PropTypes.bool.isRequired,
  setCanSaveSubmission: PropTypes.func.isRequired,
  autoSave: PropTypes.bool.isRequired,
  setAutoSave: PropTypes.func.isRequired,
  canAnnotateContent: PropTypes.bool.isRequired,
  setCanAnnotateContent: PropTypes.func.isRequired,
  canSubmitSubmission: PropTypes.bool.isRequired,
  isForm: PropTypes.bool,
  setCanSubmitSubmission: PropTypes.func.isRequired,
  handleCancelSettings: PropTypes.func.isRequired,
};
