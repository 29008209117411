import React from "react";
import "./FormTool.css";
import Drag from "./Images/drag.svg";


const FormTool = (props) => {
  return (
    <div
      className={"form-tools"}
      style={{
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: 4,
        maxWidth: 200,
        minWidth: 200,
        height: 36,
        paddingRight: 8,
        paddingLeft: 8
      }}
    >
      <img
        style={{
          display: "inline",
          height: 20,
          verticalAlign: "middle",
          marginRight: 8
        }}
        src={props.icon}
      />
      <p
        style={{
          display: "inline",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: 14,
          lineHeight: 3
        }}
      >
        {props.title}
      </p>
    </div>
  );
};

export default FormTool;
