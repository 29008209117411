import { AccountTree, Assignment, Edit, OpenInNew } from '@mui/icons-material'
import {Alert, Box, Button, IconButton, MenuItem} from '@mui/material'
import { styled } from '@mui/system'
import React, {useEffect, useState} from 'react'
import config from '../../config'
import TaskDetails from './TaskDetails'
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import {send_request} from "../../utils/Request";
import Menu from "@mui/material/Menu";
import SnackBar from "@mui/material/Snackbar";

const TopButton = styled(Button)(({ fontColour, bgColour, hoverBgColour }) => ({
  padding: "6px 18px",
  borderRadius: "4px",
  fontSize: "13px",
  lineHeight: "18px",
  letterSpacing: "1.25px",
  border: "1px solid #000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: fontColour,
  background: bgColour,
  "&:disabled": {
      color: "rgba(255, 255, 255, 0.6)",
      background: "rgba(0, 0, 0, 0.14)",
      border: "1px solid rgba(0, 0, 0, 0.14)",
  },
  "&:hover": {
      background: hoverBgColour,
  },
  marginRight: "1rem"
}));

const AppBarButtons = ({ editTask, openSessionLog, openInNewTab, viewProgress,
                           terminateTask, showTerminateDialog, closeTerminate,
                           open ,sendTerminateRequest,openSnackBar,handleCloseSnackbar, isExternal }) => (
  <>
    {!isExternal && 
    <>
      <TopButton
        fontColour={"#000"}
        bgColour={"#FFF"}
        hoverBgColour={"rgba(0, 0, 0, 0.05)"}
        startIcon={<Edit />}
        onClick={editTask}
      >
        Edit Task
      </TopButton>
        <div>

        <TopButton
            fontColour={"#000"}
            bgColour={"#FFF"}
            hoverBgColour={"rgba(0, 0, 0, 0.05)"}
            startIcon={ <DoDisturbIcon sx={{color: "black"}} />}
            onClick={terminateTask} >
            Terminate
        </TopButton>
            <Menu
                open={open}
                anchorEl={showTerminateDialog}
                onClose={closeTerminate}>
                <MenuItem value={1} onClick={sendTerminateRequest}>
                    🛑 Confirm terminate task</MenuItem>
            </Menu>
        </div>
      {/* <TopButton
        fontColour={"#000"}
        bgColour={"#FFF"}
        hoverBgColour={"rgba(0, 0, 0, 0.05)"}
      >
        Move Task
      </TopButton> */}
      <TopButton
        fontColour={"#000"}
        bgColour={"#FFF"}
        hoverBgColour={"rgba(0, 0, 0, 0.05)"}
        onClick={viewProgress}
        startIcon={<AccountTree />}
      >
        View Progress
      </TopButton>
    </>
    }
    <TopButton
      fontColour={"#000"}
      bgColour={"#FFF"}
      hoverBgColour={"rgba(0, 0, 0, 0.05)"}
      startIcon={<OpenInNew />}
      onClick={openInNewTab}
    >
      New Tab
    </TopButton>
      <SnackBar
          open={openSnackBar} autoHideDuration={6000}
          onClose={handleCloseSnackbar} style={{height:"max-content"}}   anchorOrigin={{
          vertical: "center",
          horizontal: "center"
      }} >
          <Alert  severity="success"
                  icon={false} variant={"filled"} style={{backgroundColor:"#55A77A"}} >
              ✅ Task successfully terminated
          </Alert>

      </SnackBar>
  </>
)
const ExpandedTaskPage = ({ task, close,handleRefresh }) => {

    const[showTerminateDialog,setShowTerminateDialog] = useState(false);
    const open = Boolean(showTerminateDialog);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
  if (task === null || task === undefined) {
    return null;
  }

  const { row, url } = task;

  const editTask = (task) => {
    window.open('/task/' + task.taskId + "?edit=true");
  }

  const openSessionLog = (task) => {
    window.open(
      config.APP_URL +
      "/project/logs/"
      +
      task.projectId +
      "/" +
      task.sessionId,
      "_blank"
    );
  };
    const terminateTask = (event) => {
        setShowTerminateDialog(event.target);

    }
    const closeTerminate=()=>
    {
        setShowTerminateDialog(null);
    }
    const showSnackbar =()=>
    {
        setOpenSnackbar(true);
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    }
    const sendTerminateRequest =async (task) => {
        let querydata = { isTest: false };
        if (task.threadId) querydata["threadid"] = task.threadId;
        const response = await send_request(
            `project-service/project/terminate_task/${task.sessionId}/${task.componentId}`,
            "",
            querydata,
            "post"
        ).catch((error) => {
            console.log(error);
        });
        if (response) {
            showSnackbar();
            handleRefresh();

        }
        setShowTerminateDialog(null);
    }
  const viewProgress = (task) => {
    window.open(
      "/project/logs/progress_view/"
      +
      task.projectId +
      "/" +
      task.sessionId,
      "_blank"
    );
  }

  const openInNewTab = (taskId) => {
    window.open(`/task?expanded=true&task_id=${taskId}`, "_blank")
  }


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <TaskDetails
        task={row}
        id={row.taskId}
        expanded
        appBarButtons={
          <AppBarButtons
            editTask={() => editTask(row)}
            openSessionLog={() => openSessionLog(row)}
            openInNewTab={() => openInNewTab(row.taskId)}
            viewProgress={() => viewProgress(row)}
            terminateTask={terminateTask}
            showTerminateDialog={showTerminateDialog}
            closeTerminate={closeTerminate}
            sendTerminateRequest={() => {
                sendTerminateRequest(row)
            }}
            open={open}
            openSnackBar={openSnackbar}
            handleCloseSnackbar={handleCloseSnackbar}
            isExternal={row.isExternal}
          />
        }
        close={close}
      />
      <iframe 
        style={{
          height: "800px",
          width: "100%",
          marginTop: "1rem",
          border: "none",
          borderRadius: "1px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        }}
        src={url}
      />
    </Box>
  )
}

export default ExpandedTaskPage