import uuid from "uuid";

// Creates all the sticky notes/links
// And return an array of them
export const createMapAZapStickyNotes = (data, url, yAdjust, position) => {
  let allNotes = [];

  for (let i = 0; i < data["titles"].length; i++) {
    let NOTE_CONTENT = `<p><img src="${
      data["iconUrls"][i]
    }" height="48px" width="48px" style="background-color: #fff; border: 6px solid #fff; border-radius: 4px;" /><br/><strong>Step ${i +
      1}</strong> <br/> <strong>${capitalizeFirstLetter(
      data["titles"][i]
    )}</strong> <br/> ${capitalizeFirstLetter(
      data["descriptions"][i]
    )} <br/> <br/><strong>Parameters</strong><br/><code><pre class="language-html" id="json">"params": ${JSON.stringify(
      data["params"][i],
      null,
      2
    )}</pre></code></p>`;

    const note = {
      id: uuid(),
      type: "note",
      position: {
        x: position["x"] + i * 400,
        y: position["y"] + 150 + yAdjust * 750
      },
      data: {
        content: NOTE_CONTENT,
        color: "#FDE3DA",
        width: 350,
        height: 575,
        creation: "map_zap"
      }
    };

    allNotes.push(note);
  }

  let allNoteLinks = createAllNoteLinks(allNotes);
  let topStickyNote = createTopSticky(url, yAdjust, position);
  return [topStickyNote, ...allNotes, ...allNoteLinks];
};

// Creates all the links between notes
const createAllNoteLinks = allNotes => {
  let allNoteLinks = [];

  for (let i = 0; i < allNotes.length; i++) {
    if (i < allNotes.length - 1) {
      let note1Id = allNotes[i]["id"];
      let note2Id = allNotes[i + 1]["id"];

      const link = {
        source: note1Id,
        sourceHandle: "note-right",
        target: note2Id,
        targetHandle: "note-left",
        style: { strokeWidth: "3px", stroke: "#000000" },
        type: "link",
        markerEnd: {
          type: "arrowclosed",
          color: "#868686",
        },
        id: uuid()
      };

      allNoteLinks.push(link);
    }
  }

  return allNoteLinks;
};

// Creates the sticky note at the top
const createTopSticky = (url, yAdjust, position) => {
  let NOTE_CONTENT = `<p> 🔗 Link to Zap: <i>insert link to Zap here</i> <br/> 📄 Template: ${url}</p>`;

  const note = {
    id: uuid(),
    type: "note",
    position: { x: position["x"], y: position["y"] + yAdjust * 750 },
    data: {
      content: NOTE_CONTENT,
      color: "#FBF6BE",
      width: 650,
      height: 125,
      creation: "map_zap"
    }
  };

  return note;
};

// Capitalizes the first letter of a string
const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Returns true if a given url string is a valid
// Zapier shared url
export const isValidSharedZapierUrl = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  if (!string.toLowerCase().includes("zapier")) return false;
  if (!string.toLowerCase().includes("shared")) return false;
  return url.protocol === "http:" || url.protocol === "https:";
};
