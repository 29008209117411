import { computed, observable, decorate, action, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";
import {days} from "../../utils/Days";

class ScheduleStartStore {
  constructor() {
    this.initialiseEmpty();
  }

  setScheduleStartBody = Body => {
    this.json = Body;
  };

  setTemplateData = data => {
    this.data = data;
  }

  setContent = (value, type) => {
    switch (type) {
      case "key":
        this.data.key = value;
        return;
      case "workflow":
        this.data.startWorkflow = value;
        return;
      case "date":
        this.data.date = value;
        return;
      case "time":
        this.data.time = value;
        return;
      case "selectDays":
        this.data.selectDays = days
        this.data.selectDays[value-1].active = !this.data.selectDays[value-1].active;
        return;
    }
  };

  setStopContent = (value, type) => {
    switch (type) {
      case "date":
        this.data.stopRunning.date = value;
        return;
      case "time":
        this.data.stopRunning.time = value;
        return;
    }
  };

  setRepeat = (value,type) => {
    switch (type) {
      case "interval":
        this.data.repeat.interval = value;
        return;
      case "type":
        this.data.repeat.type = value;
        return;
    }
  }

  setStopRunning = (value,type) => {
    switch (type) {
      case "times":
        this.data.stopRunning.times = value;
        return;
      case "type":
        this.data.stopRunning.type = value;
        return;
    }
  }

  setComponentData = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData && component.componentData.data) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.componentData();
      this.data = obj;
      this.lastModified = "";
      this.name = "";
    }
  };

  keyAuto = () => {
    return "schedule" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  };

  setMonth = (value) => {
    switch (value) {
      case 1:
        return "01"
      case 2:
        return "02"
      case 3:
        return "03"
      case 4:
        return "04"
      case 5:
        return "05"
      case 6:
        return "06"
      case 7:
        return "07"
      case 8:
        return "08"
      case 9:
        return "09"
      case 10:
        return "10"
      case 11:
        return "11"
      case 12:
        return "12"
    }
  }

  componentData = () => {
    let getTime = new Date().getTime();
    let DateTime = new Date(getTime);
    const date = DateTime.getDate()
    let month = DateTime.getMonth() + 1
    month = this.setMonth(month)
    const year = DateTime.getFullYear()
    const fullDate =  year+ "-" + month + "-"+date

    let hour = DateTime.getHours()
    if (hour < 10){
      hour = "0"+hour
    }
    let minutes = DateTime.getMinutes()
    if (minutes < 10){
      minutes = "0"+minutes
    }

    const fullTime = hour + ":" + minutes

    return {
      key: this.keyAuto(),
      startWorkflow: "specific_date_time",
      date: fullDate,
      time: fullTime,
      selectDays: days,
      repeat: {
        interval: 5,
        type: "minute"
      },
      stopRunning: {
          times: 1,
          type: "after_a_certain_number_of_repeats",
          date: fullDate,
          time: fullTime,
          timezone: "Australia/Hobart"
        },
      timezone: "Australia/Hobart",
    };
  };

  initialiseEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.position = null;
    this.componentId = "";
    this.type = "schedule";
    this.lastModified = "";
    this.data = obj;
    this.error = false;
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified
    };
  }

  setScheduleStartName = (name) => {
    this.name = name;
  };

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  };

}
decorate(ScheduleStartStore, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable,
  json: observable,
  type: observable,
  lastModified: observable,
  error: observable,
  setComponentName: action,
  addComponentData: action,
  setComponentData: action,
  setLastModified: action,
  setField: action,
  setFocus: action,
  deleteComponent: action,
  initialiseEmpty: action,
  template: computed,
});

const scheduleStartStore = new ScheduleStartStore();
export default scheduleStartStore;
