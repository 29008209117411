import React, { Fragment } from "react";

import { Grid } from "@mui/material";
import TransformStore from "../../TransformStore";
import { SelectArrange } from "../Component";

function Rearrange({ rearrange, index }) {
    return (
        <Fragment>
            <Grid item xs={12} className="item-label">
                Rearrange the values
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={8} lg={5} xl={4}>
                        <SelectArrange value={rearrange[0].func} onChange={(value) => TransformStore.setRearrange(value, index, "func", "rearrange")} />
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Rearrange;
