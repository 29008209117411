import {styled} from "@mui/system";
import Grid from "@mui/material/Grid";


const StyledWarningGrid = styled((props) => <Grid container {...props} />)(({ color }) => ({
    color: color ? color : "rgba(0, 0, 0, 0.54)",
    display: "flex",
    alignItems: "center",
    flexWrap: "noWrap",
    marginTop: '8px',
    '& span': {
        marginLeft: '8px'
    },
}));

export default StyledWarningGrid;
