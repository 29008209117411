import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import TriggerAnotherWorkflowStore from "./TriggerAnotherWorkflowStore";
import projectStore from "../ProjectCanvas/ProjectStore";
import { Link } from "react-router-dom";

// Utils
import { getCurrentTime } from "../../utils/getCurrentTime";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import { send_component_save_request, send_request, send_request_graphql } from "../../utils/Request";

// Custom
import { GET_COMPONENTS } from "./components/ComponentQuery";
import { Container } from "./components/StyledComponents";
import PlaceholderSelection from "./components/PlaceholderSelection";
import Dropdowns from "./components/Dropdowns";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";

class TriggerAnotherWorkflowTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: [],
      loading: false,
      projects: null,
      components: null,
      targetProjectId: "",
      targetComponentId: "",
      savedPlaceholders: []
    };
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
    TriggerAnotherWorkflowStore.initialiseEmpty();
  }

  componentDidMount() {
    if (this.props.component_id == undefined) return;

    this.setState({ loading: true });

    send_request(
      `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
      "",
      {}
    )
      .then(response => {
        if (
          response.data &&
          response.data.components &&
          response.data.components[0].componentData
        ) {
          let cData = response.data.components[0].componentData;
          TriggerAnotherWorkflowStore.setTriggerAnotherWorkflowTemplate(
            response.data.components[0]
          );
          this.props.onComponentNameChanged(
            TriggerAnotherWorkflowStore.trigger_another_workflow_name
          );
          this.props.onComponentDescriptionChanged(response.data.components[0].description);
          this.props.onLastModifiedChanged(
            TriggerAnotherWorkflowStore.lastModified
          );

          this.setState({
            targetProjectId: cData.data.targetProjectId,
            targetComponentId: cData.data.targetComponentId,
            savedPlaceholders: this.removeDeletedPlaceholders(cData.data.savedPlaceholders)
          });
          // if length changes after going through the remove placeholder function
          // trigger a save
          if(this.state.savedPlaceholders.length < cData.data.savedPlaceholders.length)
          {
            this.handleSaveComponent();
          }

          if (cData.data.targetProjectId != "") {
            this.getComponents(cData.data.targetProjectId);
          }
        }
        this.props.setChildTemplateLoaded(true);
      })
      .then(() => {
        send_request(`project-service/project/getProjectsNames`, "", {})
          .then(res => {
            this.setState({
              projects: this.renderProjects(res.data),
              loading: false
            });
          })
          .catch(err => {
            throw Error(err.message);
          });
      })
      .catch(err => {
        throw Error(err.message);
      });
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveTriggerAnotherWorkflowTemplate();
    this.reactToPlaceHolder();
  }

  handleSaveComponent = () => {
    let cData = TriggerAnotherWorkflowStore.template.componentData;

    cData.name = toJS(this.props.ComponentName);

    cData.targetProjectId = this.state.targetProjectId;
    cData.targetComponentId = this.state.targetComponentId;

    cData.savedPlaceholders = this.state.savedPlaceholders;
    console.log(cData);

    let lastModified = getCurrentTime();
    let data = {
      componentData: {
        data: cData,
        name: toJS(this.props.ComponentName),
        lastModified: lastModified
      },
      componentId: this.props.component_id,
      type: "trigger_another_workflow",
      description: this.props.ComponentDescription.value
    };

    send_component_save_request(
      "component-service/triggerAnotherWorkflow/data",
      data,
      "",
      "POST",
      this.props.SaveTrigger
    )
      .then(response => {
        this.props.onLastModifiedChanged(lastModified);
        this.props.showSuccess(data, this.props.SaveTrigger);
        projectStore.savedComponent = true;
      })
      .catch(err => {
        throw err;
      });
  };

  setTriggerAnotherWorkflowName = autorun(() => {
    TriggerAnotherWorkflowStore.setTriggerAnotherWorkflowName(
      this.props.ComponentName
    );
  });

  saveTriggerAnotherWorkflowTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    this.handleSaveComponent
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key, isNotPlaceholder } = placeholder["selected"];
      this.insertPlaceholder(
        style.default,
        key,
        style,
        this.state.focus,
        isNotPlaceholder
      );
    }
  );

  removeDeletedPlaceholders = savedPHs => {
    let pHs = [];

    for (let i = 0; i < savedPHs.length; i++) {
      let currPH = savedPHs[i];
      if (
        this.props.availablePlaceholders[0].placeholders.some(
          aPH => aPH.key === currPH.key
        )
      ) {
        pHs.push(currPH);
      }
    }
    return pHs;
  };

  insertPlaceholder = (label, text, style, focus, isNotPlaceholder) => {
    if (focus === "editor" || focus === "subject") {
      this.setState(prevState => ({
        placeholders: [...prevState.placeholders, { label, text, style, focus }]
      }));
    } else {
      this.toInput(label, text, style, focus, isNotPlaceholder);
    }
  };

  getComponents = async projectId => {
    const url = `project-service/graphql/project/${projectId}`;

    await send_request_graphql(url, GET_COMPONENTS(projectId))
      .then(res => {
        this.setState({
          components: this.renderComponents(res.data["projectById"])
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  renderProjects = data => {
    const projectList = data
      .filter(proj => proj["projectId"] != this.props.project_id)
      .map(project => {
        return {
          label: project["projectName"]
            ? project["projectName"]
            : project["projectId"],
          id: project["projectId"]
        };
      });
    return projectList;
  };

  renderComponents = data => {
    const components = data.components;
    if (components == null) return []; // No components

    return components
      .filter(comp => comp["type"] === "trigger_from_a_workflow")
      .map((comp, idx) => {
        if (comp["componentData"]) {
          return {
            label: comp["componentData"]["name"]
              ? comp["componentData"]["name"]
              : comp["componentId"],
            id: comp["componentId"]
          };
        } else {
          return {
            label: comp["componentId"],
            id: comp["componentId"]
          };
        }
      });
  };

  render() {
    return this.state.loading ? (
      <IndividualComponentLoader />
    ) : (
      <Container>
        <Dropdowns
          projects={this.state.projects}
          components={this.state.components}
          updateState={async newState => this.setState(newState)}
          handleSaveComponent={this.handleSaveComponent}
          getComponents={this.getComponents}
          targetProjectId={this.state.targetProjectId}
          targetComponentId={this.state.targetComponentId}
        />
        {this.state.targetProjectId && this.state.targetComponentId && (
          <PlaceholderSelection
            {...this.props}
            savedPlaceholders={this.state.savedPlaceholders}
            updateState={newState => this.setState(newState)}
          />
        )}
      </Container>
    );
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(TriggerAnotherWorkflowTemplate));
