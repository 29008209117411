import React, { useEffect, useState } from "react";

//mui and icons
import { Grid, InputAdornment, styled, Skeleton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
//copy
import CopyToClipboard from "react-copy-to-clipboard";
//send request
import { send_request } from "../../../../../utils/Request";

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
  borderRadius: "4px",
  background: "#E8E8E8",
  width: "100%",
  height: height,
  margin: margin,
}));

function TriggerViaEmailInfo({
  componentId,
  version,
  TriggerTitle,
  TriggerDescription,
  LinkClipBoard,
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(async () => {
    setLoading(true);

    if (!componentId) return;

    let url = `project-service/project/component/query/${componentId}/at-version/${version}`;
    const json = await send_request(url, null, null, "GET");

    try {
      let componentData = json.data.components[0].componentData;
      let receiver = componentData.data.receiver;

      setEmail(receiver);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const getTriggerFromAWorkflowContent = () => {
    if (loading) {
      return (
        <>
          <Grid item xs={12}>
            <StyledSkeleton variant="rectangular" height="90px" />
          </Grid>
          <Grid item xs={12}>
            <StyledSkeleton variant="rectangular" height="40px" width="120px" />
          </Grid>
        </>
      );
    }

    if (email) {
      return (
        <>
          <Grid item xs={12}>
            <TriggerDescription>
              Trigger this workflow by sending an email to the following email
              address
            </TriggerDescription>
          </Grid>
          <Grid item xs={12}>
            <LinkClipBoard
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
              }}
              disabled
              fullWidth
              variant="outlined"
              size="small"
              value={email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard text={email}>
                      <FileCopyIcon className="custom-icon-btn" />
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={12}>
          <TriggerDescription>
            This component has not been setup yet.
          </TriggerDescription>
        </Grid>
      );
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TriggerTitle>📩 Send an email to this address to trigger</TriggerTitle>
      </Grid>
      {getTriggerFromAWorkflowContent()}
    </Grid>
  );
}
export default TriggerViaEmailInfo;
