import React from "react";

// mui
import { Send } from "@mui/icons-material";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";

// util
import { updateNextStepInDB } from "../Util/Util";
import { Industries } from "../Util/StaticDropdownInfo";
import { send_request } from "../../../../utils/Request";
import SearchableDropdown from "../Util/SearchableDropdown";
import { companySizes } from "../../../../utils/companysize";
import { ItemTitle } from "../Util/OnboardingStyledComponents";
import { useAIOnboardingContext } from "../OnboardingContextProvider";

function CompanyInfoMessage(props) {
    const {
        loading,
        accountName,
        companySize,
        setAccountName,
        setCompanySize,
        accountNameError,
        setAccountNameError,
        industry,
        setIndustry,
        messages,
        setStep,
        setLoading,
        setLoadingBubble,
    } = useAIOnboardingContext();

    const isLatestMsg = props.position === messages.length;

    const submitCompanyInfo = async () => {
        setLoading(true);
        setLoadingBubble(true);

        // Construct info
        const clientInfo = {
            industryList: industry ? industry : null,
            companySize: companySize,
        };

        const extraQuestionURL = `authz-service/extra_questions/set_up_organization`;
        const setUpClientURL = `authz-service/set_up_client`;

        // Construct data
        const clientData = {
            clientName: accountName ? accountName : "client name",
        };

        await Promise.all([send_request(setUpClientURL, clientData, null, "POST"), send_request(extraQuestionURL, clientInfo, null, "POST")]); // Execute logic

        // Proceed to next step
        updateNextStepInDB("DESCRIBE_WORKFLOW");

        setLoading(false);
        setLoadingBubble(false);
        setStep(2);
    };

    const checkIfDisabled = () => {
        // All items have to be filled out
        if (!accountName || !industry || !companySize) return true;

        // If it's an array with 1 value
        if (Array.isArray(industry) && industry.length === 0) return true;

        return false;
    };

    return (
        <Grid container className={props.classes.userInfoBox} rowGap={2}>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>Company name (required)</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            className={props.classes.inputField}
                            variant="outlined"
                            placeholder="Enter a name for your account e.g. your company or team"
                            value={accountName}
                            onChange={(e) => {
                                if (e.target.value.length > 128) return;
                                setAccountName(e.target.value);
                            }}
                            disabled={loading}
                            error={accountNameError}
                            helperText={accountNameError}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>What industry is your company in? (required)</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <SearchableDropdown options={Industries} value={industry} setValue={setIndustry} placeholder="Select industry" disabled={loading || !isLatestMsg} allowMultiple />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>Company size (required)</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            onChange={(e) => {
                                setCompanySize(e.target.value);
                            }}
                            className={props.classes.select}
                            variant={"outlined"}
                            value={companySize}
                            size="small"
                            displayEmpty
                            disabled={loading}
                        >
                            <MenuItem value={null}>select answer</MenuItem>
                            {companySizes.map((size, index) => {
                                return (
                                    <MenuItem value={size.name} key={index}>
                                        {size.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Button className={props.classes.sendButton} variant={"contained"} onClick={submitCompanyInfo} disabled={loading || checkIfDisabled()}>
                    <Send />
                </Button>
            </Grid>
        </Grid>
    );
}

export default CompanyInfoMessage;
