import React from "react";

// MUI
import { Autocomplete, TextField, Chip } from "@mui/material";
import { styled } from "@mui/system";

// Util
import { getColourFromString } from "../../../../utils/ColourNameGenerator";

const MyChip = styled(Chip)(({ colour }) => ({
    border: `1px solid ${colour.border}`,
    backgroundColor: colour.bg,
    boxSizing: "border-box",
    borderRadius: "4px",
    marginRight: "6px",
    whiteSpace: "normal !important",
    maxWidth: "350px !important",
}));

function SearchableDropdown({ options, value, setValue, disabled, placeholder, allowMultiple }) {
    let showPlaceholder = false;
    if (!value || (Array.isArray(value) && value.length === 0)) showPlaceholder = true;

    return (
        <Autocomplete
            size="small"
            multiple
            options={options}
            value={value ? (Array.isArray(value) ? value : [value]) : []}
            freeSolo
            disabled={disabled}
            fullWidth
            variant="standard"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={showPlaceholder ? placeholder : ""} />}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => <MyChip {...getTagProps({ index })} label={option} colour={getColourFromString(option)} />);
            }}
            onChange={(e, value, reason) => {
                if (reason === "selectOption") {
                    // If we can allow multiple
                    if (allowMultiple) {
                        setValue(value);
                    } else {
                        // Only take the selected value
                        setValue([value[value.length - 1]]);
                    }
                } else if (reason === "removeOption") {
                    setValue(value);
                } else if (reason === "clear") {
                    setValue(null);
                }
            }}
        />
    );
}

export default SearchableDropdown;
