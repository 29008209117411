import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COMPONENT_NODE_ID, COMPONENT_ICON_ALT } from "../../../../../../../utils/CanvasConstants";
import { hexToPastel } from "../../../../../../../utils/HexToPastelUtil";
import AiInstructionInfoText from "./AiInstructionInfoText";
import React from "react";
import * as PropTypes from "prop-types";

const BLACK_HEX = '#000000';

const DB_TYPE = "UI.dbIcon";

const NodeBodyContainer = styled(Grid)(({ baseColor, type }) => ({
  border: `1px solid ${type === DB_TYPE ? BLACK_HEX : baseColor}`,
  overflow: "hidden"
}));

const HeaderContainer = styled(Grid)(({ baseColor }) => ({
  background: hexToPastel(baseColor),
  padding: "0px 0px 8px 0px",
  "& > .MuiGrid-item": {
    padding: "0px 8px 0px 8px"
  },
}));

const HeaderTitleGrid = styled(Grid)({
  paddingTop: "8px !important"
});

const ComponentLabelText = styled(Typography)({
  fontFamily: "Open Sans !important",
  fontSize: "12px",
  fontWeight: "400 !important",
  lineHeight: "16px",
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const ComponentDescriptionContainer = styled(Grid)({
  background: "#FFFFFF",
  "& > .MuiGrid-item": {
    padding: "8px 8px 8px 8px"
  },
});

const ComponentDescriptionText = styled(Typography)({
  fontSize: "11px",
  fontWeight: "400 !important",
  lineHeight: "18px",
  textAlign: "left",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  hyphens: "auto",
});

export function ComponentNodeBodyView(props) {
  const hasDescription = !!props.data.description;
  const hasAiInstructions = props.data.instructions &&
      (props.data.instructions.buildInstruction ||
          props.data.instructions.editInstruction);
  const showNodeBody = hasDescription || hasAiInstructions;

  return (
    <NodeBodyContainer
      baseColor={props.baseColor}
      type={props.data.type}
      className={`${props.selectedClass && props.selectedClass} component-node-container`}
      spacing={3}
      direction="column"
    >
      <HeaderContainer
        container
        item
        baseColor={props.baseColor}
      >
        <HeaderTitleGrid
          container
          item
          xs={12}
          direction="row"
          spacing={1}
          alignItems="flex-start"
        >
          <Grid item>{
            <img
                src={props.data.logo}
                alt={COMPONENT_ICON_ALT}
                className={`${props.classes.svg}`}
                width={24}
                height={24}
            />
          }
          </Grid>
          <Grid item>
            <Typography className={"bold"} sx={{color: props.data.hasDb ? BLACK_HEX: props.baseColor}}>
              {props.data.displayName}
            </Typography>
          </Grid>
        </HeaderTitleGrid>
        {props.data.label &&
            <Grid item xs={12}>
              <ComponentLabelText title={props.data.label}>
                {props.data.label}
              </ComponentLabelText>
            </Grid>
        }

      </HeaderContainer>
      {showNodeBody &&
          <ComponentDescriptionContainer
              item
              container
          >
            {hasDescription &&
                <Grid item>
                  <ComponentDescriptionText>
                    {props.data.description}
                  </ComponentDescriptionText>
                </Grid>
            }
            {hasAiInstructions &&
                <AiInstructionInfoText
                    data={props.data}
                />
            }
          </ComponentDescriptionContainer>
      }
    </NodeBodyContainer>
  );
}

ComponentNodeBodyView.propTypes = {
  baseColor: PropTypes.any,
  selectedClass: PropTypes.any,
  src: PropTypes.any,
  svg: PropTypes.any,
  displayName: PropTypes.any,
  label: PropTypes.any,
  showNodeBody: PropTypes.any,
  hasDescription: PropTypes.bool,
  description: PropTypes.any,
  hasAiInstructions: PropTypes.any,
  data: PropTypes.any,
  parent: PropTypes.any,
  textRef: PropTypes.any
};