import React, { useState } from "react";
import FormMentionEditor from "../../../../Component/FormMentionEditor";
import { StyledTextField } from "../../StyledTextField";

function DescriptionInput(props) {
    const [textValue, setTextValue] = useState(props.col.description);

    const handleOnChange = (e) => {
        if (e.target.value.length > 4000) return; // Only allow input up to 64 chars
        setTextValue(e.target.value);
    };

    const handleOnBlur = () => {
        props.handleChangeColumnValue(props.col.id, textValue, "description");
    };

    return props.availablePlaceholders ? (
        <div className="mention-editor-container">
            <span className="mention-editor-label">Description</span>
            <FormMentionEditor
                style={{
                    width: "100%",
                    marginBottom: 8,
                    verticalAlign: "top",
                    minHeight: "16px",
                }}
                aiAssistant={true}
                assignTask={true}
                contentBlock={false}
                hideScrollBar={true}
                text={props && props.col ? props.col.description : ""}
                onFocus={(e) => {}}
                placeholder={"Enter description"}
                onChange={(value) => {
                    props.handleChangeColumnValue(props.col.id, value, "description");
                }}
                {...(props.availablePlaceholders != null && {
                    availablePlaceholders: props.availablePlaceholders,
                })}
            />
        </div>
    ) : (
        <StyledTextField
            fullWidth
            multiline
            variant="filled"
            size="small"
            label={"Description"}
            value={textValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            styles={{
                width: "100%",
                minWidth: "200px",
                marginBottom: "8px",
            }}
        />
    );
}

export default DescriptionInput;
