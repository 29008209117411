import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";

export default class ValidationErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>
          <h5>🚩 We have found some issues</h5>
        </DialogTitle>
        <DialogContent>
          <p className={"s-text"}>
            We have found the following issues with this component:
          </p>
          {this.props.errors.map((error, index) => {
            return (
              <Grid key={index} container xs={12} direction={"row"} alignItems={"center"}>
                <Grid item xs={1}>
                  <ErrorIcon className={"error-text"} />
                </Grid>
                {typeof error === "object" ? (
                  <Grid item xs={11}>
                    <p className={"error-text"}>{error.error}</p>
                  </Grid>
                ) : (
                  <Grid item xs={11}>
                    <p className={"error-text"}>{error}</p>
                  </Grid>
                )}
              </Grid>
            );
          })}
          <p className={"s-text"}>
            You can still save and exit this component, but it will be marked as
            having an error. This means the workflow may not function properly.{" "}
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"contained"} color={"primary"} 
            onClick={() => {
              this.props.close();
            }}
          >
            go back
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
