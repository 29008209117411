import React from "react";
import { timezones } from "../../../utils/timezones";
import { Grid, MenuItem, Select, Autocomplete, TextField, Typography } from "@mui/material";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initialize with today's date in YYYY-MM-DD format
      previewDate: new Date().toISOString().split('T')[0],
    };
  }

  // Handler for date changes in preview mode
  handleDateChange = (event) => {
    this.setState({ previewDate: event.target.value });
  };

  render() {
    // Preview mode: display a read-only date picker
    if (this.props.preview === true) {
      return (
        <Grid container item direction={"row"} xs={12} justifyContent={"start"}>
          <TextField
            type="date"
            value={this.state.previewDate}
            onChange={this.handleDateChange}
            InputProps={{
              readOnly: true,
            }}
            size="small"
          />
        </Grid>
      );
    } 
    // Edit mode: display date format and timezone options
    else if (this.props.preview === false) {
      return (
        <Grid container spacing={2}>
          {/* Date format selection */}
          <Grid item xs={6}>
            <Typography variant="body1">Export format</Typography>
            <Select
              onChange={(e) => this.props.handleChangeOutput(e)}
              fullWidth
              variant="outlined"
              value={this.props.outputFormat}
              defaultValue={"dd LLLL YYYY"}
            >
              {/* Menu items for various date and date-time formats */}
              <MenuItem value="" disabled>Date only</MenuItem>
              <MenuItem value={"dd LLLL YYYY"}>
                dd Month yyyy [01 February 2021]
              </MenuItem>
              <MenuItem value={"dd/MM/YY"}>dd/mm/yy [01/02/21]</MenuItem>
              <MenuItem value={"dd/MM/YYYY"}>dd/mm/yyyy [01/02/2021]</MenuItem>
              <MenuItem value={"dd-MM-YYYY"}>dd-mm-yyyy [01-02-2021]</MenuItem>
              <MenuItem value={"MM/dd/YYYY"}>mm/dd/yyyy [02/01/2021]</MenuItem>
              <MenuItem value={"YYYY-MM-dd"}>yyyy-mm-dd [2021-02-01]</MenuItem>

              <MenuItem value="" disabled>Date and time</MenuItem>
              <MenuItem value={"dd LLLL YYYY kk:mm"}>
                dd Month yyyy hh:mm [01 February 2021 14:01]
              </MenuItem>
              <MenuItem value={"dd/MM/YY kk:mm"}>
                dd/mm/yy hh:mm [01/02/21 14:01]
              </MenuItem>
              <MenuItem value={"dd/MM/YYYY kk:mm"}>
                dd/mm/yyyy hh:mm [01/02/2021 14:01]
              </MenuItem>
              <MenuItem value={"dd-MM-YYYY kk:mm"}>
                dd-mm-yyyy hh:mm [01-02-2021 14:01]
              </MenuItem>
              <MenuItem value={"MM/dd/YYYY kk:mm"}>
                mm/dd/yyyy hh:mm [02/01/2021 14:01]
              </MenuItem>
              <MenuItem value={"YYYY-MM-dd kk:mm"}>
                yyyy-mm-dd hh:mm [2021-02-01 14:01]
              </MenuItem>
              <MenuItem value={"Iso-8601"}>
                ISO 8601 [2021-02-01T14:01:00±Timezone]
              </MenuItem>
              <MenuItem value={"Unix"}>Unix [1612148400]</MenuItem>
            </Select>
          </Grid>
          {/* Timezone selection */}
          <Grid item xs={6}>
            <Typography variant="body1">Timezone</Typography>
            <Autocomplete
              options={timezones}
              getOptionLabel={(option) => `${option.name} ${option.offset}`}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              value={timezones.find(tz => tz.offset === this.props.timezone) || null}
              onChange={(event, newValue) => {
                this.props.handleChangeTimezone({ target: { value: newValue ? newValue.offset : "" } });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      );
    }
  }
}

export default Calendar;