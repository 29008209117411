import React, {Component} from "react";
import {withStyles} from "@mui/styles";
import styles from "../WorkflowData/DataBankStyle";
import Grid from "@mui/material/Grid";
import {TextField, Tooltip} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import checkKey from "../../utils/CheckKey";
import sessionVariableStore from "./SessionVariableStore";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlaceholderTypeSelect from "../../utils/PlaceholderTypeSelect";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";


class RenderSessionVariableItem extends Component {


    constructor(props) {
        super(props);
        this.state = {
            checkKey: false, errorMessage: "", showWarning: false
        };
    }

    deleteSessionVariable = (index, sessionVariableData) => {
        sessionVariableData.splice(index, 1);
        this.props.setSessionVariableData(sessionVariableData);
    };
    keyAuto = () => {
        return ("session_data" + (Math.floor(Math.random() * 90000000) + 10000).toString());
    };
    copySessionVariable = (index, sessionVariableData) => {
        let aData = {
            key: this.keyAuto(), type: sessionVariableData[index].type, value: sessionVariableData[index].value,
        };
        sessionVariableData.push(aData);
        this.props.setSessionVariableData(sessionVariableData);

    }
    setPlaceholderType = (index, value) => {
        let aData = this.props.sessionVariableData[index];
        aData.type = value;
        this.props.setSessionVariableData(this.props.sessionVariableData);
    }
    handleValue = (index, value) => {

        let aData = this.props.sessionVariableData[index];
        aData.value = value;

        this.props.setSessionVariableData(this.props.sessionVariableData);
    }
    handleDateFormatValue = (index, value) => {
        let aData = this.props.sessionVariableData[index];
        aData.dateFormat = value;
        this.props.setSessionVariableData(this.props.sessionVariableData);
    }
    handleTimeZoneValue = (index, value) => {
        let aData = this.props.sessionVariableData[index];
        aData.timeZone = value;
        this.props.setSessionVariableData(this.props.sessionVariableData);
    }
    handleKey = (event, sessionVariableData, index) => {
        let keyValue = replacePlaceholderValue(event.target.value);

        const ckValue = checkKey(keyValue, index, sessionVariableData);
        this.setState({
            checkKey: ckValue[0], errorMessage: ckValue[1],
        });

        let aData = sessionVariableData[index];
        aData.key = keyValue;

        this.props.setSessionVariableData(sessionVariableData);
    };

    render() {


        const {
            classes,
            aData,
            index,
            sessionVariableData,
            availablePlaceholders,

        } = this.props;
        return <Paper className={classes.paperStyle}>

            <Grid container direction={"column"} className={classes.itemGridStyling} style={{}}>

                <Grid item>
                    <Grid container direction={"row"} columnGap={2} wrap={"nowrap"}>
                        <Grid Item display="flex" alignItems="center" justifyContent="center">
                            <span style={{ fontSize: "14px", width: "fit-content" }}>Placeholder</span>
                        </Grid>
                        <Grid Item xs={9}>
                            <W86PlaceholderTextField
                                fullWidth={true}
                                value={aData.key}
                                error={this.state.checkKey ? true : false}
                                helperText={this.state.checkKey ? this.state.errorMessage : null}
                                handleChange={(e) => this.handleKey(e, sessionVariableData, index)}
                            />
                        </Grid>
                        <Grid Item display="flex" alignItems="center" justifyContent="center">
                            <Tooltip title="Duplicate">

                            <ContentCopyIcon onClick={() => this.copySessionVariable(index, sessionVariableData)}
                                             className={classes.deleteIcon}/>
                            </Tooltip>
                        </Grid>
                        <Grid Item display="flex" alignItems="center" justifyContent="center">
                            <Tooltip title="Delete">

                            <DeleteIcon onClick={() => this.deleteSessionVariable(index, sessionVariableData)}
                                        className={classes.deleteIcon}/>
                            </Tooltip>
                        </Grid>

                    </Grid>
                </Grid>
                <PlaceholderTypeSelect
                    item={aData}
                    availablePlaceholders={availablePlaceholders}
                    setPlaceholderType={(value) => this.setPlaceholderType(index, value)}
                    setValue={(value) => {this.handleValue(index, value);}}
                    setDateFormatValue={(value) => {this.handleDateFormatValue(index, value)}}
                    setTimeZoneValue={(value)=>{this.handleTimeZoneValue(index,value)}}
                    setFocus={() => {sessionVariableStore.setFocus(index)}}
                >
                </PlaceholderTypeSelect>

            </Grid>
        </Paper>

    }
}

export default withStyles(styles)(RenderSessionVariableItem);
