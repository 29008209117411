import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, TextField } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ChipInput from "../../ChipInput/ChipInput";
import LoopThroughListStore from "../LoopThroughListStore";

import { toJS } from "mobx";
import Icon from "@mui/material/Icon";
import checkKey from "../../../utils/CheckKey";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";

class ItemLTL extends Component {
  state = {
    checkKey: false,
    errorMessage: ""
  };

  handleKeyChanges = (e, index) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    LoopThroughListStore.setComponent(placeholderValue, index, "key");
    const { data } = toJS(LoopThroughListStore);
    const value = checkKey(placeholderValue, index, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
    this.props.handleKeyChanges(value[0]);
  };

  render() {
    const { component, index, availablePlaceholders } = this.props;
    const { key, list } = component;
    return (
      <div>
        <div className="item-wrapper">
          <Grid container spacing={1}>
            <Grid item xs={11} className="key-item-label">
              Placeholder
              <Grid container marginLeft={2}>
                <Grid item xs={12}>
                  <W86PlaceholderTextField
                    value={key}
                    error={this.state.checkKey ? true : false}
                    helperText={this.state.checkKey ? this.state.errorMessage : null}
                    placeholderText="Enter placeholder for loop through list"
                    handleChange={(event) => {
                      this.handleKeyChanges(event, index);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={12} className="item-label">
              Run actions for each value of this list
            </Grid>
            <Grid item xs={12}>
              <ChipInput
                inputValue={list}
                onPlaceholderSelected={this.props.onPlaceholderSelected}
                placeholders={availablePlaceholders}
                placeholder="Insert a list"
                fullWidth
                onBeforeAdd={chip => {
                  LoopThroughListStore.setComponent(chip, index, "list");
                  return true;
                }}
                onDelete={indexChip =>
                  LoopThroughListStore.deleteChip(indexChip, index, "list")
                }
                onFocus={() => LoopThroughListStore.setFocus(index, "list")}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default ItemLTL;
