import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import TriggerFromAWorkflowStore from "./TriggerFromAWorkflowStore";
import projectStore from "../ProjectCanvas/ProjectStore";

// Utils
import { getCurrentTime } from "../../utils/getCurrentTime";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import { send_component_save_request, send_request, send_request_graphql } from "../../utils/Request";
import uuid from "uuid";

// MUI
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

// Custom
import { GET_COMPONENTS } from "./components/ComponentQuery";
import {
  Container,
  ErrorText,
  StyledErrorIcon,
  StyledButton
} from "./components/StyledComponents";
import RenderConnectionData from "./components/RenderConnectionData";
import Dropdowns from "./components/Dropdowns";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";

class TriggerFromAWorkflowTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: [],
      loading: false,
      projects: null,
      components: null,
      sourceProjectId: "",
      sourceComponentId: "",
      buttonLoading: false,
      connectionData: null,
      error: false,
      errorMessage: ""
    };
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
    TriggerFromAWorkflowStore.initialiseEmpty();
  }

  componentDidMount() {
    if (this.props.component_id == undefined) return;

    this.setState({ loading: true });

    send_request(
      `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
      "",
      {}
    )
      .then(response => {
        if (
          response.data &&
          response.data.components &&
          response.data.components[0].componentData
        ) {
          let cData = response.data.components[0].componentData;
          TriggerFromAWorkflowStore.setTriggerFromAWorkflowTemplate(
            response.data.components[0]
          );
          this.props.onComponentNameChanged(
            TriggerFromAWorkflowStore.trigger_from_a_workflow_name
          );
          this.props.onComponentDescriptionChanged(response.data.components[0].description);
          this.props.onLastModifiedChanged(
            TriggerFromAWorkflowStore.lastModified
          );

          // Set the data on initialisation
          this.setState({
            sourceProjectId: cData.data.sourceProjectId,
            sourceComponentId: cData.data.sourceComponentId,
            connectionData: cData.data.connectionData
          });

          if (cData.data.sourceProjectId != "") {
            this.getComponents(cData.data.sourceProjectId);
          }
        }
        this.props.setChildTemplateLoaded(true);
      })
      .then(() => {
        // Send a request to get all the users project names and ids
        send_request(`project-service/project/getProjectsNames`, "", {})
          .then(res => {
            this.setState({
              projects: this.renderProjects(res.data),
              loading: false
            });
          })
          .catch(err => {
            throw Error(err.message);
          });
      })
      .catch(err => {
        throw Error(err.message);
      });
  }

  setTriggerFromAWorkflowName = autorun(() => {
    TriggerFromAWorkflowStore.setTriggerFromAWorkflowName(
      this.props.ComponentName
    );
  });

  handleSaveComponent = () => {
    let cData = TriggerFromAWorkflowStore.template.componentData;
    console.log(cData);
    cData.name = toJS(this.props.ComponentName);

    // Save the relevant data
    cData.sourceProjectId = this.state.sourceProjectId;
    cData.sourceComponentId = this.state.sourceComponentId;
    cData.connectionData = this.state.connectionData;

    let lastModified = getCurrentTime();
    let data = {
      componentData: {
        data: cData,
        name: toJS(this.props.ComponentName),
        lastModified: lastModified
      },
      componentId: this.props.component_id,
      type: "trigger_from_a_workflow",
      description: this.props.ComponentDescription.value
    };

    send_component_save_request(
      "component-service/triggerFromAWorkflow/data",
      data,
      "",
      "POST",
      this.props.SaveTrigger
    )
      .then(response => {
        this.props.onLastModifiedChanged(lastModified);
        this.props.showSuccess(data, this.props.SaveTrigger);
        projectStore.savedComponent = true;
      })
      .catch(err => {
        throw err;
      });
  };

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveTriggerFromAWorkflowTemplate();
    this.reactToPlaceHolder();
  }

  saveTriggerFromAWorkflowTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    this.handleSaveComponent
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key, isNotPlaceholder } = placeholder["selected"];
      this.insertPlaceholder(
        style.default,
        key,
        style,
        this.state.focus,
        isNotPlaceholder
      );
    }
  );

  insertPlaceholder = (label, text, style, focus, isNotPlaceholder) => {
    if (focus === "editor" || focus === "subject") {
      this.setState(prevState => ({
        placeholders: [...prevState.placeholders, { label, text, style, focus }]
      }));
    } else {
      this.toInput(label, text, style, focus, isNotPlaceholder);
    }
  };

  // This fetches the components from a given project
  getComponents = async projectId => {
    const url = `project-service/graphql/project/${projectId}`;

    await send_request_graphql(url, GET_COMPONENTS(projectId))
      .then(res => {
        this.setState({
          components: this.renderComponents(res.data["projectById"])
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  // This renders the project labels and ids
  renderProjects = data => {
    const projectList = data
      .filter(proj => proj["projectId"] != this.props.project_id)
      .map(project => {
        return {
          label: project["projectName"]
            ? project["projectName"]
            : project["projectId"],
          id: project["projectId"]
        };
      });
    return projectList;
  };

  // This renders the component labels and ids
  renderComponents = data => {
    const components = data.components;

    // There are no components
    if (components == null) return [];

    return components
      .filter(comp => comp["type"] === "trigger_another_workflow")
      .map((comp, idx) => {
        if (comp["componentData"]) {
          return {
            label: comp["componentData"]["name"]
              ? comp["componentData"]["name"]
              : comp["componentId"],
            id: comp["componentId"]
          };
        } else {
          return {
            label: comp["componentId"],
            id: comp["componentId"]
          };
        }
      });
  };

  // This handles the connection button
  connectTriggers = () => {
    this.setState({ buttonLoading: true });
    // Attempt to make connection here
    const url = `project-service/project/component/${this.state.sourceComponentId}`;

    send_request(url, "", "", "get")
      .then(res => {
        // If success, set the placeholders and the data
        if (
          res.data.componentData &&
          res.data.componentData.data &&
          res.data.componentData.data.savedPlaceholders
        ) {
          console.log(res.data.componentData.data.savedPlaceholders);
          this.setState({
            buttonLoading: false,
            error: false,
            errorMessage: "",
            connectionData: {
              placeholders: res.data.componentData.data.savedPlaceholders.map(
                val => {

                  return {
                    id: uuid(),
                    sourceKey: val.key,
                    sourceValue: val.value,
                    targetKey: val.key.replace(/[^\w]/gi, "_"),
                    targetValue: val.value,
                    type: val.type,
                    length: val.length
                  };
                }
              ),
              sourceComponentId: this.state.sourceComponentId,
              sourceProjectId: this.state.sourceProjectId,
              connectionTime: Date.now()
            }
          });
        } else {
          // This means there was an error in your source config
          this.setState({
            buttonLoading: false,
            error: true,
            errorMessage:
              "There was an error in your source component configuration"
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          buttonLoading: false,
          connectionData: null
        });
      });
  };

  render() {
    if (this.state.loading) return <IndividualComponentLoader />;
    else {
      return (
        <Container>
          <Dropdowns
            {...this.state}
            getComponents={this.getComponents}
            updateState={async newState => this.setState(newState)}
            currProjectId={this.props.project_id}
            handleSaveComponent={this.handleSaveComponent}
          />

          {this.state.sourceProjectId && this.state.sourceComponentId && (
            <>
              <Grid item xs={12}>
                <StyledButton
                  disabled={this.state.buttonLoading}
                  onClick={this.connectTriggers}
                >
                  Connect
                  {this.state.buttonLoading && (
                    <CircularProgress size={24} className="buttonProgress" />
                  )}
                </StyledButton>
              </Grid>
              {this.state.error && (
                <Grid item xs={12}>
                  <ErrorText>
                    <StyledErrorIcon />
                    {this.state.errorMessage}
                  </ErrorText>
                </Grid>
              )}
              {this.state.connectionData && !this.state.buttonLoading && (
                <RenderConnectionData
                  {...this.state}
                  updateState={newState => this.setState(newState)}
                />
              )}
            </>
          )}
        </Container>
      );
    }
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(TriggerFromAWorkflowTemplate));
