import React from "react";
//material ui
import { Skeleton, Grid } from "@mui/material";
//css
import { withStyles } from "@mui/styles";
import styles from "./ComponentPaneStyle";

const ComponentPaneHeaderSkeleton = (props) => {
  const classes = props.classes;
  return (
    <Grid>
      <Grid container item direction={"row"} spacing={2}>
        <Grid item>
          <Skeleton
            variant={"rectangular"}
            width={60}
            height={28}
            className={classes.headerSkeletonButton}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant={"rectangular"}
            height={28}
            width={60}
            className={classes.headerSkeletonButton}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant={"rectangular"}
            height={28}
            width={60}
            className={classes.headerSkeletonButton}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant={"rectangular"}
            height={28}
            width={60}
            className={classes.headerSkeletonButton}
          />
        </Grid>
        <Grid item>
          <Skeleton
            variant={"rectangular"}
            height={28}
            width={60}
            className={classes.headerSkeletonButton}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Skeleton variant={"text"} height={70} width={680} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ComponentPaneHeaderSkeleton);
