import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, styled } from "@mui/material";

const CenterGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const Title = styled("span")({
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "18px",
});

const Description = styled("span")({
    fontSize: 12,
    lineHeight: "15px",
    color: "rgba(117, 117, 117, 1)",
});

const Content = styled(AccordionDetails)({
    padding: "0px",
});

const AccordionStyled = styled(Accordion)({
    boxShadow: "none",
});

const AccordionSummaryStyled = styled(AccordionSummary)({
    "&:hover": {
        background: "rgba(238, 238, 238, 1)",
        transition: "0.3s",
        borderRadius: "8px",
    },
});
const AIGroupIconColor = styled(Title)({
    backgroundImage: 'linear-gradient(180deg, #944BA8 0%, #2196F3 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent'
})

function GroupItem({ group, SidebarItem, components }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (e) => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (group.openForSearch) setExpanded(true);
        else setExpanded(false);
    }, [group]);

    return (
        <AccordionStyled expanded={expanded} onChange={handleChange}>
            <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={3} paddingRight={1}>
                    <CenterGrid item>{group.icon}</CenterGrid>
                    <Grid item xs>
                        <Grid container>
                            <Grid item xs={12}>
                                {group.title==="Artificial Intelligence" ?<AIGroupIconColor>{group.title}</AIGroupIconColor>:<Title>{group.title}</Title>}
                            </Grid>
                            <Grid item xs={12}>
                                <Description>{group.description}</Description>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummaryStyled>
            <Content>
                <Grid container marginTop={1}>
                    {group.components.map((componentName) => {
                        return (
                            <Grid item xs={12} id={`${group.title}-${componentName}`}>
                                <SidebarItem component={components[componentName]} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Content>
        </AccordionStyled>
    );
}

export default GroupItem;
