import Grid from "@mui/material/Grid";
import {MenuItem, Select, TextField} from "@mui/material";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";
import getPlaceholderColors from "../../vardogyir-ui/PlaceholderColors";
import React, {Component} from "react";
import {withStyles} from "@mui/styles";
import styles from "../WorkflowData/DataBankStyle";
import CloseIcon from '@mui/icons-material/Close';
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import checkKey from "../../utils/CheckKey";
import {toJS} from "mobx";
import FormMentionEditor from "../Component/FormMentionEditor";

class AIPlaceholderItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkKey: false, errorMessage: "", showWarning: false
        };
    }

    setPlaceholderType = (index, value) => {
        let aData = this.props.aiAssistantData.outputPlaceholders[index];
        aData.type = value;
        this.props.setAIPlaceholder(this.props.aiAssistantData.outputPlaceholders);
    }
    setDesc = (index, value) => {
        let aData = this.props.aiAssistantData.outputPlaceholders[index];
        aData.desc = value;
        this.props.setAIPlaceholder(this.props.aiAssistantData.outputPlaceholders);
    }
    setKey = (index, value) => {
        let keyValue = replacePlaceholderValue(value);

        const ckValue = checkKey(keyValue, index, toJS(this.props.aiAssistantData.outputPlaceholders));
        this.setState({
            checkKey: ckValue[0], errorMessage: ckValue[1],
        });
        let aData = this.props.aiAssistantData.outputPlaceholders[index];
        aData.key = keyValue;
        this.props.setAIPlaceholder(this.props.aiAssistantData.outputPlaceholders);
    }
    deletePlaceholders = (index) => {
        this.props.aiAssistantData.outputPlaceholders.splice(index, 1);
        this.props.setAIPlaceholder(this.props.aiAssistantData.outputPlaceholders);
    };

    render() {
        const {
            classes, aData, index, sessionVariableData, availablePlaceholders, placeholderTypeMap,

        } = this.props;

        return (
            <Grid
            container item
            spacing={1}
            direction={"column"}
            paddingTop={"8px"}
            paddingBottom={"8px"}
            paddingRight={"10px"}
            style={{background:"#F8F8F8",marginBottom:"20px"}}
            >
            <Grid item>
                    <span style={{fontSize: "14px", width: "fit-content"}}>
                        <b>
                        Describe the output
                        </b>
                    </span>
            </Grid>
            <Grid item>
                {!aData.isExplanation ?  <FormMentionEditor
                    availablePlaceholders={availablePlaceholders}
                    text={aData.desc}
                    onChange={(html) => this.setDesc(index,html)}
                    placeholder={""}
                    aiAssistant={true}
                    style={{
                        width: "100%",
                        marginBottom: 16,
                        verticalAlign: "top",
                        minHeight: "16px",
                        fontSize:"14px",
                        background: "white"
                    }}
                    onFocus={(e) => {
                    }}

                    contentBlock={false}
                    hideScrollBar={true}
                    assignTask={true}
                    {...(availablePlaceholders != null && {
                        availablePlaceholders: availablePlaceholders[0].placeholders,
                    })}
                />  : <span style={{fontSize: "14px", width: "fit-content"}}> explanation of how the task was completed </span>
                }
            </Grid>
            <Grid item>
                <span style={{fontSize: "14px", width: "fit-content"}}>
                    <b>Placeholder</b>

                </span>
            </Grid>
            <Grid container
                  item
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
            >
                <Grid style={{width: aData.isExplanation ? "100%" : "90%" ,background:"white"}}>
                    <W86PlaceholderTextField
                        value={aData.key}
                        fullWidth={true}
                        handleChange={(event) => this.setKey(index, event.target.value)}
                        error={this.state.checkKey ? true : false}
                        helperText={this.state.checkKey ? this.state.errorMessage : null}
                        placeholderText="Enter placeholder for update database"
                        style={{background:"white"}}

                    />
                </Grid>
                {!aData.isExplanation && <Grid item className={classes.deleteIcon} padding={"10px 20px 0px 20px"}>
                    <CloseIcon onClick={(event) => this.deletePlaceholders(index)}/>
                </Grid>
                }
            </Grid>
            <Grid item>
                <Select defaultValue="text"
                        className={"joditPlaceholderIcon"}
                        inputProps={{IconComponent: () => null}}
                        size={"small"}
                        sx={{
                            backgroundColor: "#F5F5F5",
                            "--custom-icon": `url(${getPlaceholderColors("text", null).icon})`,
                            verticalAlign: "top",
                            visibility: "",

                        }}
                        variant="outlined"
                        disableUnderline
                        value={aData.type}
                        onChange={(event) => this.setPlaceholderType(index, event.target.value)}
                >
                    {placeholderTypeMap.map((aCol, aColIndex) => (<MenuItem
                        value={aCol.toLowerCase()}
                        className={"joditPlaceholderIcon"}
                        style={{
                            "--custom-icon": `url(${getPlaceholderColors(aCol.toLowerCase(), null).icon})`,
                            paddingTop: "8px",
                            paddingBottom: "8px"
                        }}
                    >
                        {aCol}
                    </MenuItem>))}
                </Select>
            </Grid>

        </Grid>
                )
    }
}

export default withStyles(styles)(AIPlaceholderItem);
