import React, { useState, useEffect } from 'react';

import { LinearProgress, styled } from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import ErrorIcon from "@mui/icons-material/Error";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import "../stylesheet/dashboardPage.css";

import { send_request } from "../../../utils/Request";
import FadeIn from "react-fade-in";
import "../Achievement/AchievementStyle.css";
import { Link } from "react-router-dom";
import { DashboardCard } from "./CommonComponents";

const BorderLinearProgress = styled(LinearProgress)(({ barColor }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: barColor,
  },
}));

function ActionsUsedCard({ tier }) {
  // State variables to store and manage component data
  const [usedActions, setUsedActions] = useState(null);
  const [remainingActions, setRemainingActions] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [doughnutData, setDoughnutData] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch client session data when component mounts
    send_request("authz-service/clientstats/get-client-session-data", "", "", "get")
      .then((value) => {
        // Set used actions (minimum 0.1 to ensure visibility in chart)
        if (value.data.currentComponentSessionRun == 0) setUsedActions(0.1);
        else setUsedActions(value.data.currentComponentSessionRun);

        setRemainingActions(value.data.maxComponentSession);

        // Format and set renewal date
        setRenewalDate(formatRenewalDate(value.data.lastBillingDate));

        // Calculate and set usage percentage
        let calculatedPercentage = calculatePercentage(value.data.currentComponentSessionRun, value.data.maxComponentSession);
        setPercentage(calculatedPercentage);

        // Prepare data for the doughnut chart
        const data = {
          labels: [
            'Actions used',
            'Actions remaining',
          ],
          datasets: [{
            label: 'Actions used this month',
            data: [value.data.currentComponentSessionRun, value.data.maxComponentSession - value.data.currentComponentSessionRun],
            backgroundColor: [
              calculatedPercentage >= 90 ? 'rgba(176, 0, 32, 1)' : 'rgb(33, 150, 243)',
              'rgb(207, 216, 220)',
            ],
            hoverOffset: 0,
            cutout: 75,
            borderWidth: 0,
          }]
        };

        setDoughnutData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  // Helper function to format the renewal date and calculate days until renewal
  const formatRenewalDate = (givenDate) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    let newDate = new Date(givenDate);
    let formattedDate = newDate.getDate() + " " + monthNames[newDate.getMonth()] + " " + newDate.getFullYear();

    // Calculate days until renewal
    const today = new Date();
    const daysUntilRenewal = Math.ceil((newDate - today) / (1000 * 60 * 60 * 24));

    return { formattedDate, daysUntilRenewal };
  }

  // Helper function to calculate usage percentage
  const calculatePercentage = (used, remaining) => {
    if (remaining === 0) return 100;
    return Math.round(100 * used / remaining);
  }

  // Component to display a warning when actions are running low
  let actionWarningComponent = (<div className="actionWarningContainer">
    <FadeIn transitionDuration="1000">
      <div className="actionWarningContent">
        <p className="actionWarningText">You are about to run out of actions for this month. To prevent your workflows from being disrupted, <Link to="/set_plan" style={{ color: "#2a9af3" }}>upgrade your plan</Link></p>
      </div>
    </FadeIn>
  </div>);

  const getProgressBar = (used, available) => {
    let percentage = (used / available) * 100;
    let color = "black";

    if (percentage >= 90) color = "#B00020";
    else if (percentage >= 50) color = "#FFA722";

    return (
      <div className="progress-bar-outer">
        <div className="text-inside-progress-bar" style={{ textAlign: 'left', width: '100%' }}>
        </div>
        <BorderLinearProgress variant="determinate" value={percentage} barColor={color} />
      </div>
    );
  };

  // Main component to display actions usage information
  let actionsUsedComponent = usedActions != null && remainingActions != null && (
    <DashboardCard className={percentage >= 90 ? "cardStyleActions" : "cardStyleActions"}>
      <CardContent>
        <Typography
          variant="h4"
          component="p"
          className="cardHeader">
          Actions used this month
        </Typography>
      </CardContent>

      {percentage >= 90 && actionWarningComponent}

      <CardContent className="cardTopContent">
        <FadeIn transitionDuration="1000">
          <div className="progress-bar-outer">
            {getProgressBar(usedActions, remainingActions)}
          </div>
          <Typography className="cardDescription">
            <strong style={{ color: 'black' }}>{Math.round(remainingActions - usedActions).toLocaleString()}</strong> actions remaining out of <strong style={{ color: 'black' }}>{remainingActions.toLocaleString()}</strong>
          </Typography>
          <Typography className="cardDescription">
            {tier !== "FREE_TRIAL" && renewalDate && (
              <>
                Renews in <strong style={{ color: 'black' }}>{renewalDate.daysUntilRenewal} days</strong> on <strong style={{ color: 'black' }}>{renewalDate.formattedDate}</strong>
              </>
            )}
          </Typography>
        </FadeIn>
      </CardContent>
    </DashboardCard>
  );

  // Render the component only when data is loaded
  return !loading && actionsUsedComponent;
}

export default ActionsUsedCard;
