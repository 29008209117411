import { computed, observable, decorate, action, toJS } from "mobx";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
class TransformStore {
  constructor() {
    this.initialiseEmpty();
  }
  setComponentData = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.componentData();
      this.data = [obj];
      this.lastModified = "";
      this.name = "";
    }
  };

  setTemplateData = data => {
    this.data = data;
  }

  setComponentName = (name) => {
    this.name = name;
  };
  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  };
  setFocus = (index, field) => {
    this.position = index;
    this.field = field;
    this.posiCondition = null;
    this.posiConditionIsMet = null;
  };

  setFocusCondition = (index, posi, type) => {
    this.position = index;
    if (type == "conditions") this.posiCondition = posi;
    if (type == "conditionIsMet") this.posiConditionIsMet = posi;
  };

  keyAuto = () => {
    return "list" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  };

  componentData = () => {
    return {
      key: this.keyAuto(),
      func: null,
      list: [],
      replace: [],
      withThis: [],
      present: [],
      followList: [],
      listBelow: [],
      newList: [],
      filterList: [],
      duplicateList: [],
      conditions: [],
      conditionIsMet: [],
      extraction: [],
      rearrange: [],
      splitList: [],
      exportPlaceholders: [{ key: this.keyAuto() }],
      numPlaceholder: 1
    };
  };
  setComponent = (value, index, type) => {
    switch (type) {
      case "key":
        this.data[index].key = value;
        return;
      case "func":
        let conditions = [];
        let conditionIsMet = [];
        let extraction = [];
        let outputType = "list";
        let rearrange = [];

        let data = [
          {
            logic: "undefined",
            func: null,
            value: []
          }
        ];
        let dataExtraction = [
          {
            func: "the_first",
            elementNo: 1
          }
        ];
        let dataRearrange = [
          {
            func: "ascending_order"
          }
        ];
        if (value == "filter_based_on_a_conditional") {
          conditions = data;
          conditionIsMet = [];
        }

        if (value == "count_if_conditional_is_met") {
          conditions = [];
          conditionIsMet = data;
        }

        if (value == "extract_by_order") {
          extraction = dataExtraction;
        }
        if (value == "rearrange") {
          rearrange = dataRearrange;
        }

        if (
          value == "count_a_specific_value" ||
          value == "count_if_conditional_is_met" ||
          value == "count_length"
        ) {
          outputType = "number";
        }

        this.data[index] = {
          key: this.data[index].key,
          func: value,
          outputType: outputType,
          list: [],
          replace: [],
          withThis: [],
          present: [],
          followList: [],
          listBelow: [],
          newList: [],
          filterList: [],
          duplicateList: [],
          splitList: [],
          exportPlaceholders: [{ key: this.keyAuto() }], //an array of objects that have keys for future placeholders
          numPlaceholder: 1, //how many placeholders to export
          conditions: conditions,
          conditionIsMet: conditionIsMet,
          extraction: extraction,
          rearrange: rearrange
        };
        this.posiCondition = null;
        this.position = null;
        this.posiConditionIsMet = null;
        return;

      case "list":
        if (value) this.data[index].list = [value];
        return;
      case "replace":
        if (value) this.data[index].replace = [value];
        return;
      case "withThis":
        if (value) this.data[index].withThis = [value];
        return;
      case "present":
        if (value) this.data[index].present = [value];
        return;
      case "followList":
        if (value) this.data[index].followList = [value];
        return;
      case "listBelow":
        if (value) this.data[index].listBelow = [value];
        return;
      case "duplicateList":
        if (value) this.data[index].duplicateList = [value];
        return;
      case "splitList":
        if (value) this.data[index].splitList = [value];
        return;
      case "newList":
        if (value) {
          value = [...this.data[index].newList, value];
          this.data[index].newList = value;
          return;
        }
    }
  };
  setCondition = (value, index, position, field, type) => {
    if (type == "conditions") {
      switch (field) {
        case "logic":
          this.data[index].conditions[position].logic = value;
          return;
        case "func":
          this.data[index].conditions[position].func = value;
          return;
        case "value":
          if (value) this.data[index].conditions[position].value = [value];
          return;
      }
    }
    if (type == "conditionIsMet") {
      switch (field) {
        case "logic":
          this.data[index].conditionIsMet[position].logic = value;
          return;
        case "func":
          this.data[index].conditionIsMet[position].func = value;
          return;
        case "value":
          if (value) this.data[index].conditionIsMet[position].value = [value];
          return;
      }
    }
  };

  setExtraction = (value, index, field) => {
    switch (field) {
      case "func":
        this.data[index].extraction[0].func = value;
        return;
      case "value":
        this.data[index].extraction[0].elementNo = value;
        return;
    }
  };

  setRearrange = (value, index, field) => {
    switch (field) {
      case "func":
        this.data[index].rearrange[0].func = value;
        return;
    }
  };

  addComponent = () => {
    const obj = this.componentData();
    this.data.push(obj);
  };

  addCondition = (index, type) => {
    let data = {
      logic: "OR",
      func: null,
      value: []
    };
    if (type == "conditions") this.data[index].conditions.push(data);
    if (type == "conditionIsMet") this.data[index].conditionIsMet.push(data);
  };

  delComponent = (index) => {
    this.data.splice(index, 1);
  };
  deleteCondition = (index, position, type) => {
    if (type == "conditions") this.data[index].conditions.splice(position, 1);
    if (type == "conditionIsMet")
      this.data[index].conditionIsMet.splice(position, 1);
  };
  deleteChipCondition = (indexChip, index, p, type) => {
    if (type == "conditions")
      this.data[index].conditions[p].value.splice(indexChip, 1);
    if (type == "conditionIsMet")
      this.data[index].conditionIsMet[p].value.splice(indexChip, 1);
  };
  deleteChip = (indexChip, index, field) => {
    switch (field) {
      case "list":
        this.data[index].list.splice(indexChip, 1);
        return;
      case "replace":
        this.data[index].replace.splice(indexChip, 1);
        return;
      case "withThis":
        this.data[index].withThis.splice(indexChip, 1);
        return;
      case "present":
        this.data[index].present.splice(indexChip, 1);
        return;
      case "followList":
        this.data[index].followList.splice(indexChip, 1);
        return;
      case "listBelow":
        this.data[index].listBelow.splice(indexChip, 1);
        return;
      case "newList":
        this.data[index].newList.splice(indexChip, 1);
        return;
      case "duplicateList":
        this.data[index].duplicateList.splice(indexChip, 1);
        return;
      case "splitList":
        this.data[index].splitList.splice(indexChip, 1);
        return;
    }
  };

  setSplitList = (index, exportPlaceholders, numPlaceholder) => {
    this.data[index].exportPlaceholders = exportPlaceholders;
    this.data[index].numPlaceholder = numPlaceholder;
  };

  handleExportDifferentNumberOfPlaceholders = (event, index) => {
    //new number od placeholders
    let value = event.target.value;
    let array = [];
    if (value > 0 && value < 101) {
      if (value > this.data[index].numPlaceholder) {
        let addNumPlaceholders = value - this.data[index].numPlaceholder;
        array = this.data[index].exportPlaceholders;
        for (let i = 0; i < addNumPlaceholders; i++) {
          array.push({ key: this.keyAuto() });
        }
        this.data[index].numPlaceholder = value;
        this.data[index].exportPlaceholders = array;
      } else if (value < this.data[index].numPlaceholder) {
        let removeNumPlaceholders = this.data[index].numPlaceholder - value;
        array = this.data[index].exportPlaceholders;
        for (let i = 0; i < removeNumPlaceholders; i++) {
          array.pop();
        }
        this.data[index].numPlaceholder = value;
        this.data[index].exportPlaceholders = array;
      }
      if (value == this.data[index].numPlaceholder) {
        return;
      }
    } else {
      this.data[index].numPlaceholder = value;
      this.data[index].exportPlaceholders = array;
    }
  };

  handleChangePlaceholderName = (event, key, index) => {
    //new  placeholder name
    let value = replacePlaceholderValue(event.target.value);
    this.data[index].exportPlaceholders[key].key = value;

    this.setSplitList(
      index,
      this.data[index].exportPlaceholders,
      this.data[index].numPlaceholder
    );
  };

  initialiseEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.position = null;
    this.componentId = "";
    this.type = "list_transform";
    this.lastModified = "";
    this.data = [obj];
    this.field = null;
    this.posiCondition = null;
    this.posiConditionIsMet = null;
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      field: this.field,
      lastModified: this.lastModified,
      posiCondition: this.posiCondition,
      posiConditionIsMet: this.posiConditionIsMet
    };
  }
}

decorate(TransformStore, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable,
  type: observable,
  field: observable,
  posiCondition: observable,
  posiConditionIsMet: observable,
  lastModified: observable,
  addComponent: action,
  setComponent: action,
  setComponentData: action,
  setLastModified: action,
  setCondition: action,
  setFocus: action,
  delComponent: action,
  deleteChip: action,
  deleteCondition: action,
  deleteChipCondition: action,
  initialiseEmpty: action,
  template: computed
});

const transformStore = new TransformStore();
export default transformStore;
