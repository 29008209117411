import React, { Fragment } from "react";

import { Grid } from "@mui/material";
import TransformStore from "../../TransformStore";
import ChipInput from "../../../ChipInput/ChipInput";

function ConvertListToText({ availablePlaceholders, onPlaceholderSelected, listBelow, index }) {
    return (
        <Fragment>
            <Grid item xs={12} className="item-label">
                Convert this list to text
            </Grid>
            <Grid item xs={12} className="input-list-transform">
                <ChipInput
                    placeholders={availablePlaceholders}
                    onPlaceholderSelected={onPlaceholderSelected}
                    fullWidth
                    onBeforeAdd={(chip) => {
                        TransformStore.setComponent(chip, index, "listBelow");
                        return true;
                    }}
                    placeholder="Insert a list "
                    inputValue={listBelow}
                    onDelete={(indexChip) => TransformStore.deleteChip(indexChip, index, "listBelow")}
                    onFocus={() => TransformStore.setFocus(index, "listBelow")}
                />
            </Grid>
        </Fragment>
    );
}

export default ConvertListToText;
