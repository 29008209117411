import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import ConditionalWorkflowStore from "./ConditionalWorkflowStore";
import AddIcon from "@mui/icons-material/Add";
import ItemLogic from './components/ItemLogic';
import './components/style.css';
import { getCurrentTime } from "../../utils/getCurrentTime";
import { Grid } from "@mui/material"
import projectStore from "../ProjectCanvas/ProjectStore";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import ConditionalWorkflowPathStore from "../ConditionalWorkflowPath/ConditionalWorkflowPathStore";

class ConditionalWorkflowTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeaderFields: false,
      focus: null,
      placeholders: [],
      extraHeaderFields: [],
      requestClick: false,
      dataClick: false,
      finishedLoading: false,
      error: false,
      loading:false,
      description:null,
      status: null
    };
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
    ConditionalWorkflowStore.initialiseEmpty();
  }

  handleLogOut = () => {
    this.props.onBackProjectCanvas();
  }

  componentDidMount() {

    if (this.props.component_id !== undefined) {
      this.setState({loading:true})
      send_request(`project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`, "", {})
        .then(response => {

          if (response && response.data) {
            const { components } = response.data;
            if (components && components[0].componentData) {
              const data = {
                data: components[0].componentData
              }
              ConditionalWorkflowStore.setConditionalWorkflowTemplate(components[0].componentData);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onComponentNameChanged(ConditionalWorkflowStore.name);
              this.props.onLastModifiedChanged(ConditionalWorkflowStore.lastModified);
              this.setState({loading:false})
            }
            this.setState({loading:false})
            this.props.setChildTemplateLoaded(true);
              if(this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data!==undefined && this.props.aiComponentBuilderData.data!==''  ) {
                  this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
                  this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
                  this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
                  this.setState({ status: "AI_BUILD_GENERATED" });
                  ConditionalWorkflowStore.setData(this.props.aiComponentBuilderData.data);
              }
          }
        })
        .catch(err => {
          throw Error(err.message);
        });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveConditionalWorkflowTemplate();
    this.reactToPlaceHolder();
  }

  saveConditionalWorkflowTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {

      if(this.state.error){
        this.props.showError();
        return;
      }

      const templateName = toJS(this.props.ComponentName);
      const lastModified = getCurrentTime();
      ConditionalWorkflowStore.setLastModified(lastModified);
      ConditionalWorkflowStore.setConditionalWorkflowName(templateName);

      const template = toJS(ConditionalWorkflowStore.template);

      delete template.placeholders;
      delete template.positions;
      const data = {
        type: 'conditional_workflow',
        componentData: {
          name: template.name,
          data: template.data,
          lastModified: lastModified
        },
        componentId: this.props.component_id,
        description: this.props.ComponentDescription.value,
        status: this.state.status
      }
      send_component_save_request(
        `component-service/conditional-workflow/data`,
        data,
        "",
        "POST",
        this.props.SaveTrigger
      )
        .then(response => {
          if (response.status = 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            projectStore.savedComponent = true;
          }
        })
        .catch(err => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (!placeholder["selected"]) return;
      const { style, key } = placeholder["selected"];

      this.insertPlaceholder(style.default, key, style);
    }
  );

  insertPlaceholder = (label, text, style) => {
    let { placeholders } = ConditionalWorkflowStore;
    placeholders.push({ label, text, style });

    ConditionalWorkflowStore.setPlaceholders(placeholders);
  }

  handleKeyChanges = error => {
    this.setState({ error })
  }

  render() {
    const { data } = toJS(ConditionalWorkflowStore);
    if (this.state && !this.state.loading) {
    return (
      <Grid container direction={"column"} xs={12}>
        {data.map((data, index) => (
          <ItemLogic
            isExpanded={this.props.isExpanded}
            data={data}
            indexLogic={index}
            availablePlaceholders={this.props.availablePlaceholders}
            handleKeyChanges={this.handleKeyChanges}
            conditionalWorkflowPath={false}


          />
        ))}
      </Grid>
    );
  } else return <IndividualComponentLoader />
};
};

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(ConditionalWorkflowTemplate));
