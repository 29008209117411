import React from 'react';
import { SvgIcon } from '@mui/material';

function BooleanIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.553 6.86L9.13967 5.44666C8.87967 5.18666 8.87967 4.76666 9.13967 4.50666C9.39967 4.24666 9.81967 4.24666 10.0797 4.50666L11.0197 5.44666L13.3797 3.08666C13.6397 2.82666 14.0597 2.82666 14.3197 3.08666C14.5797 3.34666 14.5797 3.76666 14.3197 4.02666L11.493 6.85333C11.233 7.12 10.813 7.12 10.553 6.86ZM6.66634 4.66666H1.99967C1.63301 4.66666 1.33301 4.96666 1.33301 5.33333C1.33301 5.7 1.63301 6 1.99967 6H6.66634C7.03301 6 7.33301 5.7 7.33301 5.33333C7.33301 4.96666 7.03301 4.66666 6.66634 4.66666ZM13.5263 8.47333C13.2663 8.21333 12.8463 8.21333 12.5863 8.47333L11.333 9.72666L10.0797 8.47333C9.81967 8.21333 9.39967 8.21333 9.13967 8.47333C8.87967 8.73333 8.87967 9.15333 9.13967 9.41333L10.393 10.6667L9.13967 11.92C8.87967 12.18 8.87967 12.6 9.13967 12.86C9.39967 13.12 9.81967 13.12 10.0797 12.86L11.333 11.6067L12.5863 12.86C12.8463 13.12 13.2663 13.12 13.5263 12.86C13.7863 12.6 13.7863 12.18 13.5263 11.92L12.273 10.6667L13.5263 9.41333C13.7863 9.15333 13.7863 8.73333 13.5263 8.47333ZM6.66634 10H1.99967C1.63301 10 1.33301 10.3 1.33301 10.6667C1.33301 11.0333 1.63301 11.3333 1.99967 11.3333H6.66634C7.03301 11.3333 7.33301 11.0333 7.33301 10.6667C7.33301 10.3 7.03301 10 6.66634 10Z" fill="#868686"/>
      </svg>
    </SvgIcon>
  );
}

export default BooleanIcon;