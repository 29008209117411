import React, { useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/system";

const BackdropWrap = styled("div")({
    maxWidth: 350,
    textAlign: "center",
});

const BackdropTitle = styled("div")({
    fontSize: 18,
    fontWeight: "bold",
    color: "#ffffff",
});

const BackdropContent = styled("div")({
    fontSize: 12,
    fontWeight: "600",
    color: "#ffffff",
});

function W86Backdrop() {
    const [backdrop, setBackdrop] = useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        let resizeListener = window.addEventListener("resize", updateDimensions); // Add event listener

        if (!screenWidth || !screenHeight) return;

        if (screenWidth <= 900 || screenHeight <= 600) setBackdrop(true);
        else if (screenWidth > 900 && screenHeight > 600) setBackdrop(false);

        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, [screenWidth, screenHeight]);

    const updateDimensions = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    };

    return (
        <Backdrop
            open={backdrop}
            style={{
                zIndex: 9999999,
                backgroundColor: "rgba(0, 0, 0, 0.85)",
            }}
        >
            <BackdropWrap>
                <div>
                    <img src="/svgs/overlay.svg" style={{ marginRight: 16 }}></img>
                    <img src="/svgs/mobile_phone.svg"></img>
                </div>
                <BackdropTitle>This screen is too small</BackdropTitle>
                <BackdropContent>Workflow86 works best on a large screen (at least 900px wide and 600px height). Please resize this browser or view it on your computer.</BackdropContent>
            </BackdropWrap>
        </Backdrop>
    );
}

export default W86Backdrop;
