import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardMedia,
} from "@mui/material";
import Button from "@mui/material/Button";
import "./stylesheet/helpDialog.css";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import { 
  ArticleOutlined,
  ChatOutlined,
  CalendarMonthOutlined,
  TourOutlined 
} from '@mui/icons-material';

class HelpDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  redirectToDocs = () => {
    let url = "https://docs.workflow86.com/";
    window.open(url, "_blank");
  };
  redirectToCalendar = () => {
    let url = "https://cal.com/workflow86/30min";
    window.open(url, "_blank");
  };
  redirectToLiveChat = () => {
    window.intercom
  };
  triggerProductTour = async () => {
    // Close dialog first
    await this.props.close();
    
    // Defer tour initialization to next tick
    setTimeout(() => {
      const url = window.location.pathname;
      if (url.includes("canvas")) {
        ProjectStore.openCanvasPageTour();
      } else if (url.includes("project")) {
        ProjectStore.openProjectPageTour();
      } else if (url.includes("marketplace")) {
        ProjectStore.openMarketplacePageTour();
      } else if (url.includes("portal")) {
        ProjectStore.openPortalPageTour();
      } else if (url.includes("credential_store")) {
        ProjectStore.openCredentialPageTour();
      } else if (url.includes("repository")) {
        ProjectStore.openRepositoryPageTour()
      } else {
        ProjectStore.openWelcomePageTour();
      }
    }, 0);
  };
  handleClose = () => {
    this.props.close();
  };

  cardData = [
    {
      title: "Read the docs",
      icon: <ArticleOutlined sx={{ fontSize: 64, color: 'primary.main' }} />,
      description: "Read our documentation which contains detailed guides about how to use each part of the platform.",
      onClick: this.redirectToDocs
    },
    {
      title: "Chat with someone",
      icon: <ChatOutlined sx={{ fontSize: 64, color: 'primary.main' }} />,
      description: "Chat with someone from our team using our live chat messenger.",
      onClick: this.redirectToLiveChat
    },
    {
      title: "Book a 1:1 session",
      icon: <CalendarMonthOutlined sx={{ fontSize: 64, color: 'primary.main' }} />,
      description: "Book a 1:1 session with our team. We can answer questions or guide you through how to build a workflow to your requirements.",
      onClick: this.redirectToCalendar
    },
    {
      title: "Run the product tour",
      icon: <TourOutlined sx={{ fontSize: 64, color: 'primary.main' }} />,
      description: "Run (or re-run) the product tour which will guide you through all the essential parts of the platform.",
      onClick: this.triggerProductTour
    }
  ];

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        close={this.state.handleClose}
        className={"help-dialog"}
      >
        <DialogTitle>
          <h5>How can we help?</h5>
        </DialogTitle>
        <DialogContent>
          <Grid container direction={"row"} wrap={"nowrap"}>
            {this.cardData.map((card, index) => (
              <Card 
                key={index}
                className={"help-card"}
                onClick={card.onClick}
              >
                <Grid
                  container
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  className="help-grid-container"
                >
                  <div className="help-icon-container">
                    {card.icon}
                  </div>
                  <h5 className={"help-title"}>{card.title}</h5>
                  <p className={"s-text help-paragraph"}>{card.description}</p>
                </Grid>
              </Card>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} color={"primary"} onClick={this.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default HelpDialog;
