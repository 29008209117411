import { query, mutation } from "gql-query-builder";

export const GENERATE = aiBuilderDto => {
  return query({
    operation: "buildViaAIBuilder",
    variables: {
      input: {
        value: aiBuilderDto,
        type: "AIBuilderInput",
        required: false
      }
    },
    fields: []
  });
};
export const HANDLE_WORKFLOW_PREBUILD = aiBuilderDto => {
  return query({
    operation: "handleWorkflowPrebuild",
    variables: {
      input: {
        value: aiBuilderDto,
        type: "AIBuilderInput",
        required: false
      }
    },
    fields: []
  });
};
export const RETRIEVE_PREBUILD_SESSION = (sessionId, threadId) => {
  return query({
    operation: "retrievePrebuildSession",
    variables: {
      id: sessionId,
      threadId: threadId
    },
    fields: [
      "action",
      "prebuildActionType",
      "questions",
      {
        proposal: [
          "component",
          "content"
        ]
      },
      "assume",
      "comment",
      "originalGPTResponse",
      "message",
      "linkedEventId"
    ]
  });
};
export const GENERATE_WITH_ADDITIONAL_MESSAGE = aiBuilderDto => {
  return query({
    operation: "additionalAIBuilderMessage",
    variables: {
      input: {
        value: aiBuilderDto,
        type: "AIBuilderInput",
        required: false
      }
    },
    fields: []
  });
};
export const GENERATE_COMPONENT = aiComponentBuilderDto => {
  return mutation({
    operation: "buildViaComponentBuilder",
    variables: {
      input: {
        value: aiComponentBuilderDto,
        type: "ComponentBuilderInput",
        required: false
      }
    },
    fields: []
  });
};
export const GENERATE_COMPONENT_VIA_EDIT = aiComponentBuilderDto => {
  return mutation({
    operation: "buildViaComponentEditor",
    variables: {
      input: {
        value: aiComponentBuilderDto,
        type: "ComponentBuilderInput",
        required: false
      }
    },
    fields: []
  });
};

export const CHECK_BUILD = (sessionId, componentBuilder = false, currentPoll, pollMax) => {
  return query({
    operation: "checkBuild",
    variables: {
      id: sessionId,
      componentBuilder: componentBuilder,
      currentPoll: currentPoll,
      pollMax: pollMax
    },
    fields: []
  });
};
export const CHECK_BUILD_AND_ERROR = (sessionId, currentPoll, pollMax) => {
  return query({
    operation: "checkBuildAndErrorStatus",
    variables: {
      id: sessionId,
      currentPoll: currentPoll,
      pollMax: pollMax
    },
    fields: [
      "checkBuild",
      "checkError",
      {
        aiError: ["hasError", "errorMessage", "errorClass", "aiErrorType"]
      }
    ]
  });
};
export const RETRIEVE_SESSION = sessionId => {
  return query({
    operation: "retrieveSession",
    variables: {
      id: sessionId
    },
    fields: [
      "projectId",
      "explanation",
      "aiBuildingError",
      "aiSolution",
      "action",
      "name",
      {
        components: [
          "generatedTitle",
          "componentId",
          "description",
          {"instructions":["buildInstruction","editInstruction"]},
          "type",
          { nextComponents: ["componentId"] },
          { viewData: ["x", "y"] },
          {
            componentData: [
              "name",
              "lastModified",
              "targetDatabaseId",
              "isFromConditionalPath",
              { data: ["key", "pathName", "comPId"] }
            ]
          }
        ]
      },
      {
        notes: [
          "id",
          "type",
          { position: ["x", "y"] }, 
          {
            data: [
              "content",
              "color",
              "width",
              "height",
              "isTemp" ,
              "label"
            ]
          },
          "sourcePosition", 
          "targetPosition", 
          "sourceHandle", 
          "targetHandle", 
          "source",
          "target",
          {
            markerEnd: ["type", "color"]
          },
          "label",
          "isTemp",
          {
            style: ["strokeWidth", "stroke"]
          }
        ]
      },
      {
        aiDatabases: [
          "databaseId",
          "name",
          {
            columns: ["columnId", "name", "type", "databaseId"]
          }
        ]
      }
    ]
  });
};

export const RETRIEVE_EDITOR_SESSION = sessionId => {
  return query({
    operation: "retrieveTranslatedAIEditSession",
    variables: {
      id: sessionId
    },
    fields: [
      "projectId",
      "sessionId",
      "threadId",
      {
        editChangesDTO: [
          "chatTitle",
          "assume",
          "action",
          "editFalse",
          "workaround",
          "editActionsSummary",
          "newSummary",
          "removeSummary",
          "newPlaceholders",
          "obsoletePlaceholders",
          {
            componentsToAdd: [
              "generatedTitle",
              "componentId",
              "description",
              {"instructions":["buildInstruction","editInstruction"]},
              "type",
              { nextComponents: ["componentId"] },
              { viewData: ["x", "y"] },
              {
                componentData: [
                  "name",
                  "lastModified",
                  "targetDatabaseId",
                  "isFromConditionalPath",
                  { data: ["key", "pathName", "comPId", {
                    color: ["bg", "border"]
                  }] }
                ]
              },
              "previousComponents",
              "inputPlaceholders",
              "outputPlaceholders",
              "parentCWPIdLink"
            ],
            componentsToUpdate: [
              "componentId",
              "editInstructions",
              "type",
              "generatedTitle",
              "previousComponents",
              "nextComponents",
              "inputPlaceholders",
              "outputPlaceholders",
              "description",
              "editConnectionsOnly"
            ],
            componentsToDelete: [
              "componentId"
            ],
            databasesToUpdate: [
              "databaseId",
              {
                newColumns: [
                  "columnId",
                  "type",
                  "name",
                  "databaseId"
                ]
              }
            ],
            databasesToAdd: [
              "databaseId",
              "name",
              "componentLinks",
              {
                columns: [
                  "columnId",
                  "type",
                  "name",
                  "databaseId"
                ]
              }
            ],
            databasesToDelete: [
              "databaseId"
            ],
          }
        ],
      },
      "aiBuildingError",
    ]
  });
};