import React, { useState, useEffect } from "react";

// MUI
import { Grid, Skeleton, styled } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { send_request } from "../../../../../utils/Request";

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "4px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

function TriggerFromAWorkflowInfo({ componentId, version, TriggerTitle, TriggerDescription, BlackButton }) {
    const [loading, setLoading] = useState(false);
    const [sourceData, setSourceData] = useState(null);

    useEffect(async () => {
        setLoading(true);
        if (!componentId) return;

        let url = `project-service/project/component/query/${componentId}/at-version/${version}`;
        const json = await send_request(url, null, null, "GET");

        try {
            let componentData = json.data.components[0].componentData;
            let sourceProjectId = componentData.data.connectionData.sourceProjectId;

            url = `project-service/project/${sourceProjectId}`;
            const jsonTwo = await send_request(url, null, null, "GET");
            console.log(jsonTwo);

            setSourceData({
                sourceWorkflowName: jsonTwo.data.name ? jsonTwo.data.name : "Untitled",
                sourceLink: `/project/canvas/${sourceProjectId}`,
            });

            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }, []);

    const getTriggerFromAWorkflowContent = () => {
        if (loading) {
            return (
                <>
                    <Grid item xs={12}>
                        <StyledSkeleton variant="rectangular" height="90px" />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledSkeleton variant="rectangular" height="40px" width="120px" />
                    </Grid>
                </>
            );
        }

        if (sourceData) {
            return (
                <>
                    <Grid item xs={12}>
                        <TriggerDescription>
                            Your trigger component is a Trigger from another workflow component. This workflow will be triggered when <b>{sourceData.sourceWorkflowName}</b> is run. You can run{" "}
                            <b>{sourceData.sourceWorkflowName}</b> by clicking the button below.
                        </TriggerDescription>
                    </Grid>
                    <Grid item xs={12}>
                        <BlackButton variant="contained" onClick={() => window.open(sourceData.sourceLink, "_blank")} startIcon={<OpenInNew />}>
                            Open Workflow
                        </BlackButton>
                    </Grid>
                </>
            );
        } else {
            return (
                <Grid item xs={12}>
                    <TriggerDescription>This component has not been setup yet.</TriggerDescription>
                </Grid>
            );
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TriggerTitle>Trigger another workflow to start this workflow</TriggerTitle>
            </Grid>
            {getTriggerFromAWorkflowContent()}
        </Grid>
    );
}

export default TriggerFromAWorkflowInfo;
