import React from "react";

import { Grid } from "@mui/material";
import { styled, textAlign } from "@mui/system";

const Box = styled(Grid)(({ borderColor, backgroundColor }) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: "4px",
    textAlign: "center",
    padding: "8px 0px",
    margin: "16px 16px 0px 16px",
    backgroundColor: backgroundColor,
}));

const EndedText = styled("span")({
    fontWeight: "bold",
});

function FreeTrialRemainingBox({ trialRemaining }) {
    const getTrialEndedBox = () => {
        return (
            <Box item xs={12} borderColor="rgba(176, 0, 32, 1)" backgroundColor="rgba(176, 0, 32, 0.15)">
                <Grid container>
                    <Grid item xs={12}>
                        <EndedText>Your free trial has ended</EndedText>
                    </Grid>
                    <Grid item xs={12}>
                        Upgrade to a paid plan to continue to use Workflow86
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const getRemainingBox = () => {
        return (
            <Box item xs={12} borderColor="rgba(33, 150, 243, 1)" backgroundColor="rgba(211, 234, 253, 1)">
                <EndedText>{parseInt(trialRemaining)} days remaining</EndedText>
            </Box>
        );
    };

    return trialRemaining !== null && (trialRemaining <= 0 ? getTrialEndedBox() : getRemainingBox());
}

export default FreeTrialRemainingBox;
