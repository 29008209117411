import dayjs from "dayjs";
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export const generateTimeTracking = (c) => {
    let earliestStart = null;
    let latestEnd = null;
    let isWaitingState = false;

    let start = dayjs(c.startAt);
    let end = dayjs(c.endAt);
    let endStartDiff = end.diff(start);                  
    // Calculate the duration
    // Keep in mind you can get the duration in seconds, days, etc.                  

    if(endStartDiff < 0) {
      endStartDiff = endStartDiff * -1;

      //swith start and end
      let t = start;
      start = end;
      end = t;
    }

    if(c.status == "WAITING" || c.status == "PAUSED") {
      //the diff is from current time
      endStartDiff = dayjs().diff(start);
      isWaitingState = true;
    }


    if(endStartDiff < 1000) {
      //less than 1 second
      //round to 1 second
      endStartDiff = 1000;
    }

    c["timeTracking"] = endStartDiff
    
    if(earliestStart == null) {
      //init
      earliestStart = start;
    } else {
      if(start.isBefore(earliestStart)) {
        earliestStart = start;
      }
    }

    if(latestEnd == null) {
      //init
      latestEnd = end;
    } else {
      if(end.isAfter(latestEnd)) {
        latestEnd = end;
      }
    }

    return c;
  }

export const newLineToBreak = (str) => {
  return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
};

export const breakToNewLine = (str) => {
  return str.replace(/<br\s*\/?>/gm, "\n");
};
export const formatSearchHit = (searchHit) => {
    if(searchHit) {
        const startMarkIndex = searchHit.indexOf("<mark>");
        const endMarkIndex = searchHit.indexOf("</mark>");
        if(startMarkIndex===-1 || endMarkIndex ===-1)
            return searchHit;
        const searchTextIndex = startMarkIndex + 6
        const preMark = searchHit.substring(0, startMarkIndex);
        const postMark = searchHit.substring(endMarkIndex + 7);
        const searchText = searchHit.substring(searchTextIndex, endMarkIndex);

        return <a>{preMark}<mark>{searchText}</mark>{postMark}</a>
    }
    return "";
}