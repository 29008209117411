import React, { Component } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, TextField } from "@mui/material";
import ItemStatement from "./ItemStatement";
import { OutputType } from "./components";
import ConditionalLogicStore from "../ConditionalLogicStore";
import { toJS } from "mobx";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { withStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import checkKey from "../../../utils/CheckKey";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";
import { Paper, IconButton, Menu } from "@mui/material";
import RibbonItem from "vardogyir-ui/RibbonItem";
import { MoreHoriz } from "@mui/icons-material";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";

const styles = (theme) => ({
  paperItem: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: "24px 16px",
    marginBottom: "16px",
  },
  conditionFrame: {
    borderStyle: "solid",
    borderColor: "#86868650",
    borderRadius: "6px",
    borderWidth: "1.5px",
    padding: "16px",
  },
  conditionPartFrame: {
    borderStyle: "solid",
    borderColor: "#86868650",
    borderRadius: "6px",
    borderWidth: "1.5px",
    margin: "4px",
    padding: "0px 8px 8px 4px",
  },
  spacing: {
    marginBottom: "16px",
  },
  conditionSpacing: {
    marginBottom: "8px",
  },
});

class ItemLogic extends Component {
  state = {
    checkKey: false,
    errorMessage: "",
    updateState: false,
    extend: "block",
    menu: false,
    anchorEl: null,
    placeholderValue: this.props.data.key
  };

  handleKeyChanges = (event, indexLogic, keyRandom) => {
    const { itemList } = this.props;
    const placeholder = event.target.value;
    const { placeholdersError } = toJS(ConditionalLogicStore);
    const filterItem = itemList.filter((item) => item.key === placeholder);
    this.validateKey(event, indexLogic, placeholdersError);
  };

  handleInputKeyBlur = (event, indexLogic, keyRandom) => {
    const { itemList } = this.props;
    ConditionalLogicStore.setLogic(event.target.value, indexLogic, "key");
    const placeholderValue = event.target.value;

    const { placeholdersError } = toJS(ConditionalLogicStore);
    const filterItem = itemList.filter((item) => item.key === placeholderValue);
    this.validateKey(placeholderValue, indexLogic, placeholdersError);

    this.setState({
      key: event.target.value,
    });
  };

  handleInputKey = (event) => {
    const placeholderValue = replacePlaceholderValue(event.target.value);

    ConditionalLogicStore.setLogic(placeholderValue, this.props.indexLogic, "key");
    this.setState({
      placeholderValue,
    });
  };

  validateKey = (placeholder, indexLogic, placeholdersError) => {
    const { data } = toJS(ConditionalLogicStore);
    const value = checkKey(placeholder, indexLogic, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
    this.props.handleKeyChanges(value[0]);

    if (value[0]) {
      if (!placeholdersError.includes(this.props.keyRandom)) {
        placeholdersError.push(this.props.keyRandom);
      }
    } else {
      let index = placeholdersError.indexOf(this.props.keyRandom);
      if (index !== -1) {
        placeholdersError.splice(index, 1);
      }
    }
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;

    this.setState({ updateState: !currentState });
  };

  removePlaceholderError = () => {
    const { placeholdersError } = toJS(ConditionalLogicStore);

    let index = placeholdersError.indexOf(this.props.keyRandom);
    if (index !== -1) {
      placeholdersError.splice(index, 1);
    }
  };

  render() {
    const {
      indexLogic,
      data,
      availablePlaceholders,
      placeholders,
    } = this.props;
    const { key, outputType, statement } = data;
    const { condition } = statement.content;
    const { placeholdersError, newData } = toJS(ConditionalLogicStore);
    const { classes } = this.props;

    return (
      <Paper className={classes.paperItem}>
        <Grid container xs={12} direction={"row"} alignItems={"center"}>
          <Grid item>
            <p>Placeholder</p>
          </Grid>
          <Grid xs={this.props.isExpanded ? 4 : 9} item marginLeft={2}>
            <W86PlaceholderTextField
              value={this.state.placeholderValue}
              defaultValue={key}
              error={placeholdersError.includes(this.props.keyRandom)}
              helperText={placeholdersError.includes(this.props.keyRandom) ? this.state.errorMessage : null}
              placeholderText="Enter placeholder for conditional logic"
              handleChange={(event) => this.handleInputKey(event)}
              onBlur={(event) =>
                this.handleInputKeyBlur(event, indexLogic, data.keyRandom)
              }
            />
          </Grid>

          {this.state.extend === "none" ? (
            <Grid xs={6} item />
          ) : (
            <Grid xs={this.props.isExpanded ? 3 : 0} item />
          )}

          {this.state.extend && (
            <Grid
              xs={this.props.isExpanded ? 1 : 2}
              style={{
                display: this.state.extend,
              }}
              item
            >
              <p>Output Type</p>
            </Grid>
          )}

          <Grid
            xs={this.props.isExpanded ? 2 : 4}
            item
            style={{
              display: this.state.extend,
            }}
          >
            <OutputType
              value={outputType}
              onChange={(value) => {
                ConditionalLogicStore.setLogic(value, indexLogic, "outputType");
                this.forceUpdateState();
              }}
            />
          </Grid>

          <Grid
            xs={this.props.isExpanded ? 1 : 6}
            item
            container
            direction={"row"}
            justifyContent={"flex-end"}
            style={{
              display: this.state.extend === "block" ? "flex" : "none",
            }}
          >
            <IconButton
              onClick={(event) => {
                this.setState({ menu: true, anchorEl: event.currentTarget });
              }}
              className={"icon-btn"}
              size="large"
            >
              <MoreHoriz className={"material-icons "} />
            </IconButton>
            {this.state.menu && (
              <Menu
                onClose={(event) => {
                  this.setState({ menu: false });
                }}
                open={this.state.menu}
                anchorEl={this.state.anchorEl}
              >
                <RibbonItem onClick={this.props.copyThisLogic}>
                  Duplicate block
                </RibbonItem>
                <RibbonItem
                  onClick={() => {
                    ConditionalLogicStore.newRemoveLogic(this.props.keyRandom);
                    this.removePlaceholderError();
                    if (!newData.includes(this.props.keyRandom)) {
                      this.props.removeItem();
                    } else {
                      let index = newData.findIndex(
                        (x) => x === this.props.keyRandom
                      );
                      newData.splice(index, 1);
                      this.props.updateItemsInPage();
                    }
                    this.setState({ menu: false });
                    this.setState({ anchorEl: null });
                  }}
                >
                  Delete block
                </RibbonItem>
              </Menu>
            )}
          </Grid>

          <Grid xs={12} justifyContent={"flex-end"} direction={"row"} item container>
            {this.state.extend === "block" ? (
              <div></div>
            ) : (
              <ExpandMoreIcon
                onClick={() => this.setState({ extend: "block" })}
              />
            )}
          </Grid>
        </Grid>
        <div>
          <Grid style={{ display: this.state.extend }}>
            {condition &&
              condition.map((con, index) => (
                <ItemStatement
                  classes={this.props.classes}
                  isExpanded={this.props.isExpanded}
                  condition={con}
                  indexLogic={indexLogic}
                  indexStatement={index}
                  key={index + key}
                  outputType={outputType}
                  availablePlaceholders={availablePlaceholders}
                  placeholders={placeholders}
                  forceUpdateState={this.forceUpdateState}
                />
              ))}
          </Grid>
          <Grid xs={12} justifyContent={"flex-end"} direction={"row"} container>
            {this.state.extend === "block" ? (
              <ExpandLessIcon
                onClick={() => this.setState({ extend: "none" })}
              />
            ) : (
              <div></div>
            )}
          </Grid>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(ItemLogic);
