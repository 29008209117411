import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";

function CodeEditorModal({ open, onClose, code, codeLang }) {
    const codeLangToAceEditorType = (codeLang) => {
        if (codeLang == "NodeJS_16") {
            return "javascript";
        }
        return "python";
    };

    const handleCopyCode = () => {
        navigator.clipboard.writeText(code);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent>
                <Box sx={{ position: 'relative' }}>
                    <Tooltip title="Copy code">
                        <IconButton 
                            onClick={handleCopyCode}
                            sx={{
                                position: 'absolute',
                                right: '8px',
                                top: '8px',
                                zIndex: 1,
                                backgroundColor: '#fff',
                                border: '1px solid #e0e0e0',
                                borderRadius: '4px',
                                padding: '8px',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                    border: '1px solid #e0e0e0'
                                }
                            }}
                        >
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <AceEditor
                        mode={codeLangToAceEditorType(codeLang)}
                        theme="github"
                        name="code-editor-modal"
                        editorProps={{ $blockScrolling: true }}
                        value={code}
                        readOnly={true}
                        width="100%"
                        height="80vh"
                        wrapEnabled={true}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default CodeEditorModal; 