import React, {Component} from "react";
import Grid from "@mui/material/Grid";
import {createStyles, makeStyles} from "@mui/styles";
import {observer} from "mobx-react";
import JoditEditor from "jodit-pro-react";
import "./APITextEditor.css";
import {Tooltip} from "@mui/material";
import JoditSuperClass from "./JoditSuperClass";

import { configJodit } from "../../utils/configJodit";

const componentStyles = makeStyles(() => {
  createStyles({
    root: {
      flexGrow: 1,
    },
  });
});


class TextEditor extends JoditSuperClass {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content: this.props.html ? this.props.html : "",
      oldContent: this.props.html ? this.props.html : "",
    };
    this.props.editorFocus();
  }

  componentDidMount() {
    let content = this.props.html;
    if (content) {
      content = content.replace(/(?:\r\n|\r|\n)/g, "<br>");
    } else {
      content = "";
    }

    this.setState({
      content,
    });
  }

  componentDidUpdate(prevProps) {
    const { placeholder } = this.props;
    if (placeholder && placeholder.length !== prevProps.placeholder.length) {
      this.insertPlaceholder(placeholder[placeholder.length - 1]);
    }
  }

  updateContent = (value) => {
    value = value.replace(/(?:\n)/g, "");
    this.props.onChange(value);
  };

  insertPlaceholder = (placeholder) => {
    if (placeholder) {
      const text = "${" + placeholder.text + "}";
      this.jodit.selection.insertHTML(text);
    }
  };
  config = {
    readonly: this.props.readOnly ? this.props.readOnly : false,
    disablePlugins: ["tune-block", "button-generator", "inline-popup", "google-maps", "export-docs"],
    defaultActionOnPaste: "insert_only_text",
    toolbarAdaptive: false,
    toolbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    paste: false,
    placeholder: "",
    events: {
      afterInit: (instance) => {
        this.jodit = instance;
      },
    },
    autocomplete: {
      sources: [
        {
          feed: (query) =>{
            return this.sourceFeed(query,'$');
          },

          itemRenderer: (item) => {
            return this.itemRenderer(item,'$');
          },
          insertValueRenderer: ({ key, type, length }) => {
            return this.insertValueRenderer(type, length, key,'$');
          },
        }
      ]    },
    highlightSignature: {
      schema: {
        "\\$\\{([^}]+)\\}": (jodit, matched) => {
          return this.getPlaceholderStyleForExistingPlaceholders(matched, jodit);
        },
      },
    },
    askBeforePasteHTML: false,
    uploader: { url: 'none' }, // Added to prevent images from being pasted from clipboard
    ...configJodit,
    ...this.props.contentStyles,
  };

  render() {
    const { content } = this.state;
    if (!this.state.loading) {
      return (
        <Tooltip
          placement="bottom"
          title="Type “$” to view and insert placeholders"
        >
          <Grid container className={componentStyles.root} spacing={3}>
            <Grid item xs={12}>
              <span className="joditEditor">
                <JoditEditor
                  ref={this.setRef}
                  value={content}
                  config={this.config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={this.updateContent}
                />
              </span>
            </Grid>
          </Grid>
        </Tooltip>
      );
    } else {
      return (
        <div className="my-nice-tab-container">
          <div className="loading-state"></div>
        </div>
      );
    }
  }
}

export default observer(TextEditor);