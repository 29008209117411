import React, { useState, useEffect } from "react";

import { Chip, Skeleton, styled } from "@mui/material";
import { send_request } from "../../utils/Request";
import { getColourFromString } from "../../utils/ColourNameGenerator";
import {formatSearchHit} from "./Util";

const StyledSkeleton = styled(Skeleton)(({ minWidth, maxWidth, height, margin }) => ({
    borderRadius: "10px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
    minWidth: minWidth,
    maxWidth: maxWidth
}));

const ChipItem = styled(Chip)(({ colour }) => ({
    border: `1px solid ${colour.border}`,
    backgroundColor: colour.bg,
    boxSizing: "border-box",
    borderRadius: "4px",
    marginRight: "6px",
}));


function TaskWorkflowName({ task, showExternalText, returnChip }) {
    const [isLoading, setIsLoading] = useState(true);
    const [workflowName, setWorkflowName] = useState("");

    useEffect(async () => {
        setIsLoading(true);

        // Get the workflow name OR
        // Get the client name of the task creator if an external task
        let name = formatSearchHit(task.projectName);

        setWorkflowName(name);
        setIsLoading(false);
    }, []);

    const getWorkflowName = async (task) => {
        const { projectName, projectId, isExternal, taskId } = task;

        // Handle external tasks
        if (isExternal) {
            let url = `task/task/get_task_creator/${taskId}`;
            const json = await send_request(url, null, null, "GET");

            if (!json || !json.data) return "[External]";
            else return `${showExternalText ? "[External] " : ""} ${json.data}`;
        }

        if (projectId === undefined || projectId === null) {
            return "Deleted workflow";
        }

        if (projectName !== undefined && projectName !== null) {
            if (projectName.length !== 0) {
                return projectName;
            } else {
                return projectId;
            }
        } else {
            return projectId;
        }
    };

    const getItem = () => {
        if (returnChip) return <ChipItem label={workflowName} colour={getColourFromString(workflowName)} />;
        else return workflowName;
    };

    return !isLoading ? getItem() : <StyledSkeleton minWidth="100px" height="20px" variant="rectangular" maxWidth="200px" />;
}

export default TaskWorkflowName;
