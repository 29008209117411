import React, { useState, useEffect } from "react";

import { Autocomplete, Grid, TextField } from "@mui/material";
import { send_request } from "../../../../utils/Request";

function AddDatabaseUI({ AddDBText, StyledSkeleton, setShowAddDatabaseUI, createdButNotConnectedDBs, setCreatedButNotConnectedDBs, connectedDBs, projectId, version }) {
    const [fetchingDBs, setFetchingDBs] = useState(false);
    const [databases, setDatabases] = useState([]);
    const [selectedDB, setSelectedDB] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        setFetchingDBs(true);

        const json = await send_request(`database/get_databases`, "", "", "GET");
        if (json && json.data) {
            let connectedDBIds = getDBIds(connectedDBs);
            let createdButNotConnectedDBIds = getDBIds(createdButNotConnectedDBs);
            
            let allDBs = json.data;

            // Remove the currently connected databases from the list
            let dbs = [];
            for (let db of allDBs) {
                if (connectedDBIds.includes(db.databaseId) || createdButNotConnectedDBIds.includes(db.databaseId)) continue;
                dbs.push(db);
            }

            setDatabases(dbs);
        }

        setFetchingDBs(false);
    }, []);

    const getDBIds = (dbs) => {
        let ids = [];
        for (let db of dbs) {
            ids.push(db.databaseId);
        }
        return ids;
    };

    const handleSave = async () => {
        if (fetchingDBs) return;

        setLoading(true);

        if (selectedDB) {
            // Make request to save
            const url = `project-service/project/link_ai_database/${projectId}/${selectedDB.databaseId}/${version}`;
            const json = await send_request(url, null, null, "GET");
            setCreatedButNotConnectedDBs([...createdButNotConnectedDBs, selectedDB]);
        }

        setLoading(false);
        setShowAddDatabaseUI(false);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {!fetchingDBs ? (
                    <Autocomplete
                        freeSolo
                        disablePortal
                        options={databases}
                        disabled={loading}
                        getOptionLabel={(option) => (option.name ? option.name : option.databaseId)}
                        renderInput={(params) => <TextField {...params} size="small" variant="outlined" placeholder="Select a database" />}
                        renderOption={(props, option) => (
                            <li {...props} key={option.databaseId}>
                                {option.name ? option.name : option.databaseId}
                            </li>
                        )}
                        onChange={(event, newValue) => {
                            if (!newValue) return;
                            setSelectedDB(newValue);
                        }}
                        value={selectedDB}
                    />
                ) : (
                    <StyledSkeleton variant="rectangular" height="35px" />
                )}
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                {!loading ? (
                    <AddDBText color={fetchingDBs && "rgba(0, 0, 0, 0.4)"} hoverColor={fetchingDBs && "rgba(0, 0, 0, 0.4)"} onClick={handleSave}>
                        Save
                    </AddDBText>
                ) : (
                    <AddDBText color={"rgba(0, 0, 0, 0.4)"} hoverColor={"rgba(0, 0, 0, 0.4)"} onClick={handleSave}>
                        Adding database...
                    </AddDBText>
                )}
            </Grid>
        </Grid>
    );
}

export default AddDatabaseUI;
