import React, { useState } from "react";

import { Grid, InputLabel } from "@mui/material";

import {
  ConnectionText,
  BlueText,
  PlaceholderContainer,
  PlaceholderHeader,
  InsideGrid,
  SourceBox,
  NameContainer,
  PlaceholderRow,
  StyledArrow,
  PlaceholderTextField
} from "./StyledComponents";

import { Link } from "react-router-dom";
import RenderPlaceholderTextField from "./RenderPlaceholderTextField";

function RenderConnectionData(props) {
  const [error, setError] = useState({ hasError: false, message: "", idx: 0 });

  const closeError = () => {
    setError({ hasError: false, message: "", idx: 0 });
  };

  // Gets the project label
  const getProjectLabel = projId => {
    let proj = props.projects.find(proj => proj.id === projId);
    return proj;
  };

  // Gets the component label
  const getComponentLabel = compId => {
    let comp = props.components.find(comp => comp.id === compId);
    return comp;
  };

  // Checks if this new value is unique amongst all the placeholders
  const isPlaceholderUnique = (val, pHs, idx) => {
    for (let i = 0; i < pHs.length; i++) {
      if (i === idx) continue;
      if (pHs[i].targetKey === val) return false;
    }
    return true;
  };

  // Sets the value in the state on blur of the textfield
  const handleOnTextFieldBlur = (newVal, idx) => {
    if (newVal === "") {
      setError({
        hasError: true,
        message: "Placeholder cannot be empty",
        idx: idx
      });
      return;
    } else if (
      !isPlaceholderUnique(newVal, props.connectionData.placeholders, idx)
    ) {
      setError({
        hasError: true,
        message: "Placeholder must be unique",
        idx: idx
      });
      return;
    }

    closeError();
    let newConnectionData = Object.assign({}, props.connectionData);
    newConnectionData.placeholders[idx].targetKey = newVal;
    newConnectionData.placeholders[idx].targetValue = "${" + newVal + "}";
    props.updateState({ connectionData: newConnectionData });
  };

  const getTimeSince = timeStamp => {
    let now = new Date(),
      secondsPast = (now.getTime() - timeStamp) / 1000;

    if (secondsPast < 60) {
      return parseInt(secondsPast) + "s";
    }
    if (secondsPast < 3600) {
      return parseInt(secondsPast / 60) + "m";
    }
    if (secondsPast <= 86400) {
      return parseInt(secondsPast / 3600) + "h";
    }
    if (secondsPast > 86400) {
      day = timeStamp.getDate();
      month = timeStamp
        .toDateString()
        .match(/ [a-zA-Z]*/)[0]
        .replace(" ", "");
      year =
        timeStamp.getFullYear() == now.getFullYear()
          ? ""
          : " " + timeStamp.getFullYear();
      return day + " " + month + year;
    }
  };

  const getTimeText = (timeStamp) => {
    try {
      return `last updated ${getTimeSince(timeStamp)} ago`
    } catch(e) {
      return '';
    }
  }

  const renderPlaceholderTable = () => {
    return props.connectionData.placeholders.length != 0 ? (
      <PlaceholderContainer container>
        <NameContainer container item xs={12}>
          <Grid item xs={5}>
            <PlaceholderHeader>
              Placeholder in the Source Workflow
            </PlaceholderHeader>
          </Grid>
          <InsideGrid item xs={2}></InsideGrid>
          <Grid item xs={5}>
            <PlaceholderHeader>
              Export as placeholders in this workflow
            </PlaceholderHeader>
          </Grid>
        </NameContainer>

        {props.connectionData.placeholders.map((item, idx) => {
          return (
            <PlaceholderRow container item xs={12} key={idx}>
              <Grid item xs={5}>
                <PlaceholderTextField 
                  fullWidth
                  disabled
                  value={item.sourceKey}
                />
              </Grid>
              <InsideGrid item xs={2}>
                <StyledArrow />
              </InsideGrid>
              <Grid item xs={5}>
                <RenderPlaceholderTextField
                  currVal={item.targetKey}
                  handleOnTextFieldBlur={handleOnTextFieldBlur}
                  index={idx}
                  error={error}
                />
              </Grid>
            </PlaceholderRow>
          );
        })}
      </PlaceholderContainer>
    ) : (
      <NameContainer>
        <InputLabel>
          You have not selected any placeholders to export
        </InputLabel>
      </NameContainer>
    );
  };

  return (
    <>
      <Grid container marginTop="24px" marginBottom="32px">
        <Grid item xs={12}>
          <ConnectionText>
            🔌 Connected to{" "}
            <Link
              to={`/project/canvas/${props.connectionData.sourceProjectId}/trigger_another_workflow/${props.connectionData.sourceComponentId}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <BlueText>
                {getComponentLabel(props.connectionData.sourceComponentId).label}{" "}
                in {getProjectLabel(props.connectionData.sourceProjectId).label}
              </BlueText>
            </Link>
            {getTimeText(props.connectionData.connectionTime)}
          </ConnectionText>
        </Grid>
      </Grid>
      {error && renderPlaceholderTable()}
    </>
  );
}

export default RenderConnectionData;
