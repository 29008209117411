export const days = [
  {
  "key": "1",
  "name": "SUN",
  "active" : false
  },
  {
    "key": "2",
    "name": "MON",
    "active" : false
  },
  {
    "key": "3",
    "name": "TUE",
    "active" : false
  },
  {
    "key": "4",
    "name": "WED",
    "active" : false
  },
  {
    "key": "5",
    "name": "THU",
    "active" : false
  },
  {
    "key": "6",
    "name": "FRI",
    "active" : false
  },
  {
    "key": "7",
    "name": "SAT",
    "active" : false
  }
]
