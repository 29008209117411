import React from "react";

// MUI
import { styled } from "@mui/system";
import { CustomDialog } from "../../Component/Dialog";
import { Button } from "@mui/material";

const ContactUsText = styled("span")({
    fontWeight: "bold",
    "&:hover": {
        cursor: "pointer",
    },
});

function ErrorExtendingFreeTrialDialog({ errorExtendingTrial, setErrorExtendingTrial }) {
    return (
        <CustomDialog
            fullWidth={true}
            maxWidth={"sm"}
            isOpen={errorExtendingTrial}
            title={"⚠️ It appears there was an error extending your free trial"}
            contents={
                <p>
                    Please <ContactUsText id="chat_with_someone">contact us</ContactUsText> to find out more information
                </p>
            }
            buttons={
                <>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                            setErrorExtendingTrial(false);
                        }}
                    >
                        CLOSE
                    </Button>
                </>
            }
        />
    );
}

export default ErrorExtendingFreeTrialDialog;
