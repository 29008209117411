import React, { useState, useContext } from "react";
import uuid from "uuid";
import {
  send_request,
  send_request_graphql_mutation,
} from "../../../utils/Request";
import {
  createMapAZapStickyNotes,
  isValidSharedZapierUrl,
} from "./MapAZapHelpers";
import MapAZapContent from "./MapAZapContent";
import { useParams } from "react-router-dom";
import ProjectStore from "../../ProjectCanvas/ProjectStore";
// Material UI
import { Dialog, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import MapAZapStyles from "./MapAZapStyles";
import { CanvasContext } from "../../pages/Canvas/WorkflowCanvas";
//constructor for save event
import { createSaveEvent } from "../../../utils/createSaveEvent";
import {
  SAVE_EVENT,
  MAP_A_ZAP_SAVE_EVENT,
} from "../../pages/Canvas/CanvasQueries";
import { getElements, extractNodes, extractEdges } from "../../../utils/CanvasUtil";

const styles = MapAZapStyles;

function MapAZap(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [urls, setUrls] = useState([{ id: uuid(), url: "" }]);

  const { classes, showMapAZap, setShowMapAZap, setNodes, setEdges, position } = props;

  const { canvasInstance } = useContext(CanvasContext);
  const { savingInfo } = useContext(CanvasContext);
  const [reactFlowInstance, setReactFlowInstance] = canvasInstance
    ? canvasInstance
    : useState({});
  const [saving, setSaving] = savingInfo ? savingInfo : useState({});

  //record last modified value for the canvas
  const { updated } = useContext(CanvasContext);
    const [lastModified, setLastModified] = updated? updated :  useState({});

  // Turns on error with given errorMessage
  const handleError = (errorMsg) => {
    setError(true);
    setErrorMessage(errorMsg);
  };

  // Closes all errors
  const closeError = () => {
    setError(false);
    setErrorMessage("");
  };

  // Closes all
  const closeAll = () => {
    closeError();
    setLoading(false);
    setUrls([{ id: uuid(), url: "" }]);
    setShowMapAZap(false);
  };

  // Returns true if all urls in given array are valid
  const hasNoErrors = (urls) => {
    if (urls.includes("")) {
      handleError("Shared Zapier url(s) cannot be empty");
      return false;
    }

    for (let i = 0; i < urls.length; i++) {
      if (!isValidSharedZapierUrl(urls[i])) {
        handleError("You have not entered valid shared Zapier url(s)");
        return false;
      }
    }

    return true;
  };

  // Handles the call to the backend
  const handleMapping = async () => {
    if (saving) return; // If saving, return
    
    setSaving(true);
    if (position == null) return;
    let allUrls = urls.map((url) => {
      return url.url;
    });

    if (hasNoErrors(allUrls)) {
      // Make request to backend
      setLoading(true);

      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      let eventData = {
        urls: allUrls,
      };

      let saveEvent = createSaveEvent(
        "MAP_A_ZAP",
        ProjectStore.project_id,
        viewport,
        getElements(reactFlowInstance),
        eventData
      );

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${ProjectStore.project_id}`,
        MAP_A_ZAP_SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((data) => {
          //insert delay here
          setSaving(false);
          if (data.data.saveEvent && data.data.saveEvent.updated) {
            setLastModified(data.data.saveEvent.lastModified);
          }
          createCanvasStickyNotes(data.data.saveEvent.zapObjects, allUrls);
          setLoading(false);
        })
        .catch((error) => {
          handleError(
            "There was an error processing your request. Please enter a valid Zapier shared url"
          );
          console.log(error);
        });
    }
  };

  // Creates all the sticky notes
  const createCanvasStickyNotes = async (dataArray, urls) => {
    setSaving(true);
    let finalNoteCollection = [];

    for (let y = 0; y < dataArray.length; y++) {
      finalNoteCollection.push(
        createMapAZapStickyNotes(dataArray[y], urls[y], y, position)
      );
    }

    const flattenedCollection = [...finalNoteCollection.flat()];
    setNodes((ns) => ns.concat(extractNodes(flattenedCollection)));
    setEdges((es) => es.concat(extractEdges(flattenedCollection)));

    let rfiObj = reactFlowInstance.toObject();

    let viewport = {
      x: rfiObj.viewport.x.toFixed(5),
      y: rfiObj.viewport.y.toFixed(5),
      zoom: rfiObj.viewport.zoom.toFixed(5),
    };

    let eventData = {
      notesSection: finalNoteCollection.flat(),
    };

    let saveEvent = createSaveEvent(
      "ADD_MULTIPLE_NOTES",
      ProjectStore.project_id,
      viewport,
      getElements(reactFlowInstance),
      eventData
    );

    //send event
    await send_request_graphql_mutation(
      `project-service/graphql/project/save/${ProjectStore.project_id}`,
      SAVE_EVENT(saveEvent),
      "",
      "POST"
    )
      .then((response) => {
        if (response.data.saveEvent && response.data.saveEvent.updated) {
          setLastModified(response.data.saveEvent.lastModified);
        }
        setSaving(false);
      })
      .catch((error) => {
        console.log(error);
      });

    closeAll();
  };

  // Changing the textfield input
  const handleChange = (e) => {
    var index = urls.findIndex((url) => url.id === e.target.id);
    if (index === -1) return;

    let updatedAttribute = {
      url: e.target.value,
    };

    setUrls([
      ...urls.slice(0, index),
      Object.assign({}, urls[index], updatedAttribute),
      ...urls.slice(index + 1),
    ]);
  };

  // Adding another zap textfield
  const handleAddingAnotherZap = () => {
    let newInput = { id: uuid(), url: "" };
    setUrls([...urls, newInput]);
    closeError();
  };

  // Removing a zap textfield
  const handleRemovingInput = (id) => {
    if (urls.length < 2) {
      handleError("There must be at least one shared Zapier url");
      return;
    }

    let newUrls = urls.filter((url) => url.id != id);
    setUrls(newUrls);
  };

  const loadingScreen = (
    <Grid container className={classes.loadingScreen}>
      <Grid item xs={12} className={classes.loadingScreenItem}>
        <img src="/images/loading_200.gif" />
      </Grid>
      <Grid item xs={12} className={classes.loadingScreenItem}>
        <span className={classes.loadingScreenText}>Mapping...</span>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={showMapAZap}
      onClose={() => {
        closeAll();
      }}
    >
      {!loading ? (
        <MapAZapContent
          classes={classes}
          urls={urls}
          error={error}
          errorMessage={errorMessage}
          handleChange={handleChange}
          handleRemovingInput={handleRemovingInput}
          handleAddingAnotherZap={handleAddingAnotherZap}
          closeAll={closeAll}
          handleMapping={handleMapping}
        />
      ) : (
        loadingScreen
      )}
    </Dialog>
  );
}

export default withStyles(styles)(MapAZap);
