import { computed, observable, decorate, action, toJS } from "mobx";

class QueryDatabaseStore {
  constructor() {
    this.initialiseEmpty();
  }

  setUpdateDatabaseRecordTemplate = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const data = this.initData()
      this.data = data
      this.lastModified = "";
      this.name = "";
    }

  };

  setData = (data) => {
    this.data = data
  }

  setAvailableCols = (availableCols) => {
    this.data.availableCols = availableCols
  }

  setQueryData = (queryData) => {
    this.data.queryData = queryData
  }

  clearQueryData = () => {
    this.data.queryData = []
  }

  clearData = () => {
    this.data.columns = []
  }

  setTargetDatabaseId = (targetDatabaseId) => {
    this.data.targetDatabaseId = targetDatabaseId;
  }

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  }

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }

  setFocus = (index,focus) => {
    this.position = index;
    this.focus = focus;
    this.placeholders = []
  };

  initData = () => {
    return {
      targetDatabaseId : null,
      availableCols: [],
      queryData: []

    };
  }

  initialiseEmpty = () => {
    this.name = "";
    this.componentId = "";
    this.type = "query_database";
    this.lastModified = "";
    this.placeholders = [];
    this.data = this.initData();
    this.position = null;
    this.focus = null
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: toJS(this.placeholders),
      position: this.position,
      focus: this.focus
    };
  }
}

decorate(QueryDatabaseStore, {
  componentId: observable,
  name: observable,
  data: observable,
  type: observable,
  lastModified: observable,
  setLastModified: action,
  initialiseEmpty: action,
  template: computed,
  placeholders: observable,
  setPlaceholders: action,
  position: observable,
  setFocus: action,
  targetDatabaseId: observable,
  setTargetDatabaseId: action,
  focus: observable,
  returnType: observable
});

const queryDatabaseStore = new QueryDatabaseStore();
export default queryDatabaseStore;
