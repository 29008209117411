import React, {Component} from "react";
import {observer} from "mobx-react";
import {withStyles} from "@mui/styles";
import ChipInput from "../ChipInput/ChipInput";
import Paper from "@mui/material/Paper";
import {toJS} from "mobx";
import DocumentStore from "../DocumentEditor/DocumentStore";


const useStyles = () => ({});

class DocumentFilePlaceholderUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.props.onFocus();
    }

    render() {
        return (<div>
            <Paper sx={{width: "100%", padding: "16px"}}>
                <h4>
                    Generate document via placeholder
                </h4>
                <div style={{fontSize: 14, marginBottom: 24}}>
                <span>
                  Generate documents by merging placeholders into an uploaded
                  .docx template.{" "}
                    <a href="https://docs.workflow86.com/docs/components/doc_editor/#generate-documents-via-a-placeholder"
                       target="_blank" className="a-href">
                    Learn more here
                  </a>
                </span>
                </div>
                <ChipInput
                    style={{width: "100%"}}
                    focus={this.props.onFocus}
                    placeholders={this.props.availablePlaceholders}
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    inputValue={this.props.defaultValues ? toJS(this.props.defaultValues) : []}
                    placeholder={toJS(this.props.defaultValues)}
                    onDelete={(index) => {
                        this.props.defaultValues.splice(index, 1);
                        DocumentStore.setFileUrl(this.props.defaultValues)
                    }}>
                </ChipInput>
            </Paper>
        </div>
        )
    }
}

export default observer(withStyles(useStyles)(DocumentFilePlaceholderUpload));
