import { computed, observable, decorate, action, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";

class DocumentStore {
  constructor() {
    this.initialiseEmpty();
  }

  setData = component => {
    this.componentId = component.componentId;
    this.type = component.type;

    if (component.componentData) {
      this.name = component.componentData.name;
      this.data = component.componentData.data;
      this.files = component.componentData.files;
      this.fileUrl=component.componentData.fileUrl
      this.lastModified = component.componentData.lastModified;
    } else {
      this.name = "";
      this.data = {
        key: this.keyAuto(),
        type: "EDITOR",
        export: "DOCX",
        content: ""
      };
      this.lastModified = "";
    }
  };

  setTemplateData = data => {
    this.data = data;
  }

  setContent = (value, type) => {
    switch (type) {
      case "key":
        this.data.key = value;
        return;
      case "type":
        this.data.type = value;
        this.data.content = "";
        this.fileUrl=[];
        return;
      case "content":
        this.data.content = value;
        return;
      case "export":
        this.data.export = value;
        return;
    }
  };

  setFiles = (files) => {
    this.files = files;
  }
  setFileUrl = (fileUrl) => {
    this.fileUrl = fileUrl;
  }
  keyAuto = () => {
    //Refresh the files whenever a new document placeholder key is generated
    this.files = [];
    this.fileUrl = [];
    return "document" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }

  initialiseEmpty = () => {
    this.componentId = "";
    this.name = "";
    this.files = [];
    this.lastModified = "";
    this.fileUrl=[];
    this.data = {
      key: this.keyAuto(),
      type: "EDITOR",
      export: "DOCX",
      content: ""
    };
    this.type = "document_editor";
  };

  get template() {
    return {
      componentId: this.componentId,
      lastModified: this.lastModified,
      name: this.name,
      files: this.files,
      data: this.data,
      type: this.type,
      fileUrl:this.fileUrl,
      export: this.export
    };
  }
}

decorate(DocumentStore, {
  componentId: observable,
  lastModified: observable,
  name: observable,
  files: observable,
  fileUrl:observable,
  data: observable,
  type: observable,
  export: observable,
  setFiles: action,
  setFileUrl:action,
  setData: action,
  setContent: action,
  initialiseEmpty: action,
  template: computed
});

const documentStore = new DocumentStore();
export default documentStore;
