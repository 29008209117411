import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";

import { OpenInNew, SourceItem, StyledSkeleton } from "./RenameStyledComponents";
import { send_request } from "../../../../utils/Request";

function ComponentSourceItem({ componentId, projectId }) {
    const [comp, setComp] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        setLoading(true);
        let url = `project-service/project/component/${componentId}`;
        const json = await send_request(url, null, null, "GET");
        if (json && json.data) {
            setComp(json.data);
            setLoading(false);
        }
    }, []);

    const handleOpenSource = (compId) => {
        let url = `/project/canvas/${projectId}?view=${compId}`;
        window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
        <Grid item xs={12}>
            {!loading ? (
                <SourceItem onClick={() => handleOpenSource(componentId)}>
                    <OpenInNew />
                    {comp.componentData && comp.componentData.name ? comp.componentData.name : `Untitled (${componentId})`}
                </SourceItem>
            ) : (
                <StyledSkeleton variant="rectangular" height="20px" width="350px" />
            )}
        </Grid>
    );
}

export default ComponentSourceItem;
