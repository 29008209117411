export const Teams = [
    "Executive Management",
    "Human Resources",
    "Finance",
    "Marketing",
    "Sales",
    "Customer Service",
    "Information Technology (IT)",
    "Research and Development (R&D)",
    "Operations",
    "Procurement",
    "Product Development",
    "Quality Assurance",
    "Legal",
    "Public Relations",
    "Logistics",
    "Supply Chain Management",
    "Business Development",
    "Compliance",
    "Data Analysis",
    "Engineering",
    "Environmental Health & Safety (EHS)",
    "Innovation",
    "Internal Audit",
    "Inventory Management",
    "IT Security",
    "Maintenance",
    "Manufacturing",
    "Project Management",
    "Risk Management",
    "Strategy",
    "Training and Development",
    "User Experience (UX) Design",
];

export const Industries = [
    "Agriculture, Forestry, Fishing and Hunting",
    "Mining, Quarrying, and Oil and Gas Extraction",
    "Utilities",
    "Construction",
    "Manufacturing",
    "Wholesale Trade",
    "Retail Trade",
    "Transportation and Warehousing",
    "Information",
    "Finance and Insurance",
    "Real Estate and Rental and Leasing",
    "Professional, Scientific, and Technical Services",
    "Management of Companies and Enterprises",
    "Administrative and Support and Waste Management and Remediation Services",
    "Educational Services",
    "Health Care and Social Assistance",
    "Arts, Entertainment, and Recreation",
    "Accommodation and Food Services",
    "Other Services (except Public Administration)",
    "Public Administration",
];
