import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { OpenInNew } from "@mui/icons-material";

const styles = () => ({
  instruction: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    padding: "16px",
    fontSize: "14px",
  },
  linkBox: {
    color: "#2196F3",
    cursor: "pointer",
  },
  linkText: {
    fontSize: "12px",
    fontWeight: "400",
  },
  icon: {
    height: "16px",
  },
});

const AIDataBasesCreated = ({ content, classes }) => {
  const openInNewTab = useCallback((dbId) => {
    window.open(`/databases/database_management/${dbId}`, "_blank");
  }, []);

  const showDataBaseLinks = (dbId, dbName) => (
    <Box
      container
      className={classes.linkBox}
      alignItems="start"
      onClick={() => openInNewTab(dbId)}
    >
      <Box className={classes.linkText}>{dbName}</Box>
      <Box>
        <OpenInNew className={classes.icon} />
      </Box>
    </Box>
  );

  return (
    <Grid
      container
      className={classes.instruction}
      rowGap={2}
      direction={"column"}
    >
      <Grid item>
        The following databases have been created and configured:
      </Grid>
      {content &&
        content.map((item, index) => (
          <Grid item key={index}>
            {showDataBaseLinks(item.databaseId, item.name)}
          </Grid>
        ))}
    </Grid>
  );
};

AIDataBasesCreated.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      databaseId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AIDataBasesCreated);
