import React from "react";
//mui
import { Grid } from "@mui/material";
//styles
import { withStyles } from "@mui/styles";
import "../../../util/chatMessageAnimations.css";
//components
import JSONRenderer from "../JSONRenderer";
import {EditCompareButton} from "../EditCompareButton";
import Timer from "../../Timer";

const styles = () => ({
  gpt: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    "& .MuiGrid-item:first-child": {
      paddingTop: "0!important"
    }
  },
});

const GPTResponse = ({
  classes,
  content,
  startTime,
  endTime,
  showTimer,
  isJson,
  showProposalText,
    compare
}) => {

  return (
    <Grid container spacing={2} className={`${classes.gpt} message-bubble`}>
      {isJson ? (
        <Grid item>
          <b style={{ marginBottom: "16px" }}>Full plan</b>
          You can also view the full plan document which sets out the details of
          this workflow below.
          <JSONRenderer inputString={content} />
          {showTimer && <Timer startTime={startTime} endTime={endTime} />}
        </Grid>
      ) : (
        <Grid item>
          {/* Added this to ensure new lines are preserved in content */}
          <div style={{whiteSpace: "pre-line"}}>{content}</div>
        </Grid>
      )}
      {compare &&
          <Grid item>
            <EditCompareButton
                classes={classes}
                compare={compare}
            />
          </Grid>
      }

    </Grid>
  );
};

export default withStyles(styles)(GPTResponse);
