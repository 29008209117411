import React from "react";

// Mui
import ErrorIcon from "@mui/icons-material/Error";
import { styled, Grid, Button } from "@mui/material";
import { Cancel } from "@mui/icons-material";

// Util
import { getNameFromKey, getErrorTextFromKey, ErrorItem, BoldTopText, isComponentAlreadyOpen } from "./ValidationUtil";

// Styled
const CustomGrid = styled(Grid)(({ margin, horizontal, vertical }) => ({
    margin: margin,
    ...(horizontal && vertical && { display: "flex", alignItems: vertical, justifyContent: horizontal }),
}));

function ErrorContainer({ errs, errKey, idx, handleFix, disabled, editComponent }) {
    let nameWithCount = `${errs.length} ${getNameFromKey(errKey)}`;
    
    return (
        <Grid container rowGap={1} marginBottom={2}>
            <Grid item xs={12}>
                <BoldTopText>{nameWithCount}</BoldTopText>
            </Grid>
            {errs.map((err, idx) => {
                return (
                    <ErrorItem item xs={12} bgColor="rgba(247, 229, 233, 1)">
                        <Grid container columnGap={3}>
                            <CustomGrid item horizontal="center" vertical="center">
                                {errKey === "componentError" ? <ErrorIcon className={"error-text"} style={{ height: "18px" }} /> : <Cancel className={"error-text"} style={{ height: "18px" }} />}
                            </CustomGrid>
                            <CustomGrid item xs horizontal="flex-start" vertical="center">
                                {getErrorTextFromKey(err, errKey)}
                            </CustomGrid>
                            <CustomGrid item horizontal="center" vertical="center">
                                <Button
                                    size="small"
                                    variant={"outlined"}
                                    color={"info"}
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    disabled={disabled || isComponentAlreadyOpen(editComponent, err)}
                                    onClick={() => {
                                        handleFix(err, errKey);
                                    }}
                                    id={`${errKey}-${idx}`}
                                >
                                    Open
                                </Button>
                            </CustomGrid>
                        </Grid>
                    </ErrorItem>
                );
            })}
        </Grid>
    );
}

export default ErrorContainer;
