import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles, createStyles } from "@mui/styles";
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import { observer, inject } from "mobx-react";
import JoditEditor from "jodit-pro-react";
import { configJodit } from "../../utils/configJodit";
import * as JoditCore from "jodit-pro/build/jodit";
import { send_request } from "../../utils/Request";
import { withStyles } from "@mui/styles";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import { Tooltip } from "@mui/material";
import { PLACEHOLDER_COLOR, PLACEHOLDER_ERROR_COLOR } from "../../utils/PlaceholderUtil";
import JoditSuperClass from "./JoditSuperClass";
import insertImage from "../../utils/Jodit/insertImage";
import { TOOLBAR_BUTTONS } from "../../utils/Jodit/JoditButtons";
import { preventDefaultPaste } from "../../utils/Jodit/preventDefaultPaste";

const componentStyles = makeStyles(() => {
  createStyles({
    root: {
      flexGrow: 1
    }
  });
});

const useStyles = () => ({
  gridJodit: {},
  gridJoditTier: {}
});

class TextEditor extends JoditSuperClass {
  joditRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      content: this.props.html ? this.props.html : "",
      oldContent: this.props.html ? this.props.html : ""
    };
    this.props.editorFocus();
  }

  onFocus = () => {
    this.props.editorFocus();
  };

  componentDidMount = async () => {
    this.updateContentFromHtml();
  };

  updateContentFromHtml() {
    let content = this.props.html;
  
    if (content) {
      if (content.includes("table")) {
        content = content
            .replace(/(<table style=")/g, val => {
              return `<table border="1" style="border-collapse:collapse; `;
            })
            .replace(/(border-color:)/g, "border: 1px solid");
      }
      content = content
          .replace(/(?:\r\n|\r|\n)/g, "<br>")
          .replace(/(<td style="width: [0-9.]+%;">)/g, val => {
            let width = val.match(/([0-9.]+)/g)[0];
            return `<td style="width: ${width}%; padding: 4px;">`;
          });
    } else {
      content = "";
    }
  
    if (this.state.content !== content) {
      this.setState({
        content: content,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.html && this.state.content !== this.props.html) {
      if (this.state.content !== this.props.html) {
        this.updateContentFromHtml();
      }
    }
  
    const { placeholder } = this.props;
    if (placeholder && placeholder.length !== prevProps.placeholder.length) {
      this.insertPlaceholder(placeholder[placeholder.length - 1]);
    }
  }


  updateContent = value => {
    value = value.replace(/(?:\n)/g, "");
    value = value
      .replace(/(<table style=")/g, val => {
        return `<table border="1" style="border-collapse:collapse; `;
      })
      .replace(/(border-color:)/g, "border: 1px solid")
      .replace(/(<td style="width: [0-9.]+%;">)/g, val => {
        let width = val.match(/([0-9.]+)/g)[0];
        return `<td style="width: ${width}%; padding: 4px;">`;
      });
    this.props.onChange(value);
  };


  insertPlaceholder = placeholder => {
    if (placeholder) {
      const text = "${" + placeholder.text + "}";
      this.jodit.selection.insertHTML(text);
    }
  };


  config = {
    readonly: false,
    defaultActionOnPaste: "insert_only_text",
    placeholder: "",
    disablePlugins: [
      "keyboard",
      "google-search",
      "fullsize",
      "about",
      "classSpan",
      "file",
      "video",
      "image",
      "google-maps",
      "export-docs"
    ],
    // Set for differing screen sizes
    buttonsXS: TOOLBAR_BUTTONS,
    buttonsSM: TOOLBAR_BUTTONS,
    buttonsMD: TOOLBAR_BUTTONS,
    height: "auto",
    events: {
      afterInit: instance => {
        this.jodit = instance;
        this.joditRef.current = instance;
      },
      afterPaste: insertImage(this.joditRef, ProjectStore.project_id),
      beforePaste: (event) =>{preventDefaultPaste(event)}    
    },
    uploader: false,
    autocomplete: {
      sources: [
        {
          feed: (query) =>{
            return this.sourceFeed(query,'$');
          },

          itemRenderer: (item) => {
            return this.itemRenderer(item,'$');
          },
          insertValueRenderer: ({ key, type, length }) => {
            return this.insertValueRenderer(type, length, key,'$');
          },
        },
      ],
    },
    highlightSignature: {
      schema: {
        "\\$\\{([^}]+)\\}": (jodit, matched) => {
          return this.getPlaceholderStyleForExistingPlaceholders(matched, jodit);
        }
      }
    },

    ...configJodit,
    ...this.props.contentStyles
  };


  render() {
    const { content } = this.state;
    const { classes, disableTooltip = false } = this.props;
    if (!this.state.loading) {
      return (
        <Tooltip
          placement="bottom"
          title={
            disableTooltip !== true &&
            "Type “$” to view and insert placeholders"
          }
          disableHoverListener={disableTooltip}
          disableFocusListener={disableTooltip}
          disableTouchListener={disableTooltip}
        >
          <Grid
            container
            onClick={this.onFocus}
            className={componentStyles.root}
            spacing={3}
          >
            <Grid
              item
              className={
                ProjectStore.state.tier === "FREE"
                  ? classes.gridJodit
                  : classes.gridJoditTier
              }
              xs={12}
            >
              <JoditEditor
                ref={this.setRef}
                value={content}
                config={this.config}
                tabIndex={1} // tabIndex of textarea
                onBlur={this.updateContent}
              />
            </Grid>
          </Grid>
        </Tooltip>
      );
    } else {
      return (
        <div className="my-nice-tab-container">
          <div className="loading-state"></div>
        </div>
      );
    }
  }
}
export default observer(withStyles(useStyles)(TextEditor));
