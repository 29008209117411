import React from "react";
import { Chip, Tooltip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import "./initialMessages.css"; // Update this import

const ContextChips = ({ chips }) => {
  return (
    <div className="chipContainer">
      {chips.map((chip, index) => (
        <Tooltip key={index} title={chip.tooltip} arrow enterDelay={500}>
          <Chip
            icon={
              <span className="chipIconWrapper">
                <CheckIcon className="greenTick" />
                {chip.icon}
              </span>
            }
            label={chip.label}
            variant="outlined"
            className="contextChip"
            onClick={chip.onClick}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default ContextChips;
