export const GET_COMPONENTS = projectId => {
  return `
    { projectById(id: "${projectId}") {
        components {
          componentId
          type
          componentData {
            name
          }
        }
      }
    }
    `;
};
