import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, TextField } from "@mui/material";
import {
  SelectFunction,
  SelectConditional,
  SelectLogic,
  SelectExtract,
  SelectArrange
} from "./Component";
import ChipInput from "../../ChipInput/ChipInput";
import TransformStore from "../TransformStore";

import { toJS } from "mobx";
import Icon from "@mui/material/Icon";
import checkKey from "../../../utils/CheckKey";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";
import FindAndReplace from "./ListOptions/FindAndReplace";
import CountASpecificValue from "./ListOptions/CountASpecificValue";
import CountLength from "./ListOptions/CountLength";
import RemoveEmptyValues from "./ListOptions/RemoveEmptyValues";
import ConvertListToText from "./ListOptions/ConvertListToText";
import Join from "./ListOptions/Join";
import RemoveDuplicateValues from "./ListOptions/RemoveDuplicateValues";
import FilterBasedOnAConditional from "./ListOptions/FilterBasedOnAConditional";
import CountIfConditionalIsMet from "./ListOptions/CountIfConditionalIsMet";
import ExtractByOrder from "./ListOptions/ExtractByOrder";
import Rearrange from "./ListOptions/Rearrange";
import SplitListIntoPlaceholders from "./ListOptions/SplitListIntoPlaceholders";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";

class ItemTransform extends Component {
  state = {
    checkKey: false,
    errorMessage: "",
    list: ""
  };

  handleKeyChanges = (e, index) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    TransformStore.setComponent(placeholderValue, index, "key");
    const { data } = toJS(TransformStore);
    const value = checkKey(placeholderValue, index, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
    this.props.handleKeyChanges(value[0]);
  };

  handleRequestBefore = (chip) => {
    if (chip) {
      if (this.isNumber(chip)) {
        this.setState({ error: false });
      } else {
        this.setState({ error: true });
      }
    }
    return true;
  };

  isNumber = (n) => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  };

  render() {
    const { component, index, availablePlaceholders, isExpanded } = this.props;
    const {
      key,
      func,
      list,
      replace,
      withThis,
      newList,
      present,
      followList,
      listBelow,
      conditions,
      duplicateList,
      splitList,
      conditionIsMet,
      extraction,
      rearrange,
      exportPlaceholders,
      numPlaceholder
    } = component;


    // Get the relevant list option
    const getListOption = (func) => {
      const params = {
        onPlaceholderSelected: this.props.onPlaceholderSelected,
        availablePlaceholders: availablePlaceholders,
        index: index,
        isExpanded: isExpanded
      };

      switch (func) {
        case "find_and_replace":
          return <FindAndReplace 
              {...params}
              replace={replace}
              withThis={withThis} />;

        case "count_a_specific_value":
          return <CountASpecificValue 
              {...params}
              present={present} />;

        case "count_length":
        return <CountLength 
              {...params}
              followList={followList} />;

        case "remove_empty_values":
          return <RemoveEmptyValues 
              {...params}
              listBelow={listBelow} />;

        case "convert_list_to_text":
          return <ConvertListToText 
              {...params}
              listBelow={listBelow} />;

        case "join":
          return <Join 
              {...params}
              newList={newList} />;

        case "remove_duplicate_values":
          return <RemoveDuplicateValues 
              {...params}
              duplicateList={duplicateList} />;

        case "filter_based_on_a_conditional":
          return <FilterBasedOnAConditional
              conditions={conditions}
              {...params} />;

        case "count_if_conditional_is_met":
          return <CountIfConditionalIsMet 
              conditionIsMet={conditionIsMet}
              {...params} />;

        case "extract_by_order":
          return <ExtractByOrder
              extraction={extraction}
              index={index} />;

        case "rearrange":
          return <Rearrange 
              rearrange={rearrange}
              index={index} />;

        case "split_list_into_placeholders":
          return <SplitListIntoPlaceholders 
              {...params}
              splitList={splitList}
              numPlaceholder={numPlaceholder}
              exportPlaceholders={exportPlaceholders}
              checkKey={checkKey} />;
        default:
          break;
      }
    }


    return (
      <div>
        <div className="item-wrapper">
          <Grid container spacing={1}>
            {func !== "split_list_into_placeholders" && (
              <>
                <Grid item xs={11} className="key-item-label">
                  Placeholder
                  <Grid container marginLeft={2}>
                    <Grid item xs={12}>
                      <W86PlaceholderTextField
                        value={key}
                        error={this.state.checkKey ? true : false}
                        helperText={this.state.checkKey ? this.state.errorMessage : null}
                        placeholderText="Enter placeholder for list transform"
                        handleChange={(event) => this.handleKeyChanges(event, index)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} className="item-icon-delete">
                  <DeleteIcon
                    onClick={() => TransformStore.delComponent(index)}
                  />
                </Grid>
              </>
            )}

            <Grid
              container
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={10}>
                <SelectFunction
                  value={func}
                  className="item-select"
                  onChange={(value) => {
                    if (value === "split_list_into_placeholders") {
                      // We set the key to a random placeholder if split list is selected
                      let newPH = "list" + (Math.floor(Math.random() * 90000000) + 10000).toString();
                      TransformStore.setComponent(newPH, index, "key");
                    }
            
                    TransformStore.setComponent(value, index, "func");
                  }}
                />
              </Grid>
              {func == "split_list_into_placeholders" && (
                <Grid container item xs={1} className="item-icon-delete">
                  <DeleteIcon
                    onClick={() => TransformStore.delComponent(index)}
                  />
                </Grid>
              )}
            </Grid>
            {(func == "find_and_replace" ||
              func == "count_a_specific_value" ||
              func == "filter_based_on_a_conditional" ||
              func == "count_if_conditional_is_met" ||
              func == "extract_by_order" ||
              func == "rearrange") && (
              <React.Fragment>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={12}
                  xl={12}
                  className="item-label"
                >
                  In this list
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="input-list-transform"
                >
                  <ChipInput
                    inputValue={list}
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    placeholders={availablePlaceholders}
                    placeholder="Insert a list"
                    fullWidth
                    onBeforeAdd={(chip) => {
                      TransformStore.setComponent(chip, index, "list");
                      return true;
                    }}
                    onDelete={indexChip =>
                      TransformStore.deleteChip(indexChip, index, "list")
                    }
                    onFocus={() => TransformStore.setFocus(index, "list")}
                  />
                </Grid>
              </React.Fragment>
            )}
            {getListOption(func)}
          </Grid>
        </div>
      </div>
    );
  }
}
export default ItemTransform;
