const EmailTriggerStyles = (theme) => ({
  paper: {
    padding: "24px",
  },
  indent: {
    marginLeft: "16px",
  },
  address: {
    minWidth: "500px",
  },
 
});

export default EmailTriggerStyles;
