export const setMonth = (value) => {
    switch (value) {
        case 1:
            return "01";
        case 2:
            return "02";
        case 3:
            return "03";
        case 4:
            return "04";
        case 5:
            return "05";
        case 6:
            return "06";
        case 7:
            return "07";
        case 8:
            return "08";
        case 9:
            return "09";
        case 10:
            return "10";
        case 11:
            return "11";
        case 12:
            return "12";
    }
};

export const getTime = (time) => {
    let DateTime = new Date(time);
    const hour = DateTime.getHours() >= 10 ? DateTime.getHours() : "0" + DateTime.getHours();
    const minutes = DateTime.getMinutes() >= 10 ? DateTime.getMinutes() : "0" + DateTime.getMinutes();
    const fullTimeNow = hour + ":" + minutes;
    return fullTimeNow;
};

export const convertTimezoneOffset = (mins, twentyFour = false, inverse) => {
    let isMinus = false;
    if (mins < 0) {
        isMinus = true;
        mins = Math.abs(mins);
    }

    let h = Math.floor(mins / 60);
    let m = mins % 60;
    m = m < 10 ? "0" + m : m;

    let val = "";

    if (twentyFour) {
        h = h < 10 ? "0" + h : h;
        val = `${h}:${m}`;
    } else {
        let a = "am";
        if (h >= 12) a = "pm";
        if (h > 12) h = h - 12;
        val = `${h}:${m} ${a}`;
    }

    if (inverse) {
        if (!isMinus) return `-${val}`;
        else return `+${val}`;
    } else {
        if (isMinus) return `-${val}`;
        else return `+${val}`;
    }
};
