import { computed, observable, decorate, action, toJS } from "mobx";

class ConditionalWorkflowStore {

  constructor() {
    this.initialiseEmpty();
  }

  setConditionalWorkflowTemplate = (conditionalWorkflowTemplate) => {
    this.type = conditionalWorkflowTemplate.type
    this.name = conditionalWorkflowTemplate.name;
    this.data = conditionalWorkflowTemplate.data;
    this.lastModified = conditionalWorkflowTemplate.lastModified;
  }

  setConditionalWorkflowName = (ConditionalWorkflowName) => {
    this.name = ConditionalWorkflowName;
  }

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  }
  setData = (data) => {
    this.data = data;
  }
  setCondition = (content, indexLogic, indexStatement, indexCondition, type) => {
    switch (type) {
      case 'logic':
        this.data[indexLogic].statement.content
          .condition[indexStatement]
          .operators[indexCondition]
          .logic = content;
        return;
      case 'key':
        this.data[indexLogic].statement.content
          .condition[indexStatement]
          .operators[indexCondition]
          .key = content ? content.trim() : "";
        return;
      case 'function':
        this.data[indexLogic].statement.content
          .condition[indexStatement]
          .operators[indexCondition]
          .function = content;
        return;
      case 'value':
        this.data[indexLogic].statement.content
          .condition[indexStatement]
          .operators[indexCondition]
          .value = content;
        return;
    }
  }

  setThenContent = (content, indexLogic, indexStatement) => {
    this.data[indexLogic].statement.content
      .condition[indexStatement]
      .then.statement.content = content;
  }
  addStatement = (indexLogic) => {
    const obj = {
      operators: [
        {
          logic: 'OR',
          key: '',
          function: '',
          value: ''
        }
      ],
      then: {
        statement: {
          type: "VALUE",
          content: ''
        }
      }
    }
    const { condition } = this.data[indexLogic].statement.content
    this.data[indexLogic].statement.content
      .condition
      .splice(condition.length - 1, 0, obj)
  }

  addCondition = (indexLogic, indexStatement) => {
    const obj = {
      logic: 'OR',
      key: '',
      function: 'EQUAL',
      value: ''
    }

    let data = toJS(this.data[indexLogic]);
    data.statement.content.condition[indexStatement].operators.push(obj);

    this.data[indexLogic] = data; 
  }

  setLogic = (content, index, type) => {
    switch (type) {
      case 'key':
        this.data[index].key = content;
        return;
      case 'outputType':
        this.data[index].outputType = content;
        return;
    }
  }

  addLogic = () => {
    const obj = this.logicData();
    this.data.push(obj);
  }

  removeLogic = (index) => {
    this.data.splice(index, 1)
  }
  copyThisLogic = (index) => {
    let copyLogic = this.data[index];
    copyLogic = { ...copyLogic, key: this.keyAuto() };
    this.data.push(copyLogic);
  }
  removeCondition = (indexLogic, indexStatement, indexCondition) => {
    this.data[indexLogic].statement.content
      .condition[indexStatement]
      .operators
      .splice(indexCondition, 1)
  }

  removeStatement = (indexLogic, indexStatement) => {
    this.data[indexLogic].statement.content
      .condition
      .splice(indexStatement, 1)
  }
  keyAuto = () => {
    return "conditional" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }
  logicData = () => {
    return {
      key: this.keyAuto(),
      outputType: "TEXT",
      statement: {
        type: "IF",
        content: {
          condition: [
            {
              operators: [
                {
                  logic: "undefined",
                  key: "",
                  function: "EQUAL",
                  value: ""
                }
              ],
              then: {
                statement: {
                  type: "VALUE",
                  content: "CONTINUE"
                }
              }
            },
            {
              then: {
                statement: {
                  type: "VALUE",
                  content: "STOP"
                }
              }
            }
          ]
        }
      }
    }
  }

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }

  setPositions = (positions) => {
    this.placeholders = [];
    this.positions = positions;
  }


  initialiseEmpty = () => {
    const obj = this.logicData()
    this.name = "";
    this.type = "conditional_workflow";
    this.lastModified = "";
    this.data = [obj];
    this.placeholders = [];
    this.positions = {
      indexLogic: null,
      indexStatement: null,
      indexCondition: null,
      type: null
    };
  }

  get template() {
    return {
      name: this.name,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: toJS(this.placeholders),
      positions: toJS(this.positions)
    }
  }
}


decorate(ConditionalWorkflowStore, {
  name: observable,
  data: observable.shallow,
  type: observable,
  error: observable,
  placeholders: observable,
  positions: observable,
  setPositions: action,
  setPlaceholders: action,
  // setConditionalLogicTemplate: action,
  // setConditionalLogicName: action,
  // setCondition: action,
  // setLogic: action,
  // setThenContent: action,
  // addStatement: action,
  // addLogic: action,
  removeLogic: action,
  removeCondition: action,
  removeStatement: action,
  initialiseEmpty: action,
  template: computed,
  setData:action
})

const conditionalWorkflowStore = new ConditionalWorkflowStore();
export default conditionalWorkflowStore;