import React, { useState, useEffect } from "react";

// MUI
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Icons
import ErrorIcon from "@mui/icons-material/Error";
import RenderColumnRS from "./RenderColumnRS";

function Inputs(props) {
    const { classes, availablePlaceholders } = props;

    const [numberOfRows, setNumberOfRows] = useState(props.numRows);
    const [error, setError] = useState("");

    useEffect(() => {
        setNumberOfRows(props.numRows);
    }, [props.numRows]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.topTitleContainer}>
                    <span className={classes.tableSettingsTitle}>Repeatable Sections Settings</span>
                </Grid>

                <Grid item xs={12} className={classes.gridRow}>
                    <div className={classes.topText}>Export in the following format</div>
                    <Select onChange={(e) => props.setExportFormat(e.target.value)} className={classes.exportTypeSelect} disableUnderline variant={"standard"} value={props.exportFormat} size="small">
                        <MenuItem value={"JSON"}>Entire table as JSON</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={12} className={classes.gridRow}>
                    <Grid item xs={12}>
                        <span className={classes.topText}>Max sections</span>
                    </Grid>
                    <TextField
                        value={numberOfRows}
                        size="small"
                        className={classes.numRowsInput}
                        error={error != ""}
                        helperText={error != "" && error}
                        onChange={(e) => {
                            setError("");
                            setNumberOfRows(e.target.value);
                        }}
                        onBlur={() => {
                            if (isNaN(numberOfRows)) {
                                setError(`Number of sections must be an integer`);
                                return;
                            } else if (numberOfRows > 100) {
                                setError(`Number of sections must be less than 100`);
                                return;
                            } else if (numberOfRows <= 0) {
                                setError(`You must have at least one section`);
                                return;
                            }
                            props.handleChangeNumRows(numberOfRows);
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container rowGap="12px">
                <Grid item xs={12}>
                    <span className={classes.topText}>
                        <b>Section template</b>
                    </span>
                </Grid>
                {props.columns && (
                    <Grid item xs={12} style={{ border: "1px solid #868686", borderRadius: "6px", padding: "16px" }}>
                        <Grid container rowGap="40px">
                            {props.columns.map((col, idx) => {
                                return <RenderColumnRS key={col.id} col={col} idx={idx} {...props} isRS={true} availablePlaceholders={availablePlaceholders} />;
                            })}
                        </Grid>
                    </Grid>
                )}
            </Grid>

            {props.tableErrors != "" && (
                <Grid item xs={12} className={classes.errorSection}>
                    <ErrorIcon className="iconDiscard" />
                    {props.tableErrors}
                </Grid>
            )}
        </>
    );
}

export default Inputs;
