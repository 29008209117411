import { React } from "react";
import { CustomDialog } from "../../Component/Dialog";
import { Grid, TextField, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FileCopy, LaunchOutlined } from "@mui/icons-material";
import config from "../../../config";
import PropTypes from "prop-types";

export const FormLinkDialog = (props) => {
  const { openFormLink, setOpenFormLink, formId } = props;

  return (
    <CustomDialog
      isOpen={openFormLink}
      handleClose={() => {
        setOpenFormLink(false);
      }}
      title={"Link to this form"}
      contents={
        <>
          <p>
            This is a link to the production version of this form. Please note
            that the workflow will need to be published before any changes you
            have made will be pushed to the production version of this form.
          </p>
          <Grid xs={12} container item direction={"row"}>
            <TextField
              inputProps={{ className: "turn_text_black" }}
              fullWidth={true}
              value={`form.workflow86.com/form/${formId}`}
              className="key_padding "
              variant="outlined"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard
                      text={`${config.FORM.FORM_FRONT_END}/form/${formId}`}
                    >
                      <FileCopy className="custom-icon-btn" />
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      }
      buttons={
        <>
          <Button
            onClick={() => {
              setOpenFormLink(false);
              window.open(
                `${config.FORM.FORM_FRONT_END}/form/${formId}`,
                "_blank"
              );
            }}
            variant={"outlined"}
            color={"info"}
            endIcon={<LaunchOutlined />}
          >
            Open
          </Button>
          <Button
            onClick={() => {
              setOpenFormLink(false);
            }}
            variant={"contained"}
            color={"primary"}
          >
            Close
          </Button>
        </>
      }
    />
  );
};
FormLinkDialog.propTypes = {
  openFormLink: PropTypes.bool.isRequired,
  setOpenFormLink: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
};
