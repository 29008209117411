import {action, computed, decorate, observable, toJS} from "mobx";

class SessionVariableStore {
    constructor() {
        this.initialiseEmpty();
    }
    setSessionVariables = component => {
        this.componentId = component.componentId;
        this.type = component.type;
        if (component.componentData) {
            this.data = component.componentData.data;
            this.lastModified = component.componentData.lastModified;
            this.name = component.componentData.name;
        } else {
            const obj = this.sessionVariablesData();
            this.data.sessionVariableData = [obj];
            this.lastModified = "";
            this.name = "";
        }
    };

    setTemplateData = data => {
        this.data = data;
    }

    initialiseEmpty = () => {
        this.name = "";
        this.componentId = "";
        this.type = "session_variables";
        this.lastModified = "";
        this.placeholders = [];
        this.data = this.initData();
        this.position = null;
        this.focus = null
    };
    initData = () => {
        return {
            sessionVariableData: []

        };
    }
    setSessionVariableData = (sessionVariableData) => {
        this.data.sessionVariableData = sessionVariableData
    }
    get template() {
        return {
            componentId: this.componentId,
            name: this.name,
            position: this.position,
            data: toJS(this.data),
            type: this.type,
            lastModified: this.lastModified,
            placeholders: this.placeholders,
        };
    }

    sessionVariablesData() {
        return {
            key: this.keyAuto(),
            type: "text",
            value: "",
        };
    }
    keyAuto = () => {
        return (
            "session_data" + (Math.floor(Math.random() * 90000000) + 10000).toString()
        );
    };
    setFocus = index => {
        this.placeholders = [];
        this.position = index;
    };

}

decorate(SessionVariableStore, {
    componentId: observable,
    name: observable,
    data: observable,
    type: observable,
    lastModified: observable,
    setLastModified: action,
    initialiseEmpty: action,
    position: observable,
    setSessionVariableData:action,
    template: computed,
    placeholders: observable,

});
const sessionVariableStore = new SessionVariableStore();
export default sessionVariableStore;
