import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PromptTextfield from "./PromptTextfield";
import ChatButtons from "./ChatButtons";
import useDisplayedMessages from "./Hooks/useDisplayedMessages";
import MessageType from "./MessageType";
import ChatPropTypes from "./PropTypes/ChatPropTypes";
import AIQuotaRemaining from "../../util/AIQuotaRemaining";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
  color: "black",
  borderColor: "grey.400",
  borderRadius: "4px",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "#E10000",
    color: "#E10000",
  },
}));
const Chat = ({
  messages,
  classes,
  startTime,
  completedTime,
  regenerate,
  insert,
  loading,
  restart,
  buildAllComponents,
  buildComponentsIndividually,
  conclude,
  rebuild,
  cancel,
  userPrompt,
  generate,
  editAndRegenerate,
  updateUserPrompt,
  canInsert,
  showInstruction,
  hideButtons,
  showComponentSidebarButtons,
  buttonText,
  charLimit,
  dynamicMessageFunctions,
  showBuildButtons,
  showConclude,
  showUserPrompt,
  generateWithAdditional,
  additionalMessagePrompt,
  setAdditionalMessagePrompt,
  showAdditionalPrompt,
  prebuildProposal,
  handleBuildWithProposal,
  isPrebuild,
  regeneratePrebuild,
  chatHistoryLoading,
  isLoadedFromHistory,
  isInit,
  isCompBuilder,
  paneSkeleton,
  startCompTestRun,
  prebuildQuestionAnswers,
  restartComponentBuild,
  setWorkflowName,
  buildComponentInfo,
  editComponentInfo,
  sessionId,
  streamingStatus,
  setViewportOnGenerate,
  viewportOnGenerate,
  setNewDiagram,
  setStreamingStatus,
  setError,
  setPrebuildQuestionAnswers,
  openSessionHistory,
  setSidebarState,
  sidebarState,
  convertIntoWorkflow,
  syncCompleted,
  messageSent,
  buildInserted,
  applyingEditStages,
  setApplyingEditStages,
  undoEditChanges,
  editChanges,
  draftVersion,
  projectId,
  retryApplyingEdit,
  hideCancel,
  compForBuilding,
  compForEditing,
  cancelled,
  skipQuestions,
  focusObject,
  setUserPrompt,
  isLoading,
  files,
  setFiles,
  isQuestionAnswerMessage,
  nodes,
  generateWithQuesAns,
    compare,
    setCompare
}) => {
  const [displayedMessages] = useDisplayedMessages(
    messages,
    isLoadedFromHistory
  );
  const [dismiss, setDismiss] = useState(false);
  const chatContainerRef = useRef(null);

  const smoothScrollToBottom = (dismiss, duration = 100) => {
    const element = chatContainerRef.current;
    if (!element || dismiss) return;

    let start = element.scrollTop;
    let end = element.scrollHeight;
    let change = end - start;
    let startTime = performance.now();

    function animateScroll(timestamp) {
      var time = timestamp - startTime;
      var progress = time / duration;
      progress = Math.min(progress, 1);
      element.scrollTop = start + change * progress;
      if (time < duration) {
        requestAnimationFrame(animateScroll);
      }
    }

    requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const scrollToBottom = () => smoothScrollToBottom(dismiss, 100);

      const observer = new MutationObserver(scrollToBottom);
      observer.observe(chatContainer, {
        childList: true,
        subtree: true,
      });

      scrollToBottom();

      return () => observer.disconnect();
    }
  }, [dismiss]);

  const hasLatestErrorMessage = () => {
    const latestMessage = messages[messages.length - 1];
    return latestMessage && latestMessage.role === "error";
  };

  const hasLatestSuccessMessage = () => {
    const latestMessage = messages[messages.length - 1];
    return latestMessage && latestMessage.role === "success";
  };

  const showCancelButton = loading;

  const showTextfield =
    !loading &&
    sidebarState &&
    sidebarState !== "INITIAL_COMP_BUILD" &&
    (!showInstruction || showUserPrompt || prebuildQuestionAnswers) &&
    !hasLatestErrorMessage();

  const showButtonGroup =
    !loading && (showInstruction || hasLatestErrorMessage());

  const hideInsert =
    !canInsert && !hasLatestErrorMessage() && !hasLatestSuccessMessage();

  const isAdditionalPrompt =
    showAdditionalPrompt &&
    ((!hideButtons && showButtonGroup) ||
      (!loading && showComponentSidebarButtons && !cancelled));

  const showUserInputField = !showCancelButton && !(sidebarState === "INITIAL");

  const fileInputRef = useRef(null);

  const handleAddImage = () => {
    // this ensure the image upload box opens up on image icon click
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (files.length > 0) {
        URL.revokeObjectURL(files[0]);
      }
      setFiles([file]);
      event.target.value = null;
    }
  };

  return (
    <Grid container direction="column" height={"100%"}>
      <Grid
        item
        xs
        className={classes.chatDiv}
        ref={chatContainerRef}
        style={{
          overflowY: "auto",
          maxHeight: "100%",
          scrollBehavior: "smooth",
        }}
      >
        <Grid container gap={2}>
          {displayedMessages.map((message, index) => {
            const isLatestProposalMessage = (displayedMessages.length - 2) === index;
            return (
                <Grid
                    item
                    xs={12}
                    key={message.key}
                    display="flex"
                    justifyContent={
                      message.role === "user" ? "flex-end" : "flex-start"
                    }
                >
                  <MessageType
                      index={index}
                      projectId={projectId}
                      draftVersion={draftVersion}
                      editChanges={editChanges}
                      message={message}
                      startTime={startTime}
                      completedTime={completedTime}
                      dynamicMessageFunctions={dynamicMessageFunctions}
                      restartComponentBuild={restartComponentBuild}
                      setWorkflowName={setWorkflowName}
                      messages={messages}
                      buildComponentInfo={buildComponentInfo}
                      editComponentInfo={editComponentInfo}
                      sessionId={sessionId}
                      streamingStatus={streamingStatus}
                      setViewportOnGenerate={setViewportOnGenerate}
                      viewportOnGenerate={viewportOnGenerate}
                      setNewDiagram={setNewDiagram}
                      setStreamingStatus={setStreamingStatus}
                      setError={setError}
                      prebuildQuestionAnswers={prebuildQuestionAnswers}
                      setPrebuildQuestionAnswers={setPrebuildQuestionAnswers}
                      openSessionHistory={openSessionHistory}
                      setSidebarState={setSidebarState}
                      sidebarState={sidebarState}
                      dismissContainerRef={setDismiss}
                      syncCompleted={syncCompleted}
                      messageSent={messageSent}
                      applyingEditStages={applyingEditStages}
                      setApplyingEditStages={setApplyingEditStages}
                      undoEditChanges={undoEditChanges}
                      retryApplyingEdit={retryApplyingEdit}
                      focusObject={focusObject}
                      setUserPrompt={setUserPrompt}
                      nodes={nodes}
                      generateWithQuesAns={generateWithQuesAns}
                      compare={isLatestProposalMessage ? compare : undefined}
                      classes={classes}
                  />
                </Grid>
            );
          })}
        </Grid>
      </Grid>
      {!chatHistoryLoading && (
        <Grid item className={classes.chatButtonsDiv}>
          <Grid container>
            {!hideButtons && showCancelButton && !hideCancel && (
              <Grid item xs={12} container justifyContent="center">
                <StyledButton
                  onClick={() => {
                    cancel();
                  }}
                  startIcon={<CloseIcon className={classes.closeIcon} />}
                >
                  Cancel request
                </StyledButton>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              container
              direction={"row"}
              display="flex"
              justifyContent="flex-end"
            >
              {!showCancelButton && (
                <ChatButtons
                  loading={loading}
                  classes={classes}
                  buttonText={buttonText}
                  hideInsert={hideInsert}
                  hideButtons={hideButtons}
                  showButtonGroup={showButtonGroup}
                  showComponentSidebarButtons={showComponentSidebarButtons}
                  showBuildButtons={showBuildButtons}
                  showConclude={showConclude}
                  insert={insert}
                  buildAllComponents={buildAllComponents}
                  buildComponentsIndividually={buildComponentsIndividually}
                  conclude={conclude}
                  rebuild={rebuild}
                  restart={restart}
                  editAndRegenerate={editAndRegenerate}
                  regenerate={regenerate}
                  prebuildProposal={prebuildProposal}
                  handleBuildWithProposal={handleBuildWithProposal}
                  isPrebuild={isPrebuild}
                  showTextfield={showTextfield}
                  messages={messages}
                  isCompBuilder={isCompBuilder}
                  regeneratePrebuild={regeneratePrebuild}
                  startCompTestRun={startCompTestRun}
                  sidebarState={sidebarState}
                  convertIntoWorkflow={convertIntoWorkflow}
                  buildInserted={buildInserted}
                  compForBuilding={compForBuilding}
                  compForEditing={compForEditing}
                  cancelled={cancelled}
                  skipQuestions={skipQuestions}
                  isQuestionAnswerMessage={isQuestionAnswerMessage}
                  compare={compare}
                  setCompare={setCompare}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {showUserInputField && (
                <PromptTextfield
                  loading={loading}
                  charLimit={charLimit}
                  showTextfield={showTextfield}
                  isAdditionalPrompt={isAdditionalPrompt}
                  additionalMessagePrompt={additionalMessagePrompt}
                  userPrompt={userPrompt}
                  setAdditionalMessagePrompt={setAdditionalMessagePrompt}
                  updateUserPrompt={updateUserPrompt}
                  generateWithAdditional={generateWithAdditional}
                  generate={generate}
                  isInit={isInit}
                  isCompBuilder={isCompBuilder}
                  paneSkeleton={paneSkeleton}
                  isPrebuild={isPrebuild}
                  prebuildQuestionAnswers={prebuildQuestionAnswers}
                  sidebarState={sidebarState}
                  showConclude={showConclude}
                  files={files}
                  setFiles={setFiles}
                  isLoading={isLoading}
                  isQuestionAnswerMessage={isQuestionAnswerMessage}
                />
              )}
            </Grid>
            {showUserInputField && !loading && showTextfield && (
              <Grid
                item
                container
                xs={12}
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                {/* Commented out AddImageButton
                <AddImageButton onClick={handleAddImage}>
                  <AddPhotoAlternateOutlinedIcon/>
                  <input
                    //  multiple
                    accept="image/*"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </AddImageButton>
                */}
                <AIQuotaRemaining />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

Chat.propTypes = ChatPropTypes;

export default Chat;
