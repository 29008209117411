import { Button, Grid, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ReplayIcon from "@mui/icons-material/Replay";
import ErrorIcon from "@mui/icons-material/Error";

export const TopText = styled("span")({
    color: "rgba(134, 134, 134, 1)",
    fontSize: "15px",
});

export const BoldText = styled("span")({
    fontWeight: "bold",
});

export const VersionText = styled("span")({
    color: "rgba(117, 117, 117, 1)",
    fontSize: "15px",
});

export const SavedText = styled("span")({
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "15px",
});

export const NoneText = styled("span")({
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
});

export const RowItem = styled(Grid)({
    border: "1px solid rgba(218, 218, 218, 1)",
    padding: "18px 24px",
    borderRadius: "8px",
    backgroundColor: "#FFF",
});

export const StyledButton = styled(Button)(({ textColor, bgColor, hoverTextColor, hoverBgColor }) => ({
    color: textColor,
    backgroundColor: bgColor,
    padding: "6px 28px",
    "&:hover": {
        color: hoverTextColor,
        backgroundColor: hoverBgColor,
    },
}));

export const CompareIcon = styled(CompareArrowsIcon)({
    height: "16px",
    width: "auto",
});

export const RevertIcon = styled(ReplayIcon)({
    height: "16px",
    width: "auto",
});

export const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "4px",
    background: "rgba(224, 224, 224, 1)",
    width: "100%",
    height: height,
    margin: margin,
}));

export const CommentBubble = styled(Grid)({
    backgroundColor: "#F3F3F3",
    borderRadius: "6px",
    padding: "16px 8px",
    fontSize: "14px",
    color: "rgba(117, 117, 117, 1)",
});

export const CustomGrid = styled(Grid)(({ margin, horizontal, vertical }) => ({
    margin: margin,
    ...(horizontal && vertical && { display: "flex", alignItems: vertical, justifyContent: horizontal }),
}));

export const MultilineText = styled("span")(() => ({
    lineHeight: "initial",
    whiteSpace: "pre-wrap",
}));

export const UpgradeBox = styled("div")({
    background: "#FFEACB",
    border: "1px solid #FFB74D",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "10px 14px",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "32px",
});

export const Error = styled(ErrorIcon)({
    color: "#FFB74D",
    marginRight: "12px",
    width: "16px",
    height: "auto",
});
