import React, { useState } from "react";
import FormMentionEditor from "../../../../Component/FormMentionEditor";
import { StyledTextField } from "../../StyledTextField";
import { checkIfAnyColumnNamesEmpty } from "../TableHelpers";

function QuestionInput(props) {
    const [textValue, setTextValue] = useState(props.col.question);

    const handleOnChange = (e) => {
        if (e.target.value.length > 4000) return; // Only allow input up to 64 chars
        setTextValue(e.target.value);
    };

    const handleOnBlur = () => {
        props.handleChangeColumnValue(props.col.id, textValue, "question");

        if (checkIfAnyColumnNamesEmpty(props.columns, textValue, props.col.id, "question")) {
            props.setTableErrors(`Questions cannot be empty`);
            return;
        }
    };

    return props.availablePlaceholders ? (
        <div className="mention-editor-container">
            <span className="mention-editor-label">Question</span>
            <FormMentionEditor
                style={{
                    width: "100%",
                    marginBottom: 8,
                    verticalAlign: "top",
                    minHeight: "16px",
                }}
                aiAssistant={true}
                assignTask={true}
                contentBlock={false}
                hideScrollBar={true}
                text={props && props.col ? props.col.question : ""}
                onFocus={(e) => {}}
                placeholder={"Enter question"}
                onChange={(value) => {
                    props.handleChangeColumnValue(props.col.id, value, "question");
                }}
                {...(props.availablePlaceholders != null && {
                    availablePlaceholders: props.availablePlaceholders,
                })}
            />
        </div>
    ) : (
        <StyledTextField
            fullWidth
            variant="filled"
            size="small"
            multiline
            label={"Question"}
            value={textValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            styles={{
                width: "100%",
                minWidth: "200px",
                marginBottom: "8px",
            }}
        />
    );
}

export default QuestionInput;
