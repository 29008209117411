import React, {Component} from "react";
import Grid from "@mui/material/Grid";
import {withStyles} from "@mui/styles";
import styles from "./SessionVariableStyle";
import {inject, observer} from "mobx-react";
import AddIcon from "@mui/icons-material/Add";
import sessionVariableStore from "./SessionVariableStore";
import {reaction, toJS} from "mobx";
import RenderSessionVariableItem from "./RenderSessionVariableItem";
import {getCurrentTime} from "../../utils/getCurrentTime";
import {send_component_save_request, send_request} from "../../utils/Request";
import projectStore from "../ProjectCanvas/ProjectStore";
import {IndividualComponentLoader} from "../../utils/ComponentLoadingSkeleton";

class SessionVariablesTemplate extends Component {
    state = {
        error: false,
        loading: false,
        availablePlaceholders: [],
        sessionVariableData:[],
        description:null,
        status:null

    };
    componentDidMount() {
        if (this.props.component_id !== undefined) {
            this.setState({loading:true})
            send_request(
                `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
                "",
                {}
            )
                .then((response) => {
                    if (response && response.data) {
                        const { components } = response.data;
                        if (components) {
                            sessionVariableStore.setSessionVariables(components[0]);
                            this.props.onComponentDescriptionChanged(components[0].description);
                            this.props.onComponentNameChanged(sessionVariableStore.name);
                            this.props.onLastModifiedChanged(sessionVariableStore.lastModified);
                            this.setState({loading:false})
                        }
                    }

                    // Check if aiComponentBuilder is given and if so, set the draft data
                    if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
                        this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
                        this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
                        sessionVariableStore.setTemplateData(this.props.aiComponentBuilderData.data);
                        this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
                        this.setState({ status: "AI_BUILD_GENERATED" });
                    }

                    this.setState({loading:false})
                    this.props.setChildTemplateLoaded(true);
                })
                .catch((err) => {
                    throw Error(err.message);
                });
        }

    }

    componentWillUnmount() {
        // Clear the mobX reactions
        this.saveSessionTemplate();
      }

    addEmptySessionVariable = () => {
        let { sessionVariableData } = sessionVariableStore.data;
        let aData = {
            key: this.keyAuto(),
            type: "text",
            value: null,
        };
        sessionVariableData.push(aData);
        sessionVariableStore.setSessionVariableData(sessionVariableData);
    };

    saveSessionTemplate = reaction(
        () => this.props.SaveTrigger.triggerValue,
        () => {

            if(this.state.error){
                this.props.showError();
                return;
            }

            let lastModified = getCurrentTime();
            let data = {
                componentData: {
                    data: sessionVariableStore.template.data,
                    name: toJS(this.props.ComponentName),
                    lastModified: lastModified,
                },
                componentId: this.props.component_id,
                type: sessionVariableStore.template.type,
                description: this.props.ComponentDescription.value,
                status: this.state.status
            };
            send_component_save_request("component-service/session-variables/data", data, "", "POST", this.props.SaveTrigger)
                .then((response) => {
                    this.props.onLastModifiedChanged(lastModified);
                    this.props.showSuccess(data, this.props.SaveTrigger);
                    projectStore.savedComponent = true;
                })
                .catch((err) => {
                    throw err;
                });
        }
    );
    render() {
        const { availablePlaceholders,classes } = this.props;

        let { data } = sessionVariableStore;
        let { sessionVariableData } = toJS(data);
        if (this.state && !this.state.loading) {

            return (
                <Grid>

                    {sessionVariableData && sessionVariableData.map((aData, index) => (
                        <RenderSessionVariableItem
                            aData={aData}
                            index={index}
                            classes={classes}
                            sessionVariableData={sessionVariableData}
                            availablePlaceholders={availablePlaceholders}
                            onPlaceholderSelected={this.props.onPlaceholderSelected}
                            setSessionVariableData={(sessionVariableData) => {
                                sessionVariableStore.setSessionVariableData(sessionVariableData);
                            }}
                            component={"session_variable_record"}
                        />
                    ))}

                    <Grid item xs={12}>
                        <div
                            className={this.props.classes.newSessionVariable}
                        >
                            <div
                                className="query-database-add-data"
                                onClick={() => {
                                    this.addEmptySessionVariable();
                                }}
                            >
                                <AddIcon/> Add Session variable
                            </div>
                        </div>
                    </Grid>

                </Grid>


            )
        }
        else return <IndividualComponentLoader />;


    }
    keyAuto = () => {
        return (
            "session_data" + (Math.floor(Math.random() * 90000000) + 10000).toString()
        );
    };
}

export default withStyles(styles)(inject("SelectedPlaceholder", "ComponentName", "ComponentDescription", "SaveTrigger")(observer(SessionVariablesTemplate)));
