import React, { useState } from 'react';
import { Grid, Typography, Button } from "@mui/material";
import { Navigate } from "react-router-dom";

const PaymentSuccess = () => {
  const [signInOpen, setSignInOpen] = useState(false);

  if (signInOpen) {
    return <Navigate to='/' />;
  }

  return (
    <Grid container className="main-page">
      <Grid item xs={12} className="main-form-login">
        <div className="mainDiv">
          <Typography component="h4" variant="h4" style={{ color: "black", marginBottom: "16px" }}>
            Your account is ready to go
          </Typography>
          <div className="inputArea">
            <p className="inputDescription">
              Your account has been successfully updated. Click the button below to launch Workflow86.
            </p>
          </div>
          <div className="actionArea" style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button 
              variant="contained" 
              color="primary"
              onClick={() => setSignInOpen(true)}
            >
              Continue
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
