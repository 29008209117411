import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from "@mui/material/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const BrowserModal = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const BrowserHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 16px',
  backgroundColor: '#f0f0f0',
  borderBottom: '1px solid #ddd',
}));

const BrowserAddressBar = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#fff',
  borderRadius: '16px',
  padding: '4px 12px',
  marginRight: '16px',
  fontSize: '14px',
  color: '#333',
}));

const BrowserContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  backgroundColor: '#ffffff',
}));

const ActionButton = styled(IconButton)({
  width: '36px',
  height: '36px',
  marginLeft: '8px',
});

const FormPreviewModal = ({ open, onClose, previewUrl }) => {
  const copyUrl = () => {
    navigator.clipboard.writeText(previewUrl);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="form-preview-modal"
      aria-describedby="form-preview-modal-description"
    >
      <BrowserModal>
        <BrowserHeader>
          <BrowserAddressBar>{previewUrl}</BrowserAddressBar>
          <ActionButton
            color="inherit"
            onClick={copyUrl}
            aria-label="copy url"
          >
            <ContentCopyIcon />
          </ActionButton>
          <ActionButton
            color="inherit"
            onClick={() => window.open(previewUrl, '_blank')}
            aria-label="open in new tab"
          >
            <OpenInNewIcon />
          </ActionButton>
          <ActionButton onClick={onClose} size="small" aria-label="close">
            <CloseIcon />
          </ActionButton>
        </BrowserHeader>
        <BrowserContent>
          <iframe src={previewUrl} style={{width: '100%', height: '100%', border: 'none'}} />
        </BrowserContent>
      </BrowserModal>
    </Modal>
  );
};

export default FormPreviewModal;
