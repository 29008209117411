import React, { useState } from "react";

// MUI
import Grid from "@mui/material/Grid";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { StyledTextField } from "../../StyledTextField";

function DropdownOption(props) {
  const {
    classes,
    col,
    opt,
    handleChangeOptionValue,
    handleAddOrRemoveOption
  } = props;

  const [optionValue, setOptionValue] = useState(opt.value);

  return (
    <Grid
      key={opt.id}
      id={opt.id}
      item
      xs={12}
      className={classes.optionGridItem}
    >
      <StyledTextField
        value={optionValue}
        fullWidth
        size="small"
        label="Answer choice"
        variant="filled"
        onChange={e => setOptionValue(e.target.value)}
        onBlur={() => handleChangeOptionValue(col.id, opt.id, optionValue)}
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{ shrink: true }}
        styles={{ width: "100%", display: "inline-block" }}
      />
      <CancelOutlinedIcon
        className={classes.removeIcon}
        onClick={() => handleAddOrRemoveOption(col.id, opt.id, false)}
      />
    </Grid>
  );
}

export default DropdownOption;
