import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { observer } from "mobx-react";
import JoditEditor from "jodit-pro-react";


const useStyles = () => ({
  root: {
    "& .jodit-wysiwyg": {
      padding: "96px !important",
      backgroundColor: "white !important",
      /* box-shadow: rgb(214 214 214 / 80%) 0px 5px 9px 2px; */
      /* font-family: unset; */
      minHeight: "297mm !important",
    },
    "& .jodit-container": {
      boxShadow: "rgb(214 214 214 / 80%) 0px 5px 9px 2px",
    },
  },
  gridJodit: {
    display: "flex",
    justifyContent: "center",
  },
});

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ editorState: this.props.html });
  }

  setRef = (jodit) => (this.jodit = jodit);

  config = {
    license: "CC7FX-1D761-AUOP5-JG0GX",
    readonly: true,
    toolbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    width: "800px",
    disablePlugins: "resizer",
    useIframeResizer: false,
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root} spacing={3}>
        <Grid item className={classes.gridJodit} xs={12}>
          <JoditEditor
            ref={this.setRef}
            value={this.props.html}
            config={this.config}
          />
        </Grid>
      </Grid>
    );
  }
}

export default observer(withStyles(useStyles)(TextEditor));
