import React, { useState, useEffect, useRef } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Grid, styled, Skeleton } from "@mui/material";
import WorkflowProgressModal from "./WorkflowProgressModal";
import {AFTER_RUN_ALERT_TYPE} from "./ForceStartConstants";

// Styled components for consistent styling
const AlertBox = styled(Grid)(({ bgColor, borderColor }) => ({
    fontSize: "14px",
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    padding: "8px 16px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
}));

export const StyledErrorIcon = styled(ErrorIcon)({
    color: "#b00020",
    marginRight: "8px",
    height: "16px",
    width: "auto",
});

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "10px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

const SessionList = styled('ul')({
    marginTop: "8px",
    marginBottom: "0px",
});

const SessionProgressLink = styled('span')({
    color: "rgba(33, 150, 243, 1)",
    cursor: "pointer",
    textDecoration: "underline"
});


function AfterRunAlert({ alertType, sessionId, projectId, isLoading, isTest }) {
    // State to control the visibility of the WorkflowProgressModal
    const [openModal, setOpenModal] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    // Ref to keep track of the last auto-opened session ID
    const lastAutoOpenedSessionId = useRef(null);
    const isBulkRun = sessionId?.length > 1;

    // Effect to automatically open the modal when a new successful run starts
    useEffect(() => {
        // Only auto-open for single session runs AND when sessionId is a string (not an array)
        if (alertType === AFTER_RUN_ALERT_TYPE.SUCCESS && !isLoading && sessionId?.length === 1 &&
            sessionId !== lastAutoOpenedSessionId.current) {
            setOpenModal(true);
            setSelectedSessionId(sessionId[0]);
            lastAutoOpenedSessionId.current = sessionId;
        }
    }, [alertType, isLoading, sessionId]);

    // Handler functions for modal open/close
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenModal = (selectedSessionId) => {
        setSelectedSessionId(selectedSessionId);
        setOpenModal(true);
    };

    const BulkRunContent = ({ handleOpenModal }) => (
        <>
            Multiple workflow runs have been started from this component:
            <SessionList>
                {sessionId.map((id) => (
                    <li key={id}>
                        <SessionProgressLink onClick={() => handleOpenModal(id)}>
                            Session {id}
                        </SessionProgressLink>
                    </li>
                ))}
            </SessionList>
        </>
    );

    const SingleRunContent = ({ isTest, handleOpenModal }) => (
        <>
            {!isTest ? "Workflow" : "Test"} run started from this component.
            <SessionProgressLink onClick={() => handleOpenModal(sessionId[0])}>
                {" "}clicking here
            </SessionProgressLink>
        </>
    );

    // Function to render the success alert
    const getSuccessAlert = () => {
        return (
            <AlertBox container bgColor="rgba(229, 242, 235, 1)" borderColor="rgba(85, 167, 122, 1)">
                <Grid item xs={12}>
                    ✅ {isBulkRun ? (
                        <BulkRunContent handleOpenModal={handleOpenModal} />
                    ) : (
                        <SingleRunContent isTest={isTest} handleOpenModal={handleOpenModal} />
                    )}
                </Grid>
            </AlertBox>
        );
    };

    // Function to render the error alert
    const getErrorAlert = () => {
        return (
            <AlertBox container bgColor="rgba(225, 0, 0, 0.15)" borderColor="rgba(225, 0, 0, 1)">
                <StyledErrorIcon /> There was an error force-starting this component
            </AlertBox>
        );
    };

    // Function to determine which alert to display based on alertType
    const getAlert = () => {
        switch (alertType) {
            case AFTER_RUN_ALERT_TYPE.SUCCESS:
                return getSuccessAlert();
            default:
                return getErrorAlert();
        }
    }

    return (
        <>
            {/* Display a loading skeleton or the appropriate alert */}
            {isLoading ? <StyledSkeleton variant="rectangular" height="50px" /> : getAlert()}
            {/* Render the WorkflowProgressModal */}
            <WorkflowProgressModal
                open={openModal}
                onClose={handleCloseModal}
                projectId={projectId}
                sessionId={selectedSessionId}
                isTest={isTest}
            />
        </>
    );
}

export default AfterRunAlert;
