const styles = theme => ({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridRowGap: 24,
    paddingBottom: 16
  },
  headerLeft: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    maxWidth: 570,
    gridColumnGap: "16px",
    alignItems: "center"
  },
  upload: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    maxWidth: 300,
    gridColumnGap: "16px",
    alignItems: "center"
  },
  headerRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  main: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center"
  },
  wrap: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: 24,
    marginBottom: "16px",
    background: "#fff"
  },
  label: {
    fontWeight: 600,
    alignItems: "center",
    display: "grid"
  },
  label2: {
    fontWeight: 600,
    alignItems: "center",
    display: "grid",
    paddingRight: 24,
    minWidth: 90
  },
  select: {
    display: "inline-block"
  },
  reportSelect: {
    width: "100%",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    height: "36px",
    color: "#868686"
  },
  dropzone: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  custemIcon: {
    color: "#868686"
  },
  deleteIcon: {
    cursor: "pointer",
    marginLeft: 16,
    color: "#868686",
    "&:hover": {
      color: "#2196F3"
    }
  },
  wrapFlexCenter: {
    display: "flex",
    justifyContent: "center",
    margin: "16px 0"
  },
  textContentCenter: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    "&:hover": {
      color: "#2196F3"
    }
  },
  dialogContent: {
    padding: 24,
    height: "700px"
  },
  dialogText: {
    margin: "0 0 8px",
    fontSize: "14px",
    color: "#868686",
    lineHeight: "28px"
  },
  dialogImage: {
    width: "100%"
  }
});
export default styles;
