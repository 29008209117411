import React, { Component } from "react";
import { TextField } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@mui/styles";
import TextEditor from "../../TextEditor/TextEditor";
import InputPlaceholder from "./InputPlaceholder";
import MentionEditor from "../../TextEditor/MentionEditor";

import KnowledgeStore from "../KnowledgeStore";
import styles from "./KnowledgeStyle";
import { toJS } from "mobx";
import Icon from "@mui/material/Icon";
import checkKey from "../../../utils/CheckKey";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";

class ItemKnowledge extends Component {
  state = {
    checkKey: false,
    errorMessage: "",
  };

  handleKeyChange = (e, index) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    KnowledgeStore.setField(placeholderValue, index, "key");

    const { data } = toJS(KnowledgeStore);
    const value = checkKey(placeholderValue, index, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
    this.props.handleKeyChanges(value[0]);
  };

  render() {
    const { classes, index, availablePlaceholders } = this.props;
    const { key, type, content } = this.props.component;
    let { position, placeholders } = KnowledgeStore;

    placeholders = toJS(placeholders);
    let contentArray = [];
    if (position == index) contentArray = placeholders;

    return (
      <div className={classes.wrap}>
        <div className={classes.gridContainer}>
          <div className={classes.headerLeft}>
            <div className={classes.label}> Placeholder</div>
            <W86PlaceholderTextField
              value={key}
              error={this.state.checkKey ? true : false}
              helperText={this.state.checkKey ? this.state.errorMessage : null}
              placeholderText="Enter placeholder for item"
              handleChange={(event) => this.handleKeyChange(event, index)}
            />
          </div>
          <div className={classes.headerRight}>
            <div className={classes.main}>
              <div className={classes.label2}> Content type</div>
              <div className={classes.select}>
                <Select
                  name="selectType"
                  disableUnderline
                  variant="standard"
                  labelId="demo-simple-select-outlined-label"
                  className={`dropdown-mat selectStandard ${classes.reportSelect}`}
                  value={type}
                  onChange={(event) => {
                    KnowledgeStore.setField(event.target.value, index, "type");
                  }}
                >
                  <MenuItem value={"RICH"}> Rich text </MenuItem>
                  <MenuItem value={"BASIC"}> Basic text </MenuItem>
                </Select>
              </div>
              <div>
                <DeleteIcon
                  onClick={() => KnowledgeStore.deleteComponent(index)}
                  className={classes.deleteIcon}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {type == "RICH" && (
            <TextEditor
              contentStyles={{
                border: "1px solid rgba(0, 0, 0, 0.16)",
                borderRadius: "4px",
                boxShadow: "none",
                height: "auto",
                minHeight: 200,
                padding: 0,
                minWidth: "100%",
              }}
              availablePlaceholders={availablePlaceholders}
              html={content}
              onChange={(html) => {
                KnowledgeStore.setField(html, index, "content");
              }}
              placeholder={contentArray}
              editorFocus={() => {
                KnowledgeStore.setFocus(index);
              }}
            />
          )}
          {type == "BASIC" && (
            <MentionEditor
              availablePlaceholders={availablePlaceholders}
              placeholders={contentArray}
              text={content}
              placeholder="Insert placeholders and/or text"
              minHeight={100}
              onChange={(value) => {
                KnowledgeStore.setField(value, index, "content");
              }}
              onFocus={() => {
                KnowledgeStore.setFocus(index);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(ItemKnowledge);
