import React, {Component} from 'react';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import {toJS} from "mobx";
import GenerateToken from "../../Component/GenerateToken";
import {Grid} from "@mui/material";

class SetToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasToken: this.props.hasToken
    };
  }

  TOKEN_INSTRUCTIONS_URL = "https://docs.workflow86.com/docs/components/webhook_import/#how-to-set-the-token";

  changeTokenStatus = (status) => {
    this.setState({hasToken: status})
    this.props.changeTokenStatus(status)
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12} className="textAlignLeft">
            <span style={{ marginBottom: '16px', display: 'block' }}>Generate a token and then set that in the header (recommended) or in the URL of the endpoint. See further instructions <a
              href={this.TOKEN_INSTRUCTIONS_URL} 
              target="_blank">here</a>
            </span>
          </Grid>

          <GenerateToken 
            hasToken={(status) => {this.changeTokenStatus(status)}} 
            tokenStatus={this.props.hasToken}
            name={toJS(this.props.ComponentName)} 
            component_id={this.props.component_id} 
            isAlignCenter={true}
          />

          {
            !this.state.hasToken ?
              (
                <p className="err-text">
                  <ErrorIcon className="iconDiscard" />
                  A token must be generated before the Webhook Import component can be used.
                </p>
              )
              :
              (
                <p className="err-text">
                  <ErrorIcon className="iconDiscard" />
                  A token has already been generated. If you have lost this token, you will need to generate a new one.
                </p>
              )
          }

          <Grid item xs={12} className="mb-16">
            Once the token has been set up in the header, click finish.
          </Grid>
        </Grid>

        <Grid item xs={12} className="dialog-content-right">
          <Button
            onClick={() => {this.props.onChangeState(4,4)}}
            className="btnSpace"
            variant="outlined"  
            color="info"
          >
            Back
          </Button>
          <Button
            onClick={() => {this.props.onChangeState(6,6)}}
            disabled={this.state.hasToken ? false : true}
            variant="contained" 
            color="primary"
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default SetToken;