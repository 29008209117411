import { computed, observable, decorate, action, toJS } from "mobx";

class TriggerFromAWorkflowStore {
  constructor() {
    this.initialiseEmpty();
  }

  setTriggerFromAWorkflowTemplate = data => {
    this.trigger_from_a_workflow_template_id = data.componentId;
    this.trigger_from_a_workflow_name = data.componentData.name;
    this.trigger_from_a_workflow_sourceProjectId =
      data.componentData.sourceProjectId;
    this.trigger_from_a_workflow_sourceComponentId =
      data.componentData.sourceComponentId;
    this.trigger_from_a_workflow_connectionData =
      data.componentData.connectionData;
    this.template_data = data.componentData.data;
    this.lastModified = data.componentData.lastModified;
  };

  setTriggerFromAWorkflowName = triggerFromAWorkflowName => {
    this.trigger_from_a_workflow_name = triggerFromAWorkflowName;
    this.template_data.name = triggerFromAWorkflowName;
  };

  initialiseEmpty = () => {
    this.trigger_from_a_workflow_template_id = "";
    this.client_id = "000001";
    this.trigger_from_a_workflow_name = "";
    this.lastModified = "";
    this.template_data = {
      sourceProjectId: "",
      sourceComponentId: "",
      connectionData: null
    };
  };

  get template() {
    return {
      componentId: this.trigger_from_a_workflow_template_id,
      lastModified: this.lastModified,
      componentData: toJS(this.template_data)
    };
  }
}

decorate(TriggerFromAWorkflowStore, {
  trigger_from_a_workflow_template_id: observable,
  client_id: observable,
  lastModified: observable,
  trigger_from_a_workflow_name: observable,
  template_data: observable,
  setTriggerFromAWorkflowTemplate: action,
  setTriggerFromAWorkflowName: action,
  initialiseEmpty: action,
  template: computed
});

const triggerFromAWorkflowStore = new TriggerFromAWorkflowStore();
export default triggerFromAWorkflowStore;
