import React, { Fragment } from "react";

// Material UI
import { DialogActions, Button, Grid, TextField } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

function MapAZapContent(props) {
  const {
    classes,
    urls,
    error,
    errorMessage,
    handleChange,
    handleRemovingInput,
    handleAddingAnotherZap,
    closeAll,
    handleMapping
  } = props;

  return (
    <Fragment>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} className={classes.zapTitle}>
          ⚡ Map a Zap
        </Grid>

        <Grid item xs={12} className={classes.zapHelperText}>
          On the Zap that you want to map, click the Share button on the top
          right, and then copy and paste the URL here
        </Grid>

        <Grid item xs={12}>
          <span className={classes.zapInputLabel}>Shared Zap URL</span>
        </Grid>

        {urls &&
          urls.map(url => {
            return (
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                className={classes.inputSection}
                key={url.id}
              >
                <Grid item xs={11} className={classes.zapInputSection}>
                  <TextField
                    variant="outlined"
                    className={classes.zapInput}
                    fullWidth={true}
                    placeholder="Enter a shared Zapier url"
                    onChange={handleChange}
                    id={url.id}
                    key={url.id}
                  />
                </Grid>
                <Grid item xs={1} className={classes.actionDeleteButton}>
                  <CancelOutlinedIcon
                    className={classes.cancelIcon}
                    id={url.id}
                    onClick={() => handleRemovingInput(url.id)}
                  />
                </Grid>
              </Grid>
            );
          })}

        <Grid item xs={12} className={classes.addAnotherButton}>
          <span onClick={handleAddingAnotherZap}>+ add another zap</span>
        </Grid>

        {error && (
          <Grid item xs={12} className="error-section">
            <ErrorIcon className="iconDiscard" />
            {errorMessage}
          </Grid>
        )}
      </Grid>

      <DialogActions>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 16 }}>
            <Button
              onClick={() => {
                closeAll();
              }}
              variant={"outlined"}
            >
              Cancel
            </Button>
          </div>

          <Button
            onClick={handleMapping}
            variant={"contained"}
            color={"primary"}
          >
            Map It
          </Button>
        </div>
      </DialogActions>
    </Fragment>
  );
}

export default MapAZapContent;
