import React, { useEffect, useState } from "react";

// Util
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";

// MUI
import { Grid, TextField } from "@mui/material";
import { BoldText, BrokenPlaceholder, CenterGrid, OpenInNew, ReferencedText, Right, SourceItem, StyledAutocomplete, StyledChip } from "./RenameStyledComponents";
import ComponentSourceItem from "./ComponentSourceItem";

function RemapRow({ idx, brokenPH, availablePHs, currValue, updateValue, isChanging, projectId }) {
    const [available, setAvailable] = useState(null);

    useEffect(() => {
        let addedOption = [{ key: "Ignore/Do not change" }, ...availablePHs];
        setAvailable(addedOption);
    }, []);

    const getPlaceholderChip = (item) => {
        const placeholderColor = getPlaceholderColors(item.type, item.length);
        return <StyledChip backgroundColor={placeholderColor.hover} borderColor={placeholderColor.default} icon={placeholderColor.icon}>{`$\{${item.key}\}`}</StyledChip>;
    };

    const getRefText = () => {
        let size = brokenPH.referenceComponentIds.length;

        return (
            <ReferencedText>
                Referenced in <BoldText>{`${size} component${size !== 1 ? "s" : ""}`}</BoldText>
            </ReferencedText>
        );
    };

    const getSourceComponentList = () => {
        return (
            <Grid container rowSpacing={1}>
                {brokenPH.referenceComponentIds.map((componentId) => {
                    return <ComponentSourceItem componentId={componentId} projectId={projectId} />;
                })}
            </Grid>
        );
    };

    return (
        available !== null && (
            <Grid item xs={12} key={idx}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs>
                                <BrokenPlaceholder id={`broken-placeholder-${brokenPH.key}`}>{`$\{${brokenPH.key}\}`}</BrokenPlaceholder>
                            </Grid>
                            <CenterGrid item xs={1}>
                                <Right />
                            </CenterGrid>
                            <Grid item xs>
                                <StyledAutocomplete
                                    fullWidth
                                    disabled={isChanging}
                                    disablePortal
                                    options={available}
                                    getOptionLabel={(option) => option.key}
                                    onChange={(event, newValue) => {
                                        if (!newValue) updateValue({ key: "Ignore/Do not change" }, idx);
                                        else updateValue(newValue, idx);
                                    }}
                                    value={currValue}
                                    renderInput={(params) => <TextField {...params} size="small" placeholder="Select new placeholder" />}
                                    renderOption={(props, option) => {
                                        if (option.key === "Ignore/Do not change") return <li {...props}>{option.key}</li>;
                                        else return <li {...props}>{getPlaceholderChip(option)}</li>;
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {getRefText()}
                    </Grid>
                    <Grid item xs={12}>
                        {getSourceComponentList()}
                    </Grid>
                </Grid>
            </Grid>
        )
    );
}

export default RemapRow;
