import React from "react";
import { Grid, Button } from "@mui/material";
import { styled } from "@mui/system";

const DefaultOptions = [
    {
        displayValue: "Customer onboarding for a fintech",
        rawValue:
            "Step 1: Create an online form for customers to fill out their personal and financial information, which will initiate the start of the onboarding process. Step 2: Validate the information provided by the customer, including checking for any missing details and verifying the data's format. Step 3: Send the customer's information to a third-party service for identity verification, a typical practice to comply with regulations. Step 4: Handle the response from the identity verification service. If successful, the process continues; if not, an email is sent to the customer asking for a resubmission of their details. Step 5: Store the customer's details in a database for future reference and system integration. Step 6: Send a welcome email to the customer, confirming the completion of their onboarding process.",
    },
    {
        displayValue: "Hiring for a Software Engineer position",
        rawValue:
            "Start the workflow with a form to collect applications for a specific position. The form should capture essential contact details and allow for document uploads and text inputs. Assign a task to the relevant team to review the application. The task should display the applicant's information and provide a decision-making option. Create two workflow paths based on the team's decision: one for proceeding further with the candidate and another for not proceeding. For candidates chosen to proceed, assign a task to the relevant team for scheduling. The task should facilitate setting up a date and time. Send an invitation with details to the chosen candidates. For candidates not chosen to proceed, send a notification email.",
    },
    {
        displayValue: "Onboarding vendors for a manufacturing company",
        rawValue:
            "Capture vendor details through an online form and store them in a database. Assign a review task to the internal team with a link to the stored vendor details. Based on the review: Notify the vendor of approval and provide onboarding steps. Or, notify the vendor of rejection with feedback. Update the vendor's status in the database reflecting the review outcome.",
    },
    {
        displayValue: "Review and approval of legal documents",
        rawValue:
            "The workflow starts with a form filled out by the individual submitting a document. This form captures the submitter's contact details and the document itself. Assign a review task to the relevant team. This task should provide a preview of the document and options for evaluation. Determine the next steps based on the evaluation decision: there are paths for both approval and rejection. For approved documents, notify the submitter about the approval and provide any feedback from the evaluator. For rejected documents, notify the submitter about the rejection and supply any feedback from the evaluator.",
    },
    {
        displayValue: "Software incident report and management",
        rawValue:
            "Initiate with a form to capture incident details and store them in a database, generating a unique identifier. Assign the incident to a specialist for investigation, capturing findings and a resolution plan. Update the database with the investigation results. Based on severity: High: Escalate to senior staff for review. Medium/Low: Wait for the estimated resolution time. Implement the resolution plan, update the incident status, and record the resolution details in the database. Notify the reporter about the resolution via email.",
    },
];

const ContainerDiv = styled('div')({
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
});

const StyledGridItem = styled(Grid)({
    padding: "16px 0 0 0",
});

const OptionGridItem = styled(Grid)({
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingBottom: "8px",
});

function OptionButtons(props) {
    return (
        <ContainerDiv>
            <StyledGridItem item xs={12}>
                <Grid container>
                    {DefaultOptions.map((option, index) => (
                        <OptionGridItem item xs={12} key={index}>
                            <Button
                                disabled={props.loading || props.loadingBubble}
                                className={props.aiClasses.newWorkflowButton}
                                variant="outlined"
                                onClick={() => props.handleRequest(option)}
                            >
                                {option.displayValue}
                            </Button>
                        </OptionGridItem>
                    ))}
                </Grid>
            </StyledGridItem>
        </ContainerDiv>
    );
}

export default OptionButtons;
