import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import moment from "moment";
import { send_request } from "../../../utils/Request";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)({
  height: "100%",
  boxShadow: "none",
  border: "1px solid rgba(231, 231, 231, 1)",
  borderRadius: "6px",
  padding: "16px",
  background: "#fff"
});

const StyledCardContent = styled(CardContent)({
  padding: "0 16px 16px 16px",
  "& .MuiTable-root": {
    marginTop: "0"
  }
});

const StyledCardHeader = styled(CardHeader)({
  padding: "16px",
  "& .MuiCardHeader-title": {
    fontSize: "18px"
  }
});

const StyledButton = styled(Button)({
  color: "black",
  textTransform: "none",
  padding: "4px 24px",
  minWidth: "120px"
});

const StyledAutoRenewIcon = styled(AutorenewIcon)({
  height: "14px !important",
  width: "14px !important"
});

const TableCellHeader = styled(TableCell)({
  color: "rgba(134, 134, 134, 1)",
  fontSize: "14px",
  lineHeight: "24px"
});

const StyledSkeleton = styled(Skeleton)(({ width, height }) => ({
  borderRadius: "6px",
  background: "#E8E8E8",
  width: "100%",
  height: height,
}));

const LastRefreshedText = styled("span")({
  color: "rgba(134, 134, 134, 1)",
  fontSize: "12px",
  lineHeight: "24px",
  display: "block"
});

const VALID_ACTIVITY_TYPES = [
  "COMPONENT_UPDATED",
  "COMPONENT_EXECUTED",
  "ASSIGN_TASK_EXECUTED",
  "ASSIGN_TASK_COMPLETED"
];

const checkIfTestActivity = (type) => {
  if (!type) return true;
  return type.toLowerCase().includes('test');
};

const checkIfValidActivity = (type) => {
  return VALID_ACTIVITY_TYPES.includes(type);
};

const SimpleActivityRow = ({ type, timeStamp, eventData, resourceMainID, componentName, projectId, projectName }) => {
  const getActivityTypeColor = (type, eventData) => {
    switch (type) {
      case "COMPONENT_UPDATED":
        return "#2196F3";
      case "COMPONENT_EXECUTED":
        return eventData?.status !== "FAIL" ? "#55A77A" : "#E10000";
      case "ASSIGN_TASK_EXECUTED":
        return "#F59300";
      case "ASSIGN_TASK_COMPLETED":
        return "#55A77A";
      default:
        return "gray";
    }
  };

  const renderDescription = () => {
    const name = componentName || "Untitled";
    const workflow = projectName || "Untitled Workflow";

    switch (type) {
      case "COMPONENT_UPDATED":
        return (
          <span>
            <Link to={`/project/canvas/${projectId}`} target="_blank">
              {workflow}
            </Link>{" "}
            was updated
          </span>
        );
      case "COMPONENT_EXECUTED":
        return (
          <span>
            <Link to={`/project/canvas/${projectId}`} target="_blank">
              {workflow}
            </Link>{" "}
            {eventData?.status !== "FAIL" ? "was run" : "failed to run"}
          </span>
        );
      case "ASSIGN_TASK_EXECUTED":
        return (
          <span>
            <Link to={`/project/canvas/${projectId}`} target="_blank">
              {workflow}
            </Link>{" "}
            created task{" "}
            <Link to={`/task/${resourceMainID}`} target="_blank">
              {name}
            </Link>
          </span>
        );
      case "ASSIGN_TASK_COMPLETED":
        return (
          <span>
            <Link to={`/project/canvas/${projectId}`} target="_blank">
              {workflow}
            </Link>{" "}
            completed task{" "}
            <Link to={`/task/${resourceMainID}`} target="_blank">
              {name}
            </Link>
          </span>
        );
      default:
        return "";
    }
  };

  const formatTimestamp = (timestamp) => {
    return moment.utc(timestamp).local().fromNow();
  };

  return (
    <TableRow>
      <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <div 
          style={{ 
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: getActivityTypeColor(type, eventData),
            flexShrink: 0,
            display: 'inline-block',
            verticalAlign: 'middle'
          }} 
        />
        {renderDescription()}
      </TableCell>
      <TableCell sx={{ minWidth: '100px', whiteSpace: 'nowrap' }}>
        {formatTimestamp(timeStamp)}
      </TableCell>
    </TableRow>
  );
};

const createActivityObjects = async (activities) => {
  if (!Array.isArray(activities)) return [];
  
  const enrichedActivities = await Promise.all(
    activities
      .filter(activity => 
        !checkIfTestActivity(activity.activityType) && 
        checkIfValidActivity(activity.activityType)
      )
      .map(async (activity) => {
        try {
          const componentRes = await send_request(
            `project-service/project/component/${activity.resourceMainID}`,
            "",
            {},
            "get"
          );
          const projectRes = await send_request(
            `project-service/project/get_project_info_with_componentid/${activity.resourceMainID}`,
            "",
            {},
            "get"
          );

          // Skip this activity if there's no projectId
          if (!projectRes.data.projectId) {
            return null;
          }

          return {
            type: activity.activityType,
            timeStamp: activity.dateTime,
            resourceMainID: activity.resourceMainID,
            eventData: activity.eventData || {},
            componentName: componentRes.data.componentData.name,
            projectName: projectRes.data.name,
            projectId: projectRes.data.projectId
          };
        } catch (error) {
          console.error('Error fetching component/project details:', error);
          return null;  // Return null instead of object with null projectId
        }
      })
  );

  // Filter out null values and take first 10
  return enrichedActivities.filter(activity => activity !== null).slice(0, 10);
};

const StyledViewAllButton = styled(Button)({
  marginTop: '16px',
  width: '100%',
  textTransform: 'none',
  color: '#2196F3',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
});

const RecentActivityCard = () => {
  const [loadedAPI, setLoadedAPI] = useState(false);
  const [activities, setActivities] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [lastRefreshed, setLastRefreshed] = useState(null);

  const retrieveActivities = async () => {
    try {
      const activitiesData = await send_request(
        `activity/get_activities/0`,
        "",
        null,
        "get"
      );
      
      if (activitiesData?.data?.content) {
        const activityObjects = await createActivityObjects(activitiesData.data.content);
        setActivities(activityObjects);
      }
    } catch (error) {
      console.error('Error retrieving activities:', error);
      setActivities([]);
    }
  };

  useEffect(() => {
    retrieveActivities().finally(() => {
      setLoadedAPI(true);
    });
  }, []);

  const handleRefreshClick = () => {
    setRefreshing(true);
    setLastRefreshed(moment().toISOString());
    retrieveActivities().finally(() => {
      setRefreshing(false);
    });
  };

  return (
    <StyledCard>
      <StyledCardHeader
        title={
          <div>
            <div>Recent Activity</div>
            {lastRefreshed && (
              <LastRefreshedText>
                Last refreshed {moment(lastRefreshed).fromNow()}
              </LastRefreshedText>
            )}
          </div>
        }
        action={
          <StyledButton
            disabled={refreshing}
            onClick={handleRefreshClick}
            startIcon={refreshing ? <CircularProgress size={14} /> : <StyledAutoRenewIcon />}
            size="small"
          >
            {refreshing ? "Refreshing" : "Refresh"}
          </StyledButton>
        }
      />
      <StyledCardContent>
        {loadedAPI ? (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHeader>Activity</TableCellHeader>
                  <TableCellHeader>Time</TableCellHeader>
                </TableRow>
              </TableHead>
              {activities.length > 0 ? (
                activities.map((activity, index) => (
                  <SimpleActivityRow
                    key={`${activity.type}-${index}-${activity.timeStamp}`}
                    {...activity}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No recent activity
                  </TableCell>
                </TableRow>
              )}
            </Table>
            <StyledViewAllButton
              component={Link}
              to="/activity"
              variant="text"
            >
              View Full Activity Log
            </StyledViewAllButton>
          </>
        ) : (
          <StyledSkeleton variant="rectangular" height={300} />
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default RecentActivityCard;
