import PropTypes from "prop-types";

const AIPanelPropTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object,
  showAIBuilder: PropTypes.func,
  setShowAIBuilder: PropTypes.func,
  selectedHistorySession: PropTypes.object,
  setSelectedHistorySession: PropTypes.func,
  setShowTray: PropTypes.func,
  setShowNotesTray: PropTypes.func,
  showTourTray: PropTypes.bool,
  draftVersion: PropTypes.string,
  setWorkflowName: PropTypes.func,
  handleSaveProjectName: PropTypes.func,
  setAiBuilderOpen: PropTypes.func,
  projectId: PropTypes.string,
  nodes: PropTypes.array,
  edges: PropTypes.array,
  CanvasStore: PropTypes.object,
  getLatestVersion: PropTypes.func,
  refetchComponents: PropTypes.func,
  isInAIDraft: PropTypes.bool,
  showBuildPrompt: PropTypes.bool,
  setShowBuildPrompt: PropTypes.func,
  aiComponentBuilderOpen: PropTypes.bool,
  isInit: PropTypes.bool,
  setAiComponentBuilderOpen: PropTypes.func,
  setAiComponentBuilderData: PropTypes.func,
  setOpenSessionHistory: PropTypes.func,
  setResetHistory: PropTypes.func,
  handleSave: PropTypes.func,
  reactFlowInstance: PropTypes.object,
  setSaving: PropTypes.func,
  setPaneSkeleton: PropTypes.func,
  editComponent: PropTypes.object,
  aiComponentBuilderData: PropTypes.string,
  setComponentInstruction: PropTypes.func,
  setTestFromCompPanel: PropTypes.func,
  setOpenTestPanel: PropTypes.func,
  openSessionHistory: PropTypes.bool,
  setIsInAIDraft: PropTypes.func,
  sidebarState: PropTypes.string,
  setSidebarState: PropTypes.func,
  setCompSessionId: PropTypes.func,
  compSessionId: PropTypes.string,
  triggerCloseInChild:PropTypes.bool,
  setTriggerCloseInChild:PropTypes.func,
  setCallTriggerCloseInChild:PropTypes.func,
  callTriggerCloseInChild:PropTypes.bool
};

export default AIPanelPropTypes;
