import React from "react";

import { Grid, TextField } from "@mui/material";
import ChipInput from "../../../ChipInput/ChipInput";
import TransformStore from "../../TransformStore";

function SplitListIntoPlaceholders({ availablePlaceholders, onPlaceholderSelected, splitList, numPlaceholder, exportPlaceholders, checkKey, index }) {
    
    return (
        <Grid container item xs={12} direction={"column"}>
            <p>Split all objects in this list into placeholders</p>
            <ChipInput
                placeholders={availablePlaceholders}
                onPlaceholderSelected={onPlaceholderSelected}
                fullWidth
                onBeforeAdd={(chip) => {
                    TransformStore.setComponent(chip, index, "splitList");
                    return true;
                }}
                placeholder="Insert a list"
                inputValue={splitList}
                onDelete={(indexChip) => TransformStore.deleteChip(indexChip, index, "splitList")}
                onFocus={() => TransformStore.setFocus(index, "splitList")}
            />
            <p>Number of placeholders to generate</p>
            <TextField
                inputProps={{ inputMode: "numeric", pattern: "[1-100]*" }}
                size={"small"}
                type={"number"}
                value={numPlaceholder}
                onWheel={(e) => {e.preventDefault(); return;}} // To remove the scroll
                error={numPlaceholder < 1 || numPlaceholder > 100}
                helperText={numPlaceholder < 1 || numPlaceholder > 100 ? "No placeholders will be exported from the list. This value has to be in range [1-100]." : ""}
                onChange={(event) => TransformStore.handleExportDifferentNumberOfPlaceholders(event, index)}
                placeholder={"Insert a number of placeholders to generate"}
            />
            <p className={"s-text"}>Each object in the list will be outputted as a placeholder based on its order in the list</p>
            {exportPlaceholders.map((item, idx) => {
                let error = checkKey(item.key, index, TransformStore.data);
                return (
                    <Grid key={idx}>
                        <p>Placeholder for item {idx + 1} in list </p>
                        <TextField
                            size={"small"}
                            error={error[0]}
                            helperText={error[0] ? error[1] : ""}
                            placeholder={"Insert a placeholder name"}
                            value={item.key}
                            onChange={(event) => {
                                TransformStore.handleChangePlaceholderName(event, idx, index);
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default SplitListIntoPlaceholders;
