import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Slide } from "@mui/material";

import { send_request_graphql } from "../../../utils/Request";
import { runLoading } from "../../../utils/showLoadingScreen";
import { GET_LATEST_PRODUCTION_VERSION } from "./CanvasQueries";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { PlayCircleOutline } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CanvasReloadDialog({ projectId, currentProdVersion, setShowCheckingLatestDialog, data, id, forceStartComponent, setForceStartComponent, handleProductionCanvasMount }) {
    const [loading, setLoading] = useState(true);
    const [latestVersion, setLatestVersion] = useState(null);

    useEffect(async () => {
        setLoading(true);

        // Check if the current version == latest production version
        let json = await send_request_graphql(`project-service/graphql/project/${projectId}`, GET_LATEST_PRODUCTION_VERSION(projectId));
        if (!json || !json.data || !json.data.publishedProjectById) {
            setLoading(false);
            return;
        }

        let latestVersion = json.data.publishedProjectById.version;
        setLatestVersion(latestVersion);

        if (parseInt(latestVersion) !== parseInt(currentProdVersion)) {
            // Set loading to false and show refresh
            setLoading(false);
            return;
        } else {
            handleStart();
            setLoading(false);
        }
    }, []);

    const handleStart = () => {
        setShowCheckingLatestDialog(false);
    
        // If pane already open, close it
        if (forceStartComponent) setForceStartComponent(null);

        // Force start non-trigger components
        setForceStartComponent({
            ...data,
            componentId: id,
        });
    };

    return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={true} TransitionComponent={Transition}>
            {loading ? (
                <Grid container display="flex" alignItems="center" justifyContent="center" margin="32px 0px">
                    {runLoading()}
                    <p>Checking workflow for changes...</p>
                </Grid>
            ) : (
                <>
                    <DialogTitle>
                        <h5>🚩 A new production version has been detected</h5>
                    </DialogTitle>
                    <DialogContent>
                        <p>
                            The production workflow has been updated from version <b>{currentProdVersion}</b> to version <b>{latestVersion}</b>. You can continue to run this workflow on version <b>{currentProdVersion}</b> or
                            click Update to refresh this page and run this workflow on version <b>{latestVersion}</b>.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleProductionCanvasMount();
                                setShowCheckingLatestDialog(false);
                            }}
                            variant={"contained"}
                            disabled={loading}
                            startIcon={<AutorenewIcon />}
                        >
                            {`UPDATE TO V${latestVersion}`}
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            className={"start-btn"}
                            onClick={() => {
                                handleStart();
                            }}
                            startIcon={<PlayCircleOutline />}
                        >
                            {`CONTINUE TO V${currentProdVersion}`}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

export default CanvasReloadDialog;
