import React, { useEffect, useState } from "react";

// MUI
import { StyledTextField } from "../../StyledTextField";
import {
  checkIfColumnNameUnique,
  checkIfAnyColumnNamesEmpty
} from "../TableHelpers";

function ColumnNameInput(props) {
  const {
    col,
    columns,
    handleChangeColumnValue,
    tableErrors,
    setTableErrors,
    isRS
  } = props;

  const [textValue, setTextValue] = useState(col.columnName);

  const label = isRS ? "Key" : "Column name";

  useEffect(() => {
    setTextValue(col.columnName);

    if (checkIfAnyColumnNamesEmpty(columns, textValue, col.id, "columnName")) {
      setTableErrors(`${label}s cannot be empty`);
      return;
    }

    if (!checkIfColumnNameUnique(columns, textValue, col.id)) {
      setTableErrors(`${label}s must be unique`);
      return;
    }

    if (tableErrors != "") {
      setTableErrors("");
    }
  }, [columns]);

  const handleOnChange = e => {
    if (e.target.value.length > 64) return; // Only allow input up to 64 chars
    setTextValue(e.target.value);
  };

  const handleOnBlur = () => {
    handleChangeColumnValue(col.id, textValue, "columnName");
    if (checkIfAnyColumnNamesEmpty(columns, textValue, col.id, "columnName")) {
      setTableErrors(`${label}s cannot be empty`);
      return;
    }

    if (!checkIfColumnNameUnique(columns, textValue, col.id)) {
      setTableErrors(`${label}s must be unique`);
      return;
    }

    if (tableErrors != "") {
      setTableErrors("");
    }
  };

  return (
    <StyledTextField
      variant="filled"
      size="small"
      multiline={isRS}
      label={`${label}`}
      value={textValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      InputProps={{ disableUnderline: true }}
      InputLabelProps={{ shrink: true }}
      fullWidth={isRS}
      styles={{
        width: "100%",
        minWidth: "200px",
        marginBottom: "8px"
      }}
    />
  );
}

export default ColumnNameInput;
