import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction } from "mobx";
import parseJSONStore from "./ParseJSONStore";
import projectStore from "../ProjectCanvas/ProjectStore";
import "./ParseJSON.css";

// Utils
import { getCurrentTime } from "../../utils/getCurrentTime";
import { send_component_save_request, send_request } from "../../utils/Request";

// Custom
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import { Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import ReactJson from "react-json-view";
import ErrorIcon from "@mui/icons-material/Error";
import ChipInput from "../ChipInput/ChipInput";
import PlaceholderJson from "../Component/PlaceholderJson";
import handleJsonFunction from "../Component/HandleJsonFunction";
import RefreshIcon from '@mui/icons-material/Refresh';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "../../fonts/JetBrainsMono-Regular.ttf";
import { addCompleter } from "ace-builds/src-noconflict/ext-language_tools";
import { styled } from '@mui/material/styles';

// Define a styled Grid component
const StyledGrid = styled(Grid)(({  }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '8px',
}));
const PrettifyButton = styled(Button)(({  }) => ({
  background: 'black',
  color: 'white',
  '&:hover': {
    background: '#333'
  }
}));

class ParseJSONTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: [],
      loading: false,
      projects: null,
      components: null,
      uploadingSample: false,
      error: false,
      jsonParsed: false
    };
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
    parseJSONStore.initialiseEmpty();
  }

  componentDidMount() {
    if (this.props.component_id == undefined) return;

    this.setState({ loading: true });

    send_request(
      `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
      "",
      {}
    )
      .then(response => {
        if (
          response.data &&
          response.data.components &&
          response.data.components[0].componentData
        ) {
          parseJSONStore.setParseJSONTemplate(
            response.data.components[0]
          );
          this.props.onComponentNameChanged(parseJSONStore.parse_json_name);
          this.props.onComponentDescriptionChanged(response.data.components[0].description);
          this.props.onLastModifiedChanged(parseJSONStore.lastModified);
        }
        this.props.setChildTemplateLoaded(true);
      })
      .catch(err => {
        throw Error(err.message);
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  handleSaveComponent = () => {
    let cData = parseJSONStore.componentData;

    cData.name = toJS(this.props.ComponentName);

    let lastModified = getCurrentTime();
    let data = {
      componentData: {
        data: cData,
        name: toJS(this.props.ComponentName),
        lastModified: lastModified
      },
      componentId: this.props.component_id,
      type: "parse_json",
      description: this.props.ComponentDescription.value
    };

    send_component_save_request(
      "component-service/parse_json/data",
      data,
      "",
      "POST",
      this.props.SaveTrigger
    )
      .then(response => {
        this.props.onLastModifiedChanged(lastModified);
        this.props.showSuccess(data, this.props.SaveTrigger);
        projectStore.savedComponent = true;
      })
      .catch(err => {
        throw err;
      });
  };

  setParseJSONName = autorun(() => {
    parseJSONStore.setParseJSONName(
      this.props.ComponentName
    );
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveParseJSONTemplate();
    this.reactToPlaceHolder();
  }

  saveParseJSONTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    this.handleSaveComponent
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      const { style, key, isNotPlaceholder } = placeholder["selected"];
      this.insertPlaceholder(
        style.default,
        key,
        style,
        this.state.focus,
        isNotPlaceholder
      );
    }
  );

  removeDeletedPlaceholders = savedPHs => {
    let pHs = [];

    for (let i = 0; i < savedPHs.length; i++) {
      let currPH = savedPHs[i];
      if (
        this.props.availablePlaceholders[0].placeholders.some(
          aPH => aPH.key === currPH.key
        )
      ) {
        pHs.push(currPH);
      }
    }
    return pHs;
  };

  insertPlaceholder = (label, text, style, focus, isNotPlaceholder) => {
    if (isNotPlaceholder === true) {
      return;
    }
    const newPlaceholders = ["${" + text + "}"];
    parseJSONStore.setPlaceholders(newPlaceholders);
  };

  handleReactJson = () => {
    let jsonData = {}

    return (
      <ReactJson
        src={jsonData}
        collapsed="true"
        name="Data"
        displayDataTypes={false}
        style={{ fontFamily: "Open Sans !important" }}
        theme={{
          base00: "rgba(1, 1, 1, 0)",
          base01: "rgba(1, 1, 1, 0.1)",
          base02: "rgba(0, 0, 0, 0.2)",
          base03: "rgba(1, 1, 1, 0.3)",
          base04: "rgba(0, 0, 0, 0.4)",
          base05: "rgba(1, 1, 1, 0.5)",
          base06: "rgba(1, 1, 1, 0.6)",
          base07: "rgba(1, 1, 1, 0.7)",
          base08: "rgba(1, 1, 1, 0.8)",
          base09: "rgba(1, 1, 1, 0.8)",
          base0A: "rgba(1, 1, 1, 0.8)",
          base0B: "rgba(1, 1, 1, 0.8)",
          base0C: "rgba(1, 1, 1, 0.8)",
          base0D: "rgba(1, 1, 1, 0.8)",
          base0E: "rgba(1, 1, 1, 0.8)",
          base0F: "rgba(1, 1, 1, 0.8)",
        }}
      />
    )
  }

  handleJson = (key, value) => {
    for (let j = 0; j < value.length; j++) {
      if (Array.isArray(value[j])) {
        for (let x = 0; x < value[j].length; x++) {
          value.push(value[j][x]);
          key.push(key[j] + "[" + x + "]");
        }
      } else if (typeof value[j] === "object" && value[j] !== null) {
        let keyData = Object.keys(value[j]);
        let valueData = Object.values(value[j]);
        for (let y = 0; y < valueData.length; y++) {
          value.push(valueData[y]);
          key.push(key[j] + "." + keyData[y]);
        }
      }
    }
  };

  cleanJsonString = (text) => {
    // Remove wrapping quotes and cleanup escaped quotes if present
    return text.replace(/^["'](.*)["']$/, '$1').replace(/\\"/g, '"');
  };

  handleUploadSample = async () => {
    this.setState({ uploadingSample: true })
    try {
      const sampleJson = this.cleanJsonString(parseJSONStore.componentData.sample_json);
      JSON.parse(sampleJson);
      await handleJsonFunction.handleAddPlaceholdersJson(sampleJson, parseJSONStore);
      this.setState({ error: false, jsonParsed: true })
    } catch(error) {
      this.setState({ 
        error: {
          type: 'parse',
          message: `Invalid JSON format: ${error.message}`
        }, 
        jsonParsed: false 
      });
    } finally {
      this.setState({ uploadingSample: false });
    }
  }

  handlePrettifyJson = () => {
    try {
      const jsonStr = parseJSONStore.componentData.sample_json;
      const cleanedJson = this.cleanJsonString(jsonStr);
      const prettified = JSON.stringify(JSON.parse(cleanedJson), null, 2);
      parseJSONStore.setSampleJSON(prettified);
    } catch (error) {
      this.setState({ 
        error: {
          type: 'prettify',
          message: `Unable to prettify JSON: ${error.message}`
        }
      });
    }
  };

  render() {
    const { componentData } = toJS(parseJSONStore);

    const { availablePlaceholders } = this.props;
    const { error, loading, uploadingSample } = this.state;
    return loading ? (
      <IndividualComponentLoader />
    ) : (
      <Paper
        sx={{
          width: "100%",
          padding: "24px"
        }}
      >
        <Grid
          container
          xs={12}
          direction={"column"}
        >
          <Grid
            xs={12}
            container
            direction={"column"}
            sx={{
              marginBottom: "1rem",
            }}
          >
            <span
              style={{
                fontSize: "14px",
                marginBottom: "8px"
              }}
            >
              Select the input placeholder containing the JSON string to be parsed
            </span>
            <ChipInput
              inputValue={componentData.placeholders}
              onPlaceholderSelected={this.props.onPlaceholderSelected}
              placeholders={availablePlaceholders}
              placeholder="Insert a placeholder"
              onBeforeAdd={(chip) => {
                if (chip.trim() != "") {
                  parseJSONStore.setJSONPlaceholder(chip.trim());
                  return true;
                }
              }}
              onDelete={() => {
                parseJSONStore.deletePlaceholders();
              }}
              onFocus={() => {
              }}
            />
          </Grid>
          <Grid
            xs={12}
            container
            direction={"column"}
            sx={{
              marginBottom: "1rem"
            }}
          >
            <StyledGrid container>
              <span style={{ fontSize: "14px" }} xs={10}>
                Copy and paste a sample JSON {componentData.placeholders.length > 0 ? <>of <strong>{componentData.placeholders}</strong></> : ''} to map to placeholders
              </span>
              <PrettifyButton
                size="small"
                onClick={this.handlePrettifyJson}
                xs={2}
              >
                Clean/Prettify
              </PrettifyButton>
            </StyledGrid>
            <Grid item xs={12}>
              <div id="aceditor" style={{ width: "100%" }}>
                <AceEditor
                  mode="json"
                  theme="github"
                  width="100%"
                  height="auto"
                  minHeight="200px"
                  wrapEnabled={true}
                  value={componentData.sample_json}
                  onBlur={(event, editor) => {
                    parseJSONStore.setSampleJSON(editor.getValue());
                  }}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={false}
                  fontSize={16}
                  name="json-editor"
                  editorProps={{ 
                    $blockScrolling: true,
                    fontSize: "16px",
                    fontFamily: "JetBrains Mono"
                  }}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false,
                    minLines: 10,
                    maxLines: 50
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "stretch",
              marginBottom: 24
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                this.handleUploadSample();
              }}
              disabled={uploadingSample}
              sx={{
                width: "100%",
                bgcolor: 'black',
                '&:hover': {
                  bgcolor: '#333'
                }
              }}
            >
              {uploadingSample ? (
                <>
                  <CircularProgress size={20} sx={{ marginRight: 1, color: 'inherit' }} />
                  Parsing...
                </>
              ) : (
                <>
                  {this.state.jsonParsed ? (
                    <>
                      <RefreshIcon sx={{ marginRight: 1 }} />
                      Parse JSON again
                    </>
                  ) : (
                    'Parse JSON'
                  )}
                </>
              )}
            </Button>
          </div>
          {
            error !== false && (
              <p className="err-text err-text-message">
                <ErrorIcon className="iconDiscard" />
                {error.type === 'parse' ? (
                  error.message
                ) : error.type === 'prettify' ? (
                  error.message
                ) : (
                  'An unexpected error occurred. Please try again.'
                )}
              </p>
            )
          }
          {
            (!uploadingSample && componentData.samplePlaceholders.length > 0) &&
            <PlaceholderJson
              componentStore={parseJSONStore}
              stepStatus={"setPlaceholders"}
              handleKeyChanges={(error) => {
                this.setState({ error });
                this.props.handleKeyChanges(error);
              }}
              data={componentData.sample_json}
              placeholderJson={componentData.samplePlaceholders}
            />
          }
        </Grid>
      </Paper>
    );
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(ParseJSONTemplate));
