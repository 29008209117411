import * as React from "react";
import useAutocomplete from "@mui/lab/useAutocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { NoSsr } from "@mui/material";
import {
  isPlaceholder,
  PLACEHOLDER_COLOR,
  PLACEHOLDER_ERROR_COLOR,
} from "../../utils/PlaceholderUtil";
import { styled } from "@mui/material/styles";
/**
 * We don't actually use the mui chip, but we use a styled div
 * with a styled div inside it to render the chips.
 */
const InputWrapper = styled("div")`
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const StyledTag = styled(({ label, onDelete, handleDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={handleDelete} />
  </div>
))`
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  color: ${(props) =>
      props.textColor ? props.textColor : PLACEHOLDER_COLOR.TEXT};
  background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "#fafafa"};
  border: 1px solid ${(props) => (props.borderColor ? props.borderColor : "#141414")};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 38px;
  outline: 0;
  overflow: hidden;
  --custom-icon: url(${(props) =>
      props.icon ? props.icon : PLACEHOLDER_ERROR_COLOR.ICON});

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }

  &::before {
    content: '';
    background: var(--custom-icon);
    background-size: cover;
    position: absolute;
    width: 16px;
    height: 16px;
    margin-left: -30px;
  }
`;

const Listbox = styled("ul")`
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const ChipInputHook = ({
  placeholders = [],
  onFocus,
  inputValue,
  handleDelete,
  handleAddChipFromAutocomplete,
  handleAddChipNoPlaceholder,
  placeholder,
  type,
}) => {
  /**
   * This is the mui autocomplete function, we have the onChange function here,
   * which handles adding data to chip
   */
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } =
    useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: inputValue,
    multiple: true,
    freeSolo: true,
    clearOnBlur: true,
    options: placeholders,
    getOptionLabel: (option) => option.text,
    onChange: (event, value,reason,details) => {
      /**
       * backspace work for placeholders not for list chips. Have to check for single input chips.
       */
      if (event.key === "Backspace") {
        return;
      }

      let chip = value[value.length - 1];
      const { text } = chip;

      if (text !== undefined) {
        chip.text = text.substring(2, text.length - 1);
        handleAddChipFromAutocomplete(chip);
      } else {
        handleAddChipNoPlaceholder(details.option); // adding the chip text from details.option of
                                                   // the onchange event to get the correct value
      }
    },
  });

  /**
   * Reduce the inputValue chips map to get border color, background color, text color.
   * Check doing just calling it on placeholder change.
   * @type {*}
   */
  const placeholderMap = placeholders.reduce(function(
    map,
    { text, borderColor,icon, backgroundColor }
  ) {
    map[text] = { borderColor,icon, backgroundColor };
    return map;
  },
  {placeholders}); // calling for every placeholder

  /*
  Basic function to extract border color
   */

  const getBorderColor = (text) => {
    const placeholder = typeof text === "string" ? placeholderMap[text.trim()] : undefined;
    if (placeholder !== undefined) {
      return placeholder.borderColor;
    } else {
      if (isPlaceholder(text)) {
        return PLACEHOLDER_ERROR_COLOR.BORDER;
      } else if (type && type === "DATETIME"){
        return PLACEHOLDER_ERROR_COLOR.BORDER;
      }
      else return null;
    }
  };

  /*
  Basic function to extract background color
   */
  const getBackgroundColor = (text) => {
    const placeholder = typeof text === "string" ? placeholderMap[text.trim()] : undefined;
    if (placeholder !== undefined) {
      return placeholder.backgroundColor;
    } else {
      if (isPlaceholder(text)) {
        return PLACEHOLDER_ERROR_COLOR.BACKGROUND;
      } else if (type && type === "DATETIME"){
        return PLACEHOLDER_ERROR_COLOR.BACKGROUND;
      }
      else return null;
    }
  };

  /*
  Basic function to extract text color
   */
  const getTextColor = (text) => {
    const placeholder = typeof text === "string" ? placeholderMap[text.trim()] : undefined;
    if (placeholder !== undefined) {
      return PLACEHOLDER_COLOR.TEXT;
    } else {
      if (isPlaceholder(text)) {
        return PLACEHOLDER_ERROR_COLOR.TEXT;
      } else if (type && type === "DATETIME"){
        return PLACEHOLDER_ERROR_COLOR.TEXT;
      }
      else return PLACEHOLDER_COLOR.TEXT;
    }
  };
  const getIcon = (text) => {
    const placeholder = typeof text === "string" ? placeholderMap[text.trim()] : undefined;
    if (placeholder !== undefined) {
      return placeholder.icon;
    }
    else {
      if (isPlaceholder(text)){
        return PLACEHOLDER_ERROR_COLOR.ICON;
      } else if (type && type === "DATETIME"){
        return PLACEHOLDER_ERROR_COLOR.ICON;
      }
      else return PLACEHOLDER_COLOR.ICON;
    }
  };
    /*
  Basic function to extract label
   */
  const getLabel = (option) => {
    if (option.text) {
      return option.text;
    } else if (option.key) {
      return option.key;
    } else {
      return option;
    }
  };

  /**
   * This function handles the case where user exits the input after clicking out.
   * @param e
   */
  const handleOnInputWrapperBlur = (e) => {
    const newChip = e.target.value;
    if (newChip.length > 0) {
      handleAddChipNoPlaceholder(newChip);
    }
  };

  return (
    <NoSsr>
      <div>
        <div {...getRootProps()} onFocus={onFocus}>
          <InputWrapper
            ref={setAnchorEl}
            className={focused ? "focused" : ""}
            onBlur={handleOnInputWrapperBlur}
          >
            {inputValue.map((option, index) => (
              <StyledTag
                key={option.text}
                borderColor={getBorderColor(option)}
                backgroundColor={getBackgroundColor(option)}
                textColor={getTextColor(option)}
                icon={getIcon(option)}
                color
                label={getLabel(option)}
                handleDelete={() => {
                  handleDelete(index);
                }}
                {...getTagProps({ index })}
              />
            ))}
            <input
              placeholder={inputValue.length === 0 && placeholder}
              {...getInputProps()}
              />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li key={option.text} {...getOptionProps({ option, index })}>
                <StyledTag
                    key={option.text}
                    borderColor={getBorderColor(option.text)}
                    backgroundColor={getBackgroundColor(option.text)}
                    textColor={getTextColor(option.text)}
                    icon={getIcon(option.text)}
                    color
                    label={getLabel(option.text)}
                    handleDelete={() => {
                      handleDelete(index);
                    }}

                />
              </li>
            ))}
          </Listbox>
        ) : null}
      </div>
    </NoSsr>
  );
};

ChipInputHook.propTypes = {
  onChange: Function,
};

export default ChipInputHook;
