import React, { useEffect, useState } from 'react';
import { send_request } from "../../utils/Request";
import { CircularProgress, Skeleton } from "@mui/material";
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import { createStyles, makeStyles } from "@mui/styles";

// Material UI
import Badge from "@mui/material/Badge";
import { AssignmentTurnedIn } from "@mui/icons-material";

function TaskNotificationsBadge(props) {

	const useStyles = makeStyles((theme) => ({
		badge: {
		  fontSize: 10,
		  right: 3,
		  top: 1,
		  border: `2px solid ${theme.palette.background.paper}`,
		  padding: '0 4px',
		}
	  }));

	const classes = useStyles();

	const [incompleteTaskCount, setIncompleteTaskCount] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Make a call to fetch the incomplete task count
		send_request("task/task/get_incomplete_task_count")
		.then((res) => {
			setIncompleteTaskCount(res.data);
			setLoading(false);
		})
		.catch((err) => {
			console.log(err);
			setLoading(false);
		})

	}, [])


  	let badgeComponent = (<Badge size="sm" badgeContent={incompleteTaskCount} color="secondary" classes={{ badge: classes.badge }}>
							<InboxOutlinedIcon className="material-icons"/>
						  </Badge>);

	return loading ? (<Skeleton height={25} width={30} variant={"rectangular"} style={{ borderRadius: "4px", background: "#E8E8E8"}} />) : (badgeComponent);
}

export default TaskNotificationsBadge;
