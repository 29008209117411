import React from "react";
import { Grid, MenuItem, Select } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormRequiredStore from "../FormConditionStores/FormRequiredStore";
import ItemCondition from "./ItemConditional";
import ErrorIcon from '@mui/icons-material/Error';
import FlakyIcon from '@mui/icons-material/Flaky';
class ItemStatementCondition extends React.Component {
  render() {
    const { indexStatement, condition, AnswerList } = this.props;
    const { operators } = condition;

    return (
      <Grid container xs={12} direction={"column"} spacing={2}>
        {operators && operators.length > 0 ? (
          <React.Fragment>
            {operators &&
              operators.map((con, index) => {
                if (AnswerList !== undefined) {
                  return (
                    <Grid container item xs={12} direction={"row"}>
                      <ItemCondition
                        key={index}
                        indexCondition={index}
                        indexStatement={indexStatement}
                        FormStore={FormRequiredStore}
                        condition={con}
                        ConditionList={AnswerList.ConditionList[index]}
                      />
                    </Grid>
                  );
                }
              })}
            <Grid xs={12} container item direction={"row"}>
              <Grid
                xs={5}
                alignItems={"center"}
                className={"text-button"}
                direction={"row"}
                container
                item
                onClick={() => FormRequiredStore.addCondition(indexStatement)}
              >
                <AddIcon /> Add AND/OR condition
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              item
              direction={"row"}
              alignItems={"center"}
            >
              <Grid
                xs={1}
                className="d-flex-center"
                item
                style={{
                  fontWeight: 600
                }}
              >
                {" "}
                THEN
              </Grid>
              <Grid xs={5} item>
                <Select
                  style={{
                    width: "100%",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "4px",
                    height: "36px",
                    color: "#868686"
                  }}
                  name="selectFunc"
                  // displayEmpty
                  value={condition.then.Statement.content}
                  onChange={(event) => {
                    FormRequiredStore.setThenContent(
                      event.target.value,
                      indexStatement
                    );
                  }}
                  disableUnderline
                  variant="standard"
                  className="dropdown-mat selectStandard thenMargin"
                  displayEmpty
                >

                  <MenuItem value={true}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <ErrorIcon sx={{color: "black"}}/>
                      <div style={{marginLeft: "5px"}}>
                        {" "}
                        This question must be answered{" "}
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem value={false}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <FlakyIcon sx={{color: "black"}}/>
                      <div style={{marginLeft: "5px"}}>
                        This question is optional
                      </div>
                    </div>
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid container item xs={12} direction={"row"}>
              <Grid
                xs={5}
                container
                direction={"row"}
                item
                className="text-button"
                onClick={() => FormRequiredStore.addStatement()}
              >
                <AddIcon /> Add ELSE IF statement
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              item
              direction={"row"}
              alignItems={"center"}
            >
              <Grid
                xs={1}
                className="d-flex-center"
                item
                style={{
                  fontWeight: 600
                }}
              >
                {" "}
                ELSE
              </Grid>

              <Grid xs={5} item>
                <Select
                  style={{
                    width: "100%",
                    backgroundColor: "#F8F8F8",
                    borderRadius: "4px",
                    height: "36px",
                    color: "#868686"
                  }}
                  name="selectFunc"
                  // displayEmpty
                  value={condition.then.Statement.content}
                  onChange={(event) => {
                    FormRequiredStore.setThenContent(
                      event.target.value,
                      indexStatement
                    );
                  }}
                  disableUnderline
                  variant="standard"
                  labelId="demo-simple-select-outlined-label"
                  className="dropdown-mat selectStandard"
                  displayEmpty
                >
                  <MenuItem value={true}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <ErrorIcon sx={{color: "black"}}/>
                      <div style={{marginLeft: "5px"}}>
                        {" "}
                        This question must be answered{" "}
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem value={false}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <FlakyIcon sx={{color: "black"}}/>
                      <div style={{marginLeft: "5px"}}>
                        This question is optional
                      </div>
                    </div>
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

export default ItemStatementCondition;
