import { Alert, Autocomplete, Grid, Skeleton, TextField } from "@mui/material";
import { styled } from "@mui/system";

// Icons
import EastIcon from "@mui/icons-material/East";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// CHANGE PLACEHOLDER INPUTS
export const RenamedValueInput = styled(TextField)({
    "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "rgba(245, 245, 245, 1)",
        color: "#000",
    },
});

export const OpenInNew = styled(OpenInNewIcon)(({ margin }) => ({
    width: "14px",
    height: "auto",
    marginRight: "6px",
}));

export const BoldText = styled("span")({
    fontWeight: "bold",
});

export const SourceItem = styled("div")({
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: "rgba(33, 150, 243, 1)",
    "&:hover": {
        cursor: "pointer",
    },
});

// FIX BROKEN PLACEHOLDERS
export const TopText = styled("span")({
    fontSize: "15px",
    lineHeight: "19px",
    fontWeight: 400,
});

// MAPPING TABLE
export const TopTitle = styled("span")({
    fontWeight: "bold",
});

// REMAP ROW
export const BrokenPlaceholder = styled("div")({
    border: "1px solid rgba(225, 0, 0, 1)",
    backgroundColor: "#FFF",
    color: "rgba(225, 0, 0, 1)",
    padding: "1px 4px",
    borderRadius: "4px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

export const Right = styled(EastIcon)({
    height: "20px",
    width: "auto",
    fontWeight: "bold",
});

export const CenterGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

// RENAME PLACEHOLDERS DIALOG
export const DropdownLabel = styled("div")({
    fontSize: "13px",
    marginBottom: "8px",
    marginTop: "4px",
    lineHeight: "19px",
});

// RENAME SOURCE AND REFERENCES
export const Label = styled("div")({
    fontSize: "14px",
    marginBottom: "8px",
    lineHeight: "19px",
});

export const StyledAlert = styled(Alert)(({ borderColor, bgColor }) => ({
    border: `1px solid ${borderColor}`,
    background: bgColor,
    color: "black",
    fontSize: "14px",
}));

export const StyledChip = styled("div")(({ backgroundColor, borderColor,icon }) => ({
    backgroundColor: backgroundColor,
    border: `1px solid ${borderColor}`,
    borderRadius: "4px",
    padding: "4px 4px 4px 38px",
    width: "fit-content",
    '&::before': {
        content: '""',
        background: `url(${icon})`,
        backgroundSize: 'cover',
        position: 'absolute',
        width: '20px',
        height: '20px',
        marginLeft: '-30px'
    }
}));

export const StyledAutocomplete = styled(Autocomplete)({
    backgroundColor: "#FFF",
});

export const StyledSkeleton = styled(Skeleton)(({ width, height, margin, borderRadius }) => ({
    borderRadius: borderRadius ? borderRadius : "4px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

// SHOW PH REPLACE //
export const Arrow = styled(ArrowDownwardIcon)({
    height: "20px",
    width: "auto",
});

export const Error = styled(ReportGmailerrorredIcon)({
    height: "20px",
    width: "auto",
    color: "rgba(225, 0, 0, 1)",
});

export const CustomGrid = styled(Grid)(({ margin, horizontal, vertical }) => ({
    margin: margin,
    ...(horizontal && vertical && { display: "flex", alignItems: vertical, justifyContent: horizontal }),
}));

export const ReplacedItem = styled(Grid)(({ showLine }) => ({
    backgroundColor: "rgba(245, 245, 245, 1)",
    padding: "12px 8px",
    borderRadius: "4px",
    textDecoration: showLine ? "line-through" : "none",
}));

export const ReferencedText = styled("span")({
    fontSize: "13px",
    lineHeight: "16px",
    color: "rgba(134, 134, 134, 1)",
});
