import React, { useState, useEffect } from "react";

// MUI
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { withStyles } from "@mui/styles";

import PropTypes from "prop-types";
import SendIcon from "@mui/icons-material/Send";
// Util
import QuestionAnswer from "../StaticMessages/QuestionAnswer";
import "../../../util/chatMessageAnimations.css";

const styles = () => ({
  gpt: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
  },
  gridItem: {
    marginBottom: "16px",
  },
  submitButtonContainer: {
    width: '100%',
    marginTop: '16px',
  },
});

const checkIfQuestionAnswered = (ansList) => ansList.some(ans => ans.length > 0);

const SubmitButton = styled(Button)(({ isAnswered }) => ({
  background: isAnswered ? "#944BA8" : "#C0C0C0",
  color: "#FFF",
  border: `1px solid ${isAnswered ? "#944BA8" : "#C0C0C0"}`,
  padding: "4px 8px",
  textTransform: "none",
  width: '100%',
  "&:hover": {
    background: isAnswered ? "#7D3E8C" : "#A8A8A8",
  },
}));

function QuestionAnswerMessage({
  content,
  historyAnswers,
  prebuildQuestionAnswers,
  setPrebuildQuestionAnswers,
  messages,
  classes,
  dismissContainerRef,
  generateWithQuesAns,
}) {
  const [disabled, setDisabled] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (disabled) return; // If these are disabled, don't do anything

    // Take a look at the historyAnswers
    // & if they're provided, disable editing
    if (historyAnswers) {
      setDisabled(true);
      return;
    }

    // If it's the first load, skip the initial check of loading
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    setDisabled(true);
  }, [messages.length]);
  const isAnswered = checkIfQuestionAnswered(prebuildQuestionAnswers);

  return (
    <Grid className={`${classes.gpt} message-bubble`}>
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <b>❓ Questions</b>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          {content.comment ? (
            content.comment
          ) : (
            <span>
              I need some more information so I can provide my best response:
            </span>
          )}
        </Grid>
        <Grid item xs={12}>
          {content.questions && (
            <Grid container rowGap={2}>
              {content.questions.map((question, idx) => {
                return (
                  <QuestionAnswer
                    index={idx}
                    key={idx}
                    question={question}
                    disabled={disabled}
                    prebuildQuestionAnswers={prebuildQuestionAnswers}
                    setPrebuildQuestionAnswers={setPrebuildQuestionAnswers}
                    historyAnswers={historyAnswers}
                    dismissContainerRef={dismissContainerRef}
                  />
                );
              })}
            </Grid>
          )}
          <Grid
            container
            className={classes.submitButtonContainer}
          >
            <SubmitButton
              isAnswered={isAnswered}
              disabled={!isAnswered}
              onClick={generateWithQuesAns}
              className={classes.textButton}
              startIcon={<SendIcon fontSize="small" />}
            >
              Submit
            </SubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

QuestionAnswerMessage.propTypes = {
  content: PropTypes.object.isRequired,
  historyAnswers: PropTypes.array,
  prebuildQuestionAnswers: PropTypes.array.isRequired,
  setPrebuildQuestionAnswers: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  dismissContainerRef: PropTypes.object,
};

export default withStyles(styles)(QuestionAnswerMessage);
