import FormatSizeIcon from '@mui/icons-material/FormatSize';
import PinIcon from '@mui/icons-material/Pin';
import DataArrayIcon from '@mui/icons-material/DataArray';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HtmlIcon from '@mui/icons-material/Html';
import ImageIcon from '@mui/icons-material/Image';

const getThemeColors = (type, data_length) => {
  if (type == "text") {
    return { default: "#7AC0F8", hover: "#D7ECFD", icon: "/svgs/format_size_icon.svg"};
  } else if (type == "number") {
    return { default: "#ED6696", hover: "#FAD1DF",icon: "/svgs/pin_icon.svg"};
  } else if (type == "list") {
    return { default: "#55A77A", hover: "#CCE5D7",icon: "/svgs/data_array_icon.svg"};
  } else if (type == "image") {
    return { default: "#FFB74D", hover: "#FFE9CA",icon: "/svgs/format_size_icon.svg"};
  } else if (type == "datetime") {
    return { default: "#B782EC", hover: "#F1E6FB",icon: "/svgs/calendar_month.svg"};
  } else if (type == "form_placeholder") {
    return { default: "#2670FF", hover: "#D9E7FD",icon: "/svgs/form_placeholder_icon.svg" };
  } else if(type == "html")
  {
    return {default:"#E44D26",hover:"rgba(228, 77, 38, 0.15)",icon: "/svgs/html_icon.svg"}
  }

  return { default: "#7AC0F8", hover: "#D7ECFD" ,icon: "/svgs/format_size_icon.svg"};
};

export default getThemeColors;
