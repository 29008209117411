import React from "react";
import { Grid, Fade, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import ContextChips from "./ContextChips.jsx";
import "./initialMessages.css"; // Import the CSS file
import { Storage, VpnKey } from "@mui/icons-material";

const styles = () => ({
  gpt: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column"
  },
  select: {
    minWidth: "250px",
    background: "#FFF"
  }
});

const WorkflowBuilderMessage = ({ classes }) => {
  const contextChips = [
    {
      label: "Active and connected databases",
      tooltip: "Any databases actively being used by a component in this workflow, or connected but not actively used. I will try to reuse an existing database before creating a new one if possible.",
      icon: <Storage fontSize="small" />
    },
    {
      label: "Secrets and credentials",
      tooltip: "I will be aware of any secret and credential placeholders available to this workflow or component but I will not know their values. I can choose to use these secrets and credentials if appropriate.",
      icon: <VpnKey fontSize="small" />
    }
  ];

  return (
    <Fade timeout={1000} in={true}>
      <div className={classes.gpt}>
        <Grid container spacing={3}>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <span className="bold">
                🔧 Build a new workflow
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              To get started, describe the workflow you would like me to build.
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <b>💡 Tip</b>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              Include as much detail as possible if you want me to follow a
              specific structure or requirements. You can also provide numbered
              points to follow a specific sequence e.g. "1. Do this 2. then do
              this".
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <b>ℹ️ Context</b>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              I will have access to the following context when building the workflow:
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <ContextChips chips={contextChips} />
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default withStyles(styles)(WorkflowBuilderMessage);