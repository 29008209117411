import React, { Component } from "react";
import Button from "@mui/material/Button";
import DropZone from "vardogyir-ui/DropZone";
import { observer, inject } from "mobx-react";
import { withStyles } from "@mui/styles";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import { autorun, toJS, reaction, trace } from "mobx";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import { send_component_save_request, send_request } from "../../utils/Request";

import KnowledgeStore from "./KnowledgeStore";
import styles from "./components/KnowledgeStyle";
import ItemKnowledge from "./components/ItemKnowledge";
import { getCurrentTime } from "../../utils/getCurrentTime";
import ErrorIcon from "@mui/icons-material/Error";
import projectStore from '../ProjectCanvas/ProjectStore';
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
const ExcelRenderer = (file) => {
  var allowedExtensions = /(\.xlsx)$/i;
  if (allowedExtensions.exec(file.path)) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      var rABS = !!reader.readAsBinaryString;
      reader.onload = function (e) {
        /* Parse data */
        var bstr = e.target.result;
        var wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

        /* Get first worksheet */
        var wsname = wb.SheetNames[0];
        var ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        var json = XLSX.utils.sheet_to_json(ws, { header: 1 });
        //assume first row
        let titleRow = json[0];

        resolve(json);
      };
      if (file && rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    });
  } else {
    let errorMessage = "Incorrect format";
    return errorMessage;
  }
};

class KnowledgeTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      placeholders: [],
      fileName: null,
      file: null,
      listPlaceholders: [],
      error: false,
      loading:false,
      errorUploadMessage: ""
    };
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({loading:true})
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then((response) => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              KnowledgeStore.setComponentData(components[0]);
              this.props.onComponentNameChanged(KnowledgeStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(KnowledgeStore.lastModified);
              this.setState({loading:false})
            }
          }
          this.setState({loading:false})
          this.props.setChildTemplateLoaded(true);
        })
        .catch((err) => {
          throw Error(err.message);
        });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveKnowledgeTemplate();
    this.reactToPlaceHolder();
  }

  saveKnowledgeTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {

      if(this.state.error){
        this.props.showError();
        return;
      }

      let lastModified = getCurrentTime();
      let data = {
        componentData: {
          data: KnowledgeStore.template.data,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified,
        },
        componentId: this.props.component_id,
        type: KnowledgeStore.template.type,
        description: this.props.ComponentDescription.value
      };
      send_component_save_request("component-service/knowledge-bank/data", data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key } = placeholder["selected"];
      this.insertPlaceholder(style.default, key, style);
    }
  );

  insertPlaceholder = (label, text, style) => {
    let { placeholders } = KnowledgeStore;
    placeholders.push({ label, text, style });

    KnowledgeStore.setPlaceholders(placeholders);
  };

  onFilesDrop = async (files) => {
    this.setState({
      file: null
    })
    let resp = await ExcelRenderer(files[0]);
    if (typeof resp === "string") {
      this.setState({
        errorUploadMessage: resp
      });
    }else {
      let error = false

      resp.map((value,index) => {
        if (!value[0]) {
          this.setState({
            errorUploadMessage: "We’ve detected some special characters in the first column. Keys can only contain letters and numbers, and cannot contain any special characters (such as -, _, %, $ # etc).  Please remove these and upload your spreadsheet again. "
          });
          error = true
        } else {

          if(/^[a-zA-Z0-9]*$/.test(value[0]) == false) {
            this.setState({
              errorUploadMessage: "We’ve detected some special characters in the first column. Keys can only contain letters and numbers, and cannot contain any special characters (such as -, _, %, $ # etc).  Please remove these and upload your spreadsheet again. "
            });
            error = true
          }
          for (let i in resp) {
            if (value[0] == resp[i][0] && i != index) {
              this.setState({
                errorUploadMessage: "We’ve detected some special characters in the first column. Keys can only contain letters and numbers, and cannot contain any special characters (such as -, _, %, $ # etc).  Please remove these and upload your spreadsheet again. "
              });
              error = true
              break;
            }
          }
        }
      })
      if (error == false){
        this.setState({ file: files[0],errorUploadMessage: "" });
      }
    }
  };

  // onFilesDrop = (files) => {
  //   this.setState({ file: files[0] });
  // };
  handingExcelToListText = () => {
    const { file } = this.state;
    if (file) {
      var formData = new FormData();
      formData.append("file", file);
      send_request("component-service/knowledge-bank/upload-excel", formData, "", "POST")
        .then((res) => {
          if (res.data) {
            KnowledgeStore.setDataFromExcel(res.data);
            this.setState({ open: false });
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  handleKeyChanges = error => {
    this.setState({ error })
  }


  render() {
    const { classes } = this.props;
    const { file, listPlaceholders } = this.state;
    const { data } = toJS(KnowledgeStore);
    if (this.state && !this.state.loading) {
    return (
      <div style={{ width: "100%", display: "grid" }}>
        {data.map((component, index) => (
          <ItemKnowledge
            key={component.keyRandom}
            component={component}
            index={index}
            availablePlaceholders={this.props.availablePlaceholders}
            handleKeyChanges={this.handleKeyChanges}
          />
        ))}
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={this.state.open}
          handleClose={() => {
            this.setState({ error: false });
          }}
        >
          <DialogTitle>
            <h5>Bulk upload content</h5>
          </DialogTitle>
          <div className={classes.dialogPaper}>
            <DialogContent className={classes.dialogContent}>
              <p className={classes.dialogText}>
                You can bulk import content into the content library by setting
                out the content in an excel (.xlsx) file. Each row of column A
                should contain the key values:
              </p>
              <div style={{background:"#F7E5E9",padding:8,marginBottom:16}}>
                <p className="err-text errorTextFile" style={{margin:0}}>
                  <ErrorIcon className="iconDiscard" />
                  <span>Note that keys can only contain letters and numbers, and cannot contain any special characters (such as -, _, %, $ # etc).  </span>
                </p>
              </div>
              <div>
                <img
                  className={classes.dialogImage}
                  src="/images/knowledge-excel-key.png"
                />
              </div>
              <p className={classes.dialogText}>
                The each row of column B should contain the corresponding
                content you want to import:
              </p>
              <div>
                <img
                  className={classes.dialogImage}
                  src="/images/knowledge-excel-content.png"
                />
              </div>
              <p className={classes.dialogText}>
                Once configured to these requirements, upload your .xlsx file
                below.
              </p>
              <div>
                <div className={classes.dropzone}>
                  <Dropzone
                    style={{ width: "100%" }}
                    multiple={false}
                    onDrop={(file) => this.onFilesDrop(file)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} style={{}}>
                          <DropZone />
                          <input type="file" {...getInputProps()} />
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div style={{ paddingTop: 8 }}>
                    {file && file.name && (
                      <Chip
                        style={{ backgroundColor: "rgba(33, 150, 243, 0.6)" }}
                        label={<span style={{ margin: 8 }}> {file.name} </span>}
                        onDelete={() => this.setState({ file: null })}
                        color="primary"
                      />
                    )}
                    {
                      this.state.errorUploadMessage && (
                      <p className="err-text errorTextFile">
                      <ErrorIcon className="iconDiscard" />
                      {
                        this.state.errorUploadMessage
                      }
                      </p>
                      )
                    }
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "24px 0px 24px 0px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => this.setState({ open: false })}
                  variant={"outlined"}  
                  color={"info"}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handingExcelToListText}
                  style={{ marginLeft: 16 }}
                  variant={"contained"} color={"primary"} 
                  disabled={file ? false : true}
                >
                  Upload
                </Button>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        <div className={classes.wrapFlexCenter}>
          <div
            className={classes.textContentCenter}
            onClick={() => KnowledgeStore.addComponentData()}
          >
            <AddIcon /> Add a content block
          </div>
        </div>
        <div className={classes.wrapFlexCenter}>
          <div
            className={classes.textContentCenter}
            onClick={() => this.setState({ open: true })}
          >
            <AddIcon /> Bulk upload content from a file
          </div>
        </div>
      </div>
    );
  }else return <IndividualComponentLoader />;
};
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(KnowledgeTemplate))
);
