import { SUCCESS_MESSAGE } from "./StaticMessages/Success";
import {
  BASIC_LOADING_MESSAGE,
  INSERT_LOADING_MESSAGE
} from "./StaticMessages/Loading";
import { createSaveEvent } from "../../../../../../utils/createSaveEvent";
import {
  cleanArray,
  filterPropertiesBeforeSave
} from "../../util/NoteDiagramUtil";
import { getProposalMessage } from "./StaticMessages/Assistant";
import {
  GENERATE_COMPONENT,
  GENERATE_COMPONENT_VIA_EDIT
} from "../../AIBuilderQueries";

// Material UI
import { Button, Tooltip } from "@mui/material";
import { Check } from "@mui/icons-material";

/**
 * This component determines the path based on `sidebarState`.
 * 
 * `header` is a string that represents the current header text displayed in the UI.
 * It changes based on the value of `sidebarState`.
 * 
 * `sidebarState` can have one of the following values:
 * - "INITIAL": Indicates the initial state of the sidebar. 
 *              When `sidebarState` is "INITIAL", `header` is set to "Start a new AI Session".
 * - "STRATEGY": Represents a state where strategic discussions or planning are taking place. 
 *               In this state, `header` is set to "Strategy Session".
 * - "DISCOVERY": Denotes a phase of process discovery and design. 
 *                Here, `header` changes to "Process Discovery and Design".
 * - "SECTION": Implies that the user is in the process of building a specific section of a workflow. 
 *              The `header` for this state is "Build a Workflow Section".
 * - "WORKFLOW": Indicates that the user is in a workflow building phase, 
 *              typically involving more interactive or construction-oriented tasks. 
 *              The `header` is set to "AI Workflow Builder" in this state.
 * - "WORKFLOW_EDITOR": Indicates that the user is in a workflow editing phase, 
 *              typically involving more interactive or construction-oriented tasks. 
 *              The `header` is set to "Edit this workflow" in this state.
 * 
 * 
 * These states help in managing the UI elements dynamically based on the user's navigation or interaction within the application.
 */

export const runChatStateInitialiser = (
  sidebarState,
  setHeader,
  chatHistoryLoading,
  setMessages,
  props,
  setCanvasLocked,
) => {
  switch (sidebarState) {
    case "INITIAL":
      runInitialState(setHeader);
      break;
    case "STRATEGY":
      runStrategyState(
        setHeader,
        chatHistoryLoading,
        setMessages,
        props,
        setCanvasLocked
      );
      break;
    case "DISCOVERY":
      runDiscoveryState(
        setHeader,
        chatHistoryLoading,
        setMessages,
        props,
        setCanvasLocked
      );
      break;
    case "SECTION":
      runSectionState(setHeader);
      break;
    case "WORKFLOW":
      runBuilderState(
        setHeader,
        chatHistoryLoading,
        setMessages,
        props,
        setCanvasLocked
      );
      break;
    case "WORKFLOW_EDITOR":
      runWorkflowEditorState(
        setHeader,
        chatHistoryLoading,
        setMessages,
        props,
        setCanvasLocked
      );
      break;  
    case "EDIT":
      runComponentEditState(
        sidebarState,
        setHeader,
        chatHistoryLoading,
        setMessages,
        props
      );
      break;
    case "BUILD":
      runComponentBuildState(
        sidebarState,
        setHeader,
        chatHistoryLoading,
        setMessages,
        props
      );
      break;
    case "INITIAL_COMP_BUILD":
      break;
    default:
      setHeader("Start a new AI Session");
  }
};

const runInitialState = setHeader => {
  //update header
  setHeader("Start a new AI Session");
};

const runComponentEditState = (
  sidebarState,
  setSideBarState,
  chatHistoryLoading,
  setMessages,
  props
) => {
  if (chatHistoryLoading) return;
  //add an initial message
  const message = {
    role: "editComponentMessage",
    showTimer: false,
    content: "",
    componentType: props.componentType
  };
  //update chat array
  setMessages([message]);
  //lock the canvas to prevent updates
};

const runComponentBuildState = (
  sidebarState,
  setSideBarState,
  chatHistoryLoading,
  setMessages,
  props
) => {

  if (chatHistoryLoading) return;
  //add an initial message
  const message = {
    role: "buildComponentMessage",
    showTimer: false,
    content: "",
    componentType: props.componentType
  };
  //update chat array
  setMessages([message]);
};

const runStrategyState = (
  setHeader,
  chatHistoryLoading,
  setMessages,
  props,
  setCanvasLocked,
) => {
  //update header
  setHeader("Strategy Session");
  //if this is chat session history do not add a new message
  if (chatHistoryLoading) return;
  //add an initial message
  const message = {
    role: "strategy",
    showTimer: false,
    content: ""
  };
  const suggestions ={
    role:"suggestions",
    showTimer:false,
    content:"",
    sidebarState:"STRATEGY"
  }
  //update chat array
  setMessages([message,suggestions]);

  //lock the canvas to prevent updates
  setCanvasLocked(false);
};

const runDiscoveryState = (
  setHeader,
  chatHistoryLoading,
  setMessages,
  props,
  setCanvasLocked
) => {
  //update header
  setHeader("Process Discovery and Design");
  //if this is chat session history do not add a new message
  if (chatHistoryLoading) return;
  //add an initial message
  const message = {
    role: "processDiscovery",
    showTimer: false,
    content: ""
  };
  const suggestions ={
    role:"suggestions",
    showTimer:false,
    content:"",
    sidebarState:"DISCOVERY"
  }
  //update chat array
  setMessages([message,suggestions]);
  //lock the canvas to prevent updates
  setCanvasLocked(false);
};

const runSectionState = setHeader => {
  setHeader("Build a Workflow Section");
};

const runBuilderState = (
  setHeader,
  chatHistoryLoading,
  setMessages,
  props,
  setCanvasLocked,
) => {

  //update header
  setHeader("AI Workflow Builder");
  //if this is chat session history do not add a new message
  if (chatHistoryLoading) return;
  //add an initial message
  const message = {
    role: "dynamicMessage",
    showTimer: false,
    content: ""
  };
  const suggestions ={
    role:"suggestions",
    showTimer:false,
    content:"",
    sidebarState:"WORKFLOW"
  }
  //update chat array
  setMessages([message,suggestions]);
  //lock the canvas to prevent updates
  setCanvasLocked(false);
};

const runWorkflowEditorState = (
  setHeader,
  chatHistoryLoading,
  setMessages,
  props,
  setCanvasLocked
) => {
  //update header
  setHeader("Edit this workflow");
  //if this is chat session history do not add a new message
  if (chatHistoryLoading) return;
  //add an initial message
  const message = {
    role: "workflowEditor",
    showTimer: false,
    content: ""
  };
  const suggestions ={
    role:"suggestions",
    showTimer:false,
    content:"",
    sidebarState:"WORKFLOW_EDITOR"
  }
  //update chat array
  setMessages([message,suggestions]);
  //lock the canvas to prevent updates
  setCanvasLocked(false);
};

export const determineInitialMessage = sidebarState => {
  switch (sidebarState) {
    case "STRATEGY":
      return {
        role: "strategy",
        showTimer: false,
        content: ""
      };
    case "DISCOVERY":
      return {
        role: "processDiscovery",
        showTimer: false,
        content: ""
      };
    default:
      return {
        role: "dynamicMessage",
        showTimer: false,
        content: ""
      };
  }
};

export const determineInsertLoadingMessageBasedOnState = (
  sidebarState,
  isStreaming
) => {
  switch (sidebarState) {
    case "WORKFLOW_EDITOR":
      return null;
    case "STRATEGY":
    case "DISCOVERY":
      return { role: "insertLoadingMessage", isStreaming: isStreaming };
    default:
      return INSERT_LOADING_MESSAGE;
  }
};

export const determineSaveEventBasedOnState = (
  sidebarState,
  projectId,
  elements,
  sessionId,
  threadId,
  newComponents,
  viewport,
  editChanges,
  username
) => {
  let eventData = {};
  switch (sidebarState) {
    case "STRATEGY":
    case "DISCOVERY": {
      eventData = {
        projectId: projectId,
        notesSection: extractNewNotes(elements),
        notes: [],
        sessionId: sessionId,
        threadId: threadId,
        sidebarState: sidebarState
      };
      //init event
      return createSaveEvent(
        "ADD_MULTIPLE_NOTES",
        projectId,
        viewport,
        elements,
        eventData
      );
    }
    case "WORKFLOW_EDITOR": {
      eventData = {
        projectId: projectId,
        sessionId: sessionId,
        threadId: threadId,
        aiEditChangesDTO: editChanges,
        sidebarState: sidebarState
      };
      //init event
      return createSaveEvent(
        "PERFORM_AI_WORKFLOW_EDIT",
        projectId,
        viewport,
        elements,
        eventData
      );
    }
    default: {
      eventData = {
        projectId: projectId,
        diagramSection: newComponents ? newComponents : [],
        notes: [],
        sessionId: sessionId,
        threadId: threadId,
        sidebarState: sidebarState
      };
      return createSaveEvent(
        "ADD_SECTION",
        projectId,
        viewport,
        elements,
        eventData
      );
    }
  }
};

export const determineProceedToComponentBuild = (
  sidebarState,
  arr,
  projectStore,
  newComponents,
) => {
  switch (sidebarState) {
    case "WORKFLOW_EDITOR":
    case "STRATEGY":
    case "DISCOVERY":
      return;
    default:
      // arr.push({ role: "componentBuildInstruction", content: "" });
      projectStore.components = [projectStore.components, ...newComponents];
  }
};

export const finiliseDiagramInsert = (
  sidebarState,
  arr,
  setShowConclude,
  setPrebuildQuestions,
  setPrebuildProposal,
  setIsPrebuild,
  setShowBuildButtons
) => {
  switch (sidebarState) {
    case "STRATEGY":
    case "DISCOVERY":
      {
        let lastElement = arr.pop();
        lastElement.isStreaming = false;
        arr.push(lastElement);
        //show conclude button
        setShowConclude(true);
        //clear out prebuild
        setPrebuildQuestions("");
        setPrebuildProposal("");
        setIsPrebuild(true);
        //show build buttons
        setShowBuildButtons(true);
      }
      break;
    default:
      arr.push(SUCCESS_MESSAGE);
  }
};

export const extractNewNotes = elementsArray => {
  if (!Array.isArray(elementsArray)) {
    // Return an empty array or handle the error as you see fit
    return [];
  }

  let result = elementsArray.filter(item => {
    // Check if item is an object and then check for isTemp properties
    return item && (item.isTemp || (item.data && item.data.isTemp));
  });

  return cleanArray(filterPropertiesBeforeSave(result));
};

export const determinePrebuildFlow = (
  sidebarState,
  arr,
  data,
  setLoading,
  setOpenPrebuildPreview,
  setMermaidJSContent,
  diagramDTO,
  messages,
  setMessages
) => {
  switch (sidebarState) {
    case "WORKFLOW_EDITOR":
      arr.push({
        role: "streamingMessage",
        showAnimation: true,
        sidebarState: sidebarState
      });
      return;
    case "STRATEGY":
    case "DISCOVERY":
      arr.push({
        role: "streamingMessage",
        showAnimation: true,
        sidebarState: sidebarState
      });
      return;
    default:
      // display proposal
      arr.push(
        getProposalMessage(
          data,
          setLoading,
          setOpenPrebuildPreview,
          setMermaidJSContent,
          diagramDTO,
          null,
          messages,
          setMessages
        )
      ); // Add proposal message
      // arr.push(BASIC_LOADING_MESSAGE);
  }
};

export const fetchCorrectButtonWhenProposalDisplayed = (sidebarState, classes, loading, insert, handleBuildWithProposal, prebuildProposal) => {


  let tooltipTitle = "";
  let button = <></>;
  let isExplanation = false;

  switch (sidebarState) {
      case "STRATEGY":
      case "DISCOVERY":
          button = (
              <Button disabled={loading} className={classes.buildButton} variant="contained" startIcon={<Check />} onClick={() => insert()}>
                  Insert and save
              </Button>
          );
          tooltipTitle = "Accept the given proposal and build your workflow";
          break;
      case "WORKFLOW_EDITOR":
        let dataProposal;
        if (prebuildProposal.editChangesDTO) {
          dataProposal = prebuildProposal;
        } else {
          dataProposal = JSON.parse(prebuildProposal);
        }

        isExplanation = dataProposal?.action === "explain" || dataProposal?.editChangesDTO?.action === "explain";
        // Conditionally render the button based on isExplanation
        if (!isExplanation) {
          button = (
              <Button
                  disabled={loading}
                  className={classes.buildButton}
                  variant="contained"
                  startIcon={<Check />}
                  onClick={() => insert()}
              >
                Confirm and apply changes
              </Button>
          );
          tooltipTitle = "Confirm and apply these changes to your workflow";
        }
        break;
      default:
        const data = JSON.parse(prebuildProposal);
        isExplanation= data && data.action && data.action==="explain"
        if (!isExplanation) {
          button = (
              <Button disabled={loading} className={classes.buildButton} variant="contained" startIcon={<Check/>}
                      onClick={() => handleBuildWithProposal(prebuildProposal)}>
                Accept proposal
              </Button>
          );
          tooltipTitle = "Accept the given proposal and build your workflow";
        }
          break;
  }

  return (
      <Tooltip title={tooltipTitle} placement="top">
          {button}
      </Tooltip>
  );
}

export const determineAIEventSource = sidebarState => {
  switch (sidebarState) {
    case "STRATEGY":
      return "STRATEGY_BUILDER";
    case "DISCOVERY":
      return "PROCESS_DISCOVERY_BUILDER";
    case "WORKFLOW_EDITOR":
      return "WORKFLOW_EDITOR";
    default:
      return "WORKFLOW_BUILDER";
  }
};

export const findSidebarStateWithNotesBuilderEvent = arr => {
  if (arr.some(obj => obj.aiEventSource === "PROCESS_DISCOVERY_BUILDER")) {
    return "DISCOVERY";
  } else if (arr.some(obj => obj.aiEventSource === "STRATEGY_BUILDER")) {
    return "STRATEGY";
  }else if (arr.some(obj => obj.aiEventSource === "WORKFLOW_EDITOR")) {
    return "WORKFLOW_EDITOR";
  }
  return "WORKFLOW";
};

export const determineBuildAllMessage = (sidebarState) => {
  let content = "";

  switch (sidebarState) {
    case "WORKFLOW_EDITOR":
      content = "Finish setting up for me";
      break;
  
    default:
      content = "Build all components now";
      break;
  }

  return {
    role: "user",
    showTimer: false,
    content: content
  };
}

export const determineUserClickedInsertMessage = sidebarState => {
  let content = "";
  switch (sidebarState) {
    case "WORKFLOW_EDITOR":
      content = "Confirm and apply changes";
      break;
    case "STRATEGY":
    case "DISCOVERY":
      content = "Insert and save";
      break;
    default:
      content = "Insert workflow onto canvas";
  }
  return {
    role: "user",
    showTimer: false,
    content: content
  };
};
export const determineComponentBuildUrlBasedOnState = (
  aiComponentBuilderDto,
  sidebarState
) => {
  switch (sidebarState) {
    case "BUILD":
      return GENERATE_COMPONENT(aiComponentBuilderDto);
    case "EDIT":
      return GENERATE_COMPONENT_VIA_EDIT(aiComponentBuilderDto);
    default:
      return null; // will be null in case of INITIAL
  }
};
export const getResponseBasedOnState = (
  getResponseBasedOnState,
  sidebarState
) => {
  switch (sidebarState) {
    case "BUILD":
      return getResponseBasedOnState.data.buildViaComponentBuilder;
    case "EDIT":
      return getResponseBasedOnState.data.buildViaComponentEditor;
    default:
      return getResponseBasedOnState; // will be null in case of INITIAL
  }
};
export const determineSuccessMessage = chatState => {
  switch (chatState) {
    case "STRATEGY":
    case "DISCOVERY":
      SUCCESS_MESSAGE.content = "Process has been inserted and saved to the canvas."
      return SUCCESS_MESSAGE;
    default:
      return SUCCESS_MESSAGE;
  }
};
