import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './WorkflowProgressView.css'; // Add this import

function JsonTable({ data, depth = 0 }) {
    const [hoveredKey, setHoveredKey] = useState(null);

    if (!data || typeof data !== 'object') return JSON.stringify(data);

    const copyToClipboard = (key, value) => {
        const copyData = { [key]: value };
        navigator.clipboard.writeText(JSON.stringify(copyData, null, 2));
    };
    
    return (
        <TableContainer component={Paper} className={depth === 0 ? 'json-table-root' : ''}>
            <Table size={depth > 0 ? "small" : "medium"}>
                <TableBody>
                    {Object.entries(data).map(([key, value], index) => (
                        <TableRow key={`${depth}-${key}-${index}`}>
                            <TableCell className="json-table-key-cell">
                                <div 
                                    className="json-table-key-container"
                                    onMouseEnter={() => setHoveredKey(key)}
                                    onMouseLeave={() => setHoveredKey(null)}
                                >
                                    {key}
                                    {hoveredKey === key && (
                                        <Tooltip title="Copy key-value pair to clipboard">
                                            <IconButton 
                                                size="small" 
                                                onClick={() => copyToClipboard(key, value)} 
                                                className="json-table-copy-button"
                                            >
                                                <ContentCopyIcon className="json-table-copy-icon" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell>
                                {typeof value === 'object' && value !== null
                                    ? <JsonTable data={value} depth={depth + 1} />
                                    : JSON.stringify(value)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default JsonTable;
