import React, { useEffect, useState } from "react";
import RemapRow from "./RemapRow";

// MUI
import { Grid } from "@mui/material";
import { TopTitle } from "./RenameStyledComponents";

function MappingTable({ brokenPHs, availablePHs, isChanging, setIsChanging, values, setValues, projectId }) {
    useEffect(() => {
        let initValues = Array(availablePHs.length).fill({ key: "Ignore/Do not change" });
        setValues(initValues);
    }, []);

    const getTopTitles = () => {
        return (
            <>
                <Grid item xs>
                    <TopTitle>❌ Broken placeholder</TopTitle>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs>
                    <TopTitle>Remap to this placeholder</TopTitle>
                </Grid>
            </>
        );
    };

    const updateValue = (newValue, index) => {
        if (values.length <= index) return;
        setValues([...values.slice(0, index), newValue, ...values.slice(index + 1)]);
    };

    return (
        brokenPHs &&
        values && (
            <Grid container marginTop={4}>
                {getTopTitles()}
                <Grid container marginTop={1} rowSpacing={4}>
                    {brokenPHs.map((brokenPH, idx) => {
                        return <RemapRow idx={idx} brokenPH={brokenPH} availablePHs={availablePHs} currValue={values[idx]} updateValue={updateValue} isChanging={isChanging} projectId={projectId} />;
                    })}
                </Grid>
            </Grid>
        )
    );
}

export default MappingTable;
