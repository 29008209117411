import React, { Component } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import "./SlackStyle.css";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import TextEditor from "../TextEditor/APITextEditor";
import slackStore from "./SlackStore";
import { reaction, toJS } from "mobx";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { send_component_save_request, send_request } from "../../utils/Request";
import { inject, observer } from "mobx-react";
import { Grid } from "@mui/material";
import projectStore from "../ProjectCanvas/ProjectStore";
import {IndividualComponentLoader} from "../../utils/ComponentLoadingSkeleton";
class SlackTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      loginStatus: true,
      channel: "Webhook",
      disabledBtnStatus: true,
      channelWebhook: "getWebhookUrl",
      webhookStep: 1,
      focus: null,
      placeholders: [],
      loaded: false,
      description:null,
      status:null
    };
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then((response) => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              slackStore.setSlackTemplate(components[0]);
              this.props.onComponentNameChanged(slackStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(slackStore.lastModified);
            }
          }

          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
              this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
              this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
              slackStore.setTemplateData(this.props.aiComponentBuilderData.data);
              this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
              this.setState({ status: "AI_BUILD_GENERATED" });
          }

          this.props.setChildTemplateLoaded(true);
        })
        .catch((err) => {
          throw Error(err.message);
        })
        .finally(() => {
          this.setState({ loaded: true })
        });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveSlackTemplate();
    this.reactToPlaceHolder();
  }

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key } = placeholder["selected"];
      this.insertPlaceholder(style.default, key, style, this.state.focus);
    }
  );

  insertPlaceholder = (label, text, style, focus) => {
    if (focus === "editor") {
      this.setState((prevState) => ({
        placeholders: [
          ...prevState.placeholders,
          { label, text, style, focus },
        ],
      }));
    }
  };

  saveSlackTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      const lastModified = getCurrentTime();
      slackStore.setLastModified(lastModified);
      const template = toJS(slackStore.template);

      const data = {
        type: template.type,
        componentData: {
          name: this.props.ComponentName,
          data: template.data,
          lastModified: lastModified,
        },
        componentId: template.componentId
          ? template.componentId
          : this.props.component_id,
          description: this.props.ComponentDescription.value,
          status: this.state.status
      };
      send_component_save_request(`component-service/slack/data`, data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          if (response && response.status == 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            projectStore.savedComponent = true;
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  handleOnChange = (e) => {
    if (e.target.value) {
      this.setState({
        disabledBtnStatus: false,
      });
    } else {
      this.setState({
        disabledBtnStatus: true,
      });
    }
  };

  getStepContent = (body, endpoint_url) => {
    const stripHtml = (html) => {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    };

    return (
      <>
        <span style={{ display: "block", marginTop: "1rem", paddingBottom: "0px" }}>
          Follow{" "}
          <a
            href="https://docs.workflow86.com/docs/components/slack#1-getting-a-webhook-url-from-slack"
            style={{ color: "#2196F3" }}
            target="_blank"
          >
            these instructions
          </a>{" "}
          to get a Webhook URL from Slack. Copy and paste that URL below
        </span>
        <br />
        <TextEditor
          availablePlaceholders={this.props.availablePlaceholders}
          html={endpoint_url}
          onChange={(html) => {
            const strippedUrl = stripHtml(html);
            slackStore.setEndPointUrl(strippedUrl);
          }}
          controlStyles={{ display: "none" }}
          contentStyles={{
            border: "solid 1px",
            borderColor: "#cdcdcd",
            borderRadius: "5px",
            backgroundColor: "white",
            boxShadow: "none",
            padding: "10px",
            minHeight: "36px",
            margin: "16px 0 24px 0",
          }}
          placeholder="Paste your Webhook URL from Slack here"
          editorFocus={() => {
            this.setState({ focus: "url_editor" });
          }}
        />
        <span style={{ display: "block", marginTop: "1rem", paddingBottom: "16px" }}>
          Compose your Slack message
        </span>
        <TextEditor
          availablePlaceholders={this.props.availablePlaceholders}
          html={slackStore.data.body}
          onChange={(html) => {
            if (html !== body) {
              slackStore.setSlackBody(html);
            }
          }}
          controlStyles={{ display: "none" }}
          contentStyles={{
            border: "solid 1px",
            borderColor: "#cdcdcd",
            borderRadius: "5px",
            backgroundColor: "white",
            boxShadow: "none",
            padding: "10px",
            minHeight: "241px",
            margin: "16px 0 24px 0",
          }}
          placeholder={this.state.placeholders}
          editorFocus={() => {
            this.setState({ focus: "editor" });
          }}
        />
      </>
    );
  };

  render() {
    const { loaded } = this.state;
    const { body, endpoint_url } = toJS(slackStore).data;
    if (loaded) {
      return (
        <Grid container xs={12} direction={"column"}>
          <div className="item-wrapper">
            {this.getStepContent(body, endpoint_url)}
          </div>
        </Grid>
      );
    } else {
      return <IndividualComponentLoader />;
    }
  }
}
export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(SlackTemplate));