import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { confirmSignUp } from "@aws-amplify/auth";
import Snackbar from "@mui/material/Snackbar";
import { Navigate, useLocation } from "react-router-dom";

function ConfirmUser() {
  const [state, setState] = useState({
    openError: false,
    openConfirm: false,
    verify: false,
    confirmMessage: "",
    errorMessage: ""
  });

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userName = params.get("user_name");
    const code = params.get("confirmation_code");

    const confirmUser = async () => {
      try {
        await confirmSignUp({ username: userName, confirmationCode: code });
        setState(prevState => ({
          ...prevState,
          confirmMessage: "Your account has been verified",
          openConfirm: true
        }));
      } catch (error) {
        console.error("Error confirming sign up:", error);
        setState(prevState => ({
          ...prevState,
          errorMessage: "Error confirming your account",
          openError: true
        }));
      }
    };

    if (userName && code) {
      confirmUser();
    }
  }, [location.search]);

  const handleClose = () => {
    const { openError, openConfirm } = state;
    if (openError) {
      setState(prevState => ({ ...prevState, openError: false, verify: true }));
    } else if (openConfirm) {
      setState(prevState => ({ ...prevState, openConfirm: false, verify: true }));
    }
  };

  const { errorMessage, openError, openConfirm, confirmMessage, verify } = state;

  if (verify) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      {openError && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openError}
          autoHideDuration={6000}
          onClose={handleClose}
          key={"top" + "center"}
        >
          <Alert onClose={handleClose} severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {openConfirm && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openConfirm}
          autoHideDuration={6000}
          onClose={handleClose}
          key={"top" + "center"}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant={"filled"}
          >
            {confirmMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default ConfirmUser;
