import React, { useState, useEffect } from "react";
import APIResReqStore from "../../APIResReqStore";
import { observer } from "mobx-react";
import { MenuItem, Button, Grid, styled, Typography, Box, Autocomplete, TextField, Checkbox, FormControlLabel, Skeleton, Snackbar } from "@mui/material";
import {send_request} from "../../../../utils/Request";
import MentionEditor from "../../../TextEditor/MentionEditor";
import DynamicIcons from "../../../CredentialStore/DynamicIcons";
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import CurlTemplateAutoComplete from './CurlTemplateAutoComplete';
import WarningIcon from '@mui/icons-material/Warning';
import CodeIcon from '@mui/icons-material/Code';
import '../../APIResReqStyle.css'; // Make sure to import the CSS file
import { toJS } from "mobx";
import CurlTemplateSkeleton from "./CurlTemplateSkeleton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const GenerateCurlButton = styled(Button)({
  '&.Mui-disabled': {
    background: '#DCDCDC',
  },
  '&:not(.Mui-disabled)': {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  width: "100%",
  height: "34px",
  padding: "8px 24px 8px 24px",
  gap: "10px",
  borderRadius: "4px",
  textTransform: 'none',
});

const SelectLabel = styled(Typography)({
  paddingBottom: '8px',
  fontFamily: 'Open Sans !important',
  fontSize: '14px',
  fontWeight: '400 !important',
  lineHeight: '19.07px',
  textAlign: 'left',
});

const DescriptionLabel = styled(Typography)({
  paddingBottom: '8px',
  fontFamily: 'Open Sans !important',
  fontSize: '14px',
  fontWeight: '400 !important',
  lineHeight: '19.07px',
  textAlign: 'left',
  color: '#00000080',
});

const CredentialsMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  '& > *:first-of-type': {
    marginRight: '16px'
  }
});

const TitleText = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: '700 !important',
  lineHeight: '19.07px',
  textAlign: 'left',
  color: '#000000',
});

const ConnectAppButton = styled(Button)({
  height: '32px',
  padding: '6px 16px 6px 16px',
  borderRadius: '4px',
  backgroundColor: 'black',
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#333333',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minWidth: '117px',
  '& .MuiButton-startIcon': {
    marginLeft: '0',
    marginRight: '8px',
  },
});

const CredentialRefreshButton = styled(Button)({
  width: '72px',
  height: '32px',
  padding: '6px 16px',
  gap: '4px',
  borderRadius: '4px',
  border: '1px solid',
  opacity: 1,
  textTransform: 'none',
  marginLeft: '8px',
});

const ACTION_TEMPLATES_CREDENTIALS_URL = "credential-store/credential/user/action-templates";
const DEFAULT_SECRET_PLACEHOLDER = "${access_token.SECRET}";
const CREDENTIAL_PAGE_URL = '/credential_store';
const BLANK = '_blank';

const NO_CREDENTIAL_TEXT = "No credentials available";
const SELECT_ACTION_PLACEHOLDER = "Select Action";
const CURL_SETUP_MODE = "CURL";
const SELECT_APP_PLACEHOLDER = "Select App";
const SELECT_CREDENTIALS_PLACEHOLDER = "Select Credential";
const ON_FOCUS_INPUT = "rawResponsePlaceholderName";

const APP_DONT_EXIST = "The app you have selected previously is no longer available";
const FIELD_DONT_EXIST = "The action fields have changed, fields that were saved previously are now overwritten";
const ACTION_DONT_EXIST = "The action you have selected previously is no longer available";
const APP_COMING_SOON = "Apps coming soon to Workflow86";
const COMING_SOON_SUFFIX = "(Coming Soon)";

const StyledSnackbar = styled(Snackbar)({
  '& .MuiSnackbarContent-root': {
    minWidth: 'unset',
    backgroundColor: '#55A77A',
    padding: '6px 16px',
  }
});

const SnackbarContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  }
});

const FieldsLabel = styled(DescriptionLabel)({
  paddingBottom: '0px !important',
});

const AppIconCount = styled(Typography)({
  marginLeft: '4px'
});

const Divider = styled('li')({
  borderTop: '1px solid #ccc',
  margin: '8px 0',
  padding: 0
});

const DividerHeader = styled('li')({
  padding: '8px 16px',
  color: '#666666',
  fontSize: '14px',
  fontWeight: 500
});

const NoCredentialsText = styled(Typography)({
  marginBottom: '10px',
  paddingTop: '8px'
});

const ErrorText = styled(Typography)({
  marginTop: '4px',
  color: '#FF8C00'
});

const CurlTemplatesSetup = observer(({availablePlaceholders, setOnFocus}) => {
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [fields, setFields] = useState([]);
  const [curl, setCurl] = useState("");
  const [userCredentials, setUserCredentials] = useState([]);
  const [secretPlaceholders, setSecretPlaceholders] = useState([]);
  const [selectedCredential, setSelectedCredential] = useState("");
  const [templates, setTemplates] = useState([]);
  const [useIntegrationsTemplate, setUseIntegrationsTemplate] = useState(false);
  const [templateNotExistError, setTemplateNotExistError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const getAppCredentials = (credentials) => {
    return credentials
        .filter(credential => credential?.data?.integrationType)
        .map((credential) => ({
          ...credential,
          data: credential.data || {},
        }));
  }

  const processActionTemplates = (actionTemplates) => {
    return actionTemplates?.map(template => ({
      ...template,
      actions: template.actions?.map(action => ({
        ...action,
        fields: action.fields?.map(field => ({
          ...field,
          value: field.defaultValue || "",
        }))
      }))
    }));
  }

  const actionComparator = () => {
    return (a, b) => {
      // Sort coming soon items to the bottom
      const aComingSoon = a.appName.includes(COMING_SOON_SUFFIX);
      const bComingSoon = b.appName.includes(COMING_SOON_SUFFIX);
      if (aComingSoon && !bComingSoon) return 1;
      if (!aComingSoon && bComingSoon) return -1;
      return a.appName.localeCompare(b.appName);
    };
  }

  const addComingSoonDivider = () => {
    return (template, index, array) => {
      const currentComingSoon = template.appName.includes(COMING_SOON_SUFFIX);
      const prevComingSoon = index > 0 && array[index - 1].appName.includes(COMING_SOON_SUFFIX);

      // Add a divider and header before the first "Coming Soon" item
      if (currentComingSoon && !prevComingSoon) {
        return {...template, divider: true, header: APP_COMING_SOON};
      }
      return template;
    };
  }

  const getUserCredentials = async () => {
    try {
      const res = await send_request(ACTION_TEMPLATES_CREDENTIALS_URL, "", "", "GET");
      if (!res && !res.data) return;

      const data = res.data;
      if (data.credentials) {
        const credentialData = getAppCredentials(data.credentials);
        setUserCredentials(credentialData);
      }

      if (data.actionTemplates){
        const processedActionTemplates = processActionTemplates(data.actionTemplates)
                                          .sort(actionComparator())
                                          .map(addComingSoonDivider());
        setTemplates(processedActionTemplates);
        loadCurrentCurlTemplate(processedActionTemplates);
      }

    } catch (err) {
      console.log(err);
    }
  };

  const setSecretPlaceholderByApp = (appName) => {
    const placeholderList = userCredentials.filter(credential => credential.data.integrationType === appName);
    setSecretPlaceholders(placeholderList);
    return placeholderList;
  }

  useEffect(()=> {
    if (selectedApp && userCredentials.length > 0) {
      setSecretPlaceholderByApp(selectedApp);
    }
  },[selectedApp, userCredentials])


  useEffect(() => {
    getUserCredentials();
  }, []);

  const loadFieldFromTemplate = (actionFromTemplate, persistedCurlTemplate) => {
    const hasSameFields = compareActionFields(actionFromTemplate, persistedCurlTemplate.action);
    if (!hasSameFields) {
      setTemplateNotExistError(FIELD_DONT_EXIST);
      setFields(actionFromTemplate.fields);
    } else {
      setFields(persistedCurlTemplate.action.fields);
    }
  }

  const validatePersistedCredential = (persistedCurlTemplate) => {
    const persistedCredentialsPH = persistedCurlTemplate.credentialPlaceholder;
    if (persistedCredentialsPH) {
      setSelectedCredential(persistedCredentialsPH);
    }
  }

  const loadActionFromTemplate = (actionFromTemplate, persistedCurlTemplate) => {
    if (actionFromTemplate) {
      loadAction(persistedCurlTemplate.action);

      if (actionFromTemplate && actionFromTemplate.fields) {
        loadFieldFromTemplate(actionFromTemplate, persistedCurlTemplate);
      }
    } else {
      setTemplateNotExistError(ACTION_DONT_EXIST);
      setSelectedAction(undefined);
    }
  }

  const handleInvalidAppFromTemplate = () => {
    setTemplateNotExistError(APP_DONT_EXIST);
    resetTemplateState();
    setIsLoading(false);
  }

  const loadCurrentCurlTemplate = (allTemplates) => {
    setIsLoading(true);
    const persistedCurlTemplate = toJS(APIResReqStore.curlTemplateData);
    
    const isPersistedCurlTemplateEmpty = JSON.stringify(persistedCurlTemplate) === JSON.stringify(APIResReqStore.emptyCurlTemplate());

    if (persistedCurlTemplate && !isPersistedCurlTemplateEmpty) {
      setUseIntegrationsTemplate(true)

      const appFromTemplate = allTemplates.find(template => template.appName === persistedCurlTemplate.appName);
      if (appFromTemplate) {
        setSelectedApp(persistedCurlTemplate.appName);
      } else {
        handleInvalidAppFromTemplate();
        return;
      }
      validatePersistedCredential(persistedCurlTemplate);

      const actionFromTemplate = appFromTemplate.actions.find(action => action.action === persistedCurlTemplate.action.action)
      loadActionFromTemplate(actionFromTemplate, persistedCurlTemplate);
    }
    setIsLoading(false);
  }

  const loadAction = (actionObject) => {
    if (!actionObject) {
      resetTemplateState();
      return;
    }
    setSelectedAction(actionObject.action);
    setFields(actionObject.fields);
    setCurl(actionObject.curl)
  }

  const resetTemplateState = () => {
    setSelectedAction("");
    setFields([]);
    setCurl("");
    setSelectedCredential("");
  }

  const handleAppChange = (event, newValue) => {
    const newValueAppName = newValue ? newValue.appName : "";
    setSelectedApp(newValueAppName);
    resetTemplateState();
    APIResReqStore.setCurlTemplateDataAppName(newValueAppName);
    setTemplateNotExistError("");
  };

  const handleActionChange = (event, newValue) => {
    if (!newValue) {
      resetTemplateState();
      return;
    }
    const app = templates.find(t => t.appName === selectedApp);
    const action = app.actions.find(a => a.action === newValue);
    loadAction(action);
    APIResReqStore.setCurlTemplateDataAction(action);
    setTemplateNotExistError("");

    // Generate request if we have all required data
    if (selectedCredential && action.fields) {
      const hasEmptyRequired = action.fields.some(field => field.required === true && !field.value);
      if (!hasEmptyRequired) {
        let generatedCurl = action.curl;
        action.fields.forEach(field => {
          const placeholder = field.placeholder || `{{${field.name}}}`
          const regex = new RegExp(placeholder, "g");
          generatedCurl = generatedCurl.replace(regex, field.value);
        });

        if (selectedCredential) {
          generatedCurl = generatedCurl.replace(DEFAULT_SECRET_PLACEHOLDER, "${"+selectedCredential+".SECRET}");
        }

        APIResReqStore.setRequestCurl(generatedCurl);
        setOpenSnackbar(true);
      }
    }
  };

  const updateField = (fields, index, newValue, isValid, field) => {
    const newFields = [...fields];
    newFields[index] = {
      ...newFields[index],
      value: newValue,
      error: isValid ? null : `⚠️ Potentially invalid value for ${field.name}: Must satisfy ${field.regex}`
    };
    return newFields;
  };

  const handleFieldChange = (index, newValue, field, availablePlaceholders) => {
    const containsValidPlaceholder = availablePlaceholders[0].placeholders?.some(placeholder =>
      newValue.includes(`\${${placeholder.key}}`));

    let isValid = true;
    if (!containsValidPlaceholder && field.regex) {
      const valueForRegexCheck = newValue.replace(/\$\{[^}]+\}/g, '');
      isValid = new RegExp(field.regex).test(valueForRegexCheck);
    }

    const updatedFields = updateField(fields, index, newValue, isValid, field);

    setFields(updatedFields);
    APIResReqStore.setCurlTemplateDataFields(updatedFields);
    setTemplateNotExistError("");

    // Generate request after field update if we have all required data
    const hasEmptyRequired = updatedFields.some(field => field.required === true && !field.value);
    if (selectedAction && selectedCredential && !hasEmptyRequired) {
      // Use the updated fields for generating the request
      let generatedCurl = curl;
      updatedFields.forEach(field => {
        const placeholder = field.placeholder || `{{${field.name}}}`
        const regex = new RegExp(placeholder, "g");
        generatedCurl = generatedCurl.replace(regex, field.value);
      });

      if (selectedCredential) {
        generatedCurl = generatedCurl.replace(DEFAULT_SECRET_PLACEHOLDER, "${"+selectedCredential+".SECRET}");
      }

      APIResReqStore.setRequestCurl(generatedCurl);
      setOpenSnackbar(true);
    }
  };

  const handleCredentialChange = (event, newValue) => {
    const newCredentialPlaceholder = newValue ? newValue.value : "";
    setSelectedCredential(newCredentialPlaceholder);
    APIResReqStore.setCurlTemplateDataCredentialPlaceholder(newCredentialPlaceholder);
  };

  const generateRequest = () => {
    let generatedCurl = curl;
    fields.forEach(field => {
      const placeholder = field.placeholder || `{{${field.name}}}`
      const regex = new RegExp(placeholder, "g");
      generatedCurl = generatedCurl.replace(regex, field.value);
    });

    if (selectedCredential) {
      generatedCurl = generatedCurl.replace(DEFAULT_SECRET_PLACEHOLDER, "${"+selectedCredential+".SECRET}");
    }

    APIResReqStore.setRequestCurl(generatedCurl);
    setOpenSnackbar(true);
  };

  const hasEmptyRequiredFields = fields?.some(field => field.required === true && !field.value);
  const handleConnectApp = () => {
    window.open(CREDENTIAL_PAGE_URL, BLANK);
  };

  const selectedAppTemplate = templates.find(app => app.appName === selectedApp);

  const compareActionFields = (action1, action2) => {
    const fields1 = action1.fields?.map(field => field.name).sort() || [];
    const fields2 = action2.fields?.map(field => field.name).sort() || [];
    return JSON.stringify(fields1) === JSON.stringify(fields2);
  };

  return (
    <div className="setup-mode-container" hidden={APIResReqStore.requestData.setupMode !== CURL_SETUP_MODE} style={{ width: "100%"}}>
      {isLoading ? (
          <CurlTemplateSkeleton/>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {templateNotExistError && (
                <div className="warning-banner">
                  <WarningIcon />
                  <span className="warning-text">
                      {templateNotExistError}
                    </span>
                </div>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={useIntegrationsTemplate}
                  onChange={(e) => setUseIntegrationsTemplate(e.target.checked)}
                />
              }
              label={<TitleText>Use app integration template</TitleText>}
            />
            <DescriptionLabel>
              Use a pre-configured template to set up the request to an app connected in the {' '}
              <a href={`${window.location.origin}/credential_store`} target="_blank" rel="noopener noreferrer">
                credentials page
              </a>
            </DescriptionLabel>
            {!useIntegrationsTemplate && (
              <Box className="app-icons-container">
                {templates.slice(0, 3)?.map((template, index) => (
                  <Box key={index} marginRight="4px">
                    <DynamicIcons icons={[template.icon]} />
                  </Box>
                ))}
                {templates?.length > 3 && (
                  <AppIconCount variant="body2">
                    +{templates.length - 3}
                  </AppIconCount>
                )}
              </Box>
            )}
          </Grid>
          
          {useIntegrationsTemplate && (
            <>
              <Grid item xs={12}>
                <CurlTemplateAutoComplete
                  label="Select an app"
                  value={selectedAppTemplate || null}
                  onChange={handleAppChange}
                  options={templates}
                  getOptionLabel={(option) => option.appName}
                  renderOption={(props, option) => {
                    if (option.divider) {
                      return [
                        <Divider key="divider" />,
                        <DividerHeader key="header">
                          {option.header}
                        </DividerHeader>,
                        <CredentialsMenuItem {...props}>
                          <DynamicIcons icons={[option.icon]} />
                          {option.appName}
                        </CredentialsMenuItem>
                      ];
                    }
                    return (
                      <CredentialsMenuItem {...props}>
                        <DynamicIcons icons={[option.icon]} />
                        {option.appName}
                      </CredentialsMenuItem>
                    );
                  }}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          placeholder={SELECT_APP_PLACEHOLDER}
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                  {selectedApp && (
                                      <Box
                                          paddingLeft="8px"
                                          paddingRight="8px"
                                          display="flex"
                                          alignItems="center"
                                          height="100%"
                                      >
                                        <DynamicIcons icons={[selectedAppTemplate?.icon]} />
                                      </Box>
                                  )}
                                  {params.InputProps.startAdornment}
                                </>
                            ),
                          }}
                      />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                {selectedApp && secretPlaceholders?.length === 0 ? (
                  <>
                    <NoCredentialsText variant="body2">
                      No Credentials available for {selectedApp}
                    </NoCredentialsText>
                    <Box display="flex" alignItems="center">
                      <ConnectAppButton
                        variant="contained"
                        onClick={handleConnectApp}
                        startIcon={<ElectricalServicesIcon />}
                      >
                        Connect app
                      </ConnectAppButton>
                      <CredentialRefreshButton
                        variant="outlined"
                        onClick={getUserCredentials}
                      >
                        Refresh
                      </CredentialRefreshButton>
                    </Box>
                  </>
                ) : (
                  <CurlTemplateAutoComplete
                    label="Select a credential"
                    value={selectedCredential ? { label: `\${${selectedCredential}.SECRET}`, value: selectedCredential } : null}
                    onChange={handleCredentialChange}
                    options={secretPlaceholders.map(credential => ({
                      label: `\${${credential.data.placeholder}.SECRET}`,
                      value: credential.data.placeholder
                    }))}
                    renderInput={(params) => <TextField {...params} placeholder={SELECT_CREDENTIALS_PLACEHOLDER} size="small" />}
                    disabled={!selectedApp}
                    fullWidth
                    noOptionsText={NO_CREDENTIAL_TEXT}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <CurlTemplateAutoComplete
                  label="Select an action"
                  value={selectedAction}
                  onChange={handleActionChange}
                  options={selectedApp ? templates.find(t => t.appName === selectedApp).actions?.map(action => action.action) : []}
                  renderInput={(params) => <TextField {...params} placeholder={SELECT_ACTION_PLACEHOLDER} size="small" />}
                  disabled={!selectedApp}
                  fullWidth
                  autoHighlight
                />
              </Grid>

              {fields?.length > 0 &&
                  <Grid item>
                    <FieldsLabel>
                      Fields
                    </FieldsLabel>
                  </Grid>
              }

              {fields?.map((field, index) => (
                <Grid item xs={12} key={index}>
                  <SelectLabel>
                    {`${field.name} ${field.required === true ? "*" : ""}`}
                  </SelectLabel>
                  <DescriptionLabel>
                    {field.description}
                  </DescriptionLabel>
                  <MentionEditor
                    availablePlaceholders={availablePlaceholders}
                    value={field.value || ""}
                    text={field.value || ""}
                    onChange={(newValue) => handleFieldChange(index, newValue, field, availablePlaceholders)}
                    onFocus={() => setOnFocus(ON_FOCUS_INPUT)}
                  />
                  {field.error && (
                    <ErrorText variant="caption">
                      {field.errorMessage || field.error}
                    </ErrorText>
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                <GenerateCurlButton
                  variant="contained" 
                  onClick={generateRequest} 
                  disabled={!selectedAction || !selectedCredential || hasEmptyRequiredFields}
                  startIcon={<CodeIcon />}
                >
                  Generate Request
                </GenerateCurlButton>
              </Grid>
            </>
          )}
        </Grid>
      )}
      <StyledSnackbar
        open={openSnackbar}
        autoHideDuration={500}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={
          <SnackbarContent>
            <CheckCircleIcon />
            Request updated
          </SnackbarContent>
        }
      />
    </div>
  );
});

export default CurlTemplatesSetup;
