import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DropZone  from "vardogyir-ui/DropZone";
import Dialog from "@mui/material/Dialog";
import { withStyles } from "@mui/styles";
import styles from "../BulkAssessmentStyle";
import DialogContent from "@mui/material/DialogContent";
import Dropzone from "react-dropzone";
import Chip from "@mui/material/Chip";
import * as XLSX from 'xlsx';
import ErrorIcon from "@mui/icons-material/Error";

const ExcelRenderer = (file) => {
  let allowedExtensions = /(\.xlsx|\.csv)$/i;
  if (allowedExtensions.exec(file.path)) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      var rABS = !!reader.readAsBinaryString;

      reader.onload = function (e) {

        const data = e.target.result;

        if (file.path.endsWith('.csv')) {
          // Parse CSV data
          const csvData = data.split('\n').map((row) => row.split(','));
          resolve(csvData);
        } else {
          /* Parse data */
          var wb = XLSX.read(data, { type: rABS ? "binary" : "array" });

          /* Get first worksheet */
          var wsname = wb.SheetNames[0];
          var ws = wb.Sheets[wsname];

          /* Convert array of arrays */
          var json = XLSX.utils.sheet_to_json(ws, { header: 1 });
          resolve(json);
        }
      };

      if (file.path.endsWith('.csv')) reader.readAsText(file);
      else if (file && rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    });
  } else {
    let errorMessage = "Incorrect format";
    return errorMessage;
  }
};

class BulkExcelUpload extends React.Component {
  state = {
    titles: [],
    file: null,
    errorUploadMessage: ""
  }

  onFilesDrop = async (files) => {
    this.setState({
      file: null
    })
    const response = await ExcelRenderer(files[0]);
    if (typeof response === "string") {
      this.setState({
        errorUploadMessage: response
      });
    }else {
      let error = false

      response[0].map((value,index) => {
        if(/^[a-zA-Z0-9\s]*$/.test(value) == false) {
            this.setState({
              errorUploadMessage: "We’ve detected some special characters in the column. Keys can only contain letters and numbers, and cannot contain any special characters (such as -, _, %, $ # etc).  Please remove these and upload your spreadsheet again. "
            });
            error = true
        }
      })
      if (!error){
        this.setState({ file: files[0], titles: response , errorUploadMessage :"" });
      }
    }
  };

  handingUploadExcel = () => {
    const { titles } = this.state;
    this.props.handingExcel(titles)
  }

  render() {
  
    const { classes } = this.props
    const { file } = this.state;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={this.props.open}
      // handleClose={() => {
      //   this.setState({ error: false });
      // }}
      >
        <DialogTitle>
          <h5>Upload sample spreadsheet</h5>
        </DialogTitle>
        <div>
          <DialogContent className={classes.dialogContent}>
            <p className={classes.dialogText}>
              Import a spreadsheet to automatically configure this component. Each column in the first row
              of the spreadsheet should contain the key values to be imported: </p>
            <div className={classes.imageCenter}>
              <img
                className={classes.dialogImage}
                src="/images/BulkAssessmentUpload.png"
              />
            </div>
            <div>
              <div className={classes.dropzone}>
                <Dropzone
                  style={{ width: "100%" }}
                  multiple={false}
                  onDrop={(file) => this.onFilesDrop(file)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()} style={{}}>
                        <DropZone />
                        <input type="file" {...getInputProps()} />
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div style={{ paddingTop: 8 }}>
                  {file && file.name && (
                    <Chip
                      style={{ backgroundColor: "rgba(33, 150, 243, 0.6)" }}
                      label={<span style={{ margin: 8 }}> {file.name} </span>}
                      onDelete={() => this.setState({ file: null })}
                      color="primary"
                    />
                  )}
                  {
                    this.state.errorUploadMessage && (
                      <p className="err-text errorTextFile">
                        <ErrorIcon className="iconDiscard" />
                        {
                          this.state.errorUploadMessage
                        }
                      </p>
                    )
                  }
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                paddingTop: 24,
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => { this.props.handleCancel() }}
                variant={"outlined"}  
                
                color={"info"}
              >
                Cancel</Button>
              <Button
                onClick={this.handingUploadExcel}
                disabled={file ? false : true}
                style={{ marginLeft: 16 }}
                variant={"contained"} color={"primary"} 
              >
                Upload</Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(BulkExcelUpload)
