import React from "react";

//components
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";

//css
import styles from "../EmailTriggerStyles";

const CategoryTitle = (props) => {
  return (
    <Grid
      container
      item
      xs={12}
      direction={"row"}
      alignItems={"center"}
      className={props.classes.indent}
    >
      <p className={"bold"}>{props.title}</p>
    </Grid>
  );
};

export default withStyles(styles)(CategoryTitle);
