export const getColourFromString = (name) => {
    /**
     * This determines the color for the chip input
     * We grab the first and last characters of the chip value
     * We then add the first, last and first characters in ascii and use
     * them to generate the colour.
     *  */

    if (name === null || name === undefined) {
        return {
            border: "rgba(85, 167, 122, 1)",
            bg: "rgba(85, 167, 122, 0.2)",
        };
    }

    const asciiCode = name.charCodeAt(0);
    const lastAsciiCode = name.charCodeAt(name.length - 1);
    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum = asciiCode.toString() + lastAsciiCode.toString() + asciiCode.toString();
    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = (num >> 16) & 255;
    var g = (num >> 8) & 255;
    var b = num & 255;

    return {
        border: "rgb(" + r + ", " + g + ", " + b + ", 1)",
        bg: "rgb(" + r + ", " + g + ", " + b + ", 0.2)",
    };
};
