export const hexToPastel = (hex) => {
    // Convert hex to RGB
    const rgb = hexToRGB(hex);
    if (!rgb) return hex;

    const whiteWeight = 0.85;

    // Blend with white
    const pastelRGB = {
        r: Math.round(rgb.r * (1 - whiteWeight) + 255 * whiteWeight),
        g: Math.round(rgb.g * (1 - whiteWeight) + 255 * whiteWeight),
        b: Math.round(rgb.b * (1 - whiteWeight) + 255 * whiteWeight),
    };

    // Convert back to hex
    return RGBToHex(pastelRGB);
};

const hexToRGB = (hex) => {
    if (!hex) return;

    const hexValue = hex.replace(/^#/, "");
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);
    return { r, g, b };
};

const RGBToHex = (rgb) => {
    if (!rgb) return;
    return "#" + [rgb.r, rgb.g, rgb.b].map((channel) => channel.toString(16).padStart(2, "0")).join("");
};
