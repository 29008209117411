import "./stylesheet/customLogin.css";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Link as MLink,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Link, Route, Routes } from "react-router-dom";
import React, { Component } from "react";
import {confirmResetPassword, confirmSignIn, fetchUserAttributes, getCurrentUser, resetPassword, signIn, signInWithRedirect, signOut} from "@aws-amplify/auth";

import AIOnboardingPage from "./Onboarding/AIOnboardingPage";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import ConfirmUser from "../ConfirmUser/confirmUser";
import CustomSignUp from "./CustomSignUp";
import FloatingIconHalfScreen  from "./Onboarding/FloatingIconHalfScreen"
import ForgotUsername from "./ForgotUsername";
import Icon from "@mui/material/Icon";
import { categories } from "../../utils/TemplateCategories";
import { companySizes } from "../../utils/companysize";
import { runLoading } from "../../utils/showLoadingScreen";
import { send_request } from "../../utils/Request";
import { timezones } from "../../utils/timezones";
import { currentDomain } from "../../App";

export class CustomSignIn extends Component {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      username: "",
      resetUserName: "",
      password: "",
      errorMessage: "",
      openError: false,
      formState: "signUp",
      newPassword: "",
      loading: false,
      user: null,
      timezone: null,
      companySize: null,
      status: false,
      confirmMessage: null,
      openSetPlan: false,
      templatesToImport: [],
      findOutBy: null,
      automate: "YES",
      automateRequest: "",
      referralError: false,
      inviteUsers: [
        {
          email: "",
          error: false,
        },
      ],
      organisationNameCheck: false,
      organisationName: "",
      findOutDetails: "",
      referral: "",
      firstName: "",
      firstNameCheck: false,
      surName: "",
      accountsLinked: false,
      useAIOnboarding: false,
      onboardingStatus: null,
      retrySignIn: false,
    };
  }

  componentDidMount = async () => {
    let confirmMessage = sessionStorage.getItem("confirmMessage");
    let errorMessage = sessionStorage.getItem("errorMessage");
    let openError = sessionStorage.getItem("openError");
    this.setState({
      username: "",
      password: "",
      email: "",
      formState: "signIn",
      resetUserName: "",
      newPassword: "",
      confirmMessage: confirmMessage,
      openError: openError,
      errorMessage,
    });

    if (window.location.pathname === "/signUp") {
      this.setState({
        formState: "signUp",
      });
    }
    if (window.location.pathname === "/forgotPassword") {
      this.setState({
        formState: "resetPassword",
      });
    }
    if (window.location.pathname === "/forgotUsername") {
      this.setState({
        formState: "forgotUsername",
      });
    }

    if (String(window.location.hash).indexOf("AccountsLinked.") != -1) {
      this.setState({ accountsLinked: true });
      window.location.hash = "";
    }
    
    try {
      const user = await getCurrentUser();
      if (user) {
        this.checkUser(user);
      } else {
        this.setState({ formState: "signIn" });
      }
    } catch (e) {
      console.log(e);
      this.props.onStateChange("signedOut", {});
      console.log("Not signed in");
      this.setState({ formState: "signIn" });
    }
  };

  handleResetPassword = () => {
    this.setState({ formState: "resetPassword" });
  };

  handleSignUp = () => {
    this.setState({ formState: "signUp" });
  };

  handleSetNewPassword = () => {
    this.setState({ formState: "setNewPassword" });
  };

  handleFirstTimeSignIn = () => {
    this.setState({ formState: "changePassword" });
  };

  handleFormSubmission = (event) => {
    event.preventDefault();
    if (this.state.formState === "signIn") {
      this.signIn();
    } else if (this.state.formState === "resetPassword") {
      this.resetPassword();
    } else if (this.state.formState === "setNewPassword") {
      this.setNewPassword();
    } else if (this.state.formState === "changePassword") {
      this.changePassword();
    } else if (this.state.formState === "setAccountDetails") {
      this.setAccountDetails();
    } else if (this.state.formState === "setOrganisation") {
      this.setOrganisation();
    } else if (this.state.formState === "setUpAutomate") {
      this.setUpAutomate();
    } else if (this.state.formState === "setUpInvitation") {
      this.setUpInvitation();
    } else if (this.state.formState === "start") {
      this.handleStart();
    }
  };

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      return false;
    }
    return true;
  };

  setUpInvitation = async () => {
    // this.setState({ formState: "orgAccountSetup" });
    const { inviteUsers } = this.state;
    let data = [];
    inviteUsers.map((i) => {
      if (i.email !== "") {
        data.push({
          username: i.email,
          email: i.email,
          isRoleAdministrator: false,
          isRoleEditor: true,
          isRolePrivateViewer: true,
        });
      }
    });
    await send_request(`authz-service/invite-users`, data, "", "POST")
      .then(() => {
        this.setState({ formState: "start" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleStart = () => {
    this.props.firstLogin(true);
    this.props.onStateChange("signedIn", {});
  };

  resendCode = () => {
    this.resetPassword();
  };

  resetPassword = async () => {
    this.setState({ loading: true });
    const resetUserName = this.state.resetUserName;
    try {
      await resetPassword({ username: resetUserName });
      this.setState({ formState: "setNewPassword", loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ 
        openError: true, 
        errorMessage: error.message,
        loading: false
      });
    }
  };

  setNewPassword = async () => {
    this.setState({ loading: true });
    const resetUserName = this.state.resetUserName;
    const code = this.state.code;
    const newPassword = this.state.newPassword;
    try {
      await confirmResetPassword({ username: resetUserName, confirmationCode: code, newPassword });
      const { isSignedIn } = await signIn({ username: resetUserName, password: newPassword });
      if (isSignedIn) {
        await signOut();

        this.setState({
          formState: "signIn",
          resetUserName: "",
          newPassword: "",
          loading: false
        });
        window.location.href = "/";
      }
    } catch (error) {
      if (error.name === "UserLambdaValidationException") {
        this.setState({
          openError: true,
          errorMessage: "This email is already being used by another account",
          loading: false
        });
      } else {
        this.setState({ openError: true, errorMessage: error.message, loading: false });
      }
    }
  };

  /**
   * Completes the password change for invited users with temporary passwords.
   * Confirms sign-in by setting a new password and updates the user's authentication state.
   */
  changePassword = async () => {
    try {
      // Extract the new password from the component's state
      const { newChangePassword } = this.state;

      // Step 1: Confirm sign-in with the new password
      const response = await confirmSignIn({ challengeResponse: newChangePassword });

      // Step 2: Validate if sign-in was successful after password change
      if (response.isSignedIn) {
        console.log('Password changed successfully, user is signed in');
        
        // Update last logged time and transition to signed-in state
        this.updateLastLogged();
        this.props.onStateChange("signedIn", {});
      } else {
        throw new Error("Password change was unsuccessful.");
      }
    } catch (error) {
      console.error('Password change failed:', error);
      this.setState({ openError: true, errorMessage: error.message });
    }
  };
  
  setUpAutomate = async () => {
    this.setState({
      loading: true,
    });

    let { automateRequest } = this.state;
    const clientInfo = {
      use_case: automateRequest && automateRequest.trim() !== "" ? true : false,
      use_case_detail: automateRequest,
    };

    await send_request(
      `authz-service/extra_questions/set_up_automate_request`,
      clientInfo,
      "",
      "POST"
    )
      .then(() => {
        this.setState({ formState: "setUpInvitation", loading: false });
      })
      .catch((err) => {
        console.log(error);
      });
  };

  checkClientName = () => {
    const { organisationName } = this.state;

    if (organisationName) {
      if (organisationName.trim() === "") {
        this.setState({
          organisationNameCheck: true,
        });
        return true;
      } else {
        this.setState({
          organisationNameCheck: false,
        });
        return false;
      }
    } else {
      this.setState({
        organisationNameCheck: true,
      });
      return true;
    }
  };

  setAccountDetails = async () => {
    this.setState({
      loading: true,
    });
    const { firstName, surName } = this.state;

    if (firstName !== null) {
      if (firstName.trim() !== "") {
        this.setState({
          firstNameCheck: false,
        });

        let dispName = firstName.trim();

        // If surname is given, add it to the display name
        if (surName && surName.trim() !== "") dispName += "." + surName;

        let data = {
          firstName: firstName.trim(),
          lastName: surName,
          displayName: dispName,
        };

        await send_request(
          `authz-service/set-account-details`,
          data,
          "",
          "POST"
        ).then((res) => {
          if (res) {
            this.setState({ formState: "setOrganisation", loading: false });
          } else {
            this.setState({
              loading: false,
            });
          }
        });
      } else {
        this.setState({
          firstNameCheck: true,
          loading: false,
        });
      }
    } else {
      this.setState({
        firstNameCheck: true,
        loading: false,
      });
    }
  };

  setOrganisation = async () => {
    let checkCLientName = this.checkClientName();
    if (!checkCLientName) {
      this.setState({
        loading: true,
      });
      const {
        organisationName,
        timezone,
        companySize,
        findOutBy,
        findOutDetails,
      } = this.state;
      const clientInfo = {
        companySize: companySize,
        referral_source: findOutBy,
      };
      if (findOutBy === "REFERRED_BY_A_WORKFLOW86_USER") {
        clientInfo["referrer_username"] = findOutDetails;
      } else if (findOutBy === "OTHER") {
        clientInfo["other_detail"] = findOutDetails;
      } else if (findOutBy === "ANOTHER_WEBSITE") {
        clientInfo["other_website"] = findOutDetails;
      }
      const clientData = {
        clientName: organisationName ? organisationName : "client name",
        timezone: timezone,
      };

      await send_request(
        `authz-service/extra_questions/set_up_organization`,
        clientInfo,
        "",
        "POST"
      )
        .then(async () => {
          await send_request(
            `authz-service/set_up_client`,
            clientData,
            "",
            "POST"
          );
          this.setState({ formState: "referral", loading: false });
        })
        .catch((err) => {
          console.log(error);
        });
    }
  };

  setUpAccount = async () => {
    let data = [];
    data = this.state.templatesToImport;
    this.setState({ formState: "orgAccountSetup" });
    //this function makes a request to project-services to preload templates
    const preloadTemplates = await send_request(
      `project-service/template/preload-templates`,
      data,
      "",
      "POST"
    )
      .then(() => {
        this.setState({ formState: "setUpAutomate" });
        // this.props.onStateChange("signedIn", {});
      })
      .catch((error) => {
        console.log(error);
        this.setState({ formState: "setUpAutomate" });
      });
  };

 
  clearAllCookies = (domain) => {
    const cookies = document.cookie.split("; ");
    console.log(cookies);

    cookies.forEach(cookie => {
        const cookieName = cookie.split("=")[0];
        document.cookie = `${cookieName}=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; sameSite=None; secure`;
    });
}

  signIn = async () => {
    sessionStorage.clear();
    this.setState({
      formState: "signIn",
      resetUserName: "",
      newPassword: "",
      loading: true,
    });
    const username = this.state.username;
    const password = this.state.password;
    if (password == "") {
      this.setState({
        openError: true,
        errorMessage: "Password cannot be empty.",
        loading: false,
      });
    } else {
      try {
        const { isSignedIn, nextStep } = await signIn({ username, password });
        if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
          this.props.firstLogin(true);
          this.setState({
            formState: "changePassword",
            user: nextStep.signInStep,
          });
        } else if (isSignedIn) {
          // other situations
          this.checkUser(await getCurrentUser());
          // update last logged
          this.updateLastLogged();
          if(this.state.retrySignIn)
          {// if sign in successful after cookie storage cleanup we can reset the flag
            this.setState({retrySignIn:false});
          }
        }
      } catch (error) {
        if (error.name === "UserLambdaValidationException") {
          this.setState({
            openError: true,
            errorMessage: "This email is already being used by another account",
            loading: false,
          });
        } else if (error.name === "UnexpectedSignInInterruptionException") {
          console.log('here');
          // clearing all cookies just to ensure that next sign in works
          this.clearAllCookies(currentDomain); 
          
          // Retry sign in once
          if (!this.state.retrySignIn) {
            this.setState({ retrySignIn: true }); // Set retry flag
            this.signIn(); // Retry sign in
          }
        } else {
          this.setState({ openError: true, errorMessage: error.message, loading: false });
        }
      }
    }
  };

  checkUser = async (user) => {
    let email;

    const attributes = await fetchUserAttributes();
    email = attributes.email;

    const userInfo = {
      email: email,
      timezone: this.state.timezone,
    };

    let requestError = false;

    const url = `authz-service/check-user-verified-and-completed`;
    const json = await send_request(url, "", "", "POST").catch((err) => {
      requestError = true; // Set request error to true
    });

    // If there was a request error or json/json data is null
    // Prevent the user from continuing as there was an error with the request
    if (requestError || (!json || !json.data)) {

      await signOut();
      
      const errMessage = "An error was encountered when signing you in. Please try again";
      this.setState({ loading: false, openError: true, errorMessage: errMessage });
      return;
    }

    if (json.status == "200" && json.data.verified === false) {
      const createURL = `authz-service/create-new-user-and-client`;
      await send_request(createURL, userInfo, "", "POST")
        .then(async () => {

          this.props.firstLogin(true);
          this.props.onStateChange("signIn", {});
          //directs everyone to use ai onboarding
          this.setState({
            useAIOnboarding: true,
            onboardingStatus: "INITIAL_WELCOME",
          });
          
        })
        .catch((err) => {
          throw Error(err.message);
        });
    } else {
      if (json.data && json.data.isCompleted) {
        this.props.onStateChange("signedIn", {});
      } else {
        this.props.firstLogin(true);
        this.props.onStateChange("signIn", {});
        this.getUserInfo();

        // If they have ai onboarding access, set use ai onboarding to true
        if (json && json.data && json.data.hasAIOnboardingAccess) {
          this.setState({
            useAIOnboarding: true,
            onboardingStatus: json.data.onboardingStatus,
          });
        } else {
          this.setState({ formState: "setAccountDetails", loading: false });
        }
      }
    }
  };

  getUserInfo = async () => {
    let firstName = "";
    await getCurrentUser().then(async (user) => {
      if (user) {
        const { attributes } = await fetchUserAttributes(user);
        if (attributes && attributes.given_name) {
          firstName = attributes.given_name;
        }
      }
    });

    await send_request(`authz-service/user-info`, "", {}).then((res) => {
      if (res.data) {
        this.setState({
          organisationName: res.data.client ? res.data.client.clientName : "",
          timezone: res.data.client ? res.data.client.timezone : null,
          firstName: res.data.firstName ? res.data.firstName : firstName,
          surName: res.data.lastName,
        });
      }
    });

    await send_request(
      `authz-service/extra_questions/get_extra_questions`,
      "",
      {}
    ).then((res) => {
      if (res.data) {
        let findOutDetails = "";
        if (res.data.referral_source) {
          switch (res.data.referral_source) {
            case "REFERRED_BY_A_WORKFLOW86_USER":
              findOutDetails = res.data.referrer_username;
              break;
            case "OTHER":
              findOutDetails = res.data.other_detail;
              break;
            case "ANOTHER_WEBSITE":
              findOutDetails = res.data.other_website;
              break;
          }
        }
        this.setState({
          findOutBy: res.data.referral_source,
          companySize: res.data.companySize,
          findOutDetails: findOutDetails,
        });
      }
    });
  };

  updateLastLogged = async () => {
    const json = await send_request(`authz-service/update-last-logged`, "", {});
  };

  handleClose = () => {
    this.setState({ openError: false });
  };

  backToSignIn = () => {
    this.setState({ formState: "signIn" });
  };

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const check_type = ["radio", "checkbox"].includes(type);

    // Update state
    let updatedState = { checkedValue: check_type ? value : null };
    updatedState[name] = check_type ? checked : value;
    this.setState(updatedState);
  };

  handleInviteUserInputChange = (value, index) => {
    const { inviteUsers } = this.state;
    if (value === "") {
      inviteUsers[index].error = false;
    } else {
      const result = this.validateEmail(value);
      inviteUsers[index].error = result;
    }
    inviteUsers[index].email = value;

    this.setState({
      inviteUsers,
    });
  };

  handleChangeTimezone = (timezone) => {
    this.setState({ timezone: timezone });
  };

  handleChangeCompanySize = (companySize) => {
    this.setState({ companySize: companySize });
  };

  handleChangeFindOutAboutWorkflow86 = (size) => {
    this.setState({ findOutBy: size });
  };

  skipSetOrganisation = async () => {
    this.setState({ formState: "setupPackage", loading: false });
  };

  handleStarter = () => {
    this.setState({ formState: "start" });
  };
  handleStatusClose = () => {
    this.props.handleStatusClose();
  };

  redirectResendEmail = () => {
    return (
      <CustomSignUp
        username={this.state.username}
        formState={"signUpSuccess"}
      />
    );
  };

  changeState = () => {
    this.setState({ formState: "signIn" });
  };

  handleSetupPackage = (event) => {
    if (this.state.templatesToImport.includes(event.target.value)) {
      var idx = this.state.templatesToImport.indexOf(event.target.value);
      this.state.templatesToImport.splice(idx, 1);
    } else this.state.templatesToImport.push(event.target.value);

    this.setState({
      templatesToImport: this.state.templatesToImport,
    });
  };

  findOutBy = () => {
    const { findOutBy, findOutDetails } = this.state;
    switch (findOutBy) {
      case "REFERRED_BY_A_WORKFLOW86_USER":
        return (
          <Grid item xs={12}>
            <p className="inputDescription">
              What is the username of your referrer? We we’ll send you both a
              referral bonus 🎁
            </p>
            <TextField
              id="findOutDetails"
              key="findOutDetails"
              name="findOutDetails"
              value={findOutDetails}
              style={{ width: "100%" }}
              variant="outlined"
              type="text"
              onChange={(e) => {
                this.setState({ findOutDetails: e.target.value });
              }}
            />
          </Grid>
        );
      case "OTHER":
        return (
          <Grid item xs={12}>
            <p className="inputDescription">Can you provide more details?</p>
            <TextField
              id="findOutDetails"
              key="findOutDetails"
              name="findOutDetails"
              style={{ width: "100%" }}
              variant="outlined"
              type="text"
              onChange={this.handleInputChange}
            />
          </Grid>
        );
      case "ANOTHER_WEBSITE":
        return (
          <Grid item xs={12}>
            <p className="inputDescription">What was the website?</p>
            <TextField
              id="findOutDetails"
              key="findOutDetails"
              name="findOutDetails"
              style={{ width: "100%" }}
              variant="outlined"
              type="text"
              onChange={this.handleInputChange}
            />
          </Grid>
        );
    }
  };

  automateState = () => {
    return (
      <Grid item xs={12} style={{ marginTop: 16 }}>
        <p className="inputDescription">
          Briefly describe a workflow you want to build e.g. onboarding new
          customers, conduct compliance reviews, onboarding new employees
        </p>
        <TextField
          id="automateRequest"
          key="automateRequest"
          name="automateRequest"
          style={{ width: "100%" }}
          multiline
          variant="outlined"
          type="text"
          placeholder="e.g. onboarding new customers"
          onChange={this.handleInputChange}
        />
      </Grid>
    );
  };

  onSkip = () => {
    this.setState({ formState: "start" });
  };

  onSkipAutomate = async () => {
    const clientInfo = {
      use_case: false,
      use_case_detail: "",
    };

    await send_request(
      `authz-service/extra_questions/set_up_automate_request`,
      clientInfo,
      "",
      "POST"
    )
      .then(() => {
        this.setState({ formState: "setUpInvitation" });
      })
      .catch((err) => {
        console.log(error);
      });
  };

  signInWithGG = async () => {
    try {

      await signInWithRedirect({ provider: 'Google' });

    } catch (e) {
      console.log(e);
    }
  };

  handleReferral = async () => {
    this.setState({ loading: true });

    const checkReferral = await send_request(
      `authz-service/check_referral/${this.state.referral}`,
      "",
      "",
      "GET"
    )
      .then((response) => {
        if (response) {
          const referralPassed = response.data;
          if (referralPassed == true) {
            this.setState({ referralError: false, formState: "setupPackage" });
          } else {
            this.setState({ referralError: true });
          }
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ referralError: true, formState: "setupPackage" });
        this.setState({ loading: false });

        console.log(e);
      });
  };

  handleChangeReferral = (event) => {
    this.setState({ referral: event.target.value });
  };

  render() {
    const {
      formState,
      user,
      timezone,
      companySize,
      confirmMessage,
      errorMessage,
      inviteUsers,
      organisationName,
      firstName,
      surName,
    } = this.state;

    if (window.location.pathname != "/signUp" && formState == "signUp") {
      this.setState({
        formState: "signIn",
      });
    }

    let inviteUsersDisabled = false;
    inviteUsers.map((i) => {
      if (i.error) {
        inviteUsersDisabled = true;
      }
    });

    return !this.state.useAIOnboarding ? (
      <React.Fragment>
        <div>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openError}
            autoHideDuration={6000}
            onClose={this.handleClose}
            key={"top" + "center"}
          >
            <Alert onClose={this.handleClose} severity="error" variant="filled">
              {errorMessage}
            </Alert>
          </Snackbar>
          <Grid container spacing={2} className="main-page">
            
            <FloatingIconHalfScreen/>

            {errorMessage == "User is not confirmed." ? (
              <Grid item xs={12} sm={8} className="main-form-login">
                {this.redirectResendEmail()}
              </Grid>
            ) : (
              <Grid item xs={12} sm={8} className="main-form-login">
                {this.state.formState === "signUp" &&
                window.location.pathname === "/signUp" ? (
                  <Routes>
                    <Route
                      path="/signUp"
                      element={<CustomSignUp formState={"signUp"} />}
                    />
                  </Routes>
                ) : null}

                {window.location.pathname.split("/")[1] === "confirmUser" && (
                  <Routes>
                    <Route
                      path="/confirmUser"
                      element={
                        <ConfirmUser
                          onStateChange={this.props.onStateChange}
                          location={this.props.location}
                        />
                      }
                    />
                  </Routes>
                )}
                {this.state.formState === "signIn" && (
                  <div className="mainDiv">
                    {this._validAuthStates.includes(this.props.authState) && (
                      <form onSubmit={this.handleFormSubmission}>
                        <Typography component="div">
                          <div>
                            <img src="/svgs/logo.svg" alt="logo" />
                          </div>

                          <h4>
                            {confirmMessage
                              ? confirmMessage
                              : "Sign in to your account"}
                          </h4>

                          <div>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                marginBottom: 16,
                                width: '100%',
                              }}
                              startIcon={
                                <img 
                                  src="/svgs/googleLogo.svg" 
                                  alt="Google Logo"
                                  className="buttonIcon"
                                />
                              }
                              onClick={this.signInWithGG}
                              disabled={this.state.loading}
                            >
                              Sign In with Google
                              {this.state.loading && (
                                <CircularProgress
                                  size={24}
                                  className="buttonProgress"
                                />
                              )}
                            </Button>
                          </div>
                        </Typography>
                        <div className="divider">
                          <span className="divider-text">or</span>
                        </div>
                        <div className="inputArea">
                          <p className="inputDescription">Email or Username</p>
                          <TextField
                            id="username"
                            key="username"
                            name="username"
                            style={{ width: "100%" }}
                            placeholder="Enter your email or username"
                            variant="outlined"
                            type="text"
                            onChange={this.handleInputChange}
                          />
                          <div className="forgotUsernamePasswordText">
                            <Typography>
                              <Link
                                to="/forgotUsername"
                                onClick={() =>
                                  this.setState({ formState: "forgotUsername" })
                                }
                              >
                                Forgot your username?
                              </Link>
                            </Typography>
                          </div>
                          <p className="inputDescription">Password</p>
                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Enter your password"
                            variant="outlined"
                            id="password"
                            key="password"
                            name="password"
                            type="password"
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <div className="forgotUsernamePasswordText">
                          <Typography>
                            <Link
                              to="/forgotPassword"
                              onClick={this.handleResetPassword}
                            >
                              Forgot your password?
                            </Link>
                          </Typography>
                          <Typography
                            style={{
                              marginTop: "16px",
                              marginBottom: "16px",
                            }}
                          >
                            <Link to="/signUp" onClick={this.handleSignUp}>
                              Don’t have an account? Sign up
                            </Link>
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%"
                            }}
                            className="flexStartMobile"
                          >
                            <br />
                            <Button
                              className="signInButton"
                              type="submit"
                              onClick={this.handleFormSubmission}
                              disabled={this.state.loading}
                              variant="contained"
                              color="primary"
                              id="sign_in_button"
                              fullWidth
                              style={{ marginTop: "16px" }}
                            >
                              Sign In
                              {this.state.loading && (
                                <CircularProgress
                                  size={24}
                                  className="buttonProgress"
                                />
                              )}
                            </Button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                )}
                {this.state.formState !== "signIn" &&
                this.state.formState !== "signUp" ? (
                  <div className="mainDiv">
                    {this._validAuthStates.includes(this.props.authState) && (
                      <form onSubmit={this.handleFormSubmission}>
                        {this.state.formState === "changePassword" ? (
                          <React.Fragment>
                            <Typography component="div">
                              <div>
                                <img src="/svgs/logo.svg" alt="logo" />
                              </div>
                              <h4>Change Password</h4>
                            </Typography>
                            <div className="inputArea">
                              <p className="inputDescription">New Password</p>
                              <TextField
                                id="newChangePassword"
                                key="newChangePassword"
                                name="newChangePassword"
                                style={{ width: "100%" }}
                                placeholder="New Password"
                                variant="outlined"
                                type="password"
                                onChange={this.handleInputChange}
                              />
                              <p className={"s-text noWrap"}>
                                Must contain 6+ characters, special character(s), number(s),
                                uppercase and lower case letter(s).
                              </p>
                              <p className="inputDescription" style={{ marginTop: "8px" }}>
                                After submitting your new password, you will be redirected back to the sign in page
                              </p>
                            </div>
                            <div className="actionArea">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  style={{ marginRight: "10px" }}
                                  variant={"outlined"}
                                  color={"info"}
                                  onClick={this.backToSignIn}
                                >
                                  {" "}
                                  Back{" "}
                                </Button>
                                <Button
                                  variant={"contained"}
                                  color={"primary"}
                                  type="submit"
                                  onClick={this.handleFormSubmission}
                                >
                                  {" "}
                                  Change{" "}
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null}
                        {this.state.formState === "forgotUsername" &&
                          window.location.pathname === "/forgotUsername" && (
                            <ForgotUsername
                              backToSignIn={this.backToSignIn}
                              validateEmail={this.validateEmail}
                            />
                          )}
                        {this.state.formState === "resetPassword" &&
                        window.location.pathname === "/forgotPassword" ? (
                          <Routes>
                            <Route
                              path="/forgotPassword"
                              element={
                                <>
                                  <Typography component="div">
                                    <div>
                                      <img src="/svgs/logo.svg" alt="logo" />
                                    </div>
                                    <h4>Reset your password</h4>
                                  </Typography>
                                  <div className="inputArea">
                                    <p className="inputDescription">
                                      Enter the email or username of the account you want
                                      to recover
                                    </p>
                                    <TextField
                                      id="resetUserName"
                                      key="resetUserName"
                                      name="resetUserName"
                                      style={{ width: "100%" }}
                                      placeholder="Enter your account email here"
                                      variant="outlined"
                                      type="text"
                                      onChange={this.handleInputChange}
                                    />
                                    <p className="inputDescription">
                                      We will send a password reset code to the
                                      email connected to this account{" "}
                                    </p>
                                  </div>
                                  <div className="actionArea">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Link to="/">
                                        <Button
                                          style={{
                                            marginRight: "10px",
                                            textDecoration: "none",
                                          }}
                                          className="backButton"
                                          variant={"outlined"}
                                          color={"info"}
                                          onClick={this.backToSignIn}
                                        >
                                          Back{" "}
                                        </Button>
                                      </Link>
                                      <Button
                                        className="resetButton"
                                        variant={"contained"}
                                        color={"primary"}
                                        type="submit"
                                        onClick={this.handleFormSubmission}
                                        disabled={this.state.loading}
                                      >
                                        Send Code
                                        {this.state.loading && (
                                          <CircularProgress
                                            size={24}
                                            className="buttonProgress"
                                          />
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                </>
                              }
                            ></Route>
                          </Routes>
                        ) : formState == "setNewPassword" ? (
                          ""
                        ) : null}
                        {this.state.formState === "setNewPassword" ? (
                          <React.Fragment>
                            <Typography component="div">
                              <div>
                                <img src="/svgs/logo.svg" alt="logo" />
                              </div>
                              <h4>Reset your password</h4>
                            </Typography>
                            <div className="inputArea">
                              <p className="inputDescription">Reset code</p>
                              <TextField
                                id="code"
                                key="code"
                                name="code"
                                style={{ width: "100%" }}
                                placeholder="Enter the password reset code sent to your email"
                                variant="outlined"
                                type="text"
                                onChange={this.handleInputChange}
                              />
                              <p className="inputDescription">New Password</p>
                              <TextField
                                style={{ width: "100%" }}
                                placeholder="Enter your new password"
                                variant="outlined"
                                id="newPassword"
                                key="newPassword"
                                name="newPassword"
                                type="password"
                                onChange={this.handleInputChange}
                              />
                              <p className={"s-text noWrap"}>
                                Must contain 6+ characters, special character(s), number(s),
                                uppercase and lower case letter(s).
                              </p>
                              <p className="inputDescription" style={{ marginTop: "8px" }}>
                                After submitting your new password, you will be redirected back to the sign in page
                              </p>
                            </div>
                            <div className="actionArea">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  style={{ marginRight: "10px" }}
                                  className="backButton"
                                  variant={"outlined"}
                                  color={"info"}
                                  onClick={this.backToSignIn}
                                >
                                  Back
                                </Button>
                                <Button
                                  style={{ marginRight: "10px" }}
                                  className="backButton"
                                  variant={"outlined"}
                                  color={"info"}
                                  onClick={this.resendCode}
                                  disabled={this.state.loading}
                                >
                                  Resend Code
                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      className="buttonProgress"
                                    />
                                  )}
                                </Button>
                                <Button
                                  className="resetButton"
                                  variant={"contained"}
                                  color={"primary"}
                                  type="submit"
                                  onClick={this.handleFormSubmission}
                                  disabled={this.state.loading}
                                >
                                  Submit
                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      className="buttonProgress"
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null}
                        {this.state.formState === "setAccountDetails" && (
                          <React.Fragment>
                            <Typography component="div">
                              <div>
                                <img src="/svgs/logo.svg" alt="logo" />
                              </div>
                              <h4>👋 Welcome, let’s set up your details </h4>
                            </Typography>
                            <div className="inputArea">
                              <p className="inputDescription">
                                First name (required)
                              </p>
                              <TextField
                                id="firstName"
                                key="firstName"
                                name="firstName"
                                value={firstName}
                                style={{ width: "100%" }}
                                placeholder="Enter your first name"
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    firstName: e.target.value,
                                  });
                                }}
                                error={this.state.firstNameCheck ? true : false}
                                InputProps={{
                                  endAdornment: this.state.firstNameCheck && (
                                    <Icon
                                      className="fa fa-exclamation-circle"
                                      style={{ color: "#f44336" }}
                                    />
                                  ),
                                }}
                                helperText={
                                  this.state.firstNameCheck
                                    ? "This field can not be empty"
                                    : null
                                }
                              />
                            </div>
                            <div className="inputArea">
                              <p className="inputDescription">Surname</p>
                              <TextField
                                id="surName"
                                key="surName"
                                name="surName"
                                value={surName}
                                style={{ width: "100%" }}
                                placeholder="Enter your surname"
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    surName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="actionArea">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  style={{ marginBottom: 100 }}
                                  className="resetButton"
                                  variant={"contained"}
                                  color={"primary"}
                                  type="submit"
                                  onClick={this.handleFormSubmission}
                                  disabled={this.state.loading}
                                >
                                  Next
                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      className="buttonProgress"
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        {this.state.formState === "setOrganisation" && (
                          <React.Fragment>
                            <Typography component="div">
                              <div>
                                <img src="/svgs/logo.svg" alt="logo" />
                              </div>
                              <h4>Set up your account</h4>
                            </Typography>
                            <div className="inputArea">
                              <p className="inputDescription">
                                Organisation name
                              </p>
                              <TextField
                                id="organisationName"
                                key="organisationName"
                                name="organisationName"
                                value={organisationName}
                                style={{ width: "100%" }}
                                placeholder="Enter a name for your account"
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    organisationName: e.target.value,
                                  });
                                }}
                                error={
                                  this.state.organisationNameCheck
                                    ? true
                                    : false
                                }
                                InputProps={{
                                  endAdornment: this.state
                                    .organisationNameCheck && (
                                    <Icon
                                      className="fa fa-exclamation-circle"
                                      style={{ color: "#f44336" }}
                                    />
                                  ),
                                }}
                                helperText={
                                  this.state.organisationNameCheck
                                    ? "This field can not be empty"
                                    : null
                                }
                              />
                              <Grid item xs={12}>
                                <Grid item xs={6}>
                                  <p className="inputDescription">Timezone</p>
                                  <Autocomplete
                                    options={timezones}
                                    autoComplete={true}
                                    value={
                                      timezone != null
                                        ? timezone
                                        : "select timezone"
                                    }
                                    size={"small"}
                                    onChange={(event, inputValue) => {
                                      if (inputValue != null) {
                                        this.handleChangeTimezone(
                                          inputValue.name
                                        );
                                      }
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          value={
                                            timezone != null
                                              ? timezone
                                              : "select timezone"
                                          }
                                        />
                                      );
                                    }}
                                    getOptionLabel={(option) => {
                                      if (typeof option == "object")
                                        return (
                                          option.name + " " + option.offset
                                        );
                                      return option;
                                    }}
                                    isOptionEqualToValue={(option, value) => {
                                      if (option != null) {
                                        if (option.name == value) return true;
                                        else return false;
                                      }
                                    }}
                                    className={"select-list "}
                                    variant={"outlined"}
                                  />
                                </Grid>
                              </Grid>
                              <p className="inputDescription">Company Size</p>
                              <Grid item xs={12}>
                                <Grid item xs={6}>
                                  <Autocomplete
                                    options={companySizes}
                                    autoComplete={true}
                                    value={
                                      companySize != null
                                        ? companySize
                                        : "select size"
                                    }
                                    size={"small"}
                                    onChange={(event, inputValue) => {
                                      if (inputValue != null) {
                                        this.handleChangeCompanySize(
                                          inputValue.name
                                        );
                                      }
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          value={
                                            companySize != null
                                              ? companySize
                                              : "select size"
                                          }
                                        />
                                      );
                                    }}
                                    getOptionLabel={(option) => {
                                      if (typeof option == "object")
                                        return option.name;
                                      return option;
                                    }}
                                    isOptionEqualToValue={(option, value) => {
                                      if (option != null) {
                                        if (option.name == value) return true;
                                        else return false;
                                      }
                                    }}
                                    className={"select-list "}
                                    variant={"outlined"}
                                  />
                                </Grid>
                              </Grid>
                              <p className="inputDescription">
                                How did you find out about Workflow86
                              </p>
                              <Grid item xs={12}>
                                <Grid item xs={6}>
                                  <Select
                                    name="selectType"
                                    disableUnderline
                                    variant="standard"
                                    labelId="demo-simple-select-outlined-label"
                                    className="dropdown-mat selectStandard item-select-bulk find-out-by-select"
                                    fullWidth
                                    displayEmpty
                                    value={this.state.findOutBy}
                                    onChange={(event) => {
                                      this.setState({
                                        findOutBy: event.target.value,
                                      });
                                    }}
                                  >
                                    <MenuItem value={null}>
                                      {" "}
                                      select answer{" "}
                                    </MenuItem>
                                    <MenuItem value="REFERRED_BY_A_WORKFLOW86_USER">
                                      {" "}
                                      Referred by a Workflow86 user{" "}
                                    </MenuItem>
                                    <MenuItem value="VIA_POWERED_BY_WORKFLOW86">
                                      {" "}
                                      ⚡ Powered by Workflow86{" "}
                                    </MenuItem>
                                    <MenuItem value="WORKFLOW86_WEBSITE">
                                      {" "}
                                      Workflow86 Website{" "}
                                    </MenuItem>
                                    <MenuItem value="ANOTHER_WEBSITE">
                                      {" "}
                                      Another website{" "}
                                    </MenuItem>
                                    <MenuItem value="GOOGLE"> Google </MenuItem>
                                    <MenuItem value="TWITTER">
                                      {" "}
                                      Twitter{" "}
                                    </MenuItem>
                                    <MenuItem value="LINKEDIN">
                                      {" "}
                                      LinkedIn{" "}
                                    </MenuItem>
                                    <MenuItem value="OTHER"> Other </MenuItem>
                                  </Select>
                                </Grid>
                              </Grid>
                              {this.findOutBy()}
                            </div>
                            <div className="actionArea">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  style={{ marginBottom: 100 }}
                                  className="resetButton"
                                  variant={"contained"}
                                  color={"primary"}
                                  type="submit"
                                  onClick={this.handleFormSubmission}
                                  disabled={this.state.loading}
                                >
                                  Next
                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      className="buttonProgress"
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        {this.state.formState === "setUpAutomate" && (
                          <React.Fragment>
                            <Typography component="div">
                              <div>
                                <img src="/svgs/logo.svg" alt="logo" />
                              </div>
                              <h4>💬 I want to build a workflow for...</h4>
                            </Typography>
                            <div className="inputArea">
                              {this.automateState()}
                            </div>
                            <div className="actionArea">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  className="resetButton"
                                  variant={"contained"}
                                  color={"primary"}
                                  type="submit"
                                  onClick={this.handleFormSubmission}
                                  disabled={
                                    this.state.loading ||
                                    !this.state.automateRequest
                                  }
                                >
                                  Next
                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      className="buttonProgress"
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        {this.state.formState == "setupPackage" && (
                          <Grid
                            container
                            xs={12}
                            direction={"column"}
                            alignItems={"flex-start"}
                          >
                            <h5 className={"paddingBottom"}>
                              🎁 Customize your welcome package
                            </h5>
                            <p className={"s-text"}>
                              We’d like to assemble a little welcome package
                              with some pre-built workflows to get you started.
                              Which of the following use cases are you
                              interested in?
                            </p>
                            {categories.map((category, idx) => {
                              return (
                                <FormControlLabel
                                  key={idx}
                                  label={category.name}
                                  labelPlacement={"end"}
                                  control={
                                    <Checkbox
                                      onChange={this.handleSetupPackage}
                                      value={category.value}
                                    />
                                  }
                                />
                              );
                            })}

                            <Grid
                              container
                              item
                              xs={12}
                              direction={"row"}
                              justifyContent={"flex-end"}
                            >
                              <Button
                                style={{ marginBottom: 100 }}
                                variant={"contained"}
                                color={"primary"}
                                onClick={this.setUpAccount}
                                disabled={
                                  this.state.templatesToImport.length > 0
                                    ? false
                                    : true
                                }
                              >
                                Next
                              </Button>
                            </Grid>
                          </Grid>
                        )}

                        {this.state.formState === "setUpInvitation" && (
                          <React.Fragment>
                            <Typography component="div">
                              <div>
                                <img src="/svgs/logo.svg" alt="logo" />
                              </div>
                              <h4>👷‍👷‍️ Assemble your team of builders</h4>
                            </Typography>
                            <div className="inputArea">
                              <p className="inputDescription">
                                Invite other user to help you build workflows in
                                your account. Send an invite to them by entering
                                their email below. User below will be added as{" "}
                                <a href="#" className="link-wf">
                                  Editors
                                </a>{" "}
                                to your account.
                              </p>
                              {inviteUsers.map((i, index) => {
                                return (
                                  <TextField
                                    id="inviteUser"
                                    key="inviteUser"
                                    name="inviteUser"
                                    style={{
                                      width: "100%",
                                      marginBottom: 16,
                                    }}
                                    placeholder="name@example.com"
                                    variant="outlined"
                                    type="text"
                                    value={i.email}
                                    onChange={(e) =>
                                      this.handleInviteUserInputChange(
                                        e.target.value,
                                        index
                                      )
                                    }
                                    error={i.error}
                                    InputProps={{
                                      endAdornment: i.error && (
                                        <Icon
                                          className="fa fa-exclamation-circle"
                                          style={{ color: "#f44336" }}
                                        />
                                      ),
                                    }}
                                    helperText={
                                      i.error
                                        ? "The email you entered is invalid"
                                        : null
                                    }
                                  />
                                );
                              })}
                            </div>
                            <div className="actionArea">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  className="btnSpace"
                                  size="large"
                                  variant={"outlined"}
                                  onClick={this.onSkip}
                                >
                                  {" "}
                                  Skip
                                </Button>
                                <Button
                                  className="resetButton"
                                  variant={"contained"}
                                  color={"primary"}
                                  type="submit"
                                  onClick={this.handleFormSubmission}
                                  disabled={inviteUsersDisabled}
                                >
                                  Send Invites
                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      className="buttonProgress"
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        {this.state.formState == "orgAccountSetup" && (
                          <Grid container xs={12} direction={"column"}>
                            <p>🔨 Setting up your account...</p>
                            <p className={"s-text"}>
                              Just a moment while we set up your account. Please
                              do not close or navigate away from this window.{" "}
                            </p>
                            {runLoading()}
                          </Grid>
                        )}
                        {this.state.formState === "start" && (
                          <React.Fragment>
                            <Typography component="div">
                              <div>
                                <img src="/svgs/logo.svg" alt="logo" />
                              </div>
                              <h4>
                                <b>🎉 Your account is ready to go</b>
                              </h4>
                            </Typography>
                            <div className="inputArea">
                              <p className="inputDescription">
                                Your account is set up, and you are ready to
                                start building workflows. Click Launch to enter
                                Workflow86.
                              </p>
                            </div>
                            <div className="actionArea">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  className="resetButton"
                                  variant={"contained"}
                                  color={"secondary"}
                                  type="submit"
                                  onClick={this.handleFormSubmission}
                                  disabled={this.state.loading}
                                >
                                  LAUNCH
                                  {this.state.loading && (
                                    <CircularProgress
                                      size={24}
                                      className="buttonProgress"
                                    />
                                  )}
                                </Button>
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        {this.state.formState === "referral" ? (
                          <Grid
                            container
                            item
                            xs={12}
                            direction={"column"}
                            className={"m-0"}
                          >
                            <div>
                              <img src="/svgs/logo.svg" alt="logo" />
                            </div>
                            <h4>💌 Invite code</h4>
                            <p className={"s-text"}>
                              Do you have an invite code?
                            </p>
                            <TextField
                              error={this.state.referralError}
                              helperText={
                                this.state.referralError
                                  ? "This referral code is invalid."
                                  : ""
                              }
                              size={"small"}
                              variant={"outlined"}
                              fullWidth={true}
                              onChange={this.handleChangeReferral}
                              value={this.state.referral}
                              placeholder={"Enter your invite code"}
                            />
                            <p className={"s-text invite-code-text"}>
                              🎁 When you invite someone to create a Workflow86
                              account, you both earn additional actions per
                              month for free.{" "}
                              <MLink
                                href={
                                  "https://www.workflow86.com/workflow86-invite-codes/"
                                }
                              >
                                Learn more
                              </MLink>
                            </p>
                            <Grid
                              container
                              item
                              xs={12}
                              direction={"row"}
                              alignItems={"center"}
                              spacing={2}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Grid item>
                                <Button
                                  variant={"outlined"}
                                  onClick={() => {
                                    this.setState({
                                      formState: "setupPackage",
                                    });
                                  }}
                                >
                                  Skip
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant={"contained"}
                                  color={"primary"}
                                  disabled={
                                    this.state.referral == "" ? true : false
                                  }
                                  onClick={this.handleReferral}
                                >
                                  Next
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </form>
                    )}
                  </div>
                ) : null}
              </Grid>
            )}
            {this.state.accountsLinked && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={this.state.accountsLinked}
                autoHideDuration={3000}
                onClose={() => {
                  this.setState({ accountsLinked: false });
                }}
                key={"top" + "center"}
              >
                <Alert
                  onClose={() => {
                    this.setState({ accountsLinked: false });
                  }}
                  severity="success"
                  variant="filled"
                >
                  {
                    "Your Google account is now linked to your Workflow86 account. Sign in again to continue."
                  }
                </Alert>
              </Snackbar>
            )}
          </Grid>
        </div>
      </React.Fragment>
    ) : (
      <AIOnboardingPage
        handleStart={() => {
          this.props.firstLogin(false);
          this.props.onStateChange("signedIn", {});
        }}
        onboardingStatus={this.state.onboardingStatus}
      />
    );
  }
}
