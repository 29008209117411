import React from "react";
import { Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const AiInstructionText = styled(Typography)({
    fontSize: '11px',
    fontWeight: '400 !important',
    lineHeight: '18px',
    textAlign: 'left',
    fontStyle: 'italic !important',
    color: '#00000080'
});

const InstructionsInfo = ({ data }) => {
    const renderInstructionFields = () => {
        return Object.entries(data.instructions)
            .filter(([instructionKey, instructionValue]) => instructionValue)
            .map(([instructionKey, instructionValue]) => (
                <Grid item direction="column" key={instructionKey}>
                    <AiInstructionText>
                        {"🪄 " + instructionValue}
                    </AiInstructionText>
                </Grid>
            ));
    };

    return renderInstructionFields();
}

export default InstructionsInfo;
