import React from "react";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { styled } from "@mui/system";

const StyledFormatListNumberedIcon = styled(FormatListNumberedIcon)({
    color: "rgba(0, 0, 0, 0.54) !important",
});

function ListIcon(props) {
    return <StyledFormatListNumberedIcon {...props} />;
}

export default ListIcon;
