const styles = theme => ({
  headerLeft: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    maxWidth: 570,
    gridColumnStart: 1,
    gridColumnEnd: 3
  },
  headerRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  main: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnStart: 1,
    gridColumnEnd: 4
  },
  wrapCode: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: 24,
    background: "#fff",
    marginBottom: 16
  },
  label: {
    fontWeight: 600,
    alignItems: "center",
    display: "grid"
  },
  secondLevelLabel: {
    fontWeight: 400,
    alignItems: "center",
    display: "grid"
  },
  select: {
    width: "100%",
    display: "inline-block"
  },
  wrapFlexCenter: {
    display: "flex",
    justifyContent: "center",
    margin: "32px 0"
  },
  textContentCenter: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    "&:hover": {
      color: "#2196F3"
    }
  },
  loadMore: {
    background: "#000000de",
    borderRadius: "4px",
    color: "#fff",
    "&:hover": {
      filter: " brightness(0.85)",
      background: "#000000de"
    },
    "&:active": {
      filter: " brightness(0.85)",
      background: "#000000de"
    }
  }
});
export default styles;
