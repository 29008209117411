import React, { useReducer } from "react";
import {
  Grid,
  Table,
  Switch,
  Tooltip,
  Checkbox,
  TableRow as Row,
  TableHead as Head,
  TableBody as Body,
  TableCell as Cell,
  CircularProgress
} from "@mui/material";
import "./css/access.css";
import Button from "@mui/material/Button";
import { parseType } from "../../utils/parseType";
import { HelpOutline } from "@mui/icons-material";
import { CustomDialog } from "../Component/Dialog";
import { send_request } from "../../utils/Request";
import ProjectStore from "./ProjectStore";
import { runLoading } from "../../utils/showLoadingScreen";
import { getCurrentUser } from 'aws-amplify/auth'; 
import { withParams } from "../../Routes";
class Access extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      components: [],
      executors: [],
      loading: false,
      delete: [],
      disable: false,
      error: false,
      accountStatus: "",
      initialExecutors: []
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const json_execs = await send_request(
      "authz-service/get_executors_by_project_id/" + this.props.projectId,
      "",
      "",
      "get"
    )
      .then(async value => {
        //get a list of mixed project and component executors out of the db
        this.setState({ executors: value.data });
        this.setState({ initialExecutors: value.data });

        const json_components = await send_request(
          `project-service/project/get_access_components/${this.props.projectId}`
        )
          .then(value => {
            if (value.data.length == 0) return this.setState({ error: true });
            this.setState({ components: value.data });

            //replace project executors with component executors
            let newExecutors = [];

            this.state.executors.map((executor, key) => {
              if (executor.componentId == null) {
                //this is a project executor

                this.state.components.map((component, inx) => {
                  //for each component create a component executor
                  if (
                    component.type != "webhook" 
                  ) {
                    let newExecutor = {
                      componentId: component.componentId,
                      user: executor.user,
                      project: { projectId: this.props.projectId }
                    };

                    newExecutors.push(newExecutor);
                  } else component.isPublic = true;
                });

                this.state.delete.push(executor); //delete project executor
              }
            });
            //check if there are project executors
            let projectExecutors = this.state.executors.filter(exec => {
              return exec.componentId == null;
            });

            if (projectExecutors.length > 0) {
              //replace project executors with component executors
              this.setState({
                executors: newExecutors
              });
            } else {
              //use data from the request
              this.setState({
                executors: this.state.executors
              });
            }
            this.setState({
              delete: this.state.delete
            });
            //check if component is public

            this.state.components.map((component, key) => {
              component.isPublic = false;

              //check for executors for this component
              let executorsForThisComponent = this.state.executors.filter(
                executor => {
                  return executor.componentId == component.componentId;
                }
              );

              if (executorsForThisComponent.length > 0) {
                component.isPublic = false;
              } else component.isPublic = true;

              if (component.type == "webhook")
                component.isPublic = true;
            });

            this.setState({ components: this.state.components });
          })
          .catch(e => {
            console.log(e);
          });
        const json_users = await send_request(
          "authz-service/get-users/",
          "",
          "",
          "get"
        )
          .then(value => {
            let enabledUsers = value.data.filter(user => {
              return user.roleDisabled != true;
            });
            this.setState({ users: enabledUsers });
          })
          .catch(err => console.log(err));
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => console.log(e));

    try {
      const user = await getCurrentUser();
      if (user) {
        if (user.signInDetails?.loginId.includes('@')) {
          this.setState({
            accountStatus: "GOOGLE"
          });
        } else {
          this.setState({
            accountStatus: "USERNAME"
          });
        }
      }
    } catch (error) {
      console.error('Error getting current user:', error);
    }

    this.setState({ loading: false });
  }

  handleSwitch = (key, event) => {
    //this switches between component being public and private
    this.state.components[key].isPublic = !this.state.components[key].isPublic;
    this.setState({ components: this.state.components });

    if (this.state.components[key].isPublic) {
      //if component is set to Public
      let executorsForThisComponent = this.state.executors.filter(executor => {
        return executor.componentId == this.state.components[key].componentId;
      });

      //delete component executors
      this.state.delete.push.apply(
        this.state.delete,
        executorsForThisComponent
      );

      executorsForThisComponent.map((executor, id) => {
        let indx = this.state.executors.indexOf(executor);
        if (indx != -1) this.state.executors.splice(indx, 1);
      });
      this.setState({ executors: this.state.executors });
    }

    this.setState({
      delete: this.state.delete,
      components: this.state.components,
      executors: this.state.executors
    });
  };

  handleBack = () => {
    //clear out state
    this.setState({
      loading: false,
      users: [],
      components: [],
      executors: [],
      error: false
    });
    this.props.close();
  };

  handleSave = async () => {
    //save
    for (let executor of this.state.executors) {
      if (!executor.executorId) {
        //this is a new executor
        await this.grantPermission(executor);
      }
    }

    this.setState({ loading: true });

    for (let toDelete of this.state.delete) {
      await this.deletePermission(toDelete);
    }

    this.handleBack();
    this.props.handleRerenderBadge();
  };

  deletePermission = async toDelete => {
    if (toDelete) {
      if (toDelete.executorId) {
        return await send_request(
          "authz-service/delete_user_as_executor",
          "",
          {
            executorId: toDelete.executorId
          },
          "delete"
        )
          .then(value => {})
          .catch(err => console.log(err));
      }
    }
  };

  grantPermission = async executor => {
    let component = this.state.components.find(component => {
      return executor.componentId == component.componentId;
    });

    if (!component.isPublic) {
      let newExecutor = {
        projectId: this.props.projectId,
        userId: executor.user.userId,
        componentId: executor.componentId
      };
      return await send_request(
        "authz-service/set_user_as_executor",
        newExecutor,
        "",
        "post"
      )
        .then(value => {})
        .catch(err => console.log(err));
    }
  };

  handleCheckbox = (component_key, user_key, checked) => {
    if (checked) {
      //the check box is empty
      //this means we are removing this executor from the executors array

      //1. find executors that can execute this component
      let executorsWithThisComponent = this.state.executors.filter(executor => {
        return (
          executor.componentId ==
          this.state.components[component_key].componentId
        );
      });
      //2. find an executor that has the correct user
      let executor = executorsWithThisComponent.find(executor => {
        return executor.user.userName == this.state.users[user_key].userName;
      });
      //3. find the index in the executors array
      let index = this.state.executors.indexOf(executor);
      //4. add to the deletion array
      if (this.state.delete.indexOf(executor) == -1) {
        this.state.delete.push(this.state.executors[index]);
      }
      //5. remove the executor from the array
      this.state.executors.splice(index, 1);

      this.setState({
        executors: this.state.executors,
        delete: this.state.delete
      });
    } else {
      //the check box is checked

      //1. create a new executor
      let executor = {
        componentId: this.state.components[component_key].componentId,
        user: this.state.users[user_key],
        project: { projectId: this.props.projectId }
      };
      //2. add to the executors
      this.state.executors.push(executor);

      //3. check if the executor already in the deletion array
      let toDelete = this.state.delete.find(executor => {
        return (
          executor.user.userName == this.state.users[user_key].userName &&
          executor.componentId ==
            this.state.components[component_key].componentId
        );
      });
      let indexToDelete = this.state.delete.indexOf(toDelete);
      if (indexToDelete != -1) {
        this.state.delete.splice(indexToDelete, 1);
        this.setState({ delete: this.state.delete });
      }
      this.setState({ executors: this.state.executors });
    }
  };

  handleRedirect = component => {
    let node = ProjectStore.aggregate.components.find(cp => {
      return cp.component_id == component.componentId;
    });
    const node_id = node.view.node_id;
    let type = component.type;
    if (type == "form") type = "formbuilder";
    this.props.navigate(
      window.location.pathname + `/${type}/${component.componentId || ""}`,
      {
        search: `?node_id=${node_id}`
      }
    );
  };

  handleRerender = () => {
    this.props.handleRerenderBadge();
  };

  render() {
    let {
      components,
      users,
      executors,
      loading,
      error,
      accountStatus
    } = this.state;
    const { published } = this.props;
    if (error)
      return (
        <CustomDialog
          size={"md"}
          isOpen={this.state.error}
          title={"Access Control"}
          contents={
            <Grid container item direction={"column"} xs={12}>
              <p className={"s-text"}>
                Control who can access the user-facing components in this
                workflow
              </p>
              <Grid
                container
                item
                direction={"row"}
                alignItems={"center"}
                xs={12}
              >
                <i className={"material-icons default-small-icon "}>error</i>
                <p className={"m-0"}>
                  There are no user-facing components in this workflow.
                </p>
              </Grid>
            </Grid>
          }
          buttons={
            <Button
              variant={"outlined"}
              color={"info"}
              onClick={this.handleBack}
            >
              Close
            </Button>
          }
        />
      );
    else if (!loading)
      return (
        <CustomDialog
          isOpen={this.props.open && !this.state.error}
          size={"lg"}
          title={"Access Control"}
          contents={
            <>
              <p className={"s-text"}>
                Control who can access the user-facing components in this
                workflow.
              </p>
              <Table>
                <Head>
                  <Row>
                    <Cell>User</Cell>
                    {components.map((component, key) => {
                      let name = component.name;
                      if (!name) name = component.componentId;
                      return (
                        <Cell key={key} align={"center"}>
                          <Grid
                            container
                            item
                            xs={12}
                            direction={"column"}
                            alignItems={"center"}
                          >
                            <Tooltip title={name}>
                              <p
                                className={"s-text clickable trim-name"}
                                onClick={() => this.handleRedirect(component)}
                              >
                                {name}
                              </p>
                            </Tooltip>
                            <p className={"s-text trim-name"}>
                              {parseType(component.type)}
                            </p>
                          </Grid>
                        </Cell>
                      );
                    })}
                  </Row>
                  <Row>
                    <Cell className={"tableCell"}>
                      <Grid
                        item
                        container
                        direction={"row"}
                        alignItems={"center"}
                      >
                        🌏 Public
                        <Tooltip
                          title={
                            "When this is turned on, the component can be accessed by anyone."
                          }
                        >
                          <HelpOutline className={"help"} />
                        </Tooltip>
                      </Grid>
                    </Cell>
                    {components.map((component, key) => {
                      return (
                        <Cell
                          align={"center"}
                          key={key}
                          className={"tableCell"}
                        >
                          <Switch
                            value={component.isPublic}
                            checked={component.isPublic}
                            disabled={
                              component.type == "webhook" 
                                ? true
                                : false
                            }
                            onChange={() => this.handleSwitch(key, event)}
                            color="primary"
                          />
                        </Cell>
                      );
                    })}
                  </Row>
                </Head>
                <Body>
                  {users.map((user, user_key) => {
                    return (
                      <Row key={user_key}>
                        <Cell className={"tableCell"}>{user.displayName}</Cell>
                        {components.map((component, component_key) => {
                          let checked = false;

                          executors.map(executor => {
                            if (
                              executor.user.userId == user.userId &&
                              (executor.componentId == component.componentId ||
                                (executor.project.projectId ==
                                  this.props.projectId &&
                                  executor.componentId == null))
                            ) {
                              return (checked = true);
                            }
                          });
                          return (
                            <Cell
                              align={"center"}
                              key={component_key}
                              className={"tableCell"}
                            >
                              <Checkbox
                                disabled={component.isPublic ? true : false}
                                value={component.isPublic ? false : checked}
                                checked={component.isPublic ? false : checked}
                                onChange={() => {
                                  this.handleCheckbox(
                                    component_key,
                                    user_key,
                                    checked
                                  );
                                }}
                              />
                            </Cell>
                          );
                        })}
                      </Row>
                    );
                  })}
                </Body>
              </Table>
            </>
          }
          buttons={
            <>
              <Button
                variant={"outlined"}
                color={"info"}
                disable={loading}
                onClick={this.handleBack}
              >
                Close
              </Button>
              <Button
                variant={"contained"}
                color={"primary"}
                disable={loading}
                onClick={() => {
                  this.handleSave();
                }}
              >
                {loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                Save
              </Button>
            </>
          }
        />
      );
    else
      return (
        <CustomDialog isOpen={loading} size={"lg"} contents={runLoading()} />
      );
  }
}
export default withParams(Access);