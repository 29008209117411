import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// Utils
import config from "../../../../config";
import AIBuilderStyles from "./AIBuilderStyles";
import ChatHistoryBubble from "./util/ChatHistoryBubble";
import { send_request } from "../../../../utils/Request";
import projectStore from "../../../ProjectCanvas/ProjectStore";
import { StyledSkeleton } from "../RenamePlaceholderLogic/RenameStyledComponents";
import { getFormattedDateFromUTC, print_timeline_date } from "../../../../utils/Date";

// Material UI
import { Drawer, Button, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";

import { Autorenew } from "@mui/icons-material";

const styles = AIBuilderStyles;

const TopGrid = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
});

const GridContainer = styled("div")({
    height: "100%",
    display: "flex",
    flexDirection: "column",
});

const BottomGrid = styled("div")({
    flexGrow: 1,
    overflow: "auto",
    paddingRight: "8px",
});

const SessionList = styled("div")({});

const EventDate = styled("span")({
    color: "#5A5A5A",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px" /* 171.429% */,
});

const ITEMS_PER_PAGE = 25;

function SessionHistoryPanel(props) {
    // pagination
    const bottomGridRef = useRef();
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);

    const [initialLoading, setInitialLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const [latestEvents, setLatestEvents] = useState(null);
    const [groupedSessions, setGroupedSessions] = useState([]);

    // For initial loading of page
    useEffect(async () => {
        await fetchFirstPage();
    }, []);



    useEffect(() => {
        const element = bottomGridRef.current;
        if (!element) return;

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };
    }, [page, latestEvents, total]);

    const wait = (ms) =>
        new Promise((res) => {
            let timeout = setTimeout(res, ms);
            return timeout;
        });

    const handleScroll = (event) => {
        // if (initialLoading || loadingMore) return;
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        // Check if we've scrolled to the bottom
        if (scrollHeight - scrollTop === clientHeight) {
            fetchMore(page + 1);
        }
    };

    const fetchFirstPage = async () => {
        setInitialLoading(true);
        setPage(0);
        props.setResetHistory(null);

        let sourceId = props.projectId;
        if (props.aiEventSource==="COMPONENT_EDITOR"||props.aiEventSource==="COMPONENT_BUILDER") sourceId = props.componentId;
        let url = `project-service/project-ai/assistant/retrieve_latest_session_history_by_source_id/${sourceId}/${0}/${ITEMS_PER_PAGE}`;
        const json = await send_request(url, null, { aiEventSource: props.aiEventSource }, "GET");

        if (json && json.data) {
            setTotal(json.data.total);
            setLatestEvents(json.data.historyItems);
            let groupedSessions = groupSessionsByDate(json.data.historyItems);
            setGroupedSessions(groupedSessions);
        }

        setInitialLoading(false);
    };

    const fetchMore = async (givenPage) => {
        if (latestEvents.length >= total) return;

        setLoadingMore(true);
        setPage(givenPage);

        let sourceId = props.projectId;
        if (props.aiEventSource.length>1) sourceId = props.componentId;

        let url = `project-service/project-ai/assistant/retrieve_latest_session_history_by_source_id/${sourceId}/${givenPage}/${ITEMS_PER_PAGE}`;
        const json = await send_request(url, null, null, "GET");

        if (json && json.data) {
            setTotal(json.data.total);

            let updatedLatestEvents = !latestEvents ? json.data.historyItems : [...latestEvents, ...json.data.historyItems];
            setLatestEvents(updatedLatestEvents);

            let groupedSessions = groupSessionsByDate(updatedLatestEvents);
            setGroupedSessions(groupedSessions);
        }

        setLoadingMore(false);
    };

    const groupSessionsByDate = (sessions) => {
        sessions = sessions.filter((session) => session.timestamp !== null);

        const groups = sessions.reduce((groups, session) => {
            const date = getFormattedDateFromUTC(session.timestamp, projectStore.state.timezone);

            if (!groups[date]) groups[date] = [];

            groups[date].push(session);
            return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => ({
            date,
            events: groups[date],
        }));

        return groupArrays.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
    };

    const isYesterday = (someDate) => {
        const userTimezone = dayjs.tz.guess();
        const yesterday = dayjs().tz(userTimezone).subtract(1, 'day');

        return yesterday.isSame(someDate, 'day');
    };

    const isToday = (someDate) => {
        const userTimezone = dayjs.tz.guess();
        const today = dayjs().tz(userTimezone);

        return today.isSame(someDate, 'day');
    };

    const renderEventDate = (date) => {
        const userTimezone = dayjs.tz.guess();
        const localDate = dayjs(date).tz(userTimezone);

        if (isToday(localDate)) {
            return "Today";
        } else if (isYesterday(localDate)) {
            return "Yesterday";
        } else {
            return print_timeline_date(localDate);
        }
    };

    const getContent = () => {
        if (initialLoading)
            return (
                <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                    <div style={{ marginBottom: "12px" }}>
                        <StyledSkeleton variant="rectangular" height="20px" width="175px" />
                    </div>
                    {[1, 2, 3, 3, 5, 6, 7, 8, 9, 10].map(() => {
                        return (
                            <div style={{ marginBottom: "12px" }}>
                                <StyledSkeleton variant="rectangular" height="45px" />
                            </div>
                        );
                    })}
                </div>
            );

        if (total === 0 && !initialLoading && !loadingMore) return <div style={{ marginTop: "20px" }}>No previous chat sessions</div>;

        return (
            <SessionList>
                {groupedSessions.map(({ date, events }, idx) => {
                    return (
                        <div key={idx} style={{ marginBottom: "32px" }}>
                            <div style={{ marginBottom: "16px" }}>
                                <EventDate>{renderEventDate(date)}</EventDate>
                            </div>
                            <div>
                                {events.map((event) => {
                                    let isSelected = false;
                                    if (props.selectedHistorySession && props.selectedHistorySession.sessionId === event.sessionId && props.selectedHistorySession.threadId === event.threadId) {
                                        isSelected = true;
                                    }

                                    return <ChatHistoryBubble key={event.eventId} event={event} isSelected={isSelected} setSelectedHistorySession={props.setSelectedHistorySession} />;
                                })}
                            </div>
                        </div>
                    );
                })}
                {loadingMore && (
                    <div>
                        {[1, 2, 3].map(() => {
                            return (
                                <div style={{ marginBottom: "8px" }}>
                                    <StyledSkeleton variant="rectangular" height="45px" />
                                </div>
                            );
                        })}
                    </div>
                )}
            </SessionList>
        );
    };

    return (
        <Drawer
            open
            anchor={"right"}
            hideBackdrop={true}
            variant={"permanent"}
            onClose={() => props.handleClose()}
            PaperProps={{
                sx: {
                    width: config.SESSION_HISTORY_PANEL_WIDTH,
                    right: props.distanceFromRight,
                    height: "100%",
                    zIndex: 100000,
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100% - 32px)",
                    maxHeight: "calc(100vh - 32px)",
                    padding: "8px 8px 8px 16px",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    marginRight: props.isExpandedPage && "16px"
                },
            }}
        >
            <GridContainer>
                <TopGrid>
                    <Button disabled={initialLoading} sx={{ textTransform: "none", color: "#868686", minWidth: "0px", padding: "6px 6px 12px 3px" }} onClick={() => fetchFirstPage()}>
                        <Autorenew fontSize={"small"} color={"info"} />
                    </Button>
                    <Button disabled={initialLoading} sx={{ textTransform: "none", color: "#868686", minWidth: "0px", padding: "6px 6px 12px 3px" }} onClick={() => props.handleClose()}>
                        <CloseIcon fontSize={"small"} color={"info"} />
                    </Button>
                </TopGrid>
                <BottomGrid ref={bottomGridRef}>{getContent()}</BottomGrid>
            </GridContainer>
        </Drawer>
    );
}

export default withStyles(styles)(SessionHistoryPanel);
