import React, { Component } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { toJS } from "mobx";
import ReactJson from "react-json-view";
import APIResReqStore from "../APIResReqStore";
import Grid from "@mui/material/Grid";
import HandleJsonFunction from "../../Component/HandleJsonFunction";
import AlertMessage from "./AlertMessage";

class ResponseReceived extends Component {
  constructor(props) {
    super(props);
  }

  handleJson = async () => {
    const { responseData, name } = toJS(APIResReqStore);
    await HandleJsonFunction.handleAddPlaceholdersJson(
      responseData.json,
      APIResReqStore
    );
    await this.props.onChangeState(2, 2);
  };

  handleReactJson = () => {
    const { responseData } = toJS(APIResReqStore);
    if (responseData.json != "") {
      let jsonData = JSON.parse(responseData.json);

      return (
        <ReactJson
          src={jsonData ? jsonData : {}}
          collapsed="true"
          name="Data"
          displayDataTypes={false}
          style={{ fontFamily: "Open Sans !important" }}
          theme={{
            base00: "rgba(1, 1, 1, 0)",
            base01: "rgba(1, 1, 1, 0.1)",
            base02: "rgba(0, 0, 0, 0.2)",
            base03: "rgba(1, 1, 1, 0.3)",
            base04: "rgba(0, 0, 0, 0.4)",
            base05: "rgba(1, 1, 1, 0.5)",
            base06: "rgba(1, 1, 1, 0.6)",
            base07: "rgba(1, 1, 1, 0.7)",
            base08: "rgba(1, 1, 1, 0.8)",
            base09: "rgba(1, 1, 1, 0.8)",
            base0A: "rgba(1, 1, 1, 0.8)",
            base0B: "rgba(1, 1, 1, 0.8)",
            base0C: "rgba(1, 1, 1, 0.8)",
            base0D: "rgba(1, 1, 1, 0.8)",
            base0E: "rgba(1, 1, 1, 0.8)",
            base0F: "rgba(1, 1, 1, 0.8)",
          }}
        />
      );
    }
  };

  render() {

    return (
      <Grid container className="textAlignLeft">
        <AlertMessage
          message={"A sample has been received. Continue to the next step or click Send Request to get another sample."}
          alertType="success"
        />
        <br />
        <Grid item xs={12} className="json-webhook">
          {this.handleReactJson()}
        </Grid>
        <Grid item xs={12} className="dialogContentRight">
          <Grid item xs={12}>
            <Button onClick={this.handleJson} variant={"contained"} color={"primary"} >
              {" "}
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ResponseReceived;
