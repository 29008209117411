import React from 'react';

export default function HideNotesIcon({
                                     size = 21, // or any default size of your choice
                                     color = "black" // or any color of your choice
                                 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={size} // added size here
            height={size} // added size here
            fill={color} // added color here
            id="hide_note_icon"
        >
                <path d="M20.0004 16.1508L18.0004 14.1508V3.00078H6.85039L4.85039 1.00078H18.0004C18.5504 1.00078 19.0214 1.19645 19.4134 1.58778C19.8047 1.97978 20.0004 2.45078 20.0004 3.00078V16.1508ZM18.8004 20.6008L17.2004 19.0008H4.00039C3.45039 19.0008 2.97939 18.8051 2.58739 18.4138C2.19606 18.0218 2.00039 17.5508 2.00039 17.0008V3.80078L0.400391 2.20078L1.80039 0.800781L20.2004 19.2008L18.8004 20.6008ZM13.1754 15.0008L11.0754 12.9008L4.00039 5.82578V17.0008H15.1754L13.1754 15.0008Z" fill="#1C1B1F"/>



        </svg>
    )
}