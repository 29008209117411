import React from "react";

import User from "./Messages/User";
import Loading from "./Messages/Loading";
import Error from "./Messages/Error";
import Instruction from "./Messages/Instruction";
import Success from "./Messages/Success";
import GPTResponse from "./Messages/GPTResponse";
import ButtonMessage from "./Messages/ButtonMessage";
import ComponentInstruction from "./Messages/ComponentInstruction";
import WorkflowBuilderMessage from "./Messages/initial/WorkflowBuilderMessage";
import StreamingMessage from "./DynamicMessages/StreamingMessage";
import ComponentWorkflowInstruction from "./Messages/ComponentWorkflowInstruction";
import IndividualComponentStatus from "./DynamicMessages/IndividualComponentStatus";
import AIDataBasesCreated from "./Messages/AIDataBasesCreated";
import TestRunInstructionMessage from "./Messages/TestRunInstructionMessage";
import QuestionAnswerMessage from "./DynamicMessages/QuestionAnswerMessage";
import MessageTypePropTypes from "./PropTypes/MessageTypePropTypes";
import InitialMessage from "./DynamicMessages/InitialMessage";
import StrategyMessage from "./Messages/initial/StrategyMessage";
import ProcessDiscoveryMessage from "./Messages/initial/ProcessDiscoveryMessage";
import InsertLoadingMessage from "./Messages/InsertLoadingMessage";
import WorkflowEditorMessage from "./Messages/initial/WorkflowEditorMessage";
import ComponentInitialMessage from "./DynamicMessages/ComponentInitialMessage";
import BuildComponentMessage from "./Messages/initial/BuildComponentMessage";
import EditComponentMessage from "./Messages/initial/EditComponentMessage";
import SyncStateSkeleton from "./DynamicMessages/SyncStateSkeleton";
import EditChangesProgressMessage from "./DynamicMessages/EditChangesProgressMessage";
import Suggestions from "./Messages/initial/Suggestions";
import ImageChip from "../../util/ImageChip";
import { ROLES } from "./MessageRole";

export default function MessageType({
  index,
  message,
  startTime,
  completedTime,
  buildComponentInfo,
  editComponentInfo,
  applyingEditStages,
  setApplyingEditStages,
  undoEditChanges,
  editChanges,
  draftVersion,
  projectId,
  retryApplyingEdit,
  sessionId,
  streamingStatus,
  setViewportOnGenerate,
  viewportOnGenerate,
  setNewDiagram,
  setStreamingStatus,
  setError,
  prebuildQuestionAnswers,
  setPrebuildQuestionAnswers,
  messages,
  restartComponentBuild,
  setWorkflowName,
  openSessionHistory,
  setSidebarState,
  sidebarState,
  dismissContainerRef,
  syncCompleted,
  messageSent,
  focusObject,
  setUserPrompt,
  nodes,
  generateWithQuesAns,
    classes,
    compare
}) {
  switch (message.role) {
    case "loading":
      return (
        <Loading
          startTime={startTime}
          completedTime={completedTime}
          content={message.content}
          showAnimation={message.showAnimation}
          title={message.title}
        />
      );
    case ROLES.USER:
      return <User content={message.content} />;
    case "assistant":
      return (
        <GPTResponse
          content={message.content}
          endTime={completedTime}
          startTime={startTime}
          showTimer={message.showTimer}
          isJson={message.isJson}
          showProposalText={message.showProposalText}
          compare={compare}
          classes={classes}
        />
      );
    case "success":
      return <Success content={message.content} />;
    case "error":
      return <Error content={message.content} />;
    case "instruction":
      return <Instruction content={message.content} />;
    case "component_instruction":
      return <ComponentInstruction content={message.content} />;
    case "buttonMessage":
      return <ButtonMessage content={message.content} />;
    case "dynamicMessage":
      return (
        <WorkflowBuilderMessage
          content={message.content}
          shouldDisable={messages.length > 1}
        />
      );
    case "suggestions":
      return (
        <Suggestions
          setUserPrompt={setUserPrompt}
          sidebarState={message.sidebarState}
        />
      );
    case "componentBuildInstruction":
      return <ComponentWorkflowInstruction content={message.content} />;
    case "componentBuildStatus":
      return (
        <IndividualComponentStatus
          content={message.content}
          buildOrEditComponentInfo={
            !message.isEditor ? buildComponentInfo : editComponentInfo
          }
          timeout={1000}
          restartComponentBuild={restartComponentBuild}
          pollNo={message.pollNo}
          title={message.title}
          isEditor={message.isEditor}
          focusObject={focusObject}
          nodes={nodes}
        />
      );
    case "editChangesProgressMessage":
      return (
        <EditChangesProgressMessage
          projectId={projectId}
          draftVersion={draftVersion}
          editChangesDTO={editChanges ? editChanges.editChangesDTO : null}
          applyingEditStages={applyingEditStages}
          setApplyingEditStages={setApplyingEditStages}
          undoEditChanges={undoEditChanges}
          retryApplyingEdit={retryApplyingEdit}
          isLatestMessage={index === messages.length - 1}
          loadingHistory={message.loadingHistory}
        />
      );
    case "streamingMessage":
      return (
        <StreamingMessage
          message={message}
          sessionId={sessionId}
          streamingStatus={streamingStatus}
          showAnimation={message.showAnimation}
          setViewportOnGenerate={setViewportOnGenerate}
          viewportOnGenerate={viewportOnGenerate}
          startTime={startTime}
          loadingHistory={message.loadingHistory}
          setWorkflowName={setWorkflowName}
          setNewDiagram={setNewDiagram}
          setStreamingStatus={setStreamingStatus}
          setError={setError}
          openSessionHistory={openSessionHistory}
          sidebarState={sidebarState}
          pauseDrawingLogic={message.pauseDrawingLogic}
        />
      );
    case "dataBasesCreated":
      return <AIDataBasesCreated content={message.content} />;
    case "testRunInstructionMessage":
      return <TestRunInstructionMessage message={message} />;
    case "questionAnswerMessage":
      return (
        <QuestionAnswerMessage
          content={message.content}
          historyAnswers={message.historyAnswers}
          prebuildQuestionAnswers={prebuildQuestionAnswers}
          setPrebuildQuestionAnswers={setPrebuildQuestionAnswers}
          messages={messages}
          dismissContainerRef={dismissContainerRef}
          generateWithQuesAns={generateWithQuesAns}
        />
      );
    case "initialMessage":
      return <InitialMessage setSidebarState={setSidebarState} />;
    case "initialComponentMessage":
      return <ComponentInitialMessage setSidebarState={setSidebarState} />;
    case "strategy":
      return <StrategyMessage />;
    case "processDiscovery":
      return <ProcessDiscoveryMessage />;
    case "workflowEditor":
      return <WorkflowEditorMessage />;
    case "insertLoadingMessage":
      return <InsertLoadingMessage isStreaming={message.isStreaming} />;
    case "buildComponentMessage":
      return (
        <BuildComponentMessage
          setSidebarState={setSidebarState}
          sidebarState={sidebarState}
          messageSent={messageSent}
          componentType={message.componentType}
        />
      );
    case "editComponentMessage":
      return (
        <EditComponentMessage
          setSidebarState={setSidebarState}
          sidebarState={sidebarState}
          messageSent={messageSent}
          componentType={message.componentType}
        />
      );
    case "syncState":
      return <SyncStateSkeleton syncCompleted={syncCompleted} />;
    case ROLES.IMAGE:
      return <ImageChip files={message.content} />;
    default:
      return null;
  }
}

MessageType.propTypes = MessageTypePropTypes;
