import { computed, observable, decorate, action, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";

class BulkAssessmentStore {
  constructor() {
    this.initialiseEmpty();
  }
  componentKeyData = () => {
    return {
      id: (Math.floor(Math.random() * 90000000) + 10000).toString(),
      keyName: '',
      dataType: "text",
    };
  };

  addComponentKeyData = () => {
    const obj = this.componentKeyData();
    this.data.fields.push(obj);
  };

  addDataFromExcel = (data) => {
    let fields = [];
    for (let i = 0; i < data[0].length; i++) {
      let keyName = ""
      if (typeof data[0][i] == "string"){
        keyName = data[0][i].toLowerCase().trim().split(" ").join("_")
      }else if (typeof data[0][i] == "number") {
        keyName = data[0][i]
      }

      fields.push({
        id: (Math.floor(Math.random() * 90000000) + 10000).toString(),
        keyName: keyName,
        dataType: "text",
      })
    }
    if (fields.length > 0) this.data.fields = fields;
  }


  deleteComponentKeyData = index => {
    this.data.fields.splice(index, 1);
  };

  setField = (value, index, type) => {
    switch (type) {
      case "dataType":
        this.data.fields[index].dataType = value;
        return;
      case "keyName":
        this.data.fields[index].keyName = value;
        return;
    }
  };

  setData = component => {
    this.componentId = component.componentId;
    this.type = component.type;

    if (component.componentData) {
      const fields = component.componentData.data.fields.map(d => {
        return {
          id: (Math.floor(Math.random() * 90000000) + 10000).toString(),
          keyName: d.keyName,
          dataType: d.dataType,
        }
      })
      this.name = component.componentData.name;
      this.data = {
        ...component.componentData.data,
        fields
      };
      this.lastModified = component.componentData.lastModified;
    } else {
      this.name = "";
      this.data = this.initData();
      this.lastModified = "";
    }
  };

  setTemplateData = data => {
    this.data = data;
  }

  setContent = (value, type) => {
    switch (type) {
      case "key":
        this.data.key = value;
        return;
      // case "uploadPortal":
      //   this.data.uploadPortal = value;
      //   return;
      case "text":
        this.data.text = value;
        return;
    }
  };

  initData = () => {
    const obj = this.componentKeyData();
    return {
      key: this.keyAuto(),
      // uploadPortal: "internal_portal",
      fields: [obj]
    };
  }

  keyAuto = () => {
    return "bulkassessment" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }

  initialiseEmpty = () => {
    this.componentId = "";
    this.name = "";
    this.lastModified = "";
    this.data = this.initData();
    this.type = "bulk_assessment";
  };

  get template() {
    return {
      componentId: this.componentId,
      lastModified: this.lastModified,
      name: this.name,
      data: this.data,
      type: this.type,
    };
  }
}

decorate(BulkAssessmentStore, {
  componentId: observable,
  lastModified: observable,
  name: observable,
  data: observable,
  type: observable,
  setData: action,
  setContent: action,
  initialiseEmpty: action,
  template: computed,
  addComponentKeyData: action,
  deleteComponentKeyData: action,
  setField: action,
});

const bulkAssessmentStore = new BulkAssessmentStore();
export default bulkAssessmentStore;
