import React, { useState, useEffect } from "react";
import { Close, Link, LinkOff, OpenInNew, Refresh, Storage } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, styled, Skeleton } from "@mui/material";

const DescriptionText = styled("span")({
    fontSize: "13px",
    color: "rgba(117, 117, 117, 1)",
    lineHeight: "18px",
});

const DatabaseItem = styled(Grid)({
    padding: "6px",
    background: "rgba(245, 245, 245, 1)",
    borderRadius: "4px",
    fontSize: "14px",
});

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "6px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

const OpenIcon = styled(OpenInNew)({
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33, 150, 243, 1)",
    },
});

const BlueText = styled("span")({
    fontSize: "14px",
    color: "rgba(33, 150, 243, 1)",
});

function ConnectedDBs({ loading, setLoading, connectedDBs, setConnectedDBs }) {
    const getConnectedDatabases = () => {
        if (loading) {
            return [0, 1, 2].map(() => {
                return <StyledSkeleton variant="rectangular" height="28px" />;
            });
        }

        return connectedDBs.map((dB) => {
            return (
                <DatabaseItem item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                    <Grid item display="flex" alignItems="center" justifyContent="center">
                        <Storage fontSize="14px" style={{ marginRight: "16px" }} />
                        <BlueText>{dB.name}</BlueText>
                    </Grid>
                    <Grid item>
                        <OpenIcon fontSize="14px" onClick={() => window.open(`/databases/database_management/${dB.databaseId}`, "_blank")} />
                    </Grid>
                </DatabaseItem>
            );
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
                <Grid container spacing={1}>
                    <Grid item>
                        <Link style={{ width: "18px " }} />
                    </Grid>
                    <Grid item>
                        <b>Active database connections</b>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DescriptionText>These databases are actively connected to and being used by components in this workflow</DescriptionText>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap="8px">
                    {getConnectedDatabases()}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ConnectedDBs;
