const styles = (theme) => ({
  Header: {
    width: "100%",
    padding: "16px",
  },
  HeaderTitle: {
    margin: "8px 0",
    marginLeft: "32px",
  },
  HeaderBreadcrumb: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "32px",
  },
  Breadcrumb: {
    paddingLeft: "8px",
  },
  ItemBreadcrumb: {
    cursor: "pointer",
    lineHeight: "20px",
    textDecoration: "none",
    "&:hover": {
      color: "#40a9ff",
      textDecoration: "none",
    },
  },
  invite: {
    "& div > input":{
      color: "black !important"
    }
  },
  Table: {
    margin: " 0 32px",
    // padding: "32px",
    background: "#fff",
  },
  TableTitle: {
    textAlign: "right",
    paddingBottom: "40px",
  },
  dialogContent: {
    padding: " 0!important",
    overflow: "hidden",
  },
  dialogPaper: {
    padding: "24px",
  },
  dialogImageSize: {
    height: "auto",
    width: "auto",
    maxHeight: "256px",
    margin: "auto",
    display: "block",
    objectFit: "contain",
  },
  btnSpace: {
    marginRight: "16px",
  },
  popupImage: {
    width: "100%",
    height: "256px",
    marginTop: "8px",
    marginBottom: "16px",
  },
  textContent: {
    fontSize: "14px",
    lineHeight: "24px",
    margin: 0,
    padding: "8px 0 24px",
    color: "#868686",
  },
  select: {
    display: "inline-block",
    width: "50%",
  },
  reportSelect: {
    width: "100%",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    height: "36px",
    color: "#868686",
  },
  gridCol2: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 0.1fr",
    gridColumnGap: "24px",
    paddingBottom: "16px",
    alignItems: "center",
  },
  gridLabel: {
    fontWeight: "600",
  },
  addIcon: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  deleteIcon: {
    marginLeft: "16px",
    color: "#7E7E7E",
    cursor: "pointer",
    "&:hover": {
      color: "#2196F3",
    },
  },
  popupImageStyle: {
    width: "100%",
    height: "256px",
    marginTop: "8px",
    marginBottom: "16px",
  },
  messageSuccess: {
    color: "#868686",
  },
  messageFail: {
    color: "#868686",
  },
  buttonProgress: {
    color: "#2196F3",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  iconSuccess: {
    display: "inline-block",
    float: "left",
    marginRight: "16px",
    color: "#31a633",
  },
  Select: {
    padding: "0px!important",
    margin: "0px!important",
    height: "32px!important",
    backgroundColor: "#F8F8F8",
    // minWidth: "230px",
  },
  tierType: {
    fontFamily: "Open Sans",
    fontSize: "24px",
    lineHeight: "25px",
    color: "#000000",
    margin: "0px",
    padding: "0px"
  },
  tierTypeError: {
    fontFamily: "Open Sans",
    fontSize: "24px",
    lineHeight: "25px",
    color: "#b00020 !important",
    display: "inline-flex !important",
    margin: "0px",
    padding: "0px",
  },
  licencesError: {
    color: "#b00020 !important",
    margin: "0px 8px 8px 4px",
  },
  titles: {
    paddingTop: "12px",
  },
  errorParagraph: {
    background: " #f7e5e9",
    color: "#b00020 !important",
    display: "inline-flex !important",
    padding: "8px 0px 8px 4px",
    width: "280px",
  },
  usernameErrorParagraph: {
    background: " #f7e5e9",
    color: "#b00020 !important",
    display: "inline-flex !important",
    padding: "4px 0px 4px 0px",
    width: "896px",
  },
  errorMargin: {
    margin: "0px 8px 0px 4px",
  },
  userRow: {
    margin: "8px 0px 8px 0px",
  },
  display: {
    display: "inline-flex !important",
  },
  red: {
    color: "#b00020 !important",
    marginRight: "16px",
  },
  linkStyle: {
    color: "revert !important",
    cursor: "pointer",
  },
  displayRemovePadding: {
    display: "inline-flex !important",
    padding:"0px 0px 0px 0px",
    marginBottom:"0px"
  },

  freeTierBreadcrumbMargin:{
    marginTop:"40px",
  },

  upgrade: {
    backgroundColor: "#55a77a",
    color: "#ffffff",
    width: "114px",
    height: "34px",
    marginLeft: "0px!important",
    "&hover": {
      backgroundColor: "#55a77a",
      opacity: "0.5",
    }
  },
  cancelUpgrade: {
      marginRight: "16px",
      height: "34px",
  },
  rowContainer: {
    maxHeight: "50vh",
    overflowX: "hidden"
  }

});

export default styles;
