import React from "react";

// MUI
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
  box: {
    borderRadius: "10px",
    border: "1px solid #E0E0E0"
  },
  title: {
    padding: "10px",
    borderRadius: "10px 10px 0px 0px",
    fontWeight: "400 !important",
    fontSize: "18px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(66, 66, 66, 1)",
    border: "1px solid rgba(66, 66, 66, 1)",
    color: "#ffffff"
  }
});

function HowManyUsersBox(props) {
  const classes = useStyles();

  const { title, content } = props;

  return (
    <Grid container className={classes.box}>
      <Grid item xs={12} className={classes.title}>
        {title}
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
}

export default HowManyUsersBox;
