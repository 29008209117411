import React, { useEffect, useState } from "react";
import { send_request } from "../../../../utils/Request";

// MUI
import { Grid } from "@mui/material";
import { BoldText, Label, OpenInNew, RenamedValueInput, SourceItem } from "./RenameStyledComponents";

const metaDataKeys = ["formSessionURL", "projectSessionId", "formSessionId", "formSubmittedDate", "allQuestionsAnswers"];

function ChangePlaceholderInputs({ selectedPH, isReplacing, error, setError, handleReplace, StyledSkeleton, renamedValue, setRenamedValue, setHasPrefix, projectId }) {
    const [splitItems, setSplitItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sourceComponentName, setSourceComponentName] = useState(null);

    useEffect(async () => {
        setLoading(true);

        let url = `project-service/project/component/${selectedPH.sourceComponentId}`;
        const res = await send_request(url, null, null, "GET");

        let cData = res.data.componentData;
        setSourceComponentName(cData && cData.name ? cData.name : `Untitled (${selectedPH.sourceComponentId})`);

        let hasPrefix = false;
        if (res && res.data && res.data.prefixLabel) hasPrefix = true;
        setHasPrefix(hasPrefix);

        let items = getSplitItems(hasPrefix);
        setSplitItems(items);

        setLoading(false);
    }, [selectedPH]);

    const getSplitItems = (hasPrefix) => {
        let items = selectedPH.key.split(".");
        let res = [];

        for (let i = 0; i < items.length; i++) {
            if ((hasPrefix && i === 1) || (!hasPrefix && i === 0)) {
                setRenamedValue(items[i]);
                res.push({
                    editable: true,
                    placeholder: items[i],
                });
            } else {
                res.push({
                    editable: false,
                    placeholder: i === 0 ? `${items[i]}.` : `.${items[i]}`,
                });
            }
        }

        return res;
    };

    const handleOpenSource = (compId) => {
        let url = `/project/canvas/${projectId}?view=${compId}`;
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const getSkeletonLoaders = () => {
        return (
            <Grid container>
                <Grid item xs={12} marginTop={1}>
                    <StyledSkeleton variant="rectangular" height="40px" width="30%" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container columnSpacing={1} marginTop={2}>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="225px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="225px" />
                        </Grid>
                        <Grid item>
                            <StyledSkeleton variant="rectangular" height="40px" width="225px" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const checkIfMetaKey = (key) => {
        if (metaDataKeys.includes(key)) return true;
        else return false;
    };

    return !loading ? (
        <>
            <Grid item xs={12}>
                <Grid container marginTop={1}>
                    <Grid item xs={12}>
                        <Label>
                            Source of <BoldText>{`$\{${selectedPH.key}\}`}</BoldText>
                        </Label>
                    </Grid>
                    <Grid item xs={12}>
                        <SourceItem onClick={() => handleOpenSource(selectedPH.sourceComponentId)}>
                            <OpenInNew />
                            {sourceComponentName}
                        </SourceItem>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <Label>
                            Change <BoldText>{`$\{${selectedPH.key}\}`}</BoldText> to
                        </Label>
                    </Grid>
                    <Grid item xs={12}>
                        {splitItems && renamedValue !== null && (
                            <Grid container columnSpacing={1}>
                                {splitItems.map((item) => {
                                    return (
                                        <Grid item>
                                            <RenamedValueInput
                                                size="small"
                                                variant="outlined"
                                                error={item.editable && error}
                                                helperText={item.editable && error}
                                                value={item.editable ? renamedValue : item.placeholder}
                                                disabled={isReplacing || !item.editable || checkIfMetaKey(item.placeholder)}
                                                onChange={(e) => {
                                                    if (!item.editable) return;
                                                    let val = e.target.value;
                                                    let newVal = val.replace(/[^\w]/gi, "_");
                                                    setRenamedValue(newVal);
                                                    if (/^[a-zA-Z0-9_]+$/.test(newVal) === false) {
                                                        setError("No special characters or spaces allowed");
                                                    } else setError("");
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    ) : (
        getSkeletonLoaders()
    );
}

export default ChangePlaceholderInputs;
