import React, { useState, useEffect } from "react";

// Material UI
import { Grid, styled } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { format } from "../../../utils/Date";
import ProjectStore from "../../ProjectCanvas/ProjectStore";
import { send_request } from "../../../utils/Request";

const WasReRunBox = styled(Grid)(({ borderColor, backgroundColor }) => ({
    border: `1px solid ${borderColor}`,
    backgroundColor: backgroundColor,
    borderRadius: "4px",
    padding: "8px",
    margin: "16px 0px 8px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "fit-content",
}));

const Error = styled(ErrorIcon)(({ color }) => ({
    color: color,
    marginRight: "12px",
    height: "16px",
    width: "auto",
}));

const BoldText = styled("span")(({}) => ({
    fontWeight: 700,
}));

const LinkText = styled("span")(({}) => ({
    color: "rgba(33, 150, 243, 1)",
    "&:hover": {
        cursor: "pointer",
    },
}));

function ComponentWasReRunBox({ session, reRuns, setSession, setIsLatestRun, setLoadReRun, calculateTimeElapsed }) {
    const [reRun, setReRun] = useState(null);

    useEffect(() => {
        let sortedReRuns = reRuns.sort((a, b) => new Date(a.reRunAt).getTime() - new Date(b.reRunAt).getTime());

        let reRunIdx = sortedReRuns.findIndex((r) => r.reRunHistoryId === session.reRunHistoryId);
        if (reRunIdx === -1) return;

        if (reRunIdx + 1 >= sortedReRuns.length) {
            // We know that we are going to the latest
            setReRun({
                reRunAt: session.createdAt,
                reRunHistoryId: session.projectSessionId,
            });
        } else {
            // This is another re-run
            let rR = reRuns[reRunIdx + 1];
            setReRun(rR);
        }
    }, []);

    const handleOpenReRun = () => {
        setLoadReRun(true);

        // Else, we know they selected a history element
        let url = `project-service/project/get_log/${session.projectId}/${session.projectSessionId}`;

        let id = reRun.reRunHistoryId;
        let isLatestSelected = id === session.projectSessionId;

        let queryParams = {};
        if (!isLatestSelected) queryParams["reRunHistoryId"] = id;

        send_request(url, null, queryParams, "GET")
            .then((res) => {
                let newSession = calculateTimeElapsed(res.data);

                if (!isLatestSelected) newSession["reRunHistoryId"] = id; // Set history id

                setSession(newSession, isLatestSelected);
                setIsLatestRun(isLatestSelected);
                setLoadReRun(false);
            })
            .catch((e) => {
                setLoadReRun(false);
                console.log(e);
            });
    };

    return (
        reRun && (
            <WasReRunBox borderColor="rgba(255, 183, 77, 1)" backgroundColor="rgba(255, 234, 203, 1)">
                <Error color="rgba(255, 183, 77, 1)" />
                <span>
                    This component was re-run on <BoldText>{format(reRun.reRunAt, ProjectStore.state.timezone)}</BoldText>.{" "}
                    <LinkText onClick={handleOpenReRun}>🔁 Open re-run {reRun.reRunHistoryId}</LinkText>
                </span>
            </WasReRunBox>
        )
    );
}

export default ComponentWasReRunBox;
