import React from "react";

// MUI
import { Autocomplete, TextField, Box } from "@mui/material";

function CustomAutocomplete(props) {
  const { components, inputValue, handleChange, convertForm } = props;
  const rowComponent = (option) => {
    return (
      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
        <img
          src={option.logo}
          style={{ height: "20px", width: "20px", marginRight: "10px" }}
        />
        {convertForm ? option.displayName : option.description}
      </Box>
    );
  };

  return (
    <Autocomplete
      options={components}
      getOptionLabel={(option) => {
        return option
          ? props.convertForm
            ? option.displayName
            : option.description
          : "";
      }}
      value={inputValue}
      onChange={(event, newInputValue, reason) => {
        if (reason === "clear") handleChange("");
        else handleChange(newInputValue);
      }}
      renderTags={(options) => {
        return options.map((option) => rowComponent(option));
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            src={option.logo}
            style={{ height: "20px", width: "20px", marginRight: "10px" }}
          />
          {convertForm ? option.displayName : option.description}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            startAdornment:
              inputValue != null && inputValue != "" ? (
                <img
                  src={inputValue.logo}
                  style={{ height: "20px", width: "20px", marginRight: "10px" }}
                />
              ) : null
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
