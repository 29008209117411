import React from "react";
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";

const StyledIcon = styled(Icon)`
  color: ${(props) => props.color || "#f44336"};
`;

const ExclamationIcon = (props) => {
  return <StyledIcon className="fa fa-exclamation-circle" {...props} />;
};

export default ExclamationIcon;
