import { computed, observable, decorate, action, toJS } from "mobx";

class CreateDatabaseRecordStore {
  constructor() {
    this.initialiseEmpty();
  }

  setCreateDatabaseRecordTemplate = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.targetDatabaseId = component.componentData.targetDatabaseId;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      this.data = {
        key: this.keyAuto(),
        columns: []
      };
      this.lastModified = "";
      this.name = "";
      this.targetDatabaseId = null
    }

  };

  setKey = (value) => {
    this.data.key = value
  }

  setData = (data) => {
    this.data.columns = data
  }

  clearData = () => {
    this.data.columns = []
  }

  setDatabaseList = (databaseList) => {
    this.databaseList = databaseList;
  }

  setTargetDatabaseId = (targetDatabaseId) => {
    this.targetDatabaseId = targetDatabaseId;
  }

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  }

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }

  setFocus = (index) => {
    this.position = index;
    this.placeholders = []
  };

  initialiseEmpty = () => {
    this.name = "";
    this.componentId = "";
    this.type = "create_database_record";
    this.lastModified = "";
    this.placeholders = [];
    this.data = {
      key: this.keyAuto(),
      columns: []
    };
    this.position = null;
    this.databaseList = [];
    this.targetDatabaseId = null
  };

  keyAuto = () => {
    return "create_record_" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: toJS(this.placeholders),
      position: this.position,
      databaseList: this.databaseList,
      targetDatabaseId: this.targetDatabaseId
    };
  }
}

decorate(CreateDatabaseRecordStore, {
  componentId: observable,
  name: observable,
  data: observable,
  type: observable,
  lastModified: observable,
  setLastModified: action,
  initialiseEmpty: action,
  template: computed,
  placeholders: observable,
  setPlaceholders: action,
  position: observable,
  setFocus: action,
  targetDatabaseId: observable,
  setTargetDatabaseId: action
});

const createDatabaseRecordStore = new CreateDatabaseRecordStore();
export default createDatabaseRecordStore;
