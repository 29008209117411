const styles = () => ({
  root: {
    width: "100%",
    margin: "auto"
  },
  textroot: {
    width: "100%",
    margin: "auto"
  },
  topText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19px",
    marginBottom: "6px"
  },
  maxText: {
    fontSize: "12px",
    color: "#868686",
    marginLeft: "4px"
  },
  addOptionText: {
    fontSize: "12px",
    color: "#6F6F6F",
    marginLeft: "4px",
    "&:hover": {
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.9)"
    }
  },
  gridRow: {
    width: "100%"
  },
  columnNameInput: {
    width: "100%",
    minWidth: "200px",
    marginBottom: "8px",
    height: "200px"
  },
  optionInput: {
    width: "100%",
    display: "inline-block"
  },
  selectList: {
    width: "100%",
    marginBottom: "8px",
    background: "#F8F8F8",
    color: "#868686",
    borderRadius: "2px",
    padding: "2px"
  },
  columnGridContainer: {
    paddingBottom: "24px",
    maxWidth: "200px"
  },
  inputWithIcon: {
    display: "flex",
    alignItems: "center"
  },
  exportTypeSelect: {
    marginBottom: "24px",
    background: "#F8F8F8",
    color: "#868686",
    minWidth: "300px",
    borderRadius: "2px",
    padding: "8px 12px"
  },
  numRowsInput: {
    minWidth: "300px",
    marginBottom: "32px"
  },
  addIcon: {
    height: "18px",
    width: "18px",
    color: "#868686",
    "&:hover": {
      cursor: "pointer",
      color: "rgba(0,0,0,0.8)"
    }
  },
  removeIcon: {
    height: "18px",
    width: "18px",
    color: "#868686",
    "&:hover": {
      cursor: "pointer",
      color: "rgba(0,0,0,0.8)"
    }
  },
  textIcon: {
    width: "18px",
    height: "18px",
    marginRight: "4px"
  },
  errorSection: {
    display: "flex",
    alignItems: "center",
    color: "#b00020",
    fontSize: "14px"
  },
  optionGridItem: {
    marginBottom: "8px",
    display: "flex",
    alignItems: "center",
    gap: "4px"
  },
  topTitleContainer: { marginBottom: "24px" },
  tableSettingsTitle: {
    fontSize: "14px",
    fontWeight: "bold"
  },
  requiredText: {
    color: "#868686",
    fontSize: "14px",
    lineHeight: "19px"
  },
  requiredCheckbox: {
    padding: "0px",
    marginRight: "4px"
  },
  requiredGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: "4px 0px"
  },
  dropdownIcon: {
    height: "18px",
    width: "18px",
    marginLeft: "-2px",
    marginRight: "4px"
  }
});
export default styles;
