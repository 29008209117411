import React from "react";
import { toJS } from "mobx";
import { alphabet } from "./TableHelpers";

// MUI
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";

const StyledGridItem = styled(Grid)({
  margin: "8px 0px",
  whiteSpace: "nowrap",
  minWidth: "125px"
});

const StyledGridContainer = styled(Grid)({
  overflow: "hidden",
  zIndex: "1000"
});

const TextContainer = styled("div")({
  minWidth: "125px"
});

const TableCell = styled("div")({
  border: "1px solid rgba(0, 0, 0, 0.15)",
  marginBottom: "6px",
  borderRadius: "4px",
  minHeight: "40px",
  padding: "10px",
  fontSize: "12px",
  position: "relative"
});

const ColumnNameText = styled("span")({
  color: "rgba(0, 0, 0, 1)",
  fontSize: "14px",
  fontWeight: "bold",
  width: "100%"
});

const RequiredText = styled("span")({
  color: "rgba(0, 0, 0, 0.7)",
  fontSize: "11px",
  marginLeft: "3px"
});

const Row = styled(Grid)({
  marginBottom: "3px"
});

const RowItem = styled(Grid)({
  minWidth: "125px",
  marginRight: ""
});

const CellLabel = styled("div")({
  position: "absolute",
  top: "2px",
  left: "2px",
  fontSize: "7px",
  lineHeight: "7px",
  color: "rgba(134, 134, 134, 1)"
});

function TablePreview({ data }) {
  let JSONData = toJS(data);

  // Only show the first 8 rows in the preview
  const numRows =
    parseInt(JSONData.numRows) > 8 ? 8 : parseInt(JSONData.numRows);
  const columns = JSONData.columns;

  const renderTable = columns => {
    return [...Array(numRows)].map((e, rowIdx) => {
      return (
        <Row item container xs={12} key={rowIdx} wrap={"nowrap"} gap={2}>
          {columns.map((col, colIdx) => {
            return (
              <RowItem item xs key={rowIdx.toString() + colIdx.toString()}>
                <TableCell>
                  <CellLabel>{`${alphabet[colIdx]}${rowIdx + 1}`}</CellLabel>
                  {JSONData.tableCells != null &&
                    JSONData.tableCells[col.id] != null &&
                    JSONData.tableCells[col.id][rowIdx].rawValue}
                </TableCell>
              </RowItem>
            );
          })}
        </Row>
      );
    });
  };

  const renderColumnPreview = col => {
    return (
      <StyledGridItem item xs>
        <StyledGridItem item xs={12}>
          <TextContainer>
            <ColumnNameText>
              {col.columnName != "" ? col.columnName : "Column name"}
            </ColumnNameText>
            <RequiredText>{col.required && " (Required)"}</RequiredText>
          </TextContainer>
        </StyledGridItem>
      </StyledGridItem>
    );
  };

  return data && columns && numRows ? (
    <>
      <StyledGridContainer container gap={2} wrap="nowrap">
        {columns.map(col => {
          return renderColumnPreview(col);
        })}
      </StyledGridContainer>
      <StyledGridContainer container>
        {renderTable(columns)}
      </StyledGridContainer>
    </>
  ) : (
    <>Table Preview</>
  );
}

export default TablePreview;
