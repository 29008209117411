import { computed, observable, decorate, action, toJS } from "mobx";

class SlackStore {
  constructor() {
    this.initialiseEmpty();
  }

  setSlackTemplate = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      this.data = {
        endpoint_url: "",
        body: "",
      };
      this.lastModified = "";
      this.name = "";
    }

  };

  setTemplateData = data => {
    this.data = data;
  }

  setEndPointUrl = endPointUrl => {
    this.data.endpoint_url = endPointUrl;
  };

  setSlackName = name => {
    this.name = name;
  };

  setSlackBody = Body => {
    this.data.body = Body;
  };
  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  }

  initialiseEmpty = () => {
    this.name = "";
    this.componentId = "";
    this.type = "slack";
    this.lastModified = "";
    this.data = {
      body: ""
    };
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified
    };
  }
}

decorate(SlackStore, {
  componentId: observable,
  name: observable,
  data: observable,
  type: observable,
  lastModified: observable,
  setSlackTemplate: action,
  setEndPointUrl: action,
  setSlackName: action,
  setSlackBody: action,
  setLastModified: action,
  initialiseEmpty: action,
  template: computed
});

const slackStore = new SlackStore();
export default slackStore;
