import { RefreshOutlined, DriveFileRenameOutline, Delete } from "@mui/icons-material";
import { Button, MenuItem, Paper, Select, Grid } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { format } from "../../utils/Date";

const PaperContainer = styled(Paper)({
  width: "100%",
  padding: "16px",
});

const StyledParagraph = styled("p")({});

const StyledLink = styled(Link)({
  color: "#2196F3",
});

const StyledSelect = styled(Select)({
  backgroundColor: "#f8f8f8",
  border: "none",
  minWidth: "300px",
});

const ToolbarButton = styled(Button)({
  marginRight: "0.5rem",
  marginTop: "0.5rem",
});

const WorkflowTestToolbar = ({
  version, projectId,projectName,lastModified,
  handleEditNameClick,
  handleRefreshClick,
  tests,
}) => {
  return (
    <Grid xs={12} container item>
      <PaperContainer>
        <StyledParagraph>
          Testing{" "}
          <StyledLink
            to={ `/project/canvas/${projectId}`}
          >
            {`${projectName} Version ${version}`}
          </StyledLink>{" "}
          {
            `(last modified ${format(
              lastModified,
              "UTC",
              "relative"
            )})`}
        </StyledParagraph>
        <StyledParagraph
          style={{
            color: "#868686",
          }}
        >
          This test mode will make a submission from a trigger component. You
          can save the values you enter into the trigger components below to run
          this test again.
        </StyledParagraph>
        <StyledSelect
          variant="outlined"
          value={tests.length > 0 && tests[0].id}
        >
          {tests.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </StyledSelect>
        <Grid style={{ marginTop: "1rem" }} container>
          <ToolbarButton startIcon={<RefreshOutlined />} variant="outlined" onClick={handleRefreshClick}>
            Refresh
          </ToolbarButton>
          <ToolbarButton startIcon={<DriveFileRenameOutline />} variant="outlined" onClick={handleEditNameClick}>
            Edit Name
          </ToolbarButton>
        </Grid>
      </PaperContainer>
    </Grid>
  );
};

export default WorkflowTestToolbar;
