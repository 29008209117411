export const styles = () => ({
  nodeInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "74px",
  },
  settings: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "rgba(64, 64, 64, 1)",
    borderRadius: "8px",
    height: "40px",
  },
  nodeWrapper: {
    width: "250px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  node: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nodeView: {
    display: "flex",
    flexDirection: "row",
  },
  rmvMargin: {
    margin: "0px!important",
    fontSize: "12px",
  },
  port: {
    position: "relative !important",
    transform: "unset !important",
    right: "unset",
    top: '16px !important',
    height: "8px",
    width: "8px",
    backgroundColor: "#808080",
    " &:hover": {
      backgroundColor: "#2196f3",
    },
    " &:active": {
      backgroundColor: "#2196f3",
    },
  },
  filler: {
    width: "15px",
  },
  title: {
    fontSize: "8px",
  },
  nodeInfoBackground: {
    borderRadius: "8px",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  downsize: {
    height: "16px",
  },
  selectedText: {
    color: "#2196F3",
  },
  selectedIcon: {
    filter: "drop-shadow(6px 6px 0px rgba(0, 0, 0, 0.25))",
  },
  centerGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  labelText: {
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  svg: {
    zIndex: 3,
  },
  addPathButton: {
    position: "absolute",
    zIndex: "9999",
    left: "275px",
    top: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0, 0, 0, 1)",
    background: "#FFF",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 1)",
    padding: "8px",
    minWidth: "8px",
    "&:hover": {
      color: "#2196f3",
      borderColor: "#2196f3",
      cursor: "pointer",
    },
  },
  addPathIcon: {
    height: "14px",
    width: "auto",
  },
  addPathTooltipTitle: {
    fontSize: "12px",
  },

  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '2px 4px',
    color: 'rgba(0, 0, 0, 0.54)',
    '& i': {
      color: '#fff',
      fontSize: '16px',
    },
    '& span': {
      color: '#fff',
      fontSize: '12px',
    },
    '&:hover': {
      color: '#2196F3',
      '& i, & span': {
        color: 'inherit',
      },
    },
  },
  actionText: {
    fontSize: '12px',
    marginLeft: '0px',
    userSelect: 'none',
  },
});