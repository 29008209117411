import React from "react";

import { Autocomplete, TextField } from "@mui/material";

function FromADBAutocomplete({ isColumnSelect, options, value, handleChange, databaseId }) {
    return (
        <Autocomplete
            freeSolo
            fullWidth
            clearOnBlur
            selectOnFocus
            disableClearable
            handleHomeEndKeys
            sx={{ width: 300 }}
            options={options}
            filterOptions={(options, params) => {
                let filtered = [];

                let opts = [];

                if (isColumnSelect) {
                    opts = [{ name: "Record Id", columnId: `recordId_${databaseId}`, isPreset: true }, { name: "Session Id", columnId: `sessionId_${databaseId}`, isPreset: true }, ...options];
                } else opts = options;

                let val = params.inputValue.toLowerCase();

                for (let i = 0; i < opts.length; i++) {
                    let opt = opts[i];
                    let name = opt.name.toLowerCase();
                    if (name.startsWith(val)) filtered.push(opt);
                    else if (!name) {
                        let id = isColumnSelect ? opt.columnId.toLowerCase() : opt.databaseId.toLowerCase();
                        if (id.startsWith(val)) filtered.push(opt);
                    }
                }
                return filtered;
            }}
            value={value}
            getOptionLabel={(option) => {
                if (option.name) return option.name;

                if (isColumnSelect) return option.columnId;
                else return option.databaseId;
            }}
            renderOption={(props, option) => (
                <li {...props} key={isColumnSelect ? option.columnId : option.databaseId}>
                    {option.name ? option.name : isColumnSelect ? option.columnId : option.databaseId}
                </li>
            )}
            renderInput={(params) => (
                <TextField {...params} margin="none" variant="outlined" size="small" placeholder={isColumnSelect ? "Select column" : "Select database"} className={"formSelect"} />
            )}
            onChange={(event, newValue) => {
                if (isColumnSelect && !newValue) newValue = ""; // Set to empty string
                handleChange(newValue);
            }}
        />
    );
}

export default FromADBAutocomplete;
