import React from "react";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles({
  root: {
    fontSize: 12,
    padding: "8px 16px 8px 16px",
    fontFamily: "open sans",
    background: "rgba(0, 0, 0, 0.7)",
    borderRadius: 6
  }
});

const Tooltips = (props) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={props.Title}
      classes={{ tooltip: classes.root }}
      placement="right-start"
    >
      {props.Children}
    </Tooltip>
  );
};

export default Tooltips;
