import { AppBar, Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const LogoImage = styled("img")({
  width: "120px",
  height: "50px",
});

const StyledAppBar = styled(AppBar)({
  borderBottom: "1px solid #E6E5E3",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  boxShadow: "none",
});

const StyledLinkButton = styled(Button)({
  color: "black",
  fontSize: "14px",
  marginRight: "1rem",
  textTransform: "unset",
});

const StyledLogoButton = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "1rem",
});

const MarketplaceTemplateNavBar = () => {
  return (
    <StyledAppBar>
      <StyledLogoButton href="/">
        <LogoImage src="/svgs/logo.svg" alt="logo" />
      </StyledLogoButton>
      <StyledLinkButton href="https://templates.workflow86.com/">
        🔍 Explore more templates
      </StyledLinkButton>
    </StyledAppBar>
  );
};

export default MarketplaceTemplateNavBar;
