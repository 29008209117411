import React from "react";
//mui
import { Grid } from "@mui/material";
//styling
import { withStyles } from "@mui/styles";
import "../../../util/chatMessageAnimations.css";

const styles = () => ({
    buttonMessage: {
        width: "100%",
    },
    buttonMessageText: {
        fontSize: "14px",
        color: "white",
        padding: "0px",
        margin: "0px",
    },
});

const ButtonMessage = ({ content, classes }) => {
    return (
        <Grid container item className={`${classes.buttonMessage} message-bubble`} display="flex" justifyContent="flex-end">
            <p className={classes.buttonMessageText}>{content}</p>
        </Grid>
    );
};

export default withStyles(styles)(ButtonMessage);
