import {
  Button,
  CircularProgress
} from "@mui/material";
import React, { Component } from "react";
import { resendSignUpCode, signInWithRedirect, signUp } from '@aws-amplify/auth';

import Alert from "@mui/material/Alert";
import CONFIG from "../../config";
import ConditionalSignupMessage from "./Onboarding/ConditionalSignupMessage"
import Link from "@mui/material/Link";
import ReCAPTCHA from "react-grecaptcha";
import SignupOptions from "./Onboarding/SignupOptions"
import SignupSuccess from "./Onboarding/SignupSuccess";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import W86AlphanumericTextfield from "../W86Components/W86AlphanumericTextField";
import { disposableEmail } from '../../utils/DisposableEmail';
import { send_request } from "../../utils/Request";
import styles from "../User/UserStyle";
import { v4 as uuidv4 } from 'uuid';
import { withStyles } from "@mui/styles";

const captchaKey = CONFIG.CAPTCHA;

// Set this to false to disable new sign-ups
const ALLOW_NEW_SIGNUPS = true;

class CustomSignUp extends Component {
  state = {
    formState: "",
    loading: false,
    username: "",
    email: "",
    password: "",
    secret: "",
    user: {},
    isVerified: false,
    allowNewSignups: ALLOW_NEW_SIGNUPS,
  };

  componentDidMount = () => {
    this.setState({
      formState: this.props.formState,
      username: this.props.username,
    });
  };

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.setState({
      [name]: check_type ? checked : value,
      checkedValue: check_type ? value : null
    }, () => {
      // If the email field is being changed, update the username
      if (name === 'email') {
        this.updateUsername(value);
      }
    });
  };

  generateShortUUID = () => {
    return uuidv4().split('-')[0].substring(0, 6);
  };

  updateUsername = (email) => {
    if (email) {
      const baseUsername = email.split('@')[0].replace(/[^a-zA-Z0-9]/g, '_');
      const shortUUID = this.generateShortUUID();
      const username = `${baseUsername}_${shortUUID}`;
      this.setState({ username });
    }
  };

  checkEmailDomain = (domain) => {
    const domainList = disposableEmail()
    return domainList.includes(domain);
  }

  handleRegister = async (e) => {
    e.preventDefault();
    const { username, email, password, formState, secret } = this.state;
    if (this.state.isVerified) {
      if (email == "") {
        this.setState({
          openError: true,
          errorMessage: "Email cannot be empty.",
          formState,
        });
      } else {
        this.setState({
          loading: true,
        });

        const emailDomain = email.split("@")[1]
        if (this.checkEmailDomain(emailDomain)) {
          this.setState({
            openError: true,
            loading: false,
            errorMessage:
              "😬 Sorry, we do not allow sign ups with a disposable email",
            formState,
          });
        } else {
          this.checkSecretKey();
        }
      }
    } else {
      this.setState({
        openError: true,
        errorMessage:
          "The reCAPTCHA was not completed. Please try again to confirm you are not a robot 🤖.",
        formState,
      });
    }
  };
  updateLastLogged = async () => {
    const json = await send_request(`authz-service/update-last-logged`, "", {});
  };

  checkSecretKey = async () => {
    const { username, email, password, formState, secret } = this.state;
    //const json = await send_request_without_auth(`authz-service/check-secret-key`, {secret}, "","POST");

    //not using the secretKey anymore, so we just mock the data
    let json = { data: true, status: "200" };

    if (json && json.data == true && json.status == "200") {
      signUp({
        username,
        password,
        options: {
          userAttributes: {
            email: email
          }
        }
      })
        .then((user) => {
          setTimeout(() => {
            this.setState({
              formState: "signUpSuccess",
              loading: false,
              confirmMessage:
                "Further instructions have been sent to your email 📨",
              openConfirm: true,
              email: email,
            });
          }, 2000);
        })
        .catch((e) => {
          console.log(e)
          if (e.message == "User already exists") {
            this.setState({
              loading: false,
              openError: true,
              errorMessage:
                "This username is not available 😕. Please try another.",
              formState,
            });
          } else if(e.message.indexOf("This email is already being used by another account")!=-1){
            this.setState({
              loading: false,
              openError: true,
              errorMessage: "This email is already being used by another account",
              formState,
            });
          } else {
            this.setState({
              loading: false,
              openError: true,
              errorMessage: e.message,
              formState,
            });
          }
        });
    } else {
      this.setState({
        openError: true,
        errorMessage: "Invalid secret key 😬",
        loading: false,
      });
    }
  };

  handleClose = () => {
    const { openError, openConfirm } = this.state;
    if (openError) {
      this.setState({ openError: false, verify: true });
    } else if (openConfirm) {
      this.setState({ openConfirm: false, verify: true });
    }
  };

  handleResend = () => {
    const { username } = this.state;
    this.setState({
      loading: true,
    });
    resendSignUpCode({ username: username })
      .then((user) => {
        setTimeout(() => {
          this.setState({
            loading: false,
            confirmMessage: "Instructions have been sent to your email 📨.",
            openConfirm: true,
          });
        }, 2000);
      })
      .catch((e) => {
        setTimeout(() => {
          this.setState({
            loading: false,
            openError: true,
            errorMessage: e.message,
          });
        }, 2000);
      });
  };
  callback = () => {
    this.setState({
      isVerified: true,
    });
  };

  expiredCallback = () => {
    console.log("expired call back");
  };

  signUpWithGG = () => {
    this.setState({ loading: true });
    signInWithRedirect({ provider: 'Google' })
      .then(() => {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 20000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    const { formState, openError, errorMessage, openConfirm, confirmMessage, allowNewSignups } =
      this.state;
    const { classes } = this.props;
    return (
      <div className="mainDiv">
        {openError && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openError}
            autoHideDuration={6000}
            onClose={this.handleClose}
            key={"top" + "center"}
          >
            <Alert  variant={"filled"} onClose={this.handleClose} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
        {openConfirm && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openConfirm}
            autoHideDuration={6000}
            onClose={this.handleClose}
            key={"top" + "center"}
          >
            <Alert variant={"filled"} onClose={this.handleClose} severity="success">
              {confirmMessage}
            </Alert>
          </Snackbar>
        )}
        <Typography component="div">
          <div>
            <img src="/svgs/logo.svg" alt="logo" />
          </div>
        </Typography>
          <ConditionalSignupMessage 
            allowNewSignups={this.state.allowNewSignups} 
            formState={this.state.formState} 
            email={this.state.email} 
          />
        {allowNewSignups && formState === "signUp" && (
          <SignupOptions
            loading={this.state.loading}
            onSignUpWithEmail={() => this.setState({ formState: "signUpWithEmail" })}
            onSignUpWithGoogle={this.signUpWithGG}
            onBackToSignIn={() => window.location.href = "/"}
          />
        )}
        {allowNewSignups && formState === "signUpWithEmail" && (
          <form onSubmit={this.handleRegister}>
            <div className="inputArea">
            <p className="inputDescription">Work Email</p>
              <TextField
                style={{ width: "100%" }}
                placeholder="Enter your work email"
                variant="outlined"
                id="email"
                key="email"
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
              <p className="inputDescription">Password</p>
              <TextField
                style={{ width: "100%" }}
                placeholder="Enter your password"
                variant="outlined"
                id="password"
                key="password"
                name="password"
                type="password"
                onChange={this.handleInputChange}
              />
              <p className={"s-text noWrap"}>
                Must contain 6+ characters, special character(s), number(s),
                uppercase and lower case letter(s).
              </p>
              <p className="inputDescription">Username</p>
              <W86AlphanumericTextfield 
                customStyleProps={{}}
                handleChange={this.handleInputChange}
                value={this.state.username}
                props={{
                  id: "username",
                  key: "username",
                  name: "username",
                  placeholder: "Enter your username",
                }}
              />
            </div>
            <div className="actionArea">
              <Typography style={{ marginBottom: "20px" }}>
                <Link
                  href="https://www.workflow86.com/terms-and-conditions-of-use/"
                  target="_blank"
                  onClick={this.handleResetPassword}
                  underline="hover">
                  By signing up for an account, you agree to our Terms of
                  Service
                </Link>
              </Typography>
              <ReCAPTCHA
                sitekey={captchaKey}
                callback={this.callback}
                expiredCallback={this.expiredCallback}
                locale="en"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "16px",
                }}
                className="flexStartMobile"
              >
                <Button
                  className="backButton"
                  variant="outlined"
                  color="info"
                  onClick={() => this.setState({ formState: "signUp" })}
                >
                  Back
                </Button>
                <Button
                  className="signInButton"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={this.handleRegister}
                  disabled={this.state.loading}
                  style={{ marginLeft: "16px" }}
                >
                  Register
                  {this.state.loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </div>
          </form>
        )}
        {allowNewSignups && this.state.formState === "signUpSuccess" && (
          <SignupSuccess
            email={this.state.email}
            loading={this.state.loading}
            handleResend={this.handleResend}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CustomSignUp);
