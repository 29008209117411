import React, { Component } from "react";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { CustomDialog } from "../Component/Dialog";
import "./MarketplacePage.css";

class DeleteDialog extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const confirmDelete = this.props.confirmDelete;

    return (
      <CustomDialog
        fullWidth={true}
        size={"sm"}
        isOpen={confirmDelete}
        title={"Remove from Shared Workflows"}
        contents={
          <>
              <p className="s-text">
                Are you sure you want to no longer share this workflow? This will prevent users from importing it. Any copies of this workflow already imported by any user or account cannot be removed.
              </p>
          </>
        }
        buttons={
          <>
            <Button
              onClick={this.props.delete}
              variant={"outlined"}
              color={"info"}
              disabled={this.props.disableRemove}
            >
              {this.props.disableRemove && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
              Remove
            </Button>
            <Button
              onClick={this.props.close}
              variant={"contained"}
              color={"primary"}
              disabled={this.props.disableRemove}
            >
              {this.props.disableRemove && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
              Cancel
            </Button>
          </>
        }
      />
    );
  }
}

export default DeleteDialog;
