export const tableFunctions = [
  {
    placeholders: [
      { key: "REQUIRED", type: "text" },
      { key: "READONLY", type: "text" },
      { key: "DISABLE", type: "text" },

      { key: "ABS(A)", type: "number" },
      { key: "ACOS(x)", type: "number" },
      { key: "ACOSH(x)", type: "number" },
      { key: "ACOT(x)", type: "number" },
      { key: "ACOTH(x)", type: "number" },
      { key: "ACSC(x)", type: "number" },
      { key: "ACSCH(x)", type: "number" },
      { key: "ADD(A,B)", type: "number" },
      { key: "AND(x,y)", type: "number" },
      { key: "ARG(x)", type: "number" },
      { key: "ASEC(x)", type: "number" },
      { key: "ASECH(x)", type: "number" },
      { key: "ASIN(x)", type: "number" },
      { key: "ASINH(x)", type: "number" },
      { key: "ATAN(x)", type: "number" },
      { key: "ATANH(x)", type: "number" },
      { key: "CEIL(x)", type: "number" },
      { key: "COMBINATIONS(n, k)", type: "number" },
      { key: "CONJ(x)", type: "number" },
      { key: "COS(x)", type: "number" },
      { key: "COSH(x)", type: "number" },
      { key: "COT(x)", type: "number" },
      { key: "COTH(x)", type: "number" },
      { key: "CSC(x)", type: "number" },
      { key: "CSCH(x)", type: "number" },
      { key: "CUBE(x)", type: "number" },
      { key: "CUMSUM(A,B,..)", type: "number" },
      { key: "DIVIDE(x,y)", type: "number" },
      { key: "EXP(x)", type: "number" },
      { key: "FACTORIAL(x)", type: "number" },
      { key: "FIX(x)", type: "number" },
      { key: "FLOOR(x)", type: "number" },
      { key: "GAMMA(x)", type: "number" },
      { key: "IM(x)", type: "number" },
      { key: "LCM(x,y)", type: "number" },
      { key: "LOG2(x)", type: "number" },
      { key: "MAD(A,B,..)", type: "number" },
      { key: "MAX(A,B,..)", type: "number" },
      { key: "MEAN(A,B,..)", type: "number" },
      { key: "MEDIAN(A,B,..)", type: "number" },
      { key: "MIN(A,B,..)", type: "number" },
      { key: "MODE(A,B,..)", type: "number" },
      { key: "MULTIPLY(x,y)", type: "number" },
      { key: "NOT(x)", type: "number" },
      { key: "OR(x,y)", type: "number" },
      { key: "POW(x, y)", type: "number" },
      { key: "PROD(A,B,..)", type: "number" },
      { key: "RE(x)", type: "number" },
      { key: "SEC(x)", type: "number" },
      { key: "SECH(x)", type: "number" },
      { key: "SIGN(x)", type: "number" },
      { key: "SIN(x)", type: "number" },
      { key: "SINH(x)", type: "number" },
      { key: "SQRT(x)", type: "number" },
      { key: "SQUARE(x)", type: "number" },
      { key: "STD(A,B,..)", type: "number" },
      { key: "SUBTRACT(x,y)", type: "number" },
      { key: "SUM(A,B,..)", type: "number" },
      { key: "TAN(x)", type: "number" },
      { key: "TANH(x)", type: "number" },
      { key: "VARIANCE(A,B,..)", type: "number" },
      { key: "XOR(x,y)", type: "number" }
    ]
  }
];

// Code to generate the sorted values
// let arithFunctions = ["ABS(A)", "ADD(A,B)", "CEIL(x)", "CUBE(x)", "DIVIDE(x,y)", "EXP(x)", "FIX(x)", "FLOOR(x)", "LOG2(x)", "LCM(x,y)", "MULTIPLY(x,y)", "POW(x, y)", "SIGN(x)", "SQRT(x)", "SQUARE(x)", "SUBTRACT(x,y)" ];

// let statsFunctions = ["CUMSUM(A,B,..)", "MAD(A,B,..)", "MAX(A,B,..)", "MEAN(A,B,..)", "MEDIAN(A,B,..)", "MIN(A,B,..)", "MODE(A,B,..)", "PROD(A,B,..)", "STD(A,B,..)", "SUM(A,B,..)", "VARIANCE(A,B,..)"]

// let trigFunctions = ["ACOS(x)", "ACOSH(x)", "ACOT(x)", "ACOTH(x)", "ACSC(x)", "ACSCH(x)", "ASEC(x)", "ASECH(x)", "ASIN(x)", "ASINH(x)", "ATAN(x)", "ATANH(x)", "COS(x)", "COSH(x)", "COT(x)", "COTH(x)", "CSC(x)", "CSCH(x)", "SEC(x)", "SECH(x)", "SIN(x)", "SINH(x)", "TAN(x)", "TANH(x)"]

// let complexFunctions = ["ARG(x)", "CONJ(x)", "IM(x)", "RE(x)"];

// let logicalFunctions = ["AND(x,y)", "NOT(x)", "OR(x,y)", "XOR(x,y)"];

// let probabilityFunctions = ["COMBINATIONS(n, k)", "FACTORIAL(x)", "GAMMA(x)"]

// let val = [...arithFunctions, ...statsFunctions, ...trigFunctions, ...complexFunctions, ...logicalFunctions, ...probabilityFunctions].sort().map((val) => {
//     return {
//         key: val,
//         type: "number"
//     }
// })

// console.log(val)
