import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  textroot: {
    width: "100%",
    margin: "auto",
  },
});

class ListInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: "",
    };
  }

  render() {
    const { classes } = this.props;
    try {
      return (
        <div className={classes.root}>
              <TextField
                multiline
                defaultValue=""
                rows="3"
                className={classes.textroot}
                InputProps={{
                  className: "s-text",
                }}
                variant="outlined"
                defaultValue = "Press enter to inset next value"
              />
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

ListInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListInput);
