import React from "react"; 
import { withStyles } from "@mui/styles";
import styles from "../BulkAssessmentStyle"; 
import { Grid, MenuItem, Select, TextField } from "@mui/material";
import BulkAssessmentStore from "../BulkAssessmentStore";
import Icon from "@mui/material/Icon";
import {toJS} from "mobx";
import checkKeyUtil from "../../../utils/CheckKeyUtil";

class KeyData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      checkKey: false,
      errorMessage: ""
    };
  }

  handleKeyChanges = (value,index) => {
    const {data} = toJS(BulkAssessmentStore);
    const result = checkKeyUtil(value,index, data.fields,"keyName");
    this.setState({
      checkKey: result[0],
      errorMessage: result[1]
    });
  };

  render() { 
    const {  component, classes, index } = this.props
    return (
      <Grid container xs={12} spacing={2}>
        <Grid item xs={8}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              placeholder="Insert key value here"
              fullWidth
              className="key_padding"
              value={component.keyName}
              onChange={(event) => {
                BulkAssessmentStore.setField(event.target.value, index, "keyName");
                this.handleKeyChanges(event.target.value,index);
              }}
              error={this.state.checkKey ? true : false}
              InputProps={{
                endAdornment: this.state.checkKey && (
                  <Icon
                    className="fa fa-exclamation-circle"
                    style={{color: "#f44336"}}
                  />
                )
              }}
              helperText={
                this.state.checkKey ? this.state.errorMessage : null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12}>
            <Select
              name="selectType"
              disableUnderline
              variant="standard"
              labelId="demo-simple-select-outlined-label"
              className="dropdown-mat selectStandard item-select-bulk"
              fullWidth
              displayEmpty
              value={component.dataType}
              onChange={(event) => {
                BulkAssessmentStore.setField(event.target.value, index, "dataType");
              }}
            >
              <MenuItem value="text"> Text </MenuItem>
              <MenuItem value="number"> Number </MenuItem>
              <MenuItem value="list">  List  </MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Grid item xs={12}>
            <div className={classes.deleteButtonStyle}
              onClick={() => BulkAssessmentStore.deleteComponentKeyData(index)}
            >
              <i className="material-icons is-isolated">delete</i>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(KeyData)
