import React, {Component} from 'react';
import { Grid, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import Button from "@mui/material/Button";

class SelectMethod extends Component {

  constructor(props) {
    super(props);
    this.state = {
      method: this.props.componentStore.data.type
    };
  }


  selectMethod = (value) => {
    this.setState({
      method: value
    })
  }

  uploadSample = () => {
    const { method } = this.state

    this.props.componentStore.setField(method, "", "type");

    if (method === "send_a_sample_json"){
      this.props.method("send_a_sample_json")
      this.props.onChangeState(1,1)
    } else if (method === "upload_sample_json"){
      this.props.method("upload_sample_json")
      this.props.onChangeState(1,1)
    } else if (method === "open_endpoint") {
      this.props.method("open_endpoint");
      this.props.onChangeState(7,7)
    }
  }

  // Fetches the correct text to display based on the selected method
  fetchText = (method) => {
    let text = "";

    switch (method) {
      case "send_a_sample_json":
        text = (
          <>
            <strong>⚠️ Warning: This method is deprecated ⚠️ </strong> <br/>
            It is recommended you use the default option and parse using the Run Code or AI components.<br/>
            Send a sample payload to an endpoint and then parse and map to placeholders
          </>
        );
        break;
      case "upload_sample_json":
        text = (
          <>
            <strong>⚠️ Warning: This method is deprecated ⚠️ </strong> <br/>
            It is recommended you use the default option and parse using the Run Code or AI components.<br/>
            Copy and paste a sample JSON of the payload and then parse and map to placeholders
          </>
        );
        break;
      case "open_endpoint":
        text = (
          <>
            Receive a payload and output the entire response as a single placeholder. Use the Run Code, AI Assistant or Parse JSON components to map the payload to placeholders.
          </>
        );
        break;
      default:
        break;
    }

    if (!text) return;
    return <div className="method-explanation">{text}</div>
  }

  render() {
    const {method} = this.state
    return (
      <Grid item xs={12} className="textAlignLeft">
        <span>Select a method for handling and mapping the payload</span>
        
        <FormControl className="selectStandard" sx={{ margin: "24px 0" }}>
          <RadioGroup
            value={method || ''}
            onChange={(event) => this.selectMethod(event.target.value)}
          >
            <FormControlLabel 
              value="open_endpoint" 
              control={<Radio />} 
              label="Output entire payload as one placeholder (Default)"
            />
            
            <div className="deprecated-options">
              <div className="deprecated-warning">
                Legacy options
              </div>
              <FormControlLabel 
                value="send_a_sample_json" 
                control={<Radio />} 
                label="Map payload to placeholders by making a sample request"
              />
              <FormControlLabel 
                value="upload_sample_json" 
                control={<Radio />} 
                label="Map payload to placeholders by pasting a sample JSON"
              />
            </div>
          </RadioGroup>
        </FormControl>

        {this.fetchText(method)}
        <Grid item xs={12} className="dialogContent" style={{marginTop:0}}>
          <Grid item xs={12} >
            <Button
              onClick={this.uploadSample}
              disabled={!method}
              variant={"contained"} 
              color={"primary"} 
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SelectMethod;