import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { observer } from "mobx-react";
import JoditEditor from "jodit-pro-react";
import { withStyles } from "@mui/styles";


const useStyles = () => ({
  root: {
    "& .jodit-container": {
      borderColor: "#ffffff !important",
    },
  },
});

class ReadMeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: null,
      loading: true,
      keyCode: null,
      // editorState: undefined
    };
  }

  componentDidMount() {
    this.setState({ editorState: this.props.html });
  }



  config = {
    license: "CC7FX-1D761-AUOP5-JG0GX",
    readonly: true,
    toolbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    disablePlugins: "resizer",
    useIframeResizer: false,
  };

  setRef = (jodit) => (this.jodit = jodit);

  render() {
    const { editorState } = this.state;
    const { classes } = this.props;
    return (
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <span>
            <JoditEditor
              value={editorState}
              ref={this.setRef}
              config={this.config}
            />
          </span>
        </Grid>
      </Grid>
    );
  }
}

export default observer(withStyles(useStyles)(ReadMeDialog));
