import { computed, observable, decorate, action, toJS } from "mobx";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import { v4 as uuidv4 } from "uuid";
import { days } from "../../utils/Days";
import { timezones } from "../../utils/timezones";

class TimeDelayStore {
  constructor() {
    this.initialiseEmpty();
  }

  setData = (value, type) => {
    switch (type) {
      case "componentType":
        this.data.componentType = this.configComponentType(value);
        break;
      case "delayBy":
        this.data.delayBy = value;
        break;
      case "atDatetimePlaceholder":
        this.data.atDatetimePlaceholder = value;
        break;
      case "delayInterval":
        this.data.delayInterval = this.configInterval(value);
        break;
      case "timezone":
        this.data.timezone = value;
        break;
      case "date":
        this.data.date = value;
        break;
      case "day":
        this.data.day = value;
        break;
    }
  };
  configComponentType = (value) => {
    switch (value) {
      case 0:
        return "TIME_ELAPSED";
      case 1:
        return "CERTAIN_DATE_TIME";
      case 2:
        return "CERTAIN_WEEK_DAY";
      case 3:
        return "AT_DATE_TIME_PLACEHOLDER"
      default:
        return "UNSET";
    }
  };

  configComponentTypeValue = (value) => {
    switch (value) {
      case "TIME_ELAPSED":
        return 0;
      case "CERTAIN_DATE_TIME":
        return 1;
      case "CERTAIN_WEEK_DAY":
        return 2;
      case "AT_DATE_TIME_PLACEHOLDER":
        return 3;
      default:
        return 0;
    }
  };

  configInterval = (value) => {
    switch (value) {
      case 1:
        return "minutes";
      case 2:
        return "hours";
      case 3:
        return "days";
      case 4:
        return "weeks";
      case 5:
        return "months";
      case 6:
        return "businessDays";
      default:
        return "";
    }
  };

  configIntervalValue = (value) => {
    switch (value) {
      case "minutes":
        return 1;
      case "hours":
        return 2;
      case "days":
        return 3;
      case "weeks":
        return 4;
      case "months":
        return 5;
      case "businessDays":
        return 6;
      default:
        return 0;
    }
  };
  setComponentData = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData && component.componentData.data) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.componentData();
      this.data = obj;
      this.lastModified = "";
      this.name = "";
    }
  };

  setPlaceholder = (value) => {
    this.data.key = value;
  };

  keyAuto = () => {
    return (
      "timeDelay" + (Math.floor(Math.random() * 90000000) + 10000).toString()
    );
  };

  componentData = () => {
    return {
      key: this.keyAuto(),
      timezone: ProjectStore.state.timezone,
      componentType:"TIME_ELAPSED",
    };
  };

  initialiseEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.position = null;
    this.componentId = "";
    this.type = "time_delay";
    this.lastModified = "";
    this.data = obj;
    this.error = false;
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
    };
  }

  setTimeDelayName = (name) => {
    this.name = name;
  };

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  };
}
decorate(TimeDelayStore, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable,
  type: observable,
  lastModified: observable,
  error: observable,
  setComponentName: action,
  setComponentData: action,
  setLastModified: action,
  deleteComponent: action,
  initialiseEmpty: action,
  template: computed,
});

const timeDelayStore = new TimeDelayStore();
export default timeDelayStore;
