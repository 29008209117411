import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { StylesProvider } from "@mui/styles";
import FadeIn from "react-fade-in";
import MaterialGrid from "@mui/material/Grid";
import { send_request } from "../../utils/Request";
import { Link, Navigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import "./SelectPlanComponents/SelectPlanStyle.css";
import PlanComponent from "./SelectPlanComponents/PlanComponent";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogTitle, Skeleton, styled } from "@mui/material";
import { withParams } from "../../Routes";
import HowManyUsersBox from "./HowManyUsersBox";
import {ButtonGroup} from "@mui/material";
import EnterprisePlanBanner from "./EnterprisePlanBanner";


const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
  borderRadius: "10px",
  background: "#E8E8E8",
  width: "100%",
  height: height,
  margin: margin,
}));

class SetPlan extends Component {
  state = {
    errorMessage: null,
    listPrices: {},
    loadingScreen: false,
    tier: "FREE",
    plans: [],
    userQuantity: 0,
    currentUserQuantity: 0,
    endUserQuantity: 0,
    currentEndUserQuantity: 0,
    currentPlan: {},
    errorStatus: false,
    interval: "monthly"
  };

  componentDidMount() {
    this.checkUser();
    this.getListPlan();
    this.listPrice();
    this.getCurrentPlan();
  }

  getPlanSkeletonLoader = () => {
    return <Grid container spacing={3} padding={"24px"}>
        <Grid item xs={12}>
          <StyledSkeleton variant="rectangular" height="30px" width="350px" />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}><StyledSkeleton variant="rectangular" height="450px" /></Grid>
            <Grid item xs={6}><StyledSkeleton variant="rectangular" height="450px" /></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}><StyledSkeleton variant="rectangular" height="650px" /></Grid>
            <Grid item xs={3}><StyledSkeleton variant="rectangular" height="650px" /></Grid>
            <Grid item xs={3}><StyledSkeleton variant="rectangular" height="650px" /></Grid>
            <Grid item xs={3}><StyledSkeleton variant="rectangular" height="650px" /></Grid>
          </Grid>
        </Grid>
      </Grid>
  }

  checkUser = async () => {
    const json = await send_request(
      `authz-service/user-info`,
      "",
      {},
      "GET"
    ).catch(err => {
      console.error(err);
    });
    if (json && json.status == "200") {
      if (json.data.roleAdministrator) {
        if (json.data.client.tier) {
          this.setState({
            tier: json.data.client.tier,
            userQuantity: json.data.client.userQuantity >= 2 ? json.data.client.userQuantity : 2,
            currentUserQuantity: json.data.client.userQuantity,
            currentEndUserQuantity: json.data.client.endUserQuantity,
            endUserQuantity: json.data.client.endUserQuantity >= 10 ? json.data.client.endUserQuantity : 10
          });
        }

        if (json.data.roleAdministrator && json.data.client.tier === "FREE") {
        } else {
          this.setState({
            signInOpen: true
          });
        }
      } else {
        this.setState({
          errorMessage: "You do not have permission to view this page 😬",
          errorStatus: true
        });
      }
    } else {
      this.setState({
        signInOpen: true
      });
    }
  };

  listPrice = async () => {
    this.setState({
      loadingScreen: true
    });
    const json = await send_request(
      `authz-service/list-prices`,
      "",
      "",
      "GET"
    ).catch(err => {
      throw Error(err.message);
    });

    let {
      starterMonthlyPrice,
      professionalMonthlyPrice,
      professionalPlusMonthlyPrice,
      starterAnnualPrice,
      professionalAnnualPrice,
      professionalPlusAnnualPrice,
      userMonthlyPrice,
      endUserMonthlyPrice,
      userAnnualPrice,
      endUserAnnualPrice,
    } = 0;

    professionalPlusMonthlyPrice = json.data.professionalPlusData;
    professionalMonthlyPrice = json.data.professionalData;
    starterMonthlyPrice = json.data.starterData;
    professionalPlusAnnualPrice = json.data.professionalPlusDataAnnual;
    professionalAnnualPrice = json.data.professionalDataAnnual;
    starterAnnualPrice = json.data.starterDataAnnual;
    userMonthlyPrice = json.data.userData;
    endUserMonthlyPrice = json.data.endUserData;
    userAnnualPrice = json.data.userDataAnnual;
    endUserAnnualPrice = json.data.endUserDataAnnual;

    this.setState({
      listPrices: {
        proPlus: professionalPlusMonthlyPrice,
        pro: professionalMonthlyPrice,
        starter: starterMonthlyPrice,
        proPlusAnnual: professionalPlusAnnualPrice,
        proAnnual: professionalAnnualPrice,
        starterAnnual: starterAnnualPrice,
        user: userMonthlyPrice,
        endUser: endUserMonthlyPrice,
        userAnnual: userAnnualPrice,
        endUserAnnual: endUserAnnualPrice,
      }
    });

    this.setState({
      loadingScreen: false
    });
  };

  getListPlan = async () => {
    const json = await send_request(
      `authz-service/list-plans`,
      "",
      "",
      "GET"
    ).catch(err => {
      throw Error(err.message);
    });

    if (json && json.status == "200" && json.data) {
      this.setState({
        plans: json.data
      });
    }
  };

  getCurrentPlan = async () => {
    const json = await send_request(
      `authz-service/get-current-plan`,
      "",
      "",
      "GET"
    ).catch(err => {
      throw Error(err.message);
    });

    if (json && json.status == "200" && json.data) {
      this.setState({
        currentPlan: json.data[0]
      });
    }
  };

  handleUserInputChange = e => {
    let userQuantity = e.target.value;

    this.setState({
      userQuantity
    });
  };

  handleEndUserInputChange = e => {
    this.setState({
      endUserQuantity: e.target.value
    });
  };

  redirectToLiveChat = () => {
    window.Intercom("show");
  };

  goToHomePage = () => {
    this.props.navigate("/");
    this.setState({
      errorStatus: false,
      errorMessage: ""
    });
  };

  render() {
    const {
      listPrices,
      plans,
      tier,
      userQuantity,
      currentUserQuantity,
      currentPlan,
      endUserQuantity,
      currentEndUserQuantity,
      interval
    } = this.state;

    let userPrice;
    let endUserPrice;
    if (interval === "monthly") {
      userPrice = listPrices.user;
      endUserPrice = listPrices.endUser;
    } else {
      userPrice = listPrices.userAnnual;
      endUserPrice = listPrices.endUserAnnual;
    }

    let minEndUserQuantity = 10;

    if (endUserPrice) {
      minEndUserQuantity = endUserPrice[0].upTo;
    }

    let numUpTo = 0;
    if (userPrice) {
      numUpTo = userPrice[0].upTo;
    }

    const { history, firstLogin } = this.props;

    const howManyUsersOrAdmins = (
      <Grid
        container
        className="box-container"
        alignItems={"center"}
        direction={"column"}
      >
        <Grid item xs={12} className="user-text">
          Administrator and Editor users can build and maintain workflows
        </Grid>
        <Grid item xs={12} marginTop="12px">
          <TextField
            style={{
              width: "100%",
              background: "#F9F9F9"
            }}
            placeholder=""
            variant="outlined"
            type="number"
            className="number-of-user-plan-input"
            value={this.state.userQuantity}
            onChange={e => this.handleUserInputChange(e)}
          />
        </Grid>
        <Grid item xs={12} className="user-text" style={{ marginTop: "16px" }}>
          <span>
            You currently have {currentUserQuantity} Administrator/Editors
            licenses
          </span>
        </Grid>
      </Grid>
    );

    const howManyEndUsers = (
      <Grid
        container
        className="box-container"
        alignItems={"center"}
        direction={"column"}
      >
        <Grid item xs={12} className="user-text">
          End Users can use workflows built by Administrators and Editors
        </Grid>
        <Grid item xs={12} marginTop="12px">
          <TextField
            style={{ width: "100%", background: "#F9F9F9" }}
            placeholder=""
            variant="outlined"
            type="number"
            className="number-of-user-plan-input"
            value={this.state.endUserQuantity}
            onChange={e => this.handleEndUserInputChange(e)}
          />
        </Grid>
        <Grid item xs={12} className="user-text" style={{ marginTop: "16px" }}>
          You currently have {currentEndUserQuantity} End User licenses
        </Grid>
      </Grid>
    );

    if (this.state && !this.state.loadingScreen) {
      return (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={this.state.errorStatus}
          >
            <DialogTitle style={{ background: "none", display: "block" }}>
              <p className="imgPopSubheader">{this.state.errorMessage}</p>
            </DialogTitle>
            <DialogActions style={{ paddingTop: "16px" }} classes="popAction">
              <div style={{ display: "flex" }}>
                <Button
                  onClick={this.goToHomePage}
                  variant={"contained"}
                  color={"primary"}
                >
                  Go to home page
                </Button>
              </div>
            </DialogActions>
          </Dialog>
          <Grid
            container
            spacing={1}
          >
            <Grid 
              item
              xs={12}
            >
              <div className="set-plan-page">
                <React.Fragment>
                  <Typography component="div">
                    <div className="fix-box-1">
                      <div className="fix-box">
                        {firstLogin ? (
                          ""
                        ) : (
                          <Link to="/organization" className="ItemBreadcrumb">
                            <ArrowBackIcon
                              className="arrow_back"
                              style={{ marginRight: 8 }}
                            />{" "}
                            Back to Organization Settings
                          </Link>
                        )}
                      </div>
                    </div>

                    <Grid style={{textAlign: "center"}}>
                      <p style={{fontSize: "18px"}}>Billing Period</p>
                      <ButtonGroup>
                        <Button
                          color={this.state.interval === "monthly" ? "primary" : "info"}
                          variant={
                            this.state.interval === "monthly" ? "contained" : "outlined"
                          }
                          onClick={() => {
                            this.setState({
                              interval: "monthly"
                            });
                          }}
                        >
                          MONTHLY
                        </Button>
                        <Button
                          color={this.state.interval === "monthly" ? "info" : "primary"}
                          variant={
                            this.state.interval === "monthly" ? "outlined" : "contained"
                          }
                          onClick={() => {
                            this.setState({
                              interval: "annual"
                            });
                          }}
                        >
                          ANNUAL
                        </Button>
                      </ButtonGroup>
                    </Grid>      
                    <FadeIn>
                      <Grid container spacing={3} marginTop={"24px"}>
                        <Grid item xs={12} md={6} paddingTop={"0px !important"}>
                          <HowManyUsersBox
                            title="How many Administrator or Editor users?"
                            content={howManyUsersOrAdmins}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} paddingTop={"0px !important"}>
                          <HowManyUsersBox
                            title="How many End users?"
                            content={howManyEndUsers}
                          />
                        </Grid>
                      </Grid>
                    </FadeIn>

                    <StylesProvider injectFirst>
                      <FadeIn>
                        <div className="fix-box-1" style={{ marginTop: 24 }}>
                          <MaterialGrid container spacing={3}>
                            {plans.map(plan =>
                              {
                                if (plan.interval === interval || plan.interval === null) {

                                  if (plan.tier === "FREE" && tier !== "FREE") return; // Only show FREE if on FREE
                                  if (plan.tier === "FREE_TRIAL" && tier === "FREE") return; // Don't show FREE TRIAL on FREE

                                  return (
                                    <PlanComponent
                                      firstLogin={firstLogin}
                                      plan={plan}
                                      tier={tier}
                                      userQuantity={userQuantity}
                                      endUserQuantity={endUserQuantity}
                                      currentPlan={currentPlan}
                                      currentUserQuantity={currentUserQuantity}
                                      currentEndUserQuantity={currentEndUserQuantity}
                                      listPrices={listPrices}
                                      history={history}
                                      interval={interval}
                                    />
                                  )
                                }
                              }
                            )}
                          </MaterialGrid>
                        </div>
                        <EnterprisePlanBanner />
                      </FadeIn>
                    </StylesProvider>
                  </Typography>
                </React.Fragment>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else return this.getPlanSkeletonLoader();
  }
}

export default withParams(SetPlan);
