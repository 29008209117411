import React from "react";

// MUI
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
    zapierBox: {
        border: "1px solid rgba(255, 74, 0, 1)",
        borderRadius: "10px",
    },
    workflow86Box: {
        border: "1px solid rgba(0, 0, 0, 1)",
        borderRadius: "10px",
    },
    zapierTitle: {
        backgroundColor: "rgba(255, 74, 0, 1)",
        color: "#ffffff",
        padding: "16px",
        borderRadius: "10px 10px 0px 0px",
        border: "1px solid rgba(255, 74, 0, 1)",
        fontWeight: "700 !important",
        fontSize: "14px",
        lineHeight: "17px",
    },
    workflow86Title: {
        backgroundColor: "rgba(0, 0, 0, 1)",
        color: "#ffffff",
        padding: "16px",
        borderRadius: "10px 10px 0px 0px",
        border: "1px solid rgba(0, 0, 0, 1)",
        fontWeight: "700 !important",
        fontSize: "14px",
        lineHeight: "17px",
    },
    cardContent: {
        padding: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

function InZapierOrW86Box(props) {
    const classes = useStyles();

    const { zapierOrW86, content } = props;

    return (
        <>
            {props.isExpanded && <Grid item xs={3}></Grid>}
            <Grid item xs={props.isExpanded ? 6 : 12}>
                <Grid container className={zapierOrW86 === "Zapier" ? classes.zapierBox : classes.workflow86Box}>
                    <Grid item xs={12} className={zapierOrW86 === "Zapier" ? classes.zapierTitle : classes.workflow86Title}>
                        In {zapierOrW86}
                    </Grid>
                    <Grid item xs={12} clasName={classes.cardContent}>
                        {content}
                    </Grid>
                </Grid>
            </Grid>
            {props.isExpanded && <Grid item xs={3}></Grid>}
        </>
    );
}

export default InZapierOrW86Box;
