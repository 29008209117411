import React, { useState } from "react";

// MUI
import { Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

// Styled components
const AnswerTextfield = styled(TextField)({
  color: "#757575",
  fontSize: "14px",
  lineHeight: "24px",
  background: "#FFF",
  borderRadius: "4px"
});

const QuestionText = styled("span")({
  color: "#000",
  fontSize: "14px",
  lineHeight: "24px"
});

function QuestionAnswer({
  historyAnswers,
  index,
  question,
  disabled,
  prebuildQuestionAnswers,
  setPrebuildQuestionAnswers,
  dismissContainerRef
}) {
  const [answer, setAnswer] = useState(
    historyAnswers ? historyAnswers[index] : ""
  );

  return (
    <Grid item xs={12} key={index}>
      <Grid container gap="4px">
        <Grid item xs={12}>
          <QuestionText>
            {question}
          </QuestionText>
        </Grid>
        <Grid item xs={12}>
          <AnswerTextfield
            fullWidth
            multiline
            maxRows={4}
            size="small"
            variant="outlined"
            placeholder="Type your answer here"
            value={answer}
            disabled={disabled}
            onFocus={() => dismissContainerRef(true)}
            onChange={e => setAnswer(e.target.value)}
            onBlur={() => {
              dismissContainerRef(false);
              const prevState = prebuildQuestionAnswers;

              if (index >= prevState.length) return;

              setPrebuildQuestionAnswers([
                ...prevState.slice(0, index),
                answer,
                ...prevState.slice(index + 1)
              ]);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

QuestionAnswer.propTypes = {
  historyAnswers: PropTypes.array,
  index: PropTypes.number,
  question: PropTypes.string,
  disabled: PropTypes.bool,
  prebuildQuestionAnswers: PropTypes.array,
  setPrebuildQuestionAnswers: PropTypes.func,
  dismissContainerRef: PropTypes.func
};
export default QuestionAnswer;
