import { toJS } from "mobx";

export const generateRowLabels = newRowNum => {
  if (!newRowNum) return [];

  let updatedRowLabels = [];
  for (let i = 1; i <= newRowNum; i++) {
    updatedRowLabels.push({
      rowNumber: i.toString(),
      rowLabel: "",
      rowKey: "row" + i.toString()
    });
  }
  return updatedRowLabels;
};

// Replaces an array item and returns a new array
export const replaceArrItem = (arr, index, updatedParams) => {
  return [
    ...arr.slice(0, index),
    Object.assign({}, arr[index], updatedParams),
    ...arr.slice(index + 1)
  ];
};

export const checkIfColumnNameUnique = (cols, value, colId) => {
  let columns = toJS(cols);

  for (let i = 0; i < columns.length; i++) {
    if (
      colId != columns[i].id &&
      convertToKey(columns[i].columnName) === convertToKey(value)
    ) {
      return false;
    }
  }

  return true;
};

export const checkIfAnyColumnNamesEmpty = (cols, value, colId, keyToCheck) => {
  let columns = toJS(cols);

  for (let i = 0; i < columns.length; i++) {
    if (value === "") return true;
    else if (colId != columns[i].id && columns[i][keyToCheck] === "")
      return true;
  }

  return false;
};

// Converts a given string to a key
export const convertToKey = str => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
};

export const parsingTablePreviewInput = content => {
  const { newTableCells, colId, rowIdx, value } = content;

  console.log(value);

  newTableCells[colId][rowIdx].rawValue = value; // Set raw value

  // If empty, set to empty and return
  if (value === "") {
    newTableCells[colId][rowIdx].isRequired = false;
    newTableCells[colId][rowIdx].defaultValue = "";
    return;
  }

  parseValue(content);
};

export const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z"
];

// Used to set the cell values
const parseValue = content => {
  const { newTableCells, colId, rowIdx } = content;

  // Set initially to not required/disabled
  newTableCells[colId][rowIdx].isRequired = false;
  newTableCells[colId][rowIdx].isDisabled = false;
  newTableCells[colId][rowIdx].isReadOnly = false;

  let defaultVal = getDefaultValAndSetProps(content);
  newTableCells[colId][rowIdx].defaultValue = defaultVal;
  console.log(newTableCells);
};

// Parse through the string
// Set required if includes REQUIRED
// Set disabled if includes DISABLE
// Set default value
const getDefaultValAndSetProps = content => {
  const { newTableCells, colId, rowIdx, value } = content;

  let newS = value.slice();

  if (newS.includes("={REQUIRED}")) {
    newTableCells[colId][rowIdx].isRequired = true;
    newS = newS.replace(/={REQUIRED}/g, "");
  }

  if (newS.includes("={DISABLE}")) {
    newTableCells[colId][rowIdx].isDisabled = true;
    newS = newS.replace(/={DISABLE}/g, "");
  }

  if (newS.includes("={READONLY}")) {
    newTableCells[colId][rowIdx].isReadOnly = true;
    newS = newS.replace(/={READONLY}/g, "");
  }

  return newS.trim();
};
