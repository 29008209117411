import { Grid, Skeleton } from "@mui/material";
import React from "react";


function NotesLoadingSkeleton() {


    const getItem = () => {
        return (
            <Grid item >
                <Skeleton variant="rounded" width="100%" height={27} sx={{marginBottom:"8px",borderRadius:"8px"}} />
                <Skeleton variant="rounded" width="100%" height={27} sx={{marginBottom:"8px",borderRadius:"8px"}} />
                <Skeleton variant="rounded" width="60%" height={27} sx={{marginBottom:"8px",borderRadius:"8px"}} />
            </Grid>

        );
    };

        return getItem();

}

export default NotesLoadingSkeleton;
