import { send_request } from "../../../../../utils/Request";

export const delay = (ms) =>
    new Promise((res) => {
        let timeout = setTimeout(res, ms);
        return timeout;
});

export const createHistoryMessage = (sessionId, threadId, content, role, messageType) => {
    return {
        sessionId: sessionId,
        threadId: threadId,
        content: content,
        role: role,
        messageType: messageType,
        children: []
      };
}
