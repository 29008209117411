import React from "react";
import { Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormPredertermineStore from "../FormConditionStores/FormPredertermineStore";
import ItemCondition from "./ItemPredertermine";
import FormMentionEditor from "../../../Component/FormMentionEditor";
class ItemStatementCondition extends React.Component {
  render() {
    const { indexStatement, condition, AnswerList } = this.props;
    const { operators } = condition;

    return (
      <Grid container xs={12} direction={"column"} spacing={2}>
        {operators && operators.length > 0 ? (
          <React.Fragment>
            {operators &&
              operators.map((con, index) => {
                if (AnswerList !== undefined) {
                  return (
                    <Grid container item xs={12} direction={"row"}>
                      <ItemCondition
                        key={index}
                        indexCondition={index}
                        indexStatement={indexStatement}
                        condition={con}
                        ConditionList={AnswerList.ConditionList[index]}
                        availablePlaceholders={this.props.availablePlaceholders}
                      />
                    </Grid>
                  );
                }
              })}
            <Grid xs={12} container item direction={"row"}>
              <Grid
                xs={5}
                alignItems={"center"}
                className={"text-button"}
                direction={"row"}
                container
                item
                onClick={() =>
                  FormPredertermineStore.addCondition(indexStatement)
                }
              >
                <AddIcon /> Add AND/OR condition
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              item
              direction={"row"}
              alignItems={"center"}
            >
              <Grid
                xs={1}
                className="d-flex-center"
                item
                style={{
                  fontWeight: 600
                }}
              >
                {" "}
                THEN
              </Grid>
              <Grid xs={11} item>
                <FormMentionEditor
                  className="key_padding border-none thenMargin"
                  placeholder="Insert condition value"
                  assignTask={true}
                  contentBlock={false}
                  variant="outlined"
                  text={condition.then.Statement.content}
                  onChange={(value) => {
                    FormPredertermineStore.setThenContent(
                      value,
                      indexStatement
                    );
                  }}
                  onFocus={(e) => {}}
                  {...(this.props.availablePlaceholders != null && {
                    availablePlaceholders: this.props.availablePlaceholders
                  })}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid container item xs={12} direction={"row"}>
              <Grid
                xs={5}
                container
                direction={"row"}
                item
                className="text-button"
                alignItems={"center"}
                onClick={() => FormPredertermineStore.addStatement()}
              >
                <AddIcon /> Add ELSE IF statement
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              item
              direction={"row"}
              alignItems={"center"}
            >
              <Grid
                xs={1}
                className="d-flex-center"
                item
                style={{
                  fontWeight: 600
                }}
              >
                {" "}
                ELSE
              </Grid>
              <Grid xs={11} item>
                <FormMentionEditor
                  className="key_padding "
                  placeholder="Insert a default value for question. Leave this blank if no value is to be selected. "
                  text={condition.then.Statement.content}
                  onChange={(value) => {
                    FormPredertermineStore.setThenContent(
                      value,
                      indexStatement
                    );
                  }}
                  assignTask={true}
                  contentBlock={false}
                  onFocus={(e) => {}}
                  {...(this.props.availablePlaceholders != null && {
                    availablePlaceholders: this.props.availablePlaceholders
                  })}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

export default ItemStatementCondition;
