import PropTypes from "prop-types";
import React, { useState, useEffect, memo } from "react";

const Timer = ({ startTime, endTime }) => {
  const [timeElapsed, setTimeElapsed] = useState("0.00s");

  useEffect(() => {
    if (!endTime) {
      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const elapsedTime = (currentTime - startTime) / 1000; // Time in seconds
        const minutes = Math.floor(elapsedTime / 60);
        const seconds = (elapsedTime % 60).toFixed(2);
        const timeString =
          minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
        setTimeElapsed(timeString);
      }, 100); // Update every 100 ms

      // Cleanup the interval on component unmount
      return () => {
        clearInterval(timer);
      };
    } else {
      const elapsedTime = (endTime - startTime) / 1000; // Time in seconds
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = (elapsedTime % 60).toFixed(2);
      const timeString =
        minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
      setTimeElapsed(timeString);
    }
  }, [startTime, endTime]);
  if (!endTime) {
    return <p style={{ color: "#757575" }}>{timeElapsed}</p>;
  } else
    return (
      <p style={{ color: "#757575", fontSize: "11px", marginBottom:"0px" }}>
        Completed in {timeElapsed}
      </p>
    );
};

Timer.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number, // endTime can be null initially, so not required
};
export default memo(Timer);
