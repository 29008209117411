import React, { Component } from "react";
import Button from "@mui/material/Button";
import { Grid, CircularProgress } from "@mui/material";
import { send_request } from "../../../utils/Request";
import ErrorIcon from "@mui/icons-material/Error";
import HandleJsonFunction from "../../Component/HandleJsonFunction";
import { toJS } from "mobx";
import InZapierOrW86Box from "./InZapierOrW86Box";

class SendSampleFromZapier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorMessage: "",
      status: ""
    };
  }

  selectMethod = value => {
    this.setState({
      method: value
    });
  };

  setSamplingStatus = async (projectId, componentId) => {
    send_request(
      `project-service/project/set-sampling-status/${projectId}/${componentId}`,
      "",
      "",
      "PATCH"
    )
      .then(response => {
        if (response && response.status === 200) {
          this.setState({
            status: "SAMPLING"
          });
        }
      })
      .catch(err => {
        throw err;
      });
  };

  componentDidMount = () => {
    this.setSamplingStatus(this.props.project_id, this.props.component_id);
  };

  isJsonString = str => {
    try {
      JSON.parse(str);
    } catch (err) {
      this.setState({ errorMessage: "Error with sample: " + err.message });
      return false;
    }
    return true;
  };

  getSample = async (component_id, version) => {
    this.setState({ error: false, loading: true });
    setTimeout(() => {
      send_request(
        "project-service/project/component/query/" +
          component_id +
          "/at-version/" +
          version,
        "",
        {}
      )
        .then(response => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              if (components[0].componentData.sample) {
                if (
                  this.isJsonString(
                    JSON.stringify(components[0].componentData.sample)
                  )
                ) {
                  this.props.componentStore.setField(
                    JSON.stringify(components[0].componentData.sample),
                    "",
                    "sample"
                  );

                  this.setState({ error: false, loading: false });
                  this.props.onChangeState(3, 3);
                } else {
                  this.setState({
                    error: true,
                    loading: false
                  });
                }
              } else {
                this.setState({
                  error: true,
                  errorMessage:
                    "A sample was not received. Please try again by sending another sample from Zapier",
                  loading: false
                });
              }
            }
          }
        })
        .catch(err => {
          this.setState({
            error: true,
            errorMessage:
              "A sample was not received. Please try again by sending another sample from Zapier",
            loading: false
          });
        });
    }, 2000);
  };

  render() {
    let zapierContent = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          1. In Zapier, go to the <b>Test action</b> step
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          <img
            src="/images/trigger_from_zapier_sample.png"
            style={{
              border: "thin solid #C4C4C4",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              width: "80%",
              height: "auto"
            }}
          />
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          2. Click <b>Test & Continue</b>
        </Grid>
      </Grid>
    );

    let workflow86Content = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          3. Return to <b>Workflow86</b> and click <b>Find Sample</b>
        </Grid>
      </Grid>
    );

    return (
      <Grid container spacing={2}>
        <InZapierOrW86Box zapierOrW86={"Zapier"} content={zapierContent} isExpanded={this.props.isExpanded} />

        <InZapierOrW86Box
          zapierOrW86={"Workflow86"}
          content={workflow86Content}
          isExpanded={this.props.isExpanded}
        />

        {this.state.loading && (
          <Grid item xs={12} className="textAlignCenter">
            🔍 searching for sample JSON...
          </Grid>
        )}

        {this.state.error && (
          <Grid item xs={12} className="textAlignCenter">
            <p className="err-text">
              <ErrorIcon className="iconDiscard" />
              {this.state.errorMessage}
            </p>
          </Grid>
        )}

        <Grid item xs={12} className="dialogContentRight">
          <Grid item xs={12}>
            <Button
              onClick={() => {
                this.props.onChangeState(1, 1);
              }}
              className="btnSpace"
              variant={"outlined"}
              color={"info"}
            >
              {" "}
              Back
            </Button>

            {this.props.componentStore.data.json !== "" && (
              <Button
                onClick={() => {
                  this.props.onChangeState(3, 3);
                }}
                className="btnSpace"
                variant={"outlined"}
                color={"info"}
              >
                {" "}
                Skip
              </Button>
            )}

            {this.state.status == "SAMPLING" ? (
              <Button
                onClick={() =>
                  this.getSample(this.props.component_id, this.props.version)
                }
                variant={"contained"}
                color={"primary"}
                disabled={this.state.loading}
              >
                {" "}
                Find Sample
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SendSampleFromZapier;
