import React from "react";
import { Grid, } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ConditionalWorkflowStore from "../ConditionalWorkflowStore";
import ItemCondition from "./ItemCondition";
import { toJS } from "mobx";
import { SelectThenValue } from "./components";
import ConditionalWorkflowPathStore from "../../ConditionalWorkflowPath/ConditionalWorkflowPathStore";


class ItemStatementCondition extends React.Component {
  state = {
    updateState: false,
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;

    this.setState({ updateState: !currentState });
  };

  render() {
    let { positions, placeholders } = this.props.conditionalWorkflowPath?ConditionalWorkflowPathStore:ConditionalWorkflowStore;
    const {
      indexStatement,
      condition,
      indexLogic,
      outputType,
      availablePlaceholders,
      conditionalWorkflowPath
    } = this.props;
    const { operators } = condition;

    let elseArray = [];
    let thenArray = [];
    placeholders = toJS(placeholders);

    if (
      positions.indexLogic == indexLogic &&
      positions.indexStatement == indexStatement &&
      positions.indexCondition == null &&
      positions.type == "ELSE"
    ) {
      elseArray = placeholders;
    }
    if (
      positions.indexLogic == indexLogic &&
      positions.indexStatement == indexStatement &&
      positions.indexCondition == null &&
      positions.type == "THEN"
    ) {
      thenArray = placeholders;
    }

    return (

      <div className={"logic-statement" }>

        {operators && operators.length > 0 ? (
          <Grid container direction={"column"} >


          <Grid
            container
            item
            direction={"column"}
            xs={12}
            className={this.props.classes.conditionFrame}
          >

            <>
              {operators &&
                operators.map((con, index) => (
                  <ItemCondition
                    classes={this.props.classes}
                    key={index}
                    isExpanded={this.props.isExpanded}
                    indexCondition={index}
                    indexStatement={indexStatement}
                    indexLogic={indexLogic}
                    condition={con}
                    availablePlaceholders={availablePlaceholders}
                    forceUpdateState={this.forceUpdateState}
                    forceUpdateStatement={this.props.forceUpdateState}
                    conditionalWorkflowPath={conditionalWorkflowPath}
                  />
                ))}
              <Grid item xs={12} >
                <div
                  className={`hover-add-button ${this.props.classes.spacing}`}
                  onClick={() => {
                    conditionalWorkflowPath?  ConditionalWorkflowPathStore.addCondition(
                        indexLogic,
                        indexStatement
                    ):
                    ConditionalWorkflowStore.addCondition(
                      indexLogic,
                      indexStatement
                    );
                    this.forceUpdateState();
                  }}
                >
                  <AddIcon /> Add AND/OR condition
                </div>
              </Grid>
            </>
            <>
              <Grid
                container
                xs={12}
                direction={"row"}
                item
                alignItems={"baseline"}
              >
                <Grid
                  xs={1}
                  container
                  item
                >
                  Then
                </Grid>
                <Grid xs={10} container item>
                  <SelectThenValue
                    value={condition.then.statement.content}
                    onChange={(value) => {
                      if (value !== condition.then.statement.content) {
                        conditionalWorkflowPath?ConditionalWorkflowPathStore.setThenContent(
                            value,
                            indexLogic,
                            indexStatement,
                            true
                        ):
                        ConditionalWorkflowStore.setThenContent(
                          value,
                          indexLogic,
                          indexStatement,
                          true
                        );
                      }

                      this.forceUpdateState();
                    }}
                  />
                </Grid>
              </Grid>
            </>
          </Grid>
</Grid>
        ) : (
          <Grid container item xs={12} direction={"column"}>
            <Grid item xs={12}
            >
              <div
                className={`hover-add-button ${this.props.classes.spacing}`}

                onClick={() => {
                  conditionalWorkflowPath?ConditionalWorkflowPathStore.addStatement(indexLogic):ConditionalWorkflowStore.addStatement(indexLogic);
                  this.props.forceUpdateState();
                }}
              >
                <AddIcon  /> Add ELSE IF statement
              </div>
            </Grid>
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              xs={12}
              className={
                this.props.isExpanded ? this.props.classes.conditionFrame : ""
              }
            >
              <Grid
                xs={this.props.isExpanded ? 1 : 12}
                className={this.props.classes.conditionSpacing}
                item
              >
                Else
              </Grid>
              <Grid xs={this.props.isExpanded ? 10 : 12} item>
                <SelectThenValue
                  value={condition.then.statement.content}
                  onChange={(value) => {
                    if (value !== condition.then.statement.content) {
                      conditionalWorkflowPath?   ConditionalWorkflowPathStore.setThenContent(
                              value,
                              indexLogic,
                              indexStatement,
                              true
                          ):
                      ConditionalWorkflowStore.setThenContent(
                        value,
                        indexLogic,
                        indexStatement,
                        true
                      );
                    }

                    this.forceUpdateState();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

export default ItemStatementCondition;
