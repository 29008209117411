import React from "react";
import { Fade } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const StrategyMessage = ({ classes }) => {
  return (
    <Fade in timeout={1000}>
      <div className={classes.gpt}>
        <p className={"m-0"}>
          Describe a business problem you need help with, or a requirement, goal
          or objective and I will generate possible ideas or solutions.
        </p>
      </div>
    </Fade>
  );
};

StrategyMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(AIBuilderStyles())(StrategyMessage);
