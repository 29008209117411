import React from "react";
import {
  Radio,
  Grid,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  Skeleton,
  styled,
  Autocomplete
} from "@mui/material";
import "../Cards.css";
import Button from "@mui/material/Button";
import Tooltips from "vardogyir-ui/Tooltips";
import { send_request } from "../../../utils/Request";
import { runLoading } from "../../../utils/showLoadingScreen";
import FormMentionEditor from "../../Component/FormMentionEditor";
import ListChip from "./ListChip";
import FromADBAutocomplete from "./FromADBAutocomplete";
import {FROM_DB, FROM_LIST} from "../../../utils/FormUtil";

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
  borderRadius: "4px",
  background: "#E8E8E8",
  width: "100%",
  height: height,
  margin: margin,
}));

export default class ChoiceQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      databaseAnswers: "manually", //to keep track if we are pulling answers from db
      databaseId: "",
      labelColumnId: "",
      valueColumnId: "",
      dbs: [],
      columns: [],
      currentColumnLabelObj: {},
      currentColumnValueObj: {},
      currentDbObj: {},
      loading: false,
      showFetchingColumnsLoader: false,
      showFetchingDatabaseLoader: false
    };
  }

  async componentDidMount() {
    const { databaseId, columnId, labelColumnId, valueColumnId } = this.props;

    // databaseAnswers will be set to "manually" if it doesn't exist
    this.setState({
      databaseAnswers: this.props.choiceAnswerOption
        ? this.props.choiceAnswerOption
        : "manually"
    });

    if (databaseId && databaseId != "") {
      if (columnId && columnId != "") {
        this.setState({
          databaseId: databaseId,
          labelColumnId: columnId,
          valueColumnId: columnId,
          databaseAnswers: FROM_DB
        });

        const data = await this.setLegacyData();
      } else if (
        labelColumnId &&
        labelColumnId != null &&
        labelColumnId != "" &&
        valueColumnId &&
        valueColumnId != null &&
        labelColumnId != ""
      ) {
        this.setState({
          databaseId: databaseId,
          columnId: "",
          labelColumnId: labelColumnId,
          valueColumnId: valueColumnId,
          databaseAnswers: FROM_DB
        });

        const data = await this.setData();
      }
    }
  }

  setLegacyData = async () => {
    const { columnId, databaseId } = this.state;

    const dbs = await this.getDbs().then(() => {
      let dbObj = this.state.dbs.filter((db) => {
        return db.databaseId == this.props.databaseId;
      });
      dbObj = dbObj[0];
      dbObj.name == ""
        ? (dbObj.name = dbObj.databaseId)
        : (dbObj.name = dbObj.name);
      this.setState({ currentDbObj: dbObj });
    });

    const columns = await this.getColumns(this.props.databaseId).then(() => {
      let col = this.state.columns.filter((col) => {
        return col.columnId == this.props.columnId;
      });
      col = col[0];
      col.name == "" ? (col.name = col.columnId) : (col.name = col.name);
      this.setState({ currentColumnLabelObj: col, currentColumnValueObj: col });
    });
  };

  setData = async () => {
    const { labelColumnId, valueColumnId } = this.props;
    const dbs = await this.getDbs().then(() => {
      let dbObj = this.state.dbs.filter((db) => {
        return db.databaseId == this.props.databaseId;
      });
      dbObj = dbObj[0];
      if (dbObj) {
        dbObj.name == ""
        ? (dbObj.name = dbObj.databaseId)
        : (dbObj.name = dbObj.name);
        this.setState({ currentDbObj: dbObj });
      }
    });

    const columns = await this.getColumns(this.props.databaseId).then(() => {
      let label = this.state.columns.filter((col) => {
        return col.columnId == labelColumnId;
      });

      if (label.length == 0) {
        //This is a session id or a record id
        label = labelColumnId;
      } else {
        label = label[0];

        if (typeof label == "object") {
          label.name == ""
            ? (label.name = label.columnId)
            : (label.name = label.name);
        }
      }

      let value = this.state.columns.filter((col) => {
        return col.columnId == valueColumnId;
      });

      if (value.length == 0) {
        value = valueColumnId;
      } else {
        value = value[0];

        if (typeof value == "object") {
          value.name == ""
            ? (value.name = value.columnId)
            : (value.name = value.name);
        }
      }
      this.setState({
        currentColumnLabelObj: label,
        currentColumnValueObj: value
      });
    });
  };

  getColumns = async (dbId) => {
    this.setState({ showFetchingColumnsLoader: true });

    const columns = await send_request(
      `database/get_columns/${dbId}`,
      "",
      "",
      "GET"
    )
      .then((res) => {
        if (res && res.data) {
          this.setState({ columns: res.data, showFetchingColumnsLoader: false });
        }
      })
      .catch((e) => console.log(e));

    this.setState({ showFetchingColumnsLoader: false });
  };

  getDbs = async () => {
    this.setState({ showFetchingDatabaseLoader: true });
    const columns = await send_request(`database/get_databases`, "", "", "GET")
      .then((res) => {
        if (res && res.data) {
          this.setState({ dbs: res.data, showFetchingDatabaseLoader: false });
        }
      })
      .catch((e) => console.log(e));
      this.setState({ showFetchingDatabaseLoader: false });
  };

  handleChangeLabelColumn = (value) => {
    if (typeof value == "object") {

      if (value.isPreset) {
        this.setState({
          labelColumnId: value.columnId,
          currentColumnLabelObj: value
        });
        this.props.changeColumnLabel(value.columnId);
        return;
      }

      this.setState({ labelColumnId: value.columnId });

      this.props.changeColumnLabel(value.columnId);

      let label = this.state.columns.filter((col) => {
        return col.columnId == value.columnId;
      });

      label = label[0];

      label.name == ""
        ? (label.name = label.columnId)
        : (label.name = label.name);

      this.setState({ currentColumnLabelObj: label });
    } else {
      this.setState({
        labelColumnId: value,
        currentColumnLabelObj: value
      });
      this.props.changeColumnLabel(value);
    }
  };

  handleChangeValueColumn = (givenVal) => {
    if (typeof givenVal == "object") {

      if (givenVal.isPreset) {
        this.setState({
          valueColumnId: givenVal.columnId,
          currentColumnValueObj: givenVal
        });
        this.props.changeColumnValue(givenVal.columnId);
        return;
      }

      this.setState({ valueColumnId: givenVal.columnId });

      this.props.changeColumnValue(givenVal.columnId);

      let value = this.state.columns.filter((col) => {
        return col.columnId == givenVal.columnId;
      });

      value = value[0];

      value.name == ""
        ? (value.name = value.columnId)
        : (value.name = value.name);

      this.setState({ currentColumnValueObj: value });
    } else {
      this.setState({
        valueColumnId: givenVal,
        currentColumnValueObj: givenVal
      });
      this.props.changeColumnValue(givenVal);
    }
  };

  // We need this function to handle recordId/sessionId columns selected
  handleRetrievingValue = (obj) => {
    if (!obj) return null;

    if (typeof obj === "object" && obj && Object.keys(obj).length > 0) return obj;
    else if (typeof obj === "string") {
      if (obj.includes("recordId_")) return { name: "Record Id", columnId: obj, isPreset: true }
      else if (obj.includes("sessionId_")) return { name: "Session Id", columnId: obj, isPreset: true }
    }
    return null;
  }

  handleChangeDatabase = async (value) => {
    this.setState({ databaseId: value.databaseId });
    this.props.changeDb(value.databaseId);
    
    let obj = this.state.dbs.filter((db) => {
      return db.databaseId == value.databaseId;
    });

    obj = obj[0];
    if (obj) obj.name == "" ? (obj.name = obj.databaseId) : (obj.name = obj.name);

    // Set value and label columns back to default
    this.handleChangeValueColumn("");
    this.handleChangeLabelColumn("");

    this.setState({ currentDbObj: obj });

    await this.getColumns(value.databaseId);
  };

  handleChangeAnswerRetrieving = async (event) => {
    this.props.handleChangeChoiceAnswerOption(event.target.value);
    if (
      event.target.value === FROM_DB &&
      this.state.dbs.length == 0 &&
      this.state.columns.length == 0
    ) {
      this.setState({ loading: true });
      const dbs = await this.getDbs().then(() => {
        this.setState({ databaseAnswers: FROM_DB });
        this.setState({ loading: false });
      });
    } else this.setState({ databaseAnswers: event.target.value });

    if (event.target.value) {
      this.props.resetAnswerList();
    } else {
      this.props.removeDbInfo();
    }
  };

  render() {
    const { databaseAnswers } = this.state;
    const { answer_list, type } = this.props;
    if (!this.state.loading) {
      return (
        <Grid
          xs={12}
          container
          item
          direction={"column"}
          alignItems={"flex-start"}
        >
          <Grid
            className={"answerOptions"}
            container
            item
            xs={12}
            direction={"column"}
          >
            <Grid item>
              <p
                className={"narrow"}
                onClick={() => console.log(databaseAnswers)}
              >
                Set answer options
              </p>
            </Grid>
            <Grid item>
              <Select
                variant="outlined"
                className={"formSelect"}
                value={this.state.databaseAnswers}
                onChange={this.handleChangeAnswerRetrieving}
              >
                <MenuItem value={"manually"}>manually</MenuItem>
                <MenuItem value={FROM_DB}> from a database</MenuItem>
                <MenuItem value={FROM_LIST}>
                  {" "}
                  from a list or list placeholder
                </MenuItem>
              </Select>
            </Grid>
          </Grid>

          {databaseAnswers === "manually" && (
            <>
              {answer_list.map((answer, key) => {
                return (
                  <Grid
                    className={"answerOptions"}
                    container
                    item
                    key={key}
                    direction={"row"}
                    xs={12}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                  >
                    {type == 4 ? (
                      <Radio disabled={true} />
                    ) : type == 5 ? (
                      <Checkbox disabled={true} />
                    ) : (
                      ""
                    )}

                    <Grid item xs={5}>
                      <div className="mention-editor-container">
                        <span className="mention-editor-label ">
                          Answer choice
                        </span>

                        <FormMentionEditor
                          style={{
                            width: "100%",
                            marginBottom: 8,
                            verticalAlign: "top",
                            minHeight: "16px",
                          }}
                          contentBlock={false}
                          assignTask={false}
                          text={answer[1]}
                          ref={this.props.label}
                          onFocus={(e) => {}}
                          placeholder={"Answer choice"}
                          onChange={(value) =>
                            this.props.handleAnswerpair(key, 1, value)
                          }
                          hideScrollBar={true}
                          {...(this.props.availablePlaceholders != null && {
                            availablePlaceholders: this.props
                              .availablePlaceholders,
                          })}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={5} className={"btnSpace"}>
                      <div className="mention-editor-container">
                        <span className="mention-editor-label move-label-right">
                          Answer value
                        </span>
                        <FormMentionEditor
                          style={{
                            width: "100%",
                            marginBottom: 8,
                            verticalAlign: "top",
                            marginLeft: 16,
                            minHeight: "16px",
                          }}
                          contentBlock={false}
                          onFocus={(e) => {}}
                          assignTask={false}
                          text={answer[0]}
                          placeholder={"Answer value"}
                          onChange={(value) => {
                            this.props.handleAnswerpair(key, 0, value);
                          }}
                          ref={this.props.value}
                          hideScrollBar={true}
                          {...(this.props.availablePlaceholders != null && {
                            availablePlaceholders: this.props
                              .availablePlaceholders,
                          })}
                        />
                      </div>
                    </Grid>
                    {answer_list.length > 1 && (
                      <Grid
                        item
                        xs={1}
                        style={{ marginLeft: 8, marginBottom: 8 }}
                      >
                        <p
                          className={"secondary-icon-btn"}
                          onClick={() => this.props.handlePoppair(key, event)}
                        >
                          REMOVE
                        </p>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
              <div
                className={"custom-icon-btn"}
                onClick={() => {
                  this.props.handleAddpair();
                }}
                style={{ fontSize: "14px" }}
              >
                + Add Option
              </div>
            </>
          )}

          {databaseAnswers === FROM_DB && (
            <Grid container rowSpacing={"4px"}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className={"narrow "}>Select database</p>
                  </Grid>
                  <Grid item xs={12}>
                    {!this.state.showFetchingDatabaseLoader ? 
                      <FromADBAutocomplete 
                        options={this.state.dbs}
                        value={this.state.currentDbObj && Object.keys(this.state.currentDbObj).length > 0 ? this.state.currentDbObj : null}
                        handleChange={this.handleChangeDatabase}
                        databaseId={this.state.databaseId}
                      /> :
                      <StyledSkeleton variant="rectangular" height="40px" width="300px" />
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className={"narrow"}>
                      When a record is selected in this column
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    {!this.state.showFetchingDatabaseLoader && !this.state.showFetchingColumnsLoader ? 
                      <FromADBAutocomplete 
                        isColumnSelect
                        options={this.state.columns}
                        value={this.handleRetrievingValue(this.state.currentColumnLabelObj)}
                        handleChange={this.handleChangeLabelColumn}
                        databaseId={this.state.databaseId}
                      />  :
                      <StyledSkeleton variant="rectangular" height="40px" width="300px" />
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <p className={"narrow"}>Return the value in this column</p>
                  </Grid>
                  <Grid item xs={12}>
                    {!this.state.showFetchingDatabaseLoader && !this.state.showFetchingColumnsLoader ?
                      <FromADBAutocomplete 
                        isColumnSelect
                        options={this.state.columns}
                        value={this.handleRetrievingValue(this.state.currentColumnValueObj)}
                        handleChange={this.handleChangeValueColumn}
                        databaseId={this.state.databaseId}
                      />  :
                      <StyledSkeleton variant="rectangular" height="40px" width="300px" />
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {databaseAnswers === FROM_LIST && (
            <ListChip
              availablePlaceholders={this.props.availablePlaceholders}
              answer_list={answer_list}
              handleReplaceAnswerList={this.props.handleReplaceAnswerList}
            />
          )}
        </Grid>
      );
    } else return <Grid container rowSpacing={1}>
      {[1, 2, 3, 4].map(() => {
        return <Grid item xs={12}>
        <StyledSkeleton variant="rectangular" height="40px" width="300px" />
      </Grid>
      })}
    </Grid>;
  }
}
