import React from "react";

//componetns
import { Grid } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { withStyles } from "@mui/styles";

//css
import styles from "../EmailTriggerStyles";
const Title = (props) => {
  return (
    <Grid container item xs={12} direction={"row"} alignItems={"center"}>
      <Grid container xs={5}>
        <p className={"bold"}>Data from incoming email</p>
      </Grid>
      <Grid xs={1}>
        <ArrowForward
          fontSize={"small"}
          className={props.classes.indent}
        ></ArrowForward>
      </Grid>
      <Grid xs={6}>
        <p className={"bold"}>Placeholder</p>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Title);
