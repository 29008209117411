import React from "react";
import { Dialog, Grid, Link, TextField, InputAdornment } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Twitter,
  LinkedIn,
  AlternateEmail,
  FileCopy,
} from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import "./css/share.css";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const StyledButton = styled(Button)({
  color: "#000",
  backgroundColor: "#FFF"
});

const Text = styled("span")({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "14px",
  lineHeight: "1.5em"
});

export default class Share extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openLink: false,
    };
  }
  render() {
    const encoded = encodeURIComponent(this.props.url);
    const emailBody =
      "I just built this workflow on Workflow86. You can preview it here:" +
      " " +
      this.props.url;
    return (
      <Grid
        xs={12}
        container
        item
        direction={"column"}
        alignItems={this.props.template ? "flex-start" : "center"}
      >
        {this.props.template ? (
          <Grid item xs={12} marginTop="20px">
            <p>Share this template</p>
          </Grid>
        ) : (
          <p className={"bold"}>📣 Spread the word</p>
        )}

        <Grid container>
          <Grid item style={{ marginRight: '10px' }}>
            <Link
              onClick={() => {
                this.setState({ openLink: true });
                navigator.clipboard.writeText(this.props.url);
              }}
              underline="hover"
              style={{
                textDecoration: "none",
              }}
            >
              <Button
                style={{ marginLeft: this.props.template && "0px" }}
                className="resp-sharing-button resp-sharing-button--email resp-sharing-button--large"
              >
                <LinkIcon className={"share-icon-white"} />
                Link
              </Button>
            </Link>
          </Grid>
          <Grid item style={{ marginRight: '10px' }}>
            <Link
              href={`mailto:?subject=${encodeURIComponent(
                "Discover an Automated Workflow!"
              )}&body=${encodeURIComponent(
                "Check out this amazing automated workflow I created on Workflow86: " + this.props.url
              )}`}
              target="_self"
              onClick={() => {
                this.setState({ openLink: false });
              }}
              underline="hover"
              style={{
                textDecoration: "none",
              }}
            >
              <Button
                style={{ backgroundColor: "#E6E5E3", color: "black" }}
                className="resp-sharing-button resp-sharing-button--large"
              >
                <div
                  aria-hidden="true"
                  className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                >
                  <AlternateEmail />
                </div>
                Email
              </Button>
            </Link>
          </Grid>
          <Grid item style={{ marginRight: '10px' }}>
            <Link
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encoded}`}
              target="_blank"
              onClick={() => {
                this.setState({ openLink: false });
              }}
              underline="hover"
              style={{
                textDecoration: "none",
              }}
            >
              <Button className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--large">
                <div
                  aria-hidden="true"
                  className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                >
                  <LinkedIn className={"share-icon-white"} />
                </div>
                LinkedIn
              </Button>
            </Link>
          </Grid>
          <Grid item style={{ marginRight: '10px' }}>
            <Link
              href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(
                "Check out this automated workflow I built on Workflow86: "
              )}&url=${this.props.url}`}
              target="_blank"
              onClick={() => {
                this.setState({ openLink: false });
              }}
              underline="hover"
              style={{
                textDecoration: "none",
              }}
            >
              <Button className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--large">
                <div
                  aria-hidden="true"
                  className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                >
                  <Twitter className={"share-icon-white"} />
                </div>
                Twitter
              </Button>
            </Link>
          </Grid>

        </Grid>
      
        <Grid
          container
          item
          xs={12}
          direction={"row"}
          justifyContent={this.props.template ? "flex-start" : ""}
        >
          <Grid
            container
            item
            md={12}
            lg={12}
            direction={"row"}
          >
            <TextField
              value={this.props.url}
              fullWidth={true}
              className={"key_padding"}
              variant="outlined"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => {
                      navigator.clipboard.writeText(this.props.url);
                    }}
                  >
                    <FileCopy className="custom-icon-btn" />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
