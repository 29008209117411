import React from "react";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DatabaseContentPreview from "./DatabaseContentPreview";
import DatabaseContent from "./DatabaseContent";

const styles = (theme) => ({
    checkbox: {
        marginRight: 40,
        color: "#afafaf",
        "&$checked": {
            color: "#2196F3",
        },
    },
    listtext: {
        fontSize: "13px",
    },
    checked: {},
    root: {
        width: "100%",

        backgroundColor: theme.palette.background.paper,
    },
});

class DisplayDatabaseContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { classes, isPreview } = this.props;

        try {
            return <div className={classes.root}>{isPreview ? <DatabaseContentPreview data={this.props.data} /> : <DatabaseContent {...this.props} />}</div>;
        } catch (e) {
            return <h5>Please specify answers in config </h5>;
        }
    }
}

DisplayDatabaseContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DisplayDatabaseContent);
