import { MenuItem, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { send_request } from '../../utils/Request';
import { styled } from '@mui/material/styles';

const MainContainer = styled(Paper)({
  width: '250px',
  textAlign: 'left',
  position: 'sticky',
  top: '16px',
  marginTop: '16px',
  paddingBottom: '24px',
  height: 'fit-content',
  boxShadow: 'none',
  border: '1px solid #e0e0e0',
  backgroundColor: '#ffffff',
  color: '#000000',
  boxSizing: 'border-box',
  alignSelf: 'flex-start',
  '@media (max-width: 650px)': {
    display: 'none',
  },
});

const Heading = styled('p')({
  color: '#000000',
  fontWeight: 'bold',
  fontSize: '14px',
  paddingLeft: '16px',
});

const CategoryItem = styled(MenuItem)(({ selected }) => ({
  color: selected && '#2196F3',
  fontWeight: selected && 'bold',
  fontSize: '14px',
}));

const CategorySelect = ({ changeCategory, currentCategory }) => {

  const [categories, setCategories] = useState([])

  const handleMenuClick = (e) => {
    const { myValue } = e.currentTarget.dataset;
    changeCategory(parseInt(myValue));
  }

  useEffect(() => {
    
    const fetchCategories = async () => {
      const response = await send_request(
        'project-service/template/projectCategories',"",
        {},
        "GET"
      ).catch((err) => {
        console.log(err);
      });

      setCategories(response.data);
    }

    fetchCategories();

  }, [])

  return (
    <MainContainer>
      <Heading>Categories</Heading>
      <CategoryItem
        selected={0 === currentCategory}
        data-my-value={0}
        data-tut="reactour__marketplace__categories"
        onClick={handleMenuClick}
      >
        All
      </CategoryItem>
      {categories.map(c => (
        <CategoryItem
          selected={c.categoryId === currentCategory}
          data-my-value={c.categoryId}
          onClick={handleMenuClick}
        >
          {c.displayName}
        </CategoryItem>
      ))}
    </MainContainer>
  )
}

export default CategorySelect
