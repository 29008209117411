import { query, mutation, subscription } from "gql-query-builder";
export const GET_NOTES = (projectId) => {
  return `
  { projectById(id: "${projectId}") {
      notes {
        id
        type
        data {
          color
          width
          height
          label
        }
        source
        target
        sourceHandle
        targetHandle
        style {
          strokeWidth
          stroke
        }
        arrowHeadType
        position {
          x
          y
        }
      }
    }
  }
  `;
};
export const GET_PRODUCTION_NOTES = (projectId) => {
  return `
  { publishedProjectById(id: "${projectId}") {
      notes {
        id
        type
        data {
          color
          width
          height
          label
        }
        source
        target
        sourceHandle
        targetHandle
        style {
          strokeWidth
          stroke
        }
        arrowHeadType
        position {
          x
          y
        }
      }
    }
  }
  `;
};
export const GET_WORKFLOW = (projectId) => {
  return `
  { projectById(id: "${projectId}") {
      projectId
      version
      name
      categories
      imagePath
      parentFolderId
      status
      extras {
        readme
        showReadmeDialog
        notifyOnSessionFail
        notifyOnSessionTermination
        notifyEmails
      }
      parentFolderId
      lastPublished
      lastModified
      showNotesToggle
      viewport {
        x
        y
        zoom
      }
      UIOnlyIconPos {
      nodeId
      type
      viewData {
          x
          y
        }
      }
      components {
        componentId
        description
         instructions{
      buildInstruction
      editInstruction
      }
        generatedTitle
        type
        nextComponents{
          componentId
        }
        viewData {
          x
          y
        }
        
        componentId
        componentData {
          name
          lastModified
          targetDatabaseId
          isFromConditionalPath
          
          color{
          bg
          border
          }
          data {
          targetDatabaseId
          }
        }
      }
    }
  }
  `;
};
export const GET_WORKFLOW_COMPONENTS = (projectId) => {
  return `
  { projectById(id: "${projectId}") {
      components {
        componentId
        description
         instructions{
      buildInstruction
      editInstruction
      }
        generatedTitle
        type
        nextComponents{
          componentId
        }
        viewData {
          x
          y
        }
        
        componentId
        componentData {
          name
          lastModified
          targetDatabaseId
          isFromConditionalPath
          
          color{
          bg
          border
          }
          data {
          targetDatabaseId
          }
        }
      }
    }
  }
  `;
};
export const GET_PRODUCTION_WORKFLOW = (projectId) => {
  return `
{
  publishedProjectById(id: "${projectId}") {
    projectId
    version
    name
    categories
    imagePath
    parentFolderId
    versionEstablished {
      by
    }
    extras {
      readme
      showReadmeDialog
      notifyOnSessionFail
      notifyOnSessionTermination
      notifyEmails
    }
    parentFolderId
    lastPublished
    lastModified
    viewport {
      x
      y
      zoom
    }
    notes {
      type
      id
      position {
        x
        y
      }
      data {
        color
        width
        height
        label
      }
       source
        target
        sourceHandle
        targetHandle
        style {
          strokeWidth
          stroke
        }
        arrowHeadType
       
    }
    components {
      description
      instructions{
      buildInstruction
      editInstruction
      }
      generatedTitle
      componentId
      type
      
      nextComponents{
        componentId
      }
      viewData {
        x
        y
      }

      componentId
      componentData {
          name
          lastModified
          isFromConditionalPath
          targetDatabaseId
          data {
            targetDatabaseId
          }
          color{
          bg
          border
          }
       }
    }
  }
}
`;
};

export const GET_LATEST_PRODUCTION_VERSION = (projectId) => {
  return `
{
  publishedProjectById(id: "${projectId}") {
    projectId
    version
  }
}
`;
};
export const  GET_NEXT_COMPONENTS =(componentId) =>{
  {
    return `{nextComponentsByComponentId(id:"${componentId}")
    {
    type
    componentData {
          name
          lastModified
          isFromConditionalPath
          color{
          bg
          border
          }
       }
           viewData {
        x
        y
      }
      type
      generatedTitle
      componentId
      description
    }
    
    }`

  }
}

export const GET_SESSION_WORKFLOW = (projectId, version) => {
  return `
  { projectByIdAndVersion(id: "${projectId}", version: ${version}) {
      projectId
      version
      name
      categories
      imagePath
      parentFolderId
      extras {
        readme
        showReadmeDialog
      }
      parentFolderId
      lastPublished
      lastModified
      viewport {
        x
        y
        zoom
      }
      components {
        generatedTitle
        description
        componentId
        type
        nextComponents{
          componentId
        }
        viewData {
          x
          y
        }
        
        componentId
        componentData {
          name
          isFromConditionalPath
          lastModified
          targetDatabaseId
          data {
            targetDatabaseId
          }
       }
      }
    }
  }
  `;
};

export const SAVE_EVENT = (event) => {
  return mutation({
    operation: "saveEvent",
    variables: {
      input: {
        value: event,
        type: "CanvasEvent",
        required: false,
      },
    },
    fields: ["updated", "lastModified"],
  });
};

export const COPY_SELECTION_SAVE_EVENT = (event) => {
  return mutation({
    operation: "saveEvent",
    variables: {
      input: {
        value: event,
        type: "CanvasEvent",
        required: false,
      },
    },
    fields: [
      "updated",
      "lastModified",
      {
        copiedSelection: [
          "componentId",
          "type",
          { nextComponents: ["componentId"] },
          { viewData: ["x", "y"] },
          {componentData: [
              "name",
              "targetDatabaseId",
              "isFromConditionalPath",
              {color:["bg","border"]},

              {data: [
                "targetDatabaseId"
              ]}
            ]}

        ],
      },
      {
        copiedNotes: [
          "id",
          "type",
          { data: ["content", "color", "width", "height", "label"] },
          "source",
          "target",
          "sourceHandle",
          "targetHandle",
          { style: ["strokeWidth", "stroke"] },
          "arrowHeadType",
          { position: ["x", "y"] },
        ],
      },
    ],
  });
};
export const COPY_COMPONENT_SAVE_EVENT = (event) => {
  return mutation({
    operation: "saveEvent",
    variables: {
      input: {
        value: event,
        type: "CanvasEvent",
        required: false,
      },
    },
    fields: [
      "updated",
      "lastModified",
      {
        copiedComponent: [
          "componentId",
          "description",
          "type",
          { nextComponents: ["componentId"] },
          { viewData: ["x", "y"] },
          { componentData: ["name","isFromConditionalPath"] },
        ],
      },
    ],
  });
};

export const MAP_A_ZAP_SAVE_EVENT = (event) => {
  return mutation({
    operation: "saveEvent",
    variables: {
      input: {
        value: event,
        type: "CanvasEvent",
        required: false,
      },
    },
    fields: [
      "updated",
      "lastModified",
      {
        zapObjects: [
          "creatorIcon",
          "titles",
          "descriptions",
          "params",
          "iconUrls",
        ],
      },
    ],
  });
};

export const GET_WORKFLOW_BY_VERSION = (projectId, version) => {
  return query({
    operation: "projectByIdAndVersion",
    variables: { id: projectId, version: parseInt(version) },
    fields: [
      "projectId",
      "status",
      "version",
      "name",
      "categories",
      "imagePath",
      "parentFolderId",
      {versionEstablished:["by", "comment"]},
      { extras: ["readme", "showReadmeDialog"] },
      "lastPublished",
      "lastModified",
      { viewport: ["x", "y", "zoom"] },
      {
        notes: [
          "type",
          "id",
          { position: ["x", "y"] },
          { data: [ "color", "width", "height"] },
         "source",
          "target",
         "sourceHandle",
          "targetHandle",
          {style :[
            "strokeWidth",
            "stroke"]
          },
          "arrowHeadType",
          {position :[
            "x",
            "y"
                ]
          }
        ],
      },
      {
        components: [
          "generatedTitle",
          "description",
          {"instructions":["buildInstruction","editInstruction"]},
          "componentId",
          "type",
          { nextComponents: ["componentId"] },
          { viewData: ["x", "y"] },
          { componentData: ["name", "lastModified","targetDatabaseId","isFromConditionalPath",{data: [
                "targetDatabaseId"
              ]},{color:["bg","border"]}] },
        ],
      },
    ],
  });
};

export const GET_WORKFLOW_LAST_UPDATED_BY_VERSION = (projectId, version) => {
  return query({
    operation: "projectByIdAndVersion",
    variables: { id: projectId, version: parseInt(version) },
    fields: ["lastPublished", "lastModified"],
  });
};
