import React, { useState } from "react";

// Util
import config from "../../../../../config";
import TriggerFromAWorkflowInfo from "./TriggerFromAWorkflowInfo";
import TriggerViaEmailInfo from "./TriggerViEmailInfo";
// MUI
import { OpenInNew } from "@mui/icons-material";
import { Grid, InputAdornment, styled, TextField } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormModal from "./FormModal";

const LinkClipBoard = styled(TextField)({});

function TriggerStartComponent({ component, BlackButton, TriggerTitle, TriggerDescription, version, isTest, handleTestOpen, disabled}) {
    const [modalOpen, setModalOpen] = useState(false);

    const getTriggerInformation = () => {
        if (!component || !component.type) return <></>;

        switch (component.type) {
            case "form":
                return getFormInfo();
            case "bulk_assessment":
                return getUploadSpreadsheetInfo();
            case "trigger_from_a_workflow":
                return <TriggerFromAWorkflowInfo componentId={component.componentId} version={version} TriggerTitle={TriggerTitle} TriggerDescription={TriggerDescription} BlackButton={BlackButton}  />;
            case "trigger_from_zapier":
                return getTriggerFromZapierInfo();
            case "webhook":
                return getWebhookInfo();
            case "schedule_start":
                return getScheduleStartInfo();
            case "trigger_via_email":
                return <TriggerViaEmailInfo componentId={component.componentId} version={version} TriggerTitle={TriggerTitle} TriggerDescription={TriggerDescription}  LinkClipBoard={LinkClipBoard} />;
            default:
                return <></>;
        }
    };

    const getFormInfo = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TriggerTitle>📝 Make a form submission to start this workflow</TriggerTitle>
                </Grid>
                <Grid item xs={12}>
                    <TriggerDescription>
                        Your trigger component is a form import, so it will start once a form has been submitted. You can open the form below or use the link below to open it in your browser.
                    </TriggerDescription>
                </Grid>
                {!isTest &&
                    <Grid item xs={12}>
                        <LinkClipBoard
                            disabled
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={`${config.FORM.FORM_FRONT_END}/form/${component.componentId}`}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CopyToClipboard text={`${config.FORM.FORM_FRONT_END}/form/${component.componentId}`}>
                                            <FileCopyIcon className="custom-icon-btn" />
                                        </CopyToClipboard>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <BlackButton variant="contained" disabled={disabled} onClick={() => {
                        if (isTest) handleTestOpen();
                        else setModalOpen(true);
                    }} startIcon={<OpenInNew />}>
                        Open Form
                    </BlackButton>
                </Grid>
                <FormModal 
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    formUrl={`${config.FORM.FORM_FRONT_END}/form/${component.componentId}`}
                />
            </Grid>
        );
    };

    const getTriggerFromZapierInfo = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TriggerTitle>⚡ Your workflow is triggered by Zapier</TriggerTitle>
                </Grid>
                <Grid item xs={12}>
                    <TriggerDescription>This workflow is triggered by a zap in Zapier. To start this workflow, you will need to run this zap from Zapier.</TriggerDescription>
                </Grid>
                <Grid item xs={12}>
                    <BlackButton variant="contained" onClick={() => window.open("https://zapier.com/", "_blank")} startIcon={<OpenInNew />}>
                        Open Zapier
                    </BlackButton>
                </Grid>
            </Grid>
        );
    };

    const getUploadSpreadsheetInfo = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TriggerTitle>🔼 Upload a spreadsheet</TriggerTitle>
                </Grid>
                <Grid item xs={12}>
                    <TriggerDescription>This trigger component is an Upload Spreadsheet, so it will run when you upload a spreadsheet via the portal below.</TriggerDescription>
                </Grid>
                {!isTest &&
                    <Grid item xs={12}>
                        <LinkClipBoard
                            disabled
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={`${config.FORM.FORM_FRONT_END}/bulk/${component.componentId}`}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CopyToClipboard text={`${config.FORM.FORM_FRONT_END}/bulk/${component.componentId}`}>
                                            <FileCopyIcon className="custom-icon-btn" />
                                        </CopyToClipboard>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <BlackButton  variant="contained" onClick={() => {
                        if (isTest) handleTestOpen();
                        else window.open(`${config.FORM.FORM_FRONT_END}/bulk/${component.componentId}`, "_blank");
                    }} startIcon={<OpenInNew />}>
                        Open Portal
                    </BlackButton>
                </Grid>
            </Grid>
        );
    };

    const getWebhookInfo = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TriggerTitle>📩 Send a payload to the endpoint to start this workflow</TriggerTitle>
                </Grid>
                <Grid item xs={12}>
                    <TriggerDescription>
                        Your trigger component is a webhook import, so send a payload from the app or data source pointing to the endpoint below to start this workflow. Remember that the payload will
                        need the 🔑 secret key generated when you set up the webhook component as well.{" "}
                    </TriggerDescription>
                </Grid>
                <Grid item xs={12}>
                    <LinkClipBoard
                        disabled
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={`https://webhook.workflow86.com/webhook/${component.componentId}`}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CopyToClipboard text={`https://webhook.workflow86.com/webhook/${component.componentId}`}>
                                        <FileCopyIcon className="custom-icon-btn" />
                                    </CopyToClipboard>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    const getScheduleStartInfo = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TriggerTitle>⏰ Your workflow will start at the set date and time</TriggerTitle>
                </Grid>
                <Grid item xs={12}>
                    <TriggerDescription>
                        Your source component is a Schedule Start component, so this workflow will automatically start at the date and time set inside that component.
                    </TriggerDescription>
                </Grid>
            </Grid>
        );
    };

    return getTriggerInformation();
}

export default TriggerStartComponent;
