import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import JoditEditor from "jodit-pro-react";

const styles = (theme) => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
    "& .jodit-container": {
      border: "none !important",
    },
  },
  textroot: {
    width: "100%",
    margin: "auto",
    height: "100%",
  },
});

class Rawtext extends React.Component {
  constructor() {
    super();
    this.state = {
      editorState: "",
      answer: "",
    };
  }

  config = {
    license: "CC7FX-1D761-AUOP5-JG0GX",
    readonly: true,
    toolbar: false,
    disablePlugins: [
      "keyboard",
      "google-search",
      "fullsize",
      "about",
      "classSpan",
      "google-maps",
      "export-docs"
    ],
    askBeforePasteHTML: true,
    defaultActionOnPaste: "insert_only_text",
    uploader:false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    minHeight: "0px",
  };

  setRef = (jodit) => (this.jodit = jodit);

  render() {
    const { classes } = this.props;
    try {
      return (
        <div className={classes.root}>
          <JoditEditor
            ref={this.setRef}
            value={this.props.data.answer_data_default}
            config={this.config}
          />
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}
Rawtext.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Rawtext);
