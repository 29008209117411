import React, { useContext, useState, useRef, useCallback, useEffect } from "react";
// Util
import ProjectStore from "../../../../../ProjectCanvas/ProjectStore";
// Canvas
import { CanvasContext } from "../../../WorkflowCanvas";
// Custom components
import ComponentSettingsToolbar from './ComponentNodeUtil/ComponentSettingsToolbar';
import ComponentNodeView from './ComponentNodeUtil/ComponentNodeView';
// MUI
import { withStyles } from "@mui/styles";
// Icons
import Error from "@mui/icons-material/Error";
// MobX
import { inject, observer } from "mobx-react";
import InstructionsInfo from "../../../InstructionInfo";
import { styles } from './ComponentNodeUtil/CustomComponentNodeUtil';
import BaseComponent from "./BaseComponent";

const VersionCanvasComponent = inject("CanvasStore")(
    observer(({ id, data, classes, selected }) => {

      const { modeInfo } = useContext(CanvasContext);
      if (modeInfo === undefined) {
        return <BaseComponent classes={classes} data={data} isConnectable={isConnectable} />;
      }

      const parentRef = useRef(null);
      const ref = useRef(null);
      const { edit } = useContext(CanvasContext);


      // State hooks
      const [editComponent, setEditComponent] = edit ? edit : useState({});
      const [isSelectedComponent, setIsSelectedComponent] = useState(false);
      const [svgSelectedClass, setSvgSelectedClass] = useState("");
      const [mode, setMode] = modeInfo;

      // Effect hooks
      useEffect(() => {
        const dataComponentId = (data && data.componentId) ? data.componentId : id;
        setIsSelectedComponent((editComponent && dataComponentId === editComponent.componentId));
      }, [editComponent, data, id]);


      useEffect(() => {
        const isTrue = selected || isSelectedComponent;
        setSvgSelectedClass(isTrue ? classes.selectedIcon : "");
      }, [isSelectedComponent, selected, classes.selectedIcon]);


      // Handlers
      const handlePreview = useCallback((event) => {
        event.stopPropagation();
        ProjectStore.state.production = true;
        ProjectStore.state.canvasView = false;
        if (edit ) {
          if (!editComponent){
            setEditComponent({ ...data, componentId: id });
          } else {
            setEditComponent(null);
            ProjectStore.state.canvasView = true;
            ProjectStore.state.production = false;
          }

        }
      }, [edit, editComponent, data, id]);



      const nodeSettingsMenuItems = [
        {
          title: "View component",
          icon: "preview",
          onClick: handlePreview
        }
      ];

      // Render methods
      return (
        <>
          <div
              draggable={false}
              ref={parentRef}
              onDragOver={(event) => {
                event.preventDefault();
              }}
              className={classes.nodeWrapper}
          >
            <div onClick={handlePreview}>
              {data.error && (
                  <Error className={`error-text ${classes.downsize}`}></Error>
              )}
              <ComponentNodeView
                classes={classes}
                data={data}
                selected={selected}
                isSelectedComponent={isSelectedComponent}
                mode={mode}
                editComponent={editComponent}
              />
            </div>

              <ComponentSettingsToolbar
                  selected={selected}
                  actions={nodeSettingsMenuItems}
                  classes={classes}
                  data={data}
              />

              {data.instructions &&
                  (data.instructions.buildInstruction ||
                      data.instructions.editInstruction) && (
                      <InstructionsInfo
                          data={data}
                          parent={parentRef}
                          textRef={ref}
                      />
                  )}

          </div>
        </>
      );
    })
);

export default withStyles(styles)(VersionCanvasComponent);
