import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const styles = (theme) => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  input: {
    pointerEvents: "none",
    width: "30%",
    margin: "auto"
  }
});

class SingleSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    try {
      return (
        <div className={classes.root}>
          <FormControl style={{ width: "70%" }}>
            <Select></Select>
            <Paper>
              <MenuItem value="">
                {" "}
                <em>None</em>
              </MenuItem>
              {this.props.data.answer_list.map((n, i) => {
                return <MenuItem value={n[1]}>{n[1]}</MenuItem>;
              })}
            </Paper>
          </FormControl>
        </div>
      );
    } catch (e) {
      return <h5>Please specify answers in config</h5>;
    }
  }
}

SingleSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SingleSelect);
