import React from "react";
import { Grid, Skeleton } from "@mui/material";

import { styled } from "@mui/system";

const StyledSkeleton = styled(Skeleton)({
    borderRadius: "4px",
    background: "#E8E8E8",
});

export const ComponentLoadingSkeleton = () => {
    return (
        <Grid container paddingLeft={"16px"} paddingRight={"16px"} paddingTop={"24px"}>
            <Grid container marginBottom={"24px"} spacing={5}>
                <Grid item xs={5}>
                    <StyledSkeleton variant={"rectangular"} height={"40px"} width={"100%"} />
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs={1}>
                    <StyledSkeleton variant={"rectangular"} height={"40px"} width={"100%"} />
                </Grid>
                <Grid item xs={1}>
                    <StyledSkeleton variant={"rectangular"} height={"40px"} width={"100%"} />
                </Grid>
            </Grid>
            <Grid item xs={10} paddingRight={"32px"}>
                <IndividualComponentLoader />
            </Grid>
            <Grid item xs={2}>
                <StyledSkeleton variant={"rectangular"} height={"90vh"} width={"100%"} />
            </Grid>
        </Grid>
    );
};

export const IndividualComponentLoader = () => {
    return <StyledSkeleton variant={"rectangular"} height={"90vh"} width={"100%"} />;
};
