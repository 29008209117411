import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Tooltip,
  Button,
  TextField,
  Chip,
  Box,
  Skeleton,
} from "@mui/material";
import { styled } from '@mui/system';
import { Save, Edit, Close } from "@mui/icons-material";
import "./Task.css";
import TaskChip from "./TaskChip";
import { send_request } from "../../utils/Request";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import JoditEditor from "jodit-pro-react";
import { format } from "../../utils/Date";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import UserDisplayName from "../User/components/UserDisplayName";
import ChipInput from "../ChipInput/ChipInput";
import { Autocomplete } from "@mui/material";
import TextEditor from "../TextEditor/TextEditor";
import { useLocation, useParams } from "react-router";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDayjs";
import dayjs from "dayjs";
import { getComponentSessionWithSessionIdAndComponentId } from "../../utils/Requests/Projects";
import { generateTimeTracking } from "./Util";
import { getColourFromString } from "../../utils/ColourNameGenerator";
import { ListItemChip } from "../../utils/ListItemChip";
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const CONFIG = {
  license: "CC7FX-1D761-AUOP5-JG0GX",
  readonly: true,
  toolbar: false,
  disablePlugins: [
    "keyboard",
    "google-search",
    "fullsize",
    "about",
    "classSpan",
    "google-maps",
    "export-docs",
  ],
  askBeforePasteHTML: true,
  defaultActionOnPaste: "insert_only_text",
  uploader: {
    insertImageAsBase64URI: true,
  },
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
};

const styles = () => ({
  root: {
    maxWidth: "800px",
    "& .jodit-container": {
      border: "none !important",
      minHeight: "unset!important",
    },
    "& .jodit-wysiwyg": {
      padding: "0px!important",
      minHeight: "unset!important",
    },
    "& .jodit-workplace": {
      padding: "0px!important",
      minHeight: "unset!important",
    },
    "& a": {
      color: "-webkit-link",
    },
  },
});

const AppBarButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: "#707070",
  fontSize: "12px",
  padding: "4px 8px",
  minWidth: "auto",
  marginLeft: "0.25rem",
  '& .MuiSvgIcon-root': {
    fontSize: 'small',
    color: "#707070",
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: '#707070',
    '& .MuiSvgIcon-root': {
      color: '#707070',
    },
  },
}));

const CancelButton = styled(AppBarButton)(({ theme }) => ({
  marginRight: '0.5rem',
  '&:hover': {
    backgroundColor: 'rgba(255, 0, 0, 0.04)',
    color: '#d32f2f',
    '& .MuiSvgIcon-root': {
      color: '#d32f2f',
    },
  },
}));

const HeaderBox = styled(Box)({
  display: 'flex', 
  justifyContent: 'flex-end', 
  width: '100%', 
  padding: '8px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)'
});

const TaskDetailsPage = (props) => {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [assignedEmail, setAssignedEmail] = useState([]);
  const [taskSubject, setTaskSubject] = useState("");
  const [assignedUser, setAssignedUser] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [taskDescription, setTaskDescription] = useState("");
  const [timeTracking, setTimeTracking] = useState(null);

  const search = useLocation().search;
  const edit = new URLSearchParams(search).get("edit");

  const params = useParams();
  
  const editTask = (task) => {
    setLoading(true);
    setEditMode(true);

    send_request("authz-service/get-users-task/", "", "", "get")
    .then((value) => {
      setAllUsers(value.data);

      //set assigned user
      let assignedUser = [];

      if (task.assignedUser) {
        task.assignedUser.map((username) => {
          let a = value.data.filter((user) => {
            return user.userName === username;
          });

          if (a.length > 0) {
            assignedUser.push(a[0]);
          }
        });
      }
      setAssignedUser(assignedUser);
      setTaskDescription(task.taskDescription);
      setLoading(false);
      return task;
    })
  };

  const saveTask = (task) => {
    setLoading(true);

    task.overDue = false;
    if (task.dueDate) {
      const nowDate = new Date();
      const nowDataMillis = nowDate.getTime();

      const dueDate = new Date(task.dueDate);
      const dueDateMillis = dueDate.getTime();

      if (nowDataMillis > dueDateMillis) {
        task.overDue = true;
      }
    }

    task.taskSubject = taskSubject;
    task.assignedEmail = assignedEmail;

    task.assignedUser = [];

    assignedUser.map((user) => {
      task.assignedUser.push(user.userName);
    });

    task.taskDescription = taskDescription;

    send_request("task/task/update_task_detail", task, null, "POST").then(
      () => {
        setEditMode(false);
        setLoading(false);
        setTask(task);
      }
    );
  };

  const cancelEdit = () => {
    setEditMode(false);
    // Reset any changes made during edit mode
    setAssignedEmail(task.assignedEmail || []);
    setTaskSubject(task.taskSubject);
    setAssignedUser(task.assignedUser ? task.assignedUser.map(username => allUsers.find(user => user.userName === username)).filter(Boolean) : []);
    setTaskDescription(task.taskDescription);
  };

  const openDetails = (task) => {
    //open individual task page
    window.open(
      `https://app.workflow86.com/project/canvas/${task.projectId}/sequential_form/${task.componentId}`,
      "_blank"
    );
  };

  const handleChangeEmail = (chip) => {
    let key = chip.key;
    if (chip.isNotPlaceholder !== true) {
      key = "${" + key + "}";
    }
    const updatedEmail = [...assignedEmail, key];
    setAssignedEmail(updatedEmail);
  };

  const handleDeleteEmail = (index) => {
    if (assignedEmail) {
      assignedEmail.splice(index, 1);
      setAssignedEmail(assignedEmail);
    }
  };

  const handleSetUsers = (event, inputValue, reason) => {
    if (reason == "clear") {
      setAssignedUser([]);
    } else {
      setAssignedUser(inputValue);
    }
  };

  const handleChangeDescription = (html) => {
    setTaskDescription(html);
  };

  const handleTaskDueDateChange = (value) => {
    const newTask = { ...task };
    newTask.dueDate = value;
    setTask(newTask);
  };

  const renderColorStatus = (status) => {
    let color;
    switch (status) {
      case "FAIL":
        color = "#55A77A26";
        break;
      case "SUCCESS":
      case "SUCCESS_END":
        color = "#55A77A26";
        break;
      case "INPROGRESS":
        color = "#FFF1DD";
        break;
      case "WAITING":
        color = "#FFF1DD";
        break;
      case "TERMINATED":
        color = "#55A77A26";
        break;
      case "PAUSED":
        color = "#FFF1DD";
        break;
    }

    return color
  }

  useEffect(() => {
    const getTask = async () => {
      const { id } = params;

      const json = await send_request(`task/task/get_task/${id}`)
        .then(async (response) => {
          let task = response.data;

          let componentIds = [];
          componentIds.push(task.componentId);

          const taskInfo = await send_request(
            "project-service/project/get_project_info",
            componentIds,
            "",
            "POST"
          )
            .then((res) => {
              const taskExtras = res.data;

              if (taskExtras[0] && task.componentId == taskExtras[0].componentId) {
                task.componentName = taskExtras[0].componentName;
                task.projectName = taskExtras[0].projectName;
                task.projectId = taskExtras[0].projectId;
              }
              task["overDue"] = false;
              if (task.dueDate) {
                const nowDate = new Date();
                const nowDataMillis = nowDate.getTime();

                const dueDate = new Date(task.dueDate);
                const dueDateMillis = dueDate.getTime();

                if (nowDataMillis > dueDateMillis){
                  task.overDue = true
                }
              }

              var currentTask = task;
              setTask(currentTask);

              if (task.assignedEmail === null) {
                setAssignedEmail([]);
              } else {
                setAssignedEmail(task.assignedEmail);
              }

              setTaskSubject(task.taskSubject);
              setLoading(false);
              return currentTask;
            })
            .then(async (task) => {
                const res = await getComponentSessionWithSessionIdAndComponentId(task.sessionId, task.componentId);
                const componentSession = res.data;
                  
                if (componentSession) {
                  const componentTimeTracking = generateTimeTracking(componentSession);
                  setTimeTracking(componentTimeTracking);
                }
      
                return task;
            })
            .then((currentTask) => {
              if (edit) editTask(currentTask)
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTask();
    if (edit) {
      setEditMode(true);
    }
  }, []);

  const renderTimeTracking = (timeTracking, status) => {
    let timeTrackingInDuration = dayjs.duration(timeTracking);

    return (
      <p style={{
        display: "inline-block",
        background: renderColorStatus(status),
        padding: 8,
        fontSize: 14,
        borderRadius: 4,
        width: "fit-content"
      }}>
        {timeTrackingInDuration.asDays().toFixed() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.asDays().toFixed()}
            </span>
            <span> days </span>
          </>
        )}
        {timeTrackingInDuration.hours() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.hours()}
            </span>
            <span> hours </span>
          </>
        )}
        {timeTrackingInDuration.minutes() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.minutes()}
            </span>
            <span> minutes </span>
          </>
        )}
        {timeTrackingInDuration.seconds() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.seconds()}
            </span>
            <span> seconds </span>
          </>
        )}             
      </p>
    )
  }


  const { classes } = props;
  if (task != null && !loading) {
    return (
      <>
        <Container className={classes.root}>
          <Paper className={"paper taskPaper taskMaxWidth"}>
            <HeaderBox>
              {editMode ? (
                <>
                  <CancelButton
                    startIcon={<Close />}
                    onClick={cancelEdit}
                  >
                    Cancel
                  </CancelButton>
                  <AppBarButton
                    startIcon={<Save />}
                    onClick={() => saveTask(task)}
                  >
                    Save Edits
                  </AppBarButton>
                </>
              ) : (
                <AppBarButton
                  startIcon={<Edit />}
                  onClick={() => editTask(task)}
                  disabled={!(task.projectId && task.componentId)}
                >
                  Edit Task
                </AppBarButton>
              )}
            </HeaderBox>
            <Grid container item direction={"column"} xs={12}>
              <p className={"s-text m-0"}>Task Status</p>
              <Grid container item xs={12} direction={"row"}>
                <TaskChip value={task.status} extras={task} />
              </Grid>

              <p className={"s-text m-0"}>Assigned users</p>
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                style={{ paddingBottom: "8px" }}
              >
                {editMode == false && task.assignedUser != null
                  ? task.assignedUser.map((assigned, id) => {
                      return <UserDisplayName username={assigned} />;
                    })
                  : ""}

                {editMode == true ? (
                  <>
                    <Autocomplete
                      options={allUsers}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          let item = option.displayName;
                          return <ListItemChip {...getTagProps({ index })} label={item} colour={getColourFromString(item)} />
                        })
                      }
                      fullWidth
                      size={"small"}
                      multiple
                      freeSolo={false}
                      openOnFocus={true}
                      getOptionLabel={(option) => {
                        return option.displayName;
                      }}
                      variant={"outlined"}
                      value={assignedUser}
                      onChange={(event, inputValue, reason) =>
                        handleSetUsers(event, inputValue, reason)
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder={"Select users"}
                            size={"small"}
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </Grid>

              <p className={"s-text m-0"}>Assigned emails</p>
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                style={{ paddingBottom: "8px" }}
              >
                {editMode == false && task.assignedEmail != null
                  ? task.assignedEmail.map((assigned, id) => {
                      return (
                        <UserDisplayName userType="EMAIL" username={assigned} />
                      );
                    })
                  : ""}

                {editMode == true && (
                  <>
                    <ChipInput
                      inputValue={assignedEmail}
                      fullWidth={true}
                      placeholder={"Insert emails "}
                      onPlaceholderSelected={handleChangeEmail}
                      onDelete={(index) => {
                        // Deletes email
                        handleDeleteEmail(index);
                      }}
                    />
                  </>
                )}
              </Grid>

              <p className={"s-text m-0"}>Task subject</p>
              {editMode == false && <p>{taskSubject}</p>}
              {editMode == true && (
                <>
                  <TextField
                    variant="outlined"
                    value={taskSubject}
                    onChange={(event) => {
                      setTaskSubject(event.target.value);
                    }}
                    style={{ paddingBottom: "8px" }}
                  />
                </>
              )}

              <p className={"s-text m-0"}>Task Description</p>
              {editMode == false && task.taskDescription != null && task.taskDescription.length !== 0 ? (
                <span style={editMode == true ? { display: "none" } : {}}>
                  <JoditEditor
                    className={"taskDescription"}
                    value={task.taskDescription}
                    config={CONFIG}
                  />
                </span>
              ) : (
                ""
              )}

              {editMode == true && (
                <div style={{ paddingBottom: "8px" }}>
                  <TextEditor
                    editorFocus={() => {}}
                    onChange={handleChangeDescription}
                    disableTooltip
                    contentStyles={{
                      border: "1px solid rgba(0, 0, 0, 0.16)",
                      boxShadow: "none",
                      padding: "8px",
                      minWidth: "100%",
                    }}
                    html={taskDescription}
                  />
                </div>
              )}
              <p className={"s-text m-0"}>Workflow</p>
              <Link className={"m-0"} to={`/project/canvas/${task.projectId}`}>
                <p>{task.projectName}</p>
              </Link>
              <p className={"s-text m-0"}>Component Name</p>
              <p className={"bold"}>
                {task.componentName ? task.componentName : ""}
              </p>
              <p className={"s-text m-0"}>Due Date</p>
              <p className={task.overDue && task.status !== "DONE" ? "txt-overdue" : ""}>
                {!editMode ? (
                  task.dueDate != null ? (
                    format(task.dueDate, ProjectStore.state.timezone) +
                    " (Due " +
                    format(
                      task.dueDate,
                      ProjectStore.state.timezone,
                      "relative"
                    ) +
                    ")"
                  ) : (
                    <p>-</p>
                  )
                ) : (
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DateTimePicker
                      disabled={!editMode}
                      value={task.dueDate}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={handleTaskDueDateChange}
                    />
                  </LocalizationProvider>
                )}
              </p>

              <p className={"s-text m-0"}>Date Assigned</p>
              {task.assignedOn != null ? (
                <Tooltip
                  title={format(task.assignedOn, ProjectStore.state.timezone)}
                >
                  <p>
                    {format(
                      task.assignedOn,
                      ProjectStore.state.timezone,
                      "relative"
                    )}
                  </p>
                </Tooltip>
              ) : (
                <p>-</p>
              )}

              <p className={"s-text m-0"}>Date Completed</p>
              {task.completedDate != null ? (
                <Tooltip
                  title={format(
                    task.completedDate,
                    ProjectStore.state.timezone
                  )}
                >
                  <p>
                    {format(
                      task.completedDate,
                      ProjectStore.state.timezone,
                      "relative"
                    )}
                  </p>
                </Tooltip>
              ) : (
                <p>NA</p>
              )}
              <p className={"s-text m-0"}>Time to complete</p>
                {timeTracking ? 
                  renderTimeTracking(timeTracking.timeTracking, timeTracking.status) 
                  : "-"}
              <p className={"s-text m-0"}>Task ID</p>
              <p>{task.taskId}</p>
            </Grid>
          </Paper>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container className={classes.root}>
          <Paper className={"paper taskPaper taskMaxWidth"}>
            <HeaderBox>
              <Skeleton variant="rectangular" width={100} height={36} />
            </HeaderBox>
            <Grid container item direction={"column"} xs={12} spacing={2}>
              {[...Array(10)].map((_, index) => (
                <Grid item key={index}>
                  <Skeleton variant="text" width="30%" height={20} />
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Container>
      </>
    );
  }
};

export default withStyles(styles)(TaskDetailsPage);