const styles = theme => ({
    newSessionVariable: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "16px",
        marginLeft: "32px",
        marginTop: "8px"
    },
    paperStyle:{
        width: "100%",
        marginBottom: "8px"
    },
    itemGridStyling:{
        padding: "24px"
    }
});
export default styles;