export const categories= [
    {"name":"💼 Business Operations", "value":"business_operations"},
    {"name":"📞 Customer Service and Support", "value":"customer_service_and_support"},
    {"name":"👩‍💻 Cybersecurity", "value":"cybersecurity"},
    {"name":"💰 Finance", "value":"finance"},
    {"name":"⛑ Health and Safety", "value":"health_and_safety"},
    {"name":"👨 Human Resources (HR)", "value":"human_resources"},
    {"name":"💻 Information Technology (IT)", "value":"information_technology"},
    {"name":"👩‍⚖️ Legal", "value":"legal"},
    {"name":"📣 Marketing","value":"marketing"},
    {"name":"🛠️ Dev Ops","value":"devops"},
    {"name":"🦠 COVID-19","value":"covid"},
    {"name":"🛒 Sales" , "value":"sales"},

] ;
