import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import FormTool from "vardogyir-ui/FormTool";
import { DragSource } from "react-dnd";
import { observer } from "mobx-react";
import SequentialFormContainer from "./SequentialFormContainer";
import { findDOMNode } from "react-dom";
const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});
const itemSource = {
  beginDrag(props, monitor, component) {
    // Return the data describing the dragged item
    const item = { id: props.id };
    return props.item;
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      var moved = SequentialFormContainer.getMovedQuestions();
      for (var i = 0; i < moved.length; i++) {
        if (moved[i]) {
          findDOMNode(moved[i]).style.paddingTop = "0px";
          findDOMNode(component).style.paddingBottom = "0px";
          moved.length = 0;
          SequentialFormContainer.emptyMovedQuestopns();
        }
      }
      return;
    }

    // return props.handleDrop(props.item.id);
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

class DragComponent extends Component {
  handleClick = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { classes } = this.props;

    const { isDragging, connectDragSource, item } = this.props;
    const opacity = isDragging ? 0 : 1;
    try {
      return connectDragSource(
        <div className="item" style={{ opacity }}>
          <div style={{ marginTop: 16 }}>
            <FormTool
              icon={`/form_icons/${item.type}.svg`}
              title={this.props.item.text}
            />
          </div>
        </div>
      );
    } catch (e) {
      return <div></div>;
    }
  }
}

DragComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  DragSource("item", itemSource, collect)(observer(DragComponent))
);
