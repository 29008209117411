import React from "react";

// mui
import { Button, Grid, MenuItem, Select, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

// styled components
const CenterGrid = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const CompareButton = styled(Button)(() => ({
    height: "40px",
    padding: "0px 24px",
    background: "#000",
    color: "#FFF",
    "&:hover": {
        background: "rgba(0, 0, 0, 0.85)",
    },
}));

const Dropdown = styled(Select)(({ minWidth }) => ({
    minWidth: minWidth,
    height: "40px",
    borderColor: "#000",
}));

const DropdownItem = styled(MenuItem)(() => ({}));

const CompareIcon = styled(CompareArrowsIcon)({
    color: "#000",
});

const WorkflowNameText = styled("div")({
    width: "fit-content",
    maxWidth: "250px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
})

function TopBar({ goBack, workflowName, allProjects, handleCompareJson, selectionOne, selectionTwo, setSelectionOne, setSelectionTwo, compareLoad, view, setView }) {
    return (
        <Grid container spacing={2}>
            <CenterGrid item>
                <ArrowBackIcon onClick={goBack} className="arrow_back" />
            </CenterGrid>
            <CenterGrid item>
                <WorkflowNameText>{workflowName ? workflowName : "Untitled workflow"}</WorkflowNameText>
            </CenterGrid>
            <CenterGrid item>
                <Dropdown
                    value={selectionOne}
                    size="small"
                    minWidth="300px"
                    variant="outlined"
                    onChange={(e) => {
                        setSelectionOne(e.target.value);
                    }}
                    disabled={compareLoad}
                >
                    {allProjects.map((p) => {
                        return <DropdownItem value={`${p.projectStatus}_version_${p.version}`}>{`Version ${p.version} (${p.projectStatus})`}</DropdownItem>;
                    })}
                </Dropdown>
            </CenterGrid>
            <CenterGrid item>
                <CompareIcon />
            </CenterGrid>
            <CenterGrid item>
                <Dropdown
                    value={selectionTwo}
                    size="small"
                    minWidth="300px"
                    variant="outlined"
                    onChange={(e) => {
                        setSelectionTwo(e.target.value);
                    }}
                    disabled={compareLoad}
                >
                    {allProjects.map((p) => {
                        return <DropdownItem value={`${p.projectStatus}_version_${p.version}`}>{`Version ${p.version} (${p.projectStatus})`}</DropdownItem>;
                    })}
                </Dropdown>
            </CenterGrid>
            <CenterGrid item>
                <Dropdown
                    value={view}
                    size="small"
                    variant="outlined"
                    onChange={(e) => {
                        setView(e.target.value);
                    }}
                    disabled={compareLoad}
                >
                    <DropdownItem value="split_view">Split view</DropdownItem>
                    <DropdownItem value="unified_view">Unified view</DropdownItem>
                </Dropdown>
            </CenterGrid>
            <CenterGrid item>
                <CompareButton size="small" variant="contained" onClick={() => handleCompareJson()} disabled={compareLoad} startIcon={<CompareArrowsIcon />}>
                    Compare
                    {compareLoad && <CircularProgress size={24} className="buttonProgress" />}
                </CompareButton>
            </CenterGrid>
        </Grid>
    );
}

export default TopBar;
