import React, {Component} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import SelectProject from "../Component/SelectProject";
import {send_component_save_request, send_request} from "../../utils/Request";
import AddIcon from "@mui/icons-material/Add";
import RenderQueryItem from "../Component/RenderQueryItem";
import './QueryDatabaseStyle.css'
import {reaction, toJS} from "mobx";
import {withStyles} from "@mui/styles";
import {inject, observer} from "mobx-react";
import queryDatabaseStore from "./QueryDatabaseStore";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "../pages/DatabasesComponents/blue-light.css";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import NumericEditor from "@inovua/reactdatagrid-community/NumericEditor";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import DateFormatter from "@inovua/reactdatagrid-community";
import DateEditor from "@inovua/reactdatagrid-community/DateEditor";
import TextIcon from "../Component/Icons/TextIcon";
import NumberIcon from "../Component/Icons/NumberIcon";
import BooleanIcon from "../Component/Icons/BooleanIcon";
import DatetimeIcon from "../Component/Icons/DatetimeIcon";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import projectStore from "../ProjectCanvas/ProjectStore";
import ListIcon from '../Component/Icons/ListIcon';
import DBManagementRenderChips from '../pages/DatabasesComponents/DBManagementRenderChips';
import { IndividualComponentLoader } from '../../utils/ComponentLoadingSkeleton';
import ProjectStore from "../ProjectCanvas/ProjectStore";
import QueryDatabaseStore from "./QueryDatabaseStore";

const gridStyle = { minHeight: 600 };

const styles = (theme) => ({
  paper: {
    background: "#ffffff",
    borderRadius: 8,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "& div.InovuaReactDataGrid__sort-icon-wrapper": {
      display: "none !important",
    },
    "& div.inovua-react-scroll-container__wrapper > div > div > div > div": {
      overflow: "unset !important",
    },
  },
});

class QueryDatabaseTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availablePlaceholders: [],
      availableCols: [],
      columns: [],
      rows: [],
      databaseList: [],
      targetDatabaseId: null,
      checkKey: false,
      errorMessage: "",
      loadingScreen: false,
      queryData: [],
      isLoading: false,
      validateKey: false,
      description:null,
      status:null

    };
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loadingScreen: true });

      send_request(`database/get_databases`, "", {}, "GET").then((response) => {
        if (response.data) {
          this.setState({ databaseList: response.data });
        }
      });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveButton();
    this.reactToPlaceHolder();
  }

  saveButton = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      this.setState({validateKey: true})

      let cData = {};
      //cData.name = toJS(this.props.ComponentName);
      let lastModified = getCurrentTime();
      const {data} = toJS(queryDatabaseStore)

      let bodyData = {
        componentData: {
          data: data,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified,
        },
        componentId: this.props.component_id,
        type: "query_database",
        description: this.props.ComponentDescription.value
      };

      send_component_save_request(
        "component-service/query_database_record/data",
        bodyData,
        "",
        "POST",
        this.props.SaveTrigger
      )
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(bodyData, this.props.SaveTrigger);
          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loadingScreen: true });

      send_request(`database/get_databases`, "", {}, "GET").then((response) => {
        if (response.data) {
          this.setState({ databaseList: response.data });
          send_request(
            `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
            "",
            {}
          ).then((response) => {
            if (
              response.data &&
              response.data.components &&
              response.data.components[0].componentData
            ) {
              this.props.onComponentNameChanged(
                response.data.components[0].componentData.name
              );
              this.props.onComponentDescriptionChanged(response.data.components[0].description);
              this.props.onLastModifiedChanged(
                response.data.components[0].componentData.lastModified
              );

              let queryData = response.data.components[0].componentData.data;
              let data = response.data.components[0];
              if (queryData.targetDatabaseId) {
                this.updateTargetProject(queryData.targetDatabaseId);
              } else {
                let newData = data.componentData.data;
                newData.queryData.forEach((q) => {
                  if (q.value === null && q.columnName) {
                    const column = newData.availableCols.find(
                      (col) => col.name === q.columnName
                    );
                    q.value = column.placeholderKey;
                  }
                  if (q.condition) {
                    if (q.condition.length > 0) {
                      q.condition.forEach((cond) => {
                        if (cond.key === null && cond.columnName) {
                          const column = newData.availableCols.find(
                            (col) => col.name === cond.columnName
                          );
                          cond.key = column.placeholderKey;
                        }
                      });
                    }
                  }
                });
              }

              queryDatabaseStore.setUpdateDatabaseRecordTemplate(data);

              this.setState({
                loadingScreen: false,
              });
            } else {
              queryDatabaseStore.setUpdateDatabaseRecordTemplate(
                response.data.components[0]
              );
              this.setState({ loadingScreen: false });
            }
            if(this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["data"]!==undefined && this.props.aiComponentBuilderData["data"]!==''  )
            {
              this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
              this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
              QueryDatabaseStore.setTargetDatabaseId(this.props.aiComponentBuilderData.data.targetDatabaseId);
              this.updateTargetProject(this.props.aiComponentBuilderData.data.targetDatabaseId);

              ProjectStore.targetDataBaseId.set(this.props.aiComponentBuilderData.data.targetDatabaseId);
              QueryDatabaseStore.setAvailableCols(this.props.aiComponentBuilderData.data.availableCols);
              QueryDatabaseStore.setQueryData(this.props.aiComponentBuilderData.data.queryData);
              this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
              this.setState({ status: "AI_BUILD_GENERATED" });

            }
            this.props.setChildTemplateLoaded(true);
          });
        }
      });
    }
  }

  updateTargetProject = async (targetDatabaseId) => {
    const columns = [
      {
        name: "row_id",
        header: this.getHeader("", "DECIMAL"),
        width: 50,
        editable: false,
        showColumnMenuTool: false,
        minWidth: 50,
        type: "string",
        showColumnMenuLockOptions: false,
        orderNumber: 0,
      },
      {
        id: "record_id",
        name: "record_id",
        header: this.getHeader("Record ID", "VARCHAR2"),
        editable: false,
        defaultFlex: 1,
        minWidth: 200,
        type: "string",
        filterEditor: this.filterEditor("VARCHAR2").filterEditor,
        filterEditorProps: this.filterEditor("VARCHAR2").filterEditorProps,
        render: this.filterEditor("VARCHAR2").render,
        showColumnMenuLockOptions: false,
        columnName: "Record ID",
        orderNumber: 1,
      },
      {
        id: "session_id",
        name: "session_id",
        header: this.getHeader("Session ID", "VARCHAR2"),
        editable: false,
        defaultFlex: 1,
        minWidth: 200,
        type: "string",
        filterEditor: this.filterEditor("VARCHAR2").filterEditor,
        filterEditorProps: this.filterEditor("VARCHAR2").filterEditorProps,
        render: this.filterEditor("VARCHAR2").render,
        showColumnMenuLockOptions: false,
        columnName: "Session ID",
        orderNumber: 2,
      },
    ];

    // Load only first 25
    const json = await send_request(
      `database/get_database/${targetDatabaseId}/0`,
      "",
      {},
      "POST"
    ).catch((e) => {
      this.setState({
        isLoading: false,
      });
    });

    if (json && json.data.databaseId == null) {
      this.setState({
        isLoading: false,
      });
    }

    const columnJson = await send_request(
      `database/get_columns/${targetDatabaseId}`,
      "",
      {},
      "GET"
    ).catch((e) => {});

    if (columnJson && columnJson.data) json["data"]["columns"] = columnJson.data;

    if (json && json.status == "200") {
      if (json.data.columns) {
        for (let i = 0; i < json.data.columns.length; i++) {
          let config = this.filterEditor(json.data.columns[i].type);
          columns.push({
            id: json.data.columns[i].columnId,
            name: json.data.columns[i].columnId,
            header: this.getHeader(
              json.data.columns[i].name,
              json.data.columns[i].type
            ),
            type: config.type,
            baseType:json.data.columns[i].type,
            columnType: json.data.columns[i].type,
            defaultFlex: 1,
            editor: config.editor,
            editorProps: config.editorProps,
            columnName: json.data.columns[i].name,
            filterEditor: config.filterEditor,
            filterEditorProps: config.filterEditorProps,
            render: config.render,
            dateFormat:
              json.data.columns[i].type === "DATETIME"
                ? "yyyy-MM-DD hh:MM:ss"
                : "",
            minWidth: 180,
            showColumnMenuLockOptions: false,
            orderNumber: json.data.columns[i].orderNumber,
          });
        }
      }
      let rows = [];

      if (json.data.rows) {
        if (json.data.rows.length > 0) {
          for (let i = 0; i < json.data.rows.length; i++) {
            let row = {};
            row["rowId"] = json.data.rows[i].rowId;
            row["row_id"] = i + 1;
            row["record_id"] = json.data.rows[i].rowId;
            row["session_id"] = json.data.rows[i].sessionId;
            for (let j = 0; j < json.data.rows[i].cells.length; j++) {
              row[json.data.rows[i].cells[j].columnId] =
                json.data.rows[i].cells[j].content;
            }
            rows.push(row);
          }
        }
      }
      columns.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));
      let { queryData, availableCols } = toJS(queryDatabaseStore.data);
      queryData = JSON.stringify(queryData);
      let newAvailableCols = [];
      columns.forEach((col) => {
        const sameColumn = availableCols.filter(
          (c) => c.name === col.columnName
        );
        if (
          sameColumn.length > 0 &&
          sameColumn[0].placeholderKey !== "record_id" &&
          sameColumn[0].placeholderKey !== "session_id"
        ) {
          queryData = queryData.replaceAll(
            sameColumn[0].placeholderKey,
            col.id
          );
        }
        if (col.name !== "row_id") {
          newAvailableCols.push({
            placeholderKey: col.id,
            name: col.columnName,
            type:col.baseType
          });
        }
      });
      queryData = JSON.parse(queryData);

      queryDatabaseStore.clearQueryData();
      queryDatabaseStore.setQueryData(queryData);
      queryDatabaseStore.setAvailableCols(newAvailableCols);

      this.setState({
        columns,
        rows,
        database: json.data,
        isLoading: false,
      });
    }
  };

  getHeader = (name, type) => {
    switch (type) {
      case "VARCHAR2":
        return (
          <div className="columnHeader">
            <TextIcon />
            <div>{name}</div>
          </div>
        );
      case "DECIMAL":
        return (
          <div className="columnHeader">
            <NumberIcon />
            <div>{name}</div>
          </div>
        );
      case "BOOLEAN":
        return (
          <div className="columnHeader">
            <BooleanIcon />
            <div>{name}</div>
          </div>
        );
      case "DATETIME":
        return (
          <div className="columnHeader">
            <DatetimeIcon />
            <div>{name}</div>
          </div>
        );
      case "LIST":
        return (
          <div className="columnHeader">
            <ListIcon />
            <div>{name}</div>
          </div>
        );
      default:
        return (
          <div className="columnHeader">
            <div>{name}</div>
          </div>
        );
    }
  };

  filterEditor = (type) => {
    switch (type) {
      case "VARCHAR2":
        return {
          type: "string",
          operator: "startsWith",
        };
      case "DECIMAL":
        return {
          type: "number",
          operator: "eq",
          filterEditor: NumberFilter,
          editor: NumericEditor,
        };
      case "BOOLEAN":
        return {
          type: "select",
          operator: "inlist",
          filterEditor: SelectFilter,
          editor: SelectEditor,
          editorProps: {
            dataSource: this.selectBoolean(),
            idProperty: "id",
            collapseOnSelect: true,
          },
          filterEditorProps: {
            placeholder: "All",
            dataSource: this.selectBoolean(),
          },
        };
      case "DATETIME":
        return {
          type: "date",
          operator: "before",
          filterEditor: DateFilter,
          filterEditorProps: (props, { index }) => {
            // for range and not in range operators, the index is 1 for the after field
            return {
              dateFormat: "yyyy-MM-DD hh:MM:ss",
              formatter: <DateFormatter />,
            };
          },

          editor: DateEditor,
          editorProps: {
            dateFormat: "yyyy-MM-DD hh:MM:ss",
          },
        };
      case "LIST":
        return {
          type: "string",
          operator: "startsWith",
          filterEditor: "",
          filterEditorProps: "",
          render: (renderProps) => {
            let v = renderProps.cellProps.inEdit
            ? renderProps.cellProps.value
            : renderProps.value;

            return (
              <DBManagementRenderChips
                textAreaProps={{
                  disableUnderline: true,
                  value: this.convertStringToArray(v)
                }}
              />
            );
          },
        };
      default:
        return {
          type: "string",
          operator: "startsWith",
          filterEditor: "",
          filterEditorProps: "",
          render: "",
        };
    }
  };

  selectBoolean = () => {
    return [
      { id: "true", label: "true" },
      { id: "false", label: "false" },
    ];
  };

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key, isNotPlaceholder } = placeholder["selected"];

      this.insertPlaceholder(key, isNotPlaceholder);
    }
  );

  convertStringToArray = s => {
    if (s == null || s === "") return [];
    if (s.length >= 2 && s[0] === "[" && s[s.length - 1] === "]") {
      return s.slice(1, s.length - 1).split(',');
    } else return s.split(",");
  };

  insertPlaceholder = (text, isNotPlaceholder) => {
    //text = ` $${text}](${text}) `;
    if (isNotPlaceholder !== true) {
      text = " ${" + text + "} ";
    }

    let thisFocus = this.state.thisFocus;
    let { queryData } = queryDatabaseStore.data;
    let cond =
      queryData[thisFocus.index].condition[thisFocus.aDataConditionIndex];

    cond.value = text;

    this.updateCondition(
      thisFocus.index,
      thisFocus.aDataConditionIndex,
      cond.logic,
      cond.key,
      cond.function,
      cond.value
    );
  };

  updateCondition = (index, conditionIndex, logic, key, func, value) => {
    let { queryData } = queryDatabaseStore.data;
    let aData = queryData[index];
    if (aData.condition && aData.condition[conditionIndex]) {
      let conditionData = aData.condition[conditionIndex];
      conditionData.logic = logic;
      conditionData.key = key;
      conditionData.function = func;
      conditionData.value = value;
      queryDatabaseStore.setQueryData(queryData);
    }
  };

  addEmptyQueryItem = () => {
    let { queryData } = queryDatabaseStore.data;
    let aData = {
      key: this.keyAuto(),
      outputFunc: "SELECTED_COLUMN",
      outputType: "list",
      value: null,
    };
    queryData.push(aData);
    queryDatabaseStore.setQueryData(queryData);
  };

  copyThisItem = (data, index) => {
    let { queryData } = queryDatabaseStore.data;
    let aData = {
      key: this.keyAuto(),
      ...data,
    }
    queryData.push(aData);
    queryDatabaseStore.setQueryData(queryData);
  }

  keyAuto = () => {
    return (
      "query_data" + (Math.floor(Math.random() * 90000000) + 10000).toString()
    );
  };

  render() {
    const { availablePlaceholders, classes } = this.props;
    const { columns, rows } = this.state;
    let { data } = queryDatabaseStore;
    let { targetDatabaseId, availableCols, queryData } = toJS(data);
    if (!this.state.loadingScreen) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Paper className="query-item-paper defaultText">
              <SelectProject
                databaseList={this.state.databaseList}
                updateTargetProject={this.updateTargetProject}
                targetDatabaseId={targetDatabaseId}
                setTargetDatabaseId={(targetDatabaseId) => {
                  queryDatabaseStore.setTargetDatabaseId(targetDatabaseId);
                  ProjectStore.targetDataBaseId.set(targetDatabaseId);

                }}
                component={"query_database_record"}
                isLoading={this.state.isLoading}
                setIsLoading={(isLoading) => {
                  this.setState({
                    isLoading,
                  });
                }}
              /> 
            </Paper>
          </Grid>

          <Grid item xs={12} spacing={2}>
            {queryData.map((aData, index) => (
              <RenderQueryItem
                aData={aData}
                index={index}
                queryData={queryData}
                availablePlaceholders={availablePlaceholders}
                availableCols={availableCols}
                onPlaceholderSelected={this.props.onPlaceholderSelected}
                setQueryData={(queryData) => {
                  queryDatabaseStore.setQueryData(queryData);
                }}
                setThisFocus={(index, aDataConditionIndex) => {
                  this.setState({
                    thisFocus: {
                      index: index,
                      aDataConditionIndex: aDataConditionIndex,
                    },
                  });
                }}
                copyThisItem={() => {
                  this.copyThisItem(aData, index);
                }}
                component={"query_database_record"}
                outputSelect={[
                  { key: "SELECTED_COLUMN", name: "all data in a column" },
                  {
                    key: "FILTERED_COLUMN",
                    name: "select data if a condition is met",
                  },
                ]}
                validateKey={this.state.validateKey}
                setValidateKey={(validateKey) => {
                  this.setState({
                    validateKey,
                  });
                }}
              />
            ))}
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "16px",
                marginLeft: "32px",
                marginTop: "8px",
              }}
            >
              <div
                className="query-database-add-data"
                onClick={() => {
                  this.addEmptyQueryItem();
                }}
              >
                <AddIcon /> Add Data Query
              </div>
            </div>
          </Grid>
          {columns.length > 0 && (
            <Grid
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              // style={{ marginLeft: 8 }}
            >
              <Paper className={classes.paper}>
                <ReactDataGrid
                  idProperty="rowId"
                  columns={columns}
                  dataSource={rows}
                  style={gridStyle}
                  theme={"blue-light"}
                  defaultLimit={10}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      );
    } else {
      return <IndividualComponentLoader />;
    }
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(QueryDatabaseTemplate))
);
