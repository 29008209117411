const config = {
  VERSION: "0.0.1",
  API_ADDRESS: process.env.REACT_APP_ENV === 'local'
    ? "http://localhost:4001/client"
    : "https://api.w86dev.click",
  TEMPLATES_API_ADDRESS:
    "https://hy6ls4f9a1.execute-api.ap-southeast-2.amazonaws.com/dev/templates",
};

config.FORM = {
  FORM_FRONT_END: process.env.REACT_APP_ENV === 'local'
    ? "http://localhost:3000"
    : "https://form.w86dev.click",
};


config.PREVIEW_FORM_URL = "https://form.workflow86.com/form/25d1cb58-8c01-4e97-bfb9-de18c43233c0"

config.HEADER = {
  "Content-Type": "application/json",
};

config.FILE_SERVICE = {
  QUERY_URL: "https://dashboard.w86dev.click:9300/file/get",
};

config.PUBLISHABLEKEY = "pk_test_xU4umroBmBvnsqJ13Q9JfOTb00oH3HvkNT";

// config.CAPTCHA = "6LfrsPgZAAAAAANMXn-uTu9SSlepaQJ3amx_7Zb9"
config.CAPTCHA = "6LfYuQobAAAAAEXKw_NXkPH8Qkps7KipYOTR9Zna";

config.DRIFCHAT = "9e7c87br9x3x";

config.WEBHOOK_URL = "https://p4yrku69a0.execute-api.ap-southeast-2.amazonaws.com/dev/webhook";

config.WEBHOOK_ENDPOINT = {
  SAMPLE:
    "https://biam90y5yk.execute-api.ap-southeast-2.amazonaws.com/dev/webhook/catch-sample",
  PRODUCTION:
    "https://biam90y5yk.execute-api.ap-southeast-2.amazonaws.com/dev/webhook",
};

//prod setup will go here
// config.CLIENT_ID = "BjxNQnzJaUfgAYyFiTH5MG9posTnHipD4smHCrLQAtnC"
// config.REDIRECT_URL = "https://d2z23dxxyevteb.cloudfront.net/callback/callback"
//config.APP_URL = "https://app.workflow86.com";

//local
// config.DASHBOARD_SERVICE_WEBSOCKET = "http://localhost:10000/ws";
//dev
config.DASHBOARD_SERVICE_WEBSOCKET = process.env.REACT_APP_ENV === 'local'
  ? "http://localhost:10000/ws"
  : "https://dashboard.w86dev.click:10000/ws";
// prod
// config.DASHBOARD_SERVICE_WEBSOCKET = "http://localhost:10000/ws";

// ------- Pane widths configs ------- //
config.PANE_WIDTH = "40%";
config.FORM_PANE_WIDTH = "57%";
config.FULL_SCREEN_WIDTH = "97.5%";
config.AI_WORKFLOW_BUILDER_PANE_WIDTH = "33%";
config.AI_COMPONENT_BUILDER_PANE_WIDTH = "25%";
config.TEST_PANE_WIDTH = "25%";
config.SESSION_HISTORY_PANEL_WIDTH = "275px";
config.COMPONENT_BUILDER_BASE_WIDTH = "47.5%";
config.COMPONENT_BUILDER_EXPANDED_WIDTH = "72.75%";
config.TRANSLATION_BOTH_PANELS_OPEN = "translateX(-105.25%)";
config.TRANSLATION_BOTH_PANELS_OPEN_NOT_FORM = "translateX(-125%)";
config.TRANSLATION_ONLY_TEST_PANEL_OPEN = "translateX(-34%)";
config.TRANSLATION_ONLY_TEST_PANEL_OPEN_FORM = "translateX(-43%)";
config.TRANSLATION_ONLY_TEST_PANEL_OPEN_NOT_FORM = "translateX(-62%)";
config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN = "translateX(-34%)";
config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN_FORM = "translateX(-43.5%)";
config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN_NOT_FORM = "translateX(-62.5%)";

// Progress view
config.SESSION_LOGS_LIST_WIDTH = "400px";
config.SESSION_LOGS_COMPONENT_INFO_PANE_WIDTH_IN_SPLIT_VIEW = "500px";
config.SESSION_LOGS_COMPONENT_INFO_PANE_WIDTH = "40%";

config.AI_WORKFLOW_BUILDER_PANE_MAX_WIDTH_IN_PX = 480;

config.OAUTH = {
  CALLBACK_URL: process.env.REACT_APP_ENV === 'local'
    ? "https://892a-103-100-226-144.ngrok-free.app/oauth/callback"
    : "https://oauth.w86dev.click/callback"
};

config.AWS = {
  REGION: "ap-southeast-2",
  USER_POOL_ID: "ap-southeast-2_Kp9DpRrdK",
  APP_CLIENT_ID: "5f93lfrqqh7nruhjiiin8qbeem",
  DOMAIN: ".w86dev.click"
};

config.WEBHOOK_URL ="https://webhook.workflow86.com/webhook/";

export default config;
