export const getColourFromString = (name) => {
    /**
     * This determines the color for the chip input
     * We grab the first and last characters of the chip value
     * We then add the first, last and first characters in ascii and use
     * them to generate the colour.
     *  */

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    let asciiCode="A";
    let lastAsciiCode="Z";
    if (name === null || name === undefined) {
        asciiCode = characters.charCodeAt(Math.floor(Math.random() * charactersLength));
        lastAsciiCode=characters.charCodeAt(Math.floor(Math.random() * charactersLength));
    }
    else{
        asciiCode= name.charCodeAt(0);
        lastAsciiCode = name.charCodeAt(name.length - 1);
    }
    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum = asciiCode.toString() + lastAsciiCode.toString() + asciiCode.toString();
    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = (num >> 16) & 255;
    var g = (num >> 8) & 255;
    var b = num & 255;

    // apply blending
    var alpha =  0.2;
    var background = {r:248,g:248,b:248};

    var r_low =   Math.round((1 - alpha) * background.r + alpha * r);
    var g_low =   Math.round((1 - alpha) * background.g + alpha * g);
    var b_low =  Math.round((1 - alpha) * background.b + alpha * b);

    return {
        border: "rgb(" + r + ", " + g + ", " + b + ", 1)",
        bg: "rgb(" + r_low + ", " + g_low + ", " + b_low + ")",
    };

};

