import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import AddIcon from "@mui/icons-material/Add";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { Grid } from "@mui/material"
import projectStore from "../ProjectCanvas/ProjectStore";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import ConditionalWorkflowPathStore from "./ConditionalWorkflowPathStore";
import ItemLogic from "../ConditionalWorkflow/components/ItemLogic";

class ConditionalWorkflowPathTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeaderFields: false,
            focus: null,
            placeholders: [],
            extraHeaderFields: [],
            requestClick: false,
            dataClick: false,
            finishedLoading: false,
            error: false,
            loading:false,
            description:null,
            status:null
        };
        this.insertPlaceholder = this.insertPlaceholder.bind(this);
        ConditionalWorkflowPathStore.initialiseEmpty();
    }

    handleLogOut = () => {
        this.props.onBackProjectCanvas();
    }

    componentDidMount() {

        if (this.props.component_id !== undefined) {
            this.setState({loading:true})
            send_request(`project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`, "", {})
                .then(response => {

                    if (response && response.data) {
                        const { components } = response.data;
                        if (components && components[0].componentData) {
                            ConditionalWorkflowPathStore.setConditionalWorkflowTemplate(components[0].componentData);
                            this.props.onComponentNameChanged(ConditionalWorkflowPathStore.name);
                            this.props.onComponentDescriptionChanged(components[0].description);
                            this.props.onLastModifiedChanged(ConditionalWorkflowPathStore.lastModified);
                            this.setState({loading:false})
                        }
                        this.setState({loading:false})
                        this.props.setChildTemplateLoaded(true);
                    }

                }).then(()=>{
                if(this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data!==undefined && this.props.aiComponentBuilderData.data!==''  ) {
                    this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
                    this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
                    ConditionalWorkflowPathStore.setData(this.props.aiComponentBuilderData.data);
                    this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
                    this.setState({ status: "AI_BUILD_GENERATED" });
                }
            })
                .catch(err => {
                    throw Error(err.message);
                });
        }
    }

    componentWillUnmount() {
        // Clear the mobX reactions
        this.saveConditionalWorkflowTemplate();
        this.reactToPlaceHolder();
      }

    saveConditionalWorkflowTemplate = reaction(
        () => this.props.SaveTrigger.triggerValue,
        () => {

            if(this.state.error){
                this.props.showError();
                return;
            }

            const templateName = toJS(this.props.ComponentName);
            const lastModified = getCurrentTime();
            ConditionalWorkflowPathStore.setLastModified(lastModified);
            ConditionalWorkflowPathStore.setConditionalWorkflowName(templateName);

            const template = toJS(ConditionalWorkflowPathStore.template);

            delete template.placeholders;
            delete template.positions;
            const data = {
                type: 'conditional_workflow_path',
                componentData: {
                    name: template.name,
                    data: template.data,
                    lastModified: lastModified
                },
                componentId: this.props.component_id,
                description: this.props.ComponentDescription.value,
                status: this.state.status,
            }
            send_component_save_request(
                `component-service/conditional-workflow-path/data`,
                data,
                "",
                "POST",
                this.props.SaveTrigger
            )
                .then(response => {
                    if (response.status = 200) {
                        this.props.showSuccess(data, this.props.SaveTrigger);
                        this.props.onLastModifiedChanged(lastModified);
                        projectStore.savedComponent = true;
                        /**
                         * Need to change this to ensure that on expand back it doesn't go into an endless loop
                         * this doesn't cause the refrehs to happen until the component pane is closed, so any new components added on expansion,
                         * won't be added until component pane is not closed
                         */
                        if(!this.props.isExpanded)
                        {
                            projectStore.refreshCanvas =this.props.component_id;
                        }
                        else{
                            projectStore.refreshCanvas =this.props.component_id;
                        }
                    }
                })
                .catch(err => {
                    throw err;
                });
        }
    );

    reactToPlaceHolder = reaction(
        () => toJS(this.props.SelectedPlaceholder),
        (placeholder, reaction) => {
            if (!placeholder["selected"]) return;
            const { style, key } = placeholder["selected"];

            this.insertPlaceholder(style.default, key, style);
        }
    );

    insertPlaceholder = (label, text, style) => {
        let { placeholders } = ConditionalWorkflowPathStore;
        placeholders.push({ label, text, style });

        ConditionalWorkflowPathStore.setPlaceholders(placeholders);
    }

    handleKeyChanges = error => {
        this.setState({ error })
    }
    addAnotherItem =()=>{
        const { data } = toJS(ConditionalWorkflowPathStore);

        data.push(ConditionalWorkflowPathStore.logicData());
        ConditionalWorkflowPathStore.setData(data);

    }

    render() {
        const { data } = toJS(ConditionalWorkflowPathStore);
        if (this.state && !this.state.loading) {
            return (
                <Grid container direction={"column"} xs={12}>
                    {data.map((data, index) => (
                        <ItemLogic
                            isExpanded={this.props.isExpanded}
                            data={data}
                            indexLogic={index}
                            availablePlaceholders={this.props.availablePlaceholders}
                            handleKeyChanges={this.handleKeyChanges}
                            conditionalWorkflowPath={true}
                        />
                    ))}
                    <Grid
                        xs={5}
                        container
                        direction={"row"}
                        item
                        className="text-button"
                        alignItems={"center"}
                        onClick={this.addAnotherItem}
                    >
                        <AddIcon /> Add Another Path
                    </Grid>


                </Grid>

            );
        } else return <IndividualComponentLoader />
    };
};

export default inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
)(observer(ConditionalWorkflowPathTemplate));
