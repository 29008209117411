import React, { useState } from "react";
import { Grid, IconButton, Menu } from "@mui/material";
import RibbonItem from "vardogyir-ui/RibbonItem";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ConditionalLogicStore from "../ConditionalLogicStore";
import { Logic, SelectFuc } from "./components";
import MentionEditor from "../../TextEditor/MentionEditor";
import { toJS } from "mobx";
import { MoreHoriz } from "@mui/icons-material";

function ItemCondition(props) {
  const {
    indexCondition,
    indexStatement,
    indexLogic,
    statement,
    condition,
    availablePlaceholders,
    forceUpdateState,
  } = props;

  const [menu, setMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  let { positions, placeholders } = ConditionalLogicStore;

  let keyArray = [];
  let valueArray = [];
  placeholders = toJS(placeholders);

  if (
    positions.indexLogic == indexLogic &&
    positions.indexStatement == indexStatement &&
    positions.indexCondition == indexCondition &&
    positions.type == "KEY"
  ) {
    keyArray = placeholders;
  }

  if (
    positions.indexLogic == indexLogic &&
    positions.indexStatement == indexStatement &&
    positions.indexCondition == indexCondition &&
    positions.type == "VALUE"
  ) {
    valueArray = placeholders;
  }

  return (
    <>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        xs={12}
        className={`${props.classes.conditionSpacing}  ${
          indexCondition > 0 ? props.classes.conditionPartFrame : ""
        }`}
        spacing={2}
      >
        <Grid item xs={props.isExpanded ? 1 : 3}>
          {indexCondition > 0 ? (
            <Logic
              value={condition.logic}
              onChange={(event) => {
                ConditionalLogicStore.setCondition(
                  event,
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  "logic"
                );
                forceUpdateState();
              }}
            />
          ) : (
            <p className={!props.isExpanded && "m-0" }>{indexStatement > 0 ? "Else if" : "If"}</p>
          )}
        </Grid>
        <Grid container item xs={props.isExpanded ? 4 : 12}>
          <MentionEditor
            style={{ width: "100%" }}
            availablePlaceholders={availablePlaceholders}
            placeholders={keyArray}
            text={condition.key}
            single={true}
            placeholder={"Insert a placeholder"}
            onChange={(value) => {
              ConditionalLogicStore.setCondition(
                value,
                indexLogic,
                indexStatement,
                indexCondition,
                "key"
              );
            }}
            onFocus={() => {
              ConditionalLogicStore.setPositions({
                indexLogic,
                indexStatement,
                indexCondition,
                type: "KEY",
              });
            }}
          />
        </Grid>
        <Grid container item xs={props.isExpanded ? 2 : 4}>
          <SelectFuc
            value={condition.function}
            onChange={(event) => {
              ConditionalLogicStore.setCondition(
                event,
                indexLogic,
                indexStatement,
                indexCondition,
                "function"
              );
              forceUpdateState();
            }}
          />
        </Grid>
        <Grid container item xs={props.isExpanded ? 4 : 12}>
          <MentionEditor
            style={{ width: "100%" }}
            availablePlaceholders={availablePlaceholders}
            placeholders={valueArray}
            text={condition.value}
            single={true}
            placeholder={"Insert a placeholder, text or integer"}
            onChange={(value) => {
              ConditionalLogicStore.setCondition(
                value,
                indexLogic,
                indexStatement,
                indexCondition,
                "value"
              );
            }}
            onFocus={() => {
              let type = "VALUE";
              ConditionalLogicStore.setPositions({
                indexLogic,
                indexStatement,
                indexCondition,
                type,
              });
            }}
          />
        </Grid>
        {indexCondition > 0 ? (
          <Grid
            container
            xs={props.isExpanded ? 1 : 12}
            item
            direction={"row"}
            justifyContent={"flex-end"}
          >
            <IconButton
              onClick={(event) => {
                setMenu(true);
                setAnchorEl(event.currentTarget);
              }}
              className={"icon-btn"}
              size="large"
            >
              <MoreHoriz className={"material-icons"} />
            </IconButton>
            {menu && (
              <Menu
                onClose={(event) => {
                  setMenu(false);
                }}
                open={menu}
                anchorEl={anchorEl}
              >
                <RibbonItem
                  onClick={() => {
                    ConditionalLogicStore.copyThisCondition(
                      indexLogic,
                      indexStatement,
                      indexCondition
                    );
                    forceUpdateState();
                    setMenu(false);
                    setAnchorEl(null);
                  }}
                >
                  Duplicate block
                </RibbonItem>
                <RibbonItem
                  onClick={() => {
                    ConditionalLogicStore.handleRemoveCondition(
                      indexLogic,
                      indexStatement,
                      condition.conditionRandomKey
                    );
                    forceUpdateState();
                    setMenu(false);
                    setAnchorEl(null);
                  }}
                >
                  Delete block
                </RibbonItem>
              </Menu>
            )}
          </Grid>
        ) : (
          <Grid
            container
            xs={props.isExpanded ? 1 : 12}
            item
            direction={"row"}
            justifyContent={"flex-end"}
          >
            <IconButton
              onClick={(event) => {
                setMenu(true);
                setAnchorEl(event.currentTarget);
              }}
              className={"icon-btn"}
              size="large"
            >
              <MoreHoriz className={"material-icons"} />
            </IconButton>
            {menu && (
              <Menu
                onClose={(event) => {
                  setMenu(false);
                }}
                open={menu}
                anchorEl={anchorEl}
              >
                <RibbonItem
                  onClick={() => {
                    ConditionalLogicStore.copyThisStatement(
                      indexLogic,
                      indexStatement
                    );
                    props.forceUpdateStatement();
                    setMenu(false);
                    setAnchorEl(null);
                  }}
                >
                  Duplicate block
                </RibbonItem>
                <RibbonItem
                  onClick={() => {
                    ConditionalLogicStore.removeStatement(
                      indexLogic,
                      indexStatement
                    );
                    props.forceUpdateStatement();
                    setMenu(false);
                    setAnchorEl(null);
                  }}
                >
                  Delete block
                </RibbonItem>
              </Menu>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ItemCondition;
