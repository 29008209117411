import React, { Component } from "react";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { withStyles } from "@mui/styles";
import { autorun, toJS, reaction, trace } from "mobx";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import AddIcon from "@mui/icons-material/Add";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { Grid } from "@mui/material";
import TransformStore from "./TransformStore";
import ItemTransform from "./Components/ItemTransform";
import projectStore from "../ProjectCanvas/ProjectStore";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
class TransformTemplate extends Component {
  state = {
    error: false,
    loading: false,
    description:null,
    status:null
  };
  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then((response) => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              TransformStore.setComponentData(components[0]);
              this.props.onComponentNameChanged(TransformStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(TransformStore.lastModified);
              this.setState({ loading: false });
            }
          }

          // Check if aiComponentBuilder is given and if so, set the draft data
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
            this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            TransformStore.setTemplateData(this.props.aiComponentBuilderData.data);
              this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
              this.setState({ status: "AI_BUILD_GENERATED" });
          }

          this.setState({ loading: false });
          this.props.setChildTemplateLoaded(true);
        })
        .catch((err) => {
          throw Error(err.message);
        });
    }
  }

  setTransformName = autorun(() => {
    TransformStore.setComponentName(this.props.ComponentName);
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveTransformTemplate();
    this.reactToPlaceHolder();
  }

  saveTransformTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.error) {
        this.props.showError();
        return;
      }

      const lastModified = getCurrentTime();
      TransformStore.setLastModified(lastModified);
      const template = toJS(TransformStore.template);

      // Go through the template data
      // If we have a split list function
      // Set the key to a new randomised placeholder value
      if (template && template.data) {
        template.data = template.data.map((data) => {
          if (data && data.func === "split_list_into_placeholders") {
            let newData = { ...data };
            newData["key"] = "list" + (Math.floor(Math.random() * 90000000) + 10000).toString();
            return newData;
          } else return data;
        })
      }

      const data = {
        type: template.type,
        componentData: {
          name: this.props.ComponentName,
          data: template.data,
          lastModified: lastModified,
        },
        componentId: template.componentId
          ? template.componentId
          : this.props.component_id,
          status: this.state.status,
          description: this.props.ComponentDescription.value
      };
      send_component_save_request(`component-service/list-transform/data`, data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          if (response && response.status == 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            projectStore.savedComponent = true;
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (!placeholder["selected"]) return;
      const { style, key, isNotPlaceholder } = placeholder["selected"];

      this.insertPlaceholder(style.default, key, style, isNotPlaceholder);
    }
  );
  insertPlaceholder = (label, text, style, isNotPlaceholder) => {
    const template = toJS(TransformStore.template);
    const index = template.position;
    const field = template.field;
    const posiCondition = template.posiCondition;
    const posiConditionIsMet = template.posiConditionIsMet;

    if (isNotPlaceholder !== true) {
      text = "${" + text + "}";
    }

    if (text) {
      if (posiCondition != null && posiCondition >= 0) {
        TransformStore.setCondition(
          text,
          index,
          posiCondition,
          "value",
          "conditions"
        );
      } else if (posiConditionIsMet != null && posiConditionIsMet >= 0) {
        TransformStore.setCondition(
          text,
          index,
          posiConditionIsMet,
          "value",
          "conditionIsMet"
        );
      } else {
        TransformStore.setComponent(text, index, field);
      }
    }
  };

  handleKeyChanges = (error) => {
    this.setState({ error });
  };

  render() {
    const { data, name } = toJS(TransformStore);
    if (this.state && !this.state.loading) {
      return (
        <Grid container xs={12} direction={"column"}>
          {data.map((component, index) => (
            <ItemTransform
              key={index}
              component={component}
              index={index}
              availablePlaceholders={this.props.availablePlaceholders}
              handleKeyChanges={this.handleKeyChanges}
              onPlaceholderSelected={this.props.onPlaceholderSelected}
              isExpanded={this.props.isExpanded}
            />
          ))}
          <div className="item-wrapper-text">
            <div
              className="hover-add-button"
              onClick={() => TransformStore.addComponent()}
            >
              <AddIcon /> Add List Transform
            </div>
          </div>
        </Grid>
      );
    } else return <IndividualComponentLoader />;
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(TransformTemplate));
