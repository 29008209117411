import React, { useContext, useState } from "react";
// Util
import { getBezierPath, getMarkerEnd } from "reactflow";
import { createSaveEvent } from "../../../../../utils/createSaveEvent";
import { send_request_graphql_mutation } from "../../../../../utils/Request";

// Canvas
import { SAVE_EVENT } from "../../CanvasQueries";
import { CanvasContext } from "../../WorkflowCanvas";
import ProjectStore from "../../../../ProjectCanvas/ProjectStore";
import { getElements } from "../../../../../utils/CanvasUtil";

// MUI
import { withStyles } from "@mui/styles";

/// MobX
import { toJS } from "mobx";
import { inject, observer } from 'mobx-react';
import LinkLabel from "./EdgeUtils/LinkLabel";
import { EdgeLabelRenderer } from "reactflow";

const styles = {
  icon: {
    color: "#E10000",
    cursor: "pointer",
    height: "20px",
    width: "20px",
    " &:hover": {
      color: "#E10000",
    },
  },
  editIcon: {
    color: "#000",
    cursor: "pointer",
    height: "20px",
    width: "20px",
    " &:hover": {
      color: "rgba(0, 0, 0, 0.65) !important",
    },
  }
};

const CAN_SHOW_TTC_ON_LINK = ["PROGRESSVIEW"];

const Link = inject('CanvasStore')(observer(({
  id,
  source,
  sourceX,
  sourceY,
  target,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  borderRadius,
  centerX,
  centerY,
  data,
  label,
  arrowHeadType,
  markerEndId,
  selected,
  classes,
  style,
  hasDb,
  CanvasStore,
  markerEnd
}) => {
  const { nodes, edges } = CanvasStore;

  const { modeInfo } = useContext(CanvasContext);
  const { savingInfo } = useContext(CanvasContext);
  const { canvasInstance } = useContext(CanvasContext);
  const { updated } = useContext(CanvasContext);

  const [lastModified, setLastModified] = updated? updated :  useState({});

  const [reactFlowInstance, setReactFlowInstance] = canvasInstance ? canvasInstance : useState({});

  // we don't multiply with zoom because the coordinates don't change with zoom.
  const [saving, setSaving] = savingInfo ? savingInfo : useState({});

  if (!nodes) {
    const edgePath = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius,
      centerX,
      centerY
    });
    markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    return (
      <>
        <path
          id={id}
          style={style}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd={markerEnd}
        />
        ;
      </>
    );
  } else {
    const [mode, setMode] = modeInfo;

    let edgePath = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      centerX,
      centerY
    });

    const edgeCenterX = (sourceX + targetX) / 2;
    const edgeCenterY = (sourceY + targetY) / 2;
    const foreignObjectSize = 30;

    const handleDelete = async () => {
      if (saving) return;

      setSaving(true);

      if (mode === "DEFAULT") {
        // Remove the link
        let result = edges.filter((obj) => {
          return obj.id != id;
        });
        CanvasStore.setEdges(result);
      }

      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };


      //this is a component link
      let eventData = {
        sourceComponentId: source,
        targetComponentId: target,
        noteId:id
      };

      let saveEvent = createSaveEvent(
        "DELETE_LINK",
        ProjectStore.project_id,
        viewport,
        getElements(reactFlowInstance),
        eventData
      );
      

      //send event
      await send_request_graphql_mutation(
        `project-service/graphql/project/save/${ProjectStore.project_id}`,
        SAVE_EVENT(saveEvent),
        "",
        "POST"
      )
        .then((response) => {
          if (response.data.saveEvent && response.data.saveEvent.updated) {
            setLastModified(response.data.saveEvent.lastModified);
          }
          //insert delay here
          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getCorrectEventDataFormat = (updatedItem) => {
      try {
        return {
          arrowHeadType: "arrowclosed",
          id: updatedItem.id,
          source: updatedItem.source,
          target: updatedItem.target,
          sourceHandle: updatedItem.sourceHandle,
          targetHandle: updatedItem.targetHandle,
          type: "link",
          style: updatedItem.style,
          data: {
            label: updatedItem.data ? updatedItem.data.label : ""
          }
        };
      } catch (e) { return; }
    }

    const handleSaveLinkLabel = async (newLabel) => {
      if (saving) return;

      setSaving(true);

      let rfiObj = reactFlowInstance.toObject();

      let viewport = {
        x: rfiObj.viewport.x.toFixed(5),
        y: rfiObj.viewport.y.toFixed(5),
        zoom: rfiObj.viewport.zoom.toFixed(5),
      };

      // Update the label in the canvas store
      let updatedItem;
      const newEdges = edges.map((n) => {
        if (n.id !== id) return n;
        updatedItem = {
          ...n,
          data: {
            label: newLabel,
          }
        };
        return updatedItem;
      });

      CanvasStore.setEdges(newEdges);

      // Construct event data in correct format
      let eventData = getCorrectEventDataFormat(updatedItem);
      if (!eventData) {
        setSaving(false);
        return;
      }
      
      // Save label in backend
      let saveEvent = createSaveEvent(
        "UPDATE_NOTE",
        ProjectStore.project_id,
        viewport,
        getElements(reactFlowInstance),
        eventData
      );

      const url = `project-service/graphql/project/save/${ProjectStore.project_id}`;

      //send event
      await send_request_graphql_mutation(url, SAVE_EVENT(saveEvent), "", "POST")
      .then((response) => {
        if (response.data.saveEvent && response.data.saveEvent.updated) {
          setLastModified(response.data.saveEvent.lastModified);
        }
        setSaving(false);
      })
      .catch((error) => {
        console.log(error);
      });
    };

    const isSelected = selected == true && !(data && toJS(data).hasDb) &&!(data && toJS(data).showDeleteButton);

    const markedAsRemove = data && data.editStatus === "TO_DELETE" ? true : false;

    return (
      <>
        <marker id="myMarker" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6" orient="auto">
          <path d="M 0 0 L 7 5 L 0 10 z" fill="#1C1B1F" />
        </marker>
        <path
          id={id}
          style={{
            ...style,
            stroke: markedAsRemove ? "rgba(0, 0, 0, 0.2)" : style.stroke,
          }}
          className="react-flow__edge-path"
          d={edgePath ? edgePath[0] : edgePath}
          markerEnd={!(data && toJS(data).hasDb) ? toJS(markerEnd) : "url(#myMarker)"}
          strokeDasharray={markedAsRemove && "12, 8"}
        />
        
        <LinkLabel 
          classes={classes}
          label={data ? data.label : ""}
          handleDelete={handleDelete}
          edgePath={edgePath}
          isComponentLink={label && label === "componentLink"}
          isSelected={isSelected}
          saving={saving}
          handleSaveLinkLabel={handleSaveLinkLabel}
          markedAsRemove={markedAsRemove}
        />

        {CAN_SHOW_TTC_ON_LINK.indexOf(mode) !== -1 && label &&!(data && toJS(data).hasDb)&&
          <foreignObject
            width={label.length * 10}
            height={60}
            x={edgeCenterX - (label.length * 9) / 2}
            y={edgeCenterY - foreignObjectSize / 2}
          >
              <textPath
                href={`#${id}`}
                style={{ fontSize: "12px", backgroundColor: "rgba(220, 237, 228, 1)", border: "1px solid rgba(85, 167, 122, 1)", borderRadius: "6px", width: "fit-content", padding: "0px 4px" }}
                startOffset="50%"
                textAnchor="middle"
                id={`time-elapsed-${source}-${target}`}
              >
                {label}
              </textPath>
          </foreignObject>
          }
      </>
    );
  }
}));

export default withStyles(styles)(Link);
