import React from "react";
import PropTypes from "prop-types";
import { CustomDialog } from "../../Component/Dialog";
import { Select, MenuItem, Button } from "@mui/material";

export const FormExportDialog = (props) => {

  const { openExport, setOpenExport, exportType, setExportType } = props;
  
  return (
    <CustomDialog
      isOpen={openExport}
      onClose={() => {
        setOpenExport(false);
      }}
      title={"Export form data"}
      contents={
        <>
          <p>Select a format for the form data export.</p>
          <Select
            variant={"outlined"}
            defaultValue={0}
            value={exportType}
            onChange={(event) => {
              setExportType({ exportType: event.target.value });
            }}
          >
            <MenuItem value={0}>Select format</MenuItem>
            <MenuItem value={1}>Export Submission Data as .csv</MenuItem>
            <MenuItem value={2}>Export Submission Data as .xlsx</MenuItem>
          </Select>
        </>
      }
      buttons={
        <>
          <Button
            variant={"outlined"}
            color={"info"}
            onClick={() => {
              setOpenExport(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            disabled={exportType == 0 ? true : false}
            onClick={() => {
              if (exportType == 1) props.exportFormDataAsCsv();
              else if (exportType == 2) props.exportFormDataAsXLSX();
              setExportType(false);
            }}
          >
            Export
          </Button>
        </>
      }
    />
  );
};

FormExportDialog.propTypes = {
  openExport: PropTypes.bool.isRequired,
  setOpenExport: PropTypes.func.isRequired,
  exportType: PropTypes.number.isRequired,
  setExportType: PropTypes.func.isRequired,
  exportFormDataAsCsv: PropTypes.func,
  exportFormDataAsXLSX: PropTypes.func,
};
