
const AIAssistantStyles  = (theme) =>({
    gridBackground: {
        background: "#F8F8F8",
    },
    accordion: {
        marginBottom: "16px",
        maxHeight: "0px !important",
    },
    accordionSummary: {
        paddingLeft: "16px",
    },
    b: {
        fontWeight: "bold",
        paddingLeft:"16px"
    },
    paper: {
        // Add any paper styles here
    },
    gridContainerPaddings:{
        paddingLeft: "16px",
        paddingBottom: "28px",
        paddingRight:"8px"
    },
    gridItemPadding:{
        paddingBottom:"16px",
        paddingTop:"16px"
    },
    select: {
        background: "white",
        color: "black",
        width: "50%",
    },
    // Add more styles as needed
});
export default AIAssistantStyles;
