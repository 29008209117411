import React from "react";

// MUI
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

// Icons
import TextIcon from "../../../../Component/Icons/TextIcon";
import NumberIcon from "../../../../Component/Icons/NumberIcon";
import DatetimeIcon from "../../../../Component/Icons/DatetimeIcon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

// Custom Components
import DropdownOption from "./DropdownOption";
import ColumnNameInput from "./ColumnNameInput";
import DateTimeRegion from "./DateTimeRegion";

function RenderColumn(props) {
  const { classes } = props;

  const renderDropdownOptions = col => {
    return col.options.map(opt => {
      return (
        <DropdownOption
          key={opt.id}
          id={opt.id}
          classes={classes}
          col={col}
          opt={opt}
          handleChangeOptionValue={props.handleChangeOptionValue}
          handleAddOrRemoveOption={props.handleAddOrRemoveOption}
        />
      );
    });
  };

  return (
    <Grid
      item
      key={props.col.id}
      id={props.col.id}
      className={classes.columnGridContainer}
    >
      <Grid item xs={12}>
        <AddCircleOutlineIcon
          className={classes.addIcon}
          onClick={() => props.handleAddOrRemoveColumn(props.col.id, true)}
        />
        <CancelOutlinedIcon
          className={classes.removeIcon}
          onClick={() => props.handleAddOrRemoveColumn(props.col.id, false)}
        />
      </Grid>

      <Grid item xs={12} className={classes.requiredGridItem}>
        <Checkbox
          className={classes.requiredCheckbox}
          checked={props.col.required}
          onClick={() => props.handleChangeRequired(props.col.id)}
        />
        <span className={classes.requiredText}>Required</span>
      </Grid>

      <Grid item xs={12}>
        <Select
          onChange={e =>
            props.handleChangeColumnValue(
              props.col.id,
              e.target.value,
              "type"
            )
          }
          className={classes.selectList}
          variant={"filled"}
          disableUnderline
          value={props.col.type}
          size="small"
        >
          <MenuItem value={"datetime"}>
            <div className={classes.inputWithIcon}>
              <DatetimeIcon className={classes.textIcon} />
              Datetime
            </div>
          </MenuItem>
          <MenuItem value={"dropdown"}>
            <div className={classes.inputWithIcon}>
              <img src="/form_icons/10.svg" className={classes.dropdownIcon} />
              Dropdown
            </div>
          </MenuItem>
          <MenuItem value={"number"}>
            <div className={classes.inputWithIcon}>
              <NumberIcon className={classes.textIcon} />
              Number
            </div>
          </MenuItem>
          <MenuItem value={"text"}>
            <div className={classes.inputWithIcon}>
              <TextIcon className={classes.textIcon} />
              Text
            </div>
          </MenuItem>
        </Select>
      </Grid>
      {/* <Grid item xs={12}>
        
      </Grid> */}
      <ColumnNameInput {...props} />

      {props.col.type === "dropdown" && (
        <>
          {renderDropdownOptions(props.col)}
          <Grid item xs={12}>
            <span
              className={classes.addOptionText}
              onClick={() =>
                props.handleAddOrRemoveOption(props.col.id, null, true)
              }
            >
              + add option
            </span>
          </Grid>
        </>
      )}

      {props.col.type === "datetime" && (
        <DateTimeRegion
          col={props.col}
          classes={classes}
          handleChangeDtFormat={props.handleChangeDtFormat}
        />
      )}
    </Grid>
  );
}

export default RenderColumn;
