import { useState, useEffect, useRef } from "react";

// This hook handles the timing and display of chat messages. 
// It staggers the display of new messages for a more dynamic and user-friendly presentation.
export default function useDisplayedMessages(messages, isLoadedFromHistory) {
  // State for keeping track of messages that should be displayed.
  const [displayedMessages, setDisplayedMessages] = useState([]);

  // State for keeping track of message keys to prevent duplication.
  const [displayedMessageKeys, setDisplayedMessageKeys] = useState(new Set());

  // useRef to hold a list of timeout IDs. This ensures that any set timeouts can be cleared on cleanup.
  const timeoutIds = useRef([]);

  // useEffect to handle the timing and display of chat messages.
  useEffect(
    () => {
      // Create a copy of messages with the added `messageKey`.
      const newMessages = messages.map((message, index) => ({
        ...message,
        messageKey: index,
      }));

      for (let index = 0; index < newMessages.length; index++) {
        // If the message isn't already in the list of displayed messages, schedule its display.
        if (displayedMessageKeys && !displayedMessageKeys.has(index)) {
          // Set a timeout to update the displayedMessageKeys after a delay.
          // If the message is loaded from history, it's displayed immediately. Otherwise, it's displayed after a 500ms delay.
          const timeoutId = setTimeout(() => {
            setDisplayedMessageKeys(prevKeys => new Set([...prevKeys, index]));
          }, !isLoadedFromHistory ? 500 : 0);

          // Store the timeout ID so it can be cleared later if needed.
          timeoutIds.current.push(timeoutId);
        }
      }

      // Filter and set the messages to be displayed based on their unique keys.
      setDisplayedMessages(
        newMessages.filter(message => {
          return displayedMessageKeys.has(message.messageKey);
        })
      );

      // Cleanup function: When the component is unmounted or messages change, clear all timeouts.
      return () => {
        timeoutIds.current.forEach(timeoutId => {
          clearTimeout(timeoutId);
        });
        timeoutIds.current = [];
      };
    },
    [messages, isLoadedFromHistory, displayedMessageKeys]
  ); // Dependency list for the useEffect.

  // Return the state and its setter so they can be used in the component that calls this hook.
  return [displayedMessages, setDisplayedMessages];
}
