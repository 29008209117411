import React, { useState } from "react";
import { Grid, IconButton, Menu } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ConditionalWorkflowStore from "../ConditionalWorkflowStore";
import { Logic, SelectFuc } from "./components";
import MentionEditor from "../../TextEditor/MentionEditor";
import { toJS } from "mobx";
import { MoreHoriz } from "@mui/icons-material";
import RibbonItem from "vardogyir-ui/RibbonItem";
import ConditionalWorkflowPathStore from "../../ConditionalWorkflowPath/ConditionalWorkflowPathStore";
import FormMentionEditor from "../../Component/FormMentionEditor";

function ItemCondition(props) {
  const {
    indexCondition,
    indexStatement,
    indexLogic,
    condition,
    availablePlaceholders,
    forceUpdateState,
    conditionalWorkflowPath,
  } = props;
  let { positions, placeholders } = conditionalWorkflowPath
    ? ConditionalWorkflowPathStore
    : ConditionalWorkflowStore;

  const [menu, setMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  let keyArray = [];
  let valueArray = [];
  placeholders = toJS(placeholders);

  if (
    positions.indexLogic == indexLogic &&
    positions.indexStatement == indexStatement &&
    positions.indexCondition == indexCondition &&
    positions.type == "KEY"
  ) {
    keyArray = placeholders;
  }

  if (
    positions.indexLogic == indexLogic &&
    positions.indexStatement == indexStatement &&
    positions.indexCondition == indexCondition &&
    positions.type == "VALUE"
  ) {
    valueArray = placeholders;
  }

  return (
    <Grid
      container
      xs={12}
      direction={"row"}
      justifyContent={"flex-start"}
      className={`props.classes.conditionSpacing ${
        indexCondition > 0 ? props.classes.conditionPartFrame : ""
      }`}
      alignItems={"center"}
      spacing={2}
    >
      <Grid xs={props.isExpanded ? 1 : 12} item>
        {indexCondition > 0 ? (
          <div className="select-logic">
            <Logic
              value={condition.logic}
              onChange={(event) => {
                conditionalWorkflowPath
                  ? ConditionalWorkflowPathStore.setCondition(
                      event,
                      indexLogic,
                      indexStatement,
                      indexCondition,
                      "logic"
                    )
                  : ConditionalWorkflowStore.setCondition(
                      event,
                      indexLogic,
                      indexStatement,
                      indexCondition,
                      "logic"
                    );
                forceUpdateState();
              }}
            />
          </div>
        ) : indexStatement > 0 ? (
          <p className={!props.isExpanded && "m-0"}>Else if</p>
        ) : (
          <p className={!props.isExpanded && "m-0"}>If</p>
        )}
      </Grid>

      <Grid item xs={props.isExpanded ? 4 : 12}>
        {/* We want the form mention editor because regular mention editor is not rendering conditional workflow paths */}
        <FormMentionEditor
          style={{
            width: "100%",
            verticalAlign: "top",
            minHeight: "16px",
          }}
          contentBlock={false}
          hideScrollBar={true}
          assignTask={true}
          availablePlaceholders={
            availablePlaceholders[0]
              ? availablePlaceholders[0].placeholders
              : availablePlaceholders
          }
          placeholders={keyArray}
          text={condition.key ? condition.key : ""}
          single={true}
          placeholder={"Insert a placeholder"}
          onChange={(value) => {
            conditionalWorkflowPath
              ? ConditionalWorkflowPathStore.setCondition(
                  value,
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  "key"
                )
              : ConditionalWorkflowStore.setCondition(
                  value,
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  "key"
                );
          }}
          onFocus={() => {
            conditionalWorkflowPath
              ? ConditionalWorkflowPathStore.setPositions({
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  type: "KEY",
                })
              : ConditionalWorkflowStore.setPositions({
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  type: "KEY",
                });
          }}
        />
      </Grid>
      <Grid xs={props.isExpanded ? 2 : 6} item>
        <SelectFuc
          value={condition.function}
          onChange={(event) => {
            conditionalWorkflowPath
              ? ConditionalWorkflowPathStore.setCondition(
                  event,
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  "function"
                )
              : ConditionalWorkflowStore.setCondition(
                  event,
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  "function"
                );
            forceUpdateState();
          }}
        />
      </Grid>
      <Grid xs={props.isExpanded ? 4 : 12} item>
                {/* We want the form mention editor because regular mention editor is not rendering conditional workflow paths */}
        <FormMentionEditor
          style={{
            width: "100%",
            verticalAlign: "top",
            minHeight: "16px",
          }}
          contentBlock={false}
          hideScrollBar={true}
          assignTask={true}
          availablePlaceholders={
            availablePlaceholders[0]
              ? availablePlaceholders[0].placeholders
              : availablePlaceholders
          }
          placeholders={valueArray}
          text={condition.value ? condition.value : ""}
          placeholder={"Insert a placeholder, text or integer"}
          onChange={(value) => {
            conditionalWorkflowPath
              ? ConditionalWorkflowPathStore.setCondition(
                  value,
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  "value"
                )
              : ConditionalWorkflowStore.setCondition(
                  value,
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  "value"
                );
          }}
          onFocus={() => {
            let type = "VALUE";
            conditionalWorkflowPath
              ? ConditionalWorkflowPathStore.setPositions({
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  type,
                })
              : ConditionalWorkflowStore.setPositions({
                  indexLogic,
                  indexStatement,
                  indexCondition,
                  type,
                });
          }}
        />
      </Grid>
      <Grid
        xs={props.isExpanded ? 1 : 12}
        item
        container
        justifyContent={props.isExpanded ? "" : "flex-end"}
      >
        {indexStatement > 0 || indexCondition > 0 ? (
          <IconButton
            onClick={(event) => {
              setMenu(true);
              setAnchorEl(event.currentTarget);
            }}
            className={"icon-btn"}
            size="large"
          >
            <MoreHoriz className={"material-icons "} />
          </IconButton>
        ) : (
          ""
        )}
        {indexCondition > 0
          ? menu && (
              <Menu
                onClose={(event) => {
                  setMenu(false);
                }}
                open={menu}
                anchorEl={anchorEl}
              >
                <RibbonItem
                  onClick={() => {
                    conditionalWorkflowPath
                      ? ConditionalWorkflowPathStore.removeCondition(
                          indexLogic,
                          indexStatement,
                          indexCondition
                        )
                      : ConditionalWorkflowStore.removeCondition(
                          indexLogic,
                          indexStatement,
                          indexCondition
                        );
                    forceUpdateState();
                    setMenu(false);
                    setAnchorEl(null);
                  }}
                >
                  Delete block
                </RibbonItem>
              </Menu>
            )
          : indexStatement > 0
          ? menu && (
              <Menu
                onClose={(event) => {
                  setMenu(false);
                }}
                open={menu}
                anchorEl={anchorEl}
              >
                <RibbonItem
                  onClick={() => {
                    conditionalWorkflowPath
                      ? ConditionalWorkflowPathStore.removeStatement(
                          indexLogic,
                          indexStatement
                        )
                      : ConditionalWorkflowStore.removeStatement(
                          indexLogic,
                          indexStatement
                        );
                    props.forceUpdateStatement();
                    forceUpdateState();
                    setMenu(false);
                    setAnchorEl(null);
                  }}
                >
                  Delete block
                </RibbonItem>
              </Menu>
            )
          : ""}
      </Grid>
    </Grid>
  );
}

export default ItemCondition;
