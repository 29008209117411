import React, { useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DEFAULT_CANVAS_MODE } from '../../../../../../../utils/CanvasConstants';
import AddIcon from "@mui/icons-material/Add";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import '../ComponentNode.css';
import PropTypes from "prop-types";
import { ComponentNodeBodyView } from "./ComponentNodeBodyView";

const PathHandle = styled(Handle)(() => ({
  backgroundColor: "#000000",
}));

const BRANCH_PATH_TYPE = "branch_paths";
const SOURCE_TYPE = "source";
const CONDITIONAL_WORKFLOW_PATH = "conditional_workflow_path";

const ComponentNodeView = ({
  classes, 
  data, 
  selected, 
  isSelectedComponent,
  isSelectedForceStart = false,
  mode,
  saving, 
  addLinkedConditionalPath,
}) => {
  const { hasInput, hasOutput, fromWorkflowPath, type, isTemp, color, label, baseColor } = data;
  const isDraftCanvas = mode === DEFAULT_CANVAS_MODE;

  const svgSelectedClass = useMemo(() => 
    (selected || isSelectedComponent || isSelectedForceStart) ? classes.selectedIcon : "",
  [selected, isSelectedComponent, isSelectedForceStart, classes.selectedIcon]);

  const renderInput = () => {
    if (!hasInput) return <div className={classes.filler} />;
    
    const handleProps = {
      className: classes.port,
      type: "target",
      position: fromWorkflowPath ? "left" : Position.Left,
      isConnectable: fromWorkflowPath ? false : !isTemp
    };
    
    return fromWorkflowPath ? <PathHandle {...handleProps} /> : <Handle {...handleProps} />;
  };

  const renderOutput = () => {
    if (!hasOutput) return <div className={classes.filler} />;

    switch (type) {
      case BRANCH_PATH_TYPE:
        return (
          <>
            <PathHandle
              className={classes.port}
              position={Position.Right}
              type={SOURCE_TYPE}
              isConnectable={!isTemp}
            />
            {isDraftCanvas && (
              <Tooltip
                title={<span className={classes.addPathTooltipTitle}>Add a Conditional Path</span>}
                placement="bottom"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 8],
                        },
                      },
                    ],
                  },
                }}
              >
                <Button
                  variant="outlined"
                  disabled={saving}
                  className={classes.addPathButton}
                  onClick={(event) => {
                  event.stopPropagation();
                  addLinkedConditionalPath(data)
                  }}
                  id="add-conditional-path-button"
                >
                  <AddIcon className={classes.addPathIcon} />
                  <AltRouteIcon className={classes.addPathIcon} />
                </Button>
              </Tooltip>
            )}
          </>
        );
      case CONDITIONAL_WORKFLOW_PATH:
        return (
          <PathHandle
            className={classes.port}
            position={Position.Right}
            type={SOURCE_TYPE}
            isConnectable={false}
          />
        );
      default:
        return (
          <Handle
            className={classes.port}
            position={Position.Right}
            type={SOURCE_TYPE}
            isConnectable={!isTemp}
          />
        );
    }
  };

  const renderBody = () => {
    if (fromWorkflowPath) {
      return (
        <Button
          size="small"
          style={{
            backgroundColor: color.bg,
            border: `1px solid ${color.border}`,
          }}
          variant="outlined"
          className="draftVersionButton"
        >
          <span style={{ paddingLeft: "5px" }}>{label}</span>
        </Button>
      );
    }
    return (
      <ComponentNodeBodyView
        baseColor={baseColor}
        selectedClass={svgSelectedClass}
        data={data}
        classes={classes}
      />
    );
  };

  return (
    <div className={classes.nodeView} id="node-view">
      {renderInput()}
      {renderBody()}
      {renderOutput()}
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={true}
        style={{ display: "none"}}
      />
      <Handle
        type={SOURCE_TYPE}
        position={Position.Top}
        isConnectable={true}
        style={{ display: "none"}}
      />
    </div>
  );
};

ComponentNodeView.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  isSelectedComponent: PropTypes.bool,
  isSelectedForceStart: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  saving: PropTypes.bool,
  addLinkedConditionalPath: PropTypes.func.isRequired,
};

export default ComponentNodeView;