//Array of timestamp formats for datepicker
const timestampFormats = [
    { value: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", displayValue: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'" },
    { value: "dd MMMM yyyy", displayValue: "dd Month yyyy [01 February 2021]" },
    { value: "dd MMMM yyyy hh:mm", displayValue: "dd Month yyyy hh:mm [01 February 2021 14:01]" },
    { value: "dd/MM/yy", displayValue: "dd/mm/yy [01/02/21]" },
    { value: "dd/MM/yy hh:mm", displayValue: "dd/mm/yy hh:mm [01/02/21 14:01]" },
    { value: "dd/MM/yyyy", displayValue: "dd/mm/yyyy [01/02/2021]" },
    { value: "dd/MM/yyyy hh:mm", displayValue: "dd/mm/yyyy hh:mm [01/02/2021 14:01]" },
    { value: "dd-MM-yyyy", displayValue: "dd-mm-yyyy [01/02/2021]" },
    { value: "dd-MM-yyyy hh:mm", displayValue: "dd-mm-yyyy hh:mm [01/02/2021 14:01]" },
    { value: "MM/dd/yyyy", displayValue: "mm/dd/yyyy [02/01/2021]" },
    { value: "MM/dd/yyyy hh:mm", displayValue: "mm/dd/yyyy hh:mm [02/01/2021 14:01]" },
    { value: "yyyy-MM-dd", displayValue: "yyyy-mm-dd [2021-02-01]" },
    { value: "yyyy-MM-dd hh:mm", displayValue: "yyyy-mm-dd hh:mm [2021-02-01 14:01]" },
  ];

  const defaultTimestampFormat = timestampFormats[0];

  export{timestampFormats, defaultTimestampFormat}