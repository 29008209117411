import React, { useState, useEffect } from "react";

// MUI
import { Grid, styled } from "@mui/material";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

// Util
import NotesFactory from "../../../ProjectCanvas/NotesRegistry";
import projectStore from "../../../ProjectCanvas/ProjectStore";
import { StyledTray } from "./StyledTray";

const DEFAULT_NOTE = `<p>Use sticky notes to document your process, map out steps or write comments for others to see</p>`;

function NotesTray({ classes }) {
    const [notesDisplayData, setNotesDisplayData] = useState(NotesFactory);
    const [hasBanner, setHasBanner] = useState(projectStore.state.hasBanner);

    useEffect(() => {
        setHasBanner(projectStore.state.hasBanner);
    }, [projectStore.state.hasBanner]);

    const handleNotesSearch = (event) => {
        const word = event.target.value.toLowerCase();
        let searchComp = {};
        let usedData;

        const filteredComp = Object.keys(NotesFactory).filter((a, i) => {
            const comp = NotesFactory[a];
            const compName = comp.displayName.toLowerCase().trim();
            const compDescription = comp.description.toLowerCase().trim();
            if (compName.indexOf(word) !== -1) searchComp[a] = NotesFactory[a];
            if (compDescription.indexOf(word) !== -1) searchComp[a] = NotesFactory[a];
            return false;
        });

        if (word.trim() !== null) {
            usedData = searchComp;
        } else {
            usedData = NotesFactory;
        }

        setNotesDisplayData(usedData);
    };

    const SidebarNotesItem = (component) => {
        const onDragStart = (event, component) => {
            let noteData = handleCreatingNote(component.component.name);

            if (component.component.name != "map_a_zap") {
                event.dataTransfer.setData("application/reactflow", "note");
                event.dataTransfer.setData("type", component.component.name);

                // Get note data and send over
                event.dataTransfer.setData("width", noteData.width);
                event.dataTransfer.setData("height", noteData.height);
                event.dataTransfer.setData("color", noteData.color);
                event.dataTransfer.setData("content", noteData.content);

                event.dataTransfer.effectAllowed = "move";
            } else {
                event.dataTransfer.setData("application/reactflow", "note");
                event.dataTransfer.setData("type", component.component.name);
                event.dataTransfer.effectAllowed = "move";
            }
        };

        return (
            <Grid draggable={true} onDragStart={(event) => onDragStart(event, component)} container direction={"row"} alignItems={"center"} wrap={"noWrap"} className={classes.canvasItem}>
                <Grid item>
                    <img className={classes.logo} src={component.component.logo} />
                </Grid>
                <Grid xs={10} container item direction={"column"}>
                    <p className={`rmv-margin ${classes.title}`}>{component.component.displayName}</p>
                    <p className={`s-text rmv-margin canvas-description ${classes.description}`}>{component.component.description}</p>
                </Grid>
            </Grid>
        );
    };

    const handleCreatingNote = (component_name) => {
        switch (component_name) {
            case "sticky_note":
                return getNoteObject(400, 400, "#FBF6BE", DEFAULT_NOTE);
            case "small_note":
                return getNoteObject(400, 150, "#FBF6BE", DEFAULT_NOTE);
            case "large_note":
                return getNoteObject(600, 600, "#FBF6BE", DEFAULT_NOTE);
            case "full_page":
                return getNoteObject(800, 1200, "#F1F1F1", DEFAULT_NOTE);
            case "swimlane":
                return getNoteObject(1600, 300, "#DAEEFF", DEFAULT_NOTE);
            default:
                break;
        }
    };

    //create note
    const getNoteObject = (width, height, color, note_content) => {
        return {
            content: note_content,
            color: color,
            width: width,
            height: height,
        };
    };

    return (
        <StyledTray hasBanner={hasBanner} data-tut="reactour__canvas__sidebar">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        sx={{ marginBottom: "8px" }}
                        className={classes.search}
                        placeholder="Search for note type"
                        id="notes-search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleNotesSearch}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    {Object.values(notesDisplayData).map((component, id) => {
                        return <SidebarNotesItem key={id} component={component} />;
                    })}
                </Grid>
            </Grid>
        </StyledTray>
    );
}

export default NotesTray;
