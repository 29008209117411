import React from "react";

// mui
import { Autocomplete, Button, Grid, Select, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";

// util
import W86FindOut from "../Util/W86FindOut";
import { updateNextStepInDB } from "../Util/Util";
import { Teams } from "../Util/StaticDropdownInfo";
import CompanyInfoMessage from "./CompanyInfoMessage";
import { timezones } from "../../../../utils/timezones";
import { send_request } from "../../../../utils/Request";
import SearchableDropdown from "../Util/SearchableDropdown";
import { ItemTitle } from "../Util/OnboardingStyledComponents";
import { useAIOnboardingContext } from "../OnboardingContextProvider";

function RoleAndTeamInfoMessage(props) {
    const { loading, loadingBubble, setLoading, setLoadingBubble, timezone, setTimezone, role, setRole, team, setTeam, messages, setMessages, findOut, findOutDetails } = useAIOnboardingContext();

    const isLatestMsg = props.position === messages.length;

    const submitRoleAndTeamInfo = async () => {
        setLoading(true);
        setLoadingBubble(true);

        // Define URLs
        const extraQuestionURL = `authz-service/extra_questions/set_up_organization`;
        const setUpClientURL = `authz-service/set_up_client`;
        const roleAndTeamURL = `authz-service/set_role_and_team`;

        // Construct client info
        const clientInfo = {
            referral_source: findOut,
        };

        if (findOut === "REFERRED_BY_A_WORKFLOW86_USER") clientInfo["referrer_username"] = findOutDetails ? findOutDetails.trim() : "";
        else if (findOut === "OTHER") clientInfo["other_detail"] = findOutDetails ? findOutDetails.trim() : "";
        else if (findOut === "ANOTHER_WEBSITE") clientInfo["other_website"] = findOutDetails ? findOutDetails.trim() : "";

        // Construct role and team info
        const roleAndTeamInfo = {
            role: role,
            team: team && team.length === 1 ? team[0] : null,
        };

        // Construct client data timezone info
        const clientData = {
            timezone: timezone,
        };

        await Promise.all([
            send_request(setUpClientURL, clientData, null, "POST"),
            send_request(extraQuestionURL, clientInfo, null, "POST"),
            send_request(roleAndTeamURL, roleAndTeamInfo, null, "POST"),
        ]); // Execute logic

        // Move to next step
        updateNextStepInDB("COMPANY_INFO");

        setTimeout(() => {
            setLoading(false);
            setLoadingBubble(false);

            const nextMsg = {
                position: "center",
                content: <CompanyInfoMessage classes={props.classes} position={6} />,
            };
            setMessages((prevMessages) => [...prevMessages, nextMsg]);
        }, 0);
    };

    const checkIfDisabled = () => {
        if (loading || loadingBubble || !isLatestMsg) return true;

        if (!role || role.length <= 0) return true;
        if (!timezone) return true;

        return false;
    };

    return (
        <Grid container className={props.classes.userInfoBox} rowGap={2}>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>Your role or job title (required)</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            className={props.classes.inputField}
                            variant="outlined"
                            placeholder="Enter your role or job title"
                            value={role}
                            onChange={(e) => {
                                if (e.target.value.length > 128) return;
                                setRole(e.target.value);
                            }}
                            disabled={loading || !isLatestMsg}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>Your team</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <SearchableDropdown options={Teams} value={team} setValue={setTeam} placeholder="Select team" disabled={loading || !isLatestMsg} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container rowGap={"6px"}>
                    <Grid item xs={12}>
                        <ItemTitle>Your timezone (required)</ItemTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={timezones}
                            autoComplete={true}
                            value={timezone != null ? timezone : "select timezone"}
                            size={"small"}
                            onChange={(event, inputValue) => {
                                if (inputValue != null) {
                                    setTimezone(inputValue.name);
                                }
                            }}
                            renderInput={(params) => {
                                return <TextField {...params} variant="outlined" value={timezone != null ? timezone : "select timezone"} />;
                            }}
                            getOptionLabel={(option) => {
                                if (typeof option == "object") return option.name + " " + option.offset;
                                return option;
                            }}
                            isOptionEqualToValue={(option, value) => {
                                if (option != null) {
                                    if (option.name == value) return true;
                                    else return false;
                                }
                            }}
                            className={props.classes.select}
                            variant={"outlined"}
                            disabled={loading || !isLatestMsg}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <W86FindOut ItemTitle={ItemTitle} classes={props.classes} loading={loading} isLatestMsg={isLatestMsg} />
            <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Button className={props.classes.sendButton} variant={"contained"} onClick={submitRoleAndTeamInfo} disabled={checkIfDisabled()}>
                    <Send />
                </Button>
            </Grid>
        </Grid>
    );
}

export default RoleAndTeamInfoMessage;
