import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx"; 
import FormRequiredStore from "./FormConditionStores/FormRequiredStore";
import ItemStatementCondition from "./FormConditionComponents/ItemStatementRequired";

class FormConditional extends React.Component {
  componentDidMount() {
    const { required } = this.props;
    if (required && required.Statement) {
      FormRequiredStore.setStatement(required)
      FormRequiredStore.setFormConditional(required);
    } else {
      FormRequiredStore.initialiseEmpty();
    }
  }
  render() {
    const { Condition } = toJS(FormRequiredStore.Statement.Statement.content);
    const { AnswerList } = toJS(FormRequiredStore);

    return (
      <div
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          padding: "15px 15px 30px",
          margin: "15px 0"
        }}
      >
        {Condition &&
          Condition.map((condition, index) => (
            <ItemStatementCondition
              condition={condition}
              indexStatement={index}
              key={index}
              AnswerList={AnswerList[index]}
            />
          ))}
      </div>
    );
  }
}
export default observer(FormConditional);
