import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import {configJodit} from "../../utils/configJodit";
import {PLACEHOLDER_COLOR, PLACEHOLDER_ERROR_COLOR} from "../../utils/PlaceholderUtil";
import {observer} from "mobx-react";
import {Component} from "react";

class JoditSuperClass extends Component {

  generateButton = (backgroundColor, borderColor,marginRight,whitespace,icon,textColor) => {
    const button = this.jodit.createInside.element("button", {
      style: {
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`,
        color:textColor?textColor:PLACEHOLDER_COLOR.TEXT,
        display: "inline-flex",
        padding: "4px 4px 4px 38px",
        marginRight:marginRight?marginRight:"",
        borderRadius: "4px",
        whiteSpace: whitespace?whitespace:"nowrap",
        fontSize: "inherit !important",
        fontStyle: "inherit !important",
        fontFamily: "inherit !important",
      },
      contenteditable: false,
    });
    button.setAttribute('class',"joditPlaceholderIcon");
    button.setAttribute('style', button.getAttribute('style')+'; --custom-icon:url('+icon+')');
    return button;
  };

  getPlaceholderStyle = (value, backgroundColor, borderColor,icon,prefix) => {
    let content = document.createElement("span");
    const button = this.generateButton(backgroundColor, borderColor,"","nowrap",icon);
    button.setAttribute("data-highlight-schema", prefix+"{([^}]+)}");
    button.setAttribute("data-jodit-temp", "true");
    button.innerHTML = prefix+"{" + value + "}";

    content.insertAdjacentHTML("afterbegin", button.outerHTML);

    return content;
  };

  checkAvailablePlaceholder = (textContent) => {
    let result = false;
    this.props.availablePlaceholders[0].placeholders.map((placeholder) => {
      if (textContent.includes(placeholder.key)) {
        result = true;
      }
    });

    return result;
  };

  insertValueRenderer(type, length, key,prefix) {
    const placeholderColor = getPlaceholderColors(type, length);

    return this.getPlaceholderStyle(
        key,
        placeholderColor.hover,
        placeholderColor.default,
        placeholderColor.icon,
        prefix
    );
  }

  itemRenderer(item,prefix) {
    const placeholderColor = getPlaceholderColors(
        item.type,
        item.length
    );
    const button = this.generateButton(
        placeholderColor.hover,
        placeholderColor.default,
       "", "", placeholderColor.icon
    );
    button.innerHTML = prefix+"{" + item.key + "}";
    return button;
  }

  removeDuplicatePlaceholders(placeholders) {
    if (!placeholders) return [];
    
    // Define result unique placeholders list and used key set
    const uniquePHs = [];
    let usedKeys = new Set();

    // Loop over placeholders and check if has been added to usedKeys
    // If so, skip it, else add it
    for (let i = 0; i < placeholders.length; i++) {
      const pH = placeholders[i];
      if (usedKeys.has(pH.key)) continue;
      usedKeys.add(pH.key);
      uniquePHs.push(pH);
    }

    return uniquePHs;
  }

  sourceFeed(query,prefix) {
    if (this.props.availablePlaceholders === undefined) return false;

    const placeholderList = this.props.availablePlaceholders[0].placeholders;
    const uniquePlaceholders = this.removeDuplicatePlaceholders(placeholderList);

    return uniquePlaceholders.filter(
        (value) => {
          let text = prefix + "{" + value.key + "}";
          if (text === query) {
            return false;
          } else {
            return (
                text.indexOf(query.trim()) === 0 ||
                text.indexOf(query.trim()) === 1
            );
          }
        }
    )
  }

  getPlaceholderStyleForExistingPlaceholders(matched, jodit) {
    let color = PLACEHOLDER_ERROR_COLOR.BACKGROUND;
    let borderColor = PLACEHOLDER_ERROR_COLOR.BORDER;
    let textColor = PLACEHOLDER_ERROR_COLOR.TEXT;
    let icon =PLACEHOLDER_ERROR_COLOR.ICON;

    if (this.props.availablePlaceholders.length > 0) {
      this.props.availablePlaceholders[0].placeholders.filter(
          (placeholder) => {
            if (placeholder.key === matched[1]) {
              const placeholderColor = getPlaceholderColors(
                  placeholder.type,
                  placeholder.length
              );

              color = placeholderColor.hover;
              borderColor = placeholderColor.default;
              textColor = PLACEHOLDER_COLOR.TEXT;
              icon=placeholderColor.icon;
            }
          }
      );
    }
    const button = jodit.createInside.element("button", {
      style: {
        backgroundColor: color,
        border: `1px solid ${borderColor}`,
        color: textColor,
        display: "inline-flex",
        padding: "4px 4px 4px 38px",
        borderRadius: "4px",
        whiteSpace: "nowrap",
        fontSize: "inherit !important",
        fontStyle: "inherit !important",
        fontFamily: "inherit !important",
      },
      contenteditable: true,
    });
    button.setAttribute('class',"joditPlaceholderIcon");
    button.setAttribute('style', button.getAttribute('style')+'; --custom-icon:url('+icon+')');
    return button;
  }


  setRef = (jodit) => (this.jodit = jodit);
}
export default JoditSuperClass;
