import React, { Component } from "react";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import InZapierOrW86Box from "./InZapierOrW86Box";

class ChooseApp extends Component {
  constructor(props) {
    super(props);
  }

  selectMethod = value => {
    this.setState({
      method: value
    });
  };

  nextPage = () => {
    this.props.onChangeState(1, 1);
  };

  render() {
    let zapierContent = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          1. Go to{" "}
          <Button
            href="https://www.zapier.com"
            target="_blank"
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#FF4F00",
              color: "white",
              fontWeight: "normal",
              textTransform: "none",
              padding: "4px 8px",
              "&:hover": {
                backgroundColor: "#E64800"
              }
            }}
          >
            Zapier
          </Button>
        </Grid>
        <Grid item xs={12} className="textAlignCenter">
          2. In the <b>Choose app & event</b> step, select <b>Workflow86</b> as your action step
        </Grid>
        <Grid item xs={12} className="textAlignCenter">
          3. Select <b>"Trigger a Workflow"</b> as the event
        </Grid>
        <Grid item xs={12} className="textAlignCenter">
          4. In the <b>Choose account</b> step, sign in to your Workflow86 account
        </Grid>
        <Grid item xs={12} className="textAlignCenter">
          5. You will be taken to the <b>Set up action</b> step
        </Grid>
      </Grid>
    );

    let workflow86Content = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          Click <b>Next</b> to continue
        </Grid>
      </Grid>
    );

    return (
      <>
        <Grid container spacing={2}>
          <InZapierOrW86Box zapierOrW86={"Zapier"} content={zapierContent} isExpanded={this.props.isExpanded} />

          <InZapierOrW86Box
            zapierOrW86={"Workflow86"}
            content={workflow86Content}
            isExpanded={this.props.isExpanded}
          />
          <Grid item xs={12} className="dialogContentRight">
            <Grid item xs={12}>
              <Button
                onClick={this.nextPage}
                variant={"contained"}
                color={"primary"}
              >
                {" "}
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ChooseApp;
