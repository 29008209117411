// Part of the sign up flow 
// This component renders a floating panel of SVG icons on the sign-up screen,
// occupying half the screen space. The SVG icons are dynamically sourced from
// the ComponentRegistry, specifically those components marked with 
// `isFloatingIcon: true`. 
// 
// Usage: <FloatingIconHalfScreen />, referenced in CustomSignIn
// 
// Dependencies:
// - ComponentRegistry: An array of components, each with properties like `logo`
//   (the SVG path) and `displayName` (the icon’s alt text).

import React from "react";
import { Grid } from '@mui/material';
import ComponentRegistry from '../../ProjectCanvas/ComponentRegistry'; 

const FloatingIconHalfScreen = () => (
  <Grid item xs={4} className="animated-background">
    <div className="svg-container">
      {ComponentRegistry
        .filter((component) => component.isFloatingIcon) 
        .map((component) => (
          component.logo && (
            <div key={component.name} className="svg-item">
              <img src={component.logo} alt={component.displayName} />
            </div>
          )
        ))}
    </div>
  </Grid>
);

export default FloatingIconHalfScreen;
