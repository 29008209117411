import {Autocomplete, TextField, styled, Typography, Grid} from "@mui/material";
import React from "react";

const SelectLabel = styled(Typography)({
  paddingBottom: '8px',
  fontFamily: 'Open Sans !important',
  fontSize: '14px',
  fontWeight: '400 !important',
  lineHeight: '19.07px',
  textAlign: 'left',
});

const CurlTemplateAutoComplete = ({
  label, 
  value, 
  onChange, 
  options, 
  placeholder, 
  getOptionLabel, 
  renderOption, 
  renderInput 
}) => (
  <Grid item xs={12}>
    <SelectLabel>
      {label}
    </SelectLabel>
    <Autocomplete
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput || ((params) => <TextField {...params} placeholder={placeholder} size="small" />)}
      fullWidth
      autoHighlight
    />
  </Grid>
);

export default CurlTemplateAutoComplete;
