import { computed, observable, decorate, action, toJS } from "mobx";


class TriggerAZapStore {

  constructor() {
    this.initialiseEmpty();
  }

  keyAuto = () => {
    return "taz" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  };

  initialiseEmpty() {
    this.name = "";
    this.position = null;
    this.placeholders = [];
    this.componentId = "";
    this.type = "taz";
    this.lastModified = "";    
    this.error = false;    
    this.data = {
      "key":"initKeyValue",
      "zapierData": [{"key":"key1","value":"value1","sampleValue":"sample value1"}]
    };
  };

  setFocus = (index) => {
    this.position = index;    
  };

  setComponentData (component) {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData && component.componentData.data) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      this.data = {
        "key":"initKeyValue",
        "zapierData": [{"key":"key1","value":"value 1","sampleValue":"sample value 1"}]
      };
      this.name = ""
    }
  };

  setName = (name) => {
    this.name = name;
  };
  
  addNewData = () => {
    if(!this.data.zapierData) {
      this.data.zapierData = [];
    }

    let newCounter = this.data.zapierData.length+1;

    this.data.zapierData.push({"key":"key"+newCounter,
                                "value":"value "+newCounter,
                                "sampleValue":"sample value "+newCounter});
  }

  setDataValue = (index,property,value) => {
    this.data.zapierData[index][property] = value;
  }

  deleteData = (index) => {
    this.data.zapierData.splice(index,1);
  }

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }
}

decorate(TriggerAZapStore, {
  componentId: observable,
  name: observable,
  data: observable,
  json: observable,
  type: observable,
  lastModified: observable,
  error: observable,
  setComponentName: action,
  addComponentData: action,
  setComponentData: action,
  setLastModified: action,
  addNewData: action,
  setDataValue: action,
  deleteData: action,
  initialiseEmpty: action,
  placeholders: observable,
  setPlaceholders: action,
});

const store = new TriggerAZapStore();
export default store;