import React, { useState } from "react";
//material components
import { Grid } from "@mui/material";
//material icons
import {
  ContentCopy,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
//editor
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
//custom css to change editor styling
import "./custom-github-theme.css";
//copy util
import { CopyToClipboard } from "react-copy-to-clipboard";

const JSONRenderer = ({ inputString = "" }) => {
  const [expanded, setExpanded] = useState(true);

  let jsonObject;
  try {
    jsonObject = JSON.parse(inputString);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return <div>Error parsing JSON: {error.message}</div>;
  }

  return (
    <Grid
      container
      item
      xs={12}
      direction={"column"}
      justifyContent={"center"}
      wrap={"nowrap"}
    >
      <Grid
        container
        item
        xs={12}
        direction={"row"}
        alignItems={"center"}
        className={"panel"}
      >
        <Grid item xs={10}>
          View full plan
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent={"flex-end"}
          direction={"row"}
          spacing={1}
        >
          <Grid item>
            <CopyToClipboard text={JSON.stringify(jsonObject, null, 2)}>
              <ContentCopy fontSize={"small"} className={"panel-icon"} />
            </CopyToClipboard>
          </Grid>
          <Grid item>
            {expanded ? (
              <KeyboardArrowUp
                fontSize={"small"}
                className={"panel-icon"}
                onClick={() => setExpanded(!expanded)}
              />
            ) : (
              <KeyboardArrowDown
                fontSize={"small"}
                className={"panel-icon"}
                onClick={() => setExpanded(!expanded)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {expanded && (
        <Grid container item xs={12}>
          <div id="aceditor" className={"ace-editor"}>
            <AceEditor
              mode="javascript"
              theme="github"
              className="ace-custom-github" // Use the custom theme
              width="100%"
              height="100%"
              showPrintMargin={false}
              showGutter={false}
              highlightActiveLine={false}
              wrapEnabled={true}
              value={JSON.stringify(jsonObject, null, 2)}
              readOnly={true}
              fontSize={12}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default JSONRenderer;
