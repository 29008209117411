//we do not offer placeholders for maps, upload media, divider, e-signature, file preview and table
const excludedQuestionTypes = [3, 6, 8, 13, 14, 15];

export function searchForInnerFormPlaceholders(formQuestions) {
  let result = [];

  for (let question of formQuestions) {
    if (
      question.config &&
      question.config.key &&
      excludedQuestionTypes.indexOf(question.type) === -1
    )
      result.push({
        key: question.config.key + ".answer",
        type: "form_placeholder",
        value: question.config.value + ".answer"
      });
  }

  return result;
}
