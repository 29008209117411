// Part of the sign up flow to display sign-up options

import React from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const SignUpOptions = ({ loading, onSignUpWithEmail, onSignUpWithGoogle, onBackToSignIn }) => (
  <Typography component="div" className="login-with-app-another">
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginBottom: 16, width: '100%' }}
        startIcon={<AlternateEmailIcon />}
        onClick={onSignUpWithEmail}
      >
        Sign Up with Email
      </Button>
    </div>
    <div>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '100%' }}
        startIcon={
          <img 
            src="/svgs/googleLogo.svg" 
            alt="Google Logo"
            className="buttonIcon"
          />
        }
        onClick={onSignUpWithGoogle}
        disabled={loading}
      >
        Sign Up with Google
        {loading && <CircularProgress size={24} className="buttonProgress" />}
      </Button>
    </div>
    <div className="fix-link">
      <Button
        style={{ marginTop: 16, width: '100%' }}
        className="backButton"
        color="info"
        onClick={onBackToSignIn}
      >
        Back to Sign In
      </Button>
    </div>
  </Typography>
);

export default SignUpOptions;
