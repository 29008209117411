export const capitalizeLetter = (string) => {
    string = string.replaceAll("_", " ");
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getComponentDisplayName = (string) => {
    if (!string) return "Assign Task"; // If null, just return assign task
    if (string == "sequential_form") return "Assign Task";
    if (string === "form_section") return "Form Section";

    try {
        return capitalizeLetter(string);
    } catch (e) {
        return string;
    }
};
