import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Radio from "@mui/material/Radio";
import ListItemText from "@mui/material/ListItemText";

const styles = theme => ({
  checkbox: {
    marginRight: 40,
    color: "#afafaf",
    "&$checked": {
      color: "#2196F3"
    }
  },
  listtext: {
    fontSize: "13px"
  },
  checked: {},
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  }
});

class Singlechoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;

    try {
      return (
        <div className={classes.root}>
          <List component="nav">
            {this.props.data.answer_list.map((n, i) => {
              return (
                <ListItem dense button key={i}>
                  <Radio
                    value={i}
                    classes={{
                      root: classes.checkbox,
                      checked: classes.checked
                    }}
                  />

                  <div>
                    <div style={{ textAlign: "left", display: "inline" }}>
                      <ListItemText
                        className={classes.listtext}
                        disableTypography={true}
                        primary={
                          <p style={{ marginBottom: 0, fontWeight: "normal" }}>
                            {n[1]}
                          </p>
                        }
                      />
                    </div>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
      );
    } catch (e) {
      return <h5>Please specify answers in config </h5>;
    }
  }
}

Singlechoice.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Singlechoice);
