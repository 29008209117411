import { computed, observable, decorate, action, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";

class WebhookImportStore {
  constructor() {
    this.initialiseEmpty();
  }

  setWebhookImportBody = Body => {
    this.json = Body;
  };

  setTemplateData = data => {
    this.data = data;
  }

  setComponentData = (component) => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData && component.componentData.data) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.componentData();
      this.data = obj;
      this.lastModified = "";
      this.name = "";
    }
  };

  keyAuto = () => {
    return "wbhk" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  };

  setField = (value, index, type) => {
    switch (type) {
      case "sample":
        this.data.json = value;
        return;
      case "key":
        this.data[index].key = value;
        return;
      case "type":
        this.data.type = value;
        this.data.placeholders = []
        return;
      case "content":
        this.data[index].content = value;
        return;
    }
  };

  setErrorMessage = (error) => {
    this.errorJsonMessage = error
  }

  componentData = () => {
    const obj = this.dataField()
    return {
      endpoint: "https://webhook.workflow86.com/webhook/" + this.componentId,
      json: "",
      type: "open_endpoint",
      samplePlaceholders: [
        obj
      ],
      placeholders: [],
    };
  };

  setRawResponsePH = (value) => {
    this.data.samplePlaceholders = [{
      key: value,
      sampleKey: value,
      sampleData: "",
      checked: true
    }];
  }

  dataField = () => {
    return {
      key : this.keyAuto(),
      sampleKey : "",
      sampleData : "",
      checked: true
    }
  }

  addRow = () => {
    const obj = this.dataField()
    this.data.samplePlaceholders.push(obj)
  }

  jsonData = (key,value) => {
    return {
      key: key,
      sampleKey: key,
      sampleData: value,
      checked: true
    };
  };

  addJsonData = (obj) => {
    this.data.samplePlaceholders.push(obj)
  };

  clearField = () => {
    this.data.samplePlaceholders = []
  }

  deleteRow = (placeholder) => {
    let placeholders = toJS(this.data.samplePlaceholders)
    let index = placeholders.findIndex(x => x.key === placeholder.key);
    this.data.samplePlaceholders.splice(index, 1);
  }

  onChangeField = (value,placeholder,type) => {
    let placeholders = toJS(this.data.samplePlaceholders)
    let index = placeholders.findIndex(x => x.key === placeholder.key);
    switch (type) {
      case "content":
        this.data.samplePlaceholders[index].sampleKey = value;
        this.data.samplePlaceholders[index].sampleData = "";
        return;
      case "key" :
        this.data.samplePlaceholders[index].key = value;
        return;
      case "json" :
        this.data.json = value
        return;
    }

  }


  addComponentData = () => {
    const obj = this.componentData();
    this.data.push(obj);
  };

  deleteComponent = (index) => {
    this.data = this.data.splice(index, 1);
  };

  storeError = (obj) => {
    this.placeholderError = obj;
  }

  initialiseEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.position = null;
    this.componentId = "";
    this.type = "wbhk";
    this.lastModified = "";
    this.data = obj;
    this.error = false;
    this.errorJsonMessage = "";
    this.placeholderError = [];
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified
    };
  }

  setWebhookName = (name) => {
    this.name = name;
  };

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  };

}
decorate(WebhookImportStore, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable,
  json: observable,
  type: observable,
  lastModified: observable,
  error: observable,
  placeholderError: observable,
  setComponentName: action,
  addComponentData: action,
  setComponentData: action,
  setLastModified: action,
  setField: action,
  setFocus: action,
  deleteComponent: action,
  initialiseEmpty: action,
  template: computed,
});

const webhookImport = new WebhookImportStore();
export default webhookImport;
