import React from "react";
import { Tooltip } from "@mui/material";
import "./stylesheet/email.css";
import ChipInput from "../ChipInput/ChipInput";
import Grid from "@mui/material/Grid";

export default class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items ? this.props.items : [],
      value: "",
      error: false,
      focus: ""
    };
  }

  isValid(email) {
    let error = null;

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
  //   if (this.props.items.length !== nextProps.items.length) {
  //     this.setState({
  //       addressType: this.props.addressType,
  //       items: nextProps.items
  //     });
  //   }
  // }

  render() {
    return (
      <Grid
        container
        className={"emailBar"}
        direction={"row"}
        justifyContent={"flex-start"}
      >
        {this.props.addressType && <p>{`${this.props.addressType}: `}</p>}
        <ChipInput
          fullWidth={true}
          inputValue={this.props.defaultValue}
          onFocus={this.props.focus}
          onPlaceholderSelected={this.props.onPlaceholderSelected}
          onDelete={(index) => {
            this.props.defaultValue.splice(index, 1);
            this.props.store(this.props.defaultValue);
          }}
          placeholder={
            this.props.placeholder
              ? this.props.placeholder
              : "Type in an email address or insert placeholders"
          }
          placeholders={this.state.items}
          error={this.state.error ? true : false}
          className={"input " + (this.state.error && " has-error")}
        />
        {/* </Tooltip> */}
      </Grid>
    );
  }
}
