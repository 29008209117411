export const insertImagePopup = `  
                <div class="jodit-popup__content">
                  <div class="jodit-tabs">
                    <div class="jodit-tabs__buttons">
                      <button
                        class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_initial jodit-ui-button_upload jodit-ui-button_text-icons_true jodit-tabs__button jodit-tabs__button_columns_2"
                        type="button" role="button" aria-pressed="true" tabindex="-1" data-ref="upload" ref="upload">
                        <span class="jodit-ui-button__icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" class="jodit-icon_upload jodit-icon">
                            <path
                              d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
                            </path>
                          </svg>
                        </span>
                        <span class="jodit-ui-button__text">Upload</span>
                      </button>
                      <button
                        class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_initial jodit-ui-button_link jodit-ui-button_text-icons_true jodit-tabs__button jodit-tabs__button_columns_2"
                        type="button" role="button" aria-pressed="false" tabindex="-1" data-ref="link" ref="link">
                        <span class="jodit-ui-button__icon">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" class="jodit-icon_link jodit-icon">
                            <path
                              d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                            </path>
                          </svg>
                        </span>
                        <span class="jodit-ui-button__text">URL</span>
                      </button>
                    </div>
                    <div class="jodit-tabs__wrapper">
                      <div class="jodit-tab jodit-tab_active">
                        <div class="jodit-drag-and-drop__file-box"><strong>Drop image</strong><span><br>or click</span><input
                            type="file" accept="image/*" tabindex="-1" dir="auto" multiple=""></div>
                      </div>
                      <div class="jodit-tab">
                        <form class="jodit-ui-form" dir="auto">
                          <div class="jodit-ui-input jodit-ui-form__url"><span class="jodit-ui-input__label">URL</span>
                            <div class="jodit-ui-input__wrapper"><input class="jodit-ui-input__input" dir="auto" name="url"
                                type="text" data-ref="url" ref="url" required="true" placeholder="https://"></div>
                          </div>
                          <div class="jodit-ui-input jodit-ui-form__text"><span class="jodit-ui-input__label">Alternative text</span>
                            <div class="jodit-ui-input__wrapper"><input class="jodit-ui-input__input" dir="auto" name="text"
                                type="text" data-ref="text" ref="text" placeholder=""></div>
                          </div>
                          <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle"><button
                              class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_status_primary jodit-ui-button_text-icons_true"
                              type="submit" role="button" aria-pressed="false"><span class="jodit-ui-button__icon"></span><span
                                class="jodit-ui-button__text jodit-ui-button_variant_primary">Insert</span></button></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
`