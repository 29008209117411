const styles = () => ({
  root: {
    " & .MuiPaper-root": {
      padding: "24px",
    },
    "& .MuiTimeline-root": {
      fontSize: "14px",
    },
    maxWidth: "80% !important"
  },
  timeline: {
    alignItems: "flex-start",
    paddingLeft: "0",
    "& .MuiTimelineOppositeContent-root": {
      paddingLeft: "0px !important",
      paddingRight: "6px !important",
    },
  },
  timelineOppositeContent: {
    flex: "none",
    minWidth: "150px",
    maxWidth: "150px",
    textAlign: "left",
    boxSizing: "border-box",
    whiteSpace: "pre-line",
  },
  headerStatus: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px"
  },
  rangeSelectDiv: {
    display: "flex",
    marginBottom: "20px",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "12px 16px",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    position: "sticky",
    top: 0,
    zIndex: 9
  },
  showTime: {
    width: "200px",
    height: "46px",
    display: "",
    marginLeft: "16px",
    margin: "0px 8px",
  },
  hideTime: {
    width: "200px",
    height: "46px",
    display: "none",
    margin: "0px 8px",
  },
  showLogsDiv: {
    marginRight: "24px",
  },
  logsNumber: {
    color: "black",
    fontWeight: "bold",
    fontSize: "24px",
    marginRight: "8px",
  },
  updateTimeFilter: {
    marginRight: "8px",
    minWidth: "120px",
    height: "32px",
    "& .MuiSelect-root": {
      paddingRight: "30px !important",
    },
  },
  sessionHeaderInputGroup: {
    backgroundColor: "white",
  },
  sessionLogsInfoContainer: {
    display: "flex",
    alignItems: "center",
    "@media (min-width: 837px)": {
      marginTop: "12px",
      marginBottom: "-2px",
    },
  },
  flexLeft: {
    flex: 0.1,
  },
  reactJson: {
    fontFamily: "Open Sans !important",
    marginTop: "8px",
  },
  terminateSession: {
    backgroundColor: "#B00020",
    color: "white",
    "& .active": {
      backgroundColor: "#B00020",
    },
    "& .hover": {
      backgroundColor: "#B00020",
    },
  },
  timelineContainer: {
    paddingBottom: "1rem",
  },
  timelineItem: {
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  link: {
    color: "#2196F3",
  },
  gridContainer: {
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
    "& .MuiGrid-item": {
      width: "100%",
    },
  },
  sessionLogSkeleton: {
    height: "25px",
    width: "250px",
    margin: "0px 4px",
  },
  paperStyle: {
    boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.5)",
    borderRadius: "5px",
    backgroundColor: "white",
  },
});
export default styles;
