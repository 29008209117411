// This file contains old urls that were used for opening components.
// You do not have to update this when creating new components
// We only use these to handle redirects in case someone is attempting 
// to navigate into component using a url they saved

const componentRoutes = [
  "form/:component_id",
  "sequential_form/:component_id",
  "form_section/:component_id",
  "email/:component_id",
  "api_export/:component_id",
  "airtable/:component_id",
  "conditional_logic/:component_id",
  "conditional_workflow/:component_id",
  "calculator/:component_id",
  "code/:component_id",
  "advanced_code/:component_id",
  "session_variables/:component_id",
  "knowledge_bank/:component_id",
  "create_database_record/:component_id",
  "data_bank/:component_id",
  "document_editor/:component_id",
  "list_transform/:component_id",
  "loop_through_list/:component_id",
  "schedule_start/:component_id",
  "webhook/:component_id",
  "parse_json/:component_id",
  "natural_language/:component_id",
  "bulk_assessment/:component_id",
  "api_request_and_response/:component_id",
  "slack/:component_id",
  "typeform/:component_id",
  "update_database_record/:component_id",
  "query_database_record/:component_id",
  "workflow_data/:component_id",
  "time_delay/:component_id",
  "trigger_from_zapier/:component_id",
  "trigger_a_zap/:component_id",
  "trigger_another_workflow/:component_id",
  "trigger_from_a_workflow/:component_id",
  "merge_workflow/:component_id",
  "trigger_via_email/:component_id",
  "ai_assistant/:component_id",
  "conditional_workflow_path/:component_id"
];

export default  componentRoutes;