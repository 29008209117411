export const PLACEHOLDER_ERROR_COLOR = {
  BACKGROUND: "#FFF",
  TEXT: "#B00020",
  BORDER: "#B00020",
  ICON:"/svgs/warning.svg"
};

export const PLACEHOLDER_COLOR = {
  TEXT: "#000",
  ICON:"/svgs/ChipInputDefaultIcon.svg"
};


export function displayRawValues(inputsArray) {
  let result = [];

  for(let a=0;inputsArray && a < inputsArray.length;a++) {
    let input = inputsArray[a];

    result[a] = displayRawValue(input);
  }

  return result;
}


export function displayRawValue(input) {
  if(input && input.trim().endsWith(".RawValue}")) {
    return input.replace(".RawValue}","}");
  }

  return input;
}

// Returns true if it is a placeholder
// Else return false
export const isPlaceholder = (input) => {
  if (!input) return false;
  if (typeof input !== "string") return false;

  let pH = input.trim();
  if (pH.length <= 2) return false;

  if (pH[0] === "$" && pH[1] === "{" && pH[pH.length - 1] === "}") return true;

  return false;
}