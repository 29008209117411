import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { Button, CircularProgress, Grid, styled, Tooltip } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import "../stylesheet/dashboardPage.css";

import { send_request } from "../../../utils/Request";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";
import "../Achievement/AchievementStyle.css";
import './TrialCard.css';

const UpgradeNowButton = styled(Button)({
    textTransform: "uppercase",
    backgroundColor: "rgba(40, 0, 0, 1)",
    color: "#FFF",
    padding: "8px 10px",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.85)",
    },
    border: "1px solid rgba(0, 0, 0, 1)",
    marginTop: "8px",
    width: "100%",
});

const ReactivateTrialButton = styled(Button)({
    textTransform: "uppercase",
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: "#000",
    padding: "8px 10px",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    border: "1px solid #000",
});

function FreeTrialCard() {
    const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);
    const [errorExtendingTrial, setErrorExtendingTrial] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Make API call to get count
        send_request("authz-service/get_free_trial_remaining", "", "", "GET")
            .then((res) => {
                setTrialDaysRemaining(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getBorderColor = (remaining) => {
        if (remaining > 7) return "rgba(85, 167, 122, 1)";
        else if (remaining > 0) return "rgba(255, 183, 77, 1)";
        else return "rgba(176, 0, 32, 1)";
    };

    const handleReactivateTrial = async () => {
        setLoading(true);
        let url = `authz-service/reactivateFreeTrial`;

        const json = await send_request(url, null, null, "POST");

        if (!json || !json.data) {
            // This means they were not eligible
            // Show error dialog
            setErrorExtendingTrial(true);
            setLoading(false);
            return;
        }

        // Else, reload the page
        setLoading(false);
        window.location.reload(false);
    };

    const getData = () => {
        let isBetweenZeroAndSeven = trialDaysRemaining > 0 && trialDaysRemaining <= 7;
        let isZero = trialDaysRemaining === 0;

        return (
            <Card 
                className={`trial-card ${isZero ? 'extra-spacing trial-card-critical' : ''}`} 
                style={{ borderColor: getBorderColor(trialDaysRemaining) }}
                data-tut="reactour__freeTrialCountdown"
            >
                <CardContent className="trial-card-content">
                    <Typography variant="h4" component="p" className="card-header">
                        Time remaining in your free trial
                    </Typography>
                </CardContent>
                <CardContent className="text-content">
                    <FadeIn transitionDuration="1000">
                        <Typography variant="h0" component="p" className="activeNumber">
                            {trialDaysRemaining !== null ? Math.round(trialDaysRemaining) : trialDaysRemaining}
                        </Typography>
                    </FadeIn>
                    <Typography className="cardDescription">
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                days remaining
                            </Grid>
                            {!isZero && (
                                <Grid item xs={12}>
                                    <Link className="trial-card-link" to="/set_plan">
                                        Upgrade now
                                    </Link>{" "}
                                    to keep using Workflow86
                                </Grid>
                            )}
                            {isZero && (
                                <Grid item xs={12}>
                                    <Grid container spacing={2} className="trial-card-grid">
                                        <Grid item xs={12}>
                                            You can still build workflows but you will not be able to run them
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UpgradeNowButton startIcon={<BoltIcon />} onClick={() => window.open("/set_plan", "__blank")}>
                                                Upgrade Now
                                            </UpgradeNowButton>
                                        </Grid>
                                        {loading && <CircularProgress size={24} className="button-progress" />}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Typography>
                </CardContent>
                {errorExtendingTrial && <ErrorExtendingFreeTrialDialog errorExtendingTrial={errorExtendingTrial} setErrorExtendingTrial={setErrorExtendingTrial} />}
            </Card>
        );
    };

    return trialDaysRemaining !== null && getData();
}

export default FreeTrialCard;
