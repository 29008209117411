import React, { Component } from "react";
import { Grid, TextField, Paper, InputAdornment } from "@mui/material";
import TriggerAZapStore from "./TriggerAZapStore";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import GenerateToken from "../Component/GenerateToken";
import projectStore from "../ProjectCanvas/ProjectStore";
import ErrorIcon from "@mui/icons-material/Error";
import "../WebhookImport/WebhookImport.css";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import DataPayloadToZap from "./DataPayloadToZap";

class TriggerAZapTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasToken: false,
            loading: true,
            updateState: false,
        };
    }

    forceUpdateState = () => {
        let currentState = this.state.updateState;
        this.setState({ updateState: !currentState });
    };

    componentDidMount() {
        if (this.props.component_id !== undefined) {
            this.setState({ loading: true });
            let waitPromise = new Promise((resolve, reject) => {
                let wait = setTimeout(() => {
                    clearTimeout(wait);
                    resolve("wait done");
                }, 2000);
            });

            waitPromise
                .then(() => {
                    return send_request(`project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`, "", {});
                })
                .then((response) => {
                    if (response && response.data) {
                        const { components } = response.data;
                        if (components) {
                            TriggerAZapStore.setComponentData(components[0]);
                            this.props.onComponentNameChanged(TriggerAZapStore.name);
                            this.props.onComponentDescriptionChanged(components[0].description);
                            this.props.onLastModifiedChanged(TriggerAZapStore.lastModified);
                            this.setState({
                                status: components[0].status,
                            });
                            if (components[0].componentData && components[0].componentData) {
                                if (components[0].componentData.token) {
                                    this.setState({
                                        hasToken: true,
                                    });
                                }

                                this.setState({ loading: false });
                            }
                        }

                        this.setState({ loading: false });
                    } else {
                        this.setState({ loading: false });
                    }
                    this.props.setChildTemplateLoaded(true);
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    throw Error(err.message);
                });
        }
    }

    componentWillUnmount() {
        // Clear the mobX reactions
        this.saveThisComponent();
        this.reactToPlaceHolder();
    }

    saveThisComponent = reaction(
        () => this.props.SaveTrigger.triggerValue,
        () => {
            let dataComponent = toJS(TriggerAZapStore).data;

            let dataSave = toJS(TriggerAZapStore).data;
            let data = {
                componentData: {
                    data: dataSave,
                    name: toJS(this.props.ComponentName),
                },
                componentId: this.props.component_id,
                description: this.props.ComponentDescription.value
            };
            send_component_save_request("component-service/trigger_a_zap/data", data, "", "POST", this.props.SaveTrigger)
                .then((response) => {
                    this.props.showSuccess(data, this.props.SaveTrigger);
                    // this.componentDidMount();
                    projectStore.savedComponent = true;
                })
                .catch((err) => {
                    throw err;
                });
        }
    );

    reactToPlaceHolder = reaction(
        () => toJS(this.props.SelectedPlaceholder),
        (placeholder, reaction) => {
            if (placeholder["selected"] === undefined) {
                return;
            }
            const { style, key } = placeholder["selected"];

            this.insertPlaceholder(style.default, key, style);
        }
    );

    insertPlaceholder = (label, text, style) => {
        let { placeholders, position } = TriggerAZapStore;

        if (!placeholders[position]) {
            placeholders[position] = [];
        }
        placeholders[position].push({ label, text, style });
        TriggerAZapStore.setPlaceholders(placeholders);
    };

    setComponentName = autorun(() => {
        TriggerAZapStore.setName(this.props.ComponentName);
    });

    changeTokenStatus = (status) => {
        this.setState({ hasToken: status });
        this.props.changeTokenStatus(status);
    };

    render() {
        let { placeholders } = TriggerAZapStore;

        if (this.state && !this.state.loading) {
            return (
                <Paper className="componentPaper" style={{ padding: "24px" }}>
                    <Grid container className="componentGrid" spacing={2}>
                        <Grid item xs={12}>
                            <b>Component ID</b>
                        </Grid>

                        <Grid item xs={12}>
                            <div>Copy and paste this Component ID into Zapier</div>
                            <TextField
                                disabled={true}
                                className={`key_padding ${this.props.isExpanded && "textFieldWebhook"}`}
                                variant="outlined"
                                fullWidth
                                value={this.props.component_id}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CopyToClipboard text={this.props.component_id}>
                                                <FileCopyIcon className="custom-icon-btn" />
                                            </CopyToClipboard>
                                        </InputAdornment>
                                    ),
                                }}
                                style={{ marginTop: "8px" }}
                            ></TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <b>Security Token</b>
                        </Grid>

                        <Grid item xs={12}>
                            <span> Generate a token and insert the value into Zapier </span>

                            {!this.state.hasToken ? (
                                <span className="err-text" style={{ justifyContent: "flex-start", fontWeight: "bold", margin: "16px 0" }}>
                                    <ErrorIcon className="iconDiscard" />A token must be generated before the Trigger a Zap component can be used.
                                </span>
                            ) : (
                                <span className="err-text" style={{ justifyContent: "flex-start", fontWeight: "bold", margin: "16px 0" }}>
                                    <ErrorIcon className="iconDiscard" />A token has already been generated. If you have lost this token, you will need to generate a new one.
                                </span>
                            )}

                            {!this.state.loading && (
                                <GenerateToken
                                    hasToken={(status) => {
                                        this.setState({ hasToken: status });
                                    }}
                                    tokenStatus={this.state.hasToken}
                                    name={toJS(this.props.ComponentName)}
                                    component_id={this.props.component_id}
                                    data={TriggerAZapStore.data}
                                    generateTokenUrl={"component-service/trigger_a_zap/generate-token"}
                                    deleteTokenUrl={"component-service/trigger_a_zap/delete-token"}
                                    componentType="trigger_a_zap"
                                />
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <DataPayloadToZap availablePlaceholders={this.props.availablePlaceholders} placeholders={placeholders} TriggerAZapStore={TriggerAZapStore} />
                        </Grid>
                    </Grid>
                </Paper>
            );
        } else return <IndividualComponentLoader />;
    }
}

export default inject("SelectedPlaceholder", "ComponentName", "ComponentDescription", "SaveTrigger")(observer(TriggerAZapTemplate));
