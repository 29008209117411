import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";

const StandardChip = (props) => {
  const useStyles = makeStyles(() =>
    createStyles({
      chip: {
        backgroundColor:
          props.backgroundColor || "rgba(33, 150, 243, 0.6) !important;",
        color: "white"
      },
      icon: {
        color: "white"
      }
    })
  );
  const classes = useStyles();
  return (
    <Chip
      clickable={false}
      {...props}
      classes={{ root: classes.chip, deleteIcon: classes.icon }}
    />
  );
};

export default StandardChip;
