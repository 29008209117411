import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import "../../../util/chatMessageAnimations.css";

const styles = () => ({
  instruction: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "0px 16px 0px 16px",
    size: "14px",
  },
});

const TestRunInstructionMessage = ({ classes }) => {
  return (
    <Grid className={`${classes.instruction} message-bubble`}>
      <p>
        <b>Start a test run</b>
      </p>
      <p>
        All components have been configured. You can start a test run of this
        workflow by selecting a component and clicking the start test button.
      </p>
    </Grid>
  );
};

TestRunInstructionMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TestRunInstructionMessage);
