import React from "react";

import { Paper, styled } from "@mui/material";
import Draggable from "react-draggable";

const StyledPaper = styled(Paper)(({ customStyles }) => ({
    width: "400px",
    padding: "16px",
    borderRadius: "6px",
    boxShadow: "4px 4px 0px 0px rgba(0, 0, 0, 0.25)",
    border: "1px solid rgba(184, 184, 184, 1)",
    zIndex: "9999 !important",
    ...customStyles // Pass custom styling
}));

const TopButtons = styled("div")({});

const BottomContent = styled("div")({
    maxHeight: "750px",
    overflow: "hidden",
});

function W86FloatingPane({ topButtons, content, defaultPosition, customStyles }) {

    return (
        <Draggable defaultPosition={defaultPosition ? defaultPosition : {}}>
            <StyledPaper elevation={5} customStyles={customStyles ? customStyles : {}}>
                <TopButtons>{topButtons}</TopButtons>
                <BottomContent>{content}</BottomContent>
            </StyledPaper>
        </Draggable>
    );
}

export default W86FloatingPane;
