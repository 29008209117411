import React, {Component} from 'react';
import {Grid, TextField} from "@mui/material";
import ApiExportStore from "../ApiExportStore";
import apiExportStore from "../ApiExportStore";
import {observer} from "mobx-react";
import Icon from "@mui/material/Icon";
import MentionEditor from '../../TextEditor/MentionEditor';
import ExclamationIcon from '../../Component/Icons/ExclamationIcon';
class CustomHeader extends Component {

  deleteHeaders = (headerId) => {
    ApiExportStore.data.headers = ApiExportStore.data.headers.filter(
      (h) => h.header_id !== headerId
    );
    this.props.validateRequest();
  }

  render() {
    return (
      <Grid>
        <Grid xs={12} container spacing={3} direction="row">
          <Grid item xs={5}>
            <p>Key</p>
          </Grid>
          <Grid item xs={5}>
            <p className="textRightLabelJson">
              Value
            </p>
          </Grid>
        </Grid>
        {ApiExportStore.data.headers.map((h,index) => (
          <Grid
            container
            direction="row"
            style={{
              color: "black !important"
            }}
          >
            <Grid item xs={5}>
              <MentionEditor
                style={{
                  color: "black"
                }}
                text={h.header_name}
                availablePlaceholders={this.props.availablePlaceholders}
                onFocus={() => {
                  this.props.onFocus({
                    headerType: "key",
                    index
                  });
                }}
                shouldChange={this.props.indexChanged}
                placeholder="Key"
                placeholders={this.props.keyPlaceholders[index]}
                variant="outlined"
                className="textfieldPaddingJson key_padding"
                onChange={(value) => {
                  ApiExportStore.data.headers[index].header_name = value;
                  this.props.validateRequest();
                }}
                error={h.header_name.trim() == "" && this.props.headerError ? true : false}
                helperText={h.header_name.trim() == "" && this.props.headerError ? "Key cannot be empty" : null}
              />
            </Grid>
            <Grid item xs={5} style={{ color: "#868686" }}>
              <MentionEditor
                style={{
                  color: "black",
                }}
                text={h.header_value}
                availablePlaceholders={this.props.availablePlaceholders}
                placeholder="Value"
                
                className="textfieldPaddingJson key_padding"
                onChange={(value) => {
                  // h.header_value = value;
                  ApiExportStore.data.headers[index].header_value = value;
                  this.props.validateRequest();
                }}
                onFocus={() => {
                  this.props.onFocus({
                    headerType: "value",
                    index
                  });
                }}
                error={h.header_value.trim() == ""  && this.props.headerError  ? true : false}
                placeholders={this.props.valuePlaceholders[index]}
                InputProps={{
                  endAdornment: h.header_value.trim() == "" && (
                    <ExclamationIcon/>
                  ),
                  borderStyle: "solid",
                  borderColor: "red"
                }}
                helperText={h.header_value.trim() == ""  && this.props.headerError  ? "Value cannot be empty" : null}
              />
            </Grid>
            <Grid item xs={1}>
              <i
                onClick={(event) => {
                  this.deleteHeaders(h.header_id);
                }}
                className="material-icons custom-icon-btn"
                style={{marginTop: 8}}
              >
                delete
              </i>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default observer(CustomHeader);