import PropTypes from "prop-types";

const AIBuilderSidebarPropTypes = {
  open: PropTypes.bool.isRequired, //A boolean value indicating whether the Drawer is open.
  close: PropTypes.func.isRequired, // A function to close the Drawer.
  projectId: PropTypes.string.isRequired, //A string representing the project ID.
  nodes: PropTypes.array.isRequired, //An array containing the nodes elements.
  setNodes: PropTypes.func.isRequired, //A function to set new nodes elements.
  edges: PropTypes.array.isRequired, //An array containing the edges elements.
  setEdges: PropTypes.func.isRequired, //A function to set new edges elements.
  reactFlowInstance: PropTypes.object.isRequired, //An object representing the React Flow instance
  classes: PropTypes.object.isRequired, //An object containing the CSS classes for the component.
  setSaving: PropTypes.func.isRequired, //A function to set the saving state.
  setOpenSessionHistory: PropTypes.func.isRequired, //A function to set the openSessionHistory state.
  setSelectedHistorySession: PropTypes.func.isRequired, //A function to set the selectedHistorySession state.
  selectedHistorySession: PropTypes.object.isRequired, //An object representing the selectedHistorySession state.
  openSessionHistory: PropTypes.bool.isRequired, //A boolean value indicating whether the session history is open.
  handleSaveProjectName: PropTypes.func.isRequired, //A function to save the project name.
  setWorkflowName: PropTypes.func.isRequired, //A function to set the workflow name.
  setResetHistory: PropTypes.func.isRequired, //A function to set the resetHistory state.
  openStartToEnd: PropTypes.bool.isRequired, //A boolean value indicating whether the startToEnd is open.
  triggerCloseInChild:PropTypes.bool,
  setTriggerCloseInChild:PropTypes.func,
  editComponent: PropTypes.object,
  setPaneSkeleton: PropTypes.func,


};

export default AIBuilderSidebarPropTypes;
