const styles = () => ({
  contentContainer: {
    padding: "32px 32px 0px 32px",
    overflow: "auto"
  },
  zapTitle: {
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    fontWeight: 400
  },
  zapHelperText: {
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    fontWeight: 400,
    color: "#5C5C5C",
    margin: "24px 0px"
  },
  zapInputLabel: {
    fontSize: "14px",
    lineHeight: "20px"
  },
  zapInput: {
    marginTop: "3px",
    marginBottom: "8px"
  },
  loadingScreen: {
    padding: "32px"
  },
  loadingScreenItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  loadingScreenText: {
    fontSize: "18px",
    lineHeight: "26px",
    color: "#000"
  },
  addAnotherButton: {
    fontSize: "14px",
    marginTop: "8px",
    marginBottom: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.5)"
    }
  },
  actionDeleteButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cancelIcon: {
    color: "#868686"
  }
});
export default styles;
