import { computed, observable, decorate, action, toJS } from "mobx";
import uuid from "uuid";
import {getColourFromString} from "../../vardogyir-ui/randomColorGenerator";

class ConditionalWorkflowPathStore {

    constructor() {
        this.initialiseEmpty();
    }

    setConditionalWorkflowTemplate = (conditionalWorkflowTemplate) => {
        this.type = conditionalWorkflowTemplate.type
        this.name = conditionalWorkflowTemplate.name;

        for(let dat in conditionalWorkflowTemplate.data)
        { // this means it is generated by ai
            if (!conditionalWorkflowTemplate.data[dat].hasOwnProperty("statement"))
            {
                conditionalWorkflowTemplate.data[dat].color = getColourFromString();
                conditionalWorkflowTemplate.data[dat].statement= {
                        type: "IF",
                        content: {
                            condition: [
                                {
                                    operators: [
                                        {
                                            logic: "undefined",
                                            key: "",
                                            function: "EQUAL",
                                            value: ""
                                        }
                                    ],
                                    then: {
                                        statement: {
                                            type: "VALUE",
                                            content: "CONTINUE"
                                        }
                                    }
                                },
                                {
                                    then: {
                                        statement: {
                                            type: "VALUE",
                                            content: "STOP"
                                        }
                                    }
                                }
                            ]
                        }
                    }

            }

        }

        this.data = conditionalWorkflowTemplate.data;
        this.lastModified = conditionalWorkflowTemplate.lastModified;
    }

    setConditionalWorkflowName = (ConditionalWorkflowName) => {
        this.name = ConditionalWorkflowName;
    }

    setLastModified = (lastModified) => {
        this.lastModified = lastModified;
    }

    setCondition = (content, indexLogic, indexStatement, indexCondition, type) => {
        switch (type) {
            case 'logic':
                this.data[indexLogic].statement.content
                    .condition[indexStatement]
                    .operators[indexCondition]
                    .logic = content;
                return;
            case 'key':
                this.data[indexLogic].statement.content
                    .condition[indexStatement]
                    .operators[indexCondition]
                    .key = content ? content.trim() : "";
                return;
            case 'pathName':
                this.data[indexLogic].statement.content
                    .condition[indexStatement]
                    .operators[indexCondition]
                    .pathName = content ? content.trim() : "";
                return;
            case 'function':
                this.data[indexLogic].statement.content
                    .condition[indexStatement]
                    .operators[indexCondition]
                    .function = content;
                return;
            case 'value':
                this.data[indexLogic].statement.content
                    .condition[indexStatement]
                    .operators[indexCondition]
                    .value = content;
                return;
        }
    }

    setThenContent = (content, indexLogic, indexStatement) => {
        this.data[indexLogic].statement.content
            .condition[indexStatement]
            .then.statement.content = content;
    }
    addStatement = (indexLogic) => {
        const obj = {
            operators: [
                {
                    logic: 'OR',
                    key: '',
                    function: '',
                    value: ''
                }
            ],
            then: {
                statement: {
                    type: "VALUE",
                    content: ''
                }
            }
        }
        const { condition } = this.data[indexLogic].statement.content
        this.data[indexLogic].statement.content
            .condition
            .splice(condition.length - 1, 0, obj)
    }

    addCondition = (indexLogic, indexStatement) => {
        const obj = {
            logic: 'OR',
            key: '',
            function: 'EQUAL',
            value: ''
        }

        let data = toJS(this.data[indexLogic]);
        data.statement.content.condition[indexStatement].operators.push(obj);

        this.data[indexLogic] = data;
    }

    setLogic = (content, index, type) => {
        switch (type) {
            case 'key':
                this.data[index].key = content;
                return;
            case 'outputType':
                this.data[index].outputType = content;
                return;
            case 'pathName':
                this.data[index].pathName=content;
                return;
        }
    }

    addLogic = () => {
        const obj = this.logicData();
        this.data.push(obj);
    }

    removeLogic = (index) => {
        this.data.splice(index, 1)
    }
    copyThisLogic = (index) => {
        let copyLogic = this.data[index];
        copyLogic = { ...copyLogic, key: this.keyAuto(),comPId: uuid() ,pathName: this.generatePathName(),
            color:getColourFromString()};
        this.data.push(copyLogic);
    }
    removeCondition = (indexLogic, indexStatement, indexCondition) => {
        this.data[indexLogic].statement.content
            .condition[indexStatement]
            .operators
            .splice(indexCondition, 1)
    }

    removeStatement = (indexLogic, indexStatement) => {
        this.data[indexLogic].statement.content
            .condition
            .splice(indexStatement, 1)
    }
    keyAuto = () => {
        return "conditional" + (Math.floor(Math.random() * 90000000) + 10000).toString();
    }
    generatePathName =()=>{
        return "path" + (Math.floor(Math.random() * 90000000) + 10000).toString();

    }
    logicData = () => {
        return {
            key: this.keyAuto(),
            comPId: uuid(),
            pathName: this.generatePathName(),
            color:getColourFromString(),
            outputType: "TEXT",
            statement: {
                type: "IF",
                content: {
                    condition: [
                        {
                            operators: [
                                {
                                    logic: "undefined",
                                    key: "",
                                    function: "EQUAL",
                                    value: ""
                                }
                            ],
                            then: {
                                statement: {
                                    type: "VALUE",
                                    content: "CONTINUE"
                                }
                            }
                        },
                        {
                            then: {
                                statement: {
                                    type: "VALUE",
                                    content: "STOP"
                                }
                            }
                        }
                    ]
                }
            }
        }
    }

    setPlaceholders = (placeholders) => {
        this.placeholders = placeholders;
    }

    setPositions = (positions) => {
        this.placeholders = [];
        this.positions = positions;
    }

    setData = (data) => {
        this.data = data;
    }


    initialiseEmpty = () => {
        const obj = this.logicData()
        this.name = "";
        this.type = "conditional_workflow_path";
        this.lastModified = "";
        this.data = [obj];
        this.placeholders = [];
        this.positions = {
            indexLogic: null,
            indexStatement: null,
            indexCondition: null,
            type: null
        };
    }

    get template() {
        return {
            name: this.name,
            data: toJS(this.data),
            type: this.type,
            lastModified: this.lastModified,
            placeholders: toJS(this.placeholders),
            positions: toJS(this.positions)
        }
    }
}


decorate(ConditionalWorkflowPathStore, {
    name: observable,
    data: observable,
    type: observable,
    error: observable,
    placeholders: observable,
    positions: observable,
    setPositions: action,
    setPlaceholders: action,
    // setConditionalLogicTemplate: action,
    // setConditionalLogicName: action,
    // setCondition: action,
    // setLogic: action,
    // setThenContent: action,
    // addStatement: action,
    // addLogic: action,
    removeLogic: action,
    removeCondition: action,
    removeStatement: action,
    initialiseEmpty: action,
    setData:action,
    template: computed
})

const conditionalWorkflowPathStore = new ConditionalWorkflowPathStore();
export default conditionalWorkflowPathStore;