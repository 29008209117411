import React from "react";

// util
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

const newStyles = {
    variables: {
        light: {
            diffViewerBackground: "#fff",
            diffViewerColor: "#212529",
            addedBackground: "#e6ffed",
            addedColor: "#24292e",
            removedBackground: "#ffeef0",
            removedColor: "#24292e",
            wordAddedBackground: "#acf2bd",
            wordRemovedBackground: "#fdb8c0",
            addedGutterBackground: "#cdffd8",
            removedGutterBackground: "#ffdce0",
            gutterBackground: "#fff",
            gutterBackgroundDark: "#f3f1f1",
            highlightBackground: "#fffbdd",
            highlightGutterBackground: "#fff5b1",
            codeFoldGutterBackground: "#dbedff",
            codeFoldBackground: "#fff",
            emptyLineBackground: "#fff",
            gutterColor: "#212529",
            addedGutterColor: "#212529",
            removedGutterColor: "#212529",
            codeFoldContentColor: "#212529",
            diffViewerTitleBackground: "#fafbfc",
            diffViewerTitleColor: "#212529",
            diffViewerTitleBorderColor: "#eee",
        },
    },
    codeFoldGutter: {
        background: "#FFF",
    },
    diffContainer: {
        border: "1px solid rgba(218, 218, 218, 1)",
        borderRadius: "10px",
        display: "block",
        padding: "24px 8px",
    },
};

function Schema({ jsonOne, jsonTwo, view, compareLoad, StyledSkeleton }) {
    const showSplitView = view === "split_view" ? true : false;
    return compareLoad ? (
        <StyledSkeleton variant="rectangular" height="1000px" width="100%" />
    ) : (
        <ReactDiffViewer
            styles={newStyles}
            expandAll={true}
            compareMethod={DiffMethod.WORDS}
            oldValue={JSON.stringify(jsonOne, null, 2)}
            newValue={JSON.stringify(jsonTwo, null, 2)}
            splitView={showSplitView}
            showDiffOnly={false}
        />
    );
}

export default Schema;
