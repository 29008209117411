import React from "react";
//mui
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import "../../../util/chatMessageAnimations.css";

const styles = () => ({
  error: {
    maxWidth: "100%!important",
    background: "rgba(225, 0, 0, 0.15)",
    border: "2px solid #E10000",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
  },
});

const Error = ({ classes, content }) => {
  return (
    <Grid className={`${classes.error} message-bubble`}>
      {content}
    </Grid>
  );
};

export default withStyles(styles)(Error);
