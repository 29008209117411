import React, { useCallback } from "react";

// Util
import { getEdgeParams } from "../../../../../utils/CanvasUtil";
import { useStore, getBezierPath } from "reactflow";

// MobX
import { toJS } from "mobx";

const FloatingLink = ({ id, source, target, style, markerEnd }) => {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    if (!sourceNode || !targetNode) return null;

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode.position, targetNode.position);

    const edgePath = getBezierPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
    });

    if (!edgePath || edgePath.length <= 0) return null;
    return <path id={id} className="react-flow__edge-path" d={edgePath[0]} markerEnd={markerEnd} style={toJS(style)} />;
};

export default FloatingLink;
