import React, {Component} from 'react';
import {Grid} from "@mui/material";
import MentionEditor from "../TextEditor/MentionEditor";
import {toJS} from "mobx";
import updateDatabaseRecordStore from "./UpdateDatabaseRecordStore";
import TextIcon from "../Component/Icons/TextIcon";
import NumberIcon from "../Component/Icons/NumberIcon";
import BooleanIcon from "../Component/Icons/BooleanIcon";
import DatetimeIcon from "../Component/Icons/DatetimeIcon";
import ListIcon from '../Component/Icons/ListIcon';
import {observer} from "mobx-react";
import FormControlLabel from "@mui/material/FormControlLabel";

class ItemCell extends Component {

  updateColumn = (index, value, columns) => {
    let aData = columns[index];
    aData.content = value;
    updateDatabaseRecordStore.setData(columns)
  };

  getColumnType = (type) => {
    switch (type) {
      case "VARCHAR2" :
        return <TextIcon />
      case "DECIMAL" :
        return <NumberIcon/>
      case "BOOLEAN" :
        return <BooleanIcon />
      case "DATETIME" :
        return <DatetimeIcon />
      case "LIST" :
        return <ListIcon />
    }
  }

  render() {
    let {column,availablePlaceholders,index,columns} = this.props
    let {placeholders,position} = updateDatabaseRecordStore
    placeholders = toJS(placeholders)
    let keyArray = []
    if (position == index){
      keyArray = placeholders
    }

    return (
      <Grid item xs={12} style={{margin:"4px 0px 4px 8px"}}>
        <Grid item xs={12} style={{marginBottom:8,display:"flex",alignItems: "center"}}>
          <Grid style={{marginRight: 8}}>
            {this.getColumnType(column.type)}
          </Grid>
          {column.columnName}
        </Grid>
        <MentionEditor
          style={{ width: "100%",marginBottom: 16, verticalAlign: "top" }}
          availablePlaceholders={availablePlaceholders}
          placeholders={keyArray}
          text={column.content}
          placeholder="Type something or insert a placeholder"
          onChange={value => {
            this.updateColumn(index,value,columns)
          }}
          onFocus={(e) => {
            updateDatabaseRecordStore.setFocus(index,"placeholder")
          }}
        />
      </Grid>
    );
  }
}

export default observer(ItemCell);