import React, { useState, useEffect } from "react";
import { Provider } from "mobx-react";
import { Route, Routes, useParams } from "react-router-dom";
import { signIn, signOut } from 'aws-amplify/auth';

// Custom components
import InternalApp from "./InternalApp";
import { CustomSignIn } from "./CustomSignIn";
import PaymentSuccess from "./PaymentSuccess";
import CanvasPreview from "../ProjectCanvas/Canvas/CanvasPreview";
import MarketplaceTemplate from "../Marketplace/MarketplaceTemplate";

// Stores
import PreviewStore from "../ProjectCanvas/PreviewStore";
import CanvasStore from "../pages/Canvas/CanvasStore";

function AuthWrapper({ authState, onStateChange, user }) {
  const [username, setUsername] = useState("");
  const [firstLogin, setFirstLogin] = useState(false);

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  const updateUsername = (newUsername) => {
    setUsername(newUsername);
  };

  const handleSignIn = async (username, password) => {
    try {
      const user = await signIn({ username, password });
      onStateChange("signedIn", user);
    } catch (error) {
      console.error("Error signing in:", error);
      // Handle sign-in error (e.g., show error message)
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      onStateChange("signIn", null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const loggedIn = authState === "signedIn";

  return (
    <Provider PreviewStore={PreviewStore} CanvasStore={CanvasStore}>
      <Routes>
        <Route path="/template/:id" element={<MarketplaceTemplate />} />
        <Route
          path="/project/canvas_preview/:id"
          element={<CanvasPreview />}
        />
        {!loggedIn ? (
          <Route
            path="/*"
            element={
              <CustomSignIn
                authState={authState}
                updateUsername={updateUsername}
                onSignIn={handleSignIn}
                setFirstLogin={setFirstLogin}
                onStateChange={onStateChange}
                firstLogin={(status) =>
                 setFirstLogin(status)
                }
              />
            }
          />
        ) : (
          <>
            <Route
              path="/*"
              element={
                <InternalApp
                  authState={authState}
                  updateUsername={updateUsername}
                  onSignOut={handleSignOut}
                  dialogTour={firstLogin}
                  setFirstLoginState={(status) => 
                    setFirstLogin(status)
                  }
                  onStateChange={onStateChange}
                />
              }
            />
            <Route path="/payment_success" element={<PaymentSuccess />} />
          </>
        )}
      </Routes>
    </Provider>
  );
}

export default AuthWrapper;
