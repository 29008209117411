import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";

const styles = theme => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  textroot: {
    width: "100%",
    margin: "auto"
  }
});

class FreeText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: ""
    };
  }

  render() {
    const { classes } = this.props;
    const theme = createTheme({
      palette: {
        primary: { 500: "#2196F3" }
      },
      typography: { useNextVariants: true }
    });
    try {
      return (
        <div className={classes.root}>
              <TextField
                multiline
                rows="4"
                className={classes.textroot}
                InputProps={{
                  className: classes.input
                }}
                variant="outlined"
              />
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

FreeText.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FreeText);
