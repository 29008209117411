import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { useNavigate, useParams } from "react-router";
import WorkflowTestToolbar from "../WorkflowTest/Toolbar";
import { send_request, send_request_graphql } from "../../utils/Request";
import { GET_WORKFLOW } from "./Canvas/CanvasQueries";
import { CustomDialog } from "../Component/Dialog";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import config from "../../config";
import NonFormTriggerSkeleton from "../WorkflowTest/NonFormTriggers/NonFormTriggerSkeleton";
import { ArrowBack, ErrorRounded } from "@mui/icons-material";
import "./Canvas/Canvas.css"
import { Link } from "react-router-dom";
import TestIframeRender from "../WorkflowTest/TestIframeRender";

const MainContainer = styled(Grid)({
  padding: "1rem",
  paddingBottom: "5rem"
});


const WorkflowTestPage = () => {
  const params = useParams();
  const projectId = params.project_id;
  const component_id= params.component_id;
  const version = window.version;
  const projectName = window.projectName;
  const lastModified = window.lastModified;
  // For toolbar
  const [component,setComponent] = useState(null);
  const [tests, setTests] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  // Dialogs
  const [openEditName, setOpenEditName] = useState(false);
  const [editName, setEditName] = useState("");
  const [doneLoading, setDoneLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [project, setProject] = useState(null);

  const navigate = useNavigate();

  useEffect(async () => {
     await send_request(
        "project-service/project/component/" + component_id,
        "",
        {},
        "get"
    ).then((res) => {
      setComponent(res.data);
    });
     if(projectName==null)
     {
       await send_request_graphql(
           `project-service/graphql/project/${projectId}`,
           GET_WORKFLOW(projectId)
       ).then((res) => {
         setProject(res.data.projectById);
       });
     }

  }, []);

  const handleRefreshClick = async () => {
    setRefreshing(true);

    await send_request_graphql(
      `project-service/graphql/project/${projectId}`,
      GET_WORKFLOW(projectId)
    ).then((res) => {
      setProject(res.data.projectById);
    });

    await send_request(
      `project-service/test/get_tests/${projectId}`,
      "",
      "",
      "GET"
    ).then((res) => {
      setTests(res.data);
    });

    setRefreshing(false);
  };

  /**
   * Changes name of the test
   */
  const handleEditNameClick = () => {
    setOpenEditName(true);
    if (tests.length !== 0) {
      setEditName(tests[0].name);
    }
  };

  /**
   * Used to change the name of the test
   */
  const handleEditDoneClick = async () => {
    setDoneLoading(true);

    if (tests.length === 0) {
      await send_request(
        `project-service/test/create_test/${projectId}`,
        {
          name: editName,
        },
        "",
        "POST"
      ).then((res) => {
        setTests([res.data]);
      });
    } else {
      await send_request(
        `project-service/test/update_test/${tests[0].testId}`,
        {
          name: editName,
        },
        "",
        "PUT"
      ).then((res) => {
        const test = { ...tests[0], name: editName };
        setTests([test]);
      });
    }

    setDoneLoading(false);
    setOpenEditName(false);
  };

  const handleCancelClick = () => {
    setOpenEditName(false);
  };

  const handleSaveClick = () => {
    setShowSnackbar(true);
  };

  const handleBack = () => {
    navigate("/project/canvas/" + projectId)
  }



  return (
    <MainContainer container xs={12} direction="column">
      {component === null || refreshing ? (
        <>
          <Skeleton
            variant="rectangular"
            height={40}
            width="35vw"
            style={{
              marginBottom: "2rem",
              borderRadius: "4px",
              background: "#E8E8E8"
            }} />
          <Skeleton
            variant="rectangular"
            height={200}
            width="100%"
            style={{
              marginBottom: "2rem",
              borderRadius: "4px",
              background: "#E8E8E8"
            }}
          />
          <Skeleton
            variant="rectangular"
            height="50vh"
            width="100%"
            style={{
              marginBottom: "2rem",
              borderRadius: "4px",
              background: "#E8E8E8"
            }}
          />
        </>
      ) : (
        <>
          <Grid container xs={12} alignItems="center">
            <ArrowBack
              onClick={handleBack}
              className="arrow_back"
              style={{
                marginRight: "0.5rem"
              }}
            />
            <h4>Test from Trigger</h4>
          </Grid>
          <WorkflowTestToolbar
            tests={tests}
            version={version?version:project!==null ?project.version:""}
            projectName={projectName?projectName:project!==null ?project.name:""}
            lastModified={lastModified?lastModified:project!==null ? project.lastModified:""}
            projectId={projectId}
            handleEditNameClick={handleEditNameClick}
            handleRefreshClick={handleRefreshClick}
            handleSaveClick={handleSaveClick}
          />
          <TestIframeRender version={version}
                            projectId={projectId}
                            componentId={component_id}
                            compData={component}
                            expanded={true}
                            tests={tests}
                            setTests={setTests}
          />
          <CustomDialog
            isOpen={openEditName}
            title="Edit test name"
            buttons={
              <>
                <Button
                  variant="outlined"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleEditDoneClick}
                >
                  {doneLoading ? <CircularProgress size={14} /> : "Done"}
                </Button>
              </>
            }
            contents={
              <Grid
                container
                xs={12}
                direction="column"
                alignItems="flex-start"
              >
                <p>Test Name</p>
                <TextField
                  value={editName}
                  fullWidth
                  onChange={(e) => setEditName(e.target.value)}
                />
              </Grid>
            }
          />
          <Snackbar
            open={showSnackbar}
            onClose={() => setShowSnackbar(false)}
            autoHideDuration={1000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={() => setShowSnackbar(false)}
              variant="filled"
              severity="success"
              className={"saveSuccess"}
              icon={<></>}
            >
              👍 Test saved
            </Alert>
          </Snackbar>
        </>
      )}
    </MainContainer>
  );
};

export default WorkflowTestPage;
