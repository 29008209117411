import uuid from "uuid";
import { send_request } from "../../utils/Request";
import { ComponentFactory } from "./ComponentRegistry";
import { computed, observable, decorate, action, toJS } from "mobx";
import { CollectionsOutlined } from "@mui/icons-material";
import { getColourFromString } from "../../utils/ColourNameGenerator";

class ProjectStore {
  constructor() {
    this.clear();
    this.initialiseTour();
    this.state = {
      zoom_level: 85,
      active_diagram: {},
      timezone: "Australia/Hobart",
      locked: true,
      tier: "FREE",
      hasBanner: true,
      orgName: "",
      userName: "",
      diagramEngine: {},
      workflowCategoriesMapping: {},
      workflowCategories: [],
      allowCanvasTranslation: true,
      production: false,
      canvasView: false,
      dbIds: [],
      clientId:null,
      isAIEnabled: false,
    };
    //we have to move save trigger for components into the store
    //as functional components run through once and cannot have properly working attached observable values
    //instead observables get re-declared every functional component execution
    this.SaveTrigger = observable({ triggerValue: 1 });
    this.ComponentName = observable.box(""); //mobx itself tells you to .box a string
    this.ComponentDescription = observable.box("");
    this.targetDataBaseId = observable.box("");
    this.SelectedPlaceholder = observable({ selected: "" });
  }

  clearObservs = () => {
    this.SaveTrigger = observable({ triggerValue: 1 });
    this.ComponentName = observable.box(""); //mobx itself tells you to .box a string
    this.ComponentDescription = observable.box("");
    this.targetDataBaseId = observable.box(""); //mobx itself tells you to .box a string
    this.SelectedPlaceholder = observable({ selected: "" });
  };

  closeWelcomePageTour = () => {
    this.isWelcomePageTour = false;
  };

  openWelcomePageTour = () => {
    this.isWelcomePageTour = true;
  };

  closeProjectPageTour = () => {
    this.isProjectPageTour = false;
  };

  openProjectPageTour = () => {
    this.isProjectPageTour = true;
  };

  closeCanvasPageTour = () => {
    this.isCanvasPageTour = false;
  };

  openCanvasPageTour = () => {
    this.isCanvasPageTour = true;
  };
  setShowReadmeDialog = (currentStep, readmeText) => {
    if (currentStep > 0) {
      this.showReadmeDialog = false;
    } else {
      this.showReadmeDialog = true;
    }
  };

  closeRepositoryPageTour = () => {
    this.isRepositoryPageTour = false;
  };

  openRepositoryPageTour = () => {
    this.isRepositoryPageTour = true;
  };

  closeMarketplacePageTour = () => {
    this.isMarketplacePageTour = false;
  };

  openMarketplacePageTour = () => {
    this.isMarketplacePageTour = true;
  };

  closePortalPageTour = () => {
    this.isPortalPageTour = false;
  };

  openPortalPageTour = () => {
    this.isPortalPageTour = true;
  };

  closeCredentialPageTour = () => {
    this.isCredentialPageTour = false;
  };

  openCredentialPageTour = () => {
    this.isCredentialPageTour = true;
  };

  setWorkflow = (workflow) => {
    this.project_id = workflow.projectId;
    this.project_name = workflow.name;
    this.last_modified = workflow.lastModified;
    this.project_description = workflow.description;
    if (workflow.UIOnlyIconPos) {
      this.UIOnlyIconPos = workflow.UIOnlyIconPos;
    }

    if (workflow.components) {
      this.components = workflow.components;
    } else {
      this.components = [];
      this.setCanvas([]);
    }

    this.imagePath = workflow.imagePath;
    this.projectStatus = workflow.status;
    this.errors = [];
    this.error = false;
  };

  setCanvas = (canvas) => {
    this.canvas = canvas;
  };

  getCanvas = () => {
    return this.canvas;
  };

  getNodes = () => {
    return this.nodes;
  }

  setNodes = () => {
    this.nodes = nodes;
  }

  getEdges = () => {
    return this.getEdges;
  }

  setEdges = () => {
    this.edges = edges;
  }

  //create a react-flow custom node
  createNode = (component, template, isAiGenerated) => {
    let isFromConditionalPath=false;
    let color=getColourFromString();

    if (component.componentData !== undefined && component.componentData !== null && component.componentData.isFromConditionalPath) {
      isFromConditionalPath=component.componentData.isFromConditionalPath
    }
    if (component.componentData !== undefined && component.componentData !== null && component.componentData.color) {
      color = component.componentData.color;
    }

    return {
      id: component.componentId,
      type: "component",
      data: {
        error: component.error,
        logo: template.logo,
        hasInput: template.hasInput ? template.hasInput : false,
        hasOutput: template.hasOutput ? template.hasOutput : false,
        type: component.type,
        displayName: template.displayName,
        next: component.nextComponents,
        label: component.componentData
          ? component.componentData.name
          : component.generatedTitle
          ? component.generatedTitle
          : template.displayName,
        baseColor: template.baseColor,
        description:component.description,
        instructions:component.instructions,
        isTemp: isAiGenerated,
        color:color,
        fromWorkflowPath:isFromConditionalPath// set if only for workflow  path generated via conditional workflows,
      },
      position: { x: component.viewData.x, y: component.viewData.y },
    };
  };
  createDBNode = (dbId, template, component, compData) => {
    let viewDataDetails = {
      x: 100,
      y: 100,
    };
    if (component.viewData != null) {
      viewDataDetails = {
        x: component.viewData.x + 300,
        y: component.viewData.y + 100,
      };
    } else if (compData != null) {
      viewDataDetails = {
        x: compData.position.x + 300,
        y: compData.position.y + 100,
      };
    }
    if (this.UIOnlyIconPos != null) {
      let dbDetail = this.UIOnlyIconPos.find((x) => x.nodeId === dbId);
      viewDataDetails = dbDetail ? dbDetail["viewData"] : viewDataDetails;
    }
    return {
      id: dbId,
      type: "dbNode",
      data: {
        logo: template.logo,
        hasDb: true,
        displayName: template.displayName,
        label: template.displayName,
        baseColor: template.baseColor,
        type: "UI.dbIcon",
        newTabUrl: template.newTabUrl,
      },
      position: {
        x: viewDataDetails.x,
        y: viewDataDetails.y,
      },
    };
  };

  //create a react-flow custom edge
  createLink = (type,fromWorkflowPath, newNodeId, nextId, baseColor) => {
    return {
      id: uuid(),
      source: newNodeId,
      target: nextId,
      markerEnd: {
        type: type === "conditional_workflow_path" ? "" : "arrowclosed",
        color: "#868686",
      },
      type: "link",
      label:"componentLink",
      data: { showDeleteButton: type === "conditional_workflow_path" },
      style: {
        strokeWidth: "3px",
        stroke: fromWorkflowPath ? "#868686" : baseColor,
      },
    };
  };
  
  createDBLink = (newNodeId, nextId, baseColor, label) => {
    return {
      id: uuid(),
      source: newNodeId,
      target: nextId,
      markerEnd: {
        type: "arrowclosed",
        color: "#868686",
      },
      type: "floatingLink",
      data: { hasDb: true },
      label: label,
      style: {
        strokeWidth: "3px",
        stroke: baseColor,
        strokeDasharray: "3",
        test: "testLabel",
        strokeOpacity: "10%",
      },
    };
  };
  
  //temporary solution until qraphql schema has been adjusted
  readNotes = (notes) => {
    let noteIds = notes
      .map((item) => {
        if (item.data) return item.id;
      })
      .filter((a) => a != undefined);
    let result = [];
    if (notes && notes.length != 0) {
      notes.map((note, id) => {
        //remove drag handle for older notes
        if (note.dragHandle) delete note.dragHandle;

        if (note.type == "note") {
          delete note.style;
          delete note.source;
          delete note.sourceHandle;
          delete note.target;
          delete note.targetHandle;
          delete note.arrowHeadType;
        } else if (note.type == "link") {
          delete note.position;
          
          // Set note.data.label and remove anything else in data
          note.data = {
            label: note.data ? note.data.label : ""
          }
        }
      });
      //get read of links that don't belong to any note
      result = notes.filter((item) => {
        if (item.type === "note") {
          return item;
        } else if (
          noteIds.indexOf(item.source) !== -1 &&
          noteIds.indexOf(item.target) !== -1
        ) {
          return item;
        }
      });
    }

    return result;
  };
  
  createDbNodeLogic = (component, dbIds, diagram, dbId, compData) => {
    if (!new Set(dbIds.values()).has(dbId)) {
      const dbNode = this.createDBNode(
        dbId,
        ComponentFactory["UI.dbIcon"],
        component,
        compData
      );
      diagram.push(dbNode);
    }
    let newLink = "";
    if (component.type === "query_database_record") {
      newLink = this.createDBLink(
        dbId,
        component.componentId,

        "#1C1B1F",
        "link"
      );
    } else {
      newLink = this.createDBLink(
        component.componentId,
        dbId,
        "#1C1B1F",
        "link"
      );
    }
    dbIds.set(component.componentId, dbId);
    diagram.push(newLink);
  };

  //this turns stored data into a react-flow diagram
  readDiagram = (components, isAiGenerated) => {
    let diagram = [];
    let dbIds = new Map([]);
    let compNextMap = new Map([]);

    if (components) {
      components.map((component, id) => {
        if (ComponentFactory[component.type] !== undefined) {
          //create nodes
          const newNode = this.createNode(
            component,
            ComponentFactory[component.type],
            isAiGenerated
          );

          if (
            component.componentData != null &&
            (component.componentData.targetDatabaseId != null ||
              (component.componentData.data != null && component.componentData.data.length > 0 &&
                component.componentData.data[0].targetDatabaseId != null))
          ) {
            /**
             * create db node
             */
            let dbId = component.componentData.targetDatabaseId
              ? component.componentData.targetDatabaseId
              : component.componentData.data[0].targetDatabaseId;

            this.createDbNodeLogic(component, dbIds, diagram, dbId);
            newNode.data.hasDb = true;
          }

          diagram.push(newNode);

          if (newNode.data.next && newNode.data.next.length != 0) {
            //create links
            newNode.data.next.map((next, nextId) => {
              const newLink = this.createLink(
                newNode.data.type,
                newNode.data.fromWorkflowPath,
                newNode.id,
                next.componentId,
                newNode.data.baseColor,
              );

              if(newNode.data.type==="conditional_workflow_path")
              {
                compNextMap.set(next.componentId,newNode.id);
              }
              if (newNode.id && next.componentId) diagram.push(newLink);
            });
          }
        }
      });
    }
    compNextMap.forEach((value, key) => {

      let conditional_workflow = diagram.find((obj) => obj.id === key);
      if (conditional_workflow) {
        conditional_workflow.data.parentId=value;
      }   


    })
    this.state.dbIds = dbIds;
    return diagram;
  };

  findLinks = (canvas, sourceId) => {
    let next = canvas.filter((link, id) => {
      if (link.type == "link" && link.sourceHandle == null) {
        return link.source == sourceId;
      }
    });
    return next.map((nextItem, id) => {
      return {
        componentId: nextItem.target,
        nodeId: nextItem.target,
      };
    });
  };

  removeDuplicateLinks = (nextComponents) => {
    return nextComponents.reduce((unique, o) => {
      if (
        !unique.some(
          (obj) => obj.componentId === o.componentId && obj.nodeId === o.nodeId
        )
      ) {
        unique.push(o);
      }
      return unique;
    }, []);
  };

  removeUnknownComponents = (nodes, nextComponents) => {
    let nodeIds = nodes.map((node) => {
      if (node.type == "component" && node.id != undefined) return node.id;
    });
    let result = nextComponents;
    for (var i = 0; i < nextComponents.length; i++) {
      if (nodeIds.indexOf(nextComponents[i].componentId) == -1) {
        //this next component needs removing
        result = result.filter((item) => {
          return nextComponents[i].componentId != item.componentId;
        });
      }
    }
    nextComponents = result;
    return nextComponents;
  };

  readCanvasIntoComponents = (canvas) => {
    let result = [];
    canvas.map((canvasItem) => {
      if (canvasItem.type == "component" || canvasItem.type == "dbNode") {
        let positionRecord = {
          componentId: canvasItem.id,
          type: canvasItem.data.type,
          viewData: canvasItem.position,
          componentData: {
            name: canvasItem.data.label,
          },
        };
        result.push(positionRecord);
      }
    });
    return result;
  };

  readCanvasIntoNotes = (nodes) => {

    let items = [];

    for (let node of nodes) {
      if (node.type !== "note") continue;
      items.push({
        id: node.id,
        type: node.type,
        data: node.data,
        position: node.position
      })
    }

    return items;
  };

  //save canvas diagram
  saveCanvas = (canvas) => {
    let components = this.aggregate.components;
    let result = [];
    let notes = [];

    if (components != null) {
      //this is an existing project
      canvas.map((diagramItem) => {
        //check that this item is a node
        if (diagramItem.type == "component") {
          //find links that fit into this node
          let nextComponents = this.findLinks(canvas, diagramItem.id);
          nextComponents = this.removeDuplicateLinks(nextComponents);
          nextComponents = this.removeUnknownComponents(canvas, nextComponents);
          //find if this node is an existing component that needs update
          let update = components.find((component) => {
            return diagramItem.id == component.componentId;
          });

          if (update) {
            //get index
            let updateIndex = components.findIndex((obj) => {
              return obj.componentId == update.componentId;
            });

            if (updateIndex >= 0) {
              //this is an existing component that needs updating
              //record updated diagram info
              update = this.updateComponent(
                update,
                diagramItem,
                nextComponents
              );
              //return to array
              result.push(update);
            }
          } else {
            //this is a new node
            let newComponent = this.createComponent(
              diagramItem,
              nextComponents
            );
            result.push(newComponent);
          }
        }
      });
    } else if (components == null) {
      components = [];
      //this is a new canvas
      canvas.map((diagramItem) => {
        //check that this item is a node
        if (diagramItem.type == "component") {
          //find links that fit into this node
          let nextComponents = this.findLinks(canvas, diagramItem.id);
          nextComponents = this.removeDuplicateLinks(nextComponents);

          //this is a new node
          let newComponent = this.createComponent(diagramItem, nextComponents);
          result.push(newComponent);
        }
      });
    }

    //get notes
    notes = canvas.filter((item) => {
      return item.type == "note";
    });

    let noteIds = notes.map((item) => {
      return item.id;
    });

    let noteLinks = canvas
      .map((item) => {
        if (item.type == "link" && noteIds.indexOf(item.source) != -1)
          return item;
      })
      .filter((a) => a != undefined);
    notes.concat(noteLinks);

    result.map((component) => {
      delete component.componentData;
    });
    notes = [...notes, ...noteLinks];

    return { components: result, notes: notes };
  };

  //update an existing component using node information
  updateComponent = (toUpdate, item, nextComponents) => {
    toUpdate.type = item.data.type;
    toUpdate.viewData.x = item.position.x;
    toUpdate.viewData.y = item.position.y;
    toUpdate.nextComponents = nextComponents;
    return toUpdate;
  };
  //create a new component out of node information
  createComponent = (item, nextComponents) => {
    let result = {
      componentId: item.id,
      type: item.data.type,
      viewData: item.position,
      nextComponents: nextComponents,
    };
    return result;
  };

  setProjectName = (projectName) => {
    this.project_name = projectName;
  };

  saveProjectName = async () => {
    let body = this.aggregate;
    body.projectName = this.aggregate.project_name;
    send_request(
      `project-service/project/update/meta/${this.aggregate.project_id}`,
      body,
      "",
      "PATCH"
    ).catch((e) => {
      console.log(e);
    });
  };

  setLatestVersion = (draftVersion, publishedVersion) => {
    this.draftVersion = draftVersion;
    this.publishedVersion = publishedVersion;
  };

  initialiseTour = () => {
    this.isWelcomePageTour = false;
    this.isProjectPageTour = false;
    this.isCanvasPageTour = false;
    this.isMarketplacePageTour = false;
    this.isRepositoryPageTour = false;
    this.isPortalPageTour = false;
    this.isCredentialPageTour = false;
    this.showReadmeDialog = true;
    this.readmeText = "";
  };

  clear = () => {
    this.project_id = "";
    this.project_name = "";
    this.project_description = "";
    this.pageStatus = "";
    this.error = false;
    this.errors = [];
    this.components !== undefined
      ? (this.components.length = 0)
      : (this.components = []);
  };

  get isEmpty() {
    return this.project_id === "";
  }

  get aggregate() {
    return {
      project_id: this.project_id,
      project_name: this.project_name,
      draftVersion: this.draftVersion,
      publishedVersion: this.publishedVersion,
      project_description: this.project_description,
      imagePath: this.imagePath,
      components: toJS(this.components),
    };
  }

  getCurrentComponents = (node_id) => {
    return this.aggregate.components;
  };

  handleDocsRedirect = (component_type) => {
    switch (component_type) {
      case "conditional_logic":
        component_type = "cond_logic";
        break;
      case "conditional_workflow":
        component_type = "cond_workflow";
        break;
      case "document_editor":
        component_type = "doc_editor";
        break;
      case "knowledge_bank":
        component_type = "know_bank";
        break;
      case "calculation":
        component_type = "calculator";
        break;
      case "code":
        component_type = "run_code";
        break;
      case "advanced_code":
        component_type = "advanced_run_code";
        break;
      case "api_request_and_response":
        component_type = "";
        break;
      case "send_slack_message":
        component_type = "slack";
        break;
      case "upload_spreadsheet":
        component_type = "bulk_assessment";
        break;
      case "query_database_record":
        component_type = "query_database";
        break;
      case "create_database_record":
        component_type = "create_record";
        break;
      case "update_database_record":
        component_type = "update_record";
        break;
      case "trigger_from_zapier":
        component_type = "trigger_workflow_zap";
        break;
      case "trigger_a_zap":
        component_type = "trigger_zap";
        break;
      case "merge_workflow":
        component_type = "merge_workflow";
        break;
      case "loop_through_list":
        component_type = "loop_list";
        break;
      case "trigger_from_a_workflow":
        component_type = "trigger_workflow";
        break;
      case "trigger_another_workflow":
        component_type = "trigger_workflow";
        break;
      case "form":
        component_type = "form_builder";
        break;
      case "sequential_form":
        component_type = "seq_form";
        break;
      case "form_section":
        component_type = "form_section";
        break;
      case "session_variables":
        component_type = "session_variables";
        break;
      case "trigger_via_email":
        component_type = "trigger_via_email";
        break;
      case "ai_assistant":
        component_type = "ai_assistant";
        break;
      case "conditional_workflow_path":
        component_type = "conditional_workflow_path";
        break;
    }
    let url = "https://docs.workflow86.com/docs/components/" + component_type;
    window.open(url);
  };
}

decorate(ProjectStore, {
  savedComponent: observable,
  refreshCanvas: observable,
  errorSaving: observable,
  project_id: observable,
  project_name: observable,
  publishedVersion: observable,
  draftVersion: observable,
  last_modified: observable,
  components: observable,
  imagePath: observable,
  isWelcomePageTour: observable,
  isProjectPageTour: observable,
  isCanvasPageTour: observable,
  isMarketplacePageTour: observable,
  isRepositoryPageTour: observable,
  isPortalPageTour: observable,
  isCredentialPageTour: observable,
  showReadmeDialog: observable,
  readmeText: observable,
  setLatestVersion: action,
  setShowReadmeDialog: action,
  openWelcomePageTour: action,
  closeWelcomePageTour: action,
  openProjectPageTour: action,
  closeProjectPageTour: action,
  openCanvasPageTour: action,
  closeCanvasPageTour: action,
  openMarketplacePageTour: action,
  closeMarketplacePageTour: action,
  openRepositoryPageTour: action,
  closeRepositoryPageTour: action,
  openPortalPageTour: action,
  closePortalPageTour: action,
  openCredentialPageTour: action,
  closeCredentialPageTour: action,
  setProjectName: action,
  setComponentIdFromNodeId: action,
  initialiseTour: action,
  clear: action,
  setCanvas: action,
  setNodes: action,
  setEdges: action,
  aggregate: computed,
  isEmpty: computed,
  error: observable,
  errors: observable,
});

const projectStore = new ProjectStore();
console.log("importing now...");
export default projectStore;
