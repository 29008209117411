import { CheckCircle, RemoveCircleRounded, PauseCircleOutlineRounded, CachedRounded, ErrorRounded as CrossCircle, Pending } from "@mui/icons-material";
import dayjs from "dayjs";

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc)

export const renderIcon = (status, size, isLatestRun) => {
    switch (status) {
        case "FAIL":
            return <CrossCircle fontSize={size} className={isLatestRun ? "cross-icon" : "grey-icon"} />;
        case "PAUSED":
            return <PauseCircleOutlineRounded fontSize={size} color={isLatestRun && "info"} className={!isLatestRun && "grey-icon"} />;
        case "SUCCESS":
        case "SUCCESS_END":
            return <CheckCircle fontSize={size} className={isLatestRun ? "check-icon" : "grey-icon"} />;
        case "INPROGRESS":
            return <CachedRounded fontSize={size} className={isLatestRun ? "default-small-icon" : "grey-icon"} />;
        case "WAITING":
            return <Pending fontSize={size} className={isLatestRun ? "small-yellow-icon" : "grey-icon"} />;
        case "TERMINATED":
            return <RemoveCircleRounded fontSize={size} className={isLatestRun ? "small-red-icon" : "grey-icon"} />;  
    }
};

export const getColourFromStatus = (status) => {
    switch (status) {
        case "FAIL":
            return "#b00020";
        case "PAUSED":
            return "orange";
        case "SUCCESS":
            return "#31a633";
        case "SUCCESS_END":
            return "#31a633";
        case "INPROGRESS":
            return "#ff9d0b";
        case "WAITING":
            return "#ff9d0b";
        case "TERMINATED":
            return "#b00020";
    }
};

// Returns the border and color depending on a given status
export const getBorderAndBGColorFromStatus = (status) => {
    switch (status) {
        case "FAIL":
            return {
                bg: "rgba(255, 165, 0, 0.1)",
                border: "rgba(255, 165, 0, 1)"
            };
        case "PAUSED":
            return {
                bg: "rgba(49, 166, 51, 0.1)",
                border: "rgba(49, 166, 51, 1)"
            };
        case "SUCCESS":
            return {
                bg: "rgba(49, 166, 51, 0.1)",
                border: "rgba(49, 166, 51, 1)"
            };
        case "SUCCESS_END":
            return {
                bg: "rgba(49, 166, 51, 0.1)",
                border: "rgba(49, 166, 51, 1)"
            };
        case "INPROGRESS":
            return {
                bg: "rgba(255, 157, 11, 0.1)",
                border: "rgba(255, 157, 11, 1)"
            };
        case "WAITING":
            return {
                bg: "rgba(255, 157, 11, 0.1)",
                border: "rgba(255, 157, 11, 1)"
            };
        case "TERMINATED":
            return {
                bg: "rgba(176, 0, 32, 0.1)",
                border: "rgba(176, 0, 32, 1)"
            };
    }
}

// Calculates the time elapsed for a waiting component
// The difference between the components start datetime and the current datetime
export const calculateTimeElapsedForWaiting = (session) => {
    try {
        let startAt = dayjs.utc(session.startAt);
        let difference = dayjs().utc().diff(startAt);
        if (difference < 1000) difference = 1000; // Round to 1 second if less than 1 second

        const duration = dayjs.duration(difference);
        return convertDurationToString(duration);
    } catch (e) { return; }
}

// Calculates the time elapsed between two components
// i.e: the difference between their two start datetimes
export const calculateTimeElapsedForLink = (link, sessions) => {
    try {

        const source = sessions.find((session) => session.type === "sessionComponent" && session.id === link.source);
        const target = sessions.find((session) => session.type === "sessionComponent" && session.id === link.target);

        const sourceEndAt = dayjs(source.data.sessionData.startAt);
        const targetEndAt = dayjs(target.data.sessionData.startAt);

        let difference = targetEndAt.diff(sourceEndAt);
        if (difference < 1000) difference = 1000; // Round to 1 second if less than 1 second

        const duration = dayjs.duration(difference);

        return convertDurationToString(duration);
    } catch (e) { return; }
}

// Calculates the time elapsed for the last component
// i.e: the difference between the endAt
export const calculateTimeElapsedForLastComponent = (link, sessions) => {
    try {
        const source = sessions.find((session) => session.componentId === link.source);
        const target = sessions.find((session) => session.componentId === link.target);

        if (target.status === "WAITING") return; // We skip waiting sessions

        const sourceEndAt = dayjs(source.endAt);
        const targetEndAt = dayjs(target.endAt);

        let difference = targetEndAt.diff(sourceEndAt);
        if (difference < 1000) difference = 1000; // Round to 1 second if less than 1 second

        const duration = dayjs.duration(difference);

        return convertDurationToString(duration);
    } catch (e) { return; }
}

// Handles converting a dayjs duration to a formatted string
const convertDurationToString = (duration) => {
    let res = "";
    if (!duration) return res;

    if (duration.asDays().toFixed() > 0) res += `${duration.asDays().toFixed()}d `;

    if (duration.hours() > 0) res += `${duration.hours()}h `;

    if (duration.minutes() > 0) res += `${duration.minutes()}m `;

    if (duration.seconds() > 0) {
        if (res.trim() === "" && duration.seconds() <= 1) res = "<1s";
        else res += `${duration.seconds()}s `;
    }

    return res.trim();
}
