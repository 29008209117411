import React, { useState } from "react";

import { styled } from "@mui/system";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)({});

function WorkflowPublishComment({ setPublishComment }) {
  const [comment, setComment] = useState("");

  return (
    <>
      <p className={"s-text"} style={{ marginTop: "12px" }}>
        Add notes or a comment describing any changes (optional)
      </p>
      <StyledTextField
        fullWidth
        variant="outlined"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onBlur={() => setPublishComment(comment)}
        size="small"
        multiline
        inputProps={{ maxLength: 500 }}
        placeholder="Type notes/comments here"
      />
    </>
  );
}

export default WorkflowPublishComment;
