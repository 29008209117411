import { useEffect, useRef } from "react";

export function useInterval(callback, delay, isCompleted) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (isCompleted) {
            return;
        }

        if (delay) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay, isCompleted]);
}
