import { computed, observable, decorate, action, toJS } from "mobx";
import { ComponentFactory } from "./ComponentRegistry";
import _ from "lodash";
import uuid from "uuid";
import ProjectStore from "./ProjectStore";

class ProgressViewStore {
    constructor() {
        this.clear();
        this.state = {
            zoom_level: 75,
            active_diagram: {},
            locked: true,
            template: {},
        };
    }

    setWorkflow = (workflow) => {
        this.project_id = workflow.projectId;
        this.project_name = workflow.name;
        this.last_modified = workflow.lastModified;
        this.project_description = workflow.description;
        this.components = workflow.components;
        this.setCanvas(this.readDiagram(workflow.components));
        this.imagePath = workflow.imagePath;
        this.projectStatus = workflow.status;
    };

    //create a react-flow custom node
    createNode = (component, template) => {
        let isFromConditionalPath=false;

        if (component.componentData !== null && component.componentData.isFromConditionalPath) {
            isFromConditionalPath=component.componentData.isFromConditionalPath
        }

        let newNode = {
            id: component.componentId,
            type: "component",
            data: {
                logo: template.logo,
                hasInput: template.hasInput ? template.hasInput : false,
                hasOutput: template.hasOutput ? template.hasOutput : false,
                type: component.type,
                displayName: template.displayName,
                description: component.description ? component.description : "",
                next: component.nextComponents,
                label: component.componentData && component.componentData.name ? component.componentData.name : template.displayName,
                baseColor: template.baseColor,
                fromWorkflowPath:isFromConditionalPath

            },
            position: { x: component.viewData.x, y: component.viewData.y },
        };

        return newNode;
    };

    //create a react-flow custom edge
    createLink = (newNodeId, nextId, baseColor) => {
        let newLink = {
            id: uuid(),
            source: newNodeId,
            target: nextId,
            markerEnd: {
                type: "arrowclosed",
                color: "#868686",
            },
            type: "link",
            style: { strokeWidth: "3px", stroke: baseColor },
        };
        return newLink;
    };

    //this turns stored data into a react-flow diagram
    readDiagram = (components) => {
        let diagram = [];
        let dbIds=new Map([]);
        let compNextMap = new Map([]);

        if (components) {
            components.map((component, id) => {
                if (ComponentFactory[component.type] !== undefined) {
                    //create nodes
                    const newNode = this.createNode(component, ComponentFactory[component.type]);
                    if( component.componentData !=null && (component.componentData.targetDatabaseId!=null ||(component.componentData.data!=null&& component.componentData.data.targetDatabaseId!=null)))
                    {
                        /**
                         * create db node
                         */
                        let dbId = component.componentData.targetDatabaseId ? component.componentData.targetDatabaseId : component.componentData.data.targetDatabaseId;

                        ProjectStore.createDbNodeLogic(component,dbIds,diagram,dbId);
                        newNode.data.hasDb = true;

                    }

                    diagram.push(newNode);

                    if (newNode.data.next && newNode.data.next.length != 0) {
                        //create links
                        newNode.data.next.map((next, nextId) => {
                            const newLink = this.createLink(newNode.id, next.componentId, newNode.data.baseColor);
                            if(newNode.data.type==="conditional_workflow_path")
                            {

                                compNextMap.set(next.componentId,newNode.id);

                            }
                            diagram.push(newLink);
                        });
                    }
                }

            });
        }
        this.state.dbIds=dbIds;
        compNextMap.forEach((value, key) => {

            let conditional_workflow = diagram.find((obj) => obj.id === key);
            conditional_workflow.data.parentId=value;


        })

        return diagram;
    };

    setCanvas = (canvas) => {
        this.canvas = canvas;
    };

    clear = () => {
        this.project_id = "";
        this.project_name = "";
        this.project_description = "";
        this.components !== undefined ? (this.components.length = 0) : (this.components = []);
    };

    get aggregate() {
        return {
            project_id: this.project_id,
            project_name: this.project_name,
            draftVersion: this.draftVersion,
            publishedVersion: this.publishedVersion,
            project_description: this.project_description,
            imagePath: this.imagePath,
            components: toJS(this.components),
        };
    }
}

decorate(ProgressViewStore, {
    download: observable,
    template: observable,
    project_id: observable,
    project_name: observable,
    last_modified: observable,
    components: observable,
    downloadTemplate: action,
    setCanvas: action,
    clear: action,
    aggregate: computed,
});

const progressViewStore = new ProgressViewStore();

export default progressViewStore;
