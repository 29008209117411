import Grid from "@mui/material/Grid";
import {Autocomplete, Chip, MenuItem, Select, TextField} from "@mui/material";
import getPlaceholderColors from "../vardogyir-ui/PlaceholderColors";
import MentionEditor from "../components/TextEditor/MentionEditor";
import FormMentionEditor from "../components/Component/FormMentionEditor";
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDayjs";
import {getColourFromString} from "./ColourNameGenerator";
import React from "react";
import {styled} from "@mui/system";
import { timezones } from "./timezones";
import { timestampFormats, defaultTimestampFormat} from "./DatePickerUtil";

/*
item : data of the current item
setValue : function to update value of the item
setPlaceholderType : function to update type of the item
setDateFormatValue : function to update the date time value if item type is date time
setFocus : function to update focus (required for text field and rte field)
 */
const PlaceholderTypeSelect = ({item, availablePlaceholders, setValue, setPlaceholderType, setDateFormatValue, setTimeZoneValue,setFocus}) => {
    // available placeholder types
    const placeholderTypeMap = ["Text", "Number", "List", "DateTime", "HTML"]

    //color list chips
    const MyChip = styled(Chip)(({colour}) => ({
        border: `1px solid ${colour.border}`,
        backgroundColor: colour.bg,
        boxSizing: "border-box",
        borderRadius: "4px",
        marginRight: "6px",
    }));

    const handleArray = (itemData) => {
        if (itemData === null) return [];
        if (typeof itemData === "string" || itemData instanceof String) {
            // We know it's a string
            if (itemData.length >= 2 && itemData[0] === "[" && itemData[itemData.length - 1] === "]") {
                // We know it's a list
                let eles = itemData.trim().slice(1, itemData.length - 1);
                return eles.split(",");
            } else return [];

        }
        return itemData;
    };
    return <>
        <Grid Item>
            <Grid container direction={"row"} columnGap={2}>
                <Grid Item>

                    <p>Type</p>
                </Grid>
                <Grid Item>

                    <Select defaultValue="text"
                            className={"joditPlaceholderIcon"}
                            inputProps={{IconComponent: () => null}}
                            sx={{
                                backgroundColor: "#F5F5F5",
                                "--custom-icon": `url(${getPlaceholderColors(item.type, null).icon})`,
                                verticalAlign: "top",
                                marginTop: "12px",
                                visibility: "",

                            }}
                            variant="filled"
                            disableUnderline
                            value={item.type}
                            onChange={(event)=>setPlaceholderType(event.target.value)}
                    >
                        {placeholderTypeMap.map((aCol, aColIndex) => (<MenuItem
                            value={aCol.toLowerCase()}
                            className={"joditPlaceholderIcon"}
                            style={{
                                "--custom-icon": `url(${getPlaceholderColors(aCol.toLowerCase(), null).icon})`,
                                paddingTop:"8px",
                                paddingBottom:"8px"
                            }}
                        >
                            {aCol}
                        </MenuItem>))}

                    </Select>
                </Grid>

            </Grid>
        </Grid>
        <Grid Item>
            <Grid container direction={"column"}>

                <Grid Item>
                    <p>Value</p>
                </Grid>

                {item.type === "text" &&  <FormMentionEditor
                    style={{
                        width: "100%",
                        marginBottom: 16,
                        verticalAlign: "top",
                        minHeight: "16px",
                    }}
                    availablePlaceholders={availablePlaceholders}
                    assignTask={true}
                    text={item.value}
                    onChange={(value) => {
                        setValue(value)
                    }}
                    onFocus={(e) => {}}
                    hideScrollBar={true}
                    {...(availablePlaceholders != null && {
                        availablePlaceholders: availablePlaceholders[0].placeholders,
                    })}
                />}
                {item.type === "number" && <TextField
                    id="free-text-input"
                    type="number"
                    variant="outlined"
                    value={item.value}
                    className="key_padding"
                    onChange={(event) => {
                        setValue(event.target.value)
                    }}
                />}
                {item.type === "html" && <FormMentionEditor
                    availablePlaceholders={availablePlaceholders}
                    text={item.value}
                    onChange={(html) => setValue(html)}
                    placeholder={""}
                    disableTooltip
                    contentStyles={{
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        boxShadow: "none",
                        height: "auto",
                        minHeight: "360px",
                        padding: "8px",
                        minWidth: "100%",
                    }}
                    onFocus={(e) => {
                    }}

                    contentBlock={true}
                    hideScrollBar={true}
                    assignTask={true}
                    {...(availablePlaceholders != null && {
                        availablePlaceholders: availablePlaceholders[0].placeholders,
                    })}
                />}
                {item.type === "datetime" && (<LocalizationProvider dateAdapter={DateAdapter}>
                    <Grid container direction={"column"} rowGap={2} wrap={"nowrap"}>
                        <Grid item xs={12}>
                            <DateTimePicker
                                style={{margin: 0}}
                                disableToolbar
                                format={item.dateFormat ? item.dateFormat : defaultTimestampFormat.value}
                                id="date-picker-inline"
                                value={item.value}
                                onChange={(value) => setValue(new Date(value).toISOString())}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                                renderInput={(params) => <TextField {...params} variant="outlined"
                                                                    className={"key_padding"} style={{width: "100%"}}/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction={"row"} columnGap={2} rowGap={2}>
                                <Grid Item>
                            <Select
                                style={{paddingRight: "40px", paddingLeft: "24px"}}
                                disableUnderline={true}
                                variant={"filled"}
                                size={"small"}
                                autoWidth={true}
                                value={item.dateFormat ? item.dateFormat : defaultTimestampFormat.value}
                                onChange={(event) => setDateFormatValue(event.target.value)}
                            >
                                {timestampFormats.map((dateFormat, index) => (
                                    <MenuItem key={index} value={dateFormat.value}>
                                    {dateFormat.displayValue}
                                    </MenuItem>
                                ))}
                            </Select>
                                </Grid>
                                <Grid item>
                                    <Select
                                        style={{paddingRight: "40px", paddingLeft: "24px"}}
                                        onChange={(e) =>setTimeZoneValue(e.target.value)}
                                        disableUnderline={true}
                                        variant={"filled"}
                                        size={"small"}
                                        value={item.timeZone ? item.timeZone : "UTC"}
                                        defaultValue={"UTC"}
                                    >
                                        {timezones.length > 0 &&
                                            timezones.map((item, index) => (
                                                <MenuItem value={item.offset} key={index}>
                                                    {item.name} {item.offset}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </LocalizationProvider>)}


                {item.type === "list" && <Autocomplete
                    size="small"
                    multiple
                    options={[]}
                    value={handleArray(item.value)}
                    freeSolo
                    clearOnBlur
                    fullWidth
                    variant="standard"
                    /**
                    * Adding this to allow for duplicates.
                    Also the form front end is using autocomplete from a different version
                    of mui which has the allow Duplicates flag.
                    */
                    isOptionEqualToValue={(option, value) => false}
                    renderInput={(params) => <TextField {...params} variant="outlined"
                                                        placeholder={"Enter list items"} multiline/>}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => <MyChip {...getTagProps({index})} label={option}
                                                                       colour={getColourFromString(option)}/>);
                    }}
                    onBlur={(e, value, reason) => {
                        if (e.target.value.trim() === "") return;
                        let newVal = [...handleArray(item.value), e.target.value];
                        setValue(newVal);
                    }}
                    onChange={(e, value, reason) => {
                        if (reason === "clear") {
                            // Clear all
                            let newVal = [];
                            setValue(newVal);
                        } else if (reason === "removeOption") {
                            setValue(value)
                        } else if (reason === "createOption") {
                            // Create a new option
                            if (e.target.value.trim() === "") return;
                            let newVal = [...handleArray(item.value), e.target.value];
                            setValue(newVal);
                        }
                    }}
                />}

            </Grid>

        </Grid>
    </>;

}
export default PlaceholderTypeSelect;