const styles = (theme) => ({
  Header: {
    width: "100%",
    padding: "16px",
  },
  Breadcrumb: {
    paddingLeft: "8px",
  },
  ItemBreadcrumb: {
    cursor: "pointer",
    lineHeight: "20px",
    textDecoration: "none",
    "&:hover": {
      color: "#40a9ff",
      textDecoration: "none",
    },
  },
  iconError: {
    display: "inline-block",
    float: "left",
    marginRight: "16px",
    color: "#B00020",
  },
  iconDiscard: {
    display: "inline-block",
    float: "left",
    marginRight: "16px",
    color: "#868686",
  },
  iconSuccess: {
    display: "inline-block",
    float: "left",
    marginRight: "16px",
    color: "#31a633",
  },
  textContent: {
    fontSize: "14px",
    lineHeight: "24px",
    margin: 0,
    padding: "8px 0 24px",
    color: "#868686",
  },
  textTitle: {
    padding: "8px",
    fontSize: "18px",
    lineHeight: "24px",
    fontStyle: "normal!important",
    fontWeight: "normal!important",
    margin: 0,
    color: "#000000",
  },
  dialogContent: {
    padding: " 0!important",
  },
  dialogPaper: {
    padding: "24px",
  },
  Breadcrumbs: {
    marginBottom: 12,
    marginTop: 17,
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "40px",
  },
  dialogImageSize: {
    height: "auto",
    width: "auto",
    maxHeight: "256px",
    margin: "auto",
    display: "block",
    objectFit: "contain",
  },
  popupImageStyle: {
    width: "100%",
    height: "256px",
    marginTop: "8px",
    marginBottom: "16px",
  },
  readMeText: {
    fontSize: 14,
    color: "#868686",
    marginRight: 16
  }
});

export default styles;
