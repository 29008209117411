import React, { useState, useEffect } from "react";
import "./index.css";
import "./App.css";
import { Amplify } from "aws-amplify";
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import AuthWrapper from "./components/Component/AuthWrapper";
import aws_config from "./auth/awsConfig";
import { BrowserRouter } from "react-router-dom";
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';

Amplify.configure(aws_config);
// this will store auth tokens in cookie store
export const currentDomain = window.location.host.includes("localhost")
    ? "localhost"
    : '.w86dev.click';
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain: currentDomain,sameSite:'none',secure:'true',path:'/' }));

function App() {
  const [authState, setAuthState] = useState("signIn");
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await getCurrentUser();
      setUser(user);
      setAuthState("signedIn");
    } catch (error) {
      setUser(null);
      setAuthState("signIn");
    }
  }

  async function handleStateChange(state, data) {
    setAuthState(state);
    console.log(aws_config);
    if (state === "signedIn") {
      setUser(data);
    }
  }

  return (
    <BrowserRouter>
      <AuthWrapper
        authState={authState}
        onStateChange={handleStateChange}
        user={user}
      />
    </BrowserRouter>
  );
}

export default App;
