import {action, computed, decorate, observable, toJS} from "mobx";

class AIAssistantTemplateStore {
    constructor() {
        this.initialiseEmpty();
    }
    setAiAssistantVariables = component => {
        this.componentId = component.componentId;
        this.type = component.type;
        if (component.componentData) {
            this.data = component.componentData.data;
            this.lastModified = component.componentData.lastModified;
            this.name = component.componentData.name;
        } else {
            this.data.aiAssistantData = this.aiAssistantData();
            this.lastModified = "";
            this.name = "";
        }
    };

    setTemplateData = data => {
        this.data = data;
    }

    get template() {
        return {
            componentId: this.componentId,
            name: this.name,
            position: this.position,
            data: toJS(this.data),
            type: this.type,
            lastModified: this.lastModified,
            placeholders: this.placeholders,
            imageContext:this.imageContext
        };
    }
    aiAssistantData() {
        const placeholderMap = [];
        const newPlaceholder = {
            desc: "",
            key: this.keyAuto(),
            type: "text",
            isExplanation:false
        }
        placeholderMap.push(newPlaceholder);
        return {
            task: "",
            context: "",
            imageContext:[],
            explanation:false,
            outputPlaceholders: placeholderMap,
            gptModel:"gpt-4o-mini"
        };
    }
    setPlaceholders = (placeholders) => {
        this.placeholders = placeholders;
    }
    setAIOutputPlaceholders = (aiOutPutPlaceholders) => {
        this.data.aiAssistantData.outputPlaceholders = aiOutPutPlaceholders

    }
    setGptModel =(gptModel) =>{
        this.data.aiAssistantData.gptModel = gptModel
    }
    initialiseEmpty = () => {
        this.name = "";
        this.componentId = "";
        this.type = "ai_assistant";
        this.lastModified = "";
        this.placeholders = [];
        this.data={};
        this.data.aiAssistantData = this.aiAssistantData();
        this.position = null;
        this.focus = null;
        this.imageContext=[]
    };

    keyAuto = () => {
        return (
            "ai_assistant" + (Math.floor(Math.random() * 90000000) + 10000).toString()
        );
    };
    setFocus = index => {
        this.placeholders = [];
        this.position = index;
    };
    setTask = task => {
        this.data.aiAssistantData.task = task;
    };
    setContext = context => {
        this.data.aiAssistantData.context = context;
    };
    clearImageContext=()=>{
        this.data.aiAssistantData.imageContext=[];
    }

    setImageContext = imageContext => {
        if (this.data.aiAssistantData.imageContext) {
            for (let i = 0; i < this.data.aiAssistantData.imageContext.length; i++) {
                if (this.data.aiAssistantData.imageContext[i] && this.data.aiAssistantData.imageContext[i] === imageContext) {
                    return;
                }
            }

            this.data.aiAssistantData.imageContext.push(imageContext);
        }
        else{
            // to handle for older ai assistant components
            this.data.aiAssistantData.imageContext=[imageContext];
        }

    };
    setExplanation = explanation => {
        this.data.aiAssistantData.explanation = explanation;
    };
    deleteChip = (index) => {
        const newChips = [...this.data.aiAssistantData.imageContext];
        newChips.splice(index, 1);
        this.data.aiAssistantData.imageContext = newChips;
    };


};
decorate(AIAssistantTemplateStore, {
    componentId: observable,
    name: observable,
    data: observable,
    type: observable,
    lastModified: observable,
    setLastModified: action,
    initialiseEmpty: action,
    setTask:action,
    setContext:action,
    setImageContext:action,
    setExplanation:action,
    setAIOutputPlaceholders:action,
    position: observable,
    template: computed,
    placeholders: observable,
    setPlaceholders: action,
    deleteChip:action

});
const aiAssistantTemplateStore = new AIAssistantTemplateStore();
export default aiAssistantTemplateStore;
