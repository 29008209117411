import React from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import back arrow icon
import PropTypes from "prop-types";
import { styled, Grid } from "@mui/material";

const StyledIconButton = styled(IconButton)({
  color: "black",
  fontSize: "16px",
});

const StyledGridItem = styled(Grid)({
  marginRight: "auto",
});

const AIPanelToolbar = ({
  onWidth,
  onCopy,
  onHistory,
  onClose,
  state,
  setState,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {state !== "INITIAL" && (
        <StyledGridItem item>
          <StyledIconButton onClick={() => setState("INITIAL")}>
            <ArrowBackIcon />
          </StyledIconButton>
        </StyledGridItem>
      )}

      {/* <Grid item>
        <StyledIconButton onClick={onWidth}>
          <img src={"/svgs/width.svg"} alt="Width Icon" />
        </StyledIconButton>
      </Grid>

      <Grid item>
        <StyledIconButton onClick={onCopy}>
          <ContentCopyIcon />
        </StyledIconButton>
      </Grid> */}

      <Grid item>
        <StyledIconButton onClick={onHistory}>
          <HistoryIcon />
        </StyledIconButton>
      </Grid>

      <Grid item>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </Grid>
    </Grid>
  );
};

export default AIPanelToolbar;

AIPanelToolbar.propTypes = {
  onWidth: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onHistory: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
};
