import React from "react";

// MUI
import { Grid, Select, MenuItem, TextField } from "@mui/material";

// Util
import { useAIOnboardingContext } from "../OnboardingContextProvider";

function W86FindOut(props) {
    const { findOut, setFindOut, findOutDetails, setFindOutDetails } = useAIOnboardingContext();

    const { ItemTitle, loading } = props;

    const findOutByLogic = () => {
        switch (findOut) {
            case "REFERRED_BY_A_WORKFLOW86_USER":
                return (
                    <Grid container rowGap={"6px"} marginTop="6px">
                        <Grid item xs={12}>
                            <ItemTitle>What is the username of your referrer? We we’ll send you both a referral bonus 🎁</ItemTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={findOutDetails}
                                size="small"
                                fullWidth
                                className={props.classes.inputField}
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                    if (e.target.value.length > 128) return;
                                    setFindOutDetails(e.target.value);
                                }}
                                placeholder="Enter the referrer username"
                                disabled={loading || !props.isLatestMsg}
                            />
                        </Grid>
                    </Grid>
                );
            case "OTHER":
                return (
                    <Grid container rowGap={"6px"} marginTop="6px">
                        <Grid item xs={12}>
                            <ItemTitle>Can you provide more details?</ItemTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={findOutDetails}
                                size="small"
                                fullWidth
                                className={props.classes.inputField}
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                    if (e.target.value.length > 128) return;
                                    setFindOutDetails(e.target.value);
                                }}
                                placeholder="Enter more details"
                                disabled={loading || !props.isLatestMsg}
                            />
                        </Grid>
                    </Grid>
                );
            case "ANOTHER_WEBSITE":
                return (
                    <Grid container rowGap={"6px"} marginTop="6px">
                        <Grid item xs={12}>
                            <ItemTitle>What was the website?</ItemTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={findOutDetails}
                                size="small"
                                fullWidth
                                className={props.classes.inputField}
                                variant="outlined"
                                type="text"
                                onChange={(e) => {
                                    if (e.target.value.length > 128) return;
                                    setFindOutDetails(e.target.value);
                                }}
                                placeholder="Enter website name/URL"
                                disabled={loading || !props.isLatestMsg}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return <></>;
        }
    };

    return (
        <Grid item xs={12}>
            <Grid container rowGap={"6px"}>
                <Grid item xs={12}>
                    <ItemTitle>How did you find out about Workflow86?</ItemTitle>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        onChange={(e) => {
                            if (e.target.value.length > 128) return;
                            setFindOut(e.target.value);
                        }}
                        className={props.classes.select}
                        variant={"outlined"}
                        value={findOut}
                        size="small"
                        displayEmpty
                        disabled={loading || !props.isLatestMsg}
                    >
                        <MenuItem value={null}>select answer</MenuItem>
                        <MenuItem value="REFERRED_BY_A_WORKFLOW86_USER"> Referred by a Workflow86 user </MenuItem>
                        <MenuItem value="VIA_POWERED_BY_WORKFLOW86"> ⚡ Powered by Workflow86 </MenuItem>
                        <MenuItem value="WORKFLOW86_WEBSITE"> Workflow86 Website </MenuItem>
                        <MenuItem value="ANOTHER_WEBSITE"> Another website </MenuItem>
                        <MenuItem value="GOOGLE"> Google </MenuItem>
                        <MenuItem value="TWITTER"> Twitter </MenuItem>
                        <MenuItem value="LINKEDIN"> LinkedIn </MenuItem>
                        <MenuItem value="OTHER"> Other </MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    {findOutByLogic()}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default W86FindOut;
