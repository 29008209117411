import React from "react";
import { toJS } from "mobx";
import projectStore from "../../../../../ProjectCanvas/ProjectStore";

// MUI Icons
import UpgradeIcon from "@mui/icons-material/Upgrade";
import PostAddIcon from "@mui/icons-material/PostAdd";
import StorageIcon from "@mui/icons-material/Storage";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { extractEdges, extractNodes } from "../../../../../../utils/CanvasUtil";

// ========== ENUMS & CONSTS ========== //
export const EDIT_STAGES_ENUM = ["SAVED_BACKUP", "DELETED_COMPONENTS", "UPDATED_DATABASES", "ADDED_NEW_DATABASES", "ADDED_NEW_COMPONENTS", "UPDATED_COMPONENTS"];

export const StageNameMapping = {
    SAVED_BACKUP: {
        name: "Saving a backup version",
        icon: <CloudDownloadIcon style={{ height: "18px", width: "auto" }} />,
    },
    DELETED_COMPONENTS: {
        name: "Removing components",
        icon: <DeleteSweepIcon style={{ height: "18px", width: "auto" }} />,
    },
    ADDED_NEW_COMPONENTS: {
        name: "Adding new components",
        icon: <PostAddIcon style={{ height: "18px", width: "auto" }} />,
    },
    ADDED_NEW_DATABASES: {
        name: "Adding new databases",
        icon: <StorageIcon style={{ height: "18px", width: "auto" }} />,
    },
    UPDATED_COMPONENTS: {
        name: "Updating existing components",
        icon: <UpgradeIcon style={{ height: "18px", width: "auto" }} />,
    },
    UPDATED_DATABASES: {
        name: "Updating existing databases",
        icon: <SplitscreenIcon style={{ height: "18px", width: "auto" }} />,
    },
};

export const getEditsBeingPerformed = (changes) => {
    const result = new Set();
    result.add("SAVED_BACKUP");

    if (!changes) return result;

    if (changes.componentsToAdd && changes.componentsToAdd.length > 0) result.add("ADDED_NEW_COMPONENTS");
    if (changes.componentsToUpdate && changes.componentsToUpdate.length > 0) result.add("UPDATED_COMPONENTS");
    if (changes.componentsToDelete && changes.componentsToDelete.length > 0) result.add("DELETED_COMPONENTS");
    if (changes.databasesToAdd && changes.databasesToAdd.length > 0) result.add("ADDED_NEW_DATABASES");
    if (changes.databasesToUpdate && changes.databasesToUpdate.length > 0) result.add("UPDATED_DATABASES");

    return result;
}

// ========== HELPER FUNCTIONS ========== //

/**
 * Checks if the applying of the AI edit is completed
 * @returns boolean
 */
export const checkIfApplyingEditCompleted = (data) => {
    if (!data.editStages) return false;

    const changesList = [...data.editStages];

    // Continue checking if completed
    for (const stage of EDIT_STAGES_ENUM) {
        if (!changesList.includes(stage)) return false;
    }

    return true;
};

/**
 * 1. We get passed the canvas (allNodesAndEdges). This includes all existing nodes/edges + ai nodes/edges
 *    Remember that update/delete components are existing components with isTemp = true
 * 
 * 2. Handle updateComponents => Search for the update component, set required data i.e:
 *    isTemp = false, editStatus, label, description, instructions, etc
 * 
 * 3. Handle deleteComponents => Search for components to delete, set required data i.e:
 *    isTemp = false, editStatus
 * 
 * 4. Now, remove all AI nodes/edges in the canvas and set this in filteredAICanvas
 * 
 * 5. Grab the new ai nodes and parse them through the readDiagram function to get into correct format
 *    
 * 6. We then draw the links from the update components and the new ai components
 *    New: We need to go through each of the previous component ids and draw links
 *    Update: We need to go through previous/next components and draw links if not drawn
 * 
 * 7. Finally, return the combination of:
 *    - The filtered AI canvas (no AI)
 *    - The new ai components
 *    - AI Links of updateComponents & newComponents
 *    
 */
export const filterAIEditCanvasAfterTranslation = (allNodesAndEdges, changes) => {

    // Handle any update and delete components
    allNodesAndEdges = handleUpdateComponents(allNodesAndEdges, changes);
    allNodesAndEdges = handleDeleteComponents(allNodesAndEdges, changes);

    // Remove all the previous AI from the canvas
    // (i.e: components/edges added while streaming with integer ids)
    let filteredAICanvas = [];
    for (let i = 0; i < allNodesAndEdges.length; i++) {
        let item = allNodesAndEdges[i];
        if (item) {
            if (item.type === "link" && item.isTemp) continue;
            else if (item.type === "component" && item.data && item.data.isTemp) continue;
        }
        filteredAICanvas.push(item);
    }

    // Grab the nodes to add
    const nodesToAdd = changes.componentsToAdd;

    // Parse the new nodes to add through the readDiagram function
    // To return the correct format + create relevant next component links
    const diagram = projectStore.readDiagram(nodesToAdd, true);

    // Here, we need to draw:
    // 1. Previous component links for newly added components
    // 2. Previous/Next component links for updated components if given
    const aiLinks = handleCreatingAILinksForUpdateAndNewComponents(filteredAICanvas, nodesToAdd, diagram, changes);

    // Here, we combine the new ai components with the existing canvas with no ai
    // And the previous component links drawn
    const combined = [...filteredAICanvas, ...diagram, ...aiLinks];

    // Mark any links to delete
    const result = markLinksToDelete(changes.componentsToUpdate, combined);

    return result;
};

function handleDeleteComponents(allNodesAndEdges, changes) {
    if (!changes || !changes.componentsToDelete) return allNodesAndEdges;

    // Create component ids marked as delete set
    const markedAsDeleteId = new Set();
    for (let i = 0; i < changes.componentsToDelete.length; i++) {
        const item = changes.componentsToDelete[i];
        if (!item || !item.componentId) continue;
        markedAsDeleteId.add(item.componentId);
    }

    // Set the edit status to delete for the given components
    return allNodesAndEdges.map((item) => {
        if (item.type === "component" && item.data && markedAsDeleteId.has(item.id)) {
            return {
                ...item,
                data: {
                    ...item.data,

                    // Set custom info here
                    isTemp: false,
                    editStatus: "TO_DELETE",
                },
            };
        }
        return item;
    });
}

function handleUpdateComponents(allNodesAndEdges, changes) {
    if (!changes || !changes.componentsToUpdate) return allNodesAndEdges;

    // Remove any isTemp flags for components to update
    const updateCompIdToComponent = changes.componentsToUpdate.reduce((acc, item) => {
        if (!item || !item.componentId) return acc;
        acc[item.componentId] = item;
        return acc;
    }, {});

    // Handle the update components
    return allNodesAndEdges.map((item) => {
        // If it's a component we want to update
        /// Set isTemp = false & set the edit instructions
        if (item.type === "component" && item.data && item.id in updateCompIdToComponent) {
            const updateComp = updateCompIdToComponent[item.id];
            return {
                ...item,
                data: {
                    ...item.data,

                    // Set custom info here
                    isTemp: false,
                    editStatus: "TO_UPDATE",
                    label: updateComp.generatedTitle,
                    description: updateComp.description,
                    instructions: {
                        buildInstruction: item.data.instructions && item.data.instructions.buildInstruction ? item.data.instructions.buildInstruction : null,
                        editInstruction: updateComp.editInstructions,
                    },
                },
            };
        }

        return item;
    });
}

function handleCreatingAILinksForUpdateAndNewComponents(canvasWithoutAI, nodesToAdd, diagram, changes) {

    // 1. Construct a hash set of the existing links
    const existingLinks = new Set();
    const edges = extractEdges(canvasWithoutAI);
    
    for (let i = 0; i < edges.length; i++) {
        const edge = edges[i];
        if (!edge || !edge.source || !edge.target) continue;
        existingLinks.add(edge.source + "_" + edge.target);
    }

    // 2. Create a map of NEW AI componentId -> previous components
    let compIdToPreviousComponents = {};
    for (let i = 0; i < nodesToAdd.length; i++) {
        const nodeToAdd = nodesToAdd[i];
        if (!nodeToAdd || !nodeToAdd.componentId || !nodeToAdd.previousComponents) continue;
        compIdToPreviousComponents[nodeToAdd.componentId] = nodeToAdd.previousComponents;
    }

    // 3. Extract just the nodes
    const diagramWithExtractedNodes = extractNodes(diagram);

    // 4. Construct map from component id to the existing component
    let compIdToExistingNode = {};
    for (let i = 0; i < canvasWithoutAI.length; i++) {
        const item = canvasWithoutAI[i];
        if (!item || item.type !== "component") continue;
        compIdToExistingNode[item.id] = item;
    }

    // 5. Go through and add each link
    const prevCompLinks = [];
    for (let i = 0; i < diagramWithExtractedNodes.length; i++) {
        const node = diagramWithExtractedNodes[i];
        if (!node || !node.id || !(node.id in compIdToPreviousComponents)) continue;

        const prevComponentIds = compIdToPreviousComponents[node.id];

        // Go over each prevComponentId and create a link to add
        for (let j = 0; j < prevComponentIds.length; j++) {
            const prevCompId = prevComponentIds[j];

            // Grab source comp
            const sourceComp = compIdToExistingNode[prevCompId];
            if (!sourceComp) continue;

            // We should actually be passing the source stuff here
            const newLink = projectStore.createLink(sourceComp.data.type, sourceComp.data.fromWorkflowPath, sourceComp.id, node.id, sourceComp.data.baseColor);

            // Check that is it not already created
            if (!existingLinks.has(newLink.source + "_" + newLink.target)) {
                prevCompLinks.push(newLink); // Add to list
                existingLinks.add(newLink.source + "_" + newLink.target); // Add the link
            }
            
        }
    }

    // 6. Now handle the previous/next components of UPDATE_COMPONENTS
    const linksFromUpdateComponents = [];
    if (changes && changes.componentsToUpdate && changes.componentsToUpdate.length > 0) {
        // Go over each component to update
        for (let i = 0; i < changes.componentsToUpdate.length; i++) {
            const updateComp = changes.componentsToUpdate[i];

            // Go over each next components and try to create a link
            if (updateComp.nextComponents) {
                for (let j = 0; j < updateComp.nextComponents.length; j++) {
                    const nextComponentId = updateComp.nextComponents[j];
                    if (!nextComponentId) continue;
    
                    const newLink = projectStore.createLink(updateComp.type, updateComp.fromWorkflowPath, updateComp.componentId, nextComponentId, updateComp.baseColor);
    
                    // Check it doesnt already exist
                    if (!existingLinks.has(newLink.source + "_" + newLink.target)) {
                        linksFromUpdateComponents.push({
                            ...newLink,
                            isTemp: true
                        }); // Add to list
                        existingLinks.add(newLink.source + "_" + newLink.target); // Add the link
                    }
                }
            }

            // Go over each previous components and try to create a link
            if (updateComp.previousComponents) {
                for (let j = 0; j < updateComp.previousComponents.length; j++) {
                    const previousComponentId = updateComp.previousComponents[j];
                    if (!previousComponentId) continue;
    
                    const newLink = projectStore.createLink(updateComp.type, updateComp.fromWorkflowPath, previousComponentId, updateComp.componentId, updateComp.baseColor);
    
                    // Check it doesnt already exist
                    if (!existingLinks.has(newLink.source + "_" + newLink.target)) {
                        linksFromUpdateComponents.push(newLink); // Add to list
                        existingLinks.add(newLink.source + "_" + newLink.target); // Add the link
                    }
                }
            }
        }
    }

    return [...prevCompLinks, ...linksFromUpdateComponents];
}

/**
 * Function for marking links to be deleted
 * with the "TO_DELETE" editStatus
 * @param {*} componentsToUpdate 
 * @param {*} allElements 
 * @returns 
 */
const markLinksToDelete = (componentsToUpdate, allElements) => {
    // Fetch the links to mark as remove
    const linksToMarkAsRemove = fetchLinksToMarkAsRemove(componentsToUpdate, allElements);

    let result = [...allElements];

    // If there are links to mark, enter
    if (linksToMarkAsRemove && linksToMarkAsRemove.size > 0) {

        // Go over all the existing item
        result = result.map((item) => {
            // If it's a link that has been marked as remove,
            // set the editStatus to "TO_DELETE"
            if (item.type === "link" && item.source && item.target) {
                const sourceTarget = item.source + "_" + item.target;
                if (linksToMarkAsRemove.has(sourceTarget)) {
                    // This link should be marked as toRemove
                    let itemData = item.data ? item.data : {};
                    return {
                        ...item,
                        data: {
                            ...itemData,
                            editStatus: "TO_DELETE",
                        }
                    }
                }
            }

            return item;
        });
    }

    return result;
}

// This function returns a set of links with each link structure "{SOURCE_ID}_{TARGET_ID}"
// That will be marked as "TO_DELETE" as they no longer appear in the nextComponents
// Of components that are being updated
const fetchLinksToMarkAsRemove = (componentsToUpdate, allElements) => {
    if (!componentsToUpdate || !allElements) return;

    let toRemove = new Set(); // Create result toRemove set

    // Create a mapping from a given component id to a set of the next component ids
    const existingCompIdToNext = allElements.reduce((acc, item) => {
        // If not a link or has no source/target, just return acc
        if (item.type !== "link" || !item.source || !item.target) return acc;

        // Define the target next components
        // Grab the value if it already exists, else set to a new Set
        let targetNextComps = acc[item.source] || new Set();
        targetNextComps.add(item.target); // Add the target to the set
        acc[item.source] = targetNextComps; // Update the acc

        return acc;
    }, {});

    // Go over the components to update and add any links to remove
    componentsToUpdate.forEach((item) => {
        // Grab the new next components to set
        const newNextComps = new Set(item.nextComponents || []);
        const oldNextCompIds = existingCompIdToNext[item.componentId];

        // We want the compIds that appear in existingCompIdToNext and not in newNextComps
        if (oldNextCompIds) {
            oldNextCompIds.forEach(oldNextCompId => {
                if (!newNextComps.has(oldNextCompId)) {
                    toRemove.add(`${item.componentId}_${oldNextCompId}`);
                }
            });
        }
    })

    return toRemove;
}