import React from "react";

// Material UI
import { Autocomplete, Chip, TextField } from "@mui/material";
import { styled } from "@mui/system";

import { getColourFromString } from "../../../../utils/ColourNameGenerator";

const MyChip = styled(Chip)(({ colour }) => ({
    border: `1px solid ${colour.border}`,
    backgroundColor: colour.bg,
    boxSizing: "border-box",
    borderRadius: "4px",
    marginRight: "6px",
}));

function ListInput({ pH, values, setValues }) {
    const handleArray = (s) => {
        if (typeof s === "string" || s instanceof String) {
            // We know it's a string
            if (s.length >= 2 && s[0] === "[" && s[s.length - 1] === "]") {
                // We know it's a list
                let eles = s.trim().slice(1, s.length - 1);
                return eles.split(",");
            } else return [];
        }
        return s;
    };

    return (
        <Autocomplete
            size="small"
            multiple
            options={[]}
            value={handleArray(values[pH.key].value)}
            freeSolo
            clearOnBlur
            fullWidth
            variant="standard"
            /**
            Adding this to allow for duplicates.
            Also the form front end is using autocomplete from a different version
            of mui which has the allow Duplicates flag.
            */
            isOptionEqualToValue={(option, value) => false}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={"Enter list items"} multiline />}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => <MyChip {...getTagProps({ index })} label={option} colour={getColourFromString(option)} />);
            }}
            onBlur={(e, value, reason) => {
                if (e.target.value.trim() === "") return;
                let newVal = { ...values };
                let type = newVal[pH.key].type;

                newVal[pH.key] = {
                    type: type,
                    value: [...newVal[pH.key].value, e.target.value],
                };

                setValues(newVal);
            }}
            onChange={(e, value, reason) => {
                if (reason === "clear") {
                    // Clear all
                    let newVal = { ...values };
                    let type = newVal[pH.key].type;

                    newVal[pH.key] = {
                        type: type,
                        value: [],
                    };

                    setValues(newVal);
                } else if (reason === "removeOption") {
                    // Delete the current option
                    let newVal = { ...values };
                    let type = newVal[pH.key].type;

                    newVal[pH.key] = {
                        type: type,
                        value: value,
                    };

                    setValues(newVal);
                } else if (reason === "createOption") {
                    // Create a new option
                    if (e.target.value.trim() === "") return;
                    let newVal = { ...values };
                    let type = newVal[pH.key].type;

                    newVal[pH.key] = {
                        type: type,
                        value: [...newVal[pH.key].value, e.target.value],
                    };

                    setValues(newVal);
                }
            }}
        />
    );
}

export default ListInput;
