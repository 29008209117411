import React, { Component } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { withStyles } from "@mui/styles";
import { TextField, Select, MenuItem, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";

import CalcultorStore from "../CalculatorStore";
import {
  Function,
  Perform,
  InputPlaceholder,
  DateTimeFunction,
  DateTimeFunctionUnit,
  DateTimeFunctionOperator,
} from "./Components";
import styles from "./CalculatorStyle";
import { toJS } from "mobx";
import Icon from "@mui/material/Icon";
import checkKey from "../../../utils/CheckKey";
import MentionEditor from "../../TextEditor/MentionEditor";
import ChipInput from "../../ChipInput/ChipInput";
import replacePlaceholderValue from "../../../utils/ReplacePlaceholderValue";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";

class ItemCalculation extends Component {
  state = {
    chips: ["js"],
    error: false,
    checkKey: false,
    errorMessage: "",
    updateState: true,
  };

  handleKeyChanges = (event, indexCalculation) => {
    const { data } = toJS(CalcultorStore);
    const value = checkKey(event, indexCalculation, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
    this.props.handleKeyChanges(value[0]);
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;

    this.setState({ updateState: !currentState });
  };

  handleRequestBefore = (chip) => {
    const { indexCalculation } = this.props;
    if (chip && chip.trim() !== "") {
      CalcultorStore.setCalculation(chip, indexCalculation, "operator");
      return true;
    } else {
      return false;
    }
  };

  handleRequestBeforeForDateTimeValue = (chip, type) => {
    const { indexCalculation } = this.props;
    if (chip) CalcultorStore.setCalculation(chip, indexCalculation, type);
    return true;
  };

  handleKeyChange = (e, indexCalculation) => {
    console.log(e.target.value)
    const placeholderValue = replacePlaceholderValue(e.target.value);
    CalcultorStore.setCalculation(placeholderValue, indexCalculation, "key");

    const { data } = toJS(CalcultorStore);
    const value = checkKey(placeholderValue, indexCalculation, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
    this.props.handleKeyChanges(value[0]);
  };

  render() {
    const { classes, indexCalculation, availablePlaceholders, isExpanded } = this.props;
    let { position, placeholders } = CalcultorStore;
    const {
      key,
      perform,
      operator,
      func,
      round,
      datetimefunc,
      datetimefuncUnit,
      datetimevalue1,
      datetimevalue2,
      datetimefuncOperator,
      datetimeFormat,
    } = this.props.calculation;

    placeholders = toJS(placeholders);

    let operatorArray = [];
    if (position == indexCalculation) operatorArray = placeholders;

    return (
      <div className={classes.wrapCalculator}>
        <Grid container xs={12}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginBottom: 24 }}
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={2}
              xl={2}
              style={{ flexBasis: "unset" }}
            >
              <div className={classes.label} style={{ paddingRight: 0 }}>
                {" "}
                Placeholder
              </div>
            </Grid>
            <Grid
              item
              xs={9}
              sm={9}
              md={9}
              lg={9}
              xl={9}
              style={{ marginLeft: 16 }}
            >
              <W86PlaceholderTextField 
                value={key}
                error={this.state.checkKey ? true : false}
                helperText={this.state.checkKey ? this.state.errorMessage : null}
                placeholderText="Enter placeholder for calculator"
                handleChange={(e) => this.handleKeyChange(e, indexCalculation)}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              style={{ textAlign: "right" }}
            >
              <DeleteIcon
                onClick={() =>
                  CalcultorStore.deleteCalculation(indexCalculation)
                }
                className={classes.deleteIcon}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginBottom: 24 }}
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={2}
              xl={2}
              style={{ flexBasis: "unset" }}
            >
              <div className={classes.label2}> Perform a</div>
            </Grid>
            <Grid
              item
              {...isExpanded && { xs: 4, sm: 4, md: 4, lg: 3, xl: 3 }}
              {...!isExpanded && { xs: 6 }}
              style={{ marginLeft: 5 }}
            >
              <div className={classes.select}>
                <Perform
                  value={perform}
                  className={classes.calculationSelect}
                  onChange={(value) => {
                    CalcultorStore.setCalculation(
                      value,
                      indexCalculation,
                      "perform"
                    );
                    this.forceUpdateState();
                  }}
                />
              </div>
            </Grid>
          </Grid>

          {perform && perform == "DATETIME" && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <Grid container display={"flex"} alignItems={"center"}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={2}
                  style={{ flexBasis: "unset" }}
                >
                  <div className={classes.label2}> Calculate</div>
                </Grid>
                <Grid
                  item
                  {...isExpanded && { xs: 4, sm: 4, md: 4, lg: 3, xl: 3 }}
                  {...!isExpanded && { xs: 6 }}
                  style={{ marginLeft: 8 }}
                >
                  <div className={classes.select} style={{ flexBasis: "unset" }}>
                    <DateTimeFunction
                      value={datetimefunc}
                      className={classes.calculationSelect}
                      onChange={(value) => {
                        CalcultorStore.setCalculation(
                          value,
                          indexCalculation,
                          "datetimefunc"
                        );
                        this.forceUpdateState();
                      }}
                    />
                  </div>
                </Grid>

                {datetimefunc && datetimefunc == "TIMEDIFFS" && (
                  isExpanded ? 
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{ flexBasis: "unset" }}
                    >
                      <div
                        className={classes.label2}
                        style={{ justifyContent: "center" }}
                      >
                        in
                      </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                      <DateTimeFunctionUnit
                        value={datetimefuncUnit}
                        className={classes.calculationSelect}
                        onChange={(value) => {
                          CalcultorStore.setCalculation(
                            value,
                            indexCalculation,
                            "datetimefuncUnit"
                          );
                          this.forceUpdateState();
                        }}
                      />
                    </Grid>
                  </> : 
                  <Grid container marginTop={3} display={"flex"} alignItems={"center"}>
                    <Grid
                      item
                      style={{ flexBasis: "unset" }}
                    >
                      <div className={classes.newLabel} style={{ marginRight: 16 }}>
                        in
                      </div>
                    </Grid>
                    <Grid item>
                      <DateTimeFunctionUnit
                        value={datetimefuncUnit}
                        className={classes.calculationSelect}
                        onChange={(value) => {
                          CalcultorStore.setCalculation(
                            value,
                            indexCalculation,
                            "datetimefuncUnit"
                          );
                          this.forceUpdateState();
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {perform &&
            perform == "DATETIME" &&
            datetimefunc &&
            datetimefunc == "ADDSUBS" && (
              <Grid container spacing={isExpanded ? 2 : 1}>
                <Grid
                  item
                  xs={isExpanded ? 4 : 6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 24,
                  }}
                >
                  <ChipInput
                    inputValue={datetimevalue1 ? [datetimevalue1] : []}
                    placeholders={availablePlaceholders}
                    placeholder="Insert a placeholder."
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    fullWidth
                    onAdd={(chip) => {
                      CalcultorStore.setCalculation(
                        chip,
                        indexCalculation,
                        "datetimevalue1"
                      );
                      this.forceUpdateState();
                    }}
                    onDelete={index => {
                      CalcultorStore.deleteChipByType(
                        index,
                        indexCalculation,
                        "datetimevalue1"
                      );
                      this.forceUpdateState();
                    }}
                    onFocus={() => {
                      this.setState({ error: false });
                      CalcultorStore.setFocus(
                        indexCalculation,
                        "datetimevalue1"
                      );
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={isExpanded ? 2 : 6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 24,
                  }}
                >
                  <DateTimeFunctionOperator
                    value={datetimefuncOperator}
                    className={classes.calculationSelect}
                    onChange={(value) => {
                      CalcultorStore.setCalculation(
                        value,
                        indexCalculation,
                        "datetimefuncOperator"
                      );
                      this.forceUpdateState();
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={isExpanded ? 4 : 6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 24,
                  }}
                >
                  <ChipInput
                    inputValue={datetimevalue2 ? [datetimevalue2] : []}
                    placeholders={availablePlaceholders}
                    placeholder="Insert a number or placeholder."
                    fullWidth
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    isNumber
                    onAdd={(chip) => {
                      CalcultorStore.setCalculation(
                        chip,
                        indexCalculation,
                        "datetimevalue2"
                      );
                      this.forceUpdateState();
                    }}
                    onDelete={index => {
                      CalcultorStore.deleteChipByType(
                        index,
                        indexCalculation,
                        "datetimevalue2"
                      );
                      this.forceUpdateState();
                    }}
                    onFocus={() => {
                      this.setState({ error: false });
                      CalcultorStore.setFocus(
                        indexCalculation,
                        "datetimevalue2"
                      );
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={isExpanded ? 2 : 6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 24,
                  }}
                >
                  <DateTimeFunctionUnit
                    value={datetimefuncUnit}
                    className={classes.calculationSelect}
                    onChange={(value) => {
                      CalcultorStore.setCalculation(
                        value,
                        indexCalculation,
                        "datetimefuncUnit"
                      );
                      this.forceUpdateState();
                    }}
                  />
                </Grid>

                <Grid
                  item
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className={classes.label2}>{"Timestamp format "}</div>
                </Grid>
                <Grid item>
                  <Select
                    onChange={(e) => {
                      CalcultorStore.setCalculation(
                        e.target.value,
                        indexCalculation,
                        "datetimeFormat"
                      );
                      this.forceUpdateState();
                    }}
                    variant={"outlined"}
                    color={"info"}
                    value={datetimeFormat}
                  >
                    <MenuItem value={"yyyy-MM-dd'T'HH:mm:ssX"}>
                      yyyy-mm-dd'T'HH:mm:ssZ (2013-09-29T18:46:19Z)
                    </MenuItem>
                    <MenuItem value={"dd MMMM yyyy HH:mm"}>
                      dd Month yyyy hh:mm (01 February 2021 14:01)
                    </MenuItem>
                    <MenuItem value={"dd/MM/yy HH:mm"}>
                      dd/mm/yy hh:mm (15/12/21 14:01)
                    </MenuItem>
                    <MenuItem value={"dd/MM/yyyy HH:mm"}>
                      dd/mm/yyyy hh:mm (15/12/2021 14:01)
                    </MenuItem>
                    <MenuItem value={"dd-MM-yyyy HH:mm"}>
                      dd-mm-yyyy hh:mm (15-12-2021 14:01)
                    </MenuItem>
                    <MenuItem value={"MM/dd/yyyy HH:mm"}>
                      mm/dd/yyyy hh:mm (12/15/2021 14:01)
                    </MenuItem>
                    <MenuItem value={"yyyy-MM-dd HH:mm"}>
                      yyyy-mm-dd hh:mm (2021-02-01 14:01)
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}

          {perform &&
            perform == "DATETIME" &&
            datetimefunc &&
            datetimefunc == "TIMEDIFFS" && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 24,
                  }}
                >
                  <ChipInput
                    inputValue={datetimevalue1 ? [datetimevalue1] : []}
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    placeholders={availablePlaceholders}
                    placeholder="Insert a placeholder."
                    fullWidth
                    onAdd={(chip) => {
                      CalcultorStore.setCalculation(
                        chip,
                        indexCalculation,
                        "datetimevalue1"
                      );
                      this.forceUpdateState();
                    }}
                    onDelete={index => {
                      CalcultorStore.deleteChipByType(
                        index,
                        indexCalculation,
                        "datetimevalue1"
                      );
                      this.forceUpdateState();
                    }}
                    onFocus={() => {
                      this.setState({ error: false });
                      CalcultorStore.setFocus(
                        indexCalculation,
                        "datetimevalue1"
                      );
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 24,
                  }}
                >
                  <ChipInput
                    inputValue={datetimevalue2 ? [datetimevalue2] : []}
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    placeholders={availablePlaceholders}
                    placeholder="Insert a placeholder."
                    fullWidth
                    onAdd={(chip) => {
                      CalcultorStore.setCalculation(
                        chip,
                        indexCalculation,
                        "datetimevalue2"
                      );
                      this.forceUpdateState();
                    }}
                    onDelete={index => {
                      CalcultorStore.deleteChipByType(
                        index,
                        indexCalculation,
                        "datetimevalue2"
                      );
                      this.forceUpdateState();
                    }}
                    onFocus={() => {
                      this.setState({ error: false });
                      CalcultorStore.setFocus(
                        indexCalculation,
                        "datetimevalue2"
                      );
                    }}
                  />
                </Grid>
              </Grid>
            )}
          {perform && perform == "FUNCTION" && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <Grid container display={"flex"} alignItems={"center"}>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={2}
                  lg={2}
                  xl={2}
                  style={{ flexBasis: "unset" }}
                >
                  <div className={classes.label2}> Find the</div>
                </Grid>
                <Grid
                  item
                  {...isExpanded && { xs: 4, sm: 4, md: 4, lg: 3, xl: 3 }}
                  {...!isExpanded && { xs: 6 }}
                  style={{ marginLeft: 8 }}
                >
                  <div className={classes.select} style={{ flexBasis: "unset" }}>
                    <Function
                      value={func}
                      className={classes.calculationSelect}
                      onChange={(value) => {
                        CalcultorStore.setCalculation(
                          value,
                          indexCalculation,
                          "func"
                        );
                        this.forceUpdateState();
                      }}
                    />
                  </div>
                </Grid>
                {isExpanded ? 
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                    style={{ marginLeft: 16 }}
                  >
                    of the following values:
                  </Grid> :
                  <Grid
                    item
                    xs={12}
                    marginTop={2}
                  >
                    of the following values:
                  </Grid>
                }
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={classes.gridContainer}>
          <div className={classes.footer}>
            {perform && perform == "FUNCTION" && (
              <React.Fragment>
                <div>
                  <ChipInput
                    inputValue={operator}
                    onPlaceholderSelected={this.props.onPlaceholderSelected}
                    placeholders={availablePlaceholders}
                    placeholder="Enter a number and press enter, or insert a placeholder. "
                    helperText="Input must be a number"
                    isNumber
                    fullWidth
                    onBeforeAdd={(chip) => {
                      this.handleRequestBefore(chip);
                      this.forceUpdateState();
                    }}
                    onDelete={index => {
                      CalcultorStore.deleteChip(index, indexCalculation);
                      this.forceUpdateState();
                    }}
                    onFocus={() => {
                      this.setState({ error: false });
                      CalcultorStore.setFocus(indexCalculation);
                    }}
                  />
                </div>
              </React.Fragment>
            )}
            {perform && perform == "BASIC" && (
              <MentionEditor
                availablePlaceholders={availablePlaceholders}
                placeholders={operatorArray}
                text={operator}
                minHeight={100}
                placeholder="Enter a number and press enter, or insert a placeholder. You can use the following operators to create a calculation: +, -, *, /, ^ as well as brackets ()."
                onChange={(value) => {
                  CalcultorStore.setCalculation(
                    value,
                    indexCalculation,
                    "operator"
                  );
                }}
                onFocus={() => {
                  CalcultorStore.setFocus(indexCalculation);
                }}
              />
            )}
          </div>

          {datetimefunc != "ADDSUBS" && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <Grid container display={"flex"} alignItems={"center"}>
                <Grid item>
                  <div className={classes.label2}> Round to</div>
                </Grid>
                <Grid item>
                  <div className={classes.round}>
                    <TextField
                      variant="outlined"
                      className="key_padding"
                      type="number"
                      value={round}
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                      onChange={(event) => {
                        CalcultorStore.setCalculation(
                          event.target.value,
                          indexCalculation,
                          "round"
                        );
                        this.forceUpdateState();
                      }}
                    />
                  </div>
                </Grid>
                <Grid item marginLeft={2}>
                  decimal place(s)
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ItemCalculation);
