import React, { Component } from "react";
import MaterialGrid from "@mui/material/Grid";
import MaterialCard from "@mui/material/Card";
import MaterialCardContent from "@mui/material/CardContent";
import MaterialTypography from "@mui/material/Typography";
import MaterialCardMedia from "@mui/material/CardMedia";
import FadeIn from "react-fade-in";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Button from "@mui/material/Button";
import { send_request } from "../../../utils/Request";
import { loadStripe } from "@stripe/stripe-js";
import CONFIG from "../../../config";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";
import ErrorIcon from "@mui/icons-material/Error";
import { CircularProgress, Divider } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { withParams } from "../../../Routes";
import SavingsBox from "./SavingsBox";
import FreeTrialRemainingBox from "./FreeTrialRemainingBox";
import WarningBox from "./WarningBox";

const publishableKey = CONFIG.PUBLISHABLEKEY;

const genericWarningText = "Looks like you are changing the number of licenses in your current subscription. You will need to process this change first before you can move to this plan.";

class PlanComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      colorPlan: "",
      userQuantity: this.props.userQuantity,
      totalPrice: 0,
      planPrice: 0,
      userPrice: this.props.listPrices.user,
      endUserPrice: this.props.listPrices.endUser,
      selectLoading: false,
      pricingText: [],
      planHeight: "",
      paymentSuccess: false,
      trialRemaining: null
    };
  }

  componentDidMount() {
    const { plan, listPrices, userQuantity } = this.props;
    let { planPrice } = this.state;
  
    switch (plan.tier) {
      case "FREE":
        this.setState({
          colorPlan: "#2196F3",
          pricingText: [
            "Email and chat support (Up to 48 hours response time)"
          ],
          planHeight: "650px"
        });
        break;
      case "FREE_TRIAL":
        this.setState({
          pricingText: [
            "Up to 28 days duration",
            "Email and chat support (Up to 48 hours response time)"
          ],
          planHeight: "650px"
        });
        break;
      case "STARTER":
        planPrice = listPrices.starter;
        this.setState({
          colorPlan: "#2196F3",
          planPrice,
          pricingText: [
            "1:1 onboarding and training via video call",
            "Email and chat support (Up to 48 hours response time)",
            "Our Builder in Residence will help you build your first custom workflow"
          ],
          planHeight: "650px"
        });
        break;
      case "PRO":
        planPrice = listPrices.pro;
        this.setState({
          colorPlan: "#1E78FF",
          planPrice,
          pricingText: [
            "1:1 onboarding and training via video call",
            "Email and chat support (Up to 48 hours response time)",
            "Our Builder in Residence will help you build your first custom workflow"
          ],
          planHeight: "650px"
        });
        break;
      case "PRO_PLUS":
        planPrice = listPrices.proPlus;
        this.setState({
          colorPlan: "#2C2CFF",
          planPrice,
          pricingText: [
            "Priority email, chat and 1:1 video call support",
            "1:1 onboarding and training via video call",
            "Our Builder in Residence will help you build up to 5 custom workflows",
            "Custom API integrations",
            "We will help migrate your workflows from existing systems into Workflow86"
          ],
          planHeight: "770px"
        });
        break;
      case "STARTER_ANNUAL":
        planPrice = listPrices.starterAnnual;
        this.setState({
          colorPlan: "#2196F3",
          planPrice,
          pricingText: [
            "1:1 onboarding and training via video call",
            "Email and chat support (Up to 48 hours response time)",
            "Our Builder in Residence will help you build your first custom workflow"
          ],
          planHeight: "650px"
        });
        break;
      case "PRO_ANNUAL":
        planPrice = listPrices.proAnnual;
        this.setState({
          colorPlan: "#1E78FF",
          planPrice,
          pricingText: [
            "1:1 onboarding and training via video call",
            "Email and chat support (Up to 48 hours response time)",
            "Our Builder in Residence will help you build your first custom workflow"
          ],
          planHeight: "650px"
        });
        break;
      case "PRO_PLUS_ANNUAL":
        planPrice = listPrices.proPlusAnnual;
        this.setState({
          colorPlan: "#2C2CFF",
          planPrice,
          pricingText: [
            "Priority email, chat and 1:1 video call support",
            "1:1 onboarding and training via video call",
            "Our Builder in Residence will help you build up to 5 custom workflows",
            "Custom API integrations",
            "We will help migrate your workflows from existing systems into Workflow86"
          ],
          planHeight: "770px"
        });
        break;
    }

    // If the current card is FREE TRIAL
    if (plan.tier === "FREE_TRIAL") {
      // If the user is on the FREE trial, get the remaining time
      // Else set color plan to blue
      if (plan.tier === this.props.tier) {
        send_request("authz-service/get_free_trial_remaining", "", "", "get")
            .then((res) => {
                let remaining = res.data;
                this.setState({ trialRemaining: remaining });

                if (remaining === 0) this.setState({ colorPlan: "rgba(176, 0, 32, 1)"})
                else this.setState({ colorPlan: "#79BFF7"})
            })
            .catch((err) => {
                console.log(err);
            });
      } else this.setState({ colorPlan: "#79BFF7"})
    }
  }

  handleSubcription = async totalPrice => {
    let { errorMessage } = this.state;
    let { interval } = this.props;
    const { plan, tier, userQuantity, endUserQuantity } = this.props;
    this.setState({ selectLoading: true });
    if (errorMessage) {
    } else {
      let session = {
        interval,
        plan: plan.tier,
        userQuantity,
        endUserQuantity
      };

      if (plan.tier === "FREE") {
        this.setState({ selectLoading: false });
        this.props.navigate(
          `/organization?setPlan=${plan.tier}&userQuantity=${userQuantity}&endUserQuantity=${endUserQuantity}&totalPrice=${totalPrice}&interval=${interval}`
        );
      } else {
        if (tier === "FREE" || tier === "FREE_TRIAL") {
          const json = await send_request(
            `authz-service/create-checkout-session`,
            session,
            "",
            "POST"
          ).catch(err => {
            throw Error(err.message);
          });
          if (json && json.status == "200") {
            // Setup event handler to create a Checkout Session when button is clicked
            const stripePromise = loadStripe(publishableKey);

            const stripe = await stripePromise;

            stripe.redirectToCheckout({
              sessionId: json.data.sessionId
            });

            this.setState({ selectLoading: false });
          } else {
            this.setState({ selectLoading: false });
          }

        } else {
          this.setState({ selectLoading: false });

          // If we are going to FREE_TRIAL, set end user and user quantity accordingly
          if (plan.tier === "FREE" || plan.tier === "FREE_TRIAL") {

            let userPrice = interval === "monthly" ? this.props.listPrices.user : this.props.listPrices.userAnnual;
            let endUserPrice = interval === "monthly" ? this.props.listPrices.endUser : this.props.listPrices.endUserAnnual;

            let freeUsers = userPrice[0].upTo;
            let freeEndUsers = endUserPrice[0].upTo;

            this.props.navigate(
              `/organization?setPlan=${plan.tier}&userQuantity=${freeUsers}&endUserQuantity=${freeEndUsers}&totalPrice=${totalPrice}&interval=${interval}`
            );
          } else {
            this.props.navigate(
              `/organization?setPlan=${plan.tier}&userQuantity=${userQuantity}&endUserQuantity=${endUserQuantity}&totalPrice=${totalPrice}&interval=${interval}`
            );
          }

        }
      }
    }
  };

  getCorrectSelectButton = (firstLogin, givenPlanTier, clientTier, selectLoading, currentTier, newTier, currentUserQuantity, newUserQuantity, currentEndUserQuantity, newEndUserQuantity, currentRank, newRank, numUpTo, minEndUserQuantity, totalPrice) => {
    // If this is the first time they're logging in
    if (firstLogin) {
      if (givenPlanTier === "FREE" || givenPlanTier == "FREE_TRIAL") {
          return <Link to={"/payment_success"} style={{ textDecoration: "none" }}>
              <Button className="select-button" color="primary" variant={"contained"} size={"md"} type="submit" disabled={selectLoading ? true : false}>
                  SELECT
                  {selectLoading && <CircularProgress size={24} className="buttonProgress" />}
              </Button>
          </Link>;
      } else {
          return <Button
              className="select-button"
              color="primary"
              variant={"contained"}
              size={"md"}
              type="submit"
              onClick={() => (givenPlanTier == "FREE_TRIAL" || givenPlanTier === "FREE" ? "" : this.handleSubcription(totalPrice))}
              disabled={selectLoading ? true : false}
          >
              SELECT
              {selectLoading && <CircularProgress size={24} className="buttonProgress" />}
          </Button>;
      }
    } else {
      // They are not first login
      if (givenPlanTier !== "FREE_TRIAL" || clientTier !== "FREE_TRIAL") {
        return this.getBasedOnPlanStatus(currentTier, newTier, currentUserQuantity, newUserQuantity, currentEndUserQuantity, newEndUserQuantity, currentRank, newRank, numUpTo, minEndUserQuantity, totalPrice, selectLoading);
      }
    }
  }

  getSelectButton = (totalPrice, selectLoading, disabled) => {
    return <Button
            className="select-button"
            color="primary"
            variant={"contained"}
            size={"md"}
            type="submit"
            onClick={() => this.handleSubcription(totalPrice)}
            disabled={disabled || selectLoading ? true : false}
        >
            SELECT
            {selectLoading && <CircularProgress size={24} className="buttonProgress" />}
        </Button>;
  }

  getBasedOnPlanStatus = (currentTier, newTier, currentUserQuantity, newUserQuantity, currentEndUserQuantity, newEndUserQuantity, currentRank, newRank, numUpTo, minEndUserQuantity, totalPrice, selectLoading) => {

    // Handle FREE/FREE_TRIAL needing to be visible when not on them
    if (currentTier !== "FREE" && currentTier !== "FREE_TRIAL" && (newTier === "FREE" || newTier === "FREE_TRIAL")) return this.getSelectButton(totalPrice, selectLoading, false);
    
    // Handle FREE & FREE_TRIAL always being disabled when on that plan i.e: you cannot select them
    if (currentTier === newTier && (currentTier === "FREE" || currentTier === "FREE_TRIAL")) return this.getSelectButton(totalPrice, selectLoading, true);
    
    // Handle less than 0
    if (newUserQuantity <= 0 || newEndUserQuantity <= 0) 
      return <WarningBox text="Increase the number of licenses in your current plan before changing to this plan" />

    // Handle selecting less than up to
    if (newUserQuantity < numUpTo || newEndUserQuantity < minEndUserQuantity) 
      return <WarningBox text={`You must have at least ${numUpTo} Admin/Editors and ${minEndUserQuantity} End Users. Please increase the number of licenses in your current plan`} />
    
    // Handle current tier
    if (currentTier === newTier) {
      if (newUserQuantity == currentUserQuantity && newEndUserQuantity == currentEndUserQuantity) return this.getSelectButton(totalPrice, selectLoading, true);
      if (newUserQuantity >= currentUserQuantity && newEndUserQuantity >= currentEndUserQuantity) {
        return this.getSelectButton(totalPrice, selectLoading, false);
      }
      if (newUserQuantity <= currentUserQuantity && newEndUserQuantity <= currentEndUserQuantity) {
        return <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <WarningBox text="Looks like you are changing the number of licenses. Before you can change your plan, you will need to process this change at your current subscription by clicking Select below." />
        </Grid>
        <Grid item xs={12}>
          {this.getSelectButton(totalPrice, selectLoading, false)}
        </Grid>
      </Grid>
      }

      // Handle if on free tier
      if (newTier === "FREE" || newTier === "FREE_TRIAL") return this.getSelectButton(totalPrice, selectLoading, true)
    } else {
      if (newUserQuantity < currentUserQuantity || newEndUserQuantity < currentEndUserQuantity) return <WarningBox text={genericWarningText} />
    }

    // Handle lower tiers
    if (newRank < currentRank) {
      if (newUserQuantity > currentUserQuantity || newEndUserQuantity > currentEndUserQuantity) return <WarningBox text={genericWarningText} />
    }

    return this.getSelectButton(totalPrice, selectLoading, false);
  }

  checkFreeTrial = (rem) => {
    if (rem === 0) return "current-tier-red-border"
    return "current-tier-border";
  }

  checkPlanStatus = (
    currentTier,
    newTier,
    currentUserQuantity,
    newUserQuantity,
    currentEndUserQuantity,
    newEndUserQuantity,
    currentRank,
    newRank,
    numUpTo,
    minEndUserQuantity
  ) => {
    let planStatus = false;
    if (currentTier == newTier) {
      if (
        newUserQuantity == currentUserQuantity &&
        newEndUserQuantity == currentEndUserQuantity
      ) {
        planStatus = true;
      } else {
        if (newTier === "FREE" || newTier == "FREE_TRIAL") {
          planStatus = true;
        } else {
          planStatus = false;
        }
      }
    } else {
      planStatus = false;
    }

    if (newUserQuantity < numUpTo || newEndUserQuantity < minEndUserQuantity) {
      planStatus = true;
    }

    if (currentRank > newRank || currentRank < newRank) {
      if (newTier === "FREE" || newTier === "FREE_TRIAL") planStatus = false;
      else if (currentUserQuantity > newUserQuantity || currentEndUserQuantity > newEndUserQuantity) planStatus = true;
      else if (newRank < currentRank){
        if (newUserQuantity > currentUserQuantity || newEndUserQuantity > currentEndUserQuantity) planStatus = true;
      }
    }

    if (newUserQuantity <= 0 || newEndUserQuantity <= 0) {
      planStatus = true;
    }

    return planStatus;
  };

  checkIfColorDisabled = (currentTier, newTier, currentUserQuantity, newUserQuantity, currentEndUserQuantity, newEndUserQuantity, currentRank, newRank, numUpTo, minEndUserQuantity, colorPlan) => {
    let status = this.checkPlanStatus(currentTier, newTier, currentUserQuantity, newUserQuantity, currentEndUserQuantity, newEndUserQuantity, currentRank, newRank, numUpTo, minEndUserQuantity);

    if (!status) return colorPlan;
    else {
      // Need to check if on current tier
      if (currentTier === newTier && newUserQuantity == currentUserQuantity && newEndUserQuantity == currentEndUserQuantity) return colorPlan;
      if (currentTier === newTier && (currentTier === "FREE" || currentTier === "FREE_TRIAL")) return colorPlan;
      else return "rgba(218, 218, 218, 1)";
    }
  }

  render() {
    const {
      plan,
      tier,
      userQuantity,
      currentUserQuantity,
      currentPlan,
      firstLogin,
      endUserQuantity,
      currentEndUserQuantity,
      interval
    } = this.props;
    const { planPrice, selectLoading } = this.state;

    const currentTier = tier;
    const newTier = plan.tier;

    let totalPrice = 0;
    let userPrice = interval === "monthly" ? this.props.listPrices.user : this.props.listPrices.userAnnual;
    let endUserPrice = interval === "monthly" ? this.props.listPrices.endUser : this.props.listPrices.endUserAnnual;

    let numUpTo = [];
    let unitAmount = [];
    for (let i = 0; i < userPrice.length; i++) {
      numUpTo.push(userPrice[i].upTo);
      unitAmount.push(userPrice[i].unitAmount / 100);
    }

    let newUserQuantity = userQuantity;
    let newEndUserQuantity = endUserQuantity;
    let currentRank = currentPlan.tierRank;
    let newRank = plan.planRank;

    if (planPrice > 0) {
      totalPrice = planPrice;
    } else {
      totalPrice = "Free";
    }

    if (planPrice > 0) {
      let runningUserQuantity = newUserQuantity;
      let currentTierLimit = 0;
      for (let a = 0; a < userPrice.length && runningUserQuantity > 0; a++) {
        if (userPrice[a].upTo) {
          //there is a limit for the tier
          currentTierLimit = userPrice[a].upTo - currentTierLimit;

          let b =
            runningUserQuantity < currentTierLimit
              ? runningUserQuantity
              : currentTierLimit;

          totalPrice = totalPrice + b * (userPrice[a].unitAmount / 100);

          runningUserQuantity = runningUserQuantity - b;
        } else {
          //no limit, most probably the last one
          totalPrice =
            totalPrice + runningUserQuantity * (userPrice[a].unitAmount / 100);
        }
      }
    } else {
      totalPrice = "Free";
    }

    //calculate endUser pricing
    if (totalPrice != "Free") {
      let runningEndUserQuantity = newEndUserQuantity;
      let currentTierLimit = 0;
      for (
        let a = 0;
        a < endUserPrice.length && runningEndUserQuantity > 0;
        a++
      ) {
        if (endUserPrice[a].upTo) {
          //there is a limit for the tier
          currentTierLimit = endUserPrice[a].upTo - currentTierLimit;

          let b =
            runningEndUserQuantity < currentTierLimit
              ? runningEndUserQuantity
              : currentTierLimit;

          totalPrice = totalPrice + b * (endUserPrice[a].unitAmount / 100);

          runningEndUserQuantity = runningEndUserQuantity - b;
        } else {
          //no limit, most probably the last one
          totalPrice =
            totalPrice +
            runningEndUserQuantity * (endUserPrice[a].unitAmount / 100);
        }
      }
    }

    return (
      <MaterialGrid
        item
        xs={12}
        md={6}
        sm={12}
        lg={3}
        xl={3}
        style={{
          marginBottom: "32px",
          justifyContent: "center",
          borderRadius: "10px !important"
        }}
      >
        <Grid style={{ position: "relative" }}>
          <MaterialCard
            className={`cardStarter ${tier === plan.tier && (tier !== "FREE_TRIAL" ? "current-tier-border" : this.checkFreeTrial(this.state.trialRemaining))}`}
            style={{ 
              background: `${this.checkIfColorDisabled(currentTier, 
                newTier, currentUserQuantity, newUserQuantity, currentEndUserQuantity, newEndUserQuantity, 
                currentRank, newRank, numUpTo[0], endUserPrice[0].upTo, this.state.colorPlan)}`, 
              borderBottom: "none" }}
          >
            <MaterialCardContent className="card-content">
              {plan.name}
            </MaterialCardContent>
          </MaterialCard>

          <MaterialCard className={`card-style-set-plan ${tier === plan.tier && `current-tier-shadow ${(tier !== "FREE_TRIAL" ? "current-tier-border" : this.checkFreeTrial(this.state.trialRemaining))}`}`}>
            {tier === plan.tier && 
              <Grid item xs={12} className="currentSubscriptionBox">
                Your current subscription
              </Grid>
            }
            {tier === "FREE" && tier === plan.tier && 
              <Grid item xs={12} className="phaseSubscriptionBox">
                🚩 This plan is being phased out
              </Grid>
            }
            {tier === "FREE_TRIAL" && tier === plan.tier && <FreeTrialRemainingBox trialRemaining={this.state.trialRemaining} />}

            <MaterialCardContent className="textContentSetPlan">
              <FadeIn transitionDuration="1000">
                <MaterialTypography
                  variant="h0"
                  component="p"
                  className="planPrice"
                >
                  {totalPrice != "Free" ? <>${totalPrice.toLocaleString()}</> : totalPrice.toLocaleString()}
                </MaterialTypography>
                {totalPrice != "Free" ? (
                  <>
                    <span className="textPrice">per {interval === "annual" ? "year" : "month"} (excl. taxes)</span>
                    <br />
                  </>
                ) : (
                  <>
                    <span className="textPrice">
                      Maximum of {numUpTo[0]} Admin/Editors, {endUserPrice[0].upTo} End Users
                    </span>
                    <br />
                  </>
                )}
                {plan.tier !== "FREE" && plan.tier !== "FREE_TRIAL" && interval === "annual" && 
                  <SavingsBox 
                    prices={this.props.listPrices} 
                    tier={plan.tier} 
                    newUserQuantity={newUserQuantity}
                    newEndUserQuantity={newEndUserQuantity}
                  />
                }
              </FadeIn>

              <Grid
                container
                spacing={2}
                className="setPlanField"
                style={{ paddingTop: 32 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="planText"
                >
                  Workflows
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={5} xl={5}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "100%", background: "#F9F9F9" }}
                      placeholder=""
                      variant="outlined"
                      type="text"
                      className="plan-input-style"
                      value={"Unlimited"}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                className="setPlanField"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="planText"
                >
                  Actions per month
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={5} xl={5}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "100%", background: "#F9F9F9" }}
                      placeholder=""
                      variant="outlined"
                      type="number"
                      className="plan-input-style"
                      value={plan.maxSessions}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                  <Tooltip
                    title="Each time a component is executed, this counts as an action."
                    placement="right"
                  >
                    <HelpOutlineIcon style={{ color: "#868686" }} />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="setPlanField">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="planText"
                >
                  File Uploads and Storage (GB)
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={5} xl={5}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "100%", background: "#F9F9F9" }}
                      placeholder=""
                      variant="outlined"
                      type="number"
                      className="plan-input-style"
                      disabled={true}
                      value={plan.maxStorage}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                  <Tooltip
                    title="Storage of all files generated and uploaded from workflows"
                    placement="right"
                  >
                    <HelpOutlineIcon style={{ color: "#868686" }} />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="setPlanField">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="planText"
                >
                  Database Records
                </Grid>
                <Grid item xs={10} sm={5} md={5} lg={5} xl={5}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "100%", background: "#F9F9F9" }}
                      placeholder=""
                      variant="outlined"
                      type="number"
                      className="plan-input-style"
                      disabled={true}
                      value={plan.maxDatabaseSize}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                  <Tooltip
                    title="Number of database records you have access to"
                    placement="right"
                  >
                    <HelpOutlineIcon style={{ color: "#868686" }} />
                  </Tooltip>
                </Grid>
              </Grid>

              <Divider style={{ marginTop: 20, marginBottom: 8 }} />

              <Grid container spacing={2} style={{ textAlign: "left" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pricingTextStyle"
                >
                  {this.state.pricingText.map(i => (
                    <div style={{ padding: "6px 6px" }}>{i}</div>
                  ))}
                </Grid>
              </Grid>
            </MaterialCardContent>
            <MaterialCardContent className="buttonSelect">
              {this.getCorrectSelectButton(firstLogin, plan.tier, tier, selectLoading, currentTier, 
                newTier, currentUserQuantity, newUserQuantity, currentEndUserQuantity, newEndUserQuantity, currentRank,
                newRank, numUpTo[0], endUserPrice[0].upTo, totalPrice)}
            </MaterialCardContent>
          </MaterialCard>
        </Grid>
      </MaterialGrid>
    );
  }
}

export default withParams(PlanComponent);
