import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import ReactHtmlParser from "react-html-parser";
import DOMPurify from "dompurify";

const styles = (theme) => ({
  root: {},
  shaded: {
    backgroundColor: "#E7E7E7",
  },
  responsiveDiv: {
    position: "relative",
    paddingBottom: "42.1875%",
    height: 0,
  },
  responsiveIFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});

const VideoEmbed = (props) => {
  const { classes } = props;

  const [snippet, setSnippet] = useState(props.data.snippet);
  const [url, setUrl] = useState(props.data.url);
  const [embedType, setEmbedType] = useState(props.data.embedType);
  const [size, setSize] = useState(props.data.size);
  const [width, setWidth] = useState(props.data.width);
  const [height, setHeight] = useState(props.data.height);

  useEffect(() => {
    setSnippet(props.data.snippet);
    setEmbedType(props.data.embedType);
    setSize(props.data.size);
    setWidth(props.data.width);
    setHeight(props.data.height);
    setUrl(props.data.url);
  }, [props.data]);

  try {
    if (!snippet && !url)
      return (
        <div className={classes.root}>
          <div
            className={classes.shaded}
            style={{ height: "160px", width: "100%" }}
          ></div>
        </div>
      );
    else {
      return (
        <div className={classes.root}>
          {embedType === "URL" ? (
            size === "RESPONSIVE" ? (
              <div className={classes.responsiveDiv}>
                <iframe
                  src={url}
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  className={classes.responsiveIFrame}
                />
              </div>
            ) : (
              <iframe
                style={{ width: width, height: height }}
                src={url}
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              />
            )
          ) : (
            ReactHtmlParser(DOMPurify.sanitize(snippet, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }))
          )}
        </div>
      );
    }
  } catch (e) {
    return <h5>Error encountered</h5>;
  }
};

VideoEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoEmbed);
