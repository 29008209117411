import React, { Component } from "react";
import { Card, CardContent, Typography, Grid, CircularProgress } from "@mui/material";
import { format } from "../../utils/Date";
import "./MarketplacePage.css";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import Tooltip from "@mui/material/Tooltip";
import { Emoji } from "emoji-mart";
import projectStore from "../ProjectCanvas/ProjectStore";
import PortalModal from '../Portal/PortalModal';

class MarketplaceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      iframeLoading: true,
    };
  }

  truncateDescription = (description) => {
    if (!description) {
      return;
    }

    if (description.length > 100) {
      return description.substring(0, 100) + "...";
    } else {
      return description;
    }
  };

  renderEmoji = (imagePath) => {
    // Checks if image path is not an emoji
    if (!imagePath || imagePath.charAt(0) !== ":") {
      return ":twisted_rightwards_arrows:";
    } else {
      return imagePath;
    }
  };

  renderCategories = (categories) => {
    let categoriesString = "";

    if (!categories) {
      return "";
    }

    categories.forEach((c, idx) => {
      if (idx === categories.length - 1) {
        categoriesString +=
          " " + projectStore.state.workflowCategoriesMapping[c];
        return;
      }
      categoriesString +=
        projectStore.state.workflowCategoriesMapping[c] + " | ";
    });

    return categoriesString;
  };

  handleCardClick = () => {
    // Pre-fetch the iframe content
    const iframe = document.createElement('iframe');
    iframe.src = `/template/${this.props.template.projectId}`;
    
    // Open the modal immediately
    this.setState({ modalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handleIframeLoad = () => {
    this.setState({ iframeLoading: false });
  };

  render() {
    const { template } = this.props;
    const { modalOpen, iframeLoading } = this.state;

    return (
      <>
        <Card 
          className="marketplace-card"
          onClick={this.handleCardClick}
          data-tut="reactour__marketplace__download"
        >
          <CardContent className="card-content-wrapper">
            <div className="emoji-wrapper">
              <Emoji
                set="twitter"
                size={36}
                emoji={this.renderEmoji(template.imagePath)}
              />
            </div>
            
            <Tooltip title={template.name} placement="bottom-start">
              <Typography className="card-name">{template.name}</Typography>
            </Tooltip>

            <Tooltip title={template.author} placement="bottom-start">
              <Typography className="card-author">
                By <span className="author-name">{template.author}</span>
              </Typography>
            </Tooltip>

            <Tooltip title={template.description}>
              <Typography className="card-description">
                {this.truncateDescription(template.description)}
              </Typography>
            </Tooltip>

            <Tooltip title={this.renderCategories(template.categories)}>
              <Typography className="categories">
                {this.renderCategories(template.categories)}
              </Typography>
            </Tooltip>

            <div className="meta-container">
              <Tooltip title={format(template.lastModified, null)} placement="bottom-start">
                <Typography className="last-updated">
                  Last updated {format(template.lastModified, null, "relative")}
                </Typography>
              </Tooltip>
              <Typography className="downloads">
                {template.downloads} downloads
              </Typography>
            </div>
          </CardContent>
        </Card>

        <PortalModal
          open={modalOpen}
          onClose={this.handleCloseModal}
          url={`/template/${template.projectId}`}
        >
          {iframeLoading && (
            <div className="circular-progress-container">
              <CircularProgress />
            </div>
          )}
          <iframe
            className="full-screen-iframe"
            src={`/template/${template.projectId}`}
            onLoad={this.handleIframeLoad}
          />
        </PortalModal>
      </>
    );
  }
}

export default MarketplaceCard;