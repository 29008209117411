import React from "react";
import { MentionsInput, Mention } from "react-mentions";
import { Grid, TextField, MenuItem, Button, Select } from "@mui/material";

function Function(props) {
  return (
    <Select
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className={`dropdown-mat selectStandard ${props.className}`}
      displayEmpty
      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={""}> Select a function </MenuItem>
      <MenuItem value={"SUM"}> Sum </MenuItem>
      <MenuItem value={"AVERAGE"}> Average </MenuItem>
      <MenuItem value={"MODE"}> Mode </MenuItem>
      <MenuItem value={"STANDARD"}> Standard deviation </MenuItem>
      <MenuItem value={"RANGE"}> Range </MenuItem>
      <MenuItem value={"MINIMUM"}> Minimum value </MenuItem>
      <MenuItem value={"MAXIMUM"}> Maximum value </MenuItem>
    </Select>
  );
}
function Perform(props) {
  return (
    <Select
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className={`dropdown-mat selectStandard ${props.className}`}
     
      displayEmpty
      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={"BASIC"}> Basic calculation </MenuItem>
      <MenuItem value={"DATETIME"}> DateTime calculation </MenuItem>
      <MenuItem value={"FUNCTION"}> Function </MenuItem>
    </Select>
  );
}

function DateTimeFunction(props) {
  return (
    <Select
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className={`dropdown-mat selectStandard ${props.className}`}

      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
      displayEmpty
      defaultValue={""}
    >      
      <MenuItem value={""}> Select a function </MenuItem>
      <MenuItem value={"TIMEDIFFS"}> time difference </MenuItem>
      <MenuItem value={"ADDSUBS"}> add/substract time </MenuItem>
    </Select>
  );
}

function DateTimeFunctionUnit(props) {
  return (
    <Select
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className={`dropdown-mat selectStandard ${props.className}`}

      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
      defaultValue={"MINUTES"}
    >      
      <MenuItem value={"MINUTES"}> minutes </MenuItem>
      <MenuItem value={"HOURS"}> hours </MenuItem>
      <MenuItem value={"DAYS"}> days </MenuItem>
      <MenuItem value={"WEEKS"}> weeks </MenuItem>
      <MenuItem value={"MONTHS"}> months </MenuItem>
    </Select>
  );
}

function DateTimeFunctionOperator(props) {
  return (
    <Select
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className={`dropdown-mat selectStandard ${props.className}`}
      value={props.value}
      onChange={event => {
        props.onChange(event.target.value);
      }}
      defaultValue={"ADD"}
    >      
      <MenuItem value={"ADD"}> add </MenuItem>
      <MenuItem value={"SUBSTRACT"}> substract </MenuItem>
    </Select>
  );
}

function InputPlaceholder(props) {
  const data = props.data
    ? props.data.map(item => {
        return {
          id: item.key,
          display: item.key
        };
      })
    : [];
  const error = props.value && props.error ? "mentions-error" : "";
  return (
    <div>
      <MentionsInput
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        singleLine={props.singleLine}
        placeholder={props.placeholder}
        className={`mentions ${props.className} ${error}`}
      >
        <Mention trigger="@" data={data} className="mentions__mention" />
      </MentionsInput>
      {props.value && props.error && (
        <p style={{ color: "red" }}>{props.helperText}</p>
      )}
    </div>
  );
}
export { Function, Perform, InputPlaceholder, DateTimeFunction, DateTimeFunctionUnit, DateTimeFunctionOperator };
