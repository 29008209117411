// this function ensuraes that the note positions are cleaned
// before saving and we are not saving temporary data
export function filterPropertiesBeforeSave(array) {
  if (!array) {
    return;
  }

  // Collect valid note IDs
  const validNoteIds = new Set(
    array.filter(obj => obj.type === "note").map(note => note.id)
  );

  // Helper function to check if a UUID is valid
  const isValidUuid = id => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(id);
  };

  return array
    .map(obj => {
      // Common properties to remove
      const {
        isTemp,
        positionAbsolute,
        selected,
        dragging,
        data,
        ...cleanedObj
      } = obj;

      // Handle notes
      if (obj.type === "note") {
        if (data && typeof data === "object") {
          const {
            isTemp,
            positionAbsolute,
            selected,
            dragging,
            ...cleanedData
          } = data;
          
        
          cleanedObj.data = cleanedData;

        }
        return cleanedObj;
      }

      // Handle links
      if (obj.type === "link") {

        const { source, target, data } = obj;
        // Remove link if it doesn't connect to a valid note or has an invalid UUID
        if (!validNoteIds.has(target) || !isValidUuid(source)) {
          return null;
        }

           // Retain data if data.label is not empty
           if (data && data.label) {
            cleanedObj.data = cleanDataObject(data);
          }
          return cleanedObj;
      }

      // Return the object unchanged if it doesn't meet the criteria
      return obj;
    })
    .filter(obj => obj !== null); // Remove null entries (filtered out links)
}

export function cleanArray(array) {
  return array.map(obj => {
    // Create a new object for each element in the array
    let cleanedObj = {};

    // Iterate through each key in the object
    Object.keys(obj).forEach(key => {
      // Copy only the attributes that are not null or undefined
      if (obj[key] !== null && obj[key] !== undefined) {
        cleanedObj[key] = obj[key];
      }
    });

    // Check if the object type is 'link' and data.label is empty
    if (obj.type === "link" && (!obj.data?.label || obj.data.label.trim() === "")) {
      delete cleanedObj.data;
    }
    return cleanedObj;
  });
}

  // Helper function to clean null and undefined from data objects
  const cleanDataObject = data => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
  };
