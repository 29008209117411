import React, { useCallback, useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { toJS } from "mobx";
import {makeStyles, withStyles} from "@mui/styles";
//css
const useStyles = makeStyles({
    instructionInfo: (props) => ({
        width: "145%",
        position: "absolute",
        top: props.top,
        borderRadius: "10px",
        fontSize: "12px",
        zIndex: 1,
        outline: "none",
        resize: "none",
        overflow: "hidden"
    })

});
const InstructionsInfo = ({ data, parent, height, textRef, ...props }) => {
    const [top, setTop] = useState("0px");
    let totalHeight = 20; // little padding b/w heights

    const handleDoubleClick = useCallback((event) => {
        event.stopPropagation();
    }, []);

    useEffect(() => {
        let parentHeight = 0;
        let descHeight = 0;

        const updateHeight = () => {
            setTop(`${totalHeight + parentHeight + descHeight}px`);
        };

        const resizeCallback = (entries) => {
            for (const entry of entries) {
                if (entry.target === parent.current) {
                    parentHeight = entry.contentRect.height;
                } else if (entry.target === textRef.current) {
                    descHeight = entry.contentRect.height;
                }
            }
            updateHeight();
        };

        const resizeObserver = new ResizeObserver(resizeCallback);

        if (parent.current) {
            resizeObserver.observe(parent.current);
        }
        if (textRef.current) {
            resizeObserver.observe(textRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [parent, textRef]);

    const renderInstructionFields = () => {
        return Object.keys(data.instructions).map((instructionKey) => {
            if (data.instructions[instructionKey]) { // Check if the instruction is not null
                const label = instructionKey.replace(/([A-Z])/g, ' $1').trim();
                let formattedLabel="✨ New Component"
                if(label!=="build Instruction")
                    formattedLabel="📝 Edit Component"
                /*let formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);
                formattedLabel = formattedLabel.replace("Instruction", "Component Proposal");
*/
                return (
                    <div key={instructionKey}>
                        <b>{formattedLabel}:</b>
                        <TextField
                            multiline
                            style={{ width: "100%", zIndex: "1" }}
                            InputProps={{ readOnly: true, disableUnderline: true }}
                            variant={"standard"}
                            value={data.instructions[instructionKey]}
                        />
                    </div>
                );
            } else {
                return null; // Do not render anything if instruction is null
            }
        });
    };
   // const classes = props.classes;
    const classes = useStyles({ top });

    return (
        <div
            className={classes.instructionInfo}
             onDoubleClick={handleDoubleClick}
        >
            {renderInstructionFields()}
        </div>
    );
}

export default  (InstructionsInfo);
