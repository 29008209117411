import React from "react";

import { Link } from "react-router-dom";

// MUI
import { Grid, TextField } from "@mui/material";
import {
  Dropdown,
  InputLabel,
  OpenWorkflowText,
  BlueTextCenter,
  StyledOpenInNewIcon,
  BoldText
} from "./StyledComponents";

function Dropdowns(props) {
  const handleSelectProject = newProjectId => {
    props.updateState({
      sourceProjectId: newProjectId,
      sourceComponentId: "",
      connectionData: null
    }).then(() => props.handleSaveComponent())
    props.getComponents(newProjectId);
  };

  const handleSelectComponent = newComponentId => {
    props.updateState({ sourceComponentId: newComponentId });
  };

  const getProjectLabel = projId => {
    let proj = props.projects.find(proj => proj.id === projId);
    return proj;
  };

  const getComponentLabel = compId => {
    let comp = props.components.find(comp => comp.id === compId);
    return comp;
  };

  const renderProjectDropdown = () => {
    return (
      <Grid item xs={12}>
        <InputLabel>
          Select the <BoldText>Source Workflow </BoldText> that will trigger
          this component
        </InputLabel>
        <Dropdown
          value={getProjectLabel(props.sourceProjectId)}
          onChange={(e, newValue) => {
            if (newValue != null) handleSelectProject(newValue.id);
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label ? option.label : option.id}
            </li>
          )}
          size={"small"}
          options={props.projects}
          renderInput={params => (
            <TextField {...params} placeholder={"Select project"} />
          )}
        />
      </Grid>
    );
  };

  const renderComponentDropdown = () => {
    return (
      <>
        <Grid item xs={12}>
          <Link
            to={`/project/canvas/${props.sourceProjectId}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <OpenWorkflowText>
              <BlueTextCenter>
                <StyledOpenInNewIcon />
                Open {getProjectLabel(props.sourceProjectId).label} in a new tab
              </BlueTextCenter>
            </OpenWorkflowText>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            Select the <BoldText>Trigger another Workflow</BoldText> component
            in the <BoldText>Source Workflow</BoldText>
          </InputLabel>
          <Dropdown
            value={getComponentLabel(props.sourceComponentId)}
            onChange={(e, newValue) => {
              if (newValue != null) handleSelectComponent(newValue.id);
            }}
            size={"small"}
            options={props.components}
            renderInput={params => (
              <TextField {...params} placeholder={"Select component"} />
            )}
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      {props.projects && renderProjectDropdown()}
      {props.components && props.sourceProjectId && renderComponentDropdown()}
    </>
  );
}

export default Dropdowns;
