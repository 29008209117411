import React, {useState} from 'react';
import {Button, CircularProgress, Grid} from '@mui/material';
import { styled } from '@mui/system';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { getCurrentTime } from '../../../../../../utils/getCurrentTime';
import { send_request } from '../../../../../../utils/Request';
import { CustomDialog } from '../../../../../Component/Dialog';

const ContinueButton = styled(Button)({
    minWidth: 0,
    padding: '6px 16px',
    height: '40px',
    margin: '0px',
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid black',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
    '&.selected': {
        backgroundColor: 'black',
        color: 'white',
    },
    "&:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.5)", // lighter black to indicate disabled state
        color: "rgba(255, 255, 255, 0.5)", // lighter white to indicate disabled state
        cursor: "not-allowed", // change cursor to indicate disabled state
    }
});
const createWorkflow = async () => {
    const newWF = {
        status: 'DRAFT',
        lastModified: getCurrentTime(),
    };

    const url = 'project-service/project/create';
    const json = await send_request(url, newWF, '', 'post');

    if (json && json.data) {
        window.open(`/project/canvas/${json.data.projectId}?openBuilder=true`, '_self');
    }
};

const BuilderWarning = ({ open, setState, setSidebarState, setShowBuilderWarning }) => {
    const [showLoading, setShowLoading] = useState(false);

    return (
        <CustomDialog
            isOpen={open}
            title="A workflow already exists on the canvas"
            contents={
                <Grid container rowGap={1}>
                    <Grid item style={{ color: '#757575' }}>
                        We recommend that you only have one workflow per canvas - this makes it easier to manage the workflow and its data, and enhances the performance of the AI when editing.
                    </Grid>
                    <Grid item style={{ color: '#757575' }}>
                        Do you want to continue to build a new workflow on a new canvas instead?
                    </Grid>
                </Grid>
            }
            buttons={
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <ContinueButton
                            onClick={() => setShowBuilderWarning(false)}
                        >
                            Cancel
                        </ContinueButton>
                    </Grid>
                    <Grid item>
                        <ContinueButton
                            onClick={() => {
                                setState('WORKFLOW_BUILDER');
                                setSidebarState('WORKFLOW');
                                setShowBuilderWarning(false);
                            }}
                        >
                            Continue on current canvas
                        </ContinueButton>
                    </Grid>
                    <Grid item>
                        <ContinueButton
                            className="selected"
                            startIcon={<CheckBoxOutlinedIcon />}
                            onClick={() => {
                                setShowLoading(true)
                                createWorkflow().then(() => {
                                    setShowLoading(false);
                                });
                            }}
                            disabled={showLoading}
                        >
                            {showLoading && (
                                <CircularProgress size={24} className="buttonProgress" />
                            )}
                            Build on new canvas
                        </ContinueButton>
                    </Grid>
                </Grid>
            }
        />
    );
};

export default BuilderWarning;