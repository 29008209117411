import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Autocomplete, TextField, Grid } from "@mui/material";
import { toJS } from "mobx";

const styles = (theme) => ({
  root: {},
  shaded: {
    backgroundColor: "#E7E7E7",
  },
});

const SIZE_TYPE = [
  { label: "Make video width responsive", value: "RESPONSIVE" },
  { label: "Set height and width", value: "FIXED" },
];

const EMBED_TYPE = [
  { label: "Video URL", value: "URL" },
  { label: "Embed code", value: "CODE" },
];

const VideoEmbed = (props) => {
  //css
  const { classes } = props;
  //local state
  const [size, setSize] = useState({
    label: "Make video width responsive",
    value: "RESPONSIVE",
  });
  const [height, setHeight] = useState(props.target.config.height);
  const [width, setWidth] = useState(props.target.config.width);
  const [snippet, setSnippet] = useState(props.target.config.snippet);
  const [embedType, setEmbedType] = useState({
    label: "Video URL",
    value: "URL",
  });
  const [url, setUrl] = useState(props.target.config.url);
  //on component mount

  useEffect(() => {
    //check if there is an existing value for embedType and size
    let existing_size = SIZE_TYPE.find(
      (size) => size.value === props.target.config.size
    );
    let existing_embed_type = EMBED_TYPE.find(
      (embedType) => embedType.value === props.target.config.embedType
    );

    //if there are values => set
    if (existing_size) {
      setSize(existing_size);
      props.target.config.size = existing_size.value;
    } else {
      props.target.config.size = size.value;
    }

    if (existing_embed_type) {
      setEmbedType(existing_embed_type);
      props.target.config.embedType = existing_embed_type.value;
    } else {
      props.target.config.embedType = embedType.value;
    }

    props.setTarget(props.target);
  }, []);

  //update functions for local state
  const handleChangeEmbedType = (inputValue) => {
    if (inputValue != null) {
      let newValueObject = EMBED_TYPE.find(
        (embedType) => embedType.value === inputValue.value
      );

      setEmbedType(newValueObject); //set update to local state

      props.target.config.embedType = inputValue.value;
      props.setTarget(props.target); //push update to the
    }
  };

  const handleChangeUrl = (inputValue) => {
    if (inputValue != null) {
      setUrl(inputValue); //set update to local state

      props.target.config.url = inputValue;
      props.setTarget(props.target); //push update to the
    }
  };

  const handleChangeSize = (inputValue) => {
    if (inputValue != null) {
      let newValueObject = SIZE_TYPE.find(
        (size) => size.value === inputValue.value
      );
      setSize(newValueObject); //set update to local state

      props.target.config.size = inputValue.value;
      props.setTarget(props.target); //push update to the
    }
  };
  const handleChangeHeight = (inputValue) => {
    if (inputValue != null) {
      setHeight(inputValue); //set update to local state

      props.target.config.height = inputValue;
      props.setTarget(props.target); //push update to the
    }
  };
  const handleChangeWidth = (inputValue) => {
    if (inputValue != null) {
      setWidth(inputValue); //set update to local state

      props.target.config.width = inputValue;
      props.setTarget(props.target); //push update to the
    }
  };
  const handleChangeSnippet = (inputValue) => {
    if (inputValue != null) {
      setSnippet(inputValue); //set update to local state

      props.target.config.snippet = inputValue;
      props.setTarget(props.target); //push update to the
    }
  };

  try {
    return (
      <Grid container item xs={12} spacing={2} direction={"column"}>
        <Grid container item xs={12} direction={"row"}>
          <Grid item xs={6}>
            <Autocomplete
              autoComplete={true}
              value={embedType}
              options={EMBED_TYPE}
              size={"small"}
              freeSolo={false}
              openOnFocus={true}
              getOptionLabel={(option) => {
                return option.label;
              }}
              variant={"outlined"}
              blurOnSelect={true}
              onChange={(event, inputValue) =>
                handleChangeEmbedType(inputValue)
              }
              renderInput={(params) => {
                return (
                  <TextField {...params} size={"small"} variant="outlined" />
                );
              }}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>

        <Grid item>
          {embedType.value === "URL" ? (
            <>
              <p>Paste the video URL below</p>
              <Grid container item xs={12} direction={"row"}>
                <Grid item xs={10}>
                  <TextField
                    size={"small"}
                    value={url}
                    fullWidth
                    onChange={(event) => handleChangeUrl(event.target.value)}
                  />
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <p>Sizing</p>
              <Grid container item xs={12} direction={"row"}>
                <Grid item xs={6}>
                  <Autocomplete
                    autoComplete={true}
                    value={size}
                    options={SIZE_TYPE}
                    size={"small"}
                    openOnFocus={true}
                    getOptionLabel={(option) => {
                      return option.label;
                    }}
                    variant={"outlined"}
                    blurOnSelect={true}
                    onChange={(event, inputValue) => {
                      handleChangeSize(inputValue);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          size={"small"}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6} />
              </Grid>
              {size.value === "RESPONSIVE" ? (
                <></>
              ) : (
                <>
                  <p>Height (pixels)</p>
                  <TextField
                    size={"small"}
                    value={height}
                    type={"number"}
                    onChange={(event) => {
                      handleChangeHeight(event.target.value);
                    }}
                  />
                  <p>Width (pixels)</p>
                  <TextField
                    size={"small"}
                    value={width}
                    type={"number"}
                    onChange={(event) => {
                      handleChangeWidth(event.target.value);
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <p>Paste the video embed code below</p>
              <TextField
                multiline={true}
                rows={4}
                fullWidth
                value={snippet}
                onChange={(event) => handleChangeSnippet(event.target.value)}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  } catch (e) {
    return <h5>Error encountered</h5>;
  }
};

VideoEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoEmbed);
