import React, { Component } from "react";

// util
import BranchPathsStore from "./BranchPathsStore";
import projectStore from "../ProjectCanvas/ProjectStore";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { send_component_save_request, send_request } from "../../utils/Request";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";

// mui
import { Grid, Paper, styled } from "@mui/material";

// mobx
import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction } from "mobx";

const Text = styled("span")(({ bold }) => ({
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: bold ? 700 : 400,
}));

class BranchPathsTemplate extends Component {
    state = {
        error: false,
        loading: false,
        description: null,
        status: null,
    };

    componentDidMount() {
        if (!this.props.component_id) return;

        this.setState({ loading: true });

        const URL = `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`;

        send_request(URL, null, null)
            .then((response) => {
                if (response && response.data) {
                    const { components } = response.data;
                    
                    if (components) {
                        BranchPathsStore.setComponentData(components[0]);
                        this.props.onComponentDescriptionChanged(components[0].description);
                        this.props.onComponentNameChanged(BranchPathsStore.name);
                        this.props.onLastModifiedChanged(BranchPathsStore.lastModified);
                        this.setState({ loading: false });
                    }
                }

                // If ai component builder data is given, set it accordingly in the component state
                if (this.props.aiComponentBuilderData && Object.keys(this.props.aiComponentBuilderData).length > 0) {
                    this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
                    this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
                    this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
                    this.setState({ status: "AI_BUILD_GENERATED" });
                }

                this.setState({ loading: false });
                this.props.setChildTemplateLoaded(true);
            })
            .catch((err) => {
                throw Error(err.message);
            });
    }

    setBranchPathsName = autorun(() => {
        BranchPathsStore.setComponentName(this.props.ComponentName);
    });

    componentWillUnmount() {
        // Clear the mobX reactions
        this.saveBranchPathsTemplate();
    }

    saveBranchPathsTemplate = reaction(
        () => this.props.SaveTrigger.triggerValue,
        () => {
            if (this.state.error) {
                this.props.showError();
                return;
            }

            const lastModified = getCurrentTime();
            BranchPathsStore.setLastModified(lastModified);
            const template = toJS(BranchPathsStore.template);

            const data = {
                type: template.type,
                componentData: {
                    name: this.props.ComponentName,
                    lastModified: lastModified,
                },
                componentId: template.componentId ? template.componentId : this.props.component_id,
                status: this.state.status,
                description: this.props.ComponentDescription.value,
            };

            send_component_save_request(`component-service/branch_paths/data`, data, "", "POST", this.props.SaveTrigger)
                .then((response) => {
                    if (response && response.status == 200) {
                        this.props.showSuccess(data, this.props.SaveTrigger);
                        this.props.onLastModifiedChanged(lastModified);
                        projectStore.savedComponent = true;
                    }
                })
                .catch((err) => {
                    throw err;
                });
        }
    );

    render() {
        if (this.state && !this.state.loading) {
            return (
                <Paper
                    sx={{
                        width: "100%",
                        padding: "1rem",
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Text bold>Branch Paths</Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Text>
                                This component is used in combination with the <Text bold>Conditional Path</Text> component to branch the workflow into multiple paths.
                            </Text>
                        </Grid>
                        <Grid item xs={12}>
                            <Text>
                                This component can only be connected to <Text bold>Conditional Path</Text> components, and Conditional Workflow components can only have a Branch Paths component as its
                                previous component or input.
                            </Text>
                        </Grid>
                    </Grid>
                </Paper>
            );
        } else return <IndividualComponentLoader />;
    }
}

export default inject("SelectedPlaceholder", "ComponentName", "ComponentDescription", "SaveTrigger")(observer(BranchPathsTemplate));
