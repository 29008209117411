import React from "react";

// Mui
import { styled, Grid, Button } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

// Util
import { getErrorTextFromKey, ErrorItem, isComponentAlreadyOpen } from "./ValidationUtil";
import ComponentNameFromId from "../ComponentNameFromId";

// Styled
const CustomGrid = styled(Grid)(({ margin, horizontal, vertical }) => ({
    margin: margin,
    ...(horizontal && vertical && { display: "flex", alignItems: vertical, justifyContent: horizontal }),
}));

const IndividualWarningItem = styled(Grid)({
    background: "rgba(255, 183, 77, 0.1)",
    padding: "8px",
    borderRadius: "6px",
});

function WarningContainer({ idx, handleFix, placeholderKey, warningItems, disabled, editComponent }) {
    return (
        <Grid container rowGap={1} id="warning-container-grid">
            <ErrorItem item xs={12} bgColor="rgba(255, 183, 77, 0.1)">
                <Grid container columnGap={3} wrap="nowrap">
                    <CustomGrid item horizontal="center" vertical="center">
                        <ErrorOutline className={"warning-text"} style={{ height: "18px" }} />
                    </CustomGrid>
                    <CustomGrid item xs horizontal="flex-start" vertical="center" style={{ overflow: "auto" }}>
                        <Grid container rowGap={1}>
                            <Grid item xs={12}>
                                {getErrorTextFromKey({ error: placeholderKey }, "notUnique")}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container rowGap={1} wrap="wrap">
                                    {warningItems.map((item) => {
                                        return (
                                            <IndividualWarningItem item xs={12}>
                                                <Grid container display="flex" justifyContent="space-between" alignItems="center" columnGap="8px">
                                                    <Grid item xs style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        <b>
                                                            <ComponentNameFromId componentId={item.component.componentId} />
                                                        </b>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            size="small"
                                                            variant={"outlined"}
                                                            color={"info"}
                                                            sx={{
                                                                textTransform: "none",
                                                            }}
                                                            disabled={disabled || isComponentAlreadyOpen(editComponent, item)}
                                                            onClick={() => {
                                                                handleFix(item, "notUnique");
                                                            }}
                                                            id={`${item.component.componentId}-${idx}`}
                                                        >
                                                            Open
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </IndividualWarningItem>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomGrid>
                </Grid>
            </ErrorItem>
        </Grid>
    );
}

export default WarningContainer;
