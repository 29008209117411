const styles = () => ({
  textButton: {
    textTransform: "unset !important",
    color: "black",
  },
  componentName: {
    fontWeight: 300,
    fontSize: "18px",
  },
  header: {
    marginBottom: "16px",
  },
  componentSkeleton: {
    borderRadius: "4px",
    background: "#E8E8E8 !important",
    marginLeft: "32px",
    marginRight: "24px",
    display: "inline-block",
  },
  headerSkeletonButton: {
    borderRadius: "4px",
  },
  componentDescriptionBox: {
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "16px",
    color: "rgba(117, 117, 117, 1)",
  },
  componentDescriptionTitle: {
    fontSize: "12px",
    lineHeight: "18px",
  },
  instruction: {
    padding: "16px",
    background: "#FFFFFF!important",
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  instructionInfo:{
    maxWidth: "100%!important",
    background: "#F7F7F7",
    padding: "16px",
    fontSize: "14px",
    display: "flex",
    rowGap: 2,}
});

export default styles;
