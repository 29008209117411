import { Chip } from "@mui/material";
import { styled } from "@mui/system";

export const ListItemChip = styled(Chip)(({ colour }) => ({
    border: `1px solid ${colour.border}`,
    backgroundColor: colour.bg,
    boxSizing: "border-box",
    borderRadius: "4px",
    marginRight: "6px",
}));
