import * as React from 'react';
import { Psychology } from '@mui/icons-material';

const AIGroupIcon = (props) => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">

            <g >
                <path d="M6 22.5V18.2C5.05 17.3333 4.3125 16.3208 3.7875 15.1625C3.2625 14.0042 3 12.7833 3 11.5C3 9 3.875 6.875 5.625 5.125C7.375 3.375 9.5 2.5 12 2.5C14.0833 2.5 15.9292 3.1125 17.5375 4.3375C19.1458 5.5625 20.1917 7.15833 20.675 9.125L21.975 14.25C22.0583 14.5667 22 14.8542 21.8 15.1125C21.6 15.3708 21.3333 15.5 21 15.5H19V18.5C19 19.05 18.8042 19.5208 18.4125 19.9125C18.0208 20.3042 17.55 20.5 17 20.5H15V22.5H13V18.5H17V13.5H19.7L18.75 9.625C18.3667 8.10833 17.55 6.875 16.3 5.925C15.05 4.975 13.6167 4.5 12 4.5C10.0667 4.5 8.41667 5.175 7.05 6.525C5.68333 7.875 5 9.51667 5 11.45C5 12.45 5.20417 13.4 5.6125 14.3C6.02083 15.2 6.6 16 7.35 16.7L8 17.3V22.5H6ZM11 15.5H13L13.15 14.25C13.2833 14.2 13.4042 14.1417 13.5125 14.075C13.6208 14.0083 13.7167 13.9333 13.8 13.85L14.95 14.35L15.95 12.65L14.95 11.9C14.9833 11.7667 15 11.6333 15 11.5C15 11.3667 14.9833 11.2333 14.95 11.1L15.95 10.35L14.95 8.65L13.8 9.15C13.7167 9.06667 13.6208 8.99167 13.5125 8.925C13.4042 8.85833 13.2833 8.8 13.15 8.75L13 7.5H11L10.85 8.75C10.7167 8.8 10.5958 8.85833 10.4875 8.925C10.3792 8.99167 10.2833 9.06667 10.2 9.15L9.05 8.65L8.05 10.35L9.05 11.1C9.01667 11.2333 9 11.3667 9 11.5C9 11.6333 9.01667 11.7667 9.05 11.9L8.05 12.65L9.05 14.35L10.2 13.85C10.2833 13.9333 10.3792 14.0083 10.4875 14.075C10.5958 14.1417 10.7167 14.2 10.85 14.25L11 15.5ZM12 13C11.5833 13 11.2292 12.8542 10.9375 12.5625C10.6458 12.2708 10.5 11.9167 10.5 11.5C10.5 11.0833 10.6458 10.7292 10.9375 10.4375C11.2292 10.1458 11.5833 10 12 10C12.4167 10 12.7708 10.1458 13.0625 10.4375C13.3542 10.7292 13.5 11.0833 13.5 11.5C13.5 11.9167 13.3542 12.2708 13.0625 12.5625C12.7708 12.8542 12.4167 13 12 13Z" fill="url(#paint0_linear_103_1969)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_103_1969" x1="12.5059" y1="2.5" x2="12.5059" y2="22.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#944BA8"/>
                    <stop offset="1" stop-color="#2196F3"/>
                </linearGradient>
            </defs>
        </svg>

    );
};

export default AIGroupIcon;
