import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";

const DrawerItem = (props) => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        borderLeft: "3px solid white",
        height: 42
      },
      selected: {
        backgroundColor: "rgba(0,0,0,0) !important",
        borderLeft: "3px solid #000000",
        color: "#000000"
      },
      text: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        margin: 0,
        color: props.selected ? "#000000" : "#B3B3B3"
      },
      icon: {
        color: props.selected ? "#000000" : "#B3B3B3",
        fontSize: 20,
        marginLeft: 10,
        marginRight: 16,
        minWidth: 0,
        
      }
    })
  );

  const classes = useStyles();
  return (
    <ListItemButton
      selected={props.selected}
      onClick={props.onClick || ""}
      classes={{ selected: classes.selected, root: classes.root }}
    >
      <ListItemIcon 
        className={classes.icon}>{props.icon}</ListItemIcon>
      <ListItemText
        disableTypography={true}
        primary={<h4 className={classes.text}>{props.Title}</h4>}
      />
    </ListItemButton>
  );
};

export default DrawerItem;
