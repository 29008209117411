import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {Grid} from "@mui/material";
import MentionEditor from "../TextEditor/MentionEditor";
import TextIcon from "../Component/Icons/TextIcon";
import NumberIcon from "../Component/Icons/NumberIcon";
import BooleanIcon from "../Component/Icons/BooleanIcon";
import DatetimeIcon from "../Component/Icons/DatetimeIcon";
import ListIcon from '../Component/Icons/ListIcon';

import {toJS} from "mobx";
import CreateDatabaseRecordStore from "./CreateDatabaseRecordStore";

class ItemDatabase extends Component {

  updateColumn = (index, value, columns) => {
    let aData = columns[index];
    aData.columnValue = value;
    CreateDatabaseRecordStore.setData(columns)
  };

  getColumnType = (type) => {
    switch (type) {
      case "VARCHAR2" :
        return <TextIcon style={{marginTop: 6}} />
      case "DECIMAL" :
        return <NumberIcon style={{marginTop: 6}}/>
      case "BOOLEAN" :
        return <BooleanIcon style={{marginTop: 8}} />
      case "DATETIME" :
        return <DatetimeIcon />
      case "LIST" :
        return <ListIcon style={{marginTop: 8}} />
    }
  }


  render() {
    let {column,availablePlaceholders,index,columns} = this.props
    let {placeholders,position} = CreateDatabaseRecordStore
    placeholders = toJS(placeholders)
    let keyArray = []

    if (position == index){
      keyArray = placeholders
    }


    return (
      <Grid item xs={12} style={{margin:"4px 0"}}>
        <Grid item xs={12} style={{marginBottom:8,display:"flex",alignItems: "center"}}>
          <Grid style={{marginRight: 8}}>
          {this.getColumnType(column.columnType)}
          </Grid>
          {column.columnName}
        </Grid>
        <MentionEditor
          style={{ width: "100%",marginBottom: 16, verticalAlign: "top" }}
          availablePlaceholders={availablePlaceholders}
          placeholders={keyArray}
          text={column.columnValue}
          placeholder="Type something or insert a placeholder"
          onChange={value => {
            this.updateColumn(index,value,columns)
          }}
          onFocus={(e) => {
            CreateDatabaseRecordStore.setFocus(index)
          }}
        />
      </Grid>
    );
  }
}

export default observer(ItemDatabase);