import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ActionButton = styled(Button)(({ bgColor, textColor }) => ({
    backgroundColor: bgColor,
    color: textColor,
}));

const TitleText = styled("span")({
    fontWeight: "bold",
    fontSize: "16px",
});

const Content = styled("span")({
    fontSize: "15px",
});

function ErrorWorkflowDialog({ open, setOpen, projectId, saveAsNewVersion }) {
    return (
        <Dialog
            open={open}
            maxWidth="sm"
            PaperProps={{
                style: {
                    backgroundColor: "rgba(247, 229, 233, 1)",
                    border: "3px solid rgba(225, 0, 0, 1)",
                    boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.25)",
                },
            }}
            className={"dialogWithNoBackdrop"}
            hideBackdrop={true}
            disableEnforceFocus={true}
            // onClose={() => setOpen(false)}
        >
            <DialogTitle>
                <TitleText>This workflow has been disabled due to recurring errors</TitleText>
            </DialogTitle>
            <DialogContent>
                <Content>
                    This workflow has been automatically deactivated because it has encountered <b>50 consecutive failed</b> sessions when it was run. <br/><br/>This workflow cannot be run and changes will not be
                    saved until it is reactivated by saving a new version.
                </Content>
            </DialogContent>
            <DialogActions>
                <ActionButton variant="contained" onClick={saveAsNewVersion}>
                    Save as new version
                </ActionButton>
                <Link to={`/project/logs/${projectId}`} target="_blank">
                    <ActionButton variant="outlined">View session logs</ActionButton>
                </Link>
                <ActionButton variant="outlined" onClick={() => setOpen(false)}>
                    Dismiss
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorWorkflowDialog;
