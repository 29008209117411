import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, MenuItem, Select, Skeleton, Slide, styled, Drawer } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import { Close, PlayCircleOutlin, PlayCircle } from "@mui/icons-material";

export const ItemBox = styled(Grid)(({ noPadding, noShadow }) => ({
    backgroundColor: "#FFF",
    boxShadow: noShadow ? "0px" : "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    padding: noPadding ? "0px" : "16px",
}));

export const ProductionBox = styled("div")({
    border: "1px solid rgba(85, 167, 122, 1)",
    backgroundColor: "rgba(229, 242, 235, 1)",
    padding: "2px 12px",
    color: "rgba(0, 0, 0, 0.87)",
    borderRadius: "4px",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
});

export const GreenCircle = styled(CircleIcon)({
    height: "14px",
    width: "auto",
    color: "rgba(85, 167, 122, 1)",
    marginRight: "8px",
});

export const DownArrow = styled(ArrowDownwardIcon)({
    height: "24px",
    width: "auto",
});

export const RunIcon = styled(PlayCircle)({
    height: "22px",
    width: "auto",
    marginRight: "6px",
});

export const Text = styled("span")(({ textColor, fontWeight }) => ({
    fontSize: "14px",
    color: textColor,
    fontWeight: fontWeight,
}));

export const CustomGrid = styled(Grid)(({ vertical, horizontal }) => ({
    display: "flex",
    alignItems: vertical,
    justifyContent: horizontal,
}));

export const TextButton = styled(Button)({
    textTransform: "unset !important",
    color: "black",
});

export const TriggerTitle = styled("div")({
    fontSize: "18px",
    lineHeight: "24px",
});

export const TriggerDescription = styled("div")({
    fontSize: "14px",
    lineHeight: "24px",
});

export const BlackButton = styled(Button)({
    backgroundColor: "rgba(0, 0, 0, 0.87)",
    color: "#FFF",
    padding: "8px 24px",
    borderRadius: "4px",
    letterSpacing: "1.25px",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 1)",
    },
    "&:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.5)", // lighter black to indicate disabled state
        color: "rgba(255, 255, 255, 0.5)", // lighter white to indicate disabled state
        cursor: "not-allowed", // change cursor to indicate disabled state
    },
});
