import React, {Component} from "react";
import withStyles from "@mui/styles/withStyles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import cx from "classnames";
import getPlaceholderColors from "vardogyir-ui/PlaceholderColors";
import ChipInputHook from "./ChipInputHook";
import CloseIcon from "@mui/icons-material/Close";



class ChipInput extends Component {
  state = {
    chips: [],
    errorText: undefined,
    focusedChip: null,
    inputValue: "",
    isClean: true,
    isFocused: false,
    chipsUpdated: false,
    prevPropsValue: [],
    placeholders: [],
    error: false,
    listenerAdded: false
  };

  constructor(props) {
    super(props);
    if (props.defaultValue) {
      this.state.chips = props.defaultValue;
    }
    this.input = React.createRef();
  }


  /**
   * This method is a react lifecycle event and is called before render and is used to update state(local data)
   * based on props(component data).
   * @param props
   * @param state
   * @returns {null}
   */

  static getDerivedStateFromProps(props, state) {
    /**
     * function needs to be added here as this method doesn't have access to component instance
     * @param placeholder
     * @param placeholderEntities
     */
    function addColorAndUpdatePlaceHolderMap(placeholder, placeholderEntities) {
      const placeholderColor = getPlaceholderColors(
          placeholder.type,
          placeholder.length
      );

      //push the above item to the placeholder array
      placeholderEntities.push({
        borderColor: placeholderColor.default,
        backgroundColor: placeholderColor.hover,
        icon:placeholderColor.icon,
        text: "${" + placeholder.key + "}"
      });
    }


    let newState = null;

    if (props.value && props.value.length !== state.prevPropsValue.length) {
      newState = { prevPropsValue: props.value };
      if (props.clearInputValueOnChange) {
        newState.inputValue = "";
      }
    }


    if (props.disabled) {
      newState = { ...newState, focusedChip: null };
    }

    if (!state.chipsUpdated && props.defaultValue) {
      newState = { ...newState, chips: props.defaultValue };
    }

   /*
   The actual update of input happens here.
    */

    if (
      props.placeholders
    ) {
      const placeholderEntities = [];
      const availablePlaceholders = props.placeholders;

      /**
       * Setting placeholder color and border type
       */
      availablePlaceholders.map((component) => {
        if (component.placeholders) {
          component.placeholders.map((placeholder) => {
            // Only show datetime placeholders if it is a datetime type
            if (props.inputType === "DATETIME"){
              if (placeholder.type === "datetime"){
                addColorAndUpdatePlaceHolderMap(placeholder, placeholderEntities);
              }
            } else {
              addColorAndUpdatePlaceHolderMap(placeholder, placeholderEntities);
            }
            
          });
        } else {
          if (component) {
            addColorAndUpdatePlaceHolderMap(component, placeholderEntities);
          }
        }
      });

      newState = { ...newState, placeholders: placeholderEntities };
    }
    return newState;
  }
  //This eventListener will prevent typing in the input box
  handleKeyDown = (event) => {
    // Prevent the default behavior for all keydown events
    event.preventDefault();
  };

  /*
  Calling the customized on focus.
   */
  handleInputFocus = (event) => {
    this.setState({ isFocused: true });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }

    //If it is a date time query the input is untypable, only able to choose placeholders
    if (this.props.inputType === "DATETIME" && !this.listenerAdded){
      event.target.addEventListener('keydown', this.handleKeyDown);
      this.listenerAdded = true;
    }  else if (this.listenerAdded){
      event.target.removeEventListener('keydown', this.handleKeyDown);
      this.listenerAdded = false;
    }
  };


  /*
  Calling the customized on Delete function.
   */
  handleDeleteChip(i) {
    if (this.props.onDelete) {
      this.props.onDelete(i);

    }
  }

  /**
   * Creating the placeholder event and calling the customized add placeholder function.
   * @param text
   * @param borderColor
   * @param backgroundColor
   * @param icon
   * @param isNotPlaceholder
   */
  handleAdd(text, borderColor, backgroundColor,icon,isNotPlaceholder) {
    {
      const placeholderEvent = {
        key: text,
        style: {
          borderColor,
          backgroundColor
        },
        icon:icon,
        isNotPlaceholder: isNotPlaceholder
      };
      this.props.onPlaceholderSelected(placeholderEvent);
    }

  }

  /**
   * The render function calls the form control which in turn calls the chip input hook,
   * which actually handles the chip rendering.
   *
   * It also defines the add chip through
   * autocomplete function and add chip that is not a placeholder function.
   * @returns {JSX.Element}
   */
  render() {
    const {
      fullWidth,
      inputValue = [],
      classes,
      FormHelperTextProps,
      helperText,
      style,
      placeholder
    } = this.props;

    const { placeholders, error } = this.state;

    const actualInputValue =
      inputValue != null ? inputValue : this.state.inputValue;


    /**
     * Rendering of the chip via the hook
     */

    return (
      <FormControl
        className={classes.root}
        fullWidth={fullWidth}
        style={style}
        error={error}
      >
        <ChipInputHook
          onFocus={this.handleInputFocus}
          placeholders={placeholders}
          inputValue={actualInputValue}
          handleDelete={(index) => this.handleDeleteChip(index)}
          placeholder={placeholder}
          handleAddChipFromAutocomplete={({
            text,
            borderColor,
            backgroundColor,icon
          }) => this.handleAdd(text,borderColor,backgroundColor,icon,false)
        }
          handleAddChipNoPlaceholder={(text) => {
            this.handleAdd(text,"black","blue",CloseIcon,true)
          }}
          type={this.props.inputType ? this.props.inputType : "VARCHAR2"}
        />
        {error && helperText && (
          <FormHelperText
            {...FormHelperTextProps}
            className={
              FormHelperTextProps
                ? cx(FormHelperTextProps.className, classes.helperText)
                : classes.helperText
            }
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }


}

ChipInput.defaultProps = {
  clearInputValueOnChange: false,
};

export default withStyles( null,{ name: "vardogyir-chipInput" })(ChipInput);
