import React, { FunctionComponent, useState } from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Snackbar from "@mui/material/Snackbar";
import copy from "clipboard-copy";
import getPlaceholderColors from "./PlaceholderColors";
import { Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import { withStyles } from "@mui/styles";
import "./Placeholders.css";

const Placeholders = (props) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const colorScheme = getPlaceholderColors(props.data_type, props.data_length);

  const runCopy = () => {
    let text = "${" + props.data_key + "}";
    navigator.clipboard.writeText(text);
    setOpen(true);
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={"card"}
      style={{
        border: `2px solid ${colorScheme.default}`,
        backgroundColor: hover ? colorScheme.hover : "",
      }}
    >
      <div
        className={"content"}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
        onMouseDown={() => {
          if (props.onMouseDown) props.onMouseDown();
        }}
      >
        <span className={"placeholderPadding"}>
          <p className={"contentKey"}>{props.data_key}</p>
          <p className={"contentComponent"}>{props.component_name}</p>
        </span>
      </div>
      <div className={"placeholderCopyIcon"} style={{ backgroundColor: colorScheme.default }}>
        <Tooltip title={`Data type: ${props.data_type}. Click to copy`}>
          <FileCopyOutlinedIcon onClick={runCopy} />
        </Tooltip>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity="info" color={"primary"}>
          Placeholder copied to clipboard
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Placeholders;
