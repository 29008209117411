import { ErrorRounded, FileCopy, Style } from '@mui/icons-material'
import { Alert, Autocomplete, Button, CircularProgress, Grid, IconButton, InputAdornment, MenuItem, Select, Skeleton, Switch, TextField } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../../config'
import { getCurrentTime } from '../../../../utils/getCurrentTime'
import { getAutoCompleteOptions } from '../../../../utils/MarketplaceDialog'
import { send_request } from '../../../../utils/Request'
import { CustomDialog } from '../../../Component/Dialog'
import projectStore from '../../../ProjectCanvas/ProjectStore'
import ShareButtons from './ShareDialog/ShareButtons'

const ParagraphHeader = styled("p")({
  fontWeight: "bold !important",
})

const GrayParagraph = styled("p")({
  color: "#868686",
})

const StyledAlert = styled(Alert)({
  border: "solid 1px #FFB74D",
  color: "black"
})

const StyledFileCopy = styled(FileCopy)({
  "&:hover": {
    color: "#2196f3",
    cursor: "pointer"
  }
})

const DownloadContainer = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
})

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
  borderRadius: "4px",
  background: "#E8E8E8",
  width: width,
  height: height,
  margin: margin,
}));

const WarningText = styled("span")({
  fontWeight: "bold"
})

const ShareDialog = ({
    projectId,
    isOpen = true,
    workflow,
    closeDialog,
    getLatestVersion,
    draftVersion, publishedVersion
  }) => {

  const [saving, setSaving] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [auth, setAuth] = useState(null);
  const [isDraft,setIsDraft]=useState(1);
  const [description, setDescription] = useState(null)
  const [categories, setCategories] = useState([]);
  const [downloadable, setDownloadable] = useState(false);
  const [shouldDuplicateDBRecords, setShouldDuplicateDBRecords] = useState(false);


  /**
   * 
   * @param {boolean} isHidden 
   * @param {boolean} isPublic 
   * @returns Auth value of the "Who can view this workflow" given isHidden
   * and isPublic
   */
  const getAuth = (isHidden, isPublic) => {
    if (isHidden) {
      if (isPublic) {
        return 2;
      }
     }
    else {
      if (isPublic) {
        return 0;
      }
      return 1;
    }
  }

  useEffect(async () => {
    try {
      const res = await send_request(
        `project-service/template/getLatestProjectTemplateByOriginProjectId/` + workflow.projectId,
        null,
        null,
        "GET"
      );

      // This is when we find no template (i.e not shared)
      if (res.data === '') {
        setAuth(3);
        setIsDraft(1);
      } else {
        const { isHidden, isPublic, isDraft } = res.data;
        const templateAuth = getAuth(isHidden, isPublic)
        setAuth(templateAuth);
        setIsDraft(isDraft ? 1 : 0);

        // Using res.data instead of destructuring because there is a state
        // with the same name as description and categories
        setDownloadable(res.data.downloadable);
        setShouldDuplicateDBRecords(res.data.shouldDuplicateDBRecords);
        setTemplateId(res.data.projectId);
        setDescription(res.data.description);
        if (res.data.categories) setCategories(res.data.categories);
      }
    } catch (error) {
      console.log(error);
    }
  }, [isOpen]);
  

  const handleSaveClick = async () => {
    setSaving(true);
    let isPublic = false;
    auth == 0 || auth == 2 ? (isPublic = true) : (isPublic = false);

    try {
      /**
       * Deleting the workflow
       */
      if (auth === 3) {
        await send_request(
          `project-service/template/deleteTemplateByTemplateId/${workflow.projectId}/${templateId}`,
          null,
          null,
          "DELETE"
        );
        setTemplateId(null);
        return;
      }

      let updatedValues = {
        projectId,
        lastModified: getCurrentTime(),
        description,
        categories,
        imagePath: workflow.imagePath != null
          ? workflow.imagePath
          : "/images/blue-blocks2.jpg",
        isLatestVersion: true
      };
      /**
       * Updating details of the project
       */
      await send_request(
        "project-service/project/details",
        updatedValues,
        "",
        "patch"
      )

      /**
       * Determines the auth of the template
       */
      const res = await send_request(
        "project-service/template/projecttotemplate",
        {
          projectId,
          version: isDraft ? draftVersion : publishedVersion,
          author: projectStore.state.orgName,
          isHidden: auth === 2 ? true : null,
          isDraft: isDraft === 1,
          isPublic,
          downloadable,
          shouldDuplicateDBRecords
        },
        "",
        "post"
      )
      setTemplateId(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
    
  }

  const renderAuthDescription = (auth) => {
    switch(auth) {
      case 0:
        return "This template can be shared with any users outside of your organisation and will also be published in the Workflow86 templates page and marketplace."
      case 1:
        return "This template can be shared with anyone inside of your organization. Users must log in before they can view or download the template."
      case 2:
        return "This template can be shared with any users outside of your organisation via a shareable link."
      case 3:
        return "This workflow has not been shared as a template."
    }
  }

  const getContent = () => {
    return <Grid
      container
      direction="column"
      alignItems="flex-start"
    >
      <ParagraphHeader>
        Who can view this workflow
      </ParagraphHeader>
      <Select
        variant="outlined"
        value={auth}
        onChange={(e) => {
          setAuth(e.target.value);
        }}
        fullWidth
      >
        <MenuItem value={3}>
          🚫 Not shared
        </MenuItem>
        {auth ===  0 && <MenuItem value={0}>
          🌏 Open to anyone
        </MenuItem>}
        <MenuItem value={1}>
          🔒 Open to my organization only
        </MenuItem>
        {/* <MenuItem value={3}>
          🤚 Only these specific users
        </MenuItem> */}
        <MenuItem value={2}>
          🔗 Anyone with the link
        </MenuItem>
        {/* <MenuItem value={5}>
          🔗 Anyone with the link and password
        </MenuItem> */}
      </Select>
      <GrayParagraph>
        {renderAuthDescription(auth)}
      </GrayParagraph>
      { auth !== 3 &&
        <>
          {auth === 0 && 
            <StyledAlert
              severity="warning"
              icon={<ErrorRounded />}
            >
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                    <WarningText>Warning</WarningText>
                </Grid>
                <Grid item xs={12}>
                  Making this template open to anyone to view will also make it appear on the{" "}
                  <Link to="/marketplace" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "rgba(33, 150, 243, 1)" }}>
                    marketplace page
                  </Link> and{" "}
                  <a href="https://templates.workflow86.com/"  target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "rgba(33, 150, 243, 1)" }}>
                    templates.workflow86.com
                  </a>
                </Grid>
              </Grid>
            </StyledAlert>
          }
          <ParagraphHeader>Version
          </ParagraphHeader>
          <Select variant="outlined"
                  value={isDraft}
                  onChange={(e) => {
                    setIsDraft(e.target.value);
                  }}
                  fullWidth>
            <MenuItem value={1}>
              Draft (version {draftVersion})
            </MenuItem>
            <MenuItem value={0} sx={{display: publishedVersion==="None"?'none':""}}>
              Published (version {publishedVersion})
            </MenuItem>
          </Select>
        <ParagraphHeader>
          Allow download?
        </ParagraphHeader>
        <DownloadContainer>
          <Switch
            checked={downloadable}
            onClick={(e) => {
              setDownloadable(e.target.checked);
              if (!e.target.checked) setShouldDuplicateDBRecords(false); 
            }}
          />
          <GrayParagraph style={{ margin: 0 }}>
            Allow this workflow and its contents to be downloaded
          </GrayParagraph>
        </DownloadContainer>
        {downloadable && 
          <>
            <ParagraphHeader>
              Duplicate database records?
            </ParagraphHeader>
            <DownloadContainer>
              <Switch
                checked={shouldDuplicateDBRecords}
                onClick={(e) => {
                  setShouldDuplicateDBRecords(e.target.checked);
                }}
              />
              <GrayParagraph style={{ margin: 0 }}>
                Duplicate database records when cloning databases from this workflow
              </GrayParagraph>
            </DownloadContainer>
          </>
        }
        <ParagraphHeader>
          Select categories
        </ParagraphHeader>
        <Autocomplete
          fullWidth
          options={projectStore.state.workflowCategories}
          getOptionLabel={(option) => option.displayName}
          multiple
          value={getAutoCompleteOptions(categories)}
          renderInput={(params) => (
            <TextField {...params} variant="standard" />
          )}
          defaultValue={getAutoCompleteOptions(categories)}
          onChange={(event, newValue) => {
            setCategories(newValue.map(({ categoryId }) => categoryId))
          }}
        />
        <ParagraphHeader>
          Description (optional)
        </ParagraphHeader>
        <TextField
          multiline
          fullWidth
          rows={5}
          variant="outlined"
          placeholder={"Enter a description for this workflow"}
          defaultValue={description}
          onBlur={(e) => setDescription(e.target.value)}
        />
        <ParagraphHeader>
          🔗 Link
        </ParagraphHeader>
        {
          templateId ?
          <>
            <TextField
              disabled
              value={`${config.APP_URL}/template/${templateId}`}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => {
                      navigator.clipboard.writeText(`${config.APP_URL}/template/${templateId}`)
                    }}
                  >
                    <IconButton>
                      <StyledFileCopy />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <ParagraphHeader>
              Share link
            </ParagraphHeader>
            <ShareButtons
              url={`${config.APP_URL}/template/${templateId}`}
            />
          </>
          :
          <StyledAlert
            severity="warning"
            icon={<ErrorRounded />}
          >
            Click save to generate a shareable link for the latest published version of this workflow
          </StyledAlert>
      }
      </>
    }
    </Grid>
  }

  const getLoader = () => {
    return <Grid container gap={2}>
      <Grid item xs={12}>
        <StyledSkeleton variant="rectangular" height="40px" width="100%" margin="16px 0px 0px 0px" />
      </Grid>
      <Grid item xs={12}>
        <StyledSkeleton variant="rectangular" height="120px" width="100%" />
      </Grid>
    </Grid>
  }

  return (
    <CustomDialog
      isOpen={isOpen}
      title="Share this workflow"
      buttons={
        <>
          <Button
            variant="outlined"
            onClick={closeDialog}
          >
            Close
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: saving && "#E5E5E5"
            }}
            disabled={saving || auth === null}
            onClick={handleSaveClick}
          >
            {
              saving ?
              <CircularProgress size={26}/>
              :
              "Save"
            }
          </Button>
        </>
      }
      contents={auth !== null ? getContent() : getLoader()}

    />
  )
}

export default ShareDialog