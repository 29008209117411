import React, {Component} from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {FormControl} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MentionEditor from "../TextEditor/MentionEditor";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {observer, inject} from "mobx-react";
import {autorun, toJS, reaction, trace} from "mobx";
import ApiExportStore from "./ApiExportStore";
import CustomHeader from "./Components/CustomHeader";
import {send_component_save_request, send_request} from "../../utils/Request";
import {getCurrentTime} from "../../utils/getCurrentTime";
import ErrorIcon from "@mui/icons-material/Error";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import AceEditor from "react-ace";
import {addCompleter} from 'ace-builds/src-noconflict/ext-language_tools';
import {IndividualComponentLoader} from "../../utils/ComponentLoadingSkeleton";
import {validURL} from "../../utils/CanvasUtil";

const uuidv4 = require("uuid/v4");

class ApiExportTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeaderFields: false,
      focus: null,
      placeholders: [],
      extraHeaderFields: [],
      requestClick: false,
      dataClick: false,
      loading: false,
      urlError: false,
      urlErrorMessage: "",
      headerErrorMessage: "",
      headerError: false,
      keyPlaceholders: {},
      valuePlaceholders: {},
      updateState: false,
      description:null,
      status:null
    };
    ApiExportStore.initialiseEmpty();
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({loading: true});
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then((response) => {
          if (response && response.data) {
            const {components} = response.data;
            if (components) {
              ApiExportStore.setApiExportTemplate(components[0]);
              this.props.onComponentNameChanged(ApiExportStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(ApiExportStore.lastModified);
              this.setState({loading: false, headers: ApiExportStore.data.headers});
            }
          }
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["data"] !== undefined && this.props.aiComponentBuilderData["data"] !== '') {
            this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
            this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
            ApiExportStore.updateComponentData(this.props.aiComponentBuilderData["data"])
            this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
            this.setState({ status: "AI_BUILD_GENERATED" });
          }
          this.props.setChildTemplateLoaded(true);
          this.setState({loading: false});
        })
        .then(() => {
          this.checkCondition();
        })
        .catch((err) => {
          this.setState({loading: false});
          throw Error(err.message);
        });

      const placeholderParameters = [];
      if (this.props
        && this.props.availablePlaceholders
        && this.props.availablePlaceholders.length === 1
        && this.props.availablePlaceholders[0]
        && this.props.availablePlaceholders[0].placeholders
        && this.props.availablePlaceholders[0].placeholders.length > 0
      ) {
        for (let i = 0; i < this.props.availablePlaceholders[0].placeholders.length; i++) {
          const completeKey = "${" + this.props.availablePlaceholders[0].placeholders[i].key + "}";
          const newCompleteItem = {
            name: completeKey,
            value: completeKey,
            caption: completeKey,
            meta: 'local',
            score: 1000
          };
          placeholderParameters.push(newCompleteItem)
        }
      }
      addCompleter({
        getCompletions: function (editor, session, pos, prefix, callback) {
          callback(null, placeholderParameters);
        },
      });
    }




  }

  setApiExportName = autorun(() => {
    ApiExportStore.setApiExportName(this.props.ComponentName);
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveApiExportTemplate();
    this.reactToPlaceHolder();
  }

  saveApiExportTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      const lastModified = getCurrentTime();
      ApiExportStore.setLastModified(lastModified);
      const template = toJS(ApiExportStore.template);

      const data = {
        type: template.type,
        componentData: {
          name: this.props.ComponentName,
          data: template.data,
          lastModified: lastModified
        },
        componentId: template.componentId
          ? template.componentId
          : this.props.component_id,
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };
      send_component_save_request(`component-service/api-export/data`, data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          if (response && response.status == 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
          }
          ProjectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  checkCondition = () => {
    let hasPlaceholder = false;
    const {availablePlaceholders} = this.props;
    availablePlaceholders.forEach((availablePlaceholder) => {
      availablePlaceholder.placeholders.forEach((placeholder) => {
        if (ApiExportStore.data.endpoint_url.includes(placeholder.key)) {
          hasPlaceholder = true;
        }
      });
    });

    this.setState({headerError: false, headerErrorMessage: "", urlError: false, urlErrorMessage:""});

    if (hasPlaceholder) {
      this.setState({urlError: false, urlErrorMessage: ""});
      this.props.onChangeState && this.props.onChangeState(1, 1);
    } else {
      if (validURL(ApiExportStore.data.endpoint_url)) {
        this.setState({urlError: false, urlErrorMessage: ""});
        this.props.onChangeState && this.props.onChangeState(1, 1);
      } else {
        this.setState({
          urlError: true,
          urlErrorMessage:
            "Invalid or empty endpoint URL"
        });
      }
    }

    ApiExportStore.data.headers.forEach((h) => {
      if (h.header_name == "" || h.header_value == "") {
        this.setState({
          headerError: true,
          headerErrorMessage: "Please fill in the header fields. Header fields must not be empty."
        });
      }
    });
  }

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const {style, key} = placeholder["selected"];
      this.insertPlaceholder(style.default, key, style, this.state.focus);
    }
  );

  insertPlaceholder = (label, text, style, focus) => {

    // Handles key-value headers
    if (typeof focus === "object" && focus !== null) {
      const {headerType, index} = focus;
      const placeholder = "${" + text + "}"
      if (headerType === "key") {
        ApiExportStore.data.headers[index].header_name += placeholder;
        const newKeysPlaceholders = {...this.state.keyPlaceholders};
        if (!(index in newKeysPlaceholders)) {
          newKeysPlaceholders[index] = [{text}];
        } else {
          const indexedArray = [...newKeysPlaceholders[index]]
          indexedArray.push({text});
          newKeysPlaceholders[index] = indexedArray;
        }

        this.setState({keyPlaceholders: newKeysPlaceholders});
      } else {
        ApiExportStore.data.headers[index].header_value += placeholder;
        const newValuePlaceholders = {...this.state.valuePlaceholders};
        if (!(index in newValuePlaceholders)) {
          newValuePlaceholders[index] = [{text}];
        } else {
          const indexedArray = [...newValuePlaceholders[index]]
          indexedArray.push({text});
          newValuePlaceholders[index] = indexedArray;
        }
        this.setState({valuePlaceholders: newValuePlaceholders});
      }

      return;
    }

    if (focus === "editor") {
      this.setState((prevState) => ({
        placeholders: [...prevState.placeholders, {label, text, style, focus}]
      }));
    } else if (focus == "body") {
      text = `@[${text}](${text})`;
      const {data} = toJS(ApiExportStore.template);
      text = data.body + text;
      ApiExportStore.setApiExportBody(text);
    } else if (focus == "endpoint") {
      let {placeholders} = ApiExportStore;
      placeholders.push({label, text, style});

      ApiExportStore.setPlaceholders(placeholders);
    } else {
      this.toInput(label, text, style, focus);
    }
  };

  requestButtonClick = () => {
    this.setState({requestClick: true});
  };

  chooseRequest = (requestType) => {
    ApiExportStore.setRequestType(requestType);
  };

  chooseDataType = (dataType) => {
    ApiExportStore.setDataType(dataType);
  };

  insertHeaders = () => {
    let newHeader = {
      header_id: uuidv4(),
      header_name: "",
      header_value: ""
    };
    ApiExportStore.data.headers.push(newHeader);
    this.checkCondition();
    
  };
  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      name: value
    });
    if (name === "method") {
      this.chooseRequest(value);
    }
    if (name === "dataType") {
      this.chooseDataType(value);
    }
  };

  setupModeChange = (mode) => {
    ApiExportStore.setSetupMode(mode);
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;
    this.setState({updateState: !currentState});
  };

  render() {
    const {headerErrorMessage, headerError, urlError, urlErrorMessage} = this.state;
    let {placeholders} = ApiExportStore;

    placeholders = toJS(placeholders);
    let keyArray = [];
    keyArray = placeholders;

    if (this.state && !this.state.loading) {
      return (
        <Grid container xs={12} className="componentGrid">
          <Paper className="componentPaper">
            <Grid container xs={12}
                  style={{
                    width: "100%",
                    padding: "24px 24px 12px 24px",
                    background: "white"
                  }}
                  className="api-editor-container"
            >
              <Grid item style={{width: "100%"}}>
                <FormControl style={{width: "50%"}}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "8px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      fontWeight: "normal",
                      lineHeight: "19px",
                      color: "#868686"
                    }}
                  >
                    Setup Mode
                  </Grid>
                  <Select
                    name="method"
                    labelId="demo-simple-select-outlined-label"
                    className="dropdown-mat"
                    value={ApiExportStore.data.setupMode}
                    defaultValue={"MANUAL"}
                    disableUnderline
                    variant="standard"
                    align="left"
                    onChange={(value) => {
                      this.setupModeChange(value.target.value)
                    }}
                  >
                    <MenuItem value={"MANUAL"}>Manually set each field</MenuItem>
                    <MenuItem value={"CURL"}>Write cURL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <div hidden={ApiExportStore.data.setupMode !== "MANUAL"} style={{width: "100%"}}>
            <Paper className="componentPaper">
              <Grid
                container
                style={{
                  width: "100%",
                  padding: "24px 24px 24px 24px",
                  background: "white"
                }}
                className="api-editor-container"
              >
                <Grid item style={{width: "100%"}}>
                  <FormControl style={{width: "50%"}}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "8px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        fontWeight: "normal",
                        lineHeight: "19px",
                        color: "#868686"
                      }}
                    >
                      Method
                    </Grid>
                    <Select
                      name="method"
                      labelId="demo-simple-select-outlined-label"
                      className="dropdown-mat"
                      value={ApiExportStore.data.request_type}
                      defaultValue={"POST"}
                      disableUnderline
                      variant="standard"
                      align="left"
                      onChange={this.handleChange}

                    >
                      <MenuItem value={"POST"}>POST</MenuItem>
                      {/*<MenuItem value={"GET"}>GET</MenuItem>*/}
                      {/*<MenuItem value={"PATCH"}>PATCH</MenuItem>*/}
                      {/*<MenuItem value={"PUT"}>PUT</MenuItem>*/}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{width: "100%"}}>
                  <FormControl
                    fullWidth
                    style={{marginTop: "24px", width: "50%"}}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "8px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        fontWeight: "normal",
                        lineHeight: "19px",
                        color: "#868686"
                      }}
                    >
                      Content Type
                    </Grid>

                    <Select
                      name="dataType"
                      labelId="demo-simple-select-outlined-label"
                      className="dropdown-mat"
                      defaultValue={"JSON"}
                      value={ApiExportStore.data.data_type}
                      // value={"JSON"}
                      disableUnderline
                      variant="standard"
                      align="left"
                      onChange={this.handleChange}

                    >
                      <MenuItem value={"JSON"}>JSON</MenuItem>
                      {/* <MenuItem value={"XML"}>XML</MenuItem>
                <MenuItem value={"HTML"}>HTML</MenuItem>
                <MenuItem value={"TEXT"}>TEXT</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <FormControl fullWidth style={{marginTop: "24px"}}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: "8px",
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      fontWeight: "normal",
                      lineHeight: "19px",
                      color: "#868686"
                    }}
                  >
                    Endpoint URL
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{width: "100%"}}
                    className="endpoint-input"
                  >
                    <MentionEditor
                      availablePlaceholders={this.props.availablePlaceholders}
                      placeholders={keyArray}
                      text={ApiExportStore.data.endpoint_url}
                      placeholder="Insert API endpoint URL here"
                      onChange={(value) => {
                        ApiExportStore.setEndPointUrl(value);
                        this.checkCondition();
                      }}
                      onFocus={(e) => {
                        this.setState({focus: "endpoint"});
                      }}
                      error={urlError ? true : false}
                      helperText={urlError ? urlErrorMessage : null}
                    />
                  </Grid>
                </FormControl>
                <FormControl fullWidth style={{marginTop: "24px"}}>
                  <Grid item xs={12} className="bodyInput">
                    Body
                  </Grid>
                  <Grid item xs={12} style={{width: "100%"}}>
                    <div id="aceditor" style={{width: "100%", height: "700px"}}>
                      <AceEditor
                        mode="javascript"
                        theme="github"
                        width="100%"
                        height="100%"
                        showPrintMargin={false}
                        showGutter={true}
                        highlightActiveLine={true}
                        wrapEnabled={true}
                        value={ApiExportStore.data.body}
                        onChange={(html) => {
                          if (html !== ApiExportStore.data.body) {
                            ApiExportStore.setApiExportBody(html);
                          }
                        }}
                        fontSize={16}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: false,
                          showLineNumbers: true,
                          tabSize: 2
                        }}
                        editorFocus={() => {
                          this.setState({focus: "editor"});
                        }}/>
                    </div>
                  </Grid>
                </FormControl>
                {ApiExportStore.data.headers.length ? (
                  <FormControl
                    fullWidth
                    style={{marginTop: "24px", color: "#868686"}}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "8px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        fontWeight: "normal",
                        lineHeight: "19px"
                      }}
                    >
                      Custom Header
                    </Grid>
                    <CustomHeader
                      availablePlaceholders={this.props.availablePlaceholders}
                      onFocus={(focus) => {
                        this.setState({focus})
                      }}
                      validateRequest={ () => {
                        this.checkCondition();
                      }}
                      keyPlaceholders={this.state.keyPlaceholders}
                      valuePlaceholders={this.state.valuePlaceholders}
                      headerError={this.state.headerError}
                    />
                  </FormControl>
                ) : (
                  <Grid></Grid>
                )}
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant={"text"}
                    className={"custom-icon-btn"}
                    onClick={this.insertHeaders}
                  >
                    <AddIcon/> Add Header
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>

          <div hidden={ApiExportStore.data.setupMode !== "CURL"} style={{width: "100%"}}>
            <Paper className="componentPaper">
              <Grid
                container
                style={{
                  padding: "12px 24px 24px 24px",
                  background: "white"
                }}
                className="api-editor-container"
              >
                <Grid item xs={12} style={{width: "100%"}}>
                  <FormControl fullWidth style={{marginTop: "24px"}}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: "8px",
                        fontSize: "14px",
                        fontFamily: "Open Sans",
                        fontWeight: "normal",
                        lineHeight: "19px",
                        color: "#868686"
                      }}
                    >
                      cURL
                    </Grid>
                    <Grid item xs={12} style={{width: "100%"}}>
                      <div id="aceditor" style={{width: "100%", height: "200px"}}>
                        <AceEditor
                          mode="javascript"
                          theme="github"
                          width="100%"
                          height="100%"
                          showPrintMargin={false}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={ApiExportStore.data.curl}
                          wrapEnabled={true}
                          onChange={(value) => {
                            if (value) {
                              ApiExportStore.setCurl(value);
                              this.forceUpdateState()
                            }
                          }}
                          fontSize={16}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: false,
                            showLineNumbers: true,
                            tabSize: 2
                          }}
                          editorFocus={() => {
                            this.setState({focus: "curl"});
                          }}/>
                      </div>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      );
    } else return <IndividualComponentLoader/>;
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(ApiExportTemplate));
