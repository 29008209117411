import React, { useState, useEffect } from "react";

// Material UI
import { Grid, MenuItem, Select, styled } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { send_request } from "../../../utils/Request";
import ProjectStore from "../../ProjectCanvas/ProjectStore";
import { format } from "../../../utils/Date";

const ReRunBox = styled(Grid)(({ borderColor, backgroundColor }) => ({
    border: `1px solid ${borderColor}`,
    backgroundColor: backgroundColor,
    borderRadius: "4px",
    padding: "8px",
    margin: "16px 0px 8px 0px",
}));

const FlexGrid = styled(Grid)(({ horizontal, vertical }) => ({
    display: "flex",
    alignItems: vertical,
    justifyContent: horizontal,
    fontSize: "14px",
}));

const Error = styled(ErrorIcon)(({ color }) => ({
    color: color,
    marginRight: "12px",
    height: "16px",
    width: "auto",
}));

const StyledSelect = styled(Select)(({ isProgressView }) => ({
    backgroundColor: "rgba(248, 248, 248, 1)",
    border: "none",
    marginTop: isProgressView ? "0px" : "16px",
}));

const BoldText = styled("span")(({}) => ({
    fontWeight: 700,
}));

function ReRunSelectionBox({ reRuns, session, setSession, isLatestRun, setIsLatestRun, calculateTimeElapsed, setLoadReRun, isProgressView }) {
    const handleRerunSelected = (e) => {
        let id = e.target.value;

        let isLatestSelected = id === session.projectSessionId;

        setLoadReRun(true);

        // Else, we know they selected a history element
        let url = `project-service/project/get_log/${session.projectId}/${session.projectSessionId}`;

        let queryParams = {};
        if (!isLatestSelected) queryParams["reRunHistoryId"] = id;

        send_request(url, null, queryParams, "GET")
            .then((res) => {
                let newSession = calculateTimeElapsed(res.data);

                if (!isLatestSelected) newSession["reRunHistoryId"] = id; // Set history id

                setSession(newSession, isLatestSelected);
                setIsLatestRun(isLatestSelected);
                setLoadReRun(false);
            })
            .catch((e) => {
                setLoadReRun(false);
                console.log(e);
            });
    };

    const getRerunBox = () => {
        return (
            <ReRunBox container borderColor="rgba(255, 183, 77, 1)" backgroundColor="rgba(255, 234, 203, 1)">
                <FlexGrid item xs={12} horizontal="flex-start" vertical="center">
                    <Error color="rgba(255, 183, 77, 1)" />
                    <span>
                        This session has been re-run and you are currently viewing the <BoldText>latest version</BoldText>. View historical data from previous runs by selecting it below.
                    </span>
                </FlexGrid>
                <FlexGrid item xs={12}>
                    {getSelectDropdown()}
                </FlexGrid>
            </ReRunBox>
        );
    };

    const getHistoryBox = () => {
        return (
            <ReRunBox container borderColor="rgba(0, 0, 0, 1)" backgroundColor="rgba(218, 218, 218, 1)">
                <FlexGrid item xs={12} horizontal="flex-start" vertical="center">
                    <Error color="rgba(0, 0, 0, 1)" />
                    <span>This session has been re-run and its data has been archived. View historical data from previous runs by selecting it below.</span>
                </FlexGrid>
                <FlexGrid item xs={12}>
                    {getSelectDropdown()}
                </FlexGrid>
            </ReRunBox>
        );
    };

    const getSelectDropdown = () => {
        // sorting rerun based on rerun times, and getting the first one to get the date of latest run
        let sortedReruns = reRuns
            .sort((a, b) => -new Date(a.reRunAt).getTime() + new Date(b.reRunAt).getTime());
        return (
            <StyledSelect size="small" value={session.reRunHistoryId ? session.reRunHistoryId : session.projectSessionId} onChange={handleRerunSelected} isProgressView={isProgressView}>
                {/*+00:00 tells the Date.js code that the current date is in UTC, so proper conversion can be done.*/}
                <MenuItem value={session.projectSessionId}>📍 {format(sortedReruns[0].reRunAt+"+00:00", ProjectStore.state.timezone)} (latest)</MenuItem>
                {sortedReruns
                    .map((re, idx,originalArray) => {
                        let isOriginal = idx === reRuns.length - 1;
                        /* Using the rerun date of the next rerun to get the date when the current one was rerun and using the created at date for the first session*/
                         return <MenuItem value={re.reRunHistoryId}>{`📍 ${originalArray[idx+1]?format(originalArray[idx+1].reRunAt+"+00:00", ProjectStore.state.timezone):format(session.createdAt,ProjectStore.state.timezone)} ${isOriginal ? "(original)" : ""}`}</MenuItem>;
                    })}
            </StyledSelect>
        );
    }

    const getCorrectItem = () => {

        if (reRuns === null) return;
        if (isProgressView) return getSelectDropdown();
        
        return !isLatestRun ? getHistoryBox() : getRerunBox();
        
    }

    return getCorrectItem();
}

export default ReRunSelectionBox;
