import ProjectStore from "../components/ProjectCanvas/ProjectStore";
import { filterPropertiesBeforeSave } from "../components/pages/Canvas/AIBuilder/util/NoteDiagramUtil";
//this is a constructor for a save event
export function createSaveEvent(
  type,
  projectId,
  viewport,
  elements,
  eventData
) {
  let saveEvent = {
    projectId: projectId,
    type: type,
    viewport: viewport,
    positions: ProjectStore.readCanvasIntoComponents(elements),
    notePositions: filterPropertiesBeforeSave(
      ProjectStore.readCanvasIntoNotes(elements)
    ),
    eventData: eventData
  };

  return saveEvent;
}

