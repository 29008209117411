import { computed, observable, decorate, action, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";

class KnowledgeBank {
  constructor() {
    this.initialiseEmpty();
  }
  setComponentData = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const obj = this.componentData();
      this.data = [obj];
      this.lastModified = "";
      this.name = "";
    }
  };

  setFieldName = name => {
    this.name = name;
  };
  setLastModified = lastModified => {
    this.lastModified = lastModified;
  };

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }
  
  setFocus = index => {
    this.placeholders = [];
    this.position = index;
  };

  keyAuto = () => {
    return "content" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }

  componentData = () => {
    return {
      key: this.keyAuto(),
      type: "RICH",
      content: "",
      keyRandom: Math.random()
    };
  };

  setDataFromExcel = data => {
    this.data = data;
  };

  setField = (value, index, type) => {
    try{
      switch (type) {
        case "key":
          this.data[index].key = value;
          return;
        case "type":
          this.data[index].type = value;
          this.data[index].content = "";
          return;
        case "content":
          this.data[index].content = value;
          return;
      }
    } catch (error){
      console.error(error);
    }
  };

  addComponentData = () => {
    const obj = this.componentData();
    this.data.push(obj);
  };

  deleteComponent = index => {
    this.data.splice(index, 1);
  };
  initialiseEmpty = () => {
    const obj = this.componentData();
    this.name = "";
    this.position = null;
    this.componentId = "";
    this.type = "knowladge_bank";
    this.lastModified = "";
    this.data = [obj];
    this.error = false;
    this.placeholders = [];
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      position: this.position,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: this.placeholders
    };
  }
}

decorate(KnowledgeBank, {
  componentId: observable,
  position: observable,
  name: observable,
  data: observable,
  type: observable,
  placeholders: observable,
  lastModified: observable,
  error: observable,
  addComponentData: action,
  setComponentData: action,
  setPlaceholders: action,
  setLastModified: action,
  setDataFromExcel: action,
  setField: action,
  setFocus: action,
  deleteComponent: action,
  initialiseEmpty: action,
  template: computed
});

const knowledgeBank = new KnowledgeBank();
export default knowledgeBank;
