import React from 'react';
const DynamicIcons = ({ icons, size }) => {
  const ICON_SIZE = size || 24;
  const OVERLAP_OFFSET = -8;

  //Takes in a list of dataURI icons and icon size and return an icon. If there is more than one dataURI strings
  //Then it will return multiple icons that are overlapped with each other
  return (
    icons.map((iconUrl, index) => (
      <img
        key={`icon-${index}`}
        width={ICON_SIZE}
        height={ICON_SIZE}
        style={{ marginLeft: index === 0 ? 0 : `${OVERLAP_OFFSET}px` }}
        src={iconUrl}
        alt={`icon-${index + 1}`}
      />
    ))
  );
};

export default DynamicIcons;