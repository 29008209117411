import { computed, observable, decorate, action, toJS } from "mobx";

class UpdateDatabaseRecordStore {
  constructor() {
    this.initialiseEmpty();
  }

  setUpdateDatabaseRecordTemplate = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      const data = this.initData()
      this.data = data
      this.lastModified = "";
      this.name = "";
    }

  };

  setData = (data) => {
    this.data.columns = data
  }

  clearData = () => {
    this.data.columns = []
  }

  setDatabaseList = (databaseList) => {
    this.data.databaseList = databaseList;
  }

  setTargetDatabaseId = (targetDatabaseId) => {
    this.data.targetDatabaseId = targetDatabaseId;
  }

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  }

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }

  setChecked = (value,index) => {
    this.data.columns[index].checked = value
  }

  selectAll = () => {
    let selectAll = true
    this.data.columns.forEach(cell => {
      if (!cell.checked){
        selectAll = false
      }
    })

    if (selectAll){
      this.data.columns.forEach(cell => {
        cell.checked = false
      })
    }else {
      this.data.columns.forEach(cell => {
        cell.checked = true
      })
    }
  }

  setFocus = (index,focus) => {
    this.position = index;
    this.focus = focus;
    this.placeholders = []
  };

  setUpdateMethod = (value) => {
    this.data.updateMethod = value
  }

  keyAuto = () => {
    return "update_record_" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }

  setPlaceholderKey = (value) => {
    this.data.key = value
  }

  setId = (value) => {
    this.data.id = value
  }

  deleteId = () => {
    this.data.id = ""
  }

  initData = () => {
    return {
      key: this.keyAuto(),
      columns: [],
      updateMethod : "update_by_record_id",
      targetDatabaseId : null,
      databaseList : [],
      id: ""
    };
  }

  initialiseEmpty = () => {
    this.name = "";
    this.componentId = "";
    this.type = "update_database_record";
    this.lastModified = "";
    this.placeholders = [];
    this.data = this.initData();
    this.position = null;
    this.focus = null
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: toJS(this.placeholders),
      position: this.position,
      focus: this.focus
    };
  }
}

decorate(UpdateDatabaseRecordStore, {
  componentId: observable,
  name: observable,
  data: observable,
  type: observable,
  lastModified: observable,
  setLastModified: action,
  initialiseEmpty: action,
  template: computed,
  placeholders: observable,
  setPlaceholders: action,
  position: observable,
  setFocus: action,
  targetDatabaseId: observable,
  setTargetDatabaseId: action,
  focus: observable
});

const updateDatabaseRecordStore = new UpdateDatabaseRecordStore();
export default updateDatabaseRecordStore;
