import {computed, observable, decorate, action, toJS} from "mobx";
import {DemoJson} from "./Components/AceEditorPlaceholder";

class ApiExportStore {
  constructor() {
    this.initialiseEmpty();
  }

  setApiExportTemplate = component => {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
      if (!this.data.setupMode) {
        this.data.setupMode = "MANUAL"
      }
    } else {
      this.data = {
        request_type: "POST",
        data_type: "JSON",
        endpoint_url: "",
        body: DemoJson,
        headers: [],
        setupMode: "MANUAL",
        curl: "",
      };
      this.lastModified = "";
      this.name = "";
    }

  };

  updateComponentData(data) {
    if (data && data.curl) {
      this.data.curl = data.curl;
      this.data.setupMode = data.setupMode;
    }
  }

  setRequestType = requestType => {
    this.data.request_type = requestType;
  };

  setDataType = dataType => {
    this.data.data_type = dataType;
  };

  setEndPointUrl = endPointUrl => {
    this.data.endpoint_url = endPointUrl;
  };

  setApiExportName = name => {
    this.name = name;
  };

  setApiExportBody = Body => {
    this.data.body = Body;
  };
  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  }

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }

  setSetupMode = (mode) => {
    this.data.setupMode = mode;
  }

  setCurl = (curl) => {
    this.data.curl = curl;
  }

  initialiseEmpty = () => {
    this.name = "";
    this.componentId = "";
    this.type = "api_export";
    this.lastModified = "";
    this.placeholders = [];
    this.data = {
      request_type: "POST",
      data_type: "JSON",
      endpoint_url: "",
      body: "",
      headers: [],
      setupMode: "MANUAL",
      curl: "",
    };
  };

  get template() {
    return {
      componentId: this.componentId,
      name: this.name,
      data: toJS(this.data),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: toJS(this.placeholders)
    };
  }
}

decorate(ApiExportStore, {
  componentId: observable,
  name: observable,
  data: observable,
  type: observable,
  lastModified: observable,
  setApiExportTemplate: action,
  updateComponentData: action,
  setRequestType: action,
  setDataType: action,
  setEndPointUrl: action,
  setApiExportName: action,
  setApiExportBody: action,
  setLastModified: action,
  initialiseEmpty: action,
  template: computed,
  placeholders: observable,
  setPlaceholders: action,
  setSetupMode: action,
  setCurl: action,
});

const apiExportStore = new ApiExportStore();
export default apiExportStore;
