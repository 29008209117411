import React, { useEffect, useState } from "react";

// MUI
import { Grid, Skeleton, styled } from "@mui/material";
import StartIcon from "@mui/icons-material/Start";

// Util
import { send_request } from "../../../utils/Request";

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "4px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

const Start = styled(StartIcon)({
    width: "auto",
    height: "16px",
    marginRight: "8px",
});

const Hyperlink = styled("span")({
    color: "rgba(33, 150, 243, 1)",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(33, 150, 243, 0.85)",
    },
});

const RightArrow = styled(Start)({
    height: "22px",
    width: "auto"
})

function OpenExistingSession({ sessionId, projectId, InfoContainer }) {
    const [isTestSession, setIsTestSession] = useState(null);

    useEffect(async () => {
        const url = `project-service/project-session/is_test_session/${projectId}/${sessionId}`;
        let json = await send_request(url, null, null, "GET");

        if (!json) setIsTestSession(false);
        else setIsTestSession(json.data);
    }, [sessionId]);

    return isTestSession !== null ? (
        <Grid item xs={12}>
            <InfoContainer container marginTop={"2px"} alignItems="center" justifyContent="flex-start">
                <RightArrow />
                <span>
                    Run with existing data from{" "}
                    <Hyperlink
                        onClick={() => {
                            window.open(`/project/logs/progress_view/${projectId}/${sessionId}?test=${isTestSession}`, "_blank");
                        }}
                    >
                        session {sessionId}
                    </Hyperlink>
                </span>
            </InfoContainer>
        </Grid>
    ) : (
        <StyledSkeleton variant="rectangular" width="450px" />
    );
}

export default OpenExistingSession;
