import {Box, Button, Modal} from "@mui/material";
import {CompareArrows} from "@mui/icons-material";
import ReactDiffViewer, {DiffMethod} from "react-diff-viewer";
import React, {useState} from "react";
import {styled} from "@mui/system";
import * as PropTypes from "prop-types";
import {compareJsonStyle} from "./CompareJsonStyle";
import {CustomDialog} from "../../../../../Component/Dialog";

const StyledModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1400px',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '0',
    borderRadius: '8px',
    '& .diff-viewer': {
        height: 'calc(80vh)',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    }
});

const PROPOSED_TITLE = "Proposed";

const CURRENT_TITLE = "Current";

const OBJECT_TYPE = 'object';

export function EditCompareButton(props) {
    const [openCompareModal, setOpenCompareModal] = useState(false);
    //Recursively remove id fields. Did it mainly for Forms as the form fields ids are dummies
    const removeIdKey = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map(item => removeIdKey(item));
        } else if (typeof obj === OBJECT_TYPE && obj !== null) {
            const { id, ...rest } = obj;

            return Object.keys(rest).reduce((result, key) => {
                result[key] = removeIdKey(rest[key]);
                return result;
            }, {});

        }
        return obj;
    };
    const currentValue = JSON.stringify(removeIdKey(props.compare?.current), null, 2);
    const proposedValue = JSON.stringify(removeIdKey(props.compare?.new), null, 2);
    return (
        <>
            <Button
                className={props.classes.newWorkflowButton}
                variant="outlined"
                startIcon={<CompareArrows/>}
                onClick={() => setOpenCompareModal(true)}
            >
                Compare Changes
            </Button>

            <CustomDialog
                isOpen={openCompareModal}
                handleClose={() => setOpenCompareModal(false)}
                size={'lg'}
                contents={
                    <ReactDiffViewer
                        styles={compareJsonStyle}
                        expandAll={true}
                        compareMethod={DiffMethod.WORDS}
                        oldValue={currentValue}
                        newValue={proposedValue}
                        splitView={true}
                        showDiffOnly={false}
                        leftTitle={CURRENT_TITLE}
                        rightTitle={PROPOSED_TITLE}
                    />
                }
            />
        </>
    );
}

EditCompareButton.propTypes = {
    classes: PropTypes.any,
    compare: PropTypes.any
};