import React from "react";
import "./Cards.css";
import {
  Grid,
  Paper,
  TextField,
  Select,
  Switch,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { toJS, reaction } from "mobx";
import ChipInput from "../ChipInput/ChipInput";
import { Autocomplete } from "@mui/material";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { send_request } from "../../utils/Request";
import SelectCredential from "../Component/SelectCredential";
import OutsideClickHandler from "react-outside-click-handler";
import SequentialFormContainer from "./SequentialFormContainer";
import { inject, observer } from "mobx-react";
import { decorate, action } from "mobx";
import TextEditorExtraPlaceholder from "../TextEditor/TextEditorExtraPlaceholder";
import { getColourFromString } from "../../utils/ColourNameGenerator";
import { ListItemChip } from "../../utils/ListItemChip";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import MentionEditor from "../TextEditor/MentionEditor";

const styles = () => ({
  root: {
    "& .react-colorful ": {
      zIndex: "1000 !important",
      position: "absolute",
      marginTop: "28px",
    },
    "& .react-colorful &input ": {
      position: "relative",
    },
  },
});

class TaskInfo extends React.Component {
  constructor(props) {
    super();
    this.insertPlaceholder = this.insertPlaceholder.bind(this);

    this.state = {
      allUsers: [],
      reminder: false,
      placeholders: [],
      reminderPlaceholders: [],
      availablePlaceholders: [{ placeholders: [] }],
      focus: "",
      users: [],
      reminderOption: "time",
      email: [],
      ccEmails: [],
      replyToEmails: [],
      reminderEmail: [],
      reminderOnPlaceholderValue: [],
      emailSubjectPlaceholders: [],
      reminderEmailSubjectPlaceholders: [],
      reminderType: "default",
      reminderLimit: 0,
      reminderFrequency: "ONCE",
      credentialId: "",
      toDoColor: "#F59300",
      completedColor: "#55A77A",
      toDoLabel: "To do",
      completedLabel: "Completed",
      showToDoPicker: false,
      showCompletedPicker: false,
      notificationMethod: "email",
      dueDateStatus: "no_due_date",
      dueDatePeriod: "null",
      dueDateOn: [],
      slackWebhook: [],
      channelID: [],
      threadID: [],
      dueDateAfter: [],
      datetimePlaceholderError: "",
      amountOfTimeError: "",
    };
    this.reactionDisposer = reaction(
      () => toJS(SequentialFormContainer.SelectedPlaceholder),
      (placeholder) => {
        this.insertPlaceholder(placeholder);
      }
    );
  }

  componentWillUnmount() {
    // Dispose of MobX reaction
    if (this.reactionDisposer) {
      this.reactionDisposer();
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const users = await send_request(
      "authz-service/get-active-users/",
      "",
      "",
      "get"
    )
      .then((value) => {
        this.setState({ allUsers: value.data });

        let loadUsers = [];
        let loadReminderUsers = [];

        if (SequentialFormContainer.users) {
          toJS(SequentialFormContainer.users).map((username) => {
            let user = value.data.filter((user) => {
              return user.userName === username;
            });
            if (user.length > 0) {
              loadUsers.push(user[0]);
            } else {
              loadUsers.push({
                userName: username,
                displayName: username,
              });
            }
          });
        }

        if (SequentialFormContainer.reminderUsers) {
          toJS(SequentialFormContainer.reminderUsers).map((username) => {
            let user = value.data.filter((user) => {
              return user.userName === username;
            });
            if (user.length > 0) {
              loadReminderUsers.push(user[0]);
            } else {
              loadReminderUsers.push({
                userName: username,
                displayName: username,
              });
            }
          });
        }

        let { reminderOption, reminderEmailMessage } = SequentialFormContainer;

        this.setState({
          loading: false,
          availablePlaceholders: toJS(SequentialFormContainer.placeholders),
          users: loadUsers,
          reminderUsers: loadReminderUsers,
          ccEmails: SequentialFormContainer.ccEmails,
          replyToEmails: SequentialFormContainer.replyToEmails,
          email: SequentialFormContainer.email,
          reminderEmail: SequentialFormContainer.reminderEmail,
          reminderCcEmails: SequentialFormContainer.reminderCcEmails,
          reminderReplyToEmails: SequentialFormContainer.reminderReplyToEmails,
          reminderOption: SequentialFormContainer.reminderOption,
          reminderOnPlaceholderValue:
            SequentialFormContainer.reminderOnPlaceholderValue,
          reminderType: SequentialFormContainer.reminderType,
          reminderLimit: SequentialFormContainer.reminderLimit,
          reminderInterval: SequentialFormContainer.reminderInterval,
          reminderFrequency: SequentialFormContainer.reminderFrequency,
          credentialId: SequentialFormContainer.credentialId,
          reminder:
            SequentialFormContainer.reminder != null
              ? SequentialFormContainer.reminder
              : SequentialFormContainer.reminderEmail.length > 0,
          toDoColor: SequentialFormContainer.toDoColor,
          completedColor: SequentialFormContainer.completedColor,
          toDoLabel: SequentialFormContainer.toDoLabel
            ? SequentialFormContainer.toDoLabel
            : "To do",
          completedLabel: SequentialFormContainer.completedLabel
            ? SequentialFormContainer.completedLabel
            : "Completed",
          notificationMethod: SequentialFormContainer.notificationMethod
            ? SequentialFormContainer.notificationMethod
            : "email",
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.log(e);
      });
  }

  onPlaceholderSelected = (placeholderUpdateEvent) => {
    SequentialFormContainer.SelectedPlaceholder = placeholderUpdateEvent;
  };

  handleNotificationType = (event) => {
    SequentialFormContainer.notificationMethod = event.target.value;
    this.setState({ notificationMethod: event.target.value });
  };

  handleDueDate = (event) => {
    SequentialFormContainer.dueDateStatus = event.target.value;
    this.setState({ dueDateStatus: event.target.value });
  };

  handleDueDatePeriod = (event) => {
    SequentialFormContainer.dueDatePeriod = event.target.value;
    let dueDatePeriod = event.target.value;
    this.setState({ dueDatePeriod });
  };

  handleSetUsersOnBlur = (event, reminder) => {
    const newChip = event.target.value;
    if (newChip.length <= 0) {
      return;
    }
    let newUsers;
    if (!reminder) {
      newUsers = [...SequentialFormContainer.users, newChip];
      this.setState((prevState) => ({ users: [...prevState.users, newChip] }));
      SequentialFormContainer.users = newUsers;
    } else {
      newUsers = [...SequentialFormContainer.reminderUsers, newChip];
      this.setState((prevState) => ({
        reminderUsers: [...prevState.reminderUsers, newChip],
      }));
      SequentialFormContainer.reminderUsers = newUsers;
    }
  };

  handleSetUsers = (event, inputValue, reason, reminder) => {
    if (!reminder) {
      if (reason == "clear") {
        this.setState({ users: [] });
        SequentialFormContainer.users = [];
      } else {
        this.setState({ users: inputValue });
        let arr = inputValue.map((item) => {
          if (item.userName !== undefined) {
            return item.userName;
          } else {
            return item;
          }
        });
        SequentialFormContainer.users = arr;
      }
    } else {
      if (reason == "clear") {
        this.setState({ reminderUsers: [] });
        SequentialFormContainer.reminderUsers = [];
      } else {
        this.setState({ reminderUsers: inputValue });
        let arr = inputValue.map((item) => {
          if (item.userName !== undefined) {
            return item.userName;
          } else {
            return item;
          }
        });
        SequentialFormContainer.reminderUsers = arr;
      }
    }
  };

  handleChangeField = (key, chip) => {
    SequentialFormContainer[key] = chip;
    this.state[key] = chip;
  };

  handleDeleteField = (key, index) => {
    SequentialFormContainer[key].splice(index, 1);
    const newField = [...this.state[key]];
    newField.splice(index, 1);
    this.setState({
      [key]: newField,
    });
  };

  handleChangeEmailSubject = (newSubject) => {
    SequentialFormContainer.emailSubject = newSubject;
  };

  handleChangeEmailMessage = (html) => {
    if (html !== SequentialFormContainer.message) {
      SequentialFormContainer.setMessage(html);
    }
  };

  handleChangeReminder = () => {
    SequentialFormContainer.reminder = !this.state.reminder;
    this.setState({ reminder: !this.state.reminder });
  };

  handleChangeReminderEmailSubject = (newSubject) => {
    SequentialFormContainer.reminderEmailSubject = newSubject;
  };

  handleChangeReminderEmailMessage = (html) => {
    if (html !== SequentialFormContainer.reminderEmailMessage) {
      SequentialFormContainer.reminderEmailMessage = html;
    }
  };

  handleChangeReminderOption = (event) => {
    this.setState({ reminderOption: event.target.value });
    SequentialFormContainer.reminderOption = event.target.value;
  };

  handleChangeReminderInterval = (event) => {
    SequentialFormContainer.reminderInterval = event.target.value;
    this.setState({ reminderInterval: event.target.value });
  };

  handleChangeReminderType = (event) => {
    SequentialFormContainer.reminderType = event.target.value;
    this.setState({ reminderType: event.target.value });
  };

  handleChangeReminderFrequency = (event) => {
    SequentialFormContainer.reminderFrequency = event.target.value;
    this.setState({ reminderFrequency: event.target.value });
  };

  handleChangeReminderOnPlaceholderValue = (chip) => {
    SequentialFormContainer.reminderOnPlaceholderValue = chip;
    this.setState({ reminderOnPlaceholderValue: chip });
  };

  handleDeleteReminderOnPlaceholderValue = (index) => {
    const newReminderOnPlaceholderValue = [
      ...this.state.reminderOnPlaceholderValue,
    ];
    newReminderOnPlaceholderValue.splice(index, 1);

    SequentialFormContainer.reminderOnPlaceholderValue = newReminderOnPlaceholderValue;

    this.setState({
      reminderOnPlaceholderValue: newReminderOnPlaceholderValue,
    });
  };

  handleChangeReminderLimit = (event) => {
    SequentialFormContainer.reminderLimit = event.target.value;
    this.setState({ reminderLimit: event.target.value });
  };

  insertPlaceholder(placeholder) {
    const { focus, availablePlaceholders } = this.state;
    const {
      email,
      reminderEmail,
      dueDateOn,
      ccEmails,
      replyToEmails,
      reminderCcEmails,
      reminderReplyToEmails,
    } = SequentialFormContainer;

    if (placeholder && placeholder.key !== undefined) {
      const updateField = (key, newValue) => {
        SequentialFormContainer[key] = newValue;
        this.setState({ [key]: newValue });
      };

      const addToField = (key, newValue) => {
        // Ensure the field is an array and exists
        const currentField = toJS(SequentialFormContainer[key]) || [];
      
        // Check if the field is an array
        if (!Array.isArray(currentField)) {
          console.error(`${key} is not an array`);
          return;
        }
      
        const updatedField = [...currentField, newValue];
        updateField(key, updatedField);
      };
            const createPlaceholder = (key, text) => ({
        label: placeholder.style.default,
        text: text || placeholder.key,
        style: toJS(placeholder.style),
        focus,
      });

      const handleFieldUpdate = (field) => {
        const value = placeholder.isNotPlaceholder
          ? placeholder.key
          : `\${${placeholder.key}}`;
        addToField(field, value);
      };

      switch (focus) {
        case "email":
        case "ccEmails":
        case "replyToEmails":
        case "reminderEmail":
        case "reminderCcEmails":
        case "reminderReplyToEmails":
        case "reminderOnPlaceholderValue":
          handleFieldUpdate(focus);
          break;
        case "editor":
        case "subject":
        case "reminderEmailSubject":
        case "reminderEditor":
          this.setState((prevState) => ({
            placeholders: [
              ...prevState.placeholders,
              createPlaceholder(placeholder.style, placeholder.key),
            ],
          }));
          break;
        case "assignUser":
          addToField("users", {
            displayName: `\${${placeholder.key}}`,
            userName: `\${${placeholder.key}}`,
          });
          break;
        case "dueDateOn":
          if (
            availablePlaceholders[0].placeholders.find(
              (p) => p.key === placeholder.key
            ).type === "datetime"
          ) {
            this.setState({ datetimePlaceholderError: "" });
            handleFieldUpdate("dueDateOn");
          } else {
            this.setState({
              datetimePlaceholderError: "The field must be date time type",
            });
          }
          break;
        case "dueDateAfter":
          if (
            placeholder.isNotPlaceholder ||
            !isNaN(parseInt(placeholder.key) ||
            //this colour is number placeholder color. 
            //there is no other way to diffiritient placeholders by type here
            (placeholder.style && placeholder.style.backgroundColor === "#FAD1DF") 
          )
          ) {
            this.setState({ amountOfTimeError: "" });
            handleFieldUpdate("dueDateAfter");
          } else {
            this.setState({
              amountOfTimeError: "The field must be number type",
            });
          }
          break;
        case "slackWebhook":
        case "channelID":
        case "threadID":
          handleFieldUpdate(focus);
          break;
        default:
          break;
      }
    }
  }

  handleSetToDoColor = (color) => {
    SequentialFormContainer.toDoColor = color;
    this.setState({ toDoColor: color });
  };

  handleSetToDoLabel = (label) => {
    SequentialFormContainer.toDoLabel = label;
    this.setState({ toDoLabel: label });
  };

  handleSetCompletedColor = (color) => {
    SequentialFormContainer.completedColor = color;
    this.setState({ completedColor: color });
  };

  handleSetCompletedLabel = (label) => {
    SequentialFormContainer.completedLabel = label;
    this.setState({ completedLabel: label });
  };

  handleDeleteToDoChip = (label) => {
    SequentialFormContainer.toDoLabel = "";
    this.setState({ completedLabel: "" });
  };

  render() {
    const {
      placeholders,
      availablePlaceholders,
      reminderPlaceholders,
      reminder,
      loading,
      allUsers,
      reminderOption,
      notificationMethod,
      reminderOnPlaceholderValue,
      emailSubjectPlaceholders,
      reminderEmailSubjectPlaceholders,
      reminderType,
      reminderLimit,
      reminderFrequency,
      reminderInterval,
      toDoColor,
      toDoLabel,
      completedColor,
      completedLabel,
      slackWebhook,
      channelID,
      threadID,
    } = this.state;

    let {
      emailSubject,
      email,
      ccEmails,
      replyToEmails,
      message,
      reminderEmailSubject,
      reminderEmailMessage,
      reminderEmail,
      reminderCcEmails,
      reminderReplyToEmails,
      dueDateStatus,
      dueDateOn,
      dueDateAfter,
      dueDatePeriod,
    } = SequentialFormContainer;

    const { classes } = this.props;
    if (!loading)
      return (
        <Grid
          xs={12}
          direction={"column"}
          container
          item
          className={classes.root}
        >
          <Paper elevation={3} className={"paperTask"}>
            <p className={"bold"}>Notification method</p>
            <Select
              onChange={this.handleNotificationType}
              value={notificationMethod}
              placeholder={"Email"}
              className={"selection"}
              style={{ width: '100%' }} // Ensure the width does not exceed the parent div
            >
              <MenuItem value={"email"}>✉️ Email</MenuItem>
              <MenuItem value={"my_tasks_page_only"}>📋 Task inbox</MenuItem>
              {/*<MenuItem value={"slack_message_webhook"}>💬 Slack message (via webhook)</MenuItem>*/}
              {/*<MenuItem value={"slack_message_slack_api"}>💬 Slack message (via Slack API)</MenuItem>*/}
            </Select>
            {notificationMethod === "email" ? (
              <Grid>
                <p className={"bold"}>Email Account</p>
                <Grid item>
                  <SelectCredential
                    onChange={(credentialId) => {
                      SequentialFormContainer.setCredentialId(credentialId);
                    }}
                    value={SequentialFormContainer.getCredentialId()}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid></Grid>
            )}
            {notificationMethod === "slack_message_webhook" && (
              <Grid>
                <p className={"bold"}>Slack webhook URL</p>
                <Grid item>
                  <ChipInput
                    inputValue={slackWebhook}
                    onPlaceholderSelected={this.onPlaceholderSelected}
                    onFocus={() => {
                      this.setState({ focus: "slackWebhook" });
                    }}
                    onChange={(chip) => {
                      let arr = [];
                      arr = chip.map((c) => {
                        if (c.text) {
                          return c.text;
                        } else {
                          return c;
                        }
                      });

                      this.handleChangeField("slackWebhook", arr);
                    }}
                    onDelete={(index) => {
                      this.handleDeleteField("slackWebhook", index);
                    }}
                    fullWidth={true}
                    placeholder={"Insert slack webhook URL "}
                    placeholders={availablePlaceholders}
                  />
                </Grid>
              </Grid>
            )}

            {notificationMethod === "slack_message_slack_api" && (
              <Grid>
                <p className={"bold"}>Slack account</p>
                <Select
                  // onChange={this.handleNotificationType}
                  value={"slackBotA"}
                  placeholder={"Slack account"}
                  className={"selection"}
                  style={{ background: "#ffffff" }}
                >
                  <MenuItem value={"slackBotA"}>Slack Bot A</MenuItem>
                </Select>
                <p className={"bold"}>Channel ID</p>
                <Grid item>
                  <ChipInput
                    inputValue={channelID}
                    onPlaceholderSelected={this.onPlaceholderSelected}
                    onFocus={() => {
                      this.setState({ focus: "channelID" });
                    }}
                    onChange={(chip) => {
                      let arr = [];
                      arr = chip.map((c) => {
                        if (c.text) {
                          return c.text;
                        } else {
                          return c;
                        }
                      });

                      this.handleChangeField("channelID", arr);
                    }}
                    onDelete={(index) => {
                      this.handleDeleteField("channelID", index);
                    }}
                    fullWidth={true}
                    placeholder={"Insert Slack channel ID here "}
                    placeholders={availablePlaceholders}
                  />
                  <p className={"bold"}>Thread ID (optional)</p>
                  <Grid item>
                    <ChipInput
                      inputValue={threadID}
                      onPlaceholderSelected={this.onPlaceholderSelected}
                      onFocus={() => {
                        this.setState({ focus: "threadID" });
                      }}
                      onChange={(chip) => {
                        let arr = [];
                        arr = chip.map((c) => {
                          if (c.text) {
                            return c.text;
                          } else {
                            return c;
                          }
                        });

                        this.handleChangeField("threadID", arr);
                      }}
                      onDelete={(index) => {
                        this.handleDeleteField("threadID", index);
                      }}
                      fullWidth={true}
                      placeholder={"Insert Slack thread ID here "}
                      placeholders={availablePlaceholders}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Paper>
          <Paper elevation={3} className={"paperTask"}>
            <p className={"bold"}>Assign task via username</p>
            <Autocomplete
              options={allUsers}
              clearOnBlur
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  let item =
                    option.displayName === undefined
                      ? option
                      : option.displayName;
                  return (
                    <ListItemChip
                      {...getTagProps({ index })}
                      label={item}
                      colour={getColourFromString(item)}
                    />
                  );
                })
              }
              size={"small"}
              multiple
              freeSolo={true}
              openOnFocus={true}
              getOptionLabel={(option) => {
                return option.displayName;
              }}
              color={"info"}
              value={this.state.users}
              onChange={(event, inputValue, reason) => {
                this.handleSetUsers(event, inputValue, reason, false);
              }}
              onFocus={() => {
                this.setState({ focus: "assignUser" });
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    onBlur={(e) => this.handleSetUsersOnBlur(e, false)}
                    placeholder={"Select users"}
                    size={"small"}
                    variant="outlined"
                  />
                );
              }}
            />
            <p className={"s-text"}>Assign task to users via their username</p>
            {notificationMethod === "email" && (
              <>
                <p className={"bold"}>Assign task via email</p>
                <ChipInput
                  key="email"
                  inputValue={email}
                  onPlaceholderSelected={this.onPlaceholderSelected}
                  onFocus={() => {
                    this.setState({ focus: "email" });
                  }}
                  onChange={(chip) => {
                    let arr = [];
                    arr = chip.map((c) => {
                      if (c.text) {
                        return c.text;
                      } else {
                        return c;
                      }
                    });

                    this.handleChangeField("email", arr);
                  }}
                  onDelete={(index) => {
                    this.handleDeleteField("email", index);
                  }}
                  fullWidth={true}
                  placeholder={"Insert emails "}
                  placeholders={availablePlaceholders}
                />
                <p className={"s-text"}>Assign task to users via their email</p>
              </>
            )}
            <p className={"bold"}>Cc</p>
            <ChipInput
              key="ccEmails"
              inputValue={ccEmails}
              onPlaceholderSelected={this.onPlaceholderSelected}
              onFocus={() => {
                this.setState({ focus: "ccEmails" });
              }}
              onChange={(chip) => {
                let arr = [];
                arr = chip.map((c) => {
                  if (c.text) {
                    return c.text;
                  } else {
                    return c;
                  }
                });

                this.handleChangeField("ccEmails", arr);
              }}
              onDelete={(index) => {
                this.handleDeleteField("ccEmails", index);
              }}
              fullWidth={true}
              placeholder={"Insert emails "}
              placeholders={availablePlaceholders}
            />
            <p className={"s-text"}>
              These users will be Cc'd into the task email but will not be
              assigned to the task
            </p>
            <p className={"bold"}>Reply To</p>
            <ChipInput
              key="replyToEmails"
              inputValue={replyToEmails}
              onPlaceholderSelected={this.onPlaceholderSelected}
              onFocus={() => {
                this.setState({ focus: "replyToEmails" });
              }}
              onChange={(chip) => {
                let arr = [];
                arr = chip.map((c) => {
                  if (c.text) {
                    return c.text;
                  } else {
                    return c;
                  }
                });

                this.handleChangeField("replyToEmails", arr);
              }}
              onDelete={(index) => {
                this.handleDeleteField("replyToEmails", index);
              }}
              fullWidth={true}
              placeholder={"Insert emails "}
              placeholders={availablePlaceholders}
            />
          </Paper>
          <Paper elevation={3} className={"paperTask"}>
            <p className={"bold"}>Task subject</p>
            <MentionEditor
              availablePlaceholders={availablePlaceholders}
              placeholders={emailSubjectPlaceholders}
              onFocus={() => {
                this.setState({ focus: "subject" });
              }}
              size={"small"}
              text={emailSubject}
              onChange={(value) => this.handleChangeEmailSubject(value)}
              fullWidth={true}
              variant={"outlined"}
              placeholder={"Enter subject line here"}
            />
            <p className={"bold"}>Task description</p>
            <TextEditorExtraPlaceholder
              editorFocus={() => {
                this.setState({ focus: "editor" });
              }}
              availablePlaceholders={availablePlaceholders}
              placeholder={placeholders}
              onChange={this.handleChangeEmailMessage}
              contentStyles={{
                border: "1px solid rgba(0, 0, 0, 0.16)",
                boxShadow: "none",
                padding: "8px",
                minWidth: "100%",
              }}
              html={message}
              extraPlaceholders={[
                {
                  key: "taskURL",
                  type: "text",
                  length: 1,
                },
              ]}
            />
            <p className="s-text">
              Type !{`{taskURL}`} to insert the link to complete this task
            </p>
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"baseline"}
            >
              <Grid container item xs={6} direction={"column"}>
                <p className={"bold"}>Task status when assigned</p>
                <Grid
                  container
                  item
                  xs={11}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Grid container item xs={10}>
                    <TextField
                      value={toDoLabel}
                      variant={"outlined"}
                      size={"small"}
                      fullWidth={true}
                      onChange={() => {
                        this.handleSetToDoLabel(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ backgroundColor: `${toDoColor}` }}
                    className={"toDo colorPicker "}
                    onClick={() => {
                      this.setState({
                        showToDoPicker: true,
                      });
                    }}
                  ></Grid>
                  {this.state.showToDoPicker && (
                    <Grid
                      container
                      item
                      xs={12}
                      direction={"row"}
                      justifyContent={"flex-end"}
                    >
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          this.setState({ showToDoPicker: false });
                        }}
                      >
                        <>
                          <HexColorPicker
                            color={toDoColor}
                            onChange={(color) => {
                              this.handleSetToDoColor(color);
                            }}
                          />
                          <HexColorInput
                            color={toDoColor}
                            onChange={(color) => {
                              this.handleSetToDoColor(color);
                            }}
                          />
                        </>
                      </OutsideClickHandler>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={6} direction={"column"}>
                <p className={"bold"}>Task status when completed</p>
                <Grid
                  container
                  item
                  xs={11}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Grid container item xs={10}>
                    <TextField
                      value={completedLabel}
                      variant={"outlined"}
                      size={"small"}
                      fullWidth={true}
                      onChange={() => {
                        this.handleSetCompletedLabel(event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={1}
                    style={{ backgroundColor: `${completedColor}` }}
                    className={"completed colorPicker"}
                    onClick={() => {
                      this.setState({
                        showCompletedPicker: true,
                      });
                    }}
                  ></Grid>
                  {this.state.showCompletedPicker && (
                    <Grid
                      container
                      item
                      xs={12}
                      direction={"row"}
                      justifyContent={"flex-end"}
                    >
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          this.setState({ showCompletedPicker: false });
                        }}
                      >
                        <>
                          <HexColorPicker
                            color={completedColor}
                            onChange={(color) => {
                              this.handleSetCompletedColor(color);
                            }}
                          />
                          <HexColorInput
                            color={completedColor}
                            onChange={(color) => {
                              this.handleSetCompletedColor(color);
                            }}
                          />
                        </>
                      </OutsideClickHandler>
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={4} direction={"column"} />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={3} className={"paperTask"}>
            <p className={"bold"}>Task due date</p>
            <Grid container>
              <Grid item xs={6}>
                <Select
                  onChange={this.handleDueDate}
                  value={dueDateStatus}
                  className={"selection"}
                  style={{ width: '100%' }} // Ensure the width does not exceed the parent div
                >
                  <MenuItem value={"no_due_date"}>No due date</MenuItem>
                  {/*<MenuItem value={"At a specific date and time"}>At a specific date and time</MenuItem>*/}
                  <MenuItem value={"placeholder"}>
                    At datetime placeholder
                  </MenuItem>
                  <MenuItem value={"amount_of_time"}>
                    After an amount of time has elapsed
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            {dueDateStatus === "placeholder" && (
              <Grid container>
                <Grid item xs={12}>
                  <p>Task is due on</p>
                </Grid>
                <Grid item xs={12}>
                  <ChipInput
                    inputValue={dueDateOn}
                    onPlaceholderSelected={this.onPlaceholderSelected}
                    onFocus={() => {
                      this.setState({ focus: "dueDateOn" });
                    }}
                    onChange={(chip) => {
                      let arr = [];
                      arr = chip.map((c) => {
                        if (c.text) {
                          return c.text;
                        } else {
                          return c;
                        }
                      });
                      this.handleChangeField("dueDateOn", arr);
                    }}
                    onDelete={(index) => {
                      this.handleDeleteField("dueDateOn", index);
                    }}
                    fullWidth={true}
                    placeholder={
                      "Type in a date or insert a datetime placeholder"
                    }
                    placeholders={availablePlaceholders}
                  />
                </Grid>
                {this.state.datetimePlaceholderError && (
                  <Grid item xs={12} className="textFieldError">
                    {this.state.datetimePlaceholderError}
                  </Grid>
                )}
              </Grid>
            )}
            {dueDateStatus === "amount_of_time" && (
              <Grid container>
                <Grid item xs={12}>
                  <p>Task is due after</p>
                </Grid>
                <Grid item xs={9}>
                  <ChipInput
                    inputValue={dueDateAfter}
                    onPlaceholderSelected={this.onPlaceholderSelected}
                    onFocus={() => {
                      this.setState({ focus: "dueDateAfter" });
                    }}
                    onChange={(chip) => {
                      let arr = [];
                      arr = chip.map((c) => {
                        if (c.text) {
                          return c.text;
                        } else {
                          return c;
                        }
                      });
                      this.handleChangeField("dueDateAfter", arr);
                    }}
                    onDelete={(index) => {
                      this.handleDeleteField("dueDateAfter", index);
                    }}
                    fullWidth={true}
                    placeholder={
                      "Type in a number or insert a number placeholder"
                    }
                    placeholders={availablePlaceholders}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    onChange={this.handleDueDatePeriod}
                    value={dueDatePeriod}
                    className={"dueDateSelection"}
                  >
                    <MenuItem value={"null"} disabled={true}>
                      Select time period
                    </MenuItem>
                    <MenuItem value={"minutes"}>minutes</MenuItem>
                    <MenuItem value={"hours"}>hours</MenuItem>
                    <MenuItem value={"days"}>days</MenuItem>
                    <MenuItem value={"businessDays"}>business days</MenuItem>
                    <MenuItem value={"weeks"}>weeks</MenuItem>
                    <MenuItem value={"months"}>months</MenuItem>
                  </Select>
                </Grid>
                {this.state.amountOfTimeError && (
                  <Grid item xs={12} className="textFieldError">
                    {this.state.amountOfTimeError}
                  </Grid>
                )}
              </Grid>
            )}
          </Paper>
          <Paper elevation={3} className={"paperTask"}>
            <p className={"bold"}>Reminders</p>
            <Grid container item direction={"row"} xs={12}>
              <Switch
                color={"primary"}
                checked={reminder}
                value={reminder}
                onChange={this.handleChangeReminder}
                className={"switch"}
              />
              <p>Send reminder email</p>
            </Grid>
            {reminder && (
              <>
                <p className={"bold"}>Notification method</p>
                <Select
                  onChange={this.handleNotificationType}
                  value={notificationMethod}
                  placeholder={"Email"}
                  className={"selection"}
                  style={{ width: '100%' }} // Ensure the width does not exceed the parent div
                >
                  <MenuItem value={"email"}>✉️ Email</MenuItem>
                  <MenuItem value={"my_tasks_page_only"}>📋 Task inbox</MenuItem>
                  {/*<MenuItem value={"slack_message_webhook"}>💬 Slack message (via webhook)</MenuItem>*/}
                  {/*<MenuItem value={"slack_message_slack_api"}>💬 Slack message (via Slack API)</MenuItem>*/}
                </Select>
                <p className={"bold"}>When to send reminder</p>
                <Select
                  value={reminderOption}
                  onChange={this.handleChangeReminderOption}
                  disableUnderline={true}
                  variant={"outlined"}
                  className={"reminderOption"}
                >
                  <MenuItem value="time">
                    Send after a time period has elapsed
                  </MenuItem>
                  <MenuItem value="placeholder">
                    Send on date time placeholder
                  </MenuItem>
                </Select>
                {reminderOption === "time" && (
                  <>
                    <Grid
                      container
                      item
                      direction={"row"}
                      alignItems={"center"}
                      xs={12}
                      spacing={2}
                    >
                      <Grid item>
                        <p>
                          Send this reminder email if this task has not been
                          submitted after
                        </p>
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth={"true"}
                          type={"number"}
                          value={reminderInterval}
                          onChange={this.handleChangeReminderInterval}
                          placeholder={"Insert number"}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={2}
                        direction={"column"}
                        justifyContent={"flex-start"}
                      >
                        <Select
                          onChange={this.handleChangeReminderType}
                          value={reminderType}
                          placeholder={"Select period"}
                        >
                          <MenuItem value={"default"}>Select period</MenuItem>
                          <MenuItem value={"minutes"}>minutes</MenuItem>
                          <MenuItem value={"hours"}>hours</MenuItem>
                          <MenuItem value={"days"}>days</MenuItem>
                          <MenuItem value={"businessDays"}>
                            business days
                          </MenuItem>
                          <MenuItem value={"weeks"}>weeks</MenuItem>
                          <MenuItem value={"months"}>months</MenuItem>
                        </Select>
                      </Grid>

                      <Grid container item xs={2}>
                        <Select
                          defaultValue={"ONCE"}
                          value={reminderFrequency}
                          onChange={this.handleChangeReminderFrequency}
                        >
                          <MenuItem value={"ONCE"}>only send once</MenuItem>
                          <MenuItem value={"MULTIPLE"}>
                            keep sending until completed
                          </MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </>
                )}
                {reminderOption === "placeholder" && (
                  <>
                    <Grid
                      container
                      direction={"row"}
                      item
                      alignItems={"center"}
                      xs={12}
                    >
                      <Grid item>
                        <p className={"btnSpace"}>
                          Send this reminder email if this session has not been
                          submitted on
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <ChipInput
                          fullWidth
                          onFocus={() => {
                            this.setState({
                              focus: "reminderOnPlaceholderValue",
                            });
                          }}
                          onPlaceholderSelected={this.onPlaceholderSelected}
                          placeholders={availablePlaceholders}
                          placeholder={"Insert a date time placeholder here"}
                          alwaysShowPlaceholder={false}
                          onDelete={this.handleDeleteReminderOnPlaceholderValue}
                          onChange={(chip) => {
                            let arr = [];
                            arr = chip.map((c) => {
                              if (c.text) {
                                return c.text;
                              } else {
                                return c;
                              }
                            });
                            this.handleChangeReminderOnPlaceholderValue(arr);
                          }}
                          variant={"outlined"}
                          inputValue={reminderOnPlaceholderValue}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      direction={"row"}
                      alignItems={"center"}
                      xs={12}
                    >
                      <p className={"btnSpace"}>
                        Keep sending this reminder email every
                      </p>
                      <TextField
                        value={reminderInterval}
                        type={"number"}
                        inputProps={{ className: "selectDropTarget" }}
                        className={"btnSpace"}
                        onChange={this.handleChangeReminderInterval}
                        style={{ paddingTop: "10px" }}
                      />
                      <Select
                        defaultValue={0}
                        value={reminderType}
                        className={"btnSpace"}
                        inputProps={{ className: "selectDropTarget" }}
                        onChange={this.handleChangeReminderType}
                      >
                        <MenuItem value={0}>Select period</MenuItem>
                        <MenuItem value={1}>minutes</MenuItem>
                        <MenuItem value={2}>hours</MenuItem>
                        <MenuItem value={3}>days</MenuItem>
                        <MenuItem value={6}>business days</MenuItem>
                        <MenuItem value={4}>weeks</MenuItem>
                        <MenuItem value={5}>months</MenuItem>
                      </Select>
                    </Grid>
                  </>
                )}
                {reminderFrequency == "MULTIPLE" ||
                reminderOption === "placeholder" ? (
                  <Grid
                    container
                    item
                    xs={12}
                    direction={"row"}
                    alignItems={"baseline"}
                  >
                    <p className={"btnSpace"}>
                      Total number of reminder emails
                    </p>
                    <TextField
                      variant={"outlined"}
                      max={20}
                      min={1}
                      error={
                        reminderLimit > 20 || reminderLimit < 1 ? true : false
                      }
                      helperText={
                        reminderLimit > 20 || reminderLimit < 1
                          ? "Choose a number between 1 and 20"
                          : ""
                      }
                      size={"small"}
                      className={"btnSpace"}
                      type={"number"}
                      value={reminderLimit}
                      onChange={this.handleChangeReminderLimit}
                    ></TextField>
                  </Grid>
                ) : (
                  ""
                )}
                <p className={"bold"}>Send reminder to these usernames</p>
                <Autocomplete
                  options={allUsers}
                  clearOnBlur
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      let item =
                        option.displayName === undefined
                          ? option
                          : option.displayName;
                      return (
                        <ListItemChip
                          {...getTagProps({ index })}
                          label={item}
                          colour={getColourFromString(item)}
                        />
                      );
                    })
                  }
                  size={"small"}
                  multiple
                  freeSolo={true}
                  openOnFocus={true}
                  onFocus={() => {
                    this.setState({ focus: "reminderUsers" });
                  }}
                  getOptionLabel={(option) => {
                    return option.displayName;
                  }}
                  variant={"outlined"}
                  value={this.state.reminderUsers}
                  className={"autocomplete-reminder-task"}
                  onChange={(event, inputValue, reason) =>
                    this.handleSetUsers(event, inputValue, reason, true)
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        onBlur={(e) => this.handleSetUsersOnBlur(e, true)}
                        placeholder={"Select users"}
                        size={"small"}
                        variant="outlined"
                      />
                    );
                  }}
                />
                <p className={"bold"}>Send reminder to these emails</p>
                <ChipInput
                  inputValue={reminderEmail}
                  onPlaceholderSelected={this.onPlaceholderSelected}
                  onFocus={() => {
                    this.setState({ focus: "reminderEmail" });
                  }}
                  onChange={(chip) => {
                    let arr = [];
                    arr = chip.map((c) => {
                      if (c.text) {
                        return c.text;
                      } else {
                        return c;
                      }
                    });

                    this.handleChangeField("reminderEmail", arr);
                  }}
                  onDelete={(index) => {
                    this.handleDeleteField("reminderEmail", index);
                  }}
                  fullWidth={true}
                  placeholder={"Insert emails "}
                  placeholders={availablePlaceholders}
                />
                <p className={"bold"}>Cc</p>
                <ChipInput
                  inputValue={reminderCcEmails}
                  onPlaceholderSelected={this.onPlaceholderSelected}
                  onFocus={() => {
                    this.setState({ focus: "reminderCcEmails" });
                  }}
                  onChange={(chip) => {
                    let arr = [];
                    arr = chip.map((c) => {
                      if (c.text) {
                        return c.text;
                      } else {
                        return c;
                      }
                    });

                    this.handleChangeField("reminderCcEmails", arr);
                  }}
                  onDelete={(index) => {
                    this.handleDeleteField("reminderCcEmails", index);
                  }}
                  fullWidth={true}
                  placeholder={"Insert emails "}
                  placeholders={availablePlaceholders}
                />
                <p className={"bold"}>Reply To</p>

                <ChipInput
                  inputValue={reminderReplyToEmails}
                  onPlaceholderSelected={this.onPlaceholderSelected}
                  onFocus={() => {
                    this.setState({ focus: "reminderReplyToEmails" });
                  }}
                  onChange={(chip) => {
                    let arr = [];
                    arr = chip.map((c) => {
                      if (c.text) {
                        return c.text;
                      } else {
                        return c;
                      }
                    });

                    this.handleChangeField("reminderReplyToEmails", arr);
                  }}
                  onDelete={(index) => {
                    this.handleDeleteField("reminderReplyToEmails", index);
                  }}
                  fullWidth={true}
                  placeholder={"Insert emails "}
                  placeholders={availablePlaceholders}
                />
                <p className={"bold"}>Reminder subject</p>
                <MentionEditor
                  availablePlaceholders={availablePlaceholders}
                  placeholders={reminderEmailSubjectPlaceholders}
                  onFocus={() => {
                    this.setState({ focus: "reminderEmailSubject" });
                  }}
                  className={"reminderSubject"}
                  size={"small"}
                  fullWidth={true}
                  text={reminderEmailSubject}
                  onChange={(value) =>
                    this.handleChangeReminderEmailSubject(value)
                  }
                  variant={"outlined"}
                  placeholder={"Enter subject line here"}
                />
                <TextEditorExtraPlaceholder
                  availablePlaceholders={availablePlaceholders}
                  placeholder={reminderPlaceholders}
                  onChange={this.handleChangeReminderEmailMessage}
                  contentStyles={{
                    border: "1px solid rgba(0, 0, 0, 0.16)",
                    boxShadow: "none",
                    padding: "8px",
                    minWidth: "100%",
                  }}
                  html={reminderEmailMessage}
                  editorFocus={() => this.setState({ focus: "reminderEditor" })}
                  extraPlaceholders={[
                    {
                      key: "taskURL",
                      type: "image",
                      length: 1,
                    },
                  ]}
                />
                <p className="s-text">
                  Type !{`{taskURL}`} to insert the link to complete this task
                </p>
              </>
            )}
          </Paper>
        </Grid>
      );
    else return <IndividualComponentLoader />;
  }
}

export default withStyles(styles)(
  inject("ProjectStore")(
    observer(
      decorate(TaskInfo, {
        onPlaceholderSelected: action,
      })
    )
  )
);
