import React, { useEffect, useState } from "react";

// MUI
import { Skeleton, styled, Grid } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MoveDownIcon from "@mui/icons-material/MoveDown";

// Util
import { send_request } from "../../../../utils/Request";
import { Link } from "react-router-dom";

const BlueSpan = styled("div")({
    color: "rgba(33, 150, 243, 1)",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
        cursor: "pointer",
    },
    width: "fit-content",
});

const StyledOpenInNewIcon = styled(OpenInNewIcon)({
    width: "auto",
    height: "15px",
    marginRight: "4px",
});

const StyledSkeleton = styled(Skeleton)(({ width, height, margin }) => ({
    borderRadius: "4px",
    background: "#E8E8E8",
    width: "100%",
    height: height,
    margin: margin,
}));

const MigrationBox = styled(Grid)({
    border: "1px solid rgba(42, 76, 194, 1)",
    background: "rgba(223, 228, 246, 1)",
    padding: "10px",
    borderRadius: "6px",
    fontSize: "14px",
});

const BoxTitle = styled("span")({
    fontWeight: "Bold",
});

const CustomGrid = styled(Grid)(({ vertical, horizontal }) => ({
    display: "flex",
    alignItems: vertical,
    justifyContent: horizontal,
}));

function ExistingSessionInfo({ selected, projectId, version }) {
    const [isTestSession, setIsTestSession] = useState(null);
    const [existingSessionVersion, setExistingSessionVersion] = useState(null);

    useEffect(async () => {
        setIsTestSession(null);
        setExistingSessionVersion(null);

        // Get is test session
        let url = `project-service/project-session/is_test_session/${projectId}/${selected}`;
        const isTestJson = await send_request(url, null, null, "GET");

        // Get existing session version
        url = `project-service/project-session/get_session_version/${projectId}/${selected}`;
        const sessionVersionJson = await send_request(url, null, null, "GET");

        if (!isTestJson) setIsTestSession(false);
        else setIsTestSession(isTestJson.data);

        if (!sessionVersionJson || !sessionVersionJson.data) setExistingSessionVersion(version);
        else setExistingSessionVersion(sessionVersionJson.data);
    }, [selected]);

    const getVersionHistoryLink = (givenVersion) => {
        let url = `/project/view_canvas/${projectId}&version=${givenVersion}`;
        return url;
    };

    const getVersionMigrationInfo = () => {
        // If the version is the same, return
        if (version === existingSessionVersion) return;

        // Else, we need to return the info box
        return (
            <MigrationBox container margin="16px 0px 8px 0px">
                <CustomGrid item xs={12}>
                    <CustomGrid container spacing={2}>
                        <CustomGrid item vertical="center" horizontal="center">
                            <MoveDownIcon />
                        </CustomGrid>
                        <CustomGrid item xs>
                            <CustomGrid container>
                                <CustomGrid item xs={12}>
                                    <BoxTitle>Version migration</BoxTitle>
                                </CustomGrid>
                                <CustomGrid item xs={12}>
                                    <span>
                                        This session was originally run on{" "}
                                        <Link to={getVersionHistoryLink(existingSessionVersion)} target="_blank" style={{ color: "rgba(33, 150, 243, 1)" }}>
                                            version {existingSessionVersion}
                                        </Link>
                                        . Its session data will be migrated into a new session on{" "}
                                        <Link to={getVersionHistoryLink(version)} target="_blank" style={{ color: "rgba(33, 150, 243, 1)" }}>
                                            version {version}
                                        </Link>
                                    </span>
                                </CustomGrid>
                            </CustomGrid>
                        </CustomGrid>
                    </CustomGrid>
                </CustomGrid>
            </MigrationBox>
        );
    };

    return isTestSession !== null && existingSessionVersion !== null ? (
        <Grid container>
            <Grid item xs={12}>
                <BlueSpan onClick={() => window.open(`/project/logs/progress_view/${projectId}/${selected}?test=false`, "_blank")}>
                    <StyledOpenInNewIcon />
                    {`Open ${isTestSession ? "test" : ""} session ${selected} in a new tab`}
                </BlueSpan>
            </Grid>
            <Grid item xs={12}>
                {getVersionMigrationInfo()}
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StyledSkeleton variant="rectangular" width="450px" />
            </Grid>
            <Grid item xs={12}>
                <StyledSkeleton variant="rectangular" width="100%" height="80px" />
            </Grid>
        </Grid>
    );
}

export default ExistingSessionInfo;
