import React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { withStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import "../pages/stylesheet/projectReview.css";
import Share from "./Share";
import Config from "../../config";
import projectStore from "../ProjectCanvas/ProjectStore";
import Autocomplete from "@mui/lab/Autocomplete";
import {
  getAutoCompleteOptions,
  getMarketplaceDialogOptions
} from "../../utils/MarketplaceDialog";
import { CustomDialog } from "../Component/Dialog";

const styles = {
  dialogPaper: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "8px",
    paddingBottom: "16px"
  },
  flexGrid: {
    display: "inline-flex",
    alignItems: "center"
  },
  buttonProgress: {
    color: "#2196F3",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  dialogContent: {
    padding: " 0!important",
    overflowX: "hidden"
  },
  dialogTitle: {
    margin: "0px!important",
    paddingLeft: "0px!important"
  },
  dialogContentHeader: {
    fontWeight: "bold !important"
  }
};
class MarketDeploymentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: this.getSteps(),
      activeStep: 0,
      auth: 2,
      project: this.props.project(),
      project_name: "",
      failed: false,
      success: false,
      loadingDeployment: false,
      isHidden: true,
      selectedCategories: []
    };
  }
  componentDidMount() {
    this.setProjectName();
  }
  setProjectName = () => {
    this.setState({ project_name: this.props.project().name });
  };
  getSteps() {
    return ["Configure Workflow Access", "Final Confirmation"];
  }

  setActiveStep = (prevActiveStep) => {
    return prevActiveStep + 1;
  };

  nextStep = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleStepClick = (index) => {
    this.setState({ activeStep: index });
  };
  handleOpenToAnyone = () => {
    this.setState({ auth: 0 });
    this.setState({ isHidden: null });
  };
  handleOpenToMyOrg = () => {
    this.setState({ auth: 1 });
    this.setState({ isHidden: null });
  };
  handleOpenToLink = () => {
    this.setState({ auth: 2 });
    this.setState({ isHidden: true });
  };
  handleDone = () => {
    this.setState({
      activeStep: 0,
      success: false,
      failed: false,
      auth: 0,
      project_name: "",
      deploymentFailed: "",
      isHidden: false
    });
    this.props.close();
  };
  handleCancel = () => {
    this.props.close();
  };
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  handleConfirm = () => {
    this.setProjectName();
    this.setState({
      loadingDeployment: true
    });

    this.props.deploy(this.state.auth, this.state.isHidden, () => {
      var status = this.props.status;
      if (status == true) {
        this.setState({
          success: true,
          loadingDeployment: false
        });
        this.props.close();
        return;
      } else if (status == false) {
        this.setState({
          failed: true,
          loadingDeployment: false
        });
        this.props.close();
        return;
      } else {
        this.setState({
          failed: false,
          loadingDeployment: false
        });
        return;
      }
    });
  };

  changeDescription = (event) => {
    this.props.project().description = event.target.value;
  };

  changeCategories = (newCategories) => {
    this.props.project().categories = newCategories;
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog open={this.props.open} fullWidth={true} maxWidth={"md"}>
          <Stepper activeStep={this.state.activeStep}>
            {this.state.steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel
                    onClick={() => {
                      this.handleStepClick(index);
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {this.state.activeStep == 0 ? (
            <Grid>
              <Grid
                container
                direction="column"
                className={classes.dialogPaper}
              >
                <DialogTitle className={classes.dialogTitle}>
                  <h5>Share this workflow as a template</h5>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <p className="s-text">
                    If a template has already been created, it will update the
                    existing template to the current version of the workflow.
                  </p>
                  <p className={classes.dialogContentHeader}>
                    Who can download this workflow
                  </p>
                  <Select variant={"outlined"} value={this.state.auth}>
                    <MenuItem value={0} onClick={this.handleOpenToAnyone}>
                      🌏 Open to anyone
                    </MenuItem>
                    <MenuItem value={1} onClick={this.handleOpenToMyOrg}>
                      🔒 Open to my organisation only
                    </MenuItem>
                    <MenuItem value={2} onClick={this.handleOpenToLink}>
                      🔗 Anyone with the link
                    </MenuItem>
                  </Select>
                  <p className="s-text">
                    The template can be shared with any users outside of your
                    organisation via a shareable link. Anyone with that link can
                    view and download the template.
                  </p>
                  <p className={classes.dialogContentHeader}>
                    Select categories
                  </p>
                  <Autocomplete
                    options={projectStore.state.workflowCategories}
                    getOptionLabel={(option) => option.displayName}
                    multiple
                    defaultValue={getAutoCompleteOptions(
                      this.props.project().categories
                    )}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                    onChange={(event, newValue) => {
                      this.changeCategories(newValue.map((c) => c.categoryId));
                    }}
                  />
                  <p className={classes.dialogContentHeader}>
                    Description (optional)
                  </p>
                  <TextField
                    className="description_textfield"
                    multiline={true}
                    fullWidth={true}
                    rows={5}
                    variant="outlined"
                    defaultValue={this.props.project().description}
                    placeholder={"Enter a description for this workflow"}
                    onChange={this.changeDescription}
                  />
                </DialogContent>
              </Grid>
              <DialogActions>
                <Button
                  variant={"outlined"}
                  color={"info"}
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>

                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={this.nextStep}
                >
                  Next
                </Button>
              </DialogActions>
            </Grid>
          ) : (
            ""
          )}
          {this.state.activeStep == 1 ? (
            <Grid>
              <Grid
                container
                direction="column"
                className={classes.dialogPaper}
              >
                <DialogTitle className={classes.dialogTitle}>
                  <h5>Final Confirmation</h5>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <img
                    className="dialogImage"
                    src="/images/undraw_logistics_x4dc.jpg"
                  />
                  <p className="s-text">
                    A workflow template can be rescinded from the Marketplace at
                    any time. However, if someone has alreayd downlaoded the
                    workflow template before it is rescinded, you will not be
                    able to remove that downloaded template.
                  </p>
                  <p className="s-text">
                    Any uploaded files included spreadsheets, document
                    templates, and image files in the original workflow will be
                    packaged with the workflow template. This means anyone who
                    downloads the project template will also download a copy of
                    all files.
                  </p>
                  <p className="s-text">
                    By proceeding, you are confirming that you understand the
                    above.
                  </p>
                </DialogContent>
              </Grid>
              <DialogActions>
                <Button
                  variant={"outlined"}
                  color={"info"}
                  onClick={this.handleBack}
                >
                  Back
                </Button>

                <Button
                  variant={"contained"}
                  color={"primary"}
                  disabled={this.state.loadingDeployment}
                  onClick={this.handleConfirm}
                >
                  Confirm
                  {this.state.loadingDeployment && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Grid>
          ) : (
            ""
          )}
        </Dialog>
        {this.state.success ? (
          <CustomDialog
            isOpen={this.state.success}
            fullWidth={true}
            size={"sm"}
            title={
              <>
                <CheckCircleIcon className="check-icon icon-success" />
                <h5>Project deployed to the marketplace</h5>
              </>
            }
            contents={
              <>
                <img
                  className="dialogImage"
                  src="/images/undraw_confirmation_2uy0.jpg"
                ></img>
                <p className="s-text">
                  {this.state.project_name} has been deployed as a template and
                  can now be accessed via the Workflow86 Marketplace.
                </p>
                <Share
                  open={this.state.openShare}
                  url={`${Config.APP_URL}/template/${this.props.templateId}`}
                />
              </>
            }
            buttons={
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={this.handleDone}
              >
                Done
              </Button>
            }
          />
        ) : (
          <Dialog open={this.state.failed} fullWidth={true} maxWidth={"sm"}>
            <Grid>
              <Grid
                container
                direction="column"
                className={classes.dialogPaper}
              >
                <DialogTitle className={classes.dialogTitle}>
                  <h5>Workflow failed to deploy to the marketplace</h5>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <img className="dialogImage" src="/images/delete.jpg"></img>
                  <p className="s-text">
                    {this.state.project_name} failed to deploy to the
                    marketplace. Please try again or contact us for further
                    assistance.
                  </p>
                </DialogContent>
              </Grid>
              <DialogActions>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={this.handleDone}
                >
                  Done
                </Button>
              </DialogActions>
            </Grid>
          </Dialog>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(MarketDeploymentDialog);
