// This method is used for the upload spreadsheet
export default function checkKeyUtil(value, index, data, field) {
  let checkKey = false;
  let errorMessage = "";
  if (value.length == 0) {
    checkKey = true;
    errorMessage = "This field cannot be empty";
  } else if (value.length > 0) {

    if(/^[a-zA-Z0-9_\s]*$/.test(value) == false) {
      return [true, "No special character allowed"];
    }

    for (let i in data) {
      if (value == data[i][field] && i != index) {
        checkKey = true;
        errorMessage = "This value is already taken";
        break;
      } else {
        checkKey = false;
      }
    }
  } else {
    checkKey = false;
  }

  return [checkKey, errorMessage];
};
