import React from "react";
import "../Marketplace/MarketplacePage.css";
import {
  Card,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  CardContent
} from "@mui/material";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format } from "../../utils/Date";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import RibbonItem from "vardogyir-ui/RibbonItem";
import { Emoji } from "emoji-mart";
import { withParams } from "../../Routes";
import { styled } from '@mui/material/styles';

const styles = {
  status: {
    height: "11px",
    width: "1px",
    marginTop: "11px",
    marginRight: "9px"
  },
  moreIcon: {
    position: "absolute",
    top: 8,
    right: 8,
    color: "black"
  },
  publishedSection: {
    alignItems: "center"
  }
};

const PublishedStatusCircle = ({ published }) => (
  <div className={"publishedStatusCircle " + published}></div>
);

const EmojiContainer = styled(Grid)({
  minHeight: '32px',
  maxHeight: '5em',
  padding: '24px 24px 32px 24px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center'
});

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      anchorEl: null
    };
  }

  handleClick = () => {
    this.props.handleClick();
  };
  handleCardClick = () => {
    if (this.props.openInNewTab) return;
    this.props.navigate(
      `/project/canvas/${this.props.card.project_id}`
    );
  };

  openMenu = (event) => {
    this.setState({
      menu: true,
      anchorEl: event.currentTarget
    });
  };
  handleClose = () => {
    this.setState({ menu: false });
  };

  handleChangeImage = () => {
    this.handleClose();
    this.props.launchImageDialog();
  };

  handleUnpublishProject = () => {
    this.handleClose();
    this.props.unpublishProject();
  };
  handleDeployToMarketplace = () => {
    this.handleClose();
    this.props.deployToMarketplace();
  };
  handleDeleteWorkflow = () => {
    this.handleClose();
    this.props.deleteWorkflow();
  };
  handleMoveWorkflowToFolder = () => {
    this.handleClose();
    this.props.moveWorkflowToFolder();
  };

  renderEmoji = (imagePath) => {
    // Checks if image path is not an emoji
    if (!imagePath || imagePath.charAt(0) !== ":") {
      return ":twisted_rightwards_arrows:";
    } else {
      return imagePath;
    }
  };

  render() {
    const { classes, card, index, disableEditing, openInNewTab } = this.props;
    return (
      <Card
        className={`${disableEditing ? "homepage-workflow-card" : "cardFrameStyle"} cursor-pointer`}
        style={{ position: "relative", height: "auto", paddingBottom: "24px" }}
      >
        {!disableEditing && (
          <Grid
            container
            item
            xs={3}
            direction={"column"}
            alignItems={"flex-end"}
            className={classes.moreIcon}
          >
            <IconButton
              onClick={this.openMenu}
              className={"icon-btn"}
              size="large"
            >
              <MoreVertIcon className={"material-icons more-vert"} />
            </IconButton>
            {this.state.menu && (
              <Menu
                open={this.state.menu}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
              >
                <RibbonItem onClick={this.handleChangeImage}>
                  Change icon
                </RibbonItem>
                <RibbonItem onClick={this.handleDeleteWorkflow}>
                  Delete workflow
                </RibbonItem>
                {card.hasPublicVersion && (
                  <RibbonItem onClick={this.handleUnpublishProject}>
                    {" "}
                    Unpublish workflow
                  </RibbonItem>
                )}
                <RibbonItem onClick={this.handleMoveWorkflowToFolder}>
                  Move to folder
                </RibbonItem>
              </Menu>
            )}
          </Grid>
        )}
        <Link
          to={`/project/canvas/${card.project_id}`}
          target={openInNewTab && "_blank"}
          style={{ textDecoration: "none" }}
        >
          <CardContent
            className="contentBackground"
          >
            <EmojiContainer
              container
              item
              xs={12}
              onClick={this.handleCardClick}
              className="noFeatured"
            >
              <Emoji
                set="twitter"
                emoji={this.renderEmoji(card.imagePath)}
                size={"2.5em"}
                style={{ flexShrink: 0 }}
              />
            </EmojiContainer>

            <Grid
              container
              item
              className="textArea"
              direction={"row"}
              justifyContent={"flex-start"}
              xs={12}
            >
              <Grid container item xs={12} direction={"column"}>
                <Tooltip title={card.project_name} placement="bottom-start">
                  <Typography variant="div" className="textHeader">
                    {card.project_name ? card.project_name : "Untitled" }
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={format(
                    card.last_modified,
                    ProjectStore.state.timezone
                  )}
                >
                  <Typography component="p" className="textAuthor">
                    Last Updated{" "}
                    {format(
                      card.last_modified,
                      ProjectStore.state.timezone,
                      "relative"
                    )}
                  </Typography>
                </Tooltip>
                <Grid
                  className={classes.publishedSection}
                  direction={"row"}
                  container
                  item
                >
                  <PublishedStatusCircle
                    published={
                      card.status == "ERROR"
                        ? "error"
                        : card.hasPublicVersion
                        ? "published"
                        : "unpublished"
                    }
                  />
                  <Typography
                    component="p"
                    className={
                      card.status == "ERROR"
                        ? "cardErrorText"
                        : card.hasPublicVersion
                        ? "cardPublishedText"
                        : "cardDraftText"
                    }
                  >
                    {card.status == "ERROR"
                      ? "Error"
                      : card.hasPublicVersion
                      ? "Published"
                      : "Unpublished"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Link>
      </Card>
    );
  }
}
export default withParams(withStyles(styles)(ProjectCard));