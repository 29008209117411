import { toJS } from "mobx";

export default function checkKey(key, index, data) {
  let checkKey = false;
  let errorMessage = "";

  if (key.length == 0) {
    checkKey = true;
    errorMessage = "This field cannot be empty";
  } else if (key.length > 0) {
    if (/^[a-zA-Z0-9_]+$/.test(key) == false) {
      return [true, "No special characters or spaces allowed"];
    }

    // for (let i in data) {
    //   if (key == data[i].key && i != index) {
    //     checkKey = true;
    //     errorMessage = "This value is already taken";
    //     break;
    //   } else {
    //     checkKey = false;
    //   }

    //   if (data[i].exportPlaceholders) {
    //     //this is for the duplicate is between listPlaceholder and an item inside exportedPlaceholders
    //     for (let pl of data[i].exportPlaceholders) {
    //       if (pl.key == data[i].key) {
    //         checkKey = true;
    //         errorMessage = "This value is already taken";
    //         return [checkKey, errorMessage];
    //       }
    //     }
    //   }
    // }

    // For split list placeholders from list transform
    for (let i in data) {
      if (data[i].func && data[i].func == "split_list_into_placeholders") {
        var valueArr = data[i].exportPlaceholders.map(function(item) {
          return item.key;
        });
        if (getOccurrence(valueArr, key) > 1) {
          //this is for duplicate placeholders within exportedPlaceholders
          checkKey = true;
          errorMessage = "This value is already taken";
          return [checkKey, errorMessage];
        }
      }
    }
  }
  return [checkKey, errorMessage];
}

function getOccurrence(array, value) {
  var count = 0;
  array.forEach((v) => v === value && count++);
  return count;
}
