export function parseType(type) {
  switch (type) {
    case "api_export":
      return "API Export";
    case "api_request_and_response":
      return "API Request and Response";
    case "calculator":
      return "Calculator";
    case "conditional_logic":
      return "Conditional Logic";
    case "conditional_workflow":
      return "Conditional Path";
    case "create_database_record":
      return "Create Database Record";
    case "knowledge_bank":
      return "Content Library";
    case "document_editor":
      return "Document Editor";
    case "email":
      return "Email";
    case "form":
      return "Form";
    case "list_transform":
      return "List Transform";
      case "loop_through_list":
        return "Loop Through List";
    case "natural_language":
      return "Natural Language Processing";
    case "data_bank":
      return "Query Spreadsheet";
    case "query_database_record":
      return "Query Database";
    case "workflow_data":
      return "Query Workflow Data";
    case "schedule_start":
      return "Schedule Start";
    case "slack":
      return "Send Slack Message";
    case "sequential_form":
      return "Assign Task";
    case "form_section":
      return "Form Section";
    case "trigger_from_a_workflow":
      return "Trigger from a Workflow";
      case "trigger_another_workflow":
        return "Trigger another Workflow";
    case "time_delay":
      return "Time Delay";
    case "update_database_record":
      return "Update Database Record";
    case "bulk_assessment":
      return "Upload Spreadsheet";
    case "webhook":
      return "Webhook Import";
    case "trigger_via_email":
      return "Trigger via Email";
    default:
      return "";
  }
}
