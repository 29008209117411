import React from "react";
import {
  Grid,
  Slide,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@mui/material";
import { runLoading } from "../../utils/showLoadingScreen";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomDialog = ({
  isOpen,
  handleClose,
  title,
  contents,
  buttons,
  size,
  backdropStyle,
  zIndex,
  loading,
  loadText,
  publish,
  removeTitle,
  removeButtons
}) => {
  if (!loading) {
    return (
      <Grid data-tut="reactour__readme__dialog" style={{ display: "none" }}>
        <Dialog
          fullWidth={true}
          maxWidth={size ? size : "sm"}
          className={publish ? "dialogWithBackdrop" : ""}
          BackdropProps={{
            classes: {
              root: backdropStyle ? backdropStyle : ""
            }
          }}
          style={zIndex ? { zIndex: zIndex } : { zIndex: 1300 }}
          open={isOpen ? isOpen : false}
          TransitionComponent={Transition}
          onClose={handleClose}
          disableEnforceFocus={true}
        >
          {!removeTitle && 
            <DialogTitle>
              <h5>{title}</h5>
            </DialogTitle>
          }
          <DialogContent>{contents}</DialogContent>
          {!removeButtons && 
            <DialogActions>{buttons}</DialogActions>
          }
        </Dialog>
      </Grid>
    );
  } else {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={size ? size : "sm"}
        style={zIndex ? { zIndex: 100000 } : { zIndex: 1000 }}
        BackdropProps={{
          classes: {
            root: backdropStyle ? backdropStyle : ""
          }
        }}
        open={isOpen ? isOpen : false}
        TransitionComponent={Transition}
        onClose={handleClose}
        disableEnforceFocus={true}
      >
        <DialogContent>
          <Grid
            xs={12}
            direction={"column"}
            alignItems={"center"}
            container
            item
          >
            <Grid item>{runLoading()}</Grid>
            <p style={{ textAlign: "center", whiteSpace: "pre-line" }}>
              {loadText}
            </p>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
};
