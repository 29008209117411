import React, { useEffect, useState } from "react";

// MUI
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, Skeleton, Slide, styled, TextField } from "@mui/material";
import { send_request } from "../../../utils/Request";
import { ErrorRounded } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TerminateButton = styled(Button)({
    backgroundColor: "rgba(176, 0, 32, 1)",
});

const TopText = styled("span")({
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
});

const StyledAlert = styled(Alert)({
    border: "1px solid rgba(225, 0, 0, 1)",
    background: "rgba(225, 0, 0, 0.15)",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "14px",
    lineHeight: "24px",
});

const Error = styled(ErrorRounded)({
    color: "rgba(225, 0, 0, 1)",
    height: "18px",
    width: "auto",
    marginTop: "4px",
});

const StyledSkeleton = styled(Skeleton)(({ width, height, radius }) => ({
    borderRadius: radius ? radius : "4px",
    background: "#E8E8E8",
    width: width,
    height: height,
    margin: "0px 2px 0px 0px",
    display: "inline-block",
}));

const ConfirmInput = styled(TextField)({});

function ConfirmTerminateSessionDialog({ isOpen, close, sessionId, terminateSession }) {
    const [confirmText, setConfirmText] = useState("");
    const [user, setUser] = useState(null);
    const [isTerminating, setIsTerminating] = useState(false);

    useEffect(async () => {
        const json = await send_request(`authz-service/user-info`, "", {});
        if (json && json.data) setUser(json.data.displayName);

        return () => {
            setConfirmText("");
        };
    }, []);

    return (
        <Dialog fullWidth maxWidth="sm" open={isOpen} TransitionComponent={Transition} disableEnforceFocus={true}>
            <DialogTitle>Confirm terminate entire session</DialogTitle>
            <DialogContent>
                <Grid container marginTop="4px" rowGap={2}>
                    <Grid item xs={12}>
                        <TopText>
                            <b>{user ? user : <StyledSkeleton variant="text" width="125px" height="100%" />}</b> is terminating this session
                        </TopText>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledAlert severity="error" icon={<Error />}>
                            You are about to terminate this entire session. This will terminate and stop all components within it from running further. Are you sure you want to terminate this action?
                            Type “terminate” below to confirm.
                        </StyledAlert>
                    </Grid>
                    <Grid item xs={12}>
                        <ConfirmInput fullWidth size="small" placeholder="Type “terminate” here to confirm" onChange={(e) => setConfirmText(e.target.value)} disabled={isTerminating} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={close} disabled={isTerminating}>
                    Cancel
                </Button>
                <TerminateButton
                    color="error"
                    variant="contained"
                    onClick={async () => {
                        setIsTerminating(true);
                        await terminateSession(sessionId);
                        setIsTerminating(false);
                        close();
                    }}
                    disabled={isTerminating || confirmText !== "terminate"}
                >
                    {isTerminating && <CircularProgress size={24} className="buttonProgress" />}
                    Terminate
                </TerminateButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmTerminateSessionDialog;
