import React, {Component} from 'react';

class CoverImage extends Component {

  componentDidMount() {
    const canvas = this.refs.coverImage
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.src = this.props.coverImage
    image.className = 'ReactCrop__image'
    image.alt = ''
    const img = image
    img.onload = () => {
      ctx.drawImage(img, -this.props.coverImageCrop.naturalX, -this.props.coverImageCrop.naturalY)
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const canvas = this.refs.coverImage
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.src = this.props.coverImage
    image.className = 'ReactCrop__image'
    image.alt = ''
    const img = image
    img.onload = () => {
      ctx.drawImage(img, -this.props.coverImageCrop.naturalX, -this.props.coverImageCrop.naturalY)
    }
  }

  render() {
    const {coverImageCrop} = this.props
    return (
        <canvas ref="coverImage" width={coverImageCrop.naturalWidth} height={coverImageCrop.naturalHeight} style={{width: "100%",height: "auto",objectFit: "cover"}} />
    );
  }
}

export default CoverImage;