import React from "react";

// MUI
import { styled } from "@mui/system";
import { Button, Grid, Switch, Tooltip } from "@mui/material";
import {
  MoreHoriz,
  Link,
  Preview,
  TableRows,
  Code,
  FormatPaint,
  Settings,
} from "@mui/icons-material";

const TextButton = styled(Button)({
  textTransform: "unset !important",
  color: "black",
});

const Text = styled("span")({
  textTransform: "unset !important",
  color: "black",
  fontSize: "0.8125rem",
});

function FormAdditionalButtons(props) {
  const { templateLoading } = props;
  return (
    <>
      <Grid item>
        <TextButton
          size={"small"}
          startIcon={<MoreHoriz />}
          onClick={props.handleSettings}
          disabled={templateLoading}
        >
          Form settings
        </TextButton>
      </Grid>
      <Grid item>
        <TextButton
          size={"small"}
          startIcon={<Preview />}
          onClick={props.handlePreview}
          disabled={templateLoading}
        >
          Preview
        </TextButton>
      </Grid>
      {props.isForm && (
        <Grid item>
          <TextButton
            size={"small"}
            startIcon={<Link />}
            onClick={props.handleLink}
            disabled={templateLoading}
          >
            Link
          </TextButton>
        </Grid>
      )}
      <Grid item>
        <TextButton
          size={"small"}
          startIcon={<TableRows />}
          onClick={props.handleData}
          disabled={templateLoading}
        >
          Submission data
        </TextButton>
      </Grid>
      {props.isForm && (
        <Grid item>
          <TextButton
            size={"small"}
            startIcon={<Code />}
            onClick={props.handleEmbed}
            disabled={templateLoading}
          >
            Embed
          </TextButton>
        </Grid>
      )}
      <Grid item>
        <TextButton
          size={"small"}
          startIcon={<Settings />}
          onClick={props.handleFormSettings}
          disabled={templateLoading}
        >
          Component settings
        </TextButton>
      </Grid>
      <Grid item>
        <TextButton
          size={"small"}
          startIcon={<FormatPaint />}
          onClick={props.handleStyling}
          disabled={templateLoading}
        >
          Styling
        </TextButton>
      </Grid>
      {!props.isForm && (
        <Grid item display={"flex"} alignItems={"center"}>
          <Tooltip
            title="Turn this on to enable placeholders to be used in the form"
            placement="bottom-start"
          >
            <Switch
              checked={props.isDynamicForm}
              color="primary"
              onChange={props.handleEnablePlaceholders}
              size="small"
              disabled={templateLoading}
            />
          </Tooltip>
          <Text>Allow placeholders</Text>
        </Grid>
      )}
    </>
  );
}

export default FormAdditionalButtons;
