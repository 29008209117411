import { computed, observable, decorate, action, toJS } from "mobx";

class ConditionalLogicStore {

  constructor() {
    this.initialiseEmpty();
  }

  setConditionalLogicTemplate = (conditionalLogicTemplate) => {
    this.name = conditionalLogicTemplate.name;
    this.data = conditionalLogicTemplate.data;
    this.lastModified = conditionalLogicTemplate.lastModified;
  }

  setTemplateData = (data) => {
    this.data = data;
  }

  setConditionalLogicName = (ConditionalLogicName) => {
    this.name = ConditionalLogicName;
  }

  setLastModified = (lastModified) => {
    this.lastModified = lastModified;
  }

  setCondition = (content, indexLogic, indexStatement, indexCondition, type) => {
    try {
      switch (type) {
        case 'logic':
          this.data[indexLogic].statement.content
            .condition[indexStatement]
            .operators[indexCondition]
            .logic = content;
          return;
        case 'key':
          this.data[indexLogic].statement.content
            .condition[indexStatement]
            .operators[indexCondition]
            .key = content ? content.trim() : "";
          return;
        case 'function':
          this.data[indexLogic].statement.content
            .condition[indexStatement]
            .operators[indexCondition]
            .function = content;
          return;
        case 'value':
          this.data[indexLogic].statement.content
            .condition[indexStatement]
            .operators[indexCondition]
            .value = content;
          return;
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  setThenContent = (content, indexLogic, indexStatement) => {
    try {
      this.data[indexLogic].statement.content
        .condition[indexStatement]
        .then.statement.content = content;
    } catch (error) {
        console.error(error);
    }
  }
  addStatement = (indexLogic) => {
    const obj = {
      operators: [
        {
          logic: 'OR',
          key: '',
          function: '',
          value: ''
        }
      ],
      then: {
        statement: {
          type: "VALUE",
          content: ''
        }
      }
    }
    const { condition } = this.data[indexLogic].statement.content
    this.data[indexLogic].statement.content
      .condition
      .splice(condition.length - 1, 0, obj)
  }

  addCondition = (indexLogic, indexStatement) => {
    const obj = {
      conditionRandomKey : Math.random(),
      logic: 'OR',
      key: '',
      function: 'EQUAL',
      value: ''
    }

    let data = JSON.parse(JSON.stringify(toJS(this.data[indexLogic])));
    data.statement.content.condition[indexStatement].operators.push(obj);

    this.data[indexLogic] = data;
  }

  setLogic = (content, index, type) => {
    switch (type) {
      case 'key':
        this.data[index].key = content;
        return;
      case 'outputType':
        this.data[index].outputType = content;
        return;
    }
  }

  addLogic = () => {
    let obj = { ...this.logicData() };
    this.data.push(obj);
    this.newData.push(obj.keyRandom)
  }

  clearData = () => {
    this.data = []
    this.newData = []
  }

  removeLogic = (key) => {
    let index = this.data.findIndex((x) => x.key === key);
    this.data.splice(index, 1);
  }

  newRemoveLogic = (key) => {
    let index = this.data.findIndex((x) => x.keyRandom === key);
    this.data.splice(index, 1);
  }
  copyThisLogic = (logic) => {
    let obj = { ...JSON.parse(JSON.stringify(logic)), key: this.keyAuto(), keyRandom: Math.random()}
    this.data.push(obj);
  }
  copyThisCondition = (indexLogic, indexStatement, indexCondition) => {
    let operator = toJS(this.data[indexLogic].statement.content.condition[indexStatement].operators[indexCondition]);
    let copyOperator = JSON.parse(JSON.stringify(operator))
    this.data[indexLogic].statement.content.condition[indexStatement].operators.push(copyOperator);
  }

  copyThisStatement = (indexLogic, indexStatement) => {
    let statement = toJS(this.data[indexLogic].statement.content.condition[indexStatement]);
    let copyStatement = JSON.parse(JSON.stringify(statement))
    const { condition } = this.data[indexLogic].statement.content
    this.data[indexLogic].statement.content.condition.splice(condition.length - 1, 0, copyStatement)
  }
  removeCondition = (indexLogic, indexStatement, indexCondition) => {
    this.data[indexLogic].statement.content
      .condition[indexStatement]
      .operators
      .splice(indexCondition, 1)
  }

  handleRemoveCondition = (indexLogic, indexStatement, indexCondition) => {
    let index = this.data[indexLogic].statement.content
      .condition[indexStatement]
      .operators.findIndex((x) => x.conditionRandomKey === indexCondition);

    this.data[indexLogic].statement.content
      .condition[indexStatement]
      .operators.splice(index, 1);
  }

  removeStatement = (indexLogic, indexStatement) => {
    this.data[indexLogic].statement.content
      .condition
      .splice(indexStatement, 1)
  }
  keyAuto = () => {
    return "conditional" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  }

  logicData = () => {
    return {
      key: this.keyAuto(),
      keyRandom: Math.random(),
      outputType: "TEXT",
      statement: {
        type: "IF",
        content: {
          condition: [
            {
              operators: [
                {
                  logic: "undefined",
                  key: "",
                  function: "EQUAL",
                  value: ""
                }
              ],
              then: {
                statement: {
                  type: "VALUE",
                  content: ""
                }
              }
            },
            {
              then: {
                statement: {
                  type: "VALUE",
                  content: ""
                }
              }
            }
          ]
        }
      }
    }
  }

  setPlaceholders = (placeholders) => {
    this.placeholders = placeholders;
  }

  setPositions = (positions) => {
    this.placeholders = [];
    this.positions = positions;
  }


  initialiseEmpty = () => {
    const obj = this.logicData()
    this.name = "";
    this.type = "conditional_logic";
    this.lastModified = "";
    this.data = [obj];
    this.placeholders = [];
    this.placeholdersError = [];
    this.newData = [];
    this.positions = {
      indexLogic: null,
      indexStatement: null,
      indexCondition: null,
      type: null
    };
  }

  get template() {
    return {
      name: this.name,
      data: toJS(this.data),
      newData: toJS(this.newData),
      type: this.type,
      lastModified: this.lastModified,
      placeholders: toJS(this.placeholders),
      positions: toJS(this.positions)
    }
  }
}


decorate(ConditionalLogicStore, {
  name: observable,
  data: observable.shallow,
  type: observable,
  error: observable,
  placeholders: observable,
  positions: observable,
  setPositions: action,
  setPlaceholders: action,
  setConditionalLogicTemplate: action,
  setConditionalLogicName: action,
  setCondition: action,
  setLogic: action,
  setThenContent: action,
  copyThisLogic: action,
  addStatement: action,
  addCondition: action,
  addLogic: action,
  removeLogic: action,
  removeCondition: action,
  removeStatement: action,
  initialiseEmpty: action,
  template: computed
})

const conditionalLogicStore = new ConditionalLogicStore();
export default conditionalLogicStore;