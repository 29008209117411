import React, { useState, useEffect } from "react";

// MUI
import { Autocomplete, Grid, MenuItem, Select, styled, TextField } from "@mui/material";
import FormMentionEditor from "../../../Component/FormMentionEditor";
import { send_request } from "../../../../utils/Request";

import { BlueText, StyledOpenInNewIcon } from "../../../TriggerAnotherWorkflow/components/StyledComponents";
import ColumnAndRecordSelection from "./ColumnAndRecordSelection";
import { StyledSkeleton } from "../../../pages/Canvas/RenamePlaceholderLogic/RenameStyledComponents";

const SectionTitle = styled("span")(({ isBold }) => ({
    fontSize: "14px",
    lineHeight: "19px",
    color: "rgba(0, 0, 0, 1)",
    fontWeight: isBold ? "bold" : "normal",
}));

function DatabaseContent({ availablePlaceholders, isAssignTask, setDatabaseID, setColumnID, setRecordID, setDropdown, data }) {
    const [databases, setDatabases] = useState([]);
    const [displayValue, setDisplayValue] = useState(data.displayDB_dropdown);

    const [isFetchingDBs, setIsFetchingDBs] = useState(false);

    const [selectedDatabase, setSelectedDatabase] = useState(null);

    useEffect(async () => {
        await getDatabases();
    }, []);

    const getDatabases = async () => {
        setIsFetchingDBs(true);

        const json = await send_request(`database/get_databases`, "", "", "GET");
        if (json && json.data) {
            let dbs = json.data;

            // Check if databaseID is passed
            let databaseID = data.displayDB_databaseID;
            if (databaseID) {
                for (let database of dbs) {
                    if (database.databaseId !== databaseID) continue;
                    setSelectedDatabase(database);
                    break;
                }
            }

            setDatabases(dbs);
        }

        setIsFetchingDBs(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SectionTitle isBold>From this database</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                        {!isFetchingDBs ? (
                            <Autocomplete
                                size="small"
                                disableClearable
                                options={databases}
                                renderInput={(params) => <TextField size="small" {...params} placeholder="Select database" />}
                                getOptionLabel={(option) => (option.name ? option.name : option.databaseId)}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.databaseId}>
                                        {option.name ? option.name : option.databaseId}
                                    </li>
                                )}
                                value={selectedDatabase}
                                onChange={(e, value) => {
                                    if (!value) return;
                                    setSelectedDatabase(value);
                                    setDatabaseID(value.databaseId);
                                }}
                            />
                        ) : (
                            <StyledSkeleton variant="rectangular" height="35px" />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {selectedDatabase && (
                <>
                    <Grid item xs={12}>
                        {!isFetchingDBs ? (
                            <BlueText onClick={() => window.open(`/databases/database_management/${selectedDatabase.databaseId}`, "_blank")}>
                                <StyledOpenInNewIcon />
                                Open {selectedDatabase.name ? selectedDatabase.name : "Untitled"} in a new tab
                            </BlueText>
                        ) : (
                            <StyledSkeleton variant="rectangular" height="30px" width="350px" />
                        )}
                    </Grid>
                    {!isFetchingDBs ? (
                        <ColumnAndRecordSelection
                            displayValue={displayValue}
                            setDisplayValue={setDisplayValue}
                            selectedDatabase={selectedDatabase}
                            SectionTitle={SectionTitle}
                            isAssignTask={isAssignTask}
                            isFetchingDBs={isFetchingDBs}
                            dropdown={data.displayDB_dropdown}
                            setDropdown={setDropdown}
                            columnID={data.displayDB_columnID}
                            setColumnID={setColumnID}
                            recordID={data.displayDB_recordID}
                            setRecordID={setRecordID}
                            availablePlaceholders={availablePlaceholders}
                        />
                    ) : (
                        <Grid item xs={12}>
                            <StyledSkeleton variant="rectangular" height="120px" />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

export default DatabaseContent;
