import {action, computed, decorate, observable, toJS} from "mobx";
import APIResReqStore from "../APIRequestAndResponse/APIResReqStore";
import {send_request} from "../../utils/Request";

class HandleJsonFunction{
  handleAddPlaceholdersJson = async (json,componentStore) => {
    const jsonData = JSON.parse(json)
    componentStore.clearField();
    await send_request(`component-service/json/convert-json-to-placeholders`, jsonData, "", "POST")
      .then((response) => {
          if (response && response.data){
            const data = response.data
            const keyData = Object.keys(data)
            const valueData = Object.values(data)
            for (let i=0;i<keyData.length;i++){
              const obj = componentStore.jsonData(keyData[i], JSON.stringify(valueData[i]));
              componentStore.addJsonData(obj)
            }
          }
      })
  }
}

decorate(HandleJsonFunction, {
  handleAddPlaceholdersJson: action
});

const handleJsonFunction = new HandleJsonFunction();

export default handleJsonFunction;
