import React from "react";

// Util
import OnboardingChat from "./OnboardingChat";
import W86Backdrop from "../W86Backdrop";
import { OnboardingContextProvider } from "./OnboardingContextProvider";

function AIOnboardingPage(props) {
  return (
    <OnboardingContextProvider>
      <W86Backdrop />
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          backgroundImage: "radial-gradient(#868686 1px, transparent 0)",
          backgroundSize: "40px 40px"
        }}
      >
        <OnboardingChat {...props} />
      </div>
    </OnboardingContextProvider>
  );
}

export default AIOnboardingPage;
