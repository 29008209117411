import React from "react";

// Material UI
import { Grid, styled } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

// Util
import { toJS } from "mobx";

const GridWithBorder = styled(Grid)(({ padding }) => ({
    border: "1px solid #D9D9D9",
    borderRadius: "6px",
    padding: padding,
}));

const CustomGrid = styled(Grid)(({ vertical, horizontal }) => ({
    display: "flex",
    justifyContent: horizontal,
    alignItems: vertical,
}));

const CancelIcon = styled(CancelOutlinedIcon)({
    height: "18px",
    width: "18px",
    color: "#868686",
    "&:hover": {
        cursor: "pointer",
        color: "rgba(0,0,0,0.8)",
    },
});

const TableCell = styled("div")({
    border: "1px solid #C4C4C4",
    marginBottom: "6px",
    borderRadius: "4px",
    minHeight: "30px",
    padding: "10px",
    fontSize: "12px",
    position: "relative",
    width: "100%",
});

const RequiredText = styled("span")({
    color: "rgba(0, 0, 0, 0.7)",
    fontSize: "11px",
    marginLeft: "3px",
});

const DescriptionText = styled("span")({
    color: "#868685",
    fontSize: "12px",
    marginLeft: "3px",
});

const ColumnNameText = styled("span")({
    color: "rgba(0, 0, 0, 1)",
    fontSize: "13px",
    fontWeight: "bold",
    width: "100%",
});

const AddNewSectionText = styled("span")({
    color: "rgba(0, 0, 0, 1)",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const SectionMaxText = styled("span")({
    color: "#6F6F6F",
    fontSize: "12px",
    display: "flex",
});

const AddIcon = styled("img")({
    color: "#000",
    marginRight: "4px",
});

function Preview({ data }) {
    return (
        <Grid container rowGap="24px">
            <GridWithBorder item xs={12} padding="16px">
                <Grid container rowGap="8px">
                    <CustomGrid item xs={12} vertical="center" horizontal="flex-end">
                        <CancelIcon />
                    </CustomGrid>
                    {data.columns.map((col) => {
                        return (
                            <CustomGrid item xs={12}>
                                <Grid container rowGap="4px">
                                    <Grid item xs={12}>
                                        <ColumnNameText>{col.question != "" ? col.question : "Untitled"}</ColumnNameText>
                                        <RequiredText>{col.required && " (Required)"}</RequiredText>
                                    </Grid>
                                    {col.description && (
                                        <Grid item xs={12}>
                                            <DescriptionText>{col.description}</DescriptionText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <TableCell />
                                    </Grid>
                                </Grid>
                            </CustomGrid>
                        );
                    })}
                </Grid>
            </GridWithBorder>
            <GridWithBorder item xs={12} padding="6px">
                <Grid container display="flex" alignItems="center" justifyContent="center">
                    <CustomGrid item xs={12} vertical="center" horizontal="center">
                        <AddNewSectionText>
                            <AddIcon src="/svgs/add_ad_section.svg" alt="" /> Add new section
                        </AddNewSectionText>
                    </CustomGrid>
                </Grid>
            </GridWithBorder>
            {data && data.numRows && (
                <Grid item xs={12}>
                    <SectionMaxText>{`${data.numRows} section maximum`}</SectionMaxText>
                </Grid>
            )}
        </Grid>
    );
}

export default Preview;
