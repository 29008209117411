import React, { Component } from "react";
import { Grid, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InZapierOrW86Box from "./InZapierOrW86Box";

class SkipTest extends Component {
  render() {
    let zapierContent = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          1. Return to the <b>Test Action</b> step in Zapier
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          <img
            src="/images/trigger_from_zapier_finalTest.png"
            style={{
              border: "thin solid #C4C4C4",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              width: "80%",
              height: "auto"
            }}
          />
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          2. Click <b>Turn on Zap</b> to activate your Zap
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          Note: To <b>Retest</b> your Zap later, you will need to publish this workflow
        </Grid>
      </Grid>
    );

    let workflow86Content = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          3. Click <b>Finish</b> to complete the setup
        </Grid>
      </Grid>
    );

    return (
      <Grid container spacing={2}>
        <InZapierOrW86Box zapierOrW86={"Zapier"} content={zapierContent} isExpanded={this.props.isExpanded} />

        <InZapierOrW86Box
          zapierOrW86={"Workflow86"}
          content={workflow86Content}
          isExpanded={this.props.isExpanded}
        />

        <Grid item xs={12} className="dialogContentRight">
          <Grid item xs={12}>
            <Button
              onClick={() => this.props.onChangeState(5, 5)}
              className="btnSpace"
              variant={"outlined"}
              color={"info"}
            >
              {" "}
              Back
            </Button>
            <Button
              onClick={() => this.props.onChangeState(7, 7)}
              variant={"contained"}
              color={"primary"}
            >
              {" "}
              Finish
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SkipTest;
