import { computed, observable, decorate, action, toJS } from "mobx";


class MergeWorkflowStore {

  constructor() {
    this.initialiseEmpty();
  }

  keyAuto = () => {
    return "mw" + (Math.floor(Math.random() * 90000000) + 10000).toString();
  };


  getDefaultData() {
    return {
      "function":"ANY",
      "condition":{}
    }
  }

  initialiseEmpty() {
    this.name = "";
    this.componentId = "";
    this.type = "merge_workflow";
    this.lastModified = "";    
    this.error = false;    
    this.data = this.getDefaultData();
    this.relatedComponents = [];    
  };

  setFunction(funct) {
    this.data.function = funct;
  }

  setFunctionDefaultValue() {
    if(this.getFunction() === "ANY") {
      //ANY function, it should be just a single leve array in the condition
      this.setCondition({"OR":[]});      
    } else if(this.getFunction() === "OROFAND") {
      //OR of AND
      this.setCondition({"OR":[ {"AND":[]} ]});      
    }
  }

  getFunction() {
    return this.data.function;
  }

  getCondition() {
    return this.data.condition;
  }

  setCondition(condition) {
    this.data.condition = condition;
  }

  addCondition(index) {
    if(this.getFunction() === "ANY") {
      //ANY function, it should be just a single leve array in the condition
      if(!this.getCondition() || !this.getCondition()["OR"] ) {
        //init the ANY condition
        this.setCondition({"OR":[]});        
      } 

      this.getCondition()["OR"].push("null");

    } else if (this.getFunction() === "OROFAND" && index >= 0) {
      if(!this.getCondition() || !this.getCondition()["OR"] ) {
        this.setFunctionDefaultValue();
      }

      this.getCondition()["OR"][index]["AND"].push("null");

    }
  }

  deleteCondition(index, orConditionIndex) {
    if(this.getFunction() === "ANY") {
      //ANY function, it should be just a single leve array in the condition
      if(this.getCondition() && this.getCondition()["OR"] ) {
        this.getCondition()["OR"].splice(index,1);
      }       

    } else if(this.getFunction() === "OROFAND") { 
      this.getCondition()["OR"][orConditionIndex]["AND"].splice(index,1);

      if(this.getCondition()["OR"][orConditionIndex]["AND"].length == 0) {
        //no more condition. we can remove this AND block

        this.getCondition()["OR"].splice(orConditionIndex,1);
      }
    }
  }

  setConditionValue(index, value, orConditionIndex) {
    if(this.getFunction() === "ANY") {
      this.getCondition()["OR"][index] = value;
    } else if(this.getFunction() === "OROFAND") { 
      this.getCondition()["OR"][orConditionIndex]["AND"][index] = value;
    }
    
  }

  addORBlock() {
    if(this.getFunction() === "OROFAND") { 
    
      this.getCondition()["OR"].push( {"AND":[]} );
    }
  }

  setRelatedComponents (relatedComponents) {
    this.relatedComponents = relatedComponents;
  }

  getRelatedComponents() {
    return this.relatedComponents;
  }

  setFocus = (index) => {
    this.position = index;    
  };

  setComponentData (component) {
    this.componentId = component.componentId;
    this.type = component.type;
    if (component.componentData && component.componentData.data) {
      this.data = component.componentData.data;
      this.lastModified = component.componentData.lastModified;
      this.name = component.componentData.name;
    } else {
      this.data = this.getDefaultData();
      this.name = ""
    }
  };

  updateComponentData(data) {
    if (data) {
      this.data = data;
      this.relatedComponents = [];
      if(data.function === 'ANY' && data.condition && data.condition.OR){
        for(let i=0; i<data.condition.OR.length; i++){
          this.relatedComponents.push(this.createRelatedComponentItem(data.condition.OR[i]))
        }
      } else if(
        data.function === 'OROFAND'
        && data.condition
        && data.condition.OR
        && data.condition.OR.length === 1
        && data.condition.OR[0].AND
      ){
        for(let i=0; i< data.condition.OR[0].AND.length; i++){
          this.relatedComponents.push(this.createRelatedComponentItem(data.condition.OR[0].AND[i]))
        }
      }
    }
  }

  createRelatedComponentItem(componentId) {
    return {
      "componentId": componentId,
      "componentData": null,
      "userId": null,
      "type": null,
      "name": "",
      "databaseId": null
    }
  }

  setName = (name) => {
    this.name = name;
  };
}

decorate(MergeWorkflowStore, {
  componentId: observable,
  name: observable,
  data: observable,
  json: observable,
  type: observable,
  lastModified: observable,
  error: observable,
  setComponentName: action,
  addComponentData: action,
  setComponentData: action,
  updateComponentData: action,
  setLastModified: action,
  addNewData: action,
  setDataValue: action,
  deleteData: action,
  initialiseEmpty: action,
  setFunction: action,
  addCondition: action
});

const store = new MergeWorkflowStore();
export default store;