import config from "../config"

const awsconfig = {
  REGION: process.env.REACT_APP_REGION || "ap-southeast-2",
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID || config.AWS.USER_POOL_ID, 
  APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID || config.AWS.APP_CLIENT_ID, 
  DOMAIN: config.AWS.DOMAIN, 
};

const aws_config = {
  Auth: {
    Cognito: {
      userPoolId: awsconfig.USER_POOL_ID,
      userPoolClientId: awsconfig.APP_CLIENT_ID,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: `authenticate.app${awsconfig.DOMAIN}`, 
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [`https://app${awsconfig.DOMAIN}`],
          redirectSignOut: [`https://app${awsconfig.DOMAIN}`],
          responseType: 'code',
        },
        username: true,
        email: true,
        phone: true,
      },
    }
  },
  gg_client_id: "541992205730-ombu26ut0amuo794b59bku1kt08egofm.apps.googleusercontent.com",
};

// Additional settings for cookie storage and other configurations
const additionalConfig = {
  mandatorySignId: true,
  cookieStorage: {
    domain: window.location.host.includes("localhost") ? "localhost" : awsconfig.DOMAIN,
    path: "/",
    expires: 1,
    secure: false
  },
  aws_cognito_region: awsconfig.REGION,
  aws_user_pools_id: awsconfig.USER_POOL_ID,
  aws_user_pools_web_client_id: awsconfig.APP_CLIENT_ID,
  clientId: aws_config.gg_client_id,
};

export default aws_config;
