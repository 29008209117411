import config from "../config";
import { send_request  } from "./Request";
export const handleUploadFile = async (files) => {
    const project_id = window.location.pathname.split("/").pop();
    if (project_id && files && files.length > 0) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("configId", "fileupload");
      formData.append("type", "upload");
      formData.append("projectId", project_id);
      formData.append("projectSessionId", "");
      formData.append("formSessionId", "");

      const json = await send_request(
        "project-service/project/file-library/create",
        formData,
        "",
        "POST",
        {
          "content-type": "multipart/form-data",
        }
      ).catch((err) => {
        throw err;
      });

      if (json && json.data) {
        let url = `${config.FILE_SERVICE.QUERY_URL}/${json.data.id}`;

        return url;
      } else {
        console.log("Cant upload file");
      }
    }
  };