import { Grid } from "@mui/material";
import { StyledSkeleton } from "../../RenamePlaceholderLogic/RenameStyledComponents";

export const getChatHistoryLoader = () => {
    return (
        <Grid container rowSpacing={2}>
            {[1, 2, 3, 4, 5, 6].map(() => {
                return (
                    <Grid item xs={12}>
                        <StyledSkeleton variant="rectangular" height="90px" />
                    </Grid>
                );
            })}
        </Grid>
    );
};
