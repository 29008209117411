import { computed, observable, decorate, action, toJS } from "mobx";

class EmailStore {
  constructor() {
    this.initialiseEmpty();
  }

  setEmailTemplate = data => {
    this.email_template_id = data.componentId;
    //this.client_id = emailTemplate.client_id;
    this.email_name = data.componentData.name;
    this.template_data = data.componentData.data;
    if(!this.template_data.recipients.replyTo)
      this.template_data.recipients.replyTo=[]
    this.lastModified = data.componentData.lastModified;
  };
  setTemplateData = data =>{
    this.template_data= data
  }

  setEmailName = emailName => {
    this.email_name = emailName;
    this.template_data.name = emailName;
  };

  setSubject = subject => {
    this.template_data.subject = subject;
  };

  setClientName = clientName => {
    this.template_data.client_name = clientName;
  };

  setContent = content => {
    this.template_data.content = content;
  };

  setRecipientsTo = to => {
    this.template_data.recipients.to = to;
  };

  setRecipientsCc = cc => {
    this.template_data.recipients.cc = cc;
  };

  setRecipientsBcc = bcc => {
    this.template_data.recipients.bcc = bcc;
  };
  setReplyTo = replyTo =>
  {
    this.template_data.recipients.replyTo = replyTo;
  }

  setCredentialId = credentialId => {
    this.template_data.credentialId = credentialId;
  }

  getCredentialId = () => {
    return this.template_data.credentialId;
  }

  initialiseEmpty = () => {
    this.email_template_id = "";
    this.client_id = "000001";
    this.email_name = "";
    this.lastModified = "";
    this.template_data = {
      subject: "",
      client_name: "",
      content: "",
      recipients: {
        to: [],
        cc: [],
        bcc: [],
        replyTo:[]
      },
      attachedFiles: [],
      name: ""
    };
  };

  get template() {
    return {
      componentId: this.email_template_id,
      lastModified: this.lastModified,
      componentData: toJS(this.template_data)
    };
  }
}

decorate(EmailStore, {
  email_template_id: observable,
  client_id: observable,
  lastModified: observable,
  email_name: observable,
  template_data: observable,
  setEmailTemplate: action,
  setEmailName: action,
  setSubject: action,
  setClientName: action,
  setContent: action,
  setRecipientsTo: action,
  setRecipientsCc: action,
  setRecipientsBcc: action,
  setReplyTo:action,
  setCredentialId: action,
  initialiseEmpty: action,
  setTemplateData:action,
  template: computed
});

const emailStore = new EmailStore();
export default emailStore;
