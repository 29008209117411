import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx"; 
import FormPredertermineStore from "./FormConditionStores/FormPredertermineStore";
import ItemStatementCondition from "./FormConditionComponents/ItemStatementPredertermine";

class FormConditional extends React.Component {
  componentDidMount() {
    const { autoAnswer } = this.props;

    if (autoAnswer && typeof autoAnswer === "object") {
      FormPredertermineStore.setStatement(autoAnswer)
      FormPredertermineStore.setFormConditional(autoAnswer);
    } else {
      FormPredertermineStore.initialiseEmpty();
    }
  }
  render() {
    const { Condition } = toJS(
      FormPredertermineStore.Statement.Statement.content
    );
    const { AnswerList } = toJS(FormPredertermineStore); 
    return (
      <div
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          padding: "15px 15px 30px",
          margin: "15px 0"
        }}
      >
        {Condition &&
          Condition.map((condition, index) => (
            <ItemStatementCondition
              condition={condition}
              indexStatement={index}
              key={index}
              AnswerList={AnswerList[index]}
              availablePlaceholders = {this.props.availablePlaceholders}
            />
          ))}
      </div>
    );
  }
}
export default observer(FormConditional);
