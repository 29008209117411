import React, { useEffect, useState } from "react";

// MUI
import { Grid, styled } from "@mui/material";

import StorageIcon from "@mui/icons-material/Storage";
import TagIcon from "@mui/icons-material/Tag";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { send_request } from "../../../../utils/Request";
import { toJS } from "mobx";
import { StyledSkeleton } from "../../../pages/Canvas/RenamePlaceholderLogic/RenameStyledComponents";
import { getColumnIcon } from "./ColumnAndRecordSelection";

const PreviewText = styled("span")({
    fontSize: "14px",
});

function DatabaseContentPreview({ data }) {
    const [isFetchingDBName, setIsFetchingDBName] = useState(false);
    const [databaseName, setDatabaseName] = useState("Database not selected");

    const [isFetchingColumn, setIsFetchingColumn] = useState(false);
    const [columnName, setColumnName] = useState("Column not selected");
    const [columnType, setColumnType] = useState("VARCHAR2");

    const [recordIDContent, setRecordIDContent] = useState("...");

    useEffect(async () => {
        let JSONData = toJS(data);
        if (JSONData.displayDB_recordID) setRecordIDContent(JSONData.displayDB_recordID);
        fetchDatabaseName(JSONData);
        fetchColumnName(JSONData);
    }, [data.displayDB_recordID, data.displayDB_databaseID, data.displayDB_columnID]);

    const fetchDatabaseName = async (JSONData) => {
        setIsFetchingDBName(true);

        let DBId = JSONData.displayDB_databaseID;
        if (DBId) {
            let url = `database/get_database_name/${DBId}`;
            const json = await send_request(url, null, null, "GET");
            if (json && json.data) setDatabaseName(json.data);
        }

        setIsFetchingDBName(false);
    };

    const fetchColumnName = async (JSONData) => {
        setIsFetchingColumn(true);

        let columnId = JSONData.displayDB_columnID;
        if (columnId) {
            let url = `database/get_column_name_and_type/${columnId}`;
            const json = await send_request(url, null, null, "GET");
            if (json && json.data) {
                setColumnName(json.data.name);
                setColumnType(json.data.type);
            }
        }

        setIsFetchingColumn(false);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
                <StorageIcon style={{ height: "16px", marginRight: "8px" }} />{" "}
                {isFetchingDBName ? <StyledSkeleton variant="rectangular" width="200px" height="30px" /> : <PreviewText>{databaseName}</PreviewText>}
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
                {isFetchingColumn ? (
                    <StyledSkeleton variant="rectangular" width="25px" height="25px" margin="0px 8px 0px 0px" />
                ) : (
                    <div style={{ marginRight: "8px" }}>{getColumnIcon(columnType)}</div>
                )}
                {isFetchingColumn ? <StyledSkeleton variant="rectangular" width="200px" height="30px" /> : <PreviewText> {columnName}</PreviewText>}
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
                <SubdirectoryArrowRightIcon style={{ height: "16px", marginRight: "8px", color: "rgba(134, 134, 134, 1)" }} /> <PreviewText>{recordIDContent}</PreviewText>
            </Grid>
        </Grid>
    );
}

export default DatabaseContentPreview;
