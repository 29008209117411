import React, { useState } from "react";
import {
  Chip,
  Avatar,
  Tooltip,
  Grid,
  Dialog,
  DialogContent,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const StyledChip = styled(Grid)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  border: "1px solid #00000033",
  margin: "4px",
  height: "160px",
  minWidth: "160px",
  padding: "8px",
  borderRadius: "10px",
}));

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  cursor: "pointer",
  color: "#000000",
  marginLeft: "8px",
  fontSize: "small",
}));

const StyledFileName = styled("span")(({ theme }) => ({
  color: theme.palette.text.primary,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: 130,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: 120,
  width: 140,
  borderRadius: 0,
  margin: theme.spacing(0.5),
  cursor: "pointer",
}));

const ImageChip = ({ files, setFiles }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleDelete = (fileToDelete) => {
    // had to revoke the image url to free up memory
    URL.revokeObjectURL(fileToDelete.preview);
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <Grid container direction="row-reverse">
        {files.map((file, key) => (
          <Tooltip key={key} title={file.name || file} placement="top">
            <StyledChip>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <StyledFileName>{file.name || file}</StyledFileName>
                {setFiles && (
                  <StyledCloseIcon
                    onClick={() => handleDelete(file)}
                  />
                )}
              </Grid>
              <StyledAvatar
                src={file.preview}
                onClick={() => handleClickOpen(file.preview)}
              >
                <img src="/svgs/fileIcon.svg" alt="File Icon" />
              </StyledAvatar>
            </StyledChip>
          </Tooltip>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" style={{ width: "100%" }} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageChip;
