import React, {Component} from "react";

//styling
import {withStyles} from "@mui/styles";
import styles from "./EmailTriggerStyles";

//components
import {Grid, InputAdornment, Paper, TextField} from "@mui/material";
import {IndividualComponentLoader} from "../../utils/ComponentLoadingSkeleton";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CategoryTitle from "./components/CategoryTitle";
import Placeholder from "./components/Placeholder";
import Title from "./components/Title";

//mobx
import {inject, observer} from "mobx-react";
import EmailTriggerStore from "./EmailTriggerStore";
import projectStore from "../ProjectCanvas/ProjectStore";
import {reaction, toJS} from "mobx";

//making requests
import {send_component_save_request, send_request} from "../../utils/Request";
import {ContentCopy} from "@mui/icons-material";

//other utils
import {getCurrentTime} from "../../utils/getCurrentTime";

class EmailTriggerTemplate extends Component {
  //----------------------------------------------------------------------------------------------
  // State
  //----------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      receiver: "",
      receivingEmails: true,
      incomingMessagePlaceholders: {},
      status:null
    };
    EmailTriggerStore.initialiseEmpty();
  }

  //----------------------------------------------------------------------------------------------
  // Mount
  //----------------------------------------------------------------------------------------------

  async componentDidMount() {
    this.setState({ loading: true });

    if (this.props.component_id !== undefined) {
      await send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then(async (response) => {
          if (response && response.data) {
            const { components } = response.data;

            if (components) {
              EmailTriggerStore.setEmailTrigger(components[0]);

              //connect to component panel or component page via mobx
              this.props.onComponentNameChanged(EmailTriggerStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(EmailTriggerStore.lastModified);

              if (EmailTriggerStore.data.receiver == "none") {
                //make a request to component service to generate a receiving email
                await send_request(
                  `component-service/trigger_via_email/generate_email/${this.props.component_id}`,
                  "",
                  {},
                  "GET"
                )
                  .then((res) => {
                    if (res && res.data) {
                      EmailTriggerStore.data.receiver = res.data.receiver;
                      //initialize new receiver in the store

                      this.setState({ receiver: res.data.receiver });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                this.setState({ receiver: EmailTriggerStore.data.receiver });
              }
              //init local state
              let aiComponentBuilderData = this.props.aiComponentBuilderData;
              if (aiComponentBuilderData !== undefined &&
                  aiComponentBuilderData["data"] !== undefined &&
                  aiComponentBuilderData["data"] !== '') {

                if (aiComponentBuilderData.name) {
                  this.props.onComponentNameChanged(aiComponentBuilderData.name);
                }

                if (aiComponentBuilderData.data.incomingMessagePlaceholders) {
                  EmailTriggerStore.setIncomingMessagePlaceholders(aiComponentBuilderData.data.incomingMessagePlaceholders);
                }

                if (aiComponentBuilderData.explanation) {
                  this.setState({description: aiComponentBuilderData.explanation});
                  this.props.onComponentDescriptionChanged(aiComponentBuilderData.explanation);
                }

                this.setState({status: "AI_BUILD_GENERATED"});
              }


              this.setState({
                receivingEmails: EmailTriggerStore.data.receivingEmails,
                incomingMessagePlaceholders: EmailTriggerStore.data.incomingMessagePlaceholders,
                loading: false,
              });
            }
          }

          //remove loader in component sidebar
          this.props.setChildTemplateLoaded(true);
        })
        .catch((err) => {
          throw Error(err.message);
        });
    }
  }

  //----------------------------------------------------------------------------------------------
  // Save
  //----------------------------------------------------------------------------------------------

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveEmailTrigger();
  }

  saveEmailTrigger = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.error) {
        this.props.showError();
        return;
      }

      let lastModified = getCurrentTime();
      let data = {
        componentData: {
          data: EmailTriggerStore.template.data,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified,
        },
        componentId: this.props.component_id,
        type: EmailTriggerStore.template.type,
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };

      send_component_save_request("component-service/trigger_via_email/data", data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  //----------------------------------------------------------------------------------------------
  // Handling user interaction
  //----------------------------------------------------------------------------------------------

  handleSelectPlaceholder = (selected, category, id) => {
    //find category to update
    let categoryToUpdate = toJS(this.state.incomingMessagePlaceholders)[category];

    //update item
    categoryToUpdate.map((item, key) => {
      if (item.id == id) {
        item.selected = !item.selected;
      }
    });

    //replace category in the placeholders map
    let updatePlaceholders = this.state.incomingMessagePlaceholders;
    updatePlaceholders[category] = categoryToUpdate;

    //replace placeholders in the store
    EmailTriggerStore.setIncomingMessagePlaceholders(updatePlaceholders);

    //issue state update
    this.setState({ incomingMessagePlaceholders: updatePlaceholders });
  };

  handleKeyChange = (val, category, id) => {
    //find category to update
    let categoryToUpdate = toJS(this.state.incomingMessagePlaceholders)[category];

    //update item
    categoryToUpdate.map((item, key) => {
      if (item.id == id) {
        item.key = val;
      }
    });

    //replace category in the placeholders map
    let updatePlaceholders = this.state.incomingMessagePlaceholders;
    updatePlaceholders[category] = categoryToUpdate;

    //replace placeholders in the store
    EmailTriggerStore.setIncomingMessagePlaceholders(updatePlaceholders);

    //issue state update
    this.setState({ incomingMessagePlaceholders: updatePlaceholders });
  };

  //----------------------------------------------------------------------------------------------
  // JSX
  //----------------------------------------------------------------------------------------------

  render() {
    const { loading, receiver, incomingMessagePlaceholders } = this.state;

    if (loading) return <IndividualComponentLoader />;
    else
      return (
        <Grid>
          <Paper className={this.props.classes.paper}>
            <p>Send emails to this email address to trigger this component</p>
            <TextField
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
              }}
              value={receiver}
              size={"small"}
              variant="outlined"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CopyToClipboard text={receiver}>
                      <ContentCopy className="custom-icon-btn" />
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
              className={this.props.classes.address}
            />

            <Title />
            {incomingMessagePlaceholders &&
              incomingMessagePlaceholders.content &&
              incomingMessagePlaceholders.addresses &&
              incomingMessagePlaceholders.metadata && (
                <>
                  <CategoryTitle title="Email contents" />

                  {incomingMessagePlaceholders.content.map((value, k) => {
                    return (
                      <Placeholder
                        key={value.key}
                        placeholderKey={value.key}
                        id={value.id}
                        name={value.name}
                        selected={value.selected}
                        handleKeyChange={(event, val, id) => {
                          this.handleKeyChange(val, "content", id);
                        }}
                        handleSelected={(event, selected, id) =>
                          this.handleSelectPlaceholder(selected, "content", id)
                        }
                      />
                    );
                  })}

                  <CategoryTitle title="Email addresses" />

                  {incomingMessagePlaceholders.addresses.map((value, k) => {
                    return (
                      <Placeholder
                        key={value.key}
                        placeholderKey={value.key}
                        id={value.id}
                        name={value.name}
                        selected={value.selected}
                        handleKeyChange={(event, val, id) => {
                          this.handleKeyChange(val, "addresses", id);
                        }}
                        handleSelected={(event, selected, id) =>
                          this.handleSelectPlaceholder(selected, "addresses", id)
                        }
                      />
                    );
                  })}

                  <CategoryTitle title="Metadata" />

                  {incomingMessagePlaceholders.metadata.map((value, k) => {
                    return (
                      <Placeholder
                        key={value.key}
                        placeholderKey={value.key}
                        id={value.id}
                        name={value.name}
                        selected={value.selected}
                        handleKeyChange={(event, val, id) => {
                          this.handleKeyChange(val, "metadata", id);
                        }}
                        handleSelected={(event, selected, id) =>
                          this.handleSelectPlaceholder(selected, "metadata", id)
                        }
                      />
                    );
                  })}
                </>
              )}
          </Paper>
        </Grid>
      );
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(EmailTriggerTemplate))
);
