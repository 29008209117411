import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// MUI
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  InputLabel,
  Dropdown,
  OpenWorkflowText,
  BoldText,
  BlueText,
  StyledOpenInNewIcon
} from "./StyledComponents";

function Dropdowns({
  updateState,
  handleSaveComponent,
  projects,
  components,
  getComponents,
  targetProjectId,
  targetComponentId
}) {
  const getProjectLabel = projId => {
    let proj = projects.find(proj => proj.id === projId);
    return proj;
  };

  const getComponentLabel = compId => {
    if (!components) return "";
    let comp = components.find(comp => comp.id === compId);
    return comp;
  };

  // Handles updating the selected projectId
  // Also saves the component after setting the projectId
  const handleSelectProject = newProjectId => {
    updateState({
      targetProjectId: newProjectId,
      targetComponentId: ""
    }).then(() => {
      handleSaveComponent();
    });
  };

  // Handles updating the selected component id
  const handleSelectComponent = newComponentId => {
    updateState({ targetComponentId: newComponentId });
  };

  const renderProjectDropdown = () => {
    return (
      <Grid item xs={12}>
        <InputLabel>
          Select the <BoldText>Target Workflow </BoldText> that this component
          will trigger
        </InputLabel>
        <Dropdown style={{width:"450px"}}
          value={getProjectLabel(targetProjectId)}
          onChange={(e, newValue) => {
            if (newValue != null) handleSelectProject(newValue.id);
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label ? option.label : option.id}
            </li>
          )}
          size={"small"}
          options={projects}
          renderInput={params => (
            <TextField {...params} placeholder={"Select project"} />
          )}
        />
      </Grid>
    );
  };

  const renderComponentsDropdown = () => {
    return (
      <>
        <Grid item xs={12}>
          <Link
            to={`/project/canvas/${targetProjectId}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <OpenWorkflowText>
              <BlueText>
                <StyledOpenInNewIcon />
                Open {getProjectLabel(targetProjectId).label} in a new tab
              </BlueText>
            </OpenWorkflowText>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            Select the <BoldText>Trigger from a Workflow </BoldText>
            component in the <BoldText>Target Workflow</BoldText>
          </InputLabel>
          <Dropdown style={{width:"450px"}}
            value={getComponentLabel(targetComponentId)}
            onChange={(e, newValue) => {
              if (newValue != null) handleSelectComponent(newValue.id);
            }}
            onFocus={() => {
              if (targetComponentId === "") {
                getComponents(targetProjectId);
              }
            }}
            size={"small"}
            options={components ? components : []}
            renderInput={params => (
              <TextField {...params} placeholder={"Select component"} />
            )}
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      {projects && renderProjectDropdown()}
      {targetProjectId && renderComponentsDropdown()}
    </>
  );
}

Dropdowns.PropTypes = {
  updateState: PropTypes.func,
  handleSaveComponent: PropTypes.func,
  projects: PropTypes.element,
  components: PropTypes.element,
  getComponents: PropTypes.func,
  targetProjectId: PropTypes.string,
  targetComponentId: PropTypes.string
};

export default Dropdowns;
