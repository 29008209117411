import React, { useState, useEffect } from "react";

// MUI
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Icons
import ErrorIcon from "@mui/icons-material/Error";
import RenderColumn from "./RenderColumn";

function TableInputs(props) {
  const { classes } = props;

  const [numberOfRows, setNumberOfRows] = useState(props.numRows);
  const [error, setError] = useState("");

  useEffect(() => {
    setNumberOfRows(props.numRows);
  }, [props.numRows]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.topTitleContainer}>
          <span className={classes.tableSettingsTitle}>Table Settings</span>
        </Grid>

        <Grid item xs={12} className={classes.gridRow}>
          <div className={classes.topText}>Export in the following format</div>
          <Select
            onChange={e => props.setExportType(e.target.value)}
            className={classes.exportTypeSelect}
            disableUnderline
            variant={"standard"}
            value={props.exportType}
            size="small"
          >
            <MenuItem value={"rowsAsLists"}>Each row as a list</MenuItem>
            <MenuItem value={"columnsAsLists"}>Each column as a list</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} className={classes.gridRow}>
          <Grid item xs={12}>
            <span className={classes.topText}>Number of rows</span>
            <span className={classes.maxText}>(Max 100)</span>
          </Grid>
          <TextField
            value={numberOfRows}
            size="small"
            className={classes.numRowsInput}
            error={error != ""}
            helperText={error != "" && error}
            onChange={e => {
              setError("");
              setNumberOfRows(e.target.value);
            }}
            onBlur={() => {
              if (isNaN(numberOfRows)) {
                setError("Number of rows must be an integer");
                return;
              } else if (numberOfRows > 100) {
                setError("Number of Rows must be less than 100");
                return;
              } else if (numberOfRows <= 0) {
                setError("You must have at least one row");
                return;
              }
              props.handleChangeNumRows(numberOfRows);
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.columnContainer}
        gap={2}
        wrap="nowrap"
        sx={{ overflow: "auto" }}
      >
        {props.columns.map((col, idx) => {
          return <RenderColumn key={col.id} col={col} idx={idx} {...props} />;
        })}
      </Grid>

      {props.tableErrors != "" && (
        <Grid item xs={12} className={classes.errorSection}>
          <ErrorIcon className="iconDiscard" />
          {props.tableErrors}
        </Grid>
      )}
    </>
  );
}

export default TableInputs;
