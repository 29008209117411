import React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

const VButtonGroup = (props) => {
  return (
    <ButtonGroup {...props}>
      {props.data &&
        props.data.map((bt, index) => (
          <Button
            key={index}
            color={bt.active ? "primary" : "info"}
            variant={bt.active ? "contained" : "outlined"}
            size="medium"
            onClick={() => {
              props.onClickButton(bt.key);
            }}
          >
            {bt.name}
          </Button>
        ))}
    </ButtonGroup>
  );
};

export default VButtonGroup;
