import React from "react";
import PropTypes from "prop-types";
import { withStyles} from "@mui/styles";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";

const styles = theme => ({
  root: {
    width: "100%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper
  },
  textroot: {
    width: "100%",
    margin: "auto"
  }
});

class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: ""
    };
  }

  render() {
    const { classes } = this.props;
    try {
      return (
        <div className={classes.root}>
              <TextField
                id="free-text-input"
                className={classes.textroot}
                InputProps={{
                  className: classes.input
                }}
                step="any"
                type="number"
                variant="outlined"
              />
        </div>
      );
    } catch (e) {
      return <h5>Error encountered</h5>;
    }
  }
}

NumberInput.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NumberInput);
