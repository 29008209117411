const styles = theme => ({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridRowGap: 24
  },
  headerLeft: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    maxWidth: 570,
    gridColumnStart: 1,
    gridColumnEnd: 3
  },
  headerRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  main: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnStart: 1,
    gridColumnEnd: 4
  },
  footer: {
    gridColumnStart: 1,
    gridColumnEnd: 4
  },
  wrapCalculator: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: 24,
    background: "#fff",
    marginBottom: 16
  },
  label: {
    fontWeight: 600,
    alignItems: "center",
    display: "grid",
    paddingRight: 24
  },
  label2: {
    fontWeight: 600,
    alignItems: "center",
    display: "grid",
    paddingRight: 24,
    minWidth: 106
  },
  newLabel: {
    fontWeight: 600,
  },
  select: {
    width: "100%",
    display: "inline-block"
  },
  deleteIcon: {
    cursor: "pointer",
    marginLeft: 16,
    color: "#868686",
    "&:hover": {
      color: "#2196F3"
    }
  },
  wrapFlexCenter: {
    display: "flex",
    justifyContent: "center",
    margin: "32px 0"
  },
  textContentCenter: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    "&:hover": {
      color: "#2196F3"
    }
  },
  calculationSelect: {
    width: "100%",
    backgroundColor: "#F8F8F8",
    borderRadius: "4px",
    height: "36px",
    color: "#868686",
  },
  round: {
    width: 70,
    display: "inline-block"
  }
});
export default styles;
