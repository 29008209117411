import React from "react";
//mui components
import { Grid, Select, MenuItem, TextField } from "@mui/material";
//icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";

const CustomizeResponse = (props) => {
  return (
    <Grid
      container
      item
      xs={12}
      direction={"column"}
      alignItems={"flex-start"}
      className={"border"}
    >
      <p className={"bold m-0"}>Status</p>
      <Grid container item xs={4}>
        <Select
          sx={{ minWidth: 272, textAlign: "left" }}
          variant={"filled"}
          disableUnderline
          value={props.status}
          onChange={(event) => props.editStatus(event.target.value)}
        >
          <MenuItem value={200}>200</MenuItem>
          {/* <MenuItem value={300}>300</MenuItem>
          <MenuItem value={400}>400</MenuItem>
          <MenuItem value={500}>500</MenuItem> */}
        </Select>
      </Grid>
      <p className={"bold m-b-0"}>Data Type</p>
      <Select
        sx={{ minWidth: 272, textAlign: "left" }}
        variant={"filled"}
        disableUnderline
        value={props.dataType}
        onChange={(event) => props.editDataType(event.target.value)}
      >
        <MenuItem value={"JSON"}>JSON</MenuItem>
        <MenuItem value={"URL_ENCODED"}>
          application/x-www-form-urlencoded
        </MenuItem>
        <MenuItem value={"TEXT"}>text/plain</MenuItem>
      </Select>
      <p className={"bold m-b-0"}>Body</p>
      <Grid xs={12} container item>
        <TextField
          value={props.body}
          variant={"outlined"}
          fullWidth
          multiline={true}
          onChange={(event) => {
            props.editBody(event.target.value);
          }}
        />
      </Grid>
      <p className={"bold m-b-0"}>Custom Headers</p>
      {props.headers.length > 0 &&
        props.headers.map((header, index) => {
          return (
            <Grid xs={12} container item direction={"row"} key={index}>
              <Grid container item xs={12} direction={"row"} spacing={2}>
                <Grid container item xs={5}>
                  <p className={"m-b-0"}>Key</p>
                </Grid>
                <Grid container item xs={5}>
                  <p className={"m-b-0"}>Value</p>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                alignItems={"center"}
                spacing={2}
              >
                <Grid container item xs={5}>
                  <TextField
                    value={header.key}
                    fullWidth={true}
                    onChange={(event) => {
                      props.editHeader(event.target.value, index, "key");
                    }}
                    size={"small"}
                  />
                </Grid>
                <Grid container item xs={5}>
                  <TextField
                    fullWidth={true}
                    value={header.value}
                    onChange={(event) => {
                      props.editHeader(event.target.value, index, "value");
                    }}
                    size={"small"}
                  />
                </Grid>
                <Grid xs={2} item container alignItems={"flex-end"}>
                  <DeleteOutlineIcon
                    className={"custom-icon-btn"}
                    onClick={() => {
                      props.deleteHeader(index);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}

      <Grid container item xs={12} direction={"row"} alignItems={"center"}>
        <Grid
          container
          item
          xs={4}
          direction={"row"}
          alignItems={"center"}
          className="hover-add-button"
          onClick={() => {
            props.addHeader();
          }}
        >
          <AddIcon />
          <p>Add Custom Header</p>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CustomizeResponse;
