import React, {useContext, useState, useRef, useEffect, useCallback} from "react";

// Util
import uuid from "uuid";
import {useParams} from "react-router";
import ProjectStore from "../../../../../ProjectCanvas/ProjectStore";
import {createSaveEvent} from "../../../../../../utils/createSaveEvent";
import {ComponentFactory} from "../../../../../ProjectCanvas/ComponentRegistry";
import {send_request, send_request_graphql_mutation,} from "../../../../../../utils/Request";

// Canvas
import {CanvasContext} from "../../../WorkflowCanvas";
import {SAVE_EVENT, COPY_COMPONENT_SAVE_EVENT} from "../../../CanvasQueries";

import {getElements, calculateNewItemPostion,} from "../../../../../../utils/CanvasUtil";

// Custom components
import {CustomDialog} from "../../../../../Component/Dialog";
import ConvertFormDialog from "../../../Dialogs/ConvertFormDialog";
import ComponentSettingsToolbar from './ComponentNodeUtil/ComponentSettingsToolbar';
import AIDraftStatusBanner from './ComponentNodeUtil/AIDraftStatusBanner';
import ComponentNodeView from './ComponentNodeUtil/ComponentNodeView';

// MUI
import {Button, Grid, Select, MenuItem, Autocomplete, TextField,} from "@mui/material";
import {withStyles} from "@mui/styles";

// Icons
import Error from "@mui/icons-material/Error";

// MobX
import {toJS} from "mobx";
import {inject, observer} from "mobx-react";
import {getColourFromString} from "../../../../../../utils/ColourNameGenerator";
import { styles } from './ComponentNodeUtil/CustomComponentNodeUtil';
import BaseComponent from "./BaseComponent";
import {NOTE_TYPE} from "../../../../../../utils/CanvasConstants";


const DraftCanvasComponent = inject("CanvasStore")(
  observer(
    ({
      id,
      data,
      isConnectable,
      classes,
      selected,
      CanvasStore,
      xPos,
      yPos,
    }) => {
      const { modeInfo, isChildTemplateLoaded } = useContext(CanvasContext);
      const [childTemplateLoaded, setChildTemplateLoaded] = isChildTemplateLoaded || [];

      if (modeInfo === undefined) {
        return <BaseComponent classes={classes} data={data} isConnectable={isConnectable} />;
      }
      const parentRef = useRef(null);
      const [height, setHeight] = useState(0);
      const ref = useRef(null);
      useEffect(() => {
        if (ref.current) {
          const newHeight = ref.current.clientHeight;
          setHeight(newHeight);
        }
      }, []);

      const [mode, setMode] = modeInfo;

      const { nodes, edges } = CanvasStore;

      const { edit } = useContext(CanvasContext);


      const { testPanel } = useContext(CanvasContext);
      const { canvasInstance } = useContext(CanvasContext);
      const { savingInfo } = useContext(CanvasContext);
      const [editComponent, setEditComponent] = edit ? edit : useState({});

      const [saving, setSaving] = savingInfo ? savingInfo : useState({});
      const [reactFlowInstance, setReactFlowInstance] = canvasInstance
        ? canvasInstance
        : useState({});
      // dialog flags
      const [deleteNode, setDeleteNode] = useState(false);
      const [copyNode, setCopyNode] = useState(false);
      const [workflowType, setWorkflowType] = useState("current_workflow");
      const [workflows, setWorkflows] = useState([]);
      const [workflow, setWorkflow] = useState("");
      const [moreComponents, setMoreComponents] = useState(false);
      const [loadCancel, setLoadCancel] = useState(false);
      const [loadCopy, setLoadCopy] = useState(false);
      const [node, setNode] = useState([]);

      //form transform flags
      const [openConvertForm, setOpenConvertForm] = useState(false);
      const [convertTo, setConvertTo] = useState({});

      //Selected components
      const [isSelectedComponent, setIsSelectedComponent] = useState(false);

      const [svgSelectedClass, setSvgSelectedClass] = useState("");

      //record last modified value for the canvas
      const { updated } = useContext(CanvasContext);
      const { loadDialog } = useContext(CanvasContext);
      const { unpublishedChanges } = useContext(CanvasContext);
      const [lastModified, setLastModified] = updated
        ? updated
        : useState({});

      const [openTestPanel, setOpenTestPanel] = testPanel
        ? testPanel
        : useState({});

      const [loadingDialog, setLoadingDialog] = loadDialog
        ? loadDialog
        : useState(false);
      const [checkUnpublishedChanges] = unpublishedChanges
        ? unpublishedChanges
        : "";

      //react-router use params
      const params = useParams();

      let projectId = params.id;


      const handleNodeClick = useCallback((event) => {
        event.stopPropagation();
        if (edit) {
          if (editComponent) {
            if (editComponent.componentId !== node.componentId) {
              if (!childTemplateLoaded) return;
              setEditComponent(null);
              //Set this to true because there seems to be a delayed for when this gets set to true
              //ProjectStore.savedComponent is used to check if a save is in progress
              ProjectStore.savedComponent = true;
              return;
            }
          }
        }
        if (!ProjectStore?.savedComponent) {
          setEditComponent({ ...data, componentId: id });
        }

      }, [edit, editComponent, data, id]);
  
      const handleDelete = async () => {
        if (saving) return;

        setSaving(true);
        let result = [...nodes, ...edges];

        if (data.type === "conditional_workflow_path") {
          // Delete next components on canvas
          const nextCompToDelete = toJS(data.next);
          if (Array.isArray(nextCompToDelete)) {
            const componentIdsToDelete = nextCompToDelete.map(
              (item) => item.componentId
            );
            result = result.filter(
              (node) =>
                !(
                  componentIdsToDelete.includes(node.id) &&
                  node.data.type === "conditional_workflow" &&
                  node.data.fromWorkflowPath === true
                )
            );
          }
        }

        result = result.filter((node) => {
          return node.id !== id;
        });

        let oldDbid = ProjectStore.state.dbIds.get(id);
        if (oldDbid !== undefined) {
          ProjectStore.state.dbIds.delete(id);

          if (new Set(ProjectStore.state.dbIds.values()).has(oldDbid)) {
            if (id.displayName === "Query Database") {
              result = result.filter((node) => {
                return !(
                  node.target === id.componentId && node.source === oldDbid
                );
              });
            } else {
              result = result.filter((node) => {
                return !(
                  node.source === id.componentId && node.target === oldDbid
                );
              });
            }
          } else {
            result = result.filter((node) => {
              return node.id !== oldDbid;
            });
          }
        }

        CanvasStore.setNodesAndEdges(result);

        let rfiObj = reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        let eventData = {
          componentId: id,
          type: data.type,
        };

        let saveEvent = createSaveEvent(
          "DELETE_COMPONENT",
          ProjectStore.project_id,
          viewport,
          getElements(reactFlowInstance),
          eventData
        );

        //send event
        await send_request_graphql_mutation(
          `project-service/graphql/project/save/${ProjectStore.project_id}`,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            //insert delay here
            setSaving(false);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      const handleEdit = async () => {
        if (saving) return; // If saving, return

        setSaving(true);
        //save viewport
        let rfiObj = reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        let saveEvent = createSaveEvent(
          "SAVE_VIEWPORT",
          ProjectStore.project_id,
          viewport,
          getElements(reactFlowInstance),
          {}
        );

        //send event
        await send_request_graphql_mutation(
          `project-service/graphql/project/save/${ProjectStore.project_id}`,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            // handle navigation
            if (edit) {
              if (!editComponent) {
                data.componentId = id;
                setEditComponent(data);
              }
            }
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            //insert delay here
            setSaving(false);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      //copy dialog requests
      const handleSelectWorkflowType = async (value) => {
        setWorkflowType(value);
        setLoadCopy(true);

        if (value === "another_workflow" && workflows.length === 0) {
          const json = await send_request(
            `project-service/project/get_workflow_names/${projectId}`
          ).catch((e) => {
            console.log(e);
            closeCopy();
          });

          if (json && json.status === 200) {
            setWorkflows(json.data);
          }
        } else setLoadCopy(false);
      };

      const handleSelectWorkflow = async (input) => {
        if (input === "select_workflow" && workflowType === "another_workflow")
          setLoadCopy(true);
        else setLoadCopy(false);

        setWorkflow(input);
      };

      const closeCopy = () => {
        setWorkflow("select_workflow");
        setWorkflowType("current_workflow");
        setNode({});
        setCopyNode(false);
        setWorkflows([]);
        setLoadCopy(false);
        setLoadCancel(false);
      };

      const openInNewTab = () => {
        if (editComponent)
          //if component pane is open
          setEditComponent(null); //we unmount the component which triggers a save, closes the pane and dumps observables

        //Redirect to DraftCanvasComponent Page Url
        let url = window.location.pathname + `/${data.type}/${id}`;
        window.open(url, "_blank");
      };

      const requestCopy = async () => {
        if (nodes.length < 250) {
          setLoadCopy(true);
          setLoadCancel(true);

          let copyToId = "";

          if (workflowType === "current_workflow") {
            copyToId = projectId;
          } else {
            copyToId = workflow.projectId;
          }

          let eventData = {
            projectId: copyToId,
            componentId: id,
          };

          let rfiObj = reactFlowInstance.toObject();

          let viewport = {
            x: rfiObj.viewport.x.toFixed(5),
            y: rfiObj.viewport.y.toFixed(5),
            zoom: rfiObj.viewport.zoom.toFixed(5),
          };

          let saveEvent = createSaveEvent(
            "COPY_COMPONENT",
            ProjectStore.project_id,
            viewport,
            getElements(reactFlowInstance),
            eventData
          );

          //send event
          const json = await send_request_graphql_mutation(
            `project-service/graphql/project/save/${ProjectStore.project_id}`,
            COPY_COMPONENT_SAVE_EVENT(saveEvent),
            "",
            "POST"
          ).catch((e) => {
            console.log(e);
            closeCopy();
          });

          //handle updating canvas if we create a new component
          if (
            json &&
            json.status === 200 &&
            (workflowType === "current_workflow" || workflow === projectId)
          ) {
            console.log(json.data.saveEvent);
            let component = json.data.saveEvent.copiedComponent;
            let template = ComponentFactory[component.type];

            let nodesAndEdgesResult = [...nodes, ...edges];

            const newNode = {
              id: component.componentId,
              type: "component",
              position: component.viewData,
              data: {
                instruction: component.instruction
                  ? component.instruction
                  : "",
                type: component.type,
                label: component.name,
                description: component.description,
                displayName: template.displayName,
                logo: template.logo,
                hasInput: template.hasInput ? template.hasInput : false,
                hasOutput: template.hasOutput ? template.hasOutput : false,
                baseColor: template.baseColor,
              },
            };

            // Check if the copy component has a database connection
            if (ProjectStore.state.dbIds.has(id)) {
              ProjectStore.createDbNodeLogic(
                component,
                ProjectStore.state.dbIds,
                nodesAndEdgesResult,
                ProjectStore.state.dbIds.get(id),
                newNode.data
              );
              newNode.data.hasDb = true;
            }

            nodesAndEdgesResult.push(newNode);

            // Set the nodes and edges
            CanvasStore.setNodesAndEdges(nodesAndEdgesResult);
          }
        } else setMoreComponents(true);

        closeCopy();
      };

      const isForm = (type) => {
        return type === "form" ||
            type === "form_section" ||
            type === "sequential_form";


      };

      const handleConvertTo = (value) => {
        setConvertTo(value);
      };

      const openTestComponentPanel = async () => {
        // This is used when opening the test panel from the node
        // We want to open the component pane if it isn't already open
        await handleEdit();
        setOpenTestPanel(id);
      };

      const handleComponentConverted = (newType, changeTo, componentId) => {
        const updatedNodes = nodes.map((component) => {
          if (component.id === componentId) {
            return {
              ...component,
              data: {
                ...component.data,
                type: changeTo.name,
                logo: changeTo.logo,
                baseColor: changeTo.baseColor,
                hasInput: changeTo.hasInput,
                hasOutput: changeTo.hasOutput,
                displayName: changeTo.displayName
              }
            };
          }
          return component;
        });

        CanvasStore.setNodes(updatedNodes);
      };
      
      useEffect(() => {
        const dataComponentId = data?.componentId || id;
        const isEditComponentSelected = editComponent && dataComponentId === editComponent.componentId;
        const isTestPanelOpen = openTestPanel && data.componentId === openTestPanel;
        
        setIsSelectedComponent(isEditComponentSelected || isTestPanelOpen);
      }, [editComponent, openTestPanel, data, id]);

      useEffect(() => {
        const isComponentSelected = selected || isSelectedComponent;
        setSvgSelectedClass(isComponentSelected ? classes.selectedIcon : '');
      }, [isSelectedComponent, selected, classes.selectedIcon]);
      
      const addLinkedConditionalPath = async (data) => {
        if (saving) return;

        setSaving(true);

        const newComponentType = "conditional_workflow";
        const newPathId = uuid();

        const template = ComponentFactory[newComponentType];

        // Create new path component
        const newComponentPosition = calculateNewItemPostion(
          xPos,
          yPos,
          120,
          120,
          200,
          100,
          getElements(reactFlowInstance)
        );

        const newComponent = {
          id: newPathId,
          type: "component",
          data: {
            logo: template.logo,
            hasInput: template.hasInput ? template.hasInput : false,
            hasOutput: template.hasOutput ? template.hasOutput : false,
            type: newComponentType,
            displayName: template.displayName,
            next: [],
            label: template.displayName,
            baseColor: template.baseColor,
            color: getColourFromString(),
          },
          position: newComponentPosition,
        };

        // Create new link from branch path to the new path component
        const newLink = {
          id: uuid(),
          source: id,
          target: newPathId,
          markerEnd: {
            type: "arrowclosed",
            color: "#868686",
          },
          type: "link",
          label: "componentLink",
          data: { showDeleteButton: false },
          style: {
            strokeWidth: "3px",
            stroke: data.baseColor,
          },
        };

        // Add it to the canvas
        CanvasStore.setNodes([...nodes, newComponent]);
        CanvasStore.setEdges([...edges, newLink]);

        // Perform save on the backend
        let rfiObj = reactFlowInstance.toObject();

        let viewport = {
          x: rfiObj.viewport.x.toFixed(5),
          y: rfiObj.viewport.y.toFixed(5),
          zoom: rfiObj.viewport.zoom.toFixed(5),
        };

        let eventData = {
          sourceComponentId: id, // source is the branch_paths
          targetComponentId: newPathId,
          targetType: newComponentType,
          componentId: newPathId,
          type: newComponentType,
          viewData: newComponentPosition,
        };

        let saveEvent = createSaveEvent(
          "ADD_COMPONENT_AND_LINK",
          projectId,
          viewport,
          getElements(reactFlowInstance),
          eventData
        );

        // Send event to save
        const saveURL = `project-service/graphql/project/save/${projectId}`;
        return await send_request_graphql_mutation(
          saveURL,
          SAVE_EVENT(saveEvent),
          "",
          "POST"
        )
          .then((response) => {
            if (response.data.saveEvent && response.data.saveEvent.updated) {
              setLastModified(response.data.saveEvent.lastModified);
            }
            setSaving(false);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const nodeSettingsMenuItems = [
        {
          title: "Edit this component",
          icon: "edit",
          text: "Edit",
          onClick: handleEdit,
          condition: !data.fromWorkflowPath
        },
        {
          title: "Run in production",
          icon: "play_circle",
          text: "Run",
          onClick: () => {
            setLoadingDialog(true);
            checkUnpublishedChanges(true, id);
          },
          condition: !data.isTemp && !data.fromWorkflowPath
        },
        {
          title: "Start test run",
          icon: "science",
          text: "Test",
          onClick: openTestComponentPanel,
          condition: !data.fromWorkflowPath
        },
        {
          title: "Copy",
          icon: "file_copy",
          onClick: () => setCopyNode(true),
          condition: !data.fromWorkflowPath
        },
        {
          title: "Convert component",
          icon: "swap_horizontal_circle",
          onClick: () => setOpenConvertForm(true),
          condition: isForm(data.type) && !data.isTemp && !data.fromWorkflowPath
        },
        {
          title: "Delete",
          icon: "delete",
          onClick: () => setDeleteNode(true)
        }
      ].filter(action => action.condition !== false);

      return (
        <>
          <div
            draggable={false}
            ref={parentRef}
            onDragOver={(event) => {
              event.preventDefault();
            }}
            className={classes.nodeWrapper}
          >
            <div onClick={handleNodeClick}>
              {data.error && (
                <Error className={`error-text ${classes.downsize}`}></Error>
              )}
              <AIDraftStatusBanner
                componentId={id}
                editStatus={data.editStatus}
                isTemp={data.isTemp}
                data={data}
              />

              <ComponentNodeView
                classes={classes}
                data={data}
                selected={selected}
                isSelectedComponent={isSelectedComponent}
                isDraftCanvas={true}
                saving={saving}
                addLinkedConditionalPath={addLinkedConditionalPath}
                mode={mode}
                openTestPanel={openTestPanel}
                editComponent={editComponent}
                parentRef={parentRef}
                ref={ref}
              />
            </div>
              <ComponentSettingsToolbar
                  selected={selected}
                  classes={classes}
                  data={data}
                  actions={nodeSettingsMenuItems}
                  enableWorkflowPath={true}
                  />
          </div>
         
          {/* Dialogs */}
          <CustomDialog
            isOpen={deleteNode}
            size={"sm"}
            title={<h5> Confirm Delete </h5>}
            contents={
              <>
                <p className={" s-text"}>
                  Are you sure you want to delete this component ? All data
                  and settings for this component will be lost and this action
                  cannot be undone.
                </p>
              </>
            }
            buttons={
              <>
                <Button
                  onClick={handleDelete}
                  variant="outlined"
                  disabled={saving}
                >
                  Delete
                </Button>
                <Button
                  onClick={() => setDeleteNode(false)}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </>
            }
          />
          <CustomDialog
            isOpen={moreComponents}
            title={
              "You have reached the maximum of 250 components for this canvas."
            }
            buttons={
              <Button
                onClick={() => {
                  setMoreComponents(false);
                }}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            }
          />
          <ConvertFormDialog
            component={data}
            componentId={id}
            open={openConvertForm}
            convertTo={convertTo}
            handleChange={(value) => handleConvertTo(value)}
            projectId={projectId}
            handleComponentUpdated={(componentType, changeTo) => {
              handleComponentConverted(componentType, changeTo, id);
            }}
            handleClose={() => {
              setOpenConvertForm(false);
            }}
          />
          <CustomDialog
            isOpen={copyNode}
            title={"🖌 Copy and paste component"}
            contents={
              <Grid>
                <p className={"s-text"}>
                  Create a copy of this component and paste it in:
                </p>
                <Select
                  value={workflowType}
                  fullWidth={true}
                  disableUnderline={true}
                  variant={"filled"}
                  onChange={(event) =>
                    handleSelectWorkflowType(event.target.value)
                  }
                >
                  <MenuItem value={"current_workflow"}>
                    Current workflow
                  </MenuItem>
                  <MenuItem value={"another_workflow"}>
                    Another workflow
                  </MenuItem>
                </Select>

                {workflowType === "another_workflow" && (
                  <Autocomplete
                    freeSolo
                    size="small"
                    loading={workflows.length <= 0}
                    options={workflows}
                    getOptionLabel={(option) =>
                      option.projectName
                        ? option.projectName
                        : option.projectId
                        ? option.projectId
                        : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option.projectId}>
                        {option.projectName
                          ? option.projectName
                          : option.projectId}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleSelectWorkflow(newValue);
                      }
                    }}
                    value={""}
                    className={"copySelect"}
                  />
                )}
              </Grid>
            }
            buttons={
              <>
                <Button
                  variant="outlined"
                  disabled={loadCancel}
                  onClick={() => setCopyNode(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loadCopy}
                  onClick={requestCopy}
                >
                  Done
                </Button>
              </>
            }
          />
       </>
      );

    }
  )
);

export default withStyles(styles)(DraftCanvasComponent);
