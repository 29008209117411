import React, { Component } from "react";
import { CustomDialog } from "../Component/Dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import "./MarketplacePage.css";
import { Grid } from "@mui/material";

const ImportDialog = (props) => {
    const importSuccess = props.importSuccess;
    const importsv = props.importsv;

    return (
        <CustomDialog
            fullWidth={true}
            size={"sm"}
            isOpen={importSuccess}
            handleClose={props.close2}
            title={<></>}
            contents={
                <Grid container spacing={4}>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size={36} thickness={4.5} />
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <div style={{ textAlign: "center", fontSize: "18px", lineHeight: "26px" }}>Downloading template...</div>
                    </Grid>
                    <Grid item xs={12}>
                        <span style={{ fontSize: "14px", color: "rgba(54, 54, 54, 1)" }}>
                            You will be redirected to the new template once it has been created. If you close this window, the template will still be downloaded and you can find it in your workflow
                            library when the download is completed.
                        </span>
                    </Grid>
                </Grid>
            }
            buttons={<></>}
        />
    );
};
export default ImportDialog;
