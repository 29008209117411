import React from 'react';
import { SvgIcon } from '@mui/material';

function NumberIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 6.75C15 6.3375 14.6625 6 14.25 6H12V3.75C12 3.3375 11.6625 3 11.25 3C10.8375 3 10.5 3.3375 10.5 3.75V6H7.5V3.75C7.5 3.3375 7.1625 3 6.75 3C6.3375 3 6 3.3375 6 3.75V6H3.75C3.3375 6 3 6.3375 3 6.75C3 7.1625 3.3375 7.5 3.75 7.5H6V10.5H3.75C3.3375 10.5 3 10.8375 3 11.25C3 11.6625 3.3375 12 3.75 12H6V14.25C6 14.6625 6.3375 15 6.75 15C7.1625 15 7.5 14.6625 7.5 14.25V12H10.5V14.25C10.5 14.6625 10.8375 15 11.25 15C11.6625 15 12 14.6625 12 14.25V12H14.25C14.6625 12 15 11.6625 15 11.25C15 10.8375 14.6625 10.5 14.25 10.5H12V7.5H14.25C14.6625 7.5 15 7.1625 15 6.75ZM10.5 10.5H7.5V7.5H10.5V10.5Z" fill="#868686"/>
      </svg>
    </SvgIcon>
  );
}

export default NumberIcon;