import { Add, ArrowForwardIos } from "@mui/icons-material";
import { Button, Fab, styled, Tooltip } from "@mui/material";
import { isAIEnabled } from "../../../../utils/AIUtil";
import ProjectStore from "../../ProjectStore";
import './CanvasButtons.css';

export const CanvasHeaderButton = styled(Button)(
  ({ textColor, bgColor, hoverBg }) => ({
    color: textColor ? textColor : "#000",
    backgroundColor: bgColor ? bgColor : "#FFF",
    border: "none",
    "&:hover": {
      backgroundColor: hoverBg,
      border: "none",
    },

    borderRadius: "4px",
    padding: "2px 16px",
    gap: "4px",
    fontSize: "14px",
    textTransform: "none",
  })
);

const SideMenuFabItem = styled(Fab)(({ fabStyles }) => ({
  ...fabStyles,
  width: "40px",
  height: "40px",
  zIndex: "1000 !important",
  borderRadius: "4px",
  padding: "12px",
}));

const CanvasSideMenuButton = ({
  tooltip,
  onClick,
  icon,
  tour,
  fabStyles,
  showClose,
  disabled,
}) => {
  return (
    <Tooltip title={tooltip}>
      <SideMenuFabItem
        fabStyles={fabStyles ? fabStyles : {}}
        onClick={onClick}
        data-tut={tour}
        disabled={disabled}
      >
        {showClose ? <ArrowForwardIos className="canvasSideMenuIcon" /> : icon}
      </SideMenuFabItem>
    </Tooltip>
  );
};

// Individual Canvas buttons
const AIFabStyles = {
  background: "rgba(148, 75, 168)",
  color: "#FFF",
  marginBottom: "24px",
  "&:hover": {
    color: "#FFF",
    background: "rgba(148, 75, 168, 0.7)",
  },
};

export const AICanvasButton = ({ onClick, showClose, isInAIDraft }) => {
  return (
    <div className="animated-border-wrapper">
      <div className="animated-border-box-glow"></div>
      <div className="animated-border-box">
        <CanvasSideMenuButton
          className="ai-canvas-button"
          showClose={showClose}
          tooltip="AI Workflow Builder"
          fabStyles={{
            ...AIFabStyles,
            width: '40px',
            height: '40px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 3,
          }}
          onClick={onClick}
          disabled={isInAIDraft}
          icon={<img className="canvasSideMenuIcon" src="/svgs/ai-stars.svg"></img>}
        />
      </div>
    </div>
  );
};

const ComponentFabStyles = {
  background: "#000 !important",
  color: "#fff",
  marginBottom: "24px",
  "&:hover": {
    color: "#FFF",
  },
};

export const ComponentMenuCanvasButton = ({
  onClick,
  showClose,
  setTour,
  disabled = false,
}) => {
  return (
    <CanvasSideMenuButton
      showClose={showClose}
      tooltip="Add a component"
      fabStyles={ComponentFabStyles}
      onClick={onClick}
      tour={setTour ? "reactour__canvas__open_components" : ""}
      disabled={disabled}
      icon={<Add className="canvasSideMenuIcon" />}
    />
  );
};

const NoteFabStyles = {
  background: "#fbf6be !important",
  color: "#000",
  "&:hover": {
    color: "#FFF",
  },
};

export const NoteMenuCanvasButton = ({ onClick, showClose, setTour }) => {
  return (
    <CanvasSideMenuButton
      showClose={showClose}
      tooltip="Add a note"
      fabStyles={NoteFabStyles}
      onClick={onClick}
      tour={setTour ? "reactour__canvas__notes" : ""}
      icon={
        <i className={"material-icons canvasSideMenuIcon"}>sticky_note_2</i>
      }
    />
  );
};
