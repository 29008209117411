import React, { FunctionComponent } from "react";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

const RibbonItem = (props) => {
  const useStyles = makeStyles(() => ({
    menuItem: {
      color: "#2196F3",
      backgroundColor: "unset !important",
      "&:hover": {}
    }
  }));
  const classes = useStyles();
  return (
    <MenuItem
      classes={{ selected: classes.menuItem }}
      selected={props.selected}
      onClick={props.onClick}
    >
      {props.children}
    </MenuItem>
  );
};
export default RibbonItem;
