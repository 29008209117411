import React from 'react';
import { SvgIcon } from '@mui/material';

function DatetimeIcon(props) {
  return (
    <SvgIcon viewBox="0 -3 24 24" {...props}>
      <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 9.75H9.75C9.3375 9.75 9 10.0875 9 10.5V12.75C9 13.1625 9.3375 13.5 9.75 13.5H12C12.4125 13.5 12.75 13.1625 12.75 12.75V10.5C12.75 10.0875 12.4125 9.75 12 9.75ZM12 2.25V3H6V2.25C6 1.8375 5.6625 1.5 5.25 1.5C4.8375 1.5 4.5 1.8375 4.5 2.25V3H3.75C2.9175 3 2.2575 3.675 2.2575 4.5L2.25 15C2.25 15.825 2.9175 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3H13.5V2.25C13.5 1.8375 13.1625 1.5 12.75 1.5C12.3375 1.5 12 1.8375 12 2.25ZM13.5 15H4.5C4.0875 15 3.75 14.6625 3.75 14.25V6.75H14.25V14.25C14.25 14.6625 13.9125 15 13.5 15Z" fill="#868686"/>
      </svg>
    </SvgIcon>
  );
}

export default DatetimeIcon;