import React, { Component } from "react";
import { Grid, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import InZapierOrW86Box from "./InZapierOrW86Box";

class SetEndpointUrl extends Component {
  render() {
    let zapierContent = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          1. Return to the <b>Set up Action</b> step in <b>Zapier</b>
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          <img
            src="/images/trigger_from_zapier_set_endpoint.PNG"
            style={{
              border: "thin solid #C4C4C4",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              width: "80%",
              height: "auto"
            }}
          />
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          2. Replace the <b>Sample Endpoint URL</b> with the <b>Production Endpoint URL</b> below
        </Grid>

        <Grid item xs={12} style={{ marginTop: "0px" }}>
          <b style={{ fontSize: "14px" }}>Production Endpoint URL</b>
          <TextField
            value={
              "https://webhook.workflow86.com/zapier/" + this.props.component_id
            }
            style={{ width: "100%" }}
            className="key_padding textFieldZapier"
            variant="outlined"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboard
                    text={
                      "https://webhook.workflow86.com/zapier/" +
                      this.props.component_id
                    }
                  >
                    <FileCopyIcon className="custom-icon-btn" />
                  </CopyToClipboard>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          3. Click <b>Continue</b> in <b>Zapier</b>
        </Grid>
      </Grid>
    );

    let workflow86Content = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          4. Return to <b>Workflow86</b> and click <b>Next</b>
        </Grid>
      </Grid>
    );

    return (
      <Grid container spacing={2}>
        <InZapierOrW86Box zapierOrW86={"Zapier"} content={zapierContent} isExpanded={this.props.isExpanded} />

        <InZapierOrW86Box
          zapierOrW86={"Workflow86"}
          content={workflow86Content}
          isExpanded={this.props.isExpanded}
        />

        <Grid item xs={12} className="dialogContentRight">
          <Grid item xs={12}>
            <Button
              onClick={() => this.props.onChangeState(4, 4)}
              className="btnSpace"
              variant={"outlined"}
              color={"info"}
            >
              {" "}
              Back
            </Button>
            <Button
              onClick={() => this.props.onChangeState(6, 6)}
              variant={"contained"}
              color={"primary"}
            >
              {" "}
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SetEndpointUrl;
