import React, { Fragment } from "react";

import { Grid, TextField } from "@mui/material";
import TransformStore from "../../TransformStore";
import { SelectExtract } from "../Component";

function ExtractByOrder({ extraction, index }) {
    return (
        <Fragment>
            <Grid item xs={12} className="item-label">
                Extract the
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <SelectExtract value={extraction[0].func} onChange={(value) => TransformStore.setExtraction(value, index, "func", "extraction")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="number"
                            className="key_padding"
                            value={extraction[0].elementNo}
                            onChange={(e) => {
                                TransformStore.setExtraction(e.target.value, index, "value", "extraction");
                            }}
                            defaultValue={1}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ExtractByOrder;
