import React, {Component} from 'react';
import {CircularProgress} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import APIResReqStore from "../APIResReqStore";
import {send_request} from "../../../utils/Request";
import Grid from "@mui/material/Grid";
import { Send } from '@mui/icons-material';

class SendRequest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      sendReqeuestMessage: "📩 Click Send Request so we can get a sample response from the API"
    };
  }

  sendRequest = () => {
    this.setState({
      loading: true,
      sendReqeuestMessage: "⏳ Waiting for a response from the API",
      error: false
    })
    setTimeout(() => {
      send_request(
        `component-service/apirequestandresponse/sampling`,
        APIResReqStore,
        "",
        "POST"
      ).then((response) => {
        if (response && response.data) {
          /*
          Ensuring even if response body is null we get header and status
           */
          if (response.data.responseBody || (response.data.responseHeader!==null && response.data.status==="200 OK") ) {

            let sampleResponse = response.data.responseBody?response.data.responseBody: {};
            sampleResponse["headers"]=response.data.responseHeader;
            sampleResponse["status"]=response.data.status;
            APIResReqStore.setField(JSON.stringify(sampleResponse), "", "sample");
            this.setState({
              loading: false
            })
            this.props.handleSampleResponseReceived();
          } else if (response.data.errors) {
            this.setState({error: true, errorMessage: response.data.errors.reason ,loading:false});
          }else {
            this.setState({error: true, errorMessage: response.data.status ,loading:false});
          }
        }
      }).catch((err) => {
        this.setState({error: true, errorMessage: err.message,loading:false});
      });
    },2000)
  }

  render() {
    const {loading,error, errorMessage } = this.state
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          className="dialogContent" style={{marginBottom: 24}}>
          <Grid
            item
            xs={12}
            justifyContent="flex-end"
            display={"flex"}
          >
            <Button
              onClick={() => this.props.onChangeState(0,0)}
              className="btnSpace"
              variant={"outlined"}  
            >
              {" "}
              Back
            </Button>
            <Button
              onClick={this.sendRequest}
              disabled={loading}
              variant={"contained"}
              sx={{
                backgroundColor: "rgba(85, 167, 122, 1)",
                "&:hover": {
                  backgroundColor: "rgba(85, 167, 122, 1)",
                }
              }}
              startIcon={<Send />}
            >
              {" "}
              SEND REQUEST
              {loading && (
                <CircularProgress
                  size={24}
                  className="buttonProgress"
                />
              )}
            </Button>
          </Grid>
        </Grid>
        {
          error && (
            <p className="err-text">
              <ErrorIcon className="iconDiscard" />
              A response was not received. Please try again by sending another request. ( {errorMessage} )
            </p>
          )
        }
      </Grid>
    );
  }
}

export default SendRequest;