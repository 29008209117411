import React from "react";
import { CustomDialog } from "../../Component/Dialog";
import { Button } from "@mui/material";
import ReactCrop from "react-image-crop";
import DropZone from "vardogyir-ui/DropZone";
import Chip from "vardogyir-ui/Chip";
import Dropzone from "react-dropzone";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

export const FormStylingDialog = (props) => {
  const {
    coverImageCrop,
    coverImageSrc,
    openUploadDialog,
    setOpenUploadDialog,
    onImageLoaded,
    onCropChange,
    onCropComplete,
    onImageDrop,
    onDropRejected,
    uploadedCoverImage,
    errorCoverImageMessage,
    uploadedLogo,
    errorLogoMessage,
    loadingUpload,
    deleteCoverImage,
    onLogoDrop,
    setUploadedLogo,
    setUploadIcon,
  } = props;

  return (
    <CustomDialog
      isOpen={openUploadDialog}
      onClose={() => {
        setOpenUploadDialog(false);
      }}
      title={"Form styling"}
      contents={
        <>
          <p>Upload cover image</p>
          {coverImageSrc ? (
            <ReactCrop
              src={coverImageSrc}
              crop={coverImageCrop}
              ruleOfThirds
              onImageLoaded={(image) => onImageLoaded(image, "coverImage")}
              onComplete={(crop) => onCropComplete(crop, "coverImage")}
              onChange={(crop) => onCropChange(crop, "coverImage")}
              className="form-react-crop"
            />
          ) : (
            <Dropzone
              accept={".png,.jpg,.jpeg"}
              multiple={false}
              onDrop={(acceptedFiles) =>
                onImageDrop(acceptedFiles, "coverImage")
              }
              maxSize={2097152}
              onDropRejected={() => onDropRejected("coverImage")}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} style={{}}>
                    <DropZone />
                    <input type={"file"} {...getInputProps()} />
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          <div style={{ height: 8 }} />
          {uploadedCoverImage &&
            uploadedCoverImage.name !== undefined &&
            uploadedCoverImage.name !== "" && (
              <Chip
                label={`${uploadedCoverImage.name}`}
                onDelete={() => {
                  deleteCoverImage();
                }}
              ></Chip>
            )}
          {errorCoverImageMessage && (
            <p className="form-err-text">
              <ErrorIcon className="iconDiscard" />
              {errorCoverImageMessage}
            </p>
          )}
          <p>Upload logo</p>

          <Dropzone
            accept={".png,.jpg"}
            multiple={false}
            onDrop={(acceptedFiles) => onLogoDrop(acceptedFiles)}
            maxSize={2097152}
            onDropRejected={() => onDropRejected("logo")}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} style={{}}>
                  <DropZone />
                  <input type={"file"} {...getInputProps()} />
                </div>
              </section>
            )}
          </Dropzone>
          <div style={{ height: 8 }} />
          {uploadedLogo.name && (
            <Chip
              label={`${uploadedLogo.name}`}
              onDelete={() => {
                setUploadedLogo("");
                setUploadIcon("");
              }}
            ></Chip>
          )}

          {errorLogoMessage && (
            <p className="form-err-text">
              <ErrorIcon className="iconDiscard" />
              {errorLogoMessage}
            </p>
          )}
        </>
      }
      buttons={
        <>
          <Button
            onClick={() => {
              setOpenUploadDialog(false);
            }}
            variant={"outlined"}
            color={"info"}
            disabled={loadingUpload}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpenUploadDialog(false);
            }}
            variant={"contained"}
            color={"primary"}
            disabled={loadingUpload}
          >
            Done
            {loadingUpload && (
              <CircularProgress size={24} className="buttonProgress" />
            )}
          </Button>
        </>
      }
    />
  );
};
FormStylingDialog.propTypes = {
  coverImageCrop: PropTypes.object,
  coverImageSrc: PropTypes.string,
  openUploadDialog: PropTypes.bool.isRequired,
  setOpenUploadDialog: PropTypes.func.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired,
  onImageDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  uploadedCoverImage: PropTypes.shape({
    name: PropTypes.string,
  }),
  errorCoverImageMessage: PropTypes.string,
  uploadedLogo: PropTypes.shape({
    name: PropTypes.string,
  }),
  errorLogoMessage: PropTypes.string,
  loadingUpload: PropTypes.bool.isRequired,
  deleteCoverImage: PropTypes.func.isRequired,
  onLogoDrop: PropTypes.func.isRequired,
  setUploadedLogo: PropTypes.func,
  setUploadIcon: PropTypes.func,
};
