import {
  Grid,
  Paper,
  Typography,
  Breadcrumbs,
  Container,
  Button
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent
} from "@mui/lab";
import {
  CheckCircle,
  RemoveCircleRounded,
  PauseCircleOutlineRounded,
  CachedRounded,
  ErrorRounded as CrossCircle,
  ArrowBack,
  CancelPresentation,
  AccountTree,
  Refresh
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import { format } from "../../../utils/Date";
import React, { useEffect, useState } from "react";
import { send_request } from "../../../utils/Request";
import ProjectStore from "../../ProjectCanvas/ProjectStore";
import { showLoadingScreen } from "../../../utils/showLoadingScreen";
import styles from "./LogStyling.js";
import { withStyles } from "@mui/styles";
import { useParams } from "react-router";
import PendingIcon from "@mui/icons-material/Pending";
import config from "../../../config";
import { capitalizeLetter, getComponentDisplayName } from "./LogHelpers";
import { styled } from "@mui/system";

const StyledButton = styled(Button)({
  marginBottom: "8px"
})

const LogSession = props => {
  const [session, setSession] = useState({});
  const [loading, setLoading] = useState(false);

  const params = useParams();
  let { id, project_session_id } = props;
  const { classes } = props;

  useEffect(async () => {
    getSessionData();
  }, []);

  const getSessionData = async () => {
    setLoading(true);
    const data = await send_request(
      `project-service/test/get_test_log/${id}/${project_session_id}`,
      null,
      "",
      "GET"
    )
      .then(res => {
        setSession(res.data);
        console.log(res.data)
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        goBack();
        setLoading(false);
      });
  };
  const renderIcon = (status, size) => {
    switch (status) {
      case "FAIL":
        return <CrossCircle fontSize={size} className="cross-icon" />;
      case "PAUSED":
        return <PauseCircleOutlineRounded fontSize={size} color={"info"} />;
      case "SUCCESS":
      case "SUCCESS_END":
        return <CheckCircle fontSize={size} className="check-icon" />;
      case "INPROGRESS":
        return <CachedRounded fontSize={size} className="default-small-icon" />;
      case "WAITING":
        return <PendingIcon fontSize={size} className="small-yellow-icon" />;
      case "TERMINATED":
        return (
          <RemoveCircleRounded fontSize={size} className="small-red-icon" />
        );
    }
  };

  const renderStatus = (status, noStyling) => {
    let color;

    if (!noStyling) {
      switch (status) {
        case "FAIL":
          color = "#B00020";
          break;
        case "PAUSED":
          color = "info";
          break;
        case "SUCCESS":
        case "SUCCESS_END":
          color = "#31a633";
          break;
        case "INPROGRESS":
          color = "black";
          break;
        case "WAITING":
          color = "#FF9D0B";
          break;
        case "TERMINATED":
          color = "#B00020";
          break;
      }
    }

    if (status === "FAIL") {
      status = "Error";
    }

    if (status === "INPROGRESS") {
      status = "In Progress";
    }
    return (
      <span
        style={
          noStyling ? { marginLeft: "1rem" } : { color, fontWeight: "bold" }
        }
      >
        {capitalizeLetter(status.toLowerCase())}
      </span>
    );
  };

  const goBack = () => {
    window.location.href = `/project/logs/${id}`;
  };

  const terminateSession = projectSessionId => {
    if (!projectSessionId) return;

    let url = `project-service/project/terminate/${projectSessionId}`;

    send_request(url, "", { isTest: true }, "post")
    .then((res) => {
      getSessionData();
    })
    .catch(err => {
      console.log(err);
    });
  };

  const getDetailMsg = session => {
    console.log(session.status)
    const { classes } = props;
    let createdAt = session.createdAt
      ? format(session.createdAt, ProjectStore.state.timezone)
      : "";

    switch (session.status) {
      case "FAIL":
        return "Workflow session started on " + createdAt;
      case "PAUSED":
        return "Workflow session started on " + createdAt;
      case "SUCCESS":
      case "SUCCESS_END":
        return (
          "Workflow session started on " +
          createdAt +
          " and successfully completed."
        );
      case "WAITING":
        return (
          <div className={"m-0"}>
            <p className={"m-0"}>
              {"Workflow session started on " +
                createdAt +
                " and is waiting for a component to be executed."}
            </p>
            <Grid container marginTop={"10px"}>
              <Button
                onClick={() => {
                  terminateSession(session.projectSessionId);
                }}
                variant={"contained"}
                color={"error"}
                startIcon={<CancelPresentation />}
              >
                Terminate session
              </Button>
            </Grid>
          </div>
        );
      case "TERMINATED":
        return (
          "Workflow session started on " +
          createdAt +
          " and has been terminated."
        );
    }
  };

  const getLTLTitle = (component, threadIndex, parentToChildMap) => {
    let indentLevel = 1;
    if (component.status === "SUCCESS_END") {
      // Handle for LTL Parent
      if (threadIndex[String(component.thread.threadId)] != null) {
        indentLevel = threadIndex[String(component.thread.threadId)] + 1;
      }

      return capitalizeLetter(
        `Loop List (Parent${indentLevel && "-" + indentLevel})`
      );
    } else {
      // Handle for LTL Success
      let currThread =
        parentToChildMap[String(component.thread.parentThreadId)];
      indentLevel += currThread.indexOf(component);

      return capitalizeLetter(
        `Loop List (Child${indentLevel && "-" + indentLevel})`
      );
    }
  };

  const terminateAssignTask = (
    projectSessionId,
    componentId,
    thread = null
  ) => {
    if (!projectSessionId) return;

    setLoading(true);

    if (thread === null) {
      send_request(
        `project-service/project/terminate_task/${projectSessionId}/${componentId}`,
        "",
        { isTest: true },
        "post"
      ).catch((error) => {
        console.log(error);
      });
    } else {
      send_request(
        `project-service/project/terminate_task/${projectSessionId}/${componentId}`,
        "",
        {
          threadid: thread.threadId,
          isTest: true
        },
        "post"
      ).catch((error) => {
        console.log(error);
      });
    }

    /**
     * This is to set the component session to terminated on the frontend
     */

    // We create shallow copies of the session logs and the project session
    // And assign these changed copies back into the state
    let components = [...session.components];

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      if (
        component.componentId === componentId &&
        component.status === "WAITING"
      ) {
        if (
          thread !== null &&
          thread.threadId === component.thread.threadId
        ) {
          component.status = "TERMINATED";
          break;
        } else if (thread === null) {
          component.status = "TERMINATED";
          break;
        }
      }
    }

    // /**
    //  * Here, we check if all assign tasks or schedule delay components have all been terminated.
    //  * If they have all been terminated, then we terminate the entire project session.
    //  */

    let projectSessionTerminated = true;

    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      if (
        (component.type === "sequential_form" ||
        component.type === "time_delay" ||
        component.type === "form_section") &&
        component.status === "WAITING"
      ) {
        if (component.status !== "TERMINATED") {
          projectSessionTerminated = false;
          break;
        }
      }
    }

    let updatedSession = {
      ...session,
      components: components
    };

    if (projectSessionTerminated) {
      updatedSession.status = "TERMINATED";
    } 

    setSession(updatedSession)
    setLoading(false);
  }

  const getTimelineContent = (
    component,
    classes,
    threadIndex,
    parentToChildMap
  ) => {

    const { test_id } = useParams();

    let formSessionId =
      component.data && component.data.data
        ? component.data.formSessionId || component.data.data.formSessionId
        : "";

    return (
      <Grid className={classes.timelineContainer} container>
        <Grid xs={12} className={classes.timelineItem} item>
          {component.type != "loop_through_list"
            ? getComponentDisplayName(component.type)
            : getLTLTitle(component, threadIndex, parentToChildMap)}
        </Grid>
        {(component.type === "sequential_form" ||
          component.type === "time_delay" ||
          component.type === "form_section") &&
          component.status === "WAITING" && (
            <Grid xs={12} item>
              <Button
                onClick={() => {
                  terminateAssignTask(
                    session.projectSessionId,
                    component.componentId,
                    component.thread,
                  );
                }}
                style={{
                  backgroundColor: "#B00020",
                  color: "white",
                  marginBottom: "1rem",
                }}
                disabled={
                  ((loading && session.projectSessionId))
                    ? true
                    : false
                }
                startIcon={<CancelPresentation />}
              >
                {"Terminate Action"}
                {loading && session.projectSessionId (
                    <CircularProgress size={30} className={"loadingButton"} />
                  )}
              </Button>
            </Grid>
          )}
        {(component.type === "form" || component.type === "sequential_form" || component.type === "form_section") &&
        component.data &&
        component.data.data ? (
          <>
            <Grid item xs={2}>
              Form
            </Grid>
            <Grid item xs={10}>
              <a
                className={classes.link}
                href={
                  `${config.FORM.FORM_FRONT_END}/form/form_test/` +
                  component.componentId +
                  "/" +
                  test_id
                  +
                  "/" +
                  formSessionId
                }
                target="_blank"
              >
                https://form.workflow86.com/form/form_test/
                {component.componentId}/{test_id}/{formSessionId}
              </a>
            </Grid>
          </>
        ) : (
          ""
        )}
        {component.type === "SUCCESS" &&
        component.type === "document_editor" &&
        component.data ? (
          <>
            <Grid item xs={2}>
              Document
            </Grid>
            <Grid item xs={10} wrap={"nowrap"}>
              <a
                className={classes.link}
                href={component.data[Object.keys(component.data)[0]]}
                target="_blank"
              >
                {component.data[Object.keys(component.data)[0]]}
              </a>
            </Grid>
          </>
        ) : (
          ""
        )}
        <Grid item xs={2}>Started:</Grid>
        <Grid xs={10}>
          {format(component.startAt, ProjectStore.state.timezone)}
        </Grid>
        <Grid xs={2}>Ended:</Grid>
        <Grid xs={10}>
          {format(component.endAt, ProjectStore.state.timezone)}
        </Grid>
        <Grid xs={2}>Status:</Grid>
        <Grid xs={10}>{renderStatus(component.status)}</Grid>
        {component.error && (
          <>
            <Grid xs={2}>Error:</Grid>
            <Grid xs={10}>
              <Typography className={"warning preformatted"}>
                {component.error}
              </Typography>
            </Grid>
          </>
        )}
        <Grid xs={12}>
          <ReactJson
            src={
              component.data && component.data.data
                ? component.data.data
                : component.data
                ? component.data
                : {}
            }
            name="Data"
            iconStyle="square"
            displayDataTypes={false}
            className={classes.reactJson}
            theme={{
              base00: "rgba(1, 1, 1, 0)",
              base01: "rgba(1, 1, 1, 0.1)",
              base02: "rgba(0, 0, 0, 0.2)",
              base03: "rgba(1, 1, 1, 0.3)",
              base04: "rgba(0, 0, 0, 0.4)",
              base05: "rgba(1, 1, 1, 0.5)",
              base06: "rgba(1, 1, 1, 0.6)",
              base07: "rgba(1, 1, 1, 0.7)",
              base08: "rgba(0, 0, 0, 1)",
              base09: "rgba(0, 0, 0, 1)",
              base0A: "rgba(0, 0, 0, 1)",
              base0B: "rgba(0, 0, 0, 1)",
              base0C: "rgba(0, 0, 0, 1)",
              base0D: "rgba(0, 0, 0, 1)",
              base0E: "rgba(1, 1, 1, 0.7)",
              base0F: "rgba(1, 1, 1, 0.7)"
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const getBase = (component, classes, threadIndex, parentToChildMap) => {
    return (
      <TimelineItem>
        <TimelineOppositeContent 
          style={{ 
            flex: "none",
            minWidth: "150px",
            maxWidth: "150px",
            textAlign: "left",
            boxSizing: "border-box",
            whiteSpace: "pre-line",
          }}
        >
          <Typography>
            {format(component.startAt, ProjectStore.state.timezone, "timeline")}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          {renderIcon(component.status, "large")}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={5}>
          {getTimelineContent(
            component,
            classes,
            threadIndex,
            parentToChildMap
          )}
        </TimelineContent>
        <br />
      </TimelineItem>
    );
  };

  const getOuter = (content, classes) => {
    return (
      <TimelineItem>
        <TimelineOppositeContent
          position="left"
          className={classes.flexLeft}
        ></TimelineOppositeContent>
        <TimelineContent>{content}</TimelineContent>
      </TimelineItem>
    );
  };

  // Gets a map of all the components
  // Map <threadId,parentThreadId>
  const getAllThreads = data => {
    let newData = data.filter(c => c.type == "loop_through_list");

    let returnObj = {};

    for (let i = 0; i < newData.length; i++) {
      let currThread = newData[i].thread;

      if (returnObj[String(currThread.threadId)] == null) {
        returnObj[String(currThread.threadId)] = String(
          currThread.parentThreadId
        );
      }
    }
    return returnObj;
  };

  const getAmountOfCalls = (base, index, classes) => {
    while (index > 0) {
      base = getOuter(base, classes);
      return getAmountOfCalls(base, index - 1, classes);
    }

    return base;
  };

  // Returns JSX for the given indentation of a component
  const getContentForLevel = (
    component,
    index,
    classes,
    threadIndex,
    parentToChildMap
  ) => {
    let base = getBase(component, classes, threadIndex, parentToChildMap);
    return getAmountOfCalls(base, index, classes);
  };

  // Returns a map of all components
  // Map <threadId, indentationIndex>
  const mapParentThreadToIndex = allThreads => {
    let threadIndexMap = {};

    let keys = Object.keys(allThreads);

    for (let currKey of keys) {
      const mainKey = currKey;
      let idx = 0;

      while (allThreads[currKey] != "null") {
        currKey = allThreads[currKey];
        idx += 1;
      }
      if (idx >= 2) idx -= 1; // Bring everything in because we remove the LTL Success
      threadIndexMap[mainKey] = idx;
    }

    threadIndexMap["null"] = 0; // Set null thread
    return threadIndexMap;
  };

  // Creates the mapping
  // Returns Map<String, Map<String, List>>
  const createMapping = components => {
    let mapping = {};

    for (let i = 0; i < components.length; i++) {
      let currComponent = components[i];

      let innerMap = {};
      let parent = "null";
      let id = "null";

      if (currComponent.thread != null) {
        parent = String(currComponent.thread.parentThreadId);
        id = String(currComponent.thread.threadId);
      }

      // Check if contains parent
      if (mapping[parent] != null) {
        if (mapping[parent][id] != null) {
          // Add into array
          mapping[parent][id].push(currComponent);
        } else {
          // Add in new thread entry
          mapping[parent][id] = [currComponent];
        }
      } else {
        // Add in new parent entry
        innerMap[id] = [currComponent];
        mapping[parent] = innerMap;
      }
    }

    return mapping;
  };

  // Recursively adds the components
  const recurseAdd = (currComponent, mapping, orderedComponents) => {
    if (!orderedComponents.includes(currComponent)) {
      orderedComponents.push(currComponent);
    }

    let threadId = String(currComponent.thread.threadId);

    // If we hit another success_end, need to recurse
    if (mapping[threadId] != null) {
      let innerMapping = mapping[threadId];

      let keys = Object.keys(innerMapping);

      for (let key of keys) {
        let componentsOnThread = innerMapping[key];

        // Go over
        for (let i = 0; i < componentsOnThread.length; i++) {
          let currC = componentsOnThread[i];

          if (currC.type === "loop_through_list" || currC.thread != null) {
            recurseAdd(currC, mapping, orderedComponents);
          } else if (!orderedComponents.includes(currC)) {
            orderedComponents.push(currC);
          }
        }
      }
    }
  };

  // This will return a list called orderedComponents
  // This is the final order that gets printed for the components
  const getOrderedComponents = (components, mapping) => {
    let orderedComponents = [];

    for (let i = 0; i < components.length; i++) {
      let currComponent = components[i];

      // If not in components order, then consider
      if (!orderedComponents.includes(currComponent)) {
        if (currComponent.thread == null) {
          // Add and continue to next component if no thread
          orderedComponents.push(currComponent);
          continue;
        }

        // Else we're dealing with a component with a thread
        // Recursively add it to the orderedComponents

        // Check if SE - LTL
        if (
          currComponent.type === "loop_through_list" &&
          currComponent.status === "SUCCESS_END"
        ) {
          recurseAdd(currComponent, mapping, orderedComponents);
        }
      }
    }

    return orderedComponents;
  };

  const getParentToChildrenMapping = components => {
    let parentToChildMapping = {};

    for (let i = 0; i < components.length; i++) {
      let currComponent = components[i];

      if (
        currComponent.thread != null &&
        currComponent.type === "loop_through_list" &&
        currComponent.status != "SUCCESS_END"
      ) {
        if (
          parentToChildMapping[String(currComponent.thread.parentThreadId)] ==
          null
        ) {
          parentToChildMapping[String(currComponent.thread.parentThreadId)] = [
            currComponent
          ];
        } else {
          parentToChildMapping[
            String(currComponent.thread.parentThreadId)
          ].push(currComponent);
        }
      }
    }

    return parentToChildMapping;
  };

  const renderAllComponents = (components, classes) => {
    // If the componenets contain a LTL
    if (components.some(c => c.type === "loop_through_list")) {
      try {
        // Create the mapping
        const mapping = createMapping(components);

        // Get the ordered components
        let orderedComponents = getOrderedComponents(components, mapping);

        const allThreads = getAllThreads(components); // Get all thread mappings
        const threadIndex = mapParentThreadToIndex(allThreads); // Get all components indentation
        const parentToChildMap = getParentToChildrenMapping(orderedComponents);

        const content = [];

        // Loop over orderedComponents
        for (let currComponent of orderedComponents) {
          let threadId = currComponent.thread
            ? String(currComponent.thread.threadId)
            : "null";

          let additionalIndent = 0;

          if (
            currComponent.thread != null &&
            currComponent.type != "loop_through_list"
          )
            additionalIndent += 1;

          // Get the jsx for the currComponent
          // And add it to the content array
          let jsx = getContentForLevel(
            currComponent,
            threadIndex[threadId] + additionalIndent,
            classes,
            threadIndex,
            parentToChildMap
          );
          content.push(jsx);
        }

        return <Timeline>{content.flat()}</Timeline>;
      } catch (e) {
        console.log(e);
        return (
          <Timeline>
            {components.map(c => {
              return getBase(c, classes);
            })}
          </Timeline>
        );
      }
    } else {
      // No LTL, just return normal
      return (
        <Timeline>
          {components.map(c => {
            return getBase(c, classes);
          })}
        </Timeline>
      );
    }
  };

  if (!loading)
    return (
      <>
          <Grid container flex item xs={12} direction={"column"} justifyContent="flex-start">
            <Grid container item xs={12} direction={"row"}>
              <Grid className={classes.headerStatus} item xs="10">
                {session.status && renderIcon(session.status)}
                {session.status && renderStatus(session.status, true)}
              </Grid>
            </Grid>
            {session.status && getDetailMsg(session)}
              <Link
                  to={{
                    pathname: `/project/logs/progress_view/${id}/${project_session_id}?test=true`,
                  }}
                  target={"_blank"}
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                <Button
                  variant={"outlined"}
                  color={"info"}
                  startIcon={<AccountTree />}
                >
                  View Progress
                </Button>
              </Link>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={props.handleRefresh}
                >
                  Refresh
                </StyledButton>
              </Grid>
            <Timeline>
              {session.components &&
                renderAllComponents(session.components, classes)}
            </Timeline>
          </Grid>
      </>
    );
  else return showLoadingScreen();
};
export default withStyles(styles)(LogSession);
