import React from "react";
import { Fade, Grid, Typography } from "@mui/material";
import AIBuilderStyles from "../../../../AIBuilderStyles";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { AutoAwesome, Warning, Output, Storage, VpnKey } from "@mui/icons-material";
import './initialMessages.css';
import ContextChips from "./ContextChips";

const CONTEXT_CHIPS = [
  { 
    label: "Input placeholders",
    tooltip: "All placeholders from upstream components connected into this component.",
    icon: <Output fontSize="small" />,
    applicableTypes: ['all']
  },
  { 
    label: "Secrets and credentials",
    tooltip: "Any secret and credential placeholders available to this workflow or component but I will not know their values. I can choose to use these secrets and credentials if appropriate.",
    icon: <VpnKey fontSize="small" />,
    applicableTypes: ['code', 'api_request_and_response']
  },
  { 
    label: "Active and connected databases",
    tooltip: "Any databases actively being used by a component in this workflow, or connected but not actively used. I can choose to use these databases if appropriate.",
    icon: <Storage fontSize="small" />,
    applicableTypes: ['query_database_record', 'update_database_record', 'create_database_record']
  }
];

const BuildComponentMessage = (props) => {

  const getContextChips = () => {
    const type = (props.componentType || '').toLowerCase() || 'all';
    return CONTEXT_CHIPS.filter(chip => 
      (chip.applicableTypes.includes('all') || chip.applicableTypes.includes(type)) &&
      (chip.label !== "Secrets and credentials" || ['code', 'api_request_and_response'].includes(type)) &&
      (chip.label !== "Active and connected databases" || 
        ['query_database_record', 'update_database_record', 'create_database_record'].includes(type))
    );
  };

  const contextChips = getContextChips();

  return (
    <Fade in timeout={1000}>
      <div className={props.classes.gpt}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              <span className={"bold"}>
                <AutoAwesome
                  className="autoAwesomeIcon"
                />
                Build New Component
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            I will build a new component based on your instructions. Note this will overwrite any existing configuration or settings in the component.
          </Grid>

          <Grid item xs={12}>
            <div className="warningBanner">
              <Warning color="warning" />
              <Typography variant="body2">
                This action will overwrite any existing configuration of the component.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} className="messagePadding">
            <b>💡 Tips</b>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            For the best results, include as much information as possible or be
            specific with your requirements for you what you want this component
            to do.
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
              <b>ℹ️ Context</b>
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <Typography>
               I will have access to the following context when building this component:
            </Typography>
          </Grid>
          <Grid item xs={12} className="messagePadding">
            <ContextChips chips={contextChips} />
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

BuildComponentMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  sidebarState: PropTypes.string.isRequired,
  messageSent: PropTypes.bool.isRequired,
  componentType: PropTypes.string
};

export default withStyles(AIBuilderStyles())(BuildComponentMessage);
